import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Sound from '../../assets/sound/sound.mp3';
import { Toast } from '../../uikit';
import { LinkWrapper } from '../../uikit';
import SvgArrowLeft from '../../icons/SvgArrowLeft';

import { AppDispatch, RootState } from '../../store';

import {
  chatbotcheckmiddleware,
  chatbotfeedbackmiddleware,
  chatbotdataget,
  chatbottimermiddleware,
} from '../chatbot/store/middleware/chatbotmiddleware';
import Svgchatbotclose from '../../icons/Svgchatbotclose';
import Svgchatbotopen from '../../icons/Svgchatbotopen';
import zita_img from '../../assets/images/zita_img.png';
import Svgchatbotthumbsdown from '../../icons/Svgchatbotthumbsdown';
import Svgchatbotthumbsup from '../../icons/Svgchatbotthumbsup';
import SvgThumbsup from '../../icons/Svgthumbsup';
import SvgThumbsdown from '../../icons/Svgthumbsdown';
import SvgshareIcon from '../../icons/SvgShareIconview';
import Svgchatbotsendicon from '../../icons/Svgchatbotsendicon';
import styles from './chatbot.module.css';
import useApiInterval from './apiintravel';

type Props = {};

const Chatbot = ({}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const cookies = new Cookies();
  const [check, setcheck] = useState(false);
  const [loader, setloader] = useState(false);
  const [Feedback, setFeedback] = useState(false);
  const [Feedback_modal, setFeedback_modal] = useState(false);
  const [Feed_backvalue, setFeed_backvalue] = useState('');
  const [message, setMessage] = useState('');
  const [isvalidate, setvalidate] = useState('');
  const chatBoxRef = useRef(null);
  const [data, setData] = useState([]);
  const [Feedback_data, setFeedback_data] = useState([]);
  const textareaRef = useRef(null);
  const [error, seterror] = useState('');
  const [suggestion, setsuggestion] = useState([]);
  const [notification, setnotification] = useState(0);
  const [minimize, setminimize] = useState(false);
  const [inactive, setInactive] = useState(false);

  const [staticdata, setstaticdata] = useState([
    'Application Process',
    'Interview Schedule',
    'Comparative Analysis',
    'Matching & Descriptive Analysis',
  ]);
  const [intervaldata, setintervaldata] = useState([
    'Ok, thanks',
    'Application Process',
    'Interview Schedule',
    'Comparative Analysis',
  ]);

  const { user, chat } = useSelector(
    ({ userProfileReducers, ChatbotGetdata }: RootState) => ({
      user: userProfileReducers.user,
      chat: ChatbotGetdata,
    }),
  );

  const [isActive, setIsActive] = useState(true);

  /*inital get chat*/
  useEffect(() => {
    // dispatch(chatbotdataget({}));
    dispatch(chatbotdataget({}))
      .then((response) => {
        setData(response.payload.data);
      })
      .catch((err) => {
        setData([]);
      });
  }, []);

  useEffect(() => {
    const cookieString = localStorage.getItem('count');

    if (cookieString) {
      setnotification(parseInt(cookieString));
    }
    const chat_feed = localStorage.getItem('chat_feed');
    if (chat_feed === 'true') {
      setFeedback(true);
    } else {
      setFeedback(false);
    }
  }, [notification]);
  const api_cal = () => {
    const val = localStorage.getItem('active');
    var next_message = parseInt(localStorage.getItem('counter'));
    const count = next_message;
    if (val === 'true') {
      dispatch(
        chatbottimermiddleware({
          count,
        }),
      ).then((res) => {
        if (res.payload.success === true) {
          chat_push(res.payload.count);
        }
      });
    }
  };

  useEffect(() => {
    // Scroll to the bottom when the chat component is opened
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [check, loader, data, minimize]);

  // Use the custom hook to call `api_cal` every 2 minute
  useApiInterval(api_cal, 5000, isActive);
  /* initial message set */
  const initial_setmessage = () => {
    const addmessage = [
      {
        data: 'To',
        result: 'Type your question below or choose from the options.',
        link: [],
        thumbs: null,
        support: false,
        message_type: true,
      },
    ];
    var newmessage = [...addmessage];
    setData(newmessage);
    setsuggestion(staticdata);
  };

  /* scroll function */

  const handleScroll = () => {
    const selectFlexScroll = document.getElementById('chatContainer');
    if (selectFlexScroll) {
      selectFlexScroll.scrollIntoView({ behavior: 'smooth' });
    }
  };

  /* chatbot open and close Function */
  const checks = (val, close) => {
    setcheck(val);
    if (close === 0) {
      if (data.length === 0) {
        initial_setmessage();
      }
      setnotification(0);
      setminimize(false);

      //cookies.set('count', '0', { path: '/' });
      localStorage.setItem('minimize', 'false');
      localStorage.setItem('count', '0');
    } else if (close === 1) {
      // setFeedback(false);
      // setData([]);
      // setMessage('');
      // setsuggestion([]);

      localStorage.setItem('minimize', 'true');
      setminimize(true);
    } else if (close === 2) {
      dispatch(chatbotdataget({ chatclose: true }))
        .then((response) => {
          setFeedback(false);
          localStorage.setItem('chat_feed', 'false');
          setData([]);
          setMessage('');
          setsuggestion([]);
          setminimize(false);
          setFeedback_modal(false);
          setFeed_backvalue('');
          setMessage('');
          setFeedback_data([]);
          seterror('');
          setInactive(false);
          localStorage.setItem('active', 'false');
          // cookies.set('count', '0', { path: '/' });
          localStorage.setItem('minimize', 'false');
          localStorage.setItem('count', '0');
        })
        .catch((err) => {
        });
    }
  };

  /* get Input onChange value */
  const getvalue = (e) => {
    setIsActive(false);
    const value = e.target.value.trim();
    localStorage.setItem('counter', '0');
    if (value.length === 0) {
      setMessage(value);
      validation(value);
    } else {
      setMessage(e.target.value);
      validation(e.target.value);
    }
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
    // setsuggestion([]);
    // clearInterval(intervalId1);
  };

  const validation = (value) => {
    if (value.length >= 2001) {
      seterror('Message length should not exceed 2000 characters');
    } else {
      seterror('');
    }
  };

  /*get feedback value*/
  const get_feedbackvalue = (e) => {
    const value = e.target.value.trim();

    if (value.length === 0) {
      setFeed_backvalue(value);
      validation(value);
    } else {
      setFeed_backvalue(e.target.value);
      validation(e.target.value);
    }
  };

  /*Get chatGpt value Api */
  const save = (val, end) => {
    setsuggestion([]);
    if (val.length !== 0) {
      if (textareaRef.current) {
        textareaRef.current.blur();
      }

      var c = [
        {
          data: 'From',
          result: val,
          link: null,
          thumbs: null,
          support: false,
          message_type: false,
        },
      ];

      const val1 = [...data, ...c];
      setData(val1);
      setloader(true);
      setMessage('');
      const formData = new FormData();
      var chat_data = [
        {
          user_id: user.id,
          message: val,
          chat: false,
          support: false,
          message_type: false,
        },
      ];

      formData.append('message', JSON.stringify(chat_data));

      dispatch(
        chatbotcheckmiddleware({
          formData,
        }),
      )
        .then((response) => {
          if (response.payload.success) {
            var a = [
              {
                data: 'To',
                result: response.payload.data.message,
                link: response.payload.data.link,
                thumbs: null,
                support: false,
                message_type: false,
                chat_id: response.payload.data.chat_id,
                thread: response.payload.data.thread,
              },
            ];
            const values = [...val1, ...a];
            setData(values);
            setloader(false);
            setsuggestion(response.payload.data.suggestions);
            const notification_check = localStorage.getItem('minimize');

            if (notification_check === 'true') {
              notification_sound();
              var count_change = parseInt(localStorage.getItem('count')) + 1;
              setnotification(count_change);
              // cookies.set('count', count_change.toString(), { path: '/' });
              localStorage.setItem('count', count_change.toString());
            }

            if (chatBoxRef.current) {
              chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
            }
            setInactive(true);
            // setcounter(0);
            setIsActive(true);
            localStorage.setItem('counter', '0');
            localStorage.setItem('active', 'true');
            localStorage.setItem('lastApiCall', Date.now().toString());

            // cookies.set('minimize', 'false', { path: '/' });
          }
        })
        .catch((err) => {
        });
    }
  };

  /* feedback save apicall */

  const savefeedback = (val) => {
    if (val.length !== 0) {
      if (textareaRef.current) {
        textareaRef.current.blur();
      }
      var c = [
        {
          data: 'From',
          result: val,
          link: [],
          thumbs: false,
        },
      ];
      const val1 = [...Feedback_data, ...c];
      setFeedback_data(val1);
      setFeed_backvalue('');
      // setloader(true);

      const formData = new FormData();
      var chat_data = {
        Feedback: val,
      };

      formData.append('feedback', val);

      dispatch(
        chatbotfeedbackmiddleware({
          formData,
        }),
      )
        .then((res) => {
          if (res.payload.success) {
            //setloader(false);
            // var result_feed = [
            //   {
            //     data: 'To',
            //     result: 'Thanks for your valuable feedback.',
            //     link: [],
            //     thumbs: false,
            //   },
            // ];
            // var result = [...val1, ...result_feed];
            // setFeedback_data(result);
            dispatch(chatbotdataget({ chatclose: true }))
              .then((response) => {
                if (response.payload.success) {
                  dispatch(chatbotdataget({ chatclose: true }))
                    .then((resp) => {
                      Toast(
                        'Your feedback has been submitted successfully.',
                        'SHORT',
                        'success',
                      );
                      setFeedback(false);
                      //  setData([]);
                      localStorage.setItem('chat_feed', 'false');
                      initial_setmessage();
                      // setsuggestion([]);
                      setminimize(false);
                      setFeedback_modal(false);
                      setFeed_backvalue('');
                      setMessage('');
                      setFeedback_data([]);
                      seterror('');
                      setInactive(false);
                      localStorage.setItem('active', 'false');
                      // cookies.set('minimize', 'false', { path: '/' });
                      localStorage.setItem('minimize', 'false');
                    })
                    .catch((err) => {
                    });
                }
              })
              .catch((err) => {
              });
          }
        })
        .catch((err) => {
        });
    }
  };

  /* Enter key press Api call*/
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (message.length >= 2001) {
        seterror('Message length should not exceed 2000 characters');
      } else {
        save(message, 0);
      }
    }
  };

  /* Onkeypress Feedback api call*/
  const feedbak_handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (message.length >= 2001) {
        seterror('Message length should not exceed 2000 characters');
      } else {
        savefeedback(Feed_backvalue);
      }
    }
  };

  /* Select suggestion Function */
  const select_suggestion = (value, ind) => {
    if (value === 'No, thanks') {
      localStorage.setItem('active', 'false');
      setFeedback(true);
      localStorage.setItem('chat_feed', 'true');
      var addmessage = [
        {
          data: 'From',
          result: 'No, thanks',
          link: [],
          thumbs: null,
          support: false,
          message_type: false,
        },
        {
          data: 'To',
          result: 'Please rate our support and send your Feedback.',
          link: [],
          thumbs: null,
          support: false,
          message_type: true,
        },
      ];
      const val1 = [...data, ...addmessage];
      setData(val1);
      localStorage.setItem('counter', '0');
    } else if (value === 'Ok, thanks') {
      localStorage.setItem('active', 'false');
      setFeedback(true);
      localStorage.setItem('chat_feed', 'true');
      var addmessage1 = [
        {
          data: 'From',
          result: 'Ok, thanks',
          link: [],
          thumbs: null,
          support: false,
          message_type: false,
        },
        {
          data: 'To',
          result: 'Please rate our support and send your Feedback.',
          link: [],
          thumbs: null,
          support: false,
          message_type: true,
        },
      ];
      const val1 = [...data, ...addmessage1];
      setData(val1);
      localStorage.setItem('counter', '0');
    } else {
      setsuggestion([]);
      save(value, 1);
    }
  };

  /*cancel for feedbackform and contonue chat */
  const Chat_continue = () => {
    localStorage.setItem('active', 'true');
    localStorage.setItem('counter', '0');
    setsuggestion([]);
    setFeedback(false);
    localStorage.setItem('chat_feed', 'false');
    localStorage.setItem('lastApiCall', Date.now().toString());
    chat_continue();
  };

  const thumb_ok = () => {
    var start_data = [
      {
        data: 'To',
        result: 'Did I help you with what you needed?',
        link: [],
        thumb: false,
      },
      {
        data: 'From',
        result: 'yes',
        link: [],
        thumb: true,
      },
      {
        data: 'To',
        result:
          'Would you like to provide more detailed feedback? submit your feedback in the below section',
        link: [],
        thumb: false,
      },
    ];

    if (Feedback_data.length === 0) {
      setFeedback_data(start_data);
    }
    setFeedback_modal(true);
    seterror('');
  };

  const handle_backchat = () => {
    setFeedback_modal(false);
  };

  /*notification sound */
  const notification_sound = () => {
    //  const audio = new Audio(Sound);
    try {
      const audio = new Audio(
        'https://d2uv78z986v35y.cloudfront.net/notificationsound.mp3',
      );
      audio
        .play()
        .then(() => {
        })
        .catch((errors) => {
        });
    } catch (er) {
    }
  };

  /* 2minitues later chat push */

  const chat_push = (count) => {
    // var next_message = parseInt(localStorage.getItem('counter')) + 1;

    if (count === 1) {
      const newmessage = [
        {
          user_id: 0,
          message:
            "It seems you've finished your questions for now. Is there anything else I can help you with?",
          link: [],
          thumbs: null,
          support: false,
          message_type: true,
        },
      ];
      const formData = new FormData();

      formData.append('message', JSON.stringify(newmessage));
      const addmessage = [
        {
          data: 'To',
          result:
            "It seems you've finished your questions for now. Is there anything else I can help you with?",
          link: [],
          thumbs: null,
          support: false,
          message_type: true,
        },
      ];

      var latermessage = [...data, ...addmessage];

      dispatch(
        chatbotcheckmiddleware({
          formData,
        }),
      )
        .then((response) => {
          if (response.payload.success) {
            setData(latermessage);
          }
        })
        .catch((err) => {});
    } else if (count === 2) {
      const newmessage = [
        {
          user_id: 0,
          message: 'Hi there, just checking in. Are you still there?',
          link: [],
          thumbs: null,
          support: false,
          message_type: true,
        },
      ];
      const formData = new FormData();

      formData.append('message', JSON.stringify(newmessage));
      const addmessage = [
        {
          data: 'To',
          result: 'Hi there, just checking in. Are you still there?',
          link: [],
          thumbs: null,
          support: false,
          message_type: true,
        },
      ];

      var latermessage1 = [...data, ...addmessage];

      dispatch(
        chatbotcheckmiddleware({
          formData,
        }),
      )
        .then((response) => {
          if (response.payload.success) {
            setData(latermessage1);
          }
        })
        .catch((err) => {});
    } else if (count === 3) {
      const newmessage = [
        {
          user_id: 0,
          message:
            "Looks like you're stuck! Don't worry, our support team can assist you. Just email them at",
          link: [],
          thumbs: null,
          support: true,
          message_type: true,
        },
      ];
      const formData = new FormData();

      formData.append('message', JSON.stringify(newmessage));
      const addmessage = [
        {
          data: 'To',
          result:
            "Looks like you're stuck! Don't worry, our support team can assist you. Just email them at",
          link: [],
          thumbs: null,
          support: true,
          message_type: true,
        },
      ];

      var latermessage2 = [...data, ...addmessage];

      dispatch(
        chatbotcheckmiddleware({
          formData,
        }),
      )
        .then((response) => {
          if (response.payload.success) {
            setData(latermessage2);
          }
        })
        .catch((err) => {});
      setInactive(false);
      localStorage.setItem('active', 'false');
      setFeedback(true);
      localStorage.setItem('chat_feed', 'true');
      // localStorage.setItem('minimize', 'false');
    }

    // setcounter(counter + 1);

    localStorage.setItem('counter', count.toString());
    const notification_check = localStorage.getItem('minimize');
    if (notification_check === 'true') {
      notification_sound();
      var count_change = parseInt(localStorage.getItem('count')) + 1;
      setnotification(count_change);
      // cookies.set('count', count_change.toString(), { path: '/' });
      localStorage.setItem('count', count_change.toString());
    }
    localStorage.setItem('lastApiCall', Date.now().toString());
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  /*chat continue */

  const chat_continue = () => {
    const newmessage = [
      {
        user_id: 0,
        message: 'Welcome back! How can I assist you further today?',
        link: [],
        thumbs: null,
        support: false,
        message_type: true,
      },
    ];
    const formData = new FormData();

    formData.append('message', JSON.stringify(newmessage));
    const addmessage = [
      {
        data: 'To',
        result: 'Welcome back! How can I assist you further today?',
        link: [],
        thumbs: null,
        support: false,
        message_type: true,
      },
    ];
    var latermessage = [...data, ...addmessage];

    dispatch(
      chatbotcheckmiddleware({
        formData,
      }),
    )
      .then((response) => {
        if (response.payload.success) {
          setData(latermessage);
          setsuggestion([]);
          setInactive(false);
          // localStorage.setItem('active', 'false');
        }
      })
      .catch((err) => {});
  };

  /* update manual new message in back end */

  const update_message = (value) => {
    const newmessage = [
      {
        user_id: 0,
        message:
          "Looks like you're stuck! Don't worry, our support team can assist you. Just email them at",
        link: [],
        thumbs: null,
        support: true,
        message_type: true,
      },
      {
        user_id: 0,
        message:
          "Stuck and need a hand? We've got you covered on our Contact Us page:",
        link: ['https://www.zita.ai/contact-us'],
        thumbs: null,
        support: false,
        message_type: true,
      },
    ];

    const formData = new FormData();

    formData.append('message', JSON.stringify(newmessage));

    const addmessage = [
      {
        data: 'To',
        result:
          "Looks like you're stuck! Don't worry, our support team can assist you. Just email them at",
        link: [],
        thumbs: null,
        support: true,
        message_type: true,
      },
      {
        data: 'To',
        result:
          "Stuck and need a hand? We've got you covered on our Contact Us page:",
        link: ['https://www.zita.ai/contact-us'],
        thumbs: null,
        support: false,
        message_type: true,
      },
    ];

    dispatch(
      chatbotcheckmiddleware({
        formData,
      }),
    )
      .then((response) => {
        if (response.payload.success) {
          setsuggestion(intervaldata);
          setData((prevArray) => {
            return prevArray
              .map((chats) => {
                if (chats.chat_id === value.chat_id) {
                  return { ...chats, thumbs: 1 };
                }
                return chats;
              })
              .concat(addmessage); // Concatenate new messages array with updated array
          });
        }
      })
      .catch((err) => {});
  };

  /*thumbs  api call*/

  const thumb_cancel = (index, value) => {
    const counts = data.reduce(
      (count, chats) => count + (chats.thumbs === 1 ? 1 : 0),
      0,
    );
    if (counts >= 2) {
      setInactive(false);
      localStorage.setItem('active', 'false');
      dispatch(
        chatbotdataget({
          chat_id: value.chat_id,
          thread: value.thread,
          thumb: 1,
        }),
      )
        .then((res) => {
          if (res.payload.success) {
            update_message(value);
          } else {
            return false;
          }
        })
        .catch((err) => {
        });
    } else {
      dispatch(
        chatbotdataget({
          chat_id: value.chat_id,
          thread: value.thread,
          thumb: 1,
        }),
      )
        .then((res) => {
          if (res.payload.success) {
            setData((prevArray) => {
              return prevArray.map((chats) => {
                if (chats.chat_id === value.chat_id) {
                  return { ...chats, thumbs: 1 };
                }
                return chats;
              });
            });
          } else {
            return false;
          }
        })
        .catch((err) => {
        });
    }
    setsuggestion([]);
  };

  const thumb_close = () => {
    dispatch(chatbotdataget({ chatclose: true }))
      .then((response) => {
        Toast("We're improving! Share next time.", 'SHORT', 'success');
        setFeedback(false);
        localStorage.setItem('chat_feed', 'false');
        dispatch(chatbotdataget({}));
        //setData([]);
        initial_setmessage();
        setMessage('');
        //setsuggestion([]);
        setminimize(false);

        setFeedback_modal(false);
        setFeed_backvalue('');
        setMessage('');
        setFeedback_data([]);
        seterror('');
        setInactive(false);
        localStorage.setItem('active', 'false');
        // cookies.set('minimize', 'false', { path: '/' });
        localStorage.setItem('minimize', 'false');
      })
      .catch((err) => {
      });
  };

  const accpet_thumb = async (index, value) => {
    dispatch(
      chatbotdataget({
        chat_id: value.chat_id,
        thread: value.thread,
        thumb: 0,
      }),
    )
      .then((res) => {
        if (res.payload.success) {
          setData((prevArray) => {
            return prevArray.map((chats) => {
              if (chats.chat_id === value.chat_id) {
                return { ...chats, thumbs: 0 };
              }
              return chats;
            });
          });
        } else {
          return false;
        }
      })
      .catch((err) => {
      });
  };

  const contect_mail = (e) => {
    e.preventDefault();
    const toAddress = 'support@zita.ai';
    const mailtoLink = `mailto:${toAddress}`;
    try {
      window.open(mailtoLink, '_blank');
    } catch (err) {}
  };

  const setfunction = () => {
    return (
      <>
        {Feedback_modal === true ? (
          <>
            <Flex
              marginTop={10}
              title="chat close"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                checks(false, 2);
              }}
            >
              {' '}
              <Svgchatbotclose width={12} height={12} />
            </Flex>
          </>
        ) : (
          <>
            {' '}
            <Flex
              marginTop={6}
              style={{ cursor: 'pointer' }}
              title="Minimize Chat"
              onClick={() => {
                checks(false, 1);
              }}
            >
              {' '}
              <Svgchatbotclose width={12} height={12} />
            </Flex>
          </>
        )}
      </>
    );
  };

  return (
    <div className={styles.overall}>
      <div className={styles.toggle}>
        {check === false ? (
          <>
            <button
              className={styles.chat_button}
              onClick={() => {
                checks(true, 0);
              }}
            >
              Chat with us{' '}
              {notification !== 0 ? (
                <span className={styles.notification_badge}>
                  {notification}
                </span>
              ) : (
                ''
              )}
            </button>
          </>
        ) : (
          ''
        )}
      </div>
      {Feedback_modal === true ? (
        <>
          <div
            className={styles.chat_bot}
            style={{ height: isvalidate.length === 0 ? '580px' : '650px' }}
          >
            <Flex
              row
              between
              className={styles.heading}
              style={{ cursor: 'pointer' }}
            >
              {/* <Flex
                marginTop={8}
                marginRight={10}
                onClick={() => handle_backchat()}
              >
                <SvgArrowLeft fill={'White'} height={20} width={20} />
              </Flex> */}

              <Text size={16} color="white" style={{ fontWeight: '700' }}>
                Rate Your Experience
              </Text>

              <Flex
                marginTop={6}
                title="chat close"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  checks(false, 2);
                }}
              >
                {' '}
                <Svgchatbotclose width={12} height={12} />
              </Flex>
            </Flex>

            <ul
              className={styles.chat_box}
              style={{
                minHeight:
                  Feedback === true
                    ? '465px'
                    : suggestion.length === 0
                    ? '435px'
                    : suggestion.length === 1
                    ? '415px'
                    : '60%',
              }}
              ref={chatBoxRef}
            >
              {Feedback_data?.map((value, index) => (
                <Flex key={index}>
                  {value.data === 'From' ? (
                    <>
                      <li className={styles.chat}>
                        <div className={styles.feedback_out}>
                          <p
                            className={styles.fromvalue}
                            style={{ fontSize: '12px !important' }}
                          >
                            {' '}
                            {value.thumb === true ? (
                              <>
                                <SvgThumbsup
                                  fill={'#581845'}
                                  width={17}
                                  height={18}
                                />
                              </>
                            ) : (
                              ''
                            )}{' '}
                            {''}
                            {value.result}
                          </p>
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className={styles.chat}>
                        <div className={styles.feedback_income}>
                          <span
                            className={styles.img_span}
                            style={{ margin: '0px 1px 13px 0px' }}
                          >
                            <img alt="zita" src={zita_img} />
                          </span>
                          <p
                            className={styles.feedback_tovalue}
                            style={{ fontSize: '12px !important' }}
                          >
                            {value.result}
                          </p>
                        </div>
                      </li>
                    </>
                  )}
                </Flex>
              ))}
              {loader && (
                <li className={styles.chat}>
                  <div className={styles.feedback_income}>
                    <span
                      className={styles.img_span}
                      style={{
                        margin: '0px 1px 13px 0px',
                      }}
                    >
                      <img alt="zita" src={zita_img} />
                    </span>
                    <p
                      style={{ padding: '15px 35px', marginLeft: '5px' }}
                      className={styles.loader}
                    >
                      <div className={styles.dot_typing}></div>
                    </p>
                  </div>
                </li>
              )}
            </ul>

            <Flex
              className={styles.footer}
              disabled={loader === true ? true : false}
              row
              between
              center
            >
              <Flex width={300} style={{ marginBottom: '3px' }}>
                <textarea
                  className={
                    error.length === 0
                      ? styles.customtextarea1
                      : styles.customtextarea
                  }
                  placeholder="submit your Feedback"
                  value={Feed_backvalue}
                  onChange={(e) => get_feedbackvalue(e)}
                  onKeyPress={(e) => feedbak_handleKeyPress(e)}
                  ref={textareaRef}
                />
              </Flex>
              <Flex
                title={
                  Feed_backvalue?.trim()?.length > 2000 &&
                  'maximum limit of 2000 characters.'
                }
                onClick={() => {
                  if (!(Feed_backvalue?.trim()?.length > 2000)) {
                    savefeedback(Feed_backvalue);
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                <Svgchatbotsendicon
                  fill={
                    message?.trim()?.length > 2000
                      ? 'rgb(88 24 69/10%)'
                      : '#581845'
                  }
                />
              </Flex>
            </Flex>
            <Flex
              className={styles.error}
              style={{
                color: 'red',
                marginLeft: '20px',
                marginBottom: '3px',
                fontSize: '12px',
              }}
            >
              {error}
            </Flex>
          </div>
        </>
      ) : (
        <>
          {check === true ? (
            <>
              <div
                className={styles.chat_bot}
                style={{ height: isvalidate.length === 0 ? '580px' : '650px' }}
              >
                <Flex className={styles.heading}>
                  <Flex row between>
                    <Flex style={{ with: '32px' }}>
                      <Text
                        size={16}
                        color="white"
                        title={
                          user?.username.length >= 20
                            ? user?.username.toLocaleLowerCase()
                            : ''
                        }
                        style={{
                          whiteSpace: 'nowrap',
                          width: '310px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: '700',
                        }}
                      >
                        Hi {user?.username.toLocaleLowerCase()}!
                      </Text>
                    </Flex>

                    {setfunction()}
                  </Flex>
                  <Text size={14} color="white">
                    How can I help you today?
                  </Text>
                </Flex>
                <Flex id="chatContainer">
                  <ul
                    className={styles.chat_box}
                    style={{
                      height:
                        Feedback === true
                          ? '365px'
                          : suggestion.length === 0
                          ? '445px'
                          : suggestion.length === 1
                          ? '410px'
                          : '375px',
                    }}
                    ref={chatBoxRef}
                  >
                    {data?.map((value, index) => (
                      <Flex key={index}>
                        {value.data === 'From' ? (
                          <>
                            <li className={styles.chat}>
                              <div className={styles.out}>
                                <Text className={styles.fromvalue} size={12}>
                                  {value.result}
                                </Text>
                              </div>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className={styles.chat}>
                              <div className={styles.income}>
                                <span
                                  className={styles.img_span}
                                  style={{
                                    margin:
                                      data?.length === index + 1
                                        ? value.message_type === true // Check another condition
                                          ? '0px 5px 0px 0px' // Set margin based on another condition
                                          : '0px 5px 20px 0px'
                                        : value.thumbs === null
                                        ? '0px 5px 0px 0px'
                                        : '0px 5px 20px 0px',
                                  }}
                                >
                                  <img alt="zita" src={zita_img} />
                                </span>
                                <Flex style={{ maxWidth: '250px' }}>
                                  <Flex className={styles.tovalue}>
                                    <Text size={12}>
                                      {value.result}{' '}
                                      {value.support === true && (
                                        <span
                                          style={{
                                            color: '#581845',
                                            cursor: 'pointer',
                                            fontSize: '12px !important',
                                          }}
                                        >
                                          <Text
                                            onClick={(e) => contect_mail(e)}
                                            color="theme"
                                            bold
                                            style={{
                                              textDecoration: 'underline',
                                              fontSize: '12px !important',
                                            }}
                                          >
                                            {'support@zita.ai'}
                                          </Text>
                                        </span>
                                      )}
                                    </Text>
                                    {value.link.length !== 0 && (
                                      <>
                                        {value.link.map((links, int) => (
                                          <>
                                            <Flex
                                              row
                                              //  style={{ width: '200px' }}
                                            >
                                              <Flex
                                                marginTop={4}
                                                marginRight={2}
                                              >
                                                <SvgshareIcon
                                                  width={16}
                                                  height={16}
                                                />{' '}
                                              </Flex>
                                              <Flex>
                                                <a
                                                  href={links}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  style={{
                                                    color: '#581845',
                                                    textDecoration: 'underline',
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    width: '200px',
                                                  }}
                                                >
                                                  {links}
                                                </a>
                                              </Flex>
                                            </Flex>
                                          </>
                                        ))}
                                      </>
                                    )}
                                  </Flex>

                                  {value.thumbs === null ? (
                                    <>
                                      {value.message_type === false ? (
                                        <>
                                          {data.length === index + 1 && (
                                            <>
                                              <Flex
                                                className={styles.helps}
                                                row
                                              >
                                                <Text
                                                  style={{ fontSize: '12px' }}
                                                >
                                                  {' '}
                                                  Helpful ?{' '}
                                                </Text>
                                                <Flex
                                                  marginLeft={5}
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() =>
                                                    accpet_thumb(index, value)
                                                  }
                                                >
                                                  {' '}
                                                  <SvgThumbsup
                                                    fill={'#581845'}
                                                    width={14}
                                                    height={14}
                                                    viewbox="0 0 18 18"
                                                  />
                                                </Flex>

                                                <Flex
                                                  marginLeft={5}
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() =>
                                                    thumb_cancel(index, value)
                                                  }
                                                >
                                                  <SvgThumbsdown
                                                    fill={'#581845'}
                                                    width={14}
                                                    height={14}
                                                    viewbox="0 0 18 18"
                                                  />
                                                </Flex>
                                              </Flex>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {value.thumbs === 0 && (
                                        <>
                                          <Flex
                                            className={styles.helps_thumbs}
                                            row
                                          >
                                            <SvgThumbsup
                                              fill={'#581845'}
                                              width={14}
                                              height={14}
                                              viewbox="0 0 18 18"
                                            />
                                          </Flex>
                                        </>
                                      )}
                                      {value.thumbs === 1 && (
                                        <>
                                          <Flex
                                            className={styles.helps_thumbs}
                                            row
                                          >
                                            <SvgThumbsdown
                                              fill={'#581845'}
                                              width={14}
                                              height={14}
                                              viewbox="0 0 18 18"
                                            />
                                          </Flex>
                                        </>
                                      )}
                                    </>
                                  )}
                                </Flex>
                              </div>
                            </li>
                          </>
                        )}
                      </Flex>
                    ))}
                    {loader && (
                      <li className={styles.chat}>
                        <div className={styles.income}>
                          <span
                            className={styles.img_span}
                            style={{
                              margin: '0px 1px 13px 0px',
                            }}
                          >
                            <img alt="zita" src={zita_img} />
                          </span>
                          <p
                            style={{ padding: '15px 35px', marginLeft: '5px' }}
                            className={styles.loader}
                          >
                            <div className={styles.dot_typing}></div>
                          </p>
                        </div>
                      </li>
                    )}
                  </ul>
                </Flex>

                {Feedback === false ? (
                  <>
                    {suggestion?.length !== 0 ? (
                      <>
                        <Flex
                          row
                          style={{
                            flexWrap: 'wrap',
                            padding: '1px 10px',
                            overflow: 'scroll',
                            height: '74px',
                          }}
                        >
                          {suggestion.map((value, index) => (
                            <Flex
                              key={index}
                              className={styles.suggestion_Text}
                              onClick={() => select_suggestion(value, index)}
                            >
                              <Text style={{ fontSize: '12px' }}> {value}</Text>
                            </Flex>
                          ))}
                        </Flex>
                      </>
                    ) : (
                      ''
                    )}

                    <Flex
                      className={
                        error.length === 0 ? styles.footer : styles.err_footer
                      }
                      disabled={loader === true ? true : false}
                      row
                      between
                      center
                    >
                      <Flex width={300}>
                        <textarea
                          style={{
                            outline: 'none !important', // Use !important to override other styles
                          }}
                          className={
                            error.length === 0
                              ? styles.customtextarea1
                              : styles.customtextarea
                          }
                          placeholder="Type your message here…"
                          value={message}
                          onChange={(e) => getvalue(e)}
                          onKeyPress={(e) => handleKeyPress(e)}
                          ref={textareaRef}
                        />
                      </Flex>
                      <Flex
                        title={
                          error.length === 0
                            ? 'Send'
                            : 'maximum limit of 2000 characters'
                        }
                        onClick={() => {
                          if (!(message?.trim()?.length > 2000)) {
                            save(message, 0);
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <Svgchatbotsendicon
                          fill={
                            message?.trim()?.length > 2000
                              ? 'rgb(88 24 69/10%)'
                              : '#581845'
                          }
                        />
                      </Flex>
                    </Flex>
                    <Flex
                      className={styles.error}
                      style={{
                        color: 'red',
                        marginLeft: '20px',
                        marginBottom: '3px',
                        fontSize: '12px',
                      }}
                    >
                      {error}
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex className={styles.feed_back}>
                      <Flex center middle style={{ padding: '10px 0px' }}>
                        <Text color="gray">
                          This conversation has been closed
                        </Text>
                      </Flex>
                      <Flex
                        style={{
                          backgroundColor: '#D7C7D2',
                          padding: '10px 5px',
                        }}
                      >
                        <Flex middle center style={{ fontSize: '12px' }}>
                          Did I help you with what you needed?
                        </Flex>
                        <Flex row middle marginTop={10}>
                          <Flex
                            onClick={() => thumb_ok()}
                            style={{ cursor: 'pointer' }}
                          >
                            <Svgchatbotthumbsup />
                          </Flex>
                          <Flex width={10}></Flex>
                          <Flex
                            onClick={() => thumb_close()}
                            style={{ cursor: 'pointer' }}
                          >
                            <Svgchatbotthumbsdown />
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex
                        row
                        center
                        middle
                        style={{
                          padding: '10px 0px',
                        }}
                      >
                        <Text color="gray" style={{ fontSize: '12px' }}>
                          {' '}
                          Still want to know more?{' '}
                        </Text>

                        <Text
                          color="theme"
                          onClick={() => Chat_continue()}
                          style={{
                            marginLeft: '2px',
                            cursor: 'pointer',
                            fontSize: '12px',
                          }}
                          bold
                        >
                          Chat with us
                        </Text>
                      </Flex>
                    </Flex>
                  </>
                )}
              </div>
            </>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default Chatbot;
