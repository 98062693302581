import * as React from 'react';
const SvgManageUsers = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    viewBox='0 0 25 25'
    {...props}
  >
    <path
      fill="#581845"
      d="M17.5 24.15v.833h.833v-.833zm-16.667 0H0v.833h.833zm23.334.017V25H25v-.833zM13.333 5.83a4.165 4.165 0 0 1-4.166 4.163v1.667A5.83 5.83 0 0 0 15 5.83zM9.167 9.993A4.165 4.165 0 0 1 5 5.83H3.333a5.83 5.83 0 0 0 5.834 5.83zM5 5.83a4.165 4.165 0 0 1 4.167-4.163V0a5.83 5.83 0 0 0-5.834 5.83zm4.167-4.163a4.167 4.167 0 0 1 4.166 4.163H15A5.833 5.833 0 0 0 9.167 0zm8.333 21.65H.833v1.666H17.5zm-15.833.833v-3.325H0v3.325zm4.166-7.493H12.5V14.99H5.833zm10.834 4.166v3.327h1.666v-3.327zM12.5 16.657a4.167 4.167 0 0 1 4.167 4.166h1.666A5.833 5.833 0 0 0 12.5 14.99zM1.667 20.825a4.17 4.17 0 0 1 4.166-4.168V14.99A5.834 5.834 0 0 0 0 20.825zm21.666.842v2.5H25v-2.5zm.834 1.666H20V25h4.167zm-4.167-5a3.333 3.333 0 0 1 3.333 3.334H25a5 5 0 0 0-5-5zm-.833-5a2.5 2.5 0 0 1-2.5-2.5H15A4.167 4.167 0 0 0 19.167 15zm2.5-2.5a2.5 2.5 0 0 1-2.5 2.5V15a4.167 4.167 0 0 0 4.166-4.167zm-2.5-2.5a2.5 2.5 0 0 1 2.5 2.5h1.666a4.167 4.167 0 0 0-4.166-4.166zm0-1.666A4.167 4.167 0 0 0 15 10.833h1.667a2.5 2.5 0 0 1 2.5-2.5z"
    />
  </svg>
);
export default SvgManageUsers;