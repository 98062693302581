import * as React from 'react';

const defaultProps = {
  fill: 'green',
  width: 17,
  height: 18,
  viewbox: '0 0 18 18',
};

const SvgThumbsup = ({ width, height, fill, viewbox }: typeof defaultProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewbox}
    >
      <path d="M15.57 6.676c-.391-.144-2.512-.406-3.883-.56C11.902 4.861 12 3.711 12 2.5 12 1.121 10.878 0 9.5 0A2.503 2.503 0 0 0 7 2.5c0 1.875-.666 2.738-1.616 3.699A2.99 2.99 0 0 0 3 5C1.346 5 0 6.346 0 8v6c0 1.654 1.346 3 3 3 .755 0 1.438-.29 1.965-.752l.188.193C6.113 17.177 8.82 18 11.001 18c1.879 0 2.608-.293 3.253-.553l.316-.123c.834-.305 1.576-1.227 1.736-2.2l.666-5.974c.173-1.037-.443-2.125-1.402-2.474ZM3 15c-.551 0-1-.448-1-1V8a1.001 1.001 0 0 1 2 0v6c0 .552-.449 1-1 1Zm11.327-.15c-.037.224-.292.541-.443.596l-.376.146c-.545.219-1.016.408-2.508.408-1.914 0-4.118-.753-4.632-1.146C6.21 14.734 6 14.29 6 14V9.02c.003-.047.051-.656.707-1.312C7.62 6.794 9 5.414 9 2.5c0-.275.225-.5.5-.5s.5.225.5.5c0 1.407-.146 2.73-.479 4.293l-.297 1.396 1.321-.188c.603.05 3.933.447 4.334.55.058.03.132.183.111.323l-.663 5.976Z" />
    </svg>
  );
};
SvgThumbsup.defaultProps = defaultProps;
export default SvgThumbsup;
