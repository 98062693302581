import { useEffect, useRef, useState } from 'react';
import { getColor } from '../colors';
import { formatEventTitle } from '../util';
import styles from '../styles/CalendarComponents.module.css';
import { Flex } from '../../../uikit';


const ColorEvent = ({ event }) => {
  const [lineClamp, setLineClamp] = useState(1);
  const eventRef = useRef(null);

  // truncate the event title based on event occupying height.
  useEffect(() => {
    const calculateLineClamp = () => {
      const element = eventRef.current;
      if (element) {
        const parentElement = element.closest('.rbc-event'); // Find the closest parent .rbc-event div
        if (parentElement) {
          const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
          const containerHeight = parentElement.clientHeight; // Get the height of the parent .rbc-event div
          const numberOfLines = Math.floor(containerHeight / lineHeight);
          if (numberOfLines === 1) {
            setLineClamp(numberOfLines);
          }
          else {
            setLineClamp(numberOfLines);
          }
        }
      }
    };

    calculateLineClamp(); // Initial calculation
    window.addEventListener('resize', calculateLineClamp); // Recalculate on resize

    return () => {
      window.removeEventListener('resize', calculateLineClamp); // Clean up on unmount
    };
  }, [event]);


  let color = getColor(event.userId);
  return (
    <div style={{
      backgroundColor: color.backgroundColor,
      borderLeft: `4px solid ${color.borderColor}`,
      color: 'black',
      height: '100%',
      marginBottom: 0,
      padding: '5px',
      zIndex: 100,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Flex
        style={{
          width: 'fit-content'
        }}
      >
        <Flex title={formatEventTitle(event)}>
          <div
            ref={eventRef}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: lineClamp,
              WebkitBoxOrient: 'vertical',
              width: 'fit-content',
            }}
          >
            {formatEventTitle(event)}
          </div>
        </Flex>
      </Flex>
    </div>
  );
};

export default ColorEvent;