import React, { useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { Button, Flex, Text } from '../../uikit';
import styles from './tour.module.css'

type Props = {
    previous?: any;
    finish?: any;
    types?: any;
    text?: any;
    top?: any;
    right?: any;
    bottom?: any;
    left?: any;
    skiponclick?: any;
    previousonclick?: any;
    nextonclick?: any;
    process?: any;
    borderRight?:any;
    borderTop?:any;
};

const Tour = ({
    previous,
    finish,
    types,
    text,
    top,
    right,
    bottom,
    left,
    skiponclick,
    previousonclick,
    nextonclick,
    process,
    borderRight,
    borderTop,
}: Props) => {
    const arrowClass = types && styles[types];

    const splitText = (texts) => {
        return texts.split(/(?<=\.\s)/);
    };

    const points = text ? splitText(text) : [];

    const sanitizeHTML = (html) => {
        return { __html: html };
    };

    const sanitizedHTML = points.map((point, index) => (
        <div style={{ fontSize: '13px', marginBottom: 5 }} key={index} dangerouslySetInnerHTML={sanitizeHTML(point)} />
    ));

    return (
        <Flex className={styles.overlaybackground}>
            <Flex style={{ top: top, left: left, right: right, bottom: bottom,borderRight:borderRight?"5px solid #581845":'',borderTop:borderTop?"5px solid #581845":'' }} className={`${styles.box} ${arrowClass}`}>
                {/* <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} /> */}
                <div>{sanitizedHTML}</div>
                <Flex row between marginTop={15} style={{ justifyContent: finish ? 'end' : 'space-between' }}>
                    {!finish && (<Flex>
                        {/* <Button types='secondary' onClick={skiponclick}>Skip</Button> */}
                    </Flex>)}
                    <Flex row >
                        {previous && (<Button types='secondary' onClick={previousonclick} style={{ marginRight: '5px' }}>Back</Button>)}
                        <Button onClick={nextonclick}>

                            {finish ? (process() !== null ? (`Roger That ${process()}`) : (`Roger That`)) : (process() !== null ? (`Next ${process()}`) : (`Next`))}
                        </Button>
                    </Flex>


                </Flex>
            </Flex>
        </Flex>
    )
}
export default Tour;