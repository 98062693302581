import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import SvgGmail from '../../../icons/SvgGmail';
import SvgTwitterIcon from '../../../icons/SvgTwitterIcon';
import CustomTooltip from '../../../uikit/Tooltip/tooltip';
import { JdForm } from './buildCareerPageTypes';
import styles from './sharebutton.module.css';

type Props = {
  url: string;
  jd_form?: JdForm;
};
const ShareButton = ({ url, jd_form }: Props) => {
  return (
    <div className={styles.overAll}>
      <CustomTooltip title="Share on Facebook" direction_tooltip="right">
        <div>
          <FacebookShareButton
            url={`${url}#.facebook`}
            quote={jd_form?.job_title}
          >
            <FacebookIcon size={32} />
          </FacebookShareButton>
        </div>
      </CustomTooltip>
      <CustomTooltip title="Share on X(Twitter)" direction_tooltip="right">
        <div>
          <TwitterShareButton url={`${url}#.twitter`}>
            <SvgTwitterIcon height={32} width={32} viewBox="3 3 18 18" />
          </TwitterShareButton>
        </div>
      </CustomTooltip>
      <CustomTooltip title="Share on LinkedIn" direction_tooltip="right">
        <div>
          <LinkedinShareButton url={`${url}#.linkedin`}>
            <LinkedinIcon size={32} />
          </LinkedinShareButton>
        </div>
      </CustomTooltip>
      <CustomTooltip title="Share via Gmail" direction_tooltip="right">
        <div>
          <a
            rel="noreferrer"
            target={'_blank'}
            href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=&su=${encodeURIComponent(
              jd_form?.job_title,
            )}&body=${encodeURIComponent(url + '#.gmailView')}`}
          >
            <SvgGmail height={32} width={31} />
          </a>
        </div>
      </CustomTooltip>
      <CustomTooltip title="Share on WhatsApp" direction_tooltip="right">
        <div>
          <WhatsappShareButton url={`${url}#.whatsapp`}>
            <WhatsappIcon size={32} />
          </WhatsappShareButton>
        </div>
      </CustomTooltip>
    </div>
  );
};

export default ShareButton;
