import moment from "moment";

// checking the date and time to evaluate
export const verifyDate = (newdate) => {
    const expirationDate = moment(newdate);
    const currentDate = moment();
    if (currentDate.isAfter(expirationDate)) {
      return true
    } else {
      return false
    }
  }