const Svgchatbotthumbsup = (props) => (
    <svg width="46" height="19" viewBox="0 0 46 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="45" height="18" rx="2.5" stroke="#581845" />
        <path d="M15.38 8.45067C15.1193 8.35467 13.7053 8.18 12.7913 8.07733C12.9347 7.24067 13 6.474 13 5.66667C13 4.74733 12.252
 4 11.3333 4C10.4147 4 9.66667 4.74733 9.66667 5.66667C9.66667 6.91667 9.22267 7.492 8.58933 8.13267C8.40441 7.88545 8.16459
  7.68457 7.88879 7.54586C7.61298 7.40715 7.30872 7.33439 7 7.33333C5.89733 7.33333 5 8.23067 5 9.33333V13.3333C5 14.436 5.89733
   15.3333 7 15.3333C7.50333 15.3333 7.95867 15.14 8.31 14.832L8.43533 14.9607C9.07533 15.4513 10.88 16 12.334 16C13.5867 16 
   14.0727 15.8047 14.5027 15.6313L14.7133 15.5493C15.2693 15.346 15.764 14.7313 15.8707 14.0827L16.3147 10.1C16.43 9.40867 
   16.0193 8.68333 15.38 8.45067ZM7 14C6.63267 14 6.33333 13.7013 6.33333 13.3333V9.33333C6.34101 9.16168 6.4146 8.9996 6.53879
    8.88085C6.66297 8.7621 6.82818 8.69582 7 8.69582C7.17182 8.69582 7.33703 8.7621 7.46121 8.88085C7.5854 8.9996 7.65899 9.16168
     7.66667 9.33333V13.3333C7.66667 13.7013 7.36733 14 7 14ZM14.5513 13.9C14.5267 14.0493 14.3567 14.2607 14.256 14.2973L14.0053
      14.3947C13.642 14.5407 13.328 14.6667 12.3333 14.6667C11.0573 14.6667 9.588 14.1647 9.24533 13.9027C9.14 13.8227 9 13.5267
       9 13.3333V10.0133C9.002 9.982 9.034 9.576 9.47133 9.13867C10.08 8.52933 11 7.60933 11 5.66667C11 5.48333 11.15 5.33333
        11.3333 5.33333C11.5167 5.33333 11.6667 5.48333 11.6667 5.66667C11.6667 6.60467 11.5693 7.48667 11.3473 8.52867L11.1493
         9.45933L12.03 9.334C12.432 9.36733 14.652 9.632 14.9193 9.70067C14.958 9.72067 15.0073 9.82267 14.9933 9.916L14.5513 
         13.9Z" fill="#581845" />
        <path d="M23.2676 5.46875L25.0488 9.35938L26.8301 5.46875H28.7461L25.9395 10.8945V14H24.1582V10.8945L21.3457 
5.46875H23.2676ZM31.9512 14.1172C31.459 14.1172 31.0176 14.0391 30.627 13.8828C30.2363 13.7227 29.9043 13.502 29.6309 
13.2207C29.3613 12.9395 29.1543 12.6133 29.0098 12.2422C28.8652 11.8672 28.793 11.4688 28.793 11.0469V10.8125C28.793 10.332
 28.8613 9.89258 28.998 9.49414C29.1348 9.0957 29.3301 8.75 29.584 8.45703C29.8418 8.16406 30.1543 7.93945 30.5215 7.7832C30.8887
  7.62305 31.3027 7.54297 31.7637 7.54297C32.2129 7.54297 32.6113 7.61719 32.959 7.76562C33.3066 7.91406 33.5977 8.125 33.832 
  8.39844C34.0703 8.67188 34.25 9 34.3711 9.38281C34.4922 9.76172 34.5527 10.1836 34.5527
   10.6484V11.3516H29.5137V10.2266H32.8945V10.0977C32.8945 9.86328 32.8516 9.6543 32.7656 9.4707C32.6836 9.2832
    32.5586 9.13477 32.3906 9.02539C32.2227 8.91602 32.0078 8.86133 31.7461 8.86133C31.5234 8.86133 31.332 8.91016
     31.1719 9.00781C31.0117 9.10547 30.8809 9.24219 30.7793 9.41797C30.6816 9.59375 30.6074 9.80078 30.5566 10.0391C30.5098
      10.2734 30.4863 10.5312 30.4863 10.8125V11.0469C30.4863 11.3008 30.5215 11.5352 30.5918 11.75C30.666 11.9648 30.7695
       12.1504 30.9023 12.3066C31.0391 12.4629 31.2031 12.584 31.3945 12.6699C31.5898 12.7559 31.8105 12.7988 32.0566
        12.7988C32.3613 12.7988 32.6445 12.7402 32.9062 12.623C33.1719 12.502 33.4004 12.3203 33.5918 12.0781L34.4121 
        12.9688C34.2793 13.1602 34.0977 13.3438 33.8672 13.5195C33.6406 13.6953 33.3672 13.8398 33.0469 13.9531C32.7266 
        14.0625 32.3613 14.1172 31.9512 14.1172ZM38.9414 12.248C38.9414 12.127 38.9062 12.0176 38.8359 11.9199C38.7656 11.8223
         38.6348 11.7324 38.4434 11.6504C38.2559 11.5645 37.9844 11.4863 37.6289 11.416C37.3086 11.3457 37.0098 11.2578 36.7324 
         11.1523C36.459 11.043 36.2207 10.9121 36.0176 10.7598C35.8184 10.6074 35.6621 10.4277 35.5488 10.2207C35.4355 10.0098 
         35.3789 9.76953 35.3789 9.5C35.3789 9.23438 35.4355 8.98438 35.5488 8.75C35.666 8.51562 35.832 8.30859 36.0469 
         8.12891C36.2656 7.94531 36.5312 7.80273 36.8438 7.70117C37.1602 7.5957 37.5156 7.54297 37.9102 7.54297C38.4609 7.54297
          38.9336 7.63086 39.3281 7.80664C39.7266 7.98242 40.0312 8.22461 40.2422 8.5332C40.457 8.83789 40.5645 9.18555 40.5645
           9.57617H38.877C38.877 9.41211 38.8418 9.26562 38.7715 9.13672C38.7051 9.00391 38.5996 8.90039 38.4551 8.82617C38.3145
            8.74805 38.1309 8.70898 37.9043 8.70898C37.7168 8.70898 37.5547 8.74219 37.418 8.80859C37.2812 8.87109 37.1758 8.95703
             37.1016 9.06641C37.0312 9.17188 36.9961 9.28906 36.9961 9.41797C36.9961 9.51562 37.0156 9.60352 37.0547 9.68164C37.0977
              9.75586 37.166 9.82422 37.2598 9.88672C37.3535 9.94922 37.4746 10.0078 37.623 10.0625C37.7754 10.1133 37.9629 10.1602 
              38.1855 10.2031C38.6426 10.2969 39.0508 10.4199 39.4102 10.5723C39.7695 10.7207 40.0547 10.9238 40.2656 11.1816C40.4766
               11.4355 40.582 11.7695 40.582 12.1836C40.582 12.4648 40.5195 12.7227 40.3945 12.957C40.2695 13.1914 40.0898 13.3965 
               39.8555 13.5723C39.6211 13.7441 39.3398 13.8789 39.0117 13.9766C38.6875 14.0703 38.3223 14.1172 37.916 14.1172C37.3262
                14.1172 36.8262 14.0117 36.416 13.8008C36.0098 13.5898 35.7012 13.3223 35.4902 12.998C35.2832 12.6699 35.1797 12.334
                 35.1797 11.9902H36.7793C36.7871 12.2207 36.8457 12.4062 36.9551 12.5469C37.0684 12.6875 37.2109 12.7891 37.3828 
                 12.8516C37.5586 12.9141 37.748 12.9453 37.9512 12.9453C38.1699 12.9453 38.3516 12.916 38.4961 12.8574C38.6406 
                 12.7949 38.75 12.7129 38.8242 12.6113C38.9023 12.5059 38.9414 12.3848 38.9414 12.248Z" fill="#581845" />
    </svg>)
export default Svgchatbotthumbsup;
