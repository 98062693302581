import * as React from 'react';
const SvgOutlookcalendar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    fill="none"
    {...props}
  >
    <path
      fill="#1976D2"
      d="M30 11.248h18.166c1.013 0 1.834.821 1.834 1.834v23.832a1.834 1.834 0 0 1-1.834 1.834H30v-27.5Z"
    />
    <path fill="#fff" d="M47.5 17.453H30v19.428h17.5V17.453Z" />
    <path fill="#1976D2" d="M28.75 50 0 44.375V5.625L28.75 0v50Z" />
    <path
      fill="#fff"
      d="M14.063 15.627c-3.97 0-7.188 4.197-7.188 9.375s3.217 9.375 7.188 9.375c3.97 0 7.187-4.198 7.187-9.375s-3.218-9.375-7.188-9.375Zm-.313 15c-2.071 0-3.75-2.519-3.75-5.625s1.679-5.625 3.75-5.625c2.071 0 3.75 2.519 3.75 5.625s-1.679 5.625-3.75 5.625Z"
    />
    <path
      fill="#1976D2"
      d="M33.434 32.168h-3.375v3.625h3.375v-3.625ZM37.685 32.168H34.31v3.625h3.375v-3.625ZM41.936 32.168h-3.375v3.625h3.375v-3.625ZM33.434 27.705h-3.375v3.625h3.375v-3.625ZM37.685 27.705H34.31v3.625h3.375v-3.625ZM41.936 27.705h-3.375v3.625h3.375v-3.625ZM46.188 27.705h-3.375v3.625h3.375v-3.625ZM33.434 23.377h-3.375v3.625h3.375v-3.625ZM37.685 23.377H34.31v3.625h3.375v-3.625ZM41.936 23.377h-3.375v3.625h3.375v-3.625ZM46.188 23.377h-3.375v3.625h3.375v-3.625ZM37.685 18.885H34.31v3.625h3.375v-3.625ZM41.936 18.885h-3.375v3.625h3.375v-3.625ZM46.188 18.885h-3.375v3.625h3.375v-3.625Z"
    />
  </svg>
);
export default SvgOutlookcalendar;