import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import Flex from '../../uikit/Flex/Flex';
import Modal from '../../uikit/Modal/Modal';
import Text from '../../uikit/Text/Text';
import StepProgressBar from '../../uikit/StepProgressBar/StepProgressBar';
import SvgTick from '../../icons/SvgTick';
import Loader from '../../uikit/Loader/Loader';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import { copyToClipboard, isEmpty } from '../../uikit/helper';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import { routesPath } from '../../routes/routesPath';
import SvgCopy from '../../icons/SvgCopy';
import { PRIMARY, SUCCESS } from '../../uikit/Colors/colors';
import { jdMatchMiddleWare } from '../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
import PreviewTitle from './PreviewTitle';
import {
  dsOrNonDsGetdMiddleWare,
  jdPreviewMiddleWare,
  jdProfileMiddleWares,
  postJdMiddleWare,
  questionnaireForJdMiddleWare,
  selectDsorNonDsMiddleWare,
  whatjobsMiddleWare,
  whatjobstatusMiddleWare
} from './store/middleware/createjdmiddleware';
import styles from './jdpreviewscreen.module.css';
import ApplicantQuestionnaireResult from './ApplicantQuestionnaireResult';
import StandardJobPosting from './StandardJobPosting';
type ParamsType = {
  jdId: string;
};

const JdPreviewScreen = () => {
  const { jdId } = useParams<ParamsType>();
  const [isOpen, setOpen] = useState(false);
  const [isLoader, setLoader] = useState(true);
  const [extarajobpost, setextarajobpost] = useState(1);
  const [iswhatjobstatus, setwhatjobstatus] = useState<any>();
  const [iswhatjobs, setwhatjobs] = useState<any>();
  const dispatch: AppDispatch = useDispatch();
  document.title = 'Post job';
  const history = useHistory();
  const urls = window.location.href;
  const applicantpipelineUrl = urls.split('/');
  const Urlnumber = applicantpipelineUrl[applicantpipelineUrl.length - 1]
  // initial api call
  useEffect(() => {
    dispatch(jdPreviewMiddleWare({ jd_id: jdId }));
    dispatch(questionnaireForJdMiddleWare({ jd_id: jdId }));
    dispatch(dsOrNonDsGetdMiddleWare({ jd_id: jdId })).then((res) => {
      if (res.payload.success) {
        setLoader(false);
      }
    })
    dispatch(selectDsorNonDsMiddleWare());


  }, []);
  useEffect(() => {
    dispatch(whatjobstatusMiddleWare({ pk: Urlnumber ? Urlnumber : '' })).then((res) => {
      setwhatjobs(res.payload.whatjobs)
    })
  }, [iswhatjobstatus])

  const {
    profile,
    jdDetails,
    qualification,
    location,
    skills,
    questionnaire_for_jd,
    ds_role,
    company_detail,
    url,
    postLoader,
    feature,
    career_page_url,
    is_plan,
    nice_to_have,
    super_user,
    current_plan
  } = useSelector(
    ({
      jdPreviewReducers,
      questionnaireForJdReducers,
      dsOrNonDsGetReducers,
      postReducers,
      selectDsorNonDsReducers,
      permissionReducers,
      SubscriptionReducers
    }: RootState) => {
      return {
        jdDetails: jdPreviewReducers.jd,
        profile: jdPreviewReducers.profile,
        qualification: jdPreviewReducers.qualification,
        location: jdPreviewReducers.location,
        skills: jdPreviewReducers.skills,
        nice_to_have: jdPreviewReducers.nice_to_have,
        questionnaire_for_jd: questionnaireForJdReducers.questionnaire_for_jd,
        ds_role: dsOrNonDsGetReducers.ds_role,
        company_detail: jdPreviewReducers.company_detail,
        url: postReducers.url,
        postLoader: postReducers.isLoading,
        feature: selectDsorNonDsReducers.feature,
        career_page_url: jdPreviewReducers.career_page_url,
        is_plan: permissionReducers.is_plan,
        super_user: permissionReducers.super_user,
        current_plan: SubscriptionReducers.current_plan,
      };
    },

  );

  useEffect(() => {
    if (!is_plan) {
      history.push('/account_setting/subscription');
    }
  });

  const whatjob = (values) => {
    setwhatjobstatus(values)
    dispatch(whatjobstatusMiddleWare({ pk: Urlnumber ? Urlnumber : '', whatjob: values === 1 ? 'False' : 'True' })).then((res) => {
      setwhatjobs(res.payload.whatjobs)
    })
  }

  // publish form submit 
  const hanldePulish = () => {
    const formData = new FormData();
    formData.append('jd_id', jdId)
    if (isEmpty(career_page_url)) {
      if (isEmpty(company_detail.no_of_emp)) {
        // sessionStorage.setItem('superUserTab', '1');
        history.push('/account_setting/build_careers_page');
      } else {
        // sessionStorage.setItem('superUserTab', '0');
        history.push('/account_setting/profiles');
      }
      // history.push('/account_setting/settings');
    }
    else if (iswhatjobs === true) {
      dispatch(whatjobsMiddleWare({ formData }));

      dispatch(postJdMiddleWare({ jd_id: jdId })).then((res) => {

        if (res.payload.success) {
          setOpen(true);
          dispatch(jdMatchMiddleWare({ jd_id: jdId }))
        }
      });
    }

    else if (iswhatjobs === false) {

      dispatch(postJdMiddleWare({ jd_id: jdId })).then((res) => {
        if (res.payload.success) {
          setOpen(true);
          dispatch(jdMatchMiddleWare({ jd_id: jdId }))
        }
      });
    }
  };

  if (isLoader) {
    return <Loader />
  }
  return (

    <>
      <Flex row center className={styles.step} >
        <StepProgressBar roundFill barFilled />
        <StepProgressBar
          title="Weightage Matching"
          titleclassName={styles.stepTwo}
          stepIndex="2"
          roundFill
        />
        <StepProgressBar
          title="Applicant Questionnaire"
          titleclassName={styles.stepThree}
          stepIndex="3"
          roundFill
          barFilled
        />
        <StepProgressBar
          title="Preview & Post Job"
          titleclassName={styles.stepFour}
          stepIndex="4"
          roundFill
        />

      </Flex>
      <Flex
        columnFlex
        className={styles.overAll}
        height={window.innerHeight}
      >
        {postLoader && <Loader />}

        <Modal open={isOpen}>
          <Flex columnFlex className={styles.modalOverAll}>
            <Flex row center middle>
              <div style={{ marginRight: 8 }}>
                <SvgTick fill={SUCCESS} />
              </div>

              <Text>You have successfully posted the job</Text>
              <CustomTooltip title="Copy the job posting URL from your careers page.">
                <div
                  tabIndex={-1}
                  role={'button'}
                  style={{ marginLeft: 8, cursor: 'pointer' }}
                  onClick={() => copyToClipboard(url, 'Link Copied')}
                  onKeyDown={() => { }}
                >
                  <SvgCopy width={12} height={14} fill={'#581845'} />
                </div>
              </CustomTooltip>
            </Flex>
            <Text align="center" style={{ marginTop: 8 }}>
              Please{' '}
              <LinkWrapper
                to={routesPath.MY_JOB_POSTING}
                onClick={() => setOpen(false)}
              >
                <Text color="link" underLine bold>
                  click here
                </Text>
              </LinkWrapper>{' '}
              to manage your jobs.
            </Text>
          </Flex>
        </Modal>

        <PreviewTitle
          jdDetails={jdDetails}
          profile={profile}
          location={location}
          qualification={qualification}
          skills={skills}
          nice_to_have={nice_to_have}
        />
        <ApplicantQuestionnaireResult
          data={questionnaire_for_jd}
          jdDetails={jdDetails}
          company_detail={company_detail}
        />
        <StandardJobPosting
          jdId={jdId}
          hanldePulish={hanldePulish}
          ds_role={ds_role}
          feature={feature}
          whatjob={whatjob}
          iswhatjobs={iswhatjobs}
          super_user={super_user}
          postLoader={postLoader}
          current_plan={current_plan}
        />
      </Flex>
    </>
  );
};
export default JdPreviewScreen;
