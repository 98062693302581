import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../uikit/Modal/Modal';
import { Button, Flex, InputText, Loader, Toast } from '../../../../uikit';
import Text from '../../../../uikit/Text/Text';
import Required from '../../../../uikit/Required/required';
import styles from '../emailtemplates/emailopenmodal.module.css';
import RichText from '../../../common/RichText';
import {
  companyPageInitalMiddleWare,
  createemailtemplatepostMiddleWare,
  createjdtemplatepostMiddleWare,
  emailtemplatesgetMiddleWare,
} from '../../store/middleware/accountsettingmiddleware';
import { AppDispatch, RootState } from '../../../../store';
import {
  jdTemplatesApiMiddleWare,
  locationCityMiddleWare,
  locationMiddleWare,
  locationStateMiddleWare,
} from '../../../createjdmodule/store/middleware/createjdmiddleware';
import { isEmpty, toCamelCase } from '../../../../uikit/helper';
import { getDisabledEmailTags } from '../../../applicantpipelinemodule/AutomationEmailedit';
import { CityEntity, CountryEntity, StateEntity } from '../../CompanyPageTypes';
import EmailTemplateEditor from './EmailTemplateEditor';
type Props = {
  open: boolean;
  handleOpenEmailModal: () => void;
  itemvalue: any;
  setEmailTemplates: any;
  emailTemplates: any;
  settemplateid?: (id?: any) => void;
  isTempTagIntemation: any;
  setTempTagIntemation: any;
  isTempDisableTag: any;
  setTempDisableTag: any;
};

const Emailopenmodal = ({
  open,
  handleOpenEmailModal,
  itemvalue,
  setEmailTemplates,
  emailTemplates,
  settemplateid,
  isTempTagIntemation,
  setTempTagIntemation,
  isTempDisableTag,
  setTempDisableTag,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const [isTitle, setTitle] = useState('');
  const [isSubject, setSubject] = useState('');
  const [isDescription, setDescription] = useState('');
  const [errordiscription, seterrordiscription] = useState(false);
  const [errortitle, seterrortitle] = useState(false);
  const [errorsubject, seterrorsubject] = useState(false);
  const [TitleError, setTitleError] = useState(false);
  const [SubjectError, setSubjectError] = useState(false);
  const [DescError, setDescError] = useState(false);
  const [TitleExist, setTitleExist] = useState(false);
  const [SubjectExist, setSubjectExist] = useState(false);
  const [isBtnLoader, setBtnLoader] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [errormindiscription, seterrormindiscription] = useState(false);
  // const [isshowfieldrequired, setfieldrequired] = useState(false);

  const {
    data,
    title,
    tags,
    applicants_list,
    company_detail,
    user,
    final_list,
    stateid,
    cityid,
    countryid,
    countryidfin,
  } = useSelector(
    ({
      emailTemplateReducers,
      applicantPipeLineDataReducers,
      companyPageReducers,
      userProfileReducers,
      myJobPostingDataReducers,
      locationReducers
    }: RootState) => {
      return {
        data: emailTemplateReducers.data,
        title: emailTemplateReducers.title,
        tags: emailTemplateReducers.tags,
        applicants_list: applicantPipeLineDataReducers.applicants_list,
        company_detail: companyPageReducers.company_detail,
        user: userProfileReducers.user,
        final_list: myJobPostingDataReducers.final_list,
        stateid: companyPageReducers.company_detail.state_id,
        cityid: companyPageReducers.company_detail.city_id,
        countryid: companyPageReducers.company_detail.country_id,
        countryidfin: locationReducers.country,
      };
    },
  );

  const [isGetCountry, setCountry] = useState<CountryEntity[]>([]);
  const [getState, setState] = useState<StateEntity[]>([]);
  const [getCity, setCity] = useState<CityEntity[]>([]);
  const [state, setstate] = useState('');
  const [city, setcity] = useState('');
  const [country, setcountry] = useState('');

  useEffect(() => {
    dispatch(locationMiddleWare({}));
  }, []);

  useEffect(() => {
    if (countryidfin && countryidfin.length !== 0) {
      setCountry(countryidfin);
    }
  }, [countryidfin]);
  useEffect(() => {
    if (!isEmpty(countryid)) {
      dispatch(
        locationStateMiddleWare({
          country: countryid.toString(),
        }),
      ).then((res) => {
        if (res.payload.states && res.payload.states.length !== 0) {
          setState(res.payload.states);
        }
      });
    }
  }, [countryid]);
  useEffect(() => {
    if (!isEmpty(stateid)) {
      dispatch(locationCityMiddleWare({ state: stateid.toString() })).then(
        (res) => {
          if (res.payload.city && res.payload.city.length !== 0) {
            setCity(res.payload.city);
          }
        },
      );
    }
  }, [stateid]);

  useEffect(() => {
    if (
      getCity.length !== 0 &&
      isGetCountry.length !== 0 &&
      getState.length !== 0
    ) {
      cityhand();
      statehand();
      countryhand();
    }
  }, [getCity, getState, isGetCountry]);

  function cityhand() {
    if (getCity.length !== 0) {
      {
        if (cityid !== null) {
          {
            setcity(getCity.find((option) => option.id === cityid).name);
          }
        }
      }
    }
  }
  function countryhand() {
    if (isGetCountry.length !== 0) {
      if (countryid !== null) {
        setcountry(isGetCountry.find((option) => option.id === countryid).name);
      }
    }
  }

  function statehand() {
    if (getState.length !== 0) {
      if (stateid !== null) {
        setstate(getState.find((option) => option.id === stateid).name);
      }
    }
  }

  const parser = new DOMParser();
  const handlemessage = (values) => {
    const doc = parser.parseFromString(values, 'text/html');
    const textNodes = doc.querySelectorAll('body')[0].textContent;
    const texttrim = textNodes.trim();
    return texttrim;
  };

  const htmlToPlainText = (html) => {
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent;
  };

  useEffect(() => {
    const description = isDescription;
    const result = handlemessage(description);

    if (description !== null) {
      if (result?.length < 250) {
        seterrormindiscription(true);
      }
      if (result?.length === 0 && result?.length < 250) {
        seterrormindiscription(false);
      }

      if (result?.length !== 0 && result?.length > 251) {
        seterrormindiscription(false);
      }
    } else {
      seterrormindiscription(false);
    }
  }, [isDescription]);

  const plainTextDescription = htmlToPlainText(isDescription);

  // const handletitle = (e: any) => {

  //   const inputValue = e.target.value;
  //   const titles = inputValue.trimStart();
  //   const titleAlreadyExists = data?.some(template => template.name === titles);
  //   setTitle(titles);
  //   if (titles.length > 35) {
  //     seterrortitle(true);
  //   } else {
  //     seterrortitle(false);
  //   }

  //   if (titleAlreadyExists) {
  //     setTitleExist(true);
  //   } else {
  //     setTitleExist(false);
  //   }
  // }

  // filtered the without current data.
  const templatevalue =
    data && data?.filter((val) => val?.id !== itemvalue?.id);

  const handletitle = (e: any) => {
    const inputValue = e.target.value;
    const titles = inputValue.trim();
    const titleAlreadyExists = templatevalue?.some(
      (template) => template?.name?.toLowerCase() === titles?.toLowerCase(),
    );
    setTitle(inputValue);

    if (titles.length > 35) {
      seterrortitle(true);
    } else {
      seterrortitle(false);
    }
    if (titles.length === 0) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }

    if (titleAlreadyExists) {
      setTitleExist(true);
    } else {
      setTitleExist(false);
    }
  };

  const handleSubject = (e: any) => {
    const inputValue = e.target.value;
    const subject = inputValue.trimStart();

    const subjectAlreadyExists = templatevalue?.some(
      (template) => template.subject?.toLowerCase() === subject?.toLowerCase(),
    );

    setSubject(subject);

    if (subject.length === 0) {
      setSubjectError(true);
    } else {
      setSubjectError(false);
    }
    if (subject.length > 250) {
      seterrorsubject(true);
    } else {
      seterrorsubject(false);
    }
    if (subjectAlreadyExists) {
      setSubjectExist(true);
    } else {
      setSubjectExist(false);
    }
  };

  const handleDescription = (e: any) => {
    setDescription(e);
    const description = e;
    const myparser = new DOMParser();
    const doc = myparser.parseFromString(description, 'text/html');
    const textNodes = doc.querySelectorAll('body')[0].textContent.trim();
    const texttrim = textNodes.trimStart();
    const result = handlemessage(description);
    if (result.length > 2000) {
      seterrordiscription(true);
    } else {
      seterrordiscription(false);
    }
    // if (isEmpty(texttrim)) {
    //   setDescError(true);
    // } else if (!isEmpty(texttrim)) {
    //   setDescError(false);
    // }
    if (isEmpty(texttrim)) {
      setDescError(true);
    } else if (!isEmpty(texttrim)) {
      setDescError(false);
    }
  };



  const addfunction = () => {
    // setfieldrequired(true);
    if (isTitle.trim() === '') {
      setTitleError(true);
    }
    if (isDescription.trim() === '') {
      setDescError(true);
    }
    if (isSubject.trim() === '') {
      setSubjectError(true);
    }
    if (
      isTitle.trim() !== '' &&
      isDescription.trim() !== '' &&
      isSubject.trim() !== '' &&
      isSubject?.trim().length <= 250 &&
      // isDescription?.trim().length >= 300 &&
      isDescription?.trim().length <= 2000 &&
      isTitle?.trim().length <= 35 &&
      !TitleExist &&
      !SubjectExist &&
      !DescError

    ) {
      setBtnLoader(true);
      let formData = new FormData();
      if (itemvalue !== null) {
        formData.append('id', itemvalue.id);
      }
      formData.append('title', toCamelCase(isTitle));
      formData.append('rich_text', isDescription);
      formData.append('subject', isSubject);
      formData.append('text', plainTextDescription);
      formData.append('isactive', 'True');
      setLoader(true);
      dispatch(createemailtemplatepostMiddleWare({ formData })).then((res) => {
        if (res.payload.success === true) {
          settemplateid(res.payload.templateid);
          if (itemvalue !== null) {
            Toast('Template updated successfully.', 'LONG', 'success');
            setBtnLoader(false);
          } else {
            Toast('Template saved successfully.', 'LONG', 'success');
            setBtnLoader(false);
          }
        } else {
          Toast(
            'Oops! Something went wrong. Please try again shortly.',
            'LONG',
            'error',
          );
          setBtnLoader(false);
        }
        dispatch(emailtemplatesgetMiddleWare({ user: '1' }));
      });
      setLoader(false);

      handleOpenEmailModal();
    }
  };

  useEffect(() => {
    if (itemvalue !== null && itemvalue !== undefined) {
      setTitle(itemvalue.name);
      setDescription(itemvalue.templates);
      setSubject(itemvalue.subject);
    }
    dispatch(emailtemplatesgetMiddleWare({ user: '1' }));
  }, []);

  useEffect(() => {
     dispatch(companyPageInitalMiddleWare());
    // dispatch(locationMiddleWare({}));
  }, []);

  const disabled_Email_Tags = getDisabledEmailTags(company_detail, country, state, city);


  return (
    <div>
      {isLoader && <Loader />}
      <Modal open={open}>
        <Flex className={styles.addtemplatePopup}>
          {itemvalue !== null ? (
            <Text bold size={14} className={styles.titletext}>
              Update Template
            </Text>
          ) : (
            <Text bold size={14} className={styles.titletext}>
              Add Template
            </Text>
          )}
          <Flex marginBottom={5}>
            <Required />
          </Flex>
          <Flex>
            <Flex>
              <InputText
                label="Template Title"
                required
                className={styles.templatetitleInput}
                onChange={handletitle}
                value={isTitle}
                maxLength={36}
              />
            </Flex>
            {errortitle && (
              <Text color="error">
                Template title should not exceed 35 characters.
              </Text>
            )}
            {
            // isshowfieldrequired && 
            TitleError && (
              <Text color="error">This field is required.</Text>
            )}
            {TitleExist && (
              <Text color="error">Template title already exists.</Text>
            )}

            <Flex marginTop={5}>
              <InputText
                label="Subject Content"
                required
                className={styles.subjectcontentInput}
                onChange={handleSubject}
                value={isSubject}
                maxLength={251}
              />
              {errorsubject && (
                <Text color="error">
                  Subject content should not exceed 250 characters.
                </Text>
              )}
              {
              // isshowfieldrequired && 
              SubjectError && (
                <Text color="error">This field is required.</Text>
              )}
              {SubjectExist && (
                <Text color="error">Subject content already exists.</Text>
              )}
            </Flex>
            <Flex>
              <Text
                color="theme"
                size={13}
                style={{ margin: '5px 0px 2px 0px' }}
              >
                Email Content *
              </Text>
              <EmailTemplateEditor
                templateHub
                handleChange={handleDescription}
                value={isDescription}
                height={'200px'}
                tags={tags}
                disabledTags={disabled_Email_Tags}
                isTempTagIntemation={isTempTagIntemation}
                setTempTagIntemation={setTempTagIntemation}
                isTempDisableTag={isTempDisableTag}
                setTempDisableTag={setTempDisableTag}
              />
            </Flex>
            {errordiscription && (
              <Text color="error">
                Email content should not exceed 2000 characters.
              </Text>
            )}

            {/* {errormindiscription && (
            <Text color="error">
              Email content must contain minimum of 300 characters.
            </Text>
          )} */}
            {
            // isshowfieldrequired && 
            DescError && (
              <Text color="error">This field is required.</Text>
            )}
          </Flex>

          <Flex className={styles.btnContainer}>
            <Flex row>
              <Button types="close" onClick={handleOpenEmailModal}>
                Cancel
              </Button>

              {isBtnLoader ? (
                <Flex className={styles.updateBtnLoader}>
                  <Loader size="small" withOutOverlay />
                </Flex>
              ) : (
                <>
                  {itemvalue !== null ? (
                    <Button onClick={addfunction} style={{ marginLeft: 15 }}>
                      Update
                    </Button>
                  ) : (
                    <Button onClick={addfunction} style={{ marginLeft: 15 }}>
                      Add
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </div>
  );
};

export default Emailopenmodal;
