/* eslint max-len: ["error", { "code": 2000 }] */


const SvgCalendar  = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={64}
  height={64}
  fill="#581845"
  stroke="#581845"
  strokeWidth={38.4}
  viewBox="-192 -192 2304 2304"
  {...props}
>
  <path
    fillRule="evenodd"
    d="M1411.824 0c31.17 0 56.47 25.3 56.47 56.471v56.47h169.412c93.403 
    0 169.412 76.01 169.412 169.412V1920H113V282.353c0-93.402 76.009-169.412 
    169.412-169.412h169.41v-56.47c0-31.172 25.3-56.47 56.472-56.47s56.47 
    25.298 56.47 56.47v56.47h790.589v-56.47c0-31.172 25.299-56.47 
    56.47-56.47Zm282.352 564.705H225.942v1242.353h1468.234V564.705ZM451.823 225.882H282.412c-31.06 
    0-56.47 25.3-56.47 56.471v169.412h1468.234V282.353c0-31.172-25.411-56.47-56.47-56.47h-169.412v56.47c0 31.172-25.3 56.471-56.47 
    56.471-31.172 0-56.471-25.299-56.471-56.47v-56.472H564.765v56.471c0 31.172-25.3 56.471-56.471 
    56.471-31.171 0-56.471-25.299-56.471-56.47v-56.472Zm507.682 1190.74c-82.334 
    0-141.515-34.448-194.372-95.888l-90.804 83.351c62.23 74.992 154.277 128.075 283.143 128.075 154.278 
    0 258.862-96.79 258.862-231.19v-2.034c0-134.4-105.6-190.644-217.525-204.198l205.892-208.263v-95.887H715.552V902.06h320.076l-199.68 
    209.394 21.12 80.188h60.31c103.454 0 170.09 40.546 170.09 112.376v2.147c0 66.635-55.003 
    110.456-127.963 110.456"
  />
</svg>
);
export default SvgCalendar;


