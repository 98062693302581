export const postedOn = [
  { value: "", label: "All" },
  { value: '1', label: 'Last 24 hours' },
  { value: '3', label: 'Last 3 days' },
  { value: '7', label: 'Last 7 days' },
  { value: '14', label: 'Last 14 days' },
  { value: '30', label: 'Last 30 days' },
  { value: '90', label: 'Last 90 days' },
  { value: '365', label: 'Last Year' },
];

export const jobTypeData = [
  { value: 'All', label: '', width: 92, paddingleft: 50 },
  { value: 'Active', label: '1', width: 92, paddingleft: 50 },
  { value: 'Inactive', label: '4', width: 92, paddingleft: 50 },
  { value: 'Draft', label: '2,5,6', width: 92, paddingleft: 50 },

];

export const level = [
  { value: 'General', label: 'General', paddingleft: 50 },
  { value: 'IQ Based', label: 'IQ Based', paddingleft: 50 },
  { value: 'Technical', label: 'Technical', paddingleft: 50 },
  { value: 'Coding', label: 'Coding', paddingleft: 50 },
  { value: 'Behavioral', label: 'Behavioral', paddingleft: 50 },

];
export const Typeofinterviewquestion = [
  { value: 'General', label: 'General' },
  { value: 'IQ Based', label: 'IQ Based' },
  { value: 'Technical', label: 'Technical' },
  { value: 'Coding', label: 'Coding' },
  { value: 'Behavioral', label: 'Behavioral' },
];
export const Difficultylevel = [
  { value: 'Easy', label: 'Easy' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Hard', label: 'Hard' },

];
export const options = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 },
  { label: '50', value: 50 },
  { label: '60', value: 60 },
  { label: '70', value: 70 },
  { label: '80', value: 80 },
  { label: '90', value: 90 }, 
  { label: '100', value: 100 },
];

export const option = [
  { label: '15', value: 15 }, 
  { label: '30', value: 30 },
  { label: '45', value: 45 }, 
  { label: '60', value: 60 },
  { label: '75', value: 75 }, 
  { label: '90', value: 90 } 
];
