import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../uikit/Loader/Loader';
import Flex from '../../uikit/Flex/Flex';
import Toast from '../../uikit/Toast/Toast';
import SvgLeft from '../../icons/SvgLeft';
import SvgJobselection from '../../icons/SvgJobselection';
import { getDateString, isEmpty, toCamelCase } from '../../uikit/helper';
import { SelectTag } from '../../uikit';
import { AppDispatch, RootState } from '../../store';
import { inviteToApplyApi } from '../../routes/apiRoutes';
import Text from '../../uikit/Text/Text';
import { permissionMiddleWare } from '../Login/store/middleware/loginMiddleWare';
import { config, ERROR_MESSAGE, YES } from '../constValue';
import CancelAndDeletePopup from '../common/CancelAndDeletePopup';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import { backendProcessMiddleWare, creditsPurchaseMiddleWare } from '../ordersummarymodule/store/ordersummarymiddleware';
import { manageSubscriptionMiddleWare } from '../accountsettingsmodule/managesubscription/store/managesubscriptionmiddleware';
import ProfileNavBar from './ProfileNavBar';
import ApplicantTabLeft from './ApplicantTabLeft';
import ApplicantTabRight from './ApplicantTabRight';
import {
  CandidatejobidMatchMiddleWare,
  PipelineStagesDeleteMiddleWare,
  PipelineStagesGetMiddleWare,
  PipelineStagesPostMiddleWare,
  applicantAllMatchMiddleWare,
  applicantMatchMiddleWare,
  applicantNotesMiddleWare,
  applicantProfileInitialMiddleWare,
  applicantScoreMiddleWare,
  applicantStatusMiddleWare,
  calenderMiddleWare,
  messagesTemplatesMiddleWare,
  profile_summarypostMiddleWare,
} from './store/middleware/applicantProfileMiddleware';
import ApplicantTabRightOne from './ApplicantTabRightOne';
import ApplicantTabLeftOne from './ApplicantTabLeftOne';
import ApplicantTabLeftTwo from './ApplicantTabLeftTwo';
import styles from './applicantprofilescreen.module.css';
import { interviewQuestionMiddleware } from './store/middleware/interviewquestionMiddleware';



// import { LinkWrapper } from '../../uikit';

var querystring = require('querystring');
type ParamsType = {
  jdId: string;
  candiId: string;
};
const ApplicantProfileScreen = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const show = searchParams.get('show');
  const [jobtitle, setjobtitle] = useState<string>();
  const { jdId, candiId } = useParams<ParamsType>();
  const history = useHistory();
  const [isInvitePopUp, setInvitePopUp] = useState(false);
  const [isTab, setTab] = useState(false);
  const [isInviteLoader, setInviteLoader] = useState(false);
  const [isLoader, setLoader] = useState(true);
  const dispatch: AppDispatch = useDispatch();
  const [isTabValue, setTabValue] = useState(0);
  const [isoverall, setisoverall] = useState<any>(0);
  const [checkingstatus, setcheckingstatus] = useState(null);
  const [ischaractercheck, setcharactercheck] = useState<any>();
  const [isdublicatecheck, setdublicatecheck] = useState<any>();
  const [isdublicateerror, setdublicateerror] = useState<any>(false);
  const [isapplicantpipeline, setapplicantpipeline] = useState<any>();

  document.title = 'Applicant Profile View';
  //Updating overall percentage
  const updatr_overall = (val) => {
    setisoverall(val);
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const isMessage: any = query.get('isMessage');

  useEffect(() => {
    if (!isEmpty(isMessage)) {
      setTabValue(5);
    }
  }, []);
  useEffect(() => {
    const url = window.location.href;
    const applicantpipelineUrl = url.includes('/?5');
    if (applicantpipelineUrl) {
      setTabValue(5);
    }
    const interviewurl = url.includes('?question');
    if (interviewurl) {
      setTabValue(6);
    }
  }, []);
  // dispatching the API's when the user buy the add-on
  const session: any = query.get('session');
  const session_id: any = query.get('session_id');
  useEffect(() => {
    if (!isEmpty(session)) {
      dispatch(creditsPurchaseMiddleWare({ session })).then(() => {
        dispatch(permissionMiddleWare());

        dispatch(SubsriptionMiddleWare())
          .then(() => {
            dispatch(SubsriptionMiddleWare())
            dispatch(permissionMiddleWare());
          })
          .then(() => {
            if (query.has('session')) {
              query.delete('session');
              history.replace({
                search: query.toString(),
              });
            }
          }).then(() => {
            dispatch(SubsriptionMiddleWare())
            dispatch(permissionMiddleWare());

          });
      })
    }
    if (!isEmpty(session_id)) {
      dispatch(backendProcessMiddleWare({ session_id })).then(() => {
        dispatch(SubsriptionMiddleWare());
        dispatch(manageSubscriptionMiddleWare())
          .then(() => {
            dispatch(permissionMiddleWare());
            dispatch(SubsriptionMiddleWare());
          })
          .then(() => {
            if (query.has('session_id')) {
              query.delete('session_id');
              history.replace({
                search: query.toString(),
              });
            }
          })
          .then(() => {
            dispatch(SubsriptionMiddleWare())
            dispatch(permissionMiddleWare());
          });
      })
    }
  }, [session]);

  // initial api call
  useEffect(() => {
    dispatch(permissionMiddleWare());
    if (jdId !== '0' && candiId !== '0') {
      setTab(true);
      dispatch(
        applicantProfileInitialMiddleWare({ jd_id: jdId, can_id: candiId }),
      )
        .then((res) => {
          dispatch(
            CandidatejobidMatchMiddleWare({
              jd_id: res.payload.jd_id,
              can_id: res.payload.can_id,
            }),
          );
          dispatch(applicantNotesMiddleWare({ can_id: res.payload.can_id }));
          dispatch(applicantAllMatchMiddleWare({ can_id: res.payload.can_id }));
          dispatch(
            applicantScoreMiddleWare({
              jd_id: res.payload.jd_id,
              can_id: res.payload.can_id,
            }),
          );
          dispatch(messagesTemplatesMiddleWare());
          dispatch(calenderMiddleWare({ can_id: res.payload.can_id }));
          dispatch(
            applicantStatusMiddleWare({
              jd_id: res.payload.jd_id,
              can_id: res.payload.can_id,
            }),
          );
        })
        .then(() => {
          setLoader(false);
        });
    }
    if (jdId === '0' && candiId !== '0') {
      setTab(false);
      dispatch(
        applicantProfileInitialMiddleWare({ jd_id: jdId, can_id: candiId }),
      )
        .then((res) => {
          dispatch(applicantNotesMiddleWare({ can_id: res.payload.can_id }));
          dispatch(applicantAllMatchMiddleWare({ can_id: res.payload.can_id }));
          dispatch(calenderMiddleWare({ can_id: res.payload.can_id }));
        })
        .then(() => {
          setLoader(false);
        });
    }
  }, []);



  const {
    candidate_details,
    initialLoader,
    jd,
    match,
    jd_id,
    can_id,
    matchLoader,
    notesLoader,
    status_id,
    job_details,
    invite,
    stages,
    source,
    is_plan,
    jd_d,
    pipelinestages,
    current_stage
  } = useSelector(
    ({
      applicantProfileInitalReducers,
      applicantMatchReducers,
      applicantNotesReducers,
      applicantStausReducers,
      applicantPipeLineReducers,
      permissionReducers,
      PipelineStagesReducers
    }: RootState) => {
      return {
        candidate_details: applicantProfileInitalReducers.candidate_details,
        initialLoader: applicantProfileInitalReducers.isLoading,
        jd: applicantProfileInitalReducers.jd,
        match: applicantMatchReducers.match ? applicantMatchReducers.match : [],
        jd_id: applicantProfileInitalReducers.jd_id,
        jd_d: applicantProfileInitalReducers.ApplicantEntity,
        can_id: applicantProfileInitalReducers.can_id,
        matchLoader: applicantMatchReducers.isLoading,
        job_details: applicantPipeLineReducers.job_details,
        notesLoader: applicantNotesReducers.isLoading,
        status_id: applicantProfileInitalReducers.status_id,
        invite: applicantStausReducers.invite,
        source: applicantProfileInitalReducers.source,
        is_plan: permissionReducers.is_plan,
        stages: applicantStausReducers?.stages,
        pipelinestages: PipelineStagesReducers?.stages,
        current_stage: PipelineStagesReducers?.current_stage,
      };
    },
  );

  // default calling the API except the new applicant.
  useEffect(() => {
    if (checkingstatus !== null && checkingstatus !== 'Applied') {
      dispatch(PipelineStagesGetMiddleWare({
        jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
        can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
        pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id)
      }))
    }
  }, [checkingstatus])

  // dispatching the api for getting the profile summery based on perticular job and candidate.
  useEffect(() => {
    if (can_id) {
      localStorage.setItem('profile_summery', 'true');
      dispatch(profile_summarypostMiddleWare({ can_id: can_id })).then((res) => {
        if (res.payload.success === true) {
          localStorage.setItem('profile_summery', 'false');
        }
        else {
          Toast(
            'Oops! Something went wrong. Please try again shortly.',
            'LONG',
            'error',
          );
          localStorage.setItem('profile_summery', 'false');
        }
      });
    }
  }, [can_id]);

  useEffect(() => {
    if (jd_id && can_id) {
      dispatch(interviewQuestionMiddleware({ jd_id, can_id })).then((res) => {
        if (res.payload.success === false) {
          Toast(
            'Oops! Something went wrong. Please try again shortly.',
            'LONG',
            'error',
          );
        }
      });
    }
  }, [jd_id, can_id]);
  useEffect(() => {
    if (!is_plan) {
      history.push('/account_setting/subscription');
    }
  });
  useEffect(() => {
    dispatch(SubsriptionMiddleWare());
  }, []);

  useEffect(() => {
    if (query.has('question')) {
      query.delete('question');
      history.replace({
        search: query.toString(),
      });
    }
  }, []);
  const checkMatch = match && match.length === 0 ? true : false;
  const profileMatch = checkMatch ? 0 : match[0].profile_match;

  // invite popup open function
  const hanldeInvitePopUp = () => {
    setInvitePopUp(true);
  };
  // invite popup close function
  const hanldeInviteClosePopUp = () => {
    setInvitePopUp(false);
  };
  // invite submit function
  const hanldeInvite = () => {
    hanldeInviteClosePopUp();
    setInviteLoader(true);
    const data = querystring.stringify({
      jd_id,
      candi_id: can_id,
    });
    axios
      .post(inviteToApplyApi, data, config)
      .then(() => {
        setInviteLoader(false);
        Toast('Applicant invited successfully.');
        dispatch(
          applicantStatusMiddleWare({
            jd_id,
            can_id,
          }),
        );
        dispatch(applicantAllMatchMiddleWare({ can_id }));
      })
      .catch(() => {
        setInviteLoader(false);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  // passing the pipeline stages to array format to pass as a option in select tag
  const transformedData = pipelinestages?.map(item => ({
    value: item?.stage_id,
    label: item?.stage_name,
    id: item?.stage_userbased,
    stageused: item?.stage_used
  }));

  // condition for updating the stages after calling the post call then call the get call for getting the data from response.
  const handlepipelinestages = (event) => {
    if (event.__isNew__ === true) {
      if (event?.label?.replace(/\s+/g, '')?.trim()?.length > 25) {
        setcharactercheck(true)
      }
      else if (transformedData.filter(data => data.label === isdublicatecheck).length !== 0) {
        setdublicateerror(true)
      }
      else {
        setcharactercheck(false)
        setdublicateerror(false)
        const data = pipelinestages?.find((e) => Number(e?.stage_id) === Number(event?.value))
        const formData = new FormData();
        formData.append('jd_id', JSON.stringify(stages[stages?.length - 1]?.jd_id))
        formData.append('can_id', JSON.stringify(stages[stages?.length - 1]?.candidate_id))
        formData.append('pipeline_id', JSON.stringify(stages[stages?.length - 1]?.stage_id))
        formData.append('stage_name', toCamelCase(event.value))
        formData.append('customization', 'customization')
        dispatch(PipelineStagesPostMiddleWare({
          formData
        })).then(() => {
          Toast('Stage status created successfully.', 'SHORT', 'success');
          dispatch(PipelineStagesGetMiddleWare({
            jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
            can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
            pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id)
          })).then(() => {
            // setloader(false);
          })
        })
      }
    }
    else {
      const data = pipelinestages?.find((e) => Number(e?.stage_id) === Number(event?.value))
      const formData = new FormData();
      formData.append('jd_id', JSON.stringify(stages[stages?.length - 1]?.jd_id))
      formData.append('can_id', JSON.stringify(stages[stages?.length - 1]?.candidate_id))
      formData.append('pipeline_id', JSON.stringify(stages[stages?.length - 1]?.stage_id))
      formData.append('update', JSON.stringify(data?.stage_id))
      if (Number((data?.stage_id) !== 1) && (Number(current_stage) !== 4)) {
        dispatch(PipelineStagesPostMiddleWare({
          formData
        })).then(() => {
          if (Number(data?.stage_id) === 4) {
            Toast('Stage status is set to completed.', 'SHORT', 'success');
          }
          else {
            Toast('Stage status updated successfully.', 'SHORT', 'success');
          }
          dispatch(PipelineStagesGetMiddleWare({
            jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
            can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
            pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id)
          }))
        })
      }
      else {
        // setloader(false);
        Toast("You cannot move the status backward.", 'SHORT', 'error');
      }
    }
  }

  // getting the applicant current stage in this job
  useEffect(() => {
    if (stages.length >= 1) {
      const stage_name = stages[stages.length - 1].stage_id__stage_name;
      setcheckingstatus(stage_name);
    }
    if (stages.length === 0) {
      setcheckingstatus('');
    }
  }, [stages]);

  // ondelete function in select option when don't want the stage status.
  const onDeleteoption = (e) => {
    dispatch(PipelineStagesDeleteMiddleWare({
      id: e
    })).then(() => {
      Toast('Stage status removed permanently.', 'SHORT', 'success');
      dispatch(PipelineStagesGetMiddleWare({
        jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
        can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
        pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id)
      }))
    })

  }

  // oninputchange function for creating stage in select tag.
  const onCreatestage = (e) => {
    setdublicatecheck(e)
    setcharactercheck(false)
    setdublicateerror(false)
  }

  // this is using validate the input value while typing it self in the dropdown field.
  useEffect(() => {
    if (isdublicatecheck?.replace(/\s+/g, '')?.trim()?.length > 25) {
      setcharactercheck(true)
    }
  }, [isdublicatecheck])

  // useEffect for getting applicantpipelinestatus on the pipeline
  useEffect(() => {
    if (localStorage.getItem('applicantpipelinestatus')) {
      setapplicantpipeline(localStorage.getItem('applicantpipelinestatus'))
    }
  }, [localStorage.getItem('applicantpipelinestatus')])

  if (isLoader) {
    return <Loader />;
  }
  return (
    <Flex>
      <Flex>
        <Flex row center middle flex={1} className={styles.border}>
          {/* <Flex
              className={'pointer'}
              style={{ cursor: 'pointer' }}
              // onClick={cancel}
            >
              <SvgLeft fill={'#581845'} height={16} width={16} />
            </Flex> */}
          {jobtitle !== undefined && (
            <Flex row>
              {show !== 'true' ?
                <Flex row center>
                  <Flex marginTop={2}>
                    <SvgJobselection width={16} height={14} />
                  </Flex>
                  <Flex marginLeft={4}>
                    {toCamelCase(jd.job_title)} - {jd.job_id}
                  </Flex>
                </Flex> :
                <>
                  {checkingstatus !== 'Applied' &&
                    <>
                      {isdublicateerror || ischaractercheck ?
                        <Flex>
                          {stages.length !== 0 &&
                            <Flex row style={{ border: '1px solid #581845', borderRadius: '4px' }} center marginTop={18}>
                              <Flex center style={{ color: '#581845', padding: '0px 10px' }}>
                                Applicant Status
                              </Flex>
                              <Flex width={215} disabled={Number(current_stage) === 4}>
                                <SelectTag
                                  menuIsOpen={false}
                                  createstage
                                  Deletecomponent
                                  onDeleteoption={onDeleteoption}
                                  onInputChange={onCreatestage}
                                  value={transformedData?.find((e) => Number(e?.value) === Number(current_stage))}
                                  isCreate
                                  isSearchable={true}
                                  isDisabled={Number(current_stage) === 4}
                                  options={transformedData}
                                  placeholder="Please select the stages"
                                  onChange={(e) => handlepipelinestages(e)}
                                />
                              </Flex>
                            </Flex>}
                          <Flex>{!isdublicateerror && ischaractercheck && <Text color='error' style={{ zIndex: 9 }}>Stage status should not exceed 25 characters</Text>}
                            {isdublicateerror &&
                              <Text color='error' style={{ zIndex: 9 }}>Stage name already exists.</Text>
                            }
                          </Flex>
                        </Flex> :
                        <Flex>
                          {stages.length !== 0 &&
                            <Flex row style={{ border: '1px solid #581845', borderRadius: '4px' }} center >
                              <Flex center style={{ color: '#581845', padding: '0px 10px' }}>
                                Applicant Status
                              </Flex>
                              <Flex width={215} disabled={Number(current_stage) === 4}>
                                <SelectTag
                                  createstage
                                  Deletecomponent
                                  onDeleteoption={onDeleteoption}
                                  onInputChange={onCreatestage}
                                  value={transformedData?.find((e) => Number(e?.value) === Number(current_stage))}
                                  isCreate
                                  isSearchable={true}
                                  isDisabled={Number(current_stage) === 4}
                                  options={transformedData}
                                  placeholder="Please select the stages"
                                  onChange={(e) => handlepipelinestages(e)}
                                />
                              </Flex>
                            </Flex>}
                        </Flex>
                      }
                    </>}
                </>}
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex row className={styles.overAll}>
        {(initialLoader || matchLoader || notesLoader || isInviteLoader) && (
          <Loader />
        )}

        {invite && invite.length === 0 && (
          <CancelAndDeletePopup
            title={`Invite will be sent as an email to ${candidate_details && candidate_details[0].first_name
              }. Are you sure to proceed?`}
            btnDelete={hanldeInvite}
            btnCancel={hanldeInviteClosePopUp}
            btnRight={YES}
            open={isInvitePopUp}
          />
        )}
        {invite && invite.length !== 0 && (
          <CancelAndDeletePopup
            title={
              <Flex className={styles.popTitle}>
                <Text>{`The candidate ${candidate_details && candidate_details[0].first_name
                  } has already been invited for this job on ${getDateString(
                    invite[invite.length - 1].created_at,
                    'll',
                  )}.`}</Text>
                <Text>Do you wish to invite again?</Text>
              </Flex>
            }
            btnDelete={hanldeInvite}
            btnCancel={hanldeInviteClosePopUp}
            btnRight={YES}
            open={isInvitePopUp}
          />
        )}

        {candidate_details &&
          candidate_details?.map((candiList, index) => {
            return (
              <Flex
                key={''}
                height={window.innerHeight}
                style={{ boxShadow: '2px 2px 2px #D7C7D2', marginRight: '5px' }}
              >
                <ProfileNavBar
                  key={index + candiList.first_name}
                  candiList={candiList}
                  setjobtitle={setjobtitle}
                  jdDetails={jd}
                  profile_match={profileMatch}
                  nonMatch={checkMatch}
                  isoverall={isoverall}
                  applieddatecheck={isTab && stages.length === 0 ? true : false}
                  availableity={isTab && stages.length !== 0 ? false : true}
                  inviteCall={hanldeInvitePopUp}
                  // isInvite={status_id.length === 0}
                  isResume
                  withOutJD={isTab}
                  source={source}
                  updatr_overall={updatr_overall}
                />
              </Flex>
            );
          })}
        <Flex flex={1} row className={styles.tabContainer}>
          {!isTab ? (
            <Flex flex={12} className={styles.tabLeftFlex}>
              <ApplicantTabLeftOne
                activeState={isTabValue}
                updatr_overall={updatr_overall}
              />
            </Flex>
          ) : (
            <Flex flex={7} className={styles.tabLeftFlex}>
              {stages.length === 0 ? (
                <ApplicantTabLeftTwo
                  activeState={isTabValue}
                  updatr_overall={updatr_overall}
                />
              ) : (
                <ApplicantTabLeft
                  activeState={isTabValue}
                  updatr_overall={updatr_overall}
                />
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ApplicantProfileScreen;
