/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
  fill: '#979797',
  width: 22,
  height: 22,
};

const SvgSupport = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.80005 1.6001C4.3757 1.6001 3.96874 1.76867 3.66868 2.06873C3.36862 2.36878
         3.20005 2.77575 3.20005 3.2001V7.3657C3.46155 7.29167 3.72937 7.24213 4.00005
          7.2177V3.2001C4.00005 2.98792 4.08433 2.78444 4.23436 2.63441C4.38439 2.48438 4.58788
           2.4001 4.80005 2.4001H8.00005V5.2001C8.00005 5.51836 8.12648 5.82358 8.35152 6.04863C8.57656
            6.27367 8.88179 6.4001 9.20005 6.4001H12V12.8001C12 13.0123 11.9158 13.2158 11.7657 13.3658C11.6157
             13.5158 11.4122 13.6001 11.2 13.6001H8.32005C8.17442 13.8851 7.99818 14.1533 7.79445
              14.4001H11.2C11.6244 14.4001 12.0314 14.2315 12.3314 13.9315C12.6315 13.6314 12.8
               13.2244 12.8 12.8001V5.9313C12.7998 5.61315 12.6732 5.30813 12.448 5.0833L9.31685
                1.9513C9.09189 1.72648 8.78689 1.60017 8.46885 1.6001H4.80005ZM11.8344 5.6001H9.20005C9.09396
                 5.6001 8.99222 5.55795 8.91721 5.48294C8.84219 5.40793 8.80005 5.30618 8.80005
                  5.2001V2.5657L11.8344 5.6001ZM8.00005 11.6001C8.00005 10.6453 7.62076 9.72964 6.94563
                   9.05451C6.2705 8.37938 5.35483 8.0001 4.40005 8.0001C3.44527 8.0001 2.5296 8.37938
                    1.85446 9.05451C1.17933 9.72964 0.800049 10.6453 0.800049 11.6001C0.800049 12.5549
                     1.17933 13.4706 1.85446 14.1457C2.5296 14.8208 3.44527 15.2001 4.40005 15.2001C5.35483
                      15.2001 6.2705 14.8208 6.94563 14.1457C7.62076 13.4706 8.00005 12.5549 8.00005 11.6001ZM4.40005
                       13.1041C4.53266 13.1041 4.65983 13.1568 4.7536 13.2505C4.84737 13.3443 4.90005 13.4715 4.90005
                        13.6041C4.90005 13.7367 4.84737 13.8639 4.7536 13.9577C4.65983 14.0514 4.53266 14.1041 4.40005
                         14.1041C4.26744 14.1041 4.14026 14.0514 4.0465 13.9577C3.95273 13.8639 3.90005 13.7367 3.90005
                          13.6041C3.90005 13.4715 3.95273 13.3443 4.0465 13.2505C4.14026 13.1568 4.26744 13.1041 4.40005
                           13.1041ZM4.40005 9.2025C5.22485 9.2025 5.88245 9.8793 5.88245 10.7625C5.88245 11.2313 5.71125
                            11.4889 5.30085 11.8177L5.07925 11.9889C4.88245 12.1441 4.81605 12.2289 4.80245 12.3473L4.79365
                             12.4721C4.77584 12.5705 4.72185 12.6586 4.64231 12.7191C4.56278 12.7797 4.46345 12.8082 4.36391
                              12.7992C4.26436 12.7902 4.1718 12.7442 4.10446 12.6703C4.03711 12.5965 3.99986 12.5001 4.00005 12.4001C4.00005
                               11.9441 4.16805 11.6929 4.57285 11.3697L4.79525 11.1977C5.02565 11.0137 5.08325 10.9241 5.08325
                                10.7625C5.08325 10.3161 4.77765 10.0025 4.40005 10.0025C4.00485 10.0025 3.71285 10.2953 3.71765
                                 10.7585C3.71817 10.811 3.70835 10.8631 3.68873 10.9119C3.66911 10.9606 3.64009 11.005 3.60332
                                  11.0425C3.56655 11.08 3.52275 11.1099 3.47442 11.1305C3.42609 11.1511 3.37418 11.162 3.32165
                                   11.1625C3.26912 11.163 3.217 11.1532 3.16827 11.1336C3.11954 11.114 3.07515 11.0849 3.03763
                                    11.0482C3.00012 11.0114 2.97021 10.9676 2.94963 10.9193C2.92904 10.8709 2.91817 10.819 2.91765
                                     10.7665C2.90885 9.8569 3.56165 9.2025 4.40005 9.2025Z"
        fill="#581845"
      />
    </svg>
  );
};
SvgSupport.defaultProps = defaultProps;

export default SvgSupport;
