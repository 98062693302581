const defaultProps = {
    fill: '#888888',
    
  };
  
  const SvgPicupload = ({ fill }: typeof defaultProps) => {
    return (
        <svg width="16" height="16" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.00004 6.66699C9.36671 6.66699 9.68071 6.53633 9.94204 6.27499C10.2034 6.01366 10.3338
         5.69988 10.3334 5.33366C10.3334 4.96699 10.2027 4.65299 9.94138 4.39166C9.68004 4.13033 9.36626 3.99988 
         9.00004 4.00033C8.63338 4.00033 8.31938 4.13099 8.05804 4.39233C7.79671 4.65366 7.66626 4.96744 7.66671
          5.33366C7.66671 5.70033 7.79738 6.01433 8.05871 6.27566C8.32004 6.53699 8.63382 6.66744 9.00004 6.66699ZM6.33338
           9.33366H11.6667V8.96699C11.6667 8.46699 11.4223 8.06966 10.9334 7.77499C10.4445 7.48033 9.80004 7.33322 9.00004
            7.33366C8.20004 7.33366 7.5556 7.48099 7.06671 7.77566C6.57782 8.07033 6.33338 8.46744
             6.33338 8.96699V9.33366ZM1.66671 11.3337C1.30004 11.3337 0.986042 11.203 0.724708 10.9417C0.463375
              10.6803 0.332931 10.3665 0.333375 10.0003V2.00033C0.333375 1.63366 0.464042 1.31966 0.725375
               1.05833C0.986709 0.796993 1.30049 0.666549 1.66671 0.666993H5.66671L7.00004 2.00033H12.3334C12.7
                2.00033 13.014 2.13099 13.2754 2.39233C13.5367 2.65366 13.6672 2.96744 13.6667 3.33366V10.0003C13.6667
                 10.367 13.536 10.681 13.2747 10.9423C13.0134 11.2037 12.6996 11.3341 12.3334 11.3337H1.66671ZM1.66671
                  2.00033V10.0003H12.3334V3.33366H6.45004L5.11671 2.00033H1.66671Z" fill="#888888"/>
        </svg>
        
    );
  };
  SvgPicupload.defaultProps = defaultProps;
  
  export default SvgPicupload;