import * as React from 'react';
const SvgRolling = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={9}
    fill="none"
    viewBox="0 0 9 9"
    {...props}
  >
    <path
      fill="#581845"
      d="M4.5 9c-1.256 0-2.32-.436-3.192-1.308C.436 6.82 0 5.756 0 4.5c0-1.256.436-2.32 1.308-3.192C2.18.436 3.244 0 4.5 0c.647 0 1.266.134 1.856.4.59.267 1.097.65 1.519 1.147V0H9v3.938H5.062V2.812h2.363c-.3-.524-.71-.937-1.23-1.237-.52-.3-1.085-.45-1.695-.45-.938 0-1.734.328-2.39.984-.657.657-.985 1.454-.985 2.391 0 .938.328 1.734.984 2.39.657.657 1.454.985 2.391.985.722 0 1.373-.206 1.955-.619.58-.412.989-.956 1.223-1.631H8.86a4.441 4.441 0 0 1-1.603 2.433A4.364 4.364 0 0 1 4.5 9Z"
    />
  </svg>
);
export default SvgRolling;