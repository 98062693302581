export const freeData = [
  { value: "<div><strong>1 Active Job</strong> Per Account.</div>" },
  { value: "<div><strong>AI Resume Parsing</strong> Up to <strong>10</strong> per month.</div>" },
  { value: "<div><strong>AI Matching and Descriptive Analysis</strong> Up to <strong>10</strong> per month.</div>" },
  { value: "<div><strong>All Other features</strong> available in Premium.</div>" },
  { value: "<div><strong>Sourcing contact</strong> unlock credit <strong>2</strong> for the free trail.</div>" },
];

export const standard = [
  { value: "<div><strong>Job Postings</strong> Up to <strong>20</strong> per month</div>" },
  { value: "<div><strong>AI Resume Parsing</strong> Up to <strong>200</strong> per month</div>" },
  { value: "<div><strong>Advanced AI Matching</strong> and Filtering</div>" },
  { value: "<div>Import Applicants & Passive Candidates</div>" },
  { value: "<div><strong>7M+</strong> resume Database</div>" },
  { value: "<div><strong>Interview and Assessment Tools</strong></div>" },
  { value: "<div>Access to Advanced reporting tools</div>" },
  { value: "<div>Additional <strong>Add-Ons</strong></div>" },
];

export const premium = [
  { value: "<div><strong>Job Postings</strong> Up to <strong>60</strong> per month</div>" },
  { value: "<div><strong>AI Resume Parsing</strong> Up to <strong>600</strong> per month</div>" },
  { value: "<div><strong>Advanced AI Matching</strong> and Filtering</div>" },
  { value: "<div>Import Applicants & Passive Candidates</div>" },
  { value: "<div><strong>7M+</strong> resume Database</div>" },
  { value: "<div><strong>Interview and Assessment Tools</strong></div>" },
  { value: "<div>Access to<strong> Advanced reporting tools</strong></div>" },
  { value: "<div>Additional <strong>Add-Ons</strong></div>" },
];

export const enterprise = [
  { value: "<div><strong>Job Postings</strong> Up to <strong>120</strong> per month</div>" },
  { value: "<div><strong>AI Resume Parsing</strong> Up to <strong>1200</strong> per month</div>" },
  { value: "<div><strong>Advanced AI Matching</strong> and Filtering</div>" },
  { value: "<div>Import Applicants & Passive Candidates</div>" },
  { value: "<div><strong>7M+</strong> resume Database</div>" },
  { value: "<div><strong>Interview and Assessment Tools</strong></div>" },
  { value: "<div>Access to <strong>Advanced reporting tools</strong></div>" },
  { value: "<div>Additional <strong>Add-Ons</strong></div>" },
  { value: "<div>Application <strong>Customization</strong></div>" },
];


export const feedbackData = [
  {
    label: `I'm moving to other service.`,
    value: `I'm moving to other service.`,
  },
  {
    label: `The service didn’t meet my expectations.`,
    value: 'The service didn’t meet my expectations.',
  },
  {
    label: `I had trouble setting it up.`,
    value: `I had trouble setting it up.`,
  },
  {
    label: `The service is too expensive.`,
    value: `The service is too expensive.`,
  },
  {
    label: `I only needed it for a limited time.`,
    value: `I only needed it for a limited time.`,
  },
  {
    label: `Others`,
    value: '6',
  },
];
