import axios from 'axios';
import classNames from 'classnames/bind';
import moment from 'moment';
import { createRef, useEffect, useState, useRef } from 'react';

import { useDispatch } from 'react-redux';
import SvgBell from '../../../icons/SvgBell';
import SvgClose from '../../../icons/SvgClose';
import SvgHelp from '../../../icons/SvgHelp';
import SvgDocument from '../../../icons/SvgDocument';
import SvgSupport from '../../../icons/SvgSupport';
import SvgMailsupport from '../../../icons/SvgMailsupport';
import SvgSubcriptioncrown from '../../../icons/Subscriptioncrown';
import SvgPrioritysupport from '../../../icons/SvgPrioritysupport';
import { notificationApi } from '../../../routes/apiRoutes';
import { AppDispatch } from '../../../store';
import Modal from '../../../uikit/Modal/Modal';
import Button from '../../../uikit/Button/Button';
import SubscriptionModal from '../../../modules/subscriptionmodule/subscriptionmoduleScreen';
import Card from '../../../uikit/Card/Card';
import Flex from '../../../uikit/Flex/Flex';
import CustomTooltip from '../../../uikit/Tooltip/tooltip';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import Text from '../../../uikit/Text/Text';
import Toast from '../../../uikit/Toast/Toast';
import ProfileView from '../../applicantpipelinemodule/ProfileView';
import { OthersEntity } from './navbarTypes';
import styles from './notification.module.css';
import {
  notificationDeleteMiddleWare,
  notificationPostMiddleWare,
} from './store/navbarmiddleware';

const cx = classNames.bind(styles);
type Props = {
  plan?: number[];
  user_permission?: string[];
  current_plan?: number;
  priority?: boolean | null;
  show?: boolean;
  color?: any;
};
const Notification = ({
  plan,
  show,
  current_plan,
  user_permission,
  priority,
  color,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [isData, setData] = useState<any>();
  const myRef = createRef<any>();
  const dropDownRef = useRef(null);
  const [isopensubcription, setopensubcription] = useState(false);
  const handleClickOutside = (event: { target: any }) => {
    if (myRef.current && !myRef.current.contains(event.target)) {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (typeof Window !== 'undefined') {
      document.addEventListener('click', handleClickOutside, true);
    }
    return () => {
      if (myRef) {
        if (typeof Window !== 'undefined') {
          document.removeEventListener('click', handleClickOutside, true);
        }
      }
    };
  });

  const update = () => {
    setOpen(false);
    setopensubcription(true);
  };

  const handleEmailCommunication = (e) => {
    setOpen(false);
    e.preventDefault();
    const toAddress = 'support@zita.ai';
    const mailtoLink = `mailto:${toAddress}`;
    try {
      window.open(mailtoLink, '_blank');
    } catch (error) {}
    //window.open(mailtoLink, '_blank');
  };

  const documents = () => {
    window.open('https://zita.ai/knowledgebase', '_blank');
  };

  const help_click = () => {
    if (show === true) {
      window.open(
        'https://share.hsforms.com/1_3IRNtXMTI6Xa3AGdP_pVwqmqrf',
        '_blank',
      );
    } else {
      window.open(
        'https://share.hsforms.com/1PpyfSm-ARC6q35HA-T58WQqmqrf',
        '_blank',
      );
    }
  };

  const Priority_validation = () => {
    if (user_permission.includes('Priority Support') === true) {
      return (
        <Flex
          row
          className={styles.card_data}
          onClick={(e) => handleEmailCommunication(e)}
          title="Priority Support"
        >
          <Flex>
            <SvgPrioritysupport />
          </Flex>

          <Flex marginLeft={10}>
            <Text color="theme" style={{ cursor: 'pointer' }}>
              Priority Support
            </Text>
          </Flex>
        </Flex>
      );
    } else {
      if (plan.includes(current_plan) === true) {
        if (priority === true) {
          return (
            <Flex
              row
              className={styles.card_data}
              onClick={(e) => handleEmailCommunication(e)}
            >
              <Flex>
                <SvgPrioritysupport />
              </Flex>

              <Flex marginLeft={10}>
                <Text color="theme" style={{ cursor: 'pointer' }}>
                  Priority Support
                </Text>
              </Flex>
            </Flex>
          );
        } else {
          return (
            <Flex row className={styles.card_data} onClick={() => update()}>
              <Flex>
                <SvgPrioritysupport />
              </Flex>

              <Flex marginLeft={10}>
                <Text color="theme" style={{ cursor: 'pointer' }}>
                  Priority Support
                </Text>
              </Flex>
              <Flex marginLeft={5}>
                <SvgSubcriptioncrown />
              </Flex>
            </Flex>
          );
        }
      }
    }
  };

  return (
    <Flex>
      <div ref={myRef} style={{ position: 'relative' }}>
        <CustomTooltip title="Help">
          <div
            onClick={() => setOpen(!isOpen)}
            tabIndex={-1}
            onKeyDown={() => {}}
            role="button"
            className={styles.hoverback}
            style={{ position: 'relative', padding: ' 5px' }}
          >
            <SvgHelp fill={color} height={22} width={22} />
          </div>
        </CustomTooltip>
        {isOpen && (
          <Card className={styles.help}>
            <Flex style={{ padding: '12px' }}>
              <Flex
                row
                className={styles.card_data}
                onClick={() => documents()}
              >
                <Flex>
                  <SvgDocument />
                </Flex>
                <Flex marginLeft={10}>
                  <Text color="theme" style={{ cursor: 'pointer' }}>
                    Documentation
                  </Text>
                </Flex>
              </Flex>

              <Flex
                row
                className={styles.card_data}
                onClick={() => help_click()}
              >
                <Flex>
                  <SvgSupport />
                </Flex>
                <Flex marginLeft={10}>
                  <Text color="theme" style={{ cursor: 'pointer' }}>
                    Support Form
                  </Text>
                </Flex>
              </Flex>
              <Flex
                row
                className={styles.card_data}
                onClick={(e) => handleEmailCommunication(e)}
              >
                <Flex>
                  <SvgMailsupport />
                </Flex>
                <Flex marginLeft={10}>
                  <Text color="theme" style={{ cursor: 'pointer' }}>
                    Email Support
                  </Text>
                </Flex>
              </Flex>

              {show === true && Priority_validation()}
            </Flex>
          </Card>
        )}
      </div>

      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          addon_name={['Priority Support']}
          differece_key={['Ai_comparative']}
        />
      )}
    </Flex>
  );
};

export default Notification;
