import { boolean } from 'yup';
import Flex from '../../uikit/Flex/Flex';
import Loader from '../../uikit/Loader/Loader';
import Modal from '../../uikit/Modal/Modal';
import Text from '../../uikit/Text/Text';
import { Button } from '../../uikit';
import SvgClose from '../../icons/SvgClose';

type Props = {
    open:any;
    setOpenPlanDetails:any;
    value:number;
    finalcandidates:number;
    dispatchfunction:any;
}

const Detailsmodel = ({
    open,
    setOpenPlanDetails,
    value,
    finalcandidates,
    dispatchfunction,
    
}: Props) => {
    return (
        <Modal open={open}>
            <Flex style={{ padding: '25px', backgroundColor: 'white' }} width={603}>
                <Flex end onClick={(() => setOpenPlanDetails(false))} marginBottom={15}><SvgClose width={10} height={10} fill={'#888888'} cursor={'pointer'} /></Flex>
                <Flex >
                    <Text>
                        You&rsquo;ve chosen <Text bold>{value}</Text> candidates but you only have already unlocked <Text bold>{value-finalcandidates}</Text> candidates, remaining <Text bold style={{ marginRight: '2.5px' }}>{finalcandidates}</Text>
                        candidates are going for futher unlocking process.
                    </Text>
                </Flex>
                <Flex style={{display:'flex',alignItems:'center'}} marginTop={20}>
                    <Flex onClick={() => {
                        setOpenPlanDetails(false);
                        dispatchfunction()
                    }}>
                        <Button>OK</Button>
                    </Flex>
                </Flex>
            </Flex>
        </Modal>
    )
}
export default Detailsmodel;