import * as React from 'react';
const SvgLinkedinFooter = (props) => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    viewBox="0 0 20 20"
    {...props}
>
<path
      fill="#cbcbcb"
      fillRule="evenodd"
      d="M20 20h-4v-6.999c0-1.92-.847-2.991-2.366-2.991-1.653 0-2.634 1.116-2.634 2.991V20H7V7h4v1.462s1.255-2.202 4.083-2.202S20 7.986 20 11.558zM2.442 4.921A2.45 2.45 0 0 1 0 2.46 2.45 2.45 0 0 1 2.442 0a2.45 2.45 0 0 1 2.441 2.46 2.45 2.45 0 0 1-2.441 2.461M0 20h5V7H0z"
    />
</svg>
);
export default SvgLinkedinFooter;