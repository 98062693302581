/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
  width: 22,
  height: 22,
  fill: '#FFD700',
};

const SvgDoublestar = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 87 84"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.2588 48.5324L50.4488 41.6324C46.6288 39.9624 43.5788 36.9124 41.9088 33.0924L35.0088 17.2824C34.4688 16.0524 32.7388 16.0524 32.1988 17.2824L25.2988 33.0924C23.6288 36.9124 20.5788 39.9624 16.7588 41.6324L0.918754 48.5424C-0.301246 49.0724 -0.311246 50.8124 0.918754 51.3524L17.0288 58.4724C20.8388 60.1624 23.8788 63.2224 25.5288 67.0524L32.2088 82.5624C32.7388 83.7924 34.4888 83.8024 35.0188 82.5624L41.9088 66.7724C43.5788 62.9524 46.6288 59.9024 50.4488 58.2324L66.2588 51.3324C67.4888 50.7924 67.4888 49.0624 66.2588 48.5224V48.5324Z"
        fill={fill}
      />
      <path
        d="M86.0486 18.5925L76.9086 14.6025C74.6986 13.6425 72.9386 11.8725 71.9786 9.6725L67.9886 0.5325C67.6786 -0.1775 66.6786 -0.1775 66.3686 0.5325L62.3786 9.6725C61.4186 11.8825 59.6486 13.6425 57.4486 14.6025L48.2986 18.5925C47.5886 18.9025 47.5886 19.9025 48.2986 20.2125L57.6086 24.3325C59.8086 25.3025 61.5686 27.0825 62.5186 29.2925L66.3786 38.2525C66.6886 38.9625 67.6986 38.9625 68.0086 38.2525L71.9886 29.1325C72.9486 26.9225 74.7186 25.1625 76.9186 24.2025L86.0586 20.2125C86.7686 19.9025 86.7686 18.9025 86.0586 18.5925H86.0486Z"
        fill={fill}
      />
    </svg>
  );
};

SvgDoublestar.defaultProps = defaultProps;

export default SvgDoublestar;
