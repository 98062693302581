import * as React from 'react';
const SvgNotificationPref = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={27}
    fill="none"
    viewBox='0 0 27 27'
    {...props}
  >
    <path
      stroke="#581845"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.714 12.252c.757 6.72 3.733 8.751 3.733 8.751H1s3.908-2.666 3.908-12.002c0-2.121.823-4.157 2.289-5.657A7.73 7.73 0 0 1 12.724 1q.66 0 1.302.113m.951 23.641a2.56 2.56 0 0 1-.953.912 2.69 2.69 0 0 1-2.6 0 2.56 2.56 0 0 1-.954-.912M21.842 8.501c1.037 0 2.03-.395 2.763-1.098A3.68 3.68 0 0 0 25.75 4.75c0-.995-.412-1.95-1.145-2.652A4 4 0 0 0 21.842 1c-1.036 0-2.03.395-2.763 1.099a3.68 3.68 0 0 0-1.145 2.652c0 .994.412 1.948 1.145 2.652A4 4 0 0 0 21.842 8.5"
    />
  </svg>
);
export default SvgNotificationPref;