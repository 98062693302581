import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import toast from 'react-hot-toast';
import { InputText, Text } from '../../uikit';

import { AppDispatch } from '../../store';
import { friendsEventsMiddleware } from '../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
import { Modal } from '../../uikit/v2';
import { TopLineLoader } from '../../uikit/v2/Loader';
import styles from './styles/addInterviewersUI.module.css';
import './styles/addInterviewers.css';
import {
  CalendarEventType,
  EditEventDetails,
  GoogleEventType,
  InterviewInfo,
  OutlookEventType,
  TeamMemberType,
  UserInfo,
  UserType,
} from './types';
import { getUsersByCompanyIdMiddleware } from './store/middleware/calendarmiddleware';
import SelectTeamMemberCheckBox from './SelectTeamMemberIcon';
import { getColor } from './colors';
import { formatEventTitle } from './util';
import SimpleToolBar from './calendar-components/SimpleToolBar';
import ColorEvent from './calendar-components/ColorEvent';
import WeekHeader from './calendar-components/WeekHeader';

import 'react-big-calendar/lib/css/react-big-calendar.css';

// import WeekHeader from './calendar-components/WeekHeader';

let localizer = momentLocalizer(moment);
interface TeamInfo {
  id: number;
  name: string;
  email: string;
}

type Props = {
  openAddInterviewerModal: boolean;
  teamMembers: TeamMemberType[];
  currentUserEvents: CalendarEventType[];
  userLabel: string;
  jd: string;
  username: string;
  selectedInterviewers: any;
  currentUser: UserInfo;
  closeAddInterviewerSlider: () => void;
  addTeamInterviewer: (info: InterviewInfo) => void;
  removeTeamInterviewer: (info: InterviewInfo) => void;
  editEventDetails?: EditEventDetails | null;
  setlist: any;
  updatestate: any;
  interviewer?: any;
  formik?: any;
  meetingForm?: any;
};

const AddInterviewersUI = ({
  openAddInterviewerModal,
  closeAddInterviewerSlider,
  teamMembers,
  currentUserEvents,
  addTeamInterviewer,
  removeTeamInterviewer,
  editEventDetails,
  meetingForm,
  userLabel,
  jd,
  username,
  selectedInterviewers,
  currentUser,
  setlist,
  updatestate,
  interviewer,
  formik,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [myTeam, setMyTeam] = useState<TeamMemberType[]>();
  const [isdispatch, setdispatch] = useState<any>(false);
  const [teamMemberEvents, setTeamMemberEvents] =
    useState<CalendarEventType[]>(currentUserEvents);
  const [searchTerm, setSearchTerm] = useState<string>();
  useEffect(() => {
    dispatch(getUsersByCompanyIdMiddleware()).then((res) => {
      if (res.payload.users.length > 0) {
        const interviewers = res.payload.users.map((user: TeamMemberType) => {
          return {
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            role: '',
            calendarEmail: user.calendarEmail,
          };
        });
        setMyTeam(interviewers);
      }
    });
  }, []);

  useEffect(() => {
    if (
      meetingForm &&
      meetingForm?.interviewer?.filter(
        (data) => data.userId !== currentUser?.id,
      )?.length > 0 &&
      isdispatch === false
    ) {
      meetingForm?.interviewer
        ?.filter((data) => data.userId !== currentUser?.id)
        .map((member, ids) => {
          setIsLoading(true);
          dispatch(friendsEventsMiddleware({ userId: member.userId }))
            .then((res) => {
              if (res.payload.account === 'Not Found') {
                toast.error('User does not have calendar integration', {
                  duration: 3500,
                });
              } else if (res.payload.account === 'Google') {
                setTeamMemberEvents((prevState) => [
                  ...prevState,
                  ...res.payload.events.map((items: GoogleEventType) => {
                    const eventData: any = {
                      userId: member.userId,
                      title: items.summary,
                      start: new Date(items.start.dateTime),
                      end: new Date(items.start.dateTime),
                      link: 'hangoutLink' in items ? items.hangoutLink : '',
                      eventId: items.id,
                      organizer: items.organizer.email,
                    };

                    if ('attendees' in items) {
                      eventData['attendees'] = items.attendees.map(
                        (attendee: { email: any }) => {
                          return attendee.email;
                        },
                      );
                    }
                    return eventData;
                  }),
                ]);
              } else if (res.payload.account === 'Outlook') {
                setTeamMemberEvents((prevState) => [
                  ...prevState,
                  ...res.payload.events.map((items: OutlookEventType) => {
                    return {
                      userId: member.userId,
                      title: items.title,
                      start: new Date(items.start_time),
                      end: new Date(items.end_time),
                      eventId: items.event_id,
                      attendees: items.attendees,
                      organizer: items.created_by,
                      link: '',
                    };
                  }),
                ]);
              }
            })
            .finally(() => setIsLoading(false));
        });
    }
  }, []);
  const handleSelectInterviewers = (member: TeamMemberType) => {
    setdispatch(true);
    setSearchTerm('');
    setlist(member);
    updatestate(member);
    if (meetingForm && meetingForm.length > 0) {
      if (
        meetingForm?.interviewer?.filter(
          (user) => user?.userId === member?.userId,
        ).length === 0
      ) {
        addTeamInterviewer(member);
        setIsLoading(true);
        dispatch(friendsEventsMiddleware({ userId: member.userId }))
          .then((res) => {
            if (res.payload.account === 'Not Found') {
              toast.error('User does not have calendar integration', {
                duration: 3500,
              });
            } else if (res.payload.account === 'Google') {
              setTeamMemberEvents((prevState) => [
                ...prevState,
                ...res.payload.events.map((items: GoogleEventType) => {
                  const eventData: any = {
                    userId: member.userId,
                    title: items.summary,
                    start: new Date(items.start.dateTime),
                    end: new Date(items.start.dateTime),
                    link: 'hangoutLink' in items ? items.hangoutLink : '',
                    eventId: items.id,
                    organizer: items.organizer.email,
                  };

                  if ('attendees' in items) {
                    eventData['attendees'] = items.attendees.map(
                      (attendee: { email: any }) => {
                        return attendee.email;
                      },
                    );
                  }
                  return eventData;
                }),
              ]);
            } else if (res.payload.account === 'Outlook') {
              setTeamMemberEvents((prevState) => [
                ...prevState,
                ...res.payload.events.map((items: OutlookEventType) => {
                  return {
                    userId: member.userId,
                    title: items.title,
                    start: new Date(items.start_time),
                    end: new Date(items.end_time),
                    eventId: items.event_id,
                    attendees: items.attendees,
                    organizer: items.created_by,
                    link: '',
                  };
                }),
              ]);
            }
          })
          .finally(() => setIsLoading(false));
      } else {
        removeTeamInterviewer(member);
        setTeamMemberEvents((events) =>
          events.filter(
            (event) => Number(event.userId) !== Number(member.userId),
          ),
        );
      }
    } else {
      if (
        meetingForm?.interviewer?.filter(
          (user) => user?.userId === member?.userId,
        ).length === 0
      ) {
        addTeamInterviewer(member);
        setIsLoading(true);
        dispatch(friendsEventsMiddleware({ userId: member.userId }))
          .then((res) => {
            if (res?.payload?.account === 'Not Found') {
              toast.error('User does not have calendar integration', {
                duration: 3500,
              });
            } else if (res?.payload?.account === 'Google') {
              setTeamMemberEvents((prevState) => [
                ...prevState,
                ...res.payload.events.map((items: GoogleEventType) => {
                  const eventData: any = {
                    userId: member.userId,
                    title: items.summary,
                    start: new Date(items.start.dateTime),
                    end: new Date(items.start.dateTime),
                    link: 'hangoutLink' in items ? items.hangoutLink : '',
                    eventId: items.id,
                    organizer: items.organizer.email,
                  };

                  if ('attendees' in items) {
                    eventData['attendees'] = items.attendees.map(
                      (attendee: { email: any }) => {
                        return attendee.email;
                      },
                    );
                  }
                  return eventData;
                }),
              ]);
            } else if (res?.payload?.account === 'Outlook') {
              setTeamMemberEvents((prevState) => [
                ...prevState,
                ...res.payload.events.map((items: OutlookEventType) => {
                  return {
                    userId: member.userId,
                    title: items.title,
                    start: new Date(items.start_time),
                    end: new Date(items.end_time),
                    eventId: items.event_id,
                    attendees: items.attendees,
                    organizer: items.created_by,
                    link: '',
                  };
                }),
              ]);
            }
          })
          .finally(() => setIsLoading(false));
      } else {
        removeTeamInterviewer(member);
        setTeamMemberEvents((events) =>
          events.filter(
            (event) => Number(event.userId) !== Number(member.userId),
          ),
        );
      }
    }
  };

  const getMemberFullName = (member: TeamMemberType) => {
    return `${member.firstName} ${member.lastName}`;
  };

  const InterviewerDropDown = (
    <>
      <TopLineLoader show={isLoading} />

      <div>
        <div className={styles.inputSearchWrapper}>
          <InputText
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
            placeholder={'Select team members'}
            value={searchTerm}
            className={styles.inputSearch}
          />
        </div>
        <div style={{ margin: '2px 4px' }}>
          {myTeam
            ? (() => {
                const filteredMembers = myTeam.filter((member) => {
                  if (!searchTerm) {
                    return true;
                  }
                  return getMemberFullName(member)
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
                });

                if (filteredMembers.length === 0) {
                  return (
                    <div className={styles.checkboxContainer}>
                      <Text style={{ marginLeft: '3px', color: '#888888' }}>
                        No interviewer found
                      </Text>
                    </div>
                  ); // Display this message when no members match the search term
                }

                return filteredMembers.map((member, index) => (
                  <SelectTeamMemberCheckBox
                    label={getMemberFullName(member)}
                    checked={
                      editEventDetails
                        ? selectedInterviewers?.some(
                            (user) => user?.userId === member?.userId,
                          ) || currentUser?.id === member?.userId
                        : formik?.values?.interviewers?.some(
                            (user) => user?.userId === member?.userId,
                          ) || currentUser?.id === member?.userId
                    }
                    disabled={currentUser.id === member.userId}
                    color={getColor(member.userId)}
                    onClick={() => handleSelectInterviewers(member)}
                    key={index}
                    loader={isLoading}
                  />
                ));
              })()
            : ''}
        </div>
      </div>
    </>
  );

  return (
    <Modal
      open={openAddInterviewerModal}
      onClose={() => {
        closeAddInterviewerSlider();
      }}
      center={false}
      top={0}
      right={0}
      backgroundColor={'none'}
      boxShadow={false}
    >
      <div className={styles.addInterviewerContainer}>
        <div className={styles.addInterviewer}>
          <div className={styles.menus}>{InterviewerDropDown}</div>
          <div
            className={styles.calenderContent}
            style={{ width: window.innerWidth - 370 }}
          >
            {currentUserEvents && (
              <BigCalendar
                localizer={localizer}
                events={teamMemberEvents}
                defaultView={'day'}
                views={['day', 'work_week', 'week', 'month']}
                dayLayoutAlgorithm={'no-overlap'}
                selectable
                popup={true}
                className={styles.calendar}
                titleAccessor={formatEventTitle}
                formats={{
                  eventTimeRangeFormat: () => '',
                }}
                // onShowMore={(events, date) =>
                //       useState({ showModal: true, events })
                //     }
                showAllEvents={true}
                components={{
                  toolbar: SimpleToolBar,
                  event: ColorEvent,
                  week: {
                    header: WeekHeader,
                  },
                  work_week: {
                    header: WeekHeader,
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddInterviewersUI;
