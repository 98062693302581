const SvgCollapse = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        fill="#581845"
        d="M19.563.515c.279.329.418.747.418 1.254 
      0 .508-.14.926-.418 1.255l-5.888 6.987L19.563 17c.279.328.424.74.436 
      1.233a1.83 1.83 0 0 1-.436 1.275A1.333 1.333 0 0 1 18.5 20c-.431 
      0-.786-.164-1.064-.493l-6.99-8.242a1.632 1.632 0 0 1-.323-.582 2.324 2.324 
      0 0 1-.095-.672c0-.239.032-.463.095-.672.064-.209.171-.403.323-.582l6.99-8.242c.278-.328.627-.5
       1.045-.514.418-.016.778.156 1.082.514Zm-10.028 0c.278.329.417.747.417
        1.254 0 .508-.139.926-.417 1.255L3.647 10.01 9.535 17c.278.328.424.74.437
         1.233.012.492-.133.917-.437 1.275A1.333 1.333 
         0 0 1 8.47 20c-.43 0-.785-.164-1.064-.493l-6.99-8.242a1.612 1.612 
         0 0 1-.321-.582A2.29 2.29 0 0 1 0 
         10.011c0-.239.032-.463.096-.672.063-.209.17-.403.322-.582L7.408.515A1.38 1.38 
         0 0 1 8.45.001c.419-.016.78.156 1.084.514Z"
      />
    </svg>
  );
};


export default SvgCollapse;
