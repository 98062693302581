/* eslint max-len: ["error", { "code": 2000 }] */

const SvgDashboardw = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="-2.4 -2.4 28.8 28.8"
    {...props}
  >
    <g fill="none" fillRule="evenodd" strokeWidth={1.32}>
      <path d="M0 0h24v24H0z" />
      <rect
        width={16}
        height={16}
        x={4}
        y={4}
        stroke="#ffffff"
        strokeLinecap="round"
        rx={2}
      />
      <path stroke="#ffffff" strokeLinecap="round" d="M4 9h16M9 10v10" />
    </g>
  </svg>
  );
  
  export default SvgDashboardw