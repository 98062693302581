import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import SvgLogout from '../../../icons/SvgLogOut';
import SvgSetting from '../../../icons/SvgSetting';
import SvgUser from '../../../icons/SvgUser';
import SvgLocknav from '../../../icons/SvgLocknav';
import { AppDispatch, RootState } from '../../../store';
import Flex from '../../../uikit/Flex/Flex';
import Avatar, { getUserInitials } from '../../../uikit/Avatar';
import { isEmpty } from '../../../uikit/helper';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import Loader from '../../../uikit/Loader/Loader';
import Text from '../../../uikit/Text/Text';
import CustomTooltip from '../../../uikit/Tooltip/tooltip';
import { mediaPath } from '../../constValue';
import { notificationApi } from '../../../routes/apiRoutes';
import Help from '../empnavbar/help';
import {
  logOutMiddleWare,
  navBarMiddleWare,
} from '../store/middleware/navbarmiddleware';
import styles from './navbarcandidate.module.css';
import CandidateNotification from './CandidateNotification';

const cx = classNames.bind(styles);
type Props = {
  update?: () => void;
  setjd_id?: (val: any) => void;
  setnotificationdata?: (val: any) => void;
};
const NavBarCandidate = ({ update, setjd_id, setnotificationdata }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isLogOutLoader, setLogOutLoader] = useState(false);
  const [isData, setData] = useState<any>();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(navBarMiddleWare());
  }, []);

  // Calling notification API for fetching the notification data.
  useEffect(() => {
    const interval = setInterval(() => {
      axios.get(notificationApi).then((res) => {
        setData(res.data);
      });
    }, 4500);
    return () => clearInterval(interval);
  }, []);

  const { company_detail, setting, profile, user_info } = useSelector(
    ({ navBarReducers, dashboardReducers }: RootState) => {
      return {
        company_detail: navBarReducers.company_detail,
        user_info: dashboardReducers.user_info,
        setting: dashboardReducers.setting,
        profile: dashboardReducers.profile,
      };
    },
  );

  // logout function
  const handleLogout = () => {
    setLogOutLoader(true);
    dispatch(logOutMiddleWare()).then((res) => {
      setLogOutLoader(false);
      localStorage.clear();
      sessionStorage.clear();
      history.push(`/login_candidate`);
      //window.location.replace(window.location.origin + '/login_candidate');
    });
  };

  // Onclick function for password change.
  const handlepassword = () => {
    update();
  };

  return (
    <div className={styles.overAll}>
      {isLogOutLoader && <Loader />}
      <Flex row center between>
        <Flex row center>
          {!isEmpty(company_detail?.logo) && (
            <img
              style={{ objectFit: 'contain' }}
              alt="logo"
              src={mediaPath + company_detail?.logo}
              className={styles.profile}
            />
          )}

          <LinkWrapper>
            <Text
              color="white"
              className={cx('linkTitle', { navFocusColor: pathname === '/' })}
            >
              My Dashboard
            </Text>
          </LinkWrapper>
          <LinkWrapper to={`/${setting?.career_page_url}/careers`}>
            <Text color="white" className={cx('linkTitle')}>
              Careers Page
            </Text>
          </LinkWrapper>
          <LinkWrapper
            to={`/candidate_profile_edit/${localStorage.getItem(
              'loginUserId',
            )}`}
          >
            <Text color="white" className={cx('linkTitle')}>
              Edit Profile
            </Text>
          </LinkWrapper>
        </Flex>
        <Flex row center>
          <Flex>
            <Help show={false} color={'white'} />
          </Flex>
          <Flex>
            <CandidateNotification
              setjd_id={setjd_id}
              setnotificationdata={setnotificationdata}
            />
          </Flex>
          <div
            style={{
              width: '1px',
              height: '30px',
              backgroundColor: '#ffffff',
              marginLeft: '10px',
            }}
          ></div>
          <Flex marginLeft={4}>
            <Dropdown>
              <Dropdown.Toggle
                className={styles.dropdownhead}
                style={{
                  backgroundColor: '#581845',
                  border: '#581845',
                  boxShadow: 'none',
                  paddingRight: '0',
                }}
              >
                <SvgUser fill={'#ffffff'} height={25} width={25} />
              </Dropdown.Toggle>
              <Dropdown.Menu className={styles.dropdown_item}>
                <Dropdown.Item href="#" onClick={handlepassword}>
                  <Flex title=" Change Password">
                    <div
                      style={{
                        marginLeft: '-10px',
                        cursor: 'pointer',
                        padding: '3px 10px 3px 0px',
                      }}
                    >
                      <text style={{ verticalAlign: '3px' }}>
                        <SvgLocknav fill={'#581845'} height={22} width={18} />
                      </text>

                      <span
                        style={{
                          color: '#581845',
                          marginLeft: '10px',
                          cursor: 'pointer',
                          fontSize: '13px',
                        }}
                      >
                        Change Password
                      </span>
                    </div>
                  </Flex>
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={handleLogout}>
                  <Flex title="Logout">
                    <div
                      style={{
                        marginLeft: '-10px',
                        cursor: 'pointer',
                        padding: '3px 10px 3px 0px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      className={cx('svgMargin', {
                        navFocusColor: pathname.includes('/account_setting'),
                      })}
                    >
                      <text
                        style={{ verticalAlign: '3px', marginBottom: '3px' }}
                      >
                        <SvgLogout height={22} width={18} fill={'#581845'} />
                      </text>

                      <span
                        style={{
                          color: '#581845',
                          marginLeft: '10px',
                          cursor: 'pointer',
                          fontSize: '13px',
                        }}
                      >
                        Logout
                      </span>
                    </div>
                  </Flex>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default NavBarCandidate;
