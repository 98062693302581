// import * as React from 'react';
// const SvgLocationjobpost = (props) => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width={16}
//     height={16}
//     fill="none"
//     {...props}
//   >
//     <path
//       fill="#581845"
//       d="M8 0C3.577 0 0 2.504 0 5.6 0 9.8 8 16 8 16s8-6.2 8-10.4C16 2.504 12.423 0 8 0ZM2.286 5.6c0-2.208 2.56-4 5.714-4 3.154 0 5.714 1.792 5.714 4 0 2.304-3.291 5.752-5.714 7.904C5.623 11.368 2.286 7.88 2.286 5.6Z"
//     />
//     <path
//       fill="#581845"
//       d="M7.742 8.165c1.194 0 2.163-.73 2.163-1.633 0-.901-.969-1.633-2.163-1.633-1.195 0-2.163.732-2.163 1.633 0 .902.968 1.633 2.163 1.633Z"
//     />
//   </svg>
// );
// export default SvgLocationjobpost;
import * as React from 'react';
const SvgLocationjobpost = (props) => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width={16}
  //   height={16}
  //   fill="none"
  //   {...props}
  // viewBox="0 0 18 24"
  // >
  //   <path
  //     fill="#581845"
  //     d="M5 .738c-2.764 0-5 1.565-5 3.5 0 2.625 5 6.5 5 6.5s5-3.875 5-6.5c0-1.935-2.236-3.5-5-3.5Zm-3.571 3.5c0-1.38 1.6-2.5 3.57-2.5 1.972 0 3.572 1.12 3.572 2.5 0 1.44-2.057 3.595-3.571 4.94-1.486-1.335-3.571-3.515-3.571-4.94Z"
  //   />
  //   <path
  //     fill="#581845"
  //     d="M4.839 5.842c.746 0 1.352-.457 1.352-1.02 0-.564-.606-1.021-1.352-1.021-.747 0-1.352.457-1.352 1.02 0 .564.605 1.021 1.352 1.021Z"    
  //   />
  // </svg>
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={16}
  height={13.5}
  fill="none"
  viewBox="0 2 17 15" 
  
>
  <path
    fill="#581845"
    d="M8 2.259a4.663 4.663 0 0 0-4.667 4.666c0 3.5 4.667 8.667 4.667 8.667s4.667-5.167 4.667-8.667A4.663 4.663 0 0 0 8 2.26ZM4.667 6.925a3.335 3.335 0 0 1 6.667 0c0 1.92-1.92 4.794-3.334 6.587-1.386-1.78-3.333-4.687-3.333-6.587Z"
  />
  <path
   fill="#581845"
    d="M8 8.592A1.667 1.667 0 1 0 8 5.26a1.667 1.667 0 0 0 0 3.333Z"
  />
</svg>
);
export default SvgLocationjobpost;