import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Modal } from 'react-bootstrap';
import { AppDispatch, RootState } from '../../../../store';

import {
  Text,
  Flex,
  Button,
  Card,
  Toast,
  Loader,
  InputText,
  LinkWrapper,
} from '../../../../uikit';
import SvgAdd from '../../../../icons/SvgAdd';
import { jddeleteMiddleWare } from '../../store/middleware/accountsettingmiddleware';
import SvgEdit from '../../../../icons/SvgEdit';
import SvgDelete from '../../../../icons/SvgDelete';
import SvgNoData from '../../../../icons/SvgNoData';
import SvgSearch from '../../../../icons/SvgSearch';
import SvgDotMenu from '../../../../icons/SvgDotMenu';
import SvgBack from '../../../../icons/SvgBack';
import SvgNoDataIcon from '../../../../icons/SvgNoDataIcon';

import SvgIntomark from '../../../../icons/Intomark';
import { enterKeyPress, toCamelCase } from '../../../../uikit/helper';
import { jdTemplatesApiMiddleWare } from '../../../createjdmodule/store/middleware/createjdmiddleware';
import CancelAndDeletePopup from '../../../common/CancelAndDeletePopup';
import ViewjdModal from './viewjdModal';
import styles from './jdtemplateModule.module.css';
import JDopenModal from './jdopenModal';
import Table from './table';

type jdProps = {
  handleBack: () => void;
};
const jdtemplateModule = ({ handleBack }: jdProps) => {
  const dispatch: AppDispatch = useDispatch();
  const [itemvalue, setitemvalue] = useState<any>(null);
  const [editedId, setEditedId] = useState(null);
  const cardRefs = useRef([]);
  const myRef = useRef<any>();
  const { jd_templates, job_title, isloading, status } = useSelector(
    ({ jdTemplatesReducers }: RootState) => {
      return {
        isloading: jdTemplatesReducers.isLoading,
        job_title: jdTemplatesReducers.job_title,
        jd_templates: jdTemplatesReducers.jd_templates,
        status: jdTemplatesReducers.status,
      };
    },
  );
  const [isOpenJDModal, setOpenJDModal] = useState(false);
  const [opendelete, setopendelete] = useState(false);
  const [iddelete, setiddelete] = useState<any>();
  const [idview, setidview] = useState<any>();
  const [openview, setopenview] = useState(false);
  const [isSearch, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(jdTemplatesApiMiddleWare({ ds_role: '0', user: '2' })).then(
      (res) => { },
    );
  }, []);
  const [filteredJobs, setFilteredJobs] = useState(jd_templates);

  useEffect(() => {
    if (jd_templates.length !== 0) {
      setFilteredJobs(jd_templates);
    }
  }, [jd_templates]);

  // useEffect function for scroll and focus the perticular template data based on update or create the template.
  useEffect(() => {
    const targetElement = document.getElementById(editedId);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      // setEditedId(null)
    }
  }, [editedId, jd_templates, filteredJobs]);

  const handleJdModal = () => {
    setOpenJDModal(!isOpenJDModal);
    setEditedId(null);
    if (itemvalue !== null) {
      setitemvalue(null);
    }
  };

  function itemclose() {
    setitemvalue(null);
  }

  const closeview = () => {
    setopenview(false);
  };
  const oneditfunction = (item: any) => {
    handleJdModal();
    setitemvalue(item);
  };
  const onDeletefunction = (item: any) => {
    setopendelete(true);
    setiddelete(item.id);
  };
  const conformdeletefun = () => {
    const id = iddelete;
    dispatch(jddeleteMiddleWare(id)).then((res) => {
      if (res.payload.success === true) {
        setopendelete(false);
        setEditedId(null);
        dispatch(jdTemplatesApiMiddleWare({ ds_role: '0', user: '2' }));
        Toast('Template deleted successfully.', 'LONG', 'success');
      } else {
        Toast(
          'Oops! Something went wrong. Please try again shortly.',
          'LONG',
          'error',
        );
      }
    });
  };
  const openviewfun = (item: any) => {
    setopenview(true);
    setidview(item);
  };
  if (isloading) {
    return <Loader />;
  }
  const onClearSearch = () => {
    setSearch('');
    dispatch(
      jdTemplatesApiMiddleWare({ ds_role: '0', search: '', user: '2' }),
    ).then((res) => { });
  };
  const handleSearchSubmit = () => {
    filterJobs(searchTerm);
  };

  const handleInputChange = (event) => {
    // const value = event.target.value;
    setSearchTerm(event.target.value);
  };

  const filterJobs = (term) => {
    const filtered = jd_templates.filter((job) =>
      job.job_title.toLowerCase().includes(term.toLowerCase()),
    );
    setFilteredJobs(filtered);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredJobs(jd_templates);
  };

  return (
    <>
      <Flex
        column
        className={styles.overflowContainer}
        style={{ padding: '0px 10px' }}
      >
        <Flex row end className={styles.titleBar}>
          <Flex row center>
            <Flex row className={styles.searchstyle}>
              <Flex row className={styles.searchboxoverall}>
                <InputText
                  ref={myRef}
                  value={searchTerm}
                  className={styles.boxstyle}
                  onChange={(e) => handleInputChange(e)}
                  id="zitamatchfilters__search"
                  placeholder="Search by template title "
                  actionRight={() => (
                    <label
                      htmlFor={'zitamatchfilters__search'}
                      style={{ margin: 0 }}
                      //  onClick={handleSearchSubmit}
                      tabIndex={-1}
                      role={'button'} // eslint-disable-line
                    >
                      {searchTerm.trim() !== '' && (
                        <button
                          className={styles.crossIcon}
                          onClick={clearSearch}
                        >
                          <SvgIntomark width={14} height={14} fill="#888888" />
                        </button>
                      )}
                    </label>
                  )}
                  onKeyPress={(e) => enterKeyPress(e, handleSearchSubmit)}
                />

                <Flex
                  className={styles.searchicons}
                  onClick={handleSearchSubmit}
                >
                  <SvgSearch width={12} height={12} fill="#ffffff" />
                </Flex>
              </Flex>
            </Flex>
            <Button onClick={handleJdModal}>
              <Flex row center className={styles.pointer}>
                <SvgAdd height={10} width={10} fill="#FFFFFF" />
                <Text
                  bold
                  color="white"
                  size={13}
                  style={{ marginLeft: '10px' }}
                  className={styles.pointer}
                >
                  Add Template
                </Text>
              </Flex>
            </Button>
          </Flex>

          {openview && (
            <ViewjdModal
              open={true}
              setidview={setidview}
              idview={idview}
              closeview={closeview}
              oneditfunction={oneditfunction}
            />
          )}
        </Flex>
        {isOpenJDModal && (
          <>
            <JDopenModal
              open={true}
              handleJdModal={handleJdModal}
              itemvalue={itemvalue}
              itemclose={itemclose}
              job_title={job_title}
              jd_templates={jd_templates}
              setopenview={setopenview}
              setEditedId={setEditedId}
            />
          </>
        )}

        {status && status === '0' ? (
          <Flex
            center
            className={styles.nodatastyle}
            height={window.innerHeight - 207}
          >
            <Flex middle className={styles.overAll2}>
              <Flex center>
                <Flex center>
                  <Text className={styles.postyet1} size={13}>
                    No Job Templates Created - Yet !
                  </Text>
                </Flex>
                <Flex center>
                  <Text className={styles.postyet2} size={13}>
                    Looks like you haven’t create any job templates
                  </Text>
                </Flex>
                <Flex center>
                  <Text className={styles.postyet3} size={13}>
                    No worries, just click on “Add Template” to kick start
                  </Text>
                </Flex>
                <Flex center className={styles.postyet4}>
                  <div className={styles.makecenter}>
                    <Button onClick={handleJdModal}>
                      <Flex row center className={styles.pointer}>
                        <SvgAdd height={10} width={10} fill="#FFFFFF" />
                        <Text
                          bold
                          color="white"
                          size={13}
                          style={{ marginLeft: '10px' }}
                          className={styles.pointer}
                        >
                          Add Template
                        </Text>
                      </Flex>
                    </Button>{' '}
                  </div>{' '}
                </Flex>{' '}
              </Flex>{' '}
            </Flex>
          </Flex>
        ) : filteredJobs.length === 0 ? (
          <Flex
            className={styles.nodatastyle}
            height={window.innerHeight - 207}
          >
            <Flex flex={1} center middle style={{ display: 'flex' }}>
              <SvgNoData width={16} height={16} fill={'#888'} />
              <Text style={{ marginTop: '2px' }} color="placeholder">
                No templates available
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Flex className={styles.aligncards} height={window.innerHeight - 207}>
            <Table
              onDeletefunction={onDeletefunction}
              oneditfunction={oneditfunction}
              filteredData={filteredJobs}
              openviewfun={openviewfun}
            />
          </Flex>
        )}
      </Flex>
      {opendelete === true ? (
        <CancelAndDeletePopup
          btnCancel={() => setopendelete(false)}
          title={
            <Flex className={styles.popUpFlex}>
              <Flex column>
                <Flex>
                  <Text size={13} style={{ marginLeft: '10px' }}>
                    This action will permanently remove the template and its job
                    description.
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <Text
                  size={13}
                  style={{ marginLeft: '10px', marginTop: '2px' }}
                >
                  Are you sure to proceed?
                </Text>
              </Flex>
            </Flex>
          }
          btnDelete={conformdeletefun}
          btnLeft="Cancel"
          btnRight="Delete"
          open={opendelete} 
        />
      ) : (
        ''
      )}
    </>
  );
}; 
export default jdtemplateModule;
