import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import DOMPurify from 'dompurify';
import Toast from '../../uikit/Toast/Toast';
import {
  deletemail,
  movefolder,
  mailread,
  Gmail_unread_messages,
  Gmail_read_messages,
  Gmail_MessageToBin,
  getmail,
  move_to_spam,
  gmail_permanent_Delete,
  outlooktoken,
  getattachments,
} from '../../emailService';

import config from '../../outlookmailConfig';
import { Flex, Card, Text } from '../../uikit';
import SvgArchive from '../../icons/SvgArchive';
import { SvgEdit, SvgTrash } from '../../icons';
import SvgSpam from '../../icons/SvgSpam';
import SvgJunk from '../../icons/SvgJunk';
import SvgReply from '../../icons/SvgReply';
import SvgReplyall from '../../icons/SvgReplyall';
import SvgForward from '../../icons/SvgForward';
import SvgRead from '../../icons/SvgRead';
import SvgLeft from '../../icons/SvgLeft';
import SvgRight from '../../icons/SvgRight';
import SvgDownload from '../../icons/SvgDownload';
import SvgEmptyMail from '../../icons/SVGEmptyMail';
import styles from './message.module.css';

type Props = {
  message: any;
  sidebarroute: number;
  composemodal: () => void;
  removemsg: () => void;
  isprofileview?: boolean;
  page: () => void;
  attachments: any;
  msglistcount: any;
  integration: string;
  emailcollection: any;
  updateMailaction: (val: any) => void;
  remove_message: (id: any) => void;
  update_message: (id: any, val: boolean) => void;
  Emailsidebar?: any;
  noEmails?: any;
};
const Inbox = ({
  message,
  sidebarroute,
  composemodal,
  Emailsidebar,
  removemsg,
  isprofileview,
  page,
  attachments,
  remove_message,
  msglistcount,
  emailcollection,
  integration,
  updateMailaction,
  update_message,
  noEmails,
}: Props) => {
  const msal = useMsal();
  const [view, setview] = useState(true);
  const [read, setread] = useState(true);
  const [emailCheck, setemailCheck] = useState(false);
  const [image, setimage] = useState([]);
  const iframeRef = useRef(null);
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      account: msal.instance.getActiveAccount()!,
      scopes: config.scopes,

      interactionType: InteractionType.Popup,
    },
  );
  const selectinbox = () => {
    setview(!view);
  };

  const mail = (val) => {
    updateMailaction(val);
    composemodal();
  };

  const handle_disablefunction = () => {
    if (message !== '') {
      if (emailcollection.integration === 'outlook') {
        const emailValues = [
          ...emailcollection.maillist.map((item: any) => item.value),
          emailcollection.email,
        ];

        const bcc = message.bccRecipients.map(
          (item) => item.emailAddress.address,
        );
        const cc = message.ccRecipients.map(
          (item) => item.emailAddress.address,
        );
        const to = message.toRecipients.map(
          (item) => item.emailAddress.address,
        );
        const merged = [...bcc, ...cc, ...to];

        if (message.from !== undefined) {
          merged.push(message.from.emailAddress.address);
        }

        const allEmailsIncluded = merged.every((email) =>
          emailValues.some((e) => e.toLowerCase() === email.toLowerCase()),
        );
        setemailCheck(allEmailsIncluded);
      }

      if (emailcollection.integration === 'google') {
        const emailValues = [
          ...emailcollection.maillist.map((item: any) => item.value),
          emailcollection.email,
        ];

        const extractEmails = (value) => {
          const regex = /<([^>]+)>/g;
          return Array.from(value.matchAll(regex)).map((match) => match[1]);
        };

        const ToEmail = message.header
          .filter((item) => item.name === 'To')
          .flatMap((item) => extractEmails(item.value));

        const CcEmail = message.header
          .filter((item) => item.name === 'CC')
          .flatMap((item) => extractEmails(item.value));
        const From = message.header
          .filter((item) => item.name === 'From')
          .flatMap((item) => extractEmails(item.value));

        const Bcc = message.header
          .filter((item) => item.name === 'Bcc')
          .flatMap((item) => extractEmails(item.value));

        const val = [...ToEmail, ...CcEmail, ...Bcc, ...From];

        const merged: string[] = val.filter(
          (email, index, self) => self.indexOf(email) === index,
        );

        const allEmailsIncluded = merged.every((email) =>
          emailValues.some((e) => e.toLowerCase() === email.toLowerCase()),
        );
        setemailCheck(allEmailsIncluded);
      }
    }
  };

  useEffect(() => {
    if (integration === 'google') {
      readmessages();
    } else if (integration === 'outlook') {
      updatereadmessage();
    }
    handle_disablefunction();
  }, [message, integration, emailCheck]);

  const readmessages = async () => {
    const labelIds = message.labelIds || [];
    const isRead = !labelIds.includes('UNREAD');
    if (isRead === false && message !== '') {
      await Gmail_read_messages(message.id)
        .then((res) => {
          update_message(message.id, false);
        })
        .catch((error) => {});
    }
  };

  const updatereadmessage = async () => {
    if (message.isRead !== true && message !== '') {
      var readmessage = {
        IsRead: true,
      };
      outlooktoken(emailcollection.token).then(async () => {
        await mailread(message.id, readmessage)
          .then((res) => {
            update_message(message.id, true);
            //page();
          })
          .catch((error) => {});
      });
    }
  };

  const googleremove = async () => {
    if (sidebarroute === 5) {
      await gmail_permanent_Delete(message.id)
        .then((res) => {
          removemsg();
          Toast('Email permanently removed.', 'SHORT', 'success');
          Toast('Email permanently removed.', 'SHORT', 'success');
          remove_message(message.id);
        })
        .catch((error) => {});
    } else {
      await Gmail_MessageToBin(message.id)
        .then((res) => {
          removemsg();
          Toast('Email moved to trash successfully', 'SHORT', 'success');
          remove_message(message.id);
        })
        .catch((error) => {});
    }
  };

  const remove = async () => {
    if (message !== '') {
      if (sidebarroute === 5) {
        await deletemail(authProvider, message.id).then((res) => {
          removemsg();

          Toast('Email permanently deleted.', 'SHORT', 'success');
          remove_message(message.id);
        });
      } else {
        await movefolder(authProvider, message.id, 'deleteditems').then(
          (res) => {
            removemsg();

            Toast('Email deleted successfully.', 'SHORT', 'success');
            remove_message(message.id);
          },
        );
      }
    }
  };

  // const refetch = () => {
  //   if (sidebarroute === 1) {
  //     inboxapi();
  //   } else if (sidebarroute === 2) {
  //     senditemapi();
  //   } else if (sidebarroute === 3) {
  //     draftapi();
  //   } else if (sidebarroute === 4) {
  //     archiveapi();
  //   } else if (sidebarroute === 5) {
  //     deleteditemsapi();
  //   } else {
  //     junkemailapi();
  //   }
  // };

  const archive = async () => {
    if (message !== '') {
      await movefolder(authProvider, message.id, 'archive').then((res) => {
        removemsg();
        // page();
        Toast('Email archived successfully.', 'SHORT', 'success');
        remove_message(message.id);
      });
    }
  };

  const junk = async () => {
    if (message !== '') {
      await movefolder(authProvider, message.id, 'junkemail').then((res) => {
        removemsg();
        // page();
        Toast('Email moved to junk successfully.', 'SHORT', 'success');
        remove_message(message.id);
      });
    }
  };

  const unread = async (val: boolean) => {
    if (message !== '') {
      var readmessage = {
        IsRead: false,
      };
      await mailread(message.id, readmessage).then((res) => {
        removemsg();
        update_message(message.id, false);
      });
    }
  };

  const getattach = async (val) => {
    if (integration === 'outlook') {
      await getattachments(val)
        .then((res) => {
          setimage(res.value);
        })
        .catch((error) => {
          setimage([]);
        });
    }
  };

  useEffect(() => {
    if (message) {
      getattach(message.id);
    }
  }, [message]);

  useEffect(() => {
    if (integration === 'google') {
      const iframe = iframeRef.current;
      if (iframe && message?.body) {
        const content = processEmailContent(message.body);
        iframe.srcdoc = content;

        iframe.onload = () => {
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
          const links = iframeDocument.querySelectorAll('a');

          const style2 = iframeDocument.createElement('style');
          style2.textContent = `
      p {
           margin-block-start: 7px;
            margin-block-end: 0px;
        /* Add any other styles you want for <p> tag */
      }
    `;
          iframeDocument.head.appendChild(style2);

          links.forEach((link) => {
            link.addEventListener('click', (event) => {
              event.preventDefault();
              window.open(link.href, '_blank');
            });
          });
        };
      }
    }

    if (integration === 'outlook') {
      let htmlContent = '';

      // Check if message.body is a string or an object with a 'content' property
      if (typeof message.body === 'string') {
        htmlContent = message.body;
      } else if (message.body && typeof message.body.content === 'string') {
        htmlContent = message.body.content;
      }

      if (htmlContent) {
        const iframe = iframeRef.current;
        const processedContent = processEmailContent1(htmlContent, image);
        if (iframe) {
          iframe.srcdoc = processedContent;

          iframe.onload = () => {
            const iframeDocument =
              iframe.contentDocument || iframe.contentWindow?.document;
            const style1 = iframeDocument.createElement('style');
            style1.textContent = `
                body, div, font {
                  font-size: 13px !important;
                  font-family: 'Roboto', sans-serif;
                }
                html, body {
                  height: 500px;
                }
              `;
            iframeDocument.head.appendChild(style1);

            const style2 = iframeDocument.createElement('style');
            style2.textContent = `
      p {
           margin-block-start: 7px;
           margin-block-end: 0px;
        /* Add any other styles you want for <p> tag */
      }
    `;
            iframeDocument.head.appendChild(style2);

            const links = iframeDocument?.querySelectorAll('a');
            links?.forEach((link) => {
              link.addEventListener('click', (event) => {
                event.preventDefault();
                window.open(link.href, '_blank');
              });
            });
          };
        }
      }
    }
  }, [message, image]);

  const donwnload = async (val) => {
    if (integration === 'google') {
      const data = val.contentBytes;
      const decodedData = Buffer.from(data, 'base64');
      const blob = new Blob([decodedData], { type: val.contentType });

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = val.name;
      link.click();
      URL.revokeObjectURL(url);
    } else {
      var a = document.createElement('a');
      a.href = `data:${val.contentType};base64,${val.contentBytes}`;
      a.download = val.name;
      a.click();
    }
  };

  // const Previousdata = () => {
  //   previousfun();
  // };

  // const Nextdata = () => {
  //   nextfun();
  // };

  const sender = (data, val) => {
    const from = data.filter((item) => item.name === 'From');
    if (from.length !== 0) {
      let From = from[0].value.replace(/\s\S*$/, '');
      return <>{From}</>;
    } else {
      return <>{'(No Recipients)'}</>;
    }
  };

  const subject = (data) => {
    const sub = data.filter((item) => item.name === 'Subject');
    if (sub.length !== 0) {
      if (sub[0].value !== '') {
        return <>{sub[0].value}</>;
      } else {
        return <>{'(No Subject)'}</>;
      }
    } else {
      return <>{'(No Subject)'}</>;
    }
  };

  // const to = (data, val) => {
  //   const from = data.filter((item) => item.name === 'To');
  //   if (from.length !== 0) {
  //     let From = from[0].value.replace(/\s\S*$/, '');
  //     return <>{`To: ${from[0].value}`}</>;
  //   } else {
  //     return <>{`To: (No Recipients)`}</>;
  //   }
  // };

  const to = (data) => {
    const from = data.filter((item) => item.name === 'To');

    if (from.length !== 0) {
      let toList = from[0].value.trim();
      if (toList !== '') {
        let toListArray = toList.split(',').map((email) => email.trim());
        let toListDisplay = toListArray.slice(0, 3).join(', ');
        let remainingCount = toListArray.length - 3;

        return (
          <Text color="black">
            {`To: ${toListDisplay}`}
            {remainingCount > 0 && (
              <span
                style={{ cursor: 'pointer', color: 'black', marginLeft: '5px' }}
              >
                {` (+ ${remainingCount} more)`}
              </span>
            )}
          </Text>
        );
      }
    }

    return <Text color="black">{`To: (No Recipients)`}</Text>;
  };

  const gmailunread = async (val: any) => {
    await Gmail_unread_messages(val.id)
      .then((res) => {
        removemsg();
        update_message(message.id, true);
      })
      .catch((error) => {});
  };

  const movespam = async (val: any) => {
    var Gfolder = '';
    if (sidebarroute === 1) {
      Gfolder = 'INBOX';
    } else if (sidebarroute === 2) {
      Gfolder = 'SENT';
    } else if (sidebarroute === 3) {
      Gfolder = 'DRAFT';
    } else if (sidebarroute === 4) {
      Gfolder = 'SPAM';
    } else if (sidebarroute === 5) {
      Gfolder = 'TRASH';
    }
    await move_to_spam(message.id, Gfolder)
      .then((res) => {
        removemsg();
        Toast('Moved to spam folder successfully.', 'SHORT', 'success');
        remove_message(message.id);
      })
      .catch((error) => {});
  };

  const messageIcon = message !== '';

  const topActionBar = () => {
    if (!noEmails) {
      if (sidebarroute !== 3) {
        return (
          <>
            <Flex row>
              {sidebarroute !== 4 && (
                // <Text onClick={archive}> Archive </Text>
                <Flex
                  title="Archive"
                  className={messageIcon ? styles.icons : styles.iconsDisabled}
                >
                  {integration === 'outlook' ? (
                    <SvgArchive
                      width={16}
                      height={16}
                      fill={messageIcon ? '#581845' : '#58184550'}
                      onClick={messageIcon ? archive : undefined}
                    />
                  ) : (
                    ''
                  )}
                </Flex>
              )}

              {integration === 'google' ? (
                <Flex
                  title="Bin"
                  className={messageIcon ? styles.icons : styles.iconsDisabled}
                  // onClick={messageIcon ? remove : undefined}
                >
                  <SvgTrash
                    width={16}
                    height={16}
                    fill={messageIcon ? '#581845' : '#58184550'}
                    onClick={messageIcon ? googleremove : undefined}
                    cursor={messageIcon ? 'pointer' : 'auto'}
                  />
                </Flex>
              ) : (
                <Flex
                  title="Delete"
                  className={messageIcon ? styles.icons : styles.iconsDisabled}
                  // onClick={messageIcon ? remove : undefined}
                >
                  <SvgTrash
                    width={16}
                    height={16}
                    fill={messageIcon ? '#581845' : '#58184550'}
                    onClick={messageIcon ? remove : undefined}
                    cursor={messageIcon ? 'pointer' : 'auto'}
                  />
                </Flex>
              )}
              {sidebarroute !== 4 && (
                <>
                  {integration === 'google' ? (
                    <Flex
                      title="Spam"
                      className={
                        messageIcon ? styles.icons : styles.iconsDisabled
                      }
                      onClick={messageIcon ? movespam : undefined}
                    >
                      <SvgSpam
                        width={18}
                        height={18}
                        fill={messageIcon ? '#581845' : '#58184550'}
                      />
                    </Flex>
                  ) : (
                    ''
                  )}
                </>
              )}

              {sidebarroute !== 6 && (
                <>
                  {integration !== 'google' ? (
                    <Flex
                      title="Move to Junk"
                      className={
                        messageIcon ? styles.icons : styles.iconsDisabled
                      }
                      onClick={messageIcon ? junk : undefined}
                    >
                      <SvgJunk
                        width={16}
                        height={16}
                        stroke={messageIcon ? '#581845' : '#58184550'}
                      />
                    </Flex>
                  ) : (
                    ''
                  )}
                </>
              )}
            </Flex>
          </>
        );
      }

      return (
        <>
          <Flex row width={'100%'} height={'100%'}>
            {' '}
            <Flex
              title="Delete"
              className={messageIcon ? styles.icons : styles.iconsDisabled}
              onClick={messageIcon ? remove : undefined}
            >
              <SvgTrash
                width={16}
                height={16}
                fill={messageIcon ? '#581845' : '#58184550'}
                cursor={messageIcon ? 'pointer' : 'auto'}
              />
            </Flex>
            {/* <Flex
            title="Mark as unread"
            className={messageIcon ? styles.icons : styles.iconsDisabled}
            style={{ cursor: 'pointer' }}
            onClick={() => unread(false)}
          >
            <SvgRead
              width={16}
              height={16}
              fill={messageIcon ? '#581845' : '#58184550'}
            />
          </Flex> */}
            {/* <Flex
            title="Edit Message"
            className={styles.iconsDisabled}
            // style={{ cursor: 'pointer' }}
            onClick={() => {}}
          >
            <SvgEdit
              width={16}
              height={16}
              fill={messageIcon ? '#581845' : '#58184550'}
            />
          </Flex> */}
          </Flex>
        </>
      );
    }
  };

  const renderAttachments = attachments && (
    <>
      {attachments.length !== 0 && (
        <Flex
          row
          width={'100%'}
          className={styles.filesContainer}
          style={{
            borderTop: attachments.length !== 0 ? '1px solid #c3c3c3' : 'unset',
            height: attachments.length !== 0 ? '60px' : 'unset',
          }}
        >
          {attachments.map((val, ind) => (
            <Flex
              flex={1}
              row
              center
              between
              key={ind}
              className={styles.attachfile}
            >
              <Flex marginRight={10} style={{ padding: '10px' }}>
                <Text size={12} title={val.name} className={styles.fileName}>
                  {val.name}
                </Text>
                <Text
                  size={10}
                  title={`${Math.round(val.size / 1024)} KB`}
                  style={{ color: '#666666' }}
                >
                  {Math.round(val.size / 1024)} KB
                </Text>
              </Flex>
              <Flex
                style={{
                  borderLeft: '1px solid #c3c3c3',
                  height: '100%',
                  display: 'flex',
                  padding: '0',
                }}
                className={styles.iconsContainer}
              >
                <Flex
                  title="Export File"
                  style={{
                    cursor: 'pointer',
                    padding: '5px 10px 5px 0px',
                    height: '100%',
                  }}
                  onClick={() => donwnload(val)}
                >
                  <SvgDownload
                    width={14}
                    height={14}
                    className={styles.svgicon}
                  />
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </>
  );

  const drafticon = (val) => {
    var get = val.labelIds.includes('DRAFT');

    if (get === true) {
      return (
        <>
          <Flex
            title={
              emailCheck ? 'Edit Message' : 'Not supported for external emails'
            }
            className={emailCheck ? styles.icons : styles.iconsDisabled}
            style={{ cursor: 'pointer' }}
            onClick={emailCheck ? () => mail('draft') : undefined}
          >
            <SvgEdit
              width={16}
              height={16}
              fill={emailCheck ? '#581845' : '#58184550'}
            />
          </Flex>
        </>
      );
    } else {
      return (
        <>
          <Flex
            title={emailCheck ? 'Reply' : 'Not supported for external emails'}
            className={emailCheck ? styles.icons : styles.iconsDisabled}
            onClick={emailCheck ? () => mail('reply') : undefined}
          >
            <SvgReply
              width={16}
              height={16}
              fill={emailCheck ? '#581845' : '#58184550'}
            />
          </Flex>
          {isprofileview !== true ? (
            <Flex
              title={
                emailCheck ? 'Reply All' : 'Not supported for external emails'
              }
              className={emailCheck ? styles.icons : styles.iconsDisabled}
              onClick={emailCheck ? () => mail('replyall') : undefined}
            >
              <SvgReplyall
                width={16}
                height={16}
                fill={emailCheck ? '#581845' : '#58184550'}
              />
            </Flex>
          ) : (
            ''
          )}

          <Flex
            title={emailCheck ? 'Forward' : 'Not supported for external emails'}
            className={emailCheck ? styles.icons : styles.iconsDisabled}
            onClick={emailCheck ? () => mail('forward') : undefined}
          >
            <SvgForward
              width={16}
              height={16}
              fill={emailCheck ? '#581845' : '#58184550'}
            />
          </Flex>
        </>
      );
    }
  };

  const handlefunction = (val) => {
    const hasDraftLabel = val.includes('DRAFT');
    if (hasDraftLabel) {
      return (
        <Text bold size={13} style={{ color: '#ED4857' }}>
          {'Draft'}
        </Text>
      );
    }
  };

  // const ccshow = (val) => {
  //   const from = val.filter((item) => item.name === 'Cc');

  //   if (from.length !== 0) {
  //     let From = from[0].value.replace(/\s\S*$/, '');
  //     if (from[0].value !== '') {
  //       return <>{`Cc: ${from[0].value}`}</>;
  //     }
  //   }
  // };

  const ccshow = (val) => {
    const from = val.filter((item) => item.name === 'Cc');

    if (from.length !== 0) {
      let ccList = from[0].value.trim();
      if (ccList !== '') {
        let ccListArray = ccList.split(',').map((email) => email.trim());
        let ccListDisplay = ccListArray.slice(0, 3).join(', ');
        let remainingCount = ccListArray.length - 3;

        return (
          <Text color="black">
            {`Cc: ${ccListDisplay}`}
            {remainingCount > 0 && (
              <span
                role="button"
                style={{ cursor: 'pointer', color: 'black', marginLeft: '5px' }}
                // onClick={() => alert('Show more Cc recipients')}
              >
                {` (+ ${remainingCount} more)`}
              </span>
            )}
          </Text>
        );
      }
    }

    return null; // Return null if no Cc recipients
  };

  const processEmailContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const links = doc.querySelectorAll('a');
    links.forEach((link) => {
      link.setAttribute('target', '_blank');
    });
    const sanitizedContent = DOMPurify.sanitize(doc.documentElement.outerHTML);
    return sanitizedContent;
  };

  const processEmailContent1 = (htmlContent: string, Images): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Replace CID references with Data URLs
    Images.forEach((attachment) => {
      if (attachment.isInline && attachment.contentId) {
        const cid = attachment.contentId;
        const dataUrl = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
        const images = doc.querySelectorAll(`img[src="cid:${cid}"]`);

        images.forEach((imgElement) => {
          // Ensure that the element is indeed an HTMLImageElement
          const img = imgElement as HTMLImageElement;
          img.src = dataUrl;
        });
      }
    });

    // Sanitize the HTML content
    return DOMPurify.sanitize(doc.documentElement.outerHTML);
  };

  const renderBody = () => {
    if (message !== '') {
      return (
        <>
          {integration === 'google' ? (
            <>
              <Flex
                column
                style={{
                  position: 'relative',
                  border: '1px solid #c3c3c3',
                  borderRadius: '5px 5px 0px 0px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  overflow: 'scroll',
                  maxHeight: '-webkit-fill-available',
                }}
              >
                <Flex
                  row
                  between
                  style={{
                    position: 'relative',
                    borderBottom: '1px solid #c3c3c3',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Flex
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '5px 10px',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {handlefunction(message.labelIds)}

                    <Flex row between width={'100%'}>
                      <Text bold size={13}>
                        {sender(message.header, '0')}
                      </Text>

                      <Flex row marginRight={10}>
                        {drafticon(message)}
                        <Flex>
                          <Flex
                            title={
                              emailCheck
                                ? 'Mark as Unread'
                                : 'Not supported for external emails'
                            }
                            className={
                              emailCheck ? styles.icons : styles.iconsDisabled
                            }
                          >
                            <SvgRead
                              width={16}
                              height={16}
                              fill={emailCheck ? '#581845' : '#58184550'}
                              onClick={
                                emailCheck
                                  ? () => gmailunread(message)
                                  : undefined
                              }
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>

                    <Text size={13}>{subject(message.header)}</Text>
                    {to(message.header)}
                    {ccshow(message.header)}
                  </Flex>
                </Flex>

                <Flex
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    margin: '10px',
                    overflowY: 'auto',
                    width: '-webkit-fill-available',
                    maxHeight: '-webkit-fill-available',
                  }}
                >
                  <iframe
                    style={{
                      border: 'none',
                      height: window.innerHeight - 170,
                      overflow: 'auto',
                    }}
                    className={styles.bulletpoint}
                    ref={iframeRef}
                    title="Gmail Message"
                    sandbox="allow-same-origin allow-scripts"
                  />
                </Flex>
                {renderAttachments}
              </Flex>
            </>
          ) : (
            <>
              <Flex
                column
                style={{
                  position: 'relative',
                  border: '1px solid #c3c3c3',
                  borderRadius: '5px 5px 0px 0px',
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'fit-content',
                  maxHeight: '-webkit-fill-available',
                }}
                width={
                  Emailsidebar
                    ? isprofileview
                      ? '-webkit-fill-available'
                      : window.innerWidth - 490
                    : isprofileview
                    ? '-webkit-fill-available'
                    : window.innerWidth - 630
                }
                className={isprofileview && styles.overflow}
              >
                <Flex
                  row
                  between
                  style={{
                    position: 'relative',
                    borderBottom: '1px solid #c3c3c3',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Flex
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '5px 10px',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Flex row between width={'100%'}>
                      {message.isDraft !== true ? (
                        <>
                          <Text bold size={13}>
                            {message.sender.emailAddress.name}
                          </Text>
                        </>
                      ) : (
                        <Flex>
                          <Text bold size={13} style={{ color: '#ED4857' }}>
                            {'Draft'}
                          </Text>
                          <Text bold size={13}>
                            {'(No Sender)'}
                          </Text>
                        </Flex>
                      )}

                      <Flex row marginRight={10}>
                        {message.isDraft !== true ? (
                          <>
                            <Flex
                              title={
                                emailCheck
                                  ? 'Reply'
                                  : 'Not supported for external emails'
                              }
                              className={
                                emailCheck ? styles.icons : styles.iconsDisabled
                              }
                              onClick={
                                emailCheck ? () => mail('reply') : undefined
                              }
                            >
                              <SvgReply
                                width={16}
                                height={16}
                                fill={emailCheck ? '#581845' : '#58184550'}
                              />
                            </Flex>
                            {isprofileview !== true ? (
                              <Flex
                                title={
                                  emailCheck
                                    ? 'Reply All'
                                    : 'Not supported for external emails'
                                }
                                className={
                                  emailCheck
                                    ? styles.icons
                                    : styles.iconsDisabled
                                }
                                onClick={
                                  emailCheck
                                    ? () => mail('replyall')
                                    : undefined
                                }
                              >
                                <SvgReplyall
                                  width={16}
                                  height={16}
                                  fill={emailCheck ? '#581845' : '#58184550'}
                                />
                              </Flex>
                            ) : (
                              ''
                            )}
                            <Flex
                              title={
                                emailCheck
                                  ? 'Forward'
                                  : 'Not supported for external emails'
                              }
                              className={
                                emailCheck ? styles.icons : styles.iconsDisabled
                              }
                              onClick={
                                emailCheck ? () => mail('forward') : undefined
                              }
                            >
                              <SvgForward
                                width={16}
                                height={16}
                                fill={emailCheck ? '#581845' : '#58184550'}
                              />
                            </Flex>
                          </>
                        ) : (
                          <Flex
                            title={
                              emailCheck
                                ? 'Edit Message'
                                : 'Not supported for external emails'
                            }
                            className={
                              emailCheck ? styles.icons : styles.iconsDisabled
                            }
                            style={{ cursor: 'pointer' }}
                            onClick={
                              emailCheck ? () => mail('draft') : undefined
                            }
                          >
                            <SvgEdit
                              width={16}
                              height={16}
                              fill={emailCheck ? '#581845' : '#58184550'}
                            />
                          </Flex>
                        )}

                        <Flex
                          title={
                            emailCheck
                              ? 'Mark as Unread'
                              : 'Not supported for external emails'
                          }
                          className={
                            emailCheck ? styles.icons : styles.iconsDisabled
                          }
                        >
                          <SvgRead
                            width={16}
                            height={16}
                            fill={emailCheck ? '#581845' : '#58184550'}
                            onClick={
                              emailCheck ? () => unread(false) : undefined
                            }
                          />
                        </Flex>
                      </Flex>
                    </Flex>

                    <Text size={13}>
                      {message.subject !== ''
                        ? message.subject
                        : '(No Subject)'}
                    </Text>
                    {message.toRecipients.length !== 0 ? (
                      <>
                        <Flex row>
                          <Text size={13}>
                            {`To: ${message.toRecipients
                              .slice(0, 3)
                              .map((doc) => doc.emailAddress.name)
                              .join(', ')}`}{' '}
                          </Text>
                          {message.toRecipients.length > 3 && (
                            <Text
                              size={13}
                              style={{ paddingLeft: '5px' }}
                            >{` (+ ${
                              message.toRecipients.length - 3
                            } more)`}</Text>
                          )}
                        </Flex>
                      </>
                    ) : (
                      <Text color="black">{`To: (No Recipients)`}</Text>
                    )}
                    {message.ccRecipients.length !== 0 && (
                      <>
                        <Flex row>
                          <Text size={13}>
                            {`Cc: ${message.ccRecipients
                              .slice(0, 3)
                              .map((doc) => doc.emailAddress.name)
                              .join(', ')}`}{' '}
                          </Text>
                          {message.ccRecipients.length > 3 && (
                            <Text
                              size={13}
                              style={{ paddingLeft: '5px' }}
                            >{` (+ ${
                              message.ccRecipients.length - 3
                            } more)`}</Text>
                          )}
                        </Flex>
                      </>
                    )}
                    {message.bccRecipients.length !== 0 && (
                      <>
                        <Flex row>
                          <Text size={13}>
                            {`Bcc: ${message.bccRecipients
                              .slice(0, 3)
                              .map((doc) => doc.emailAddress.name)
                              .join(', ')}`}{' '}
                          </Text>
                          {message.bccRecipients.length > 3 && (
                            <Text
                              size={13}
                              style={{ paddingLeft: '5px' }}
                            >{` (+ ${
                              message.bccRecipients.length - 3
                            } more)`}</Text>
                          )}
                        </Flex>
                      </>
                    )}
                  </Flex>
                </Flex>

                <Flex
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    margin: '10px',
                    overflowY: 'scroll',
                    fontsize: '13px',
                  }}
                  height={window.innerHeight - 900}
                  className={styles.bulletpoint1}
                >
                  <iframe
                    style={{
                      border: 'none',
                      height: window.innerHeight - 170,
                      overflow: 'auto',
                    }}
                    className={styles.bulletpoint}
                    ref={iframeRef}
                    title="Outlook Message"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
                  />

                  {renderAttachments}
                </Flex>
              </Flex>
            </>
          )}
        </>
      );
    }

    return (
      <Flex
        center
        style={{
          alignContent: 'center',
          alignItems: 'center',
          // height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        height={
          isprofileview ? window.innerHeight - 190 : window.innerHeight - 170
        }
      >
        <SvgEmptyMail />
        {/* <Flex center middle> */}
        <Text style={{ marginTop: '10px' }}>Select an item to read</Text>
        <Text color="gray">Nothing is selected</Text>
        {/* </Flex> */}
      </Flex>
    );
  };

  return (
    <Flex
      className={styles.messageContainer}
      height={
        isprofileview ? window.innerHeight - 95 : window.innerHeight - 100
      }
    >
      <Flex row between center className={styles.iconContainer}>
        {topActionBar()}
      </Flex>
      {msglistcount !== 0 ? (
        <>
          <Flex
            className={
              isprofileview ? styles.bodyContainers : styles.bodyContainer
            }
            // style={{overflowY:'scroll'}}
            height={isprofileview ? window.innerHeight - 130 : ''}
          >
            {renderBody()}
          </Flex>
        </>
      ) : (
        ''
      )}
    </Flex>
  );
};

export default Inbox;
