import * as React from 'react';
const SvgLocktick = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="#581845"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#581845"
      d="M8.649 10.667c0 .845-.77 1.524-1.73 1.524-.459 0-.899-.161-1.223-.447a1.438 1.438 
      0 0 1-.507-1.077c0-.846.77-1.524 1.73-1.524.459 0 .899.16 1.223.446.324.286.507.673.507 
      1.078Zm-.787 3.81c.103.548.32 1.058.622 1.523H1.73c-.459 0-.899-.16-1.223-.446A1.438 1.438 
      0 0 1 0 14.476V6.857c0-.846.77-1.524 1.73-1.524h.865V3.81C2.595 1.707 4.532 0 6.919 0c2.387 
      0 4.324 1.707 4.324 3.81v1.523h.865c.459 0 .899.16 1.223.447.325.285.507.673.507 1.077v2.354a6.496 6.496 
      0 0 0-.865-.068c-.294 0-.58.03-.865.068V6.857H1.73v7.62h6.132ZM4.324 
      5.332h5.19V3.81c0-1.265-1.16-2.286-2.595-2.286-1.436 0-2.595 1.02-2.595 2.286v1.523Zm10.673 5.974-3.105 
2.735-1.375-1.211-1.003.883L11.892 16 16 12.381l-1.003-1.074Z"
    />
  </svg>
);
export default SvgLocktick;