import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { JdandApplicants, eventSchedulerApi } from '../../../routes/apiRoutes';
import { AppDispatch, RootState } from '../../../store';
import Button from '../../../uikit/Button/Button';
import Flex from '../../../uikit/Flex/Flex';
import InputCheckBox from '../../../uikit/InputCheckbox/InputCheckBox';
import InputText from '../../../uikit/InputText/InputText';
import SvgClock from '../../../icons/SvgClock';
import SvgNoDataIcon from '../../../icons/SvgNoDataIcon';
import Text from '../../../uikit/Text/Text';
import { ApplicantsSourceMiddleWare } from '../../reportsmodule/store/middleware/reportsmiddleware';
import Toast from '../../../uikit/Toast/Toast';
import SvgSearch from '../../../icons/SvgSearch';
import SvgNoCandidate from '../../../icons/SvgNoCandidate';
import Loader from '../../../uikit/Loader/Loader';
import Modal from '../../../uikit/Modal/Modal';
import { toCamelCase } from '../../../uikit/helper';
import { SelectTag } from '../../../uikit';
import { getScheduleMiddleWare } from './store/middleware/eventmiddleware';
import { DataEntity, ShareEntity } from './ScheduleTypes';
import styles from './linkshare.module.css';




type Props = {
  sharelinkdata?: ShareEntity[];
  details?: DataEntity;
  setShare: (boolean) => void;
}

const LinkShare = ({
  sharelinkdata,
  details,
  setShare,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [checkedItems, setCheckedItems] = useState([]);
  const [data, setData] = useState([]);
  const [InitialData, setInitialData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [jd_id, setjd_id] = useState(0);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckedItems((prevItems) => [...prevItems, value]);
    } else {
      setCheckedItems((prevItems) =>
        prevItems.filter((item) => item !== value),
      );
    }
  };

  const { jd_list } = useSelector((
    { applicantsSourceReducers }: RootState) => ({
      jd_list: applicantsSourceReducers.jd_list,
    }));


  useEffect(() => {
    setLoader(true)
    dispatch(ApplicantsSourceMiddleWare({ share: "true" })).then((res) => {
      setLoader(false)
    })
  }, [])


  function searchItems(searchquery) {
    if (searchquery.length > 0) {
      const items = InitialData;
      const lowercaseQuery = searchquery.toLowerCase();
      const filteredItems = items.filter((item) =>
        item.full_name.toLowerCase().includes(lowercaseQuery.toString()),
      );
      setData(filteredItems);
      return filteredItems;
    } else {
      setData(InitialData)
    }
  }

  const onbuttonclick = (eventid: any) => {
    if (checkedItems.length > 0 && jd_id !== 0) {
      const sharelink = JSON.stringify(checkedItems);
      setLoader(true);
      axios
        .get(`${eventSchedulerApi}?pk=${eventid}&sharelink=${sharelink}&jd_id=${jd_id}`).then((res) => {
          dispatch(getScheduleMiddleWare(undefined));
          Toast('Event shared successfully.', 'LONG');
          setLoader(false);
          setShare(false);
        })
    }
  };

  const handleOnChange = (jd) => {
    setjd_id(jd)
    setCheckedItems([])
    if (jd !== 0) {
      axios.get(`${JdandApplicants}?jd_id=${jd}`)
        .then((res) => {
          setData(res.data.applicants)
          setInitialData(res.data.applicants)
          setLoader(false);
        })
    }
  };

  const respon = jd_list && jd_list.filter(t => t.id !== 0 && t?.jd_status === 1)
  const jobViewArray: any =
    respon &&
    respon.map((list) => {
      const result =
        list.id === 0
          ? `${list.job_title}`
          : `${list.job_title}`;
      const id = list.id === 0 ? `${list.job_title}` : `${list.id}`;
      const jobid = `${list.job_id}`;
      return { id: id, label: result, value: jobid };
    });

  return (
    <>
      {loader && <Loader />}
      <div className={styles.sharelink}>

        <Flex row between center>
          <Flex>
            <Text size={14} bold>{toCamelCase(details.event_name)}</Text>
            <Text size={13} style={{ marginTop: '5px' }}>
              Select to whom you want to share the link
            </Text>
          </Flex>
          <Flex>
            <Text size={13}>{details.event_type}</Text>
            <Flex row center style={{ marginTop: '5px' }}>
              <SvgClock width={16} height={16} fill={'#581845'} />
              <Text size={13} style={{ marginLeft: '5px' }}>{details.duration}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex className={styles.line}></Flex>
        <Flex row>
          <Flex>
            <Text
              style={{ marginBottom: "5px" }}
            >
              Job Title
            </Text>

            <div className={styles.skillContainer}>
              <SelectTag
                labelBold
                value={
                  jobViewArray
                    ? jobViewArray.find(
                      (option: any) =>
                        Number(option.id) ===
                        Number(jd_id),
                    )
                    : ''
                }
                options={jobViewArray}
                placeholder="Select"
                onChange={(option) => {
                  handleOnChange(option.id)
                }}
              />
            </div>
          </Flex>
          {jd_id !== 0 &&
            <Flex>
              <Text size={13} style={{ marginBottom: "5px" }}>Applicants</Text>

              <Flex width={300}>
                <InputText
                  style={{
                    width: '97%',
                    marginBottom: '15px',
                    paddingLeft: '8px',
                    paddingRight: '20px'
                  }}
                  onChange={(e) => {
                    searchItems(e.target.value);
                  }}
                  placeholder="Search applicant by the name"
                  inputConatinerClass={styles.inputContainer}
                  actionRight={() => (
                    <label htmlFor={'applicant found'} style={{ margin: 0, marginTop: '3px', marginRight: '11px' }}>
                      <SvgSearch />
                    </label>
                  )}

                />
              </Flex>
            </Flex>
          }
        </Flex>
        {jobViewArray && jobViewArray.length === 0 &&
          <Flex center middle height={100}>
            <Flex>
              <Flex middle>
                <SvgNoDataIcon width={16} height={16} fill={'#888'} />
              </Flex>
              <Flex marginTop={5}>
                <Text color='gray' size={12}>
                  There are no job postings or applicants at this moment.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        }
        {data.length > 0 ? (
          <>
            <Flex row className={styles.candidate}>
              <div className={styles.grid}>
                {data.map((name, index) => (
                  <Flex row key={index}>
                    <Flex row center>
                      <Flex className={styles.flex} >
                        <InputCheckBox
                          value={name.id}
                          checked={checkedItems.includes(name.id.toString()) ? true : false}
                          onChange={handleCheckboxChange}
                        />
                      </Flex>
                      <Flex style={{ marginLeft: '10px' }}>
                        <Flex row >
                          <Text size={13} className={styles.textOverflow} title={name.full_name}> {name.full_name}</Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
              </div>
            </Flex>
          </>
        ) : (
          <>
            {jd_id !== 0 && <Flex center middle height={100}>
              <Flex>
                <Flex middle>
                  <SvgNoCandidate style={{ filter: 'opacity(0.6)' }} />
                </Flex>
                <Flex marginTop={5}><Text color='gray' size={12}>No applicants at this moment.</Text></Flex>
              </Flex>
            </Flex>}
          </>
        )}
        <Flex row end marginTop={10} className={styles.borderLine} >
          <Button
            types="primary"
            className={styles.cancel}
            onClick={() => setShare(false)}
          >
            Cancel
          </Button>
          {data.length > 0 && checkedItems.length > 0 ? (

            <>
              <Button
                className={styles.share}
                onClick={() => onbuttonclick(details.id)}
              >
                Share Link
              </Button>
            </>
          ) : (
            <>
              <Button
                className={styles.share}
                disabled
              >
                Share Link
              </Button>
            </>
          )}

        </Flex>
      </div>
    </>
  );
};

export default LinkShare;
