const SvgMsgread = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      fill="#581845"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
         fillRule="evenodd"
        d="M1.4 11.013c-.385 0-.715-.146-.99-.439A1.485 1.485 0 
        0 1 0 9.52V3.659c0-.187.05-.37.15-.551.099-.181.235-.32.41-.42L5.32.149a.784.784 
        0 0 1 .307-.121 1.872 1.872 0 0 1 .647 
        0c.111.02.213.06.306.121l4.655 2.539c.14.075.26.193.36.355.098.161.165.33.2.504H9.748L5.95 1.493 1.4 3.901v7.112ZM3.5 14c-.385 
        0-.715-.146-.99-.439a1.485 1.485
         0 0 1-.41-1.054v-6.72c0-.411.137-.763.412-1.055.274-.293.603-.44.988-.439h9.1c.385 
         0 .715.147.99.44.274.292.41.644.41 1.054v6.72c0 .41-.137.762-.412 1.055A1.305 1.305 
         0 0 1 12.6 14H3.5Zm4.55-4.312a.612.612 0 0 1-.158-.019 1.188 1.188 0 0 
         1-.157-.056L3.5 7.28v5.227h9.1V7.28L8.365 9.613c-.047.025-.152.05-.315.075Zm0-1.4 4.55-2.501H3.5l4.55 2.501Z"
      />
    </svg>
  );
  export default SvgMsgread;