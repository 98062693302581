import { useSelector } from 'react-redux';
import SvgRoundClose from '../../../icons/SvgRoundClose';
import SvgTick from '../../../icons/SvgTick';
import { RootState } from '../../../store';
import { ERROR, SUCCESS } from '../../../uikit/Colors/colors';
import Flex from '../../../uikit/Flex/Flex';
import Text from '../../../uikit/Text/Text';
import styles from './detailedfeaturescomparison.module.css';

const DetailedFeaturesComparison = () => {
 const { addons_content_01,addons_content_02 } =
  useSelector(({ manageSubscriptionReducers }: RootState) => ({
    addons_content_01: manageSubscriptionReducers.addons_content_01,
    addons_content_02: manageSubscriptionReducers.addons_content_02,
  }));
  
  return (
    <Flex columnFlex width={'76%'} marginBottom={20} className={styles.scrollablecontainer}>
      <Flex row center className={styles.titleFlex}>
       <Flex flex={6} >
          <div />
        </Flex>
              
        {
          addons_content_01.map((value,idx)=>(
            <Flex key={idx} columnFlex center flex={2}>
              <Text>{value.name}</Text>
              <Text>{value.price}</Text>
            </Flex> 
          ))
        }
      </Flex>
      <Flex style={{overflowY:'scroll',maxHeight:'400px'}}>
      {addons_content_02.map((data, i) => (
        <Flex key={i}>
          <Flex center className={styles.singleTile}>
            <Text>{data.title}</Text>
          </Flex>
          <Flex>
            {data.features?.map((val, id) => (
              <Flex center row key={id} className={styles.borderBottom}>
                <Flex flex={6}>
                    <Text>{val.titlepricing}</Text>
                </Flex>
                <Flex columnFlex center flex={2}>
                {
                  val.freetrial === "Yes" ? (
                    <SvgTick fill={SUCCESS} />
                  ) : val.freetrial === 'No' ? (
                    <SvgRoundClose fill={ERROR} height={16} width={16} />
                  ) : (
                    <Text>{val.freetrial}</Text>
                  )
                }
                </Flex>
                <Flex columnFlex center flex={2}>
                {
                  val.standerd === "Yes" ? (
                    <SvgTick fill={SUCCESS} />
                  ) : val.standerd === 'No' ? (
                    <SvgRoundClose fill={ERROR} height={16} width={16} />
                  ) : (
                    <Text>{val.standerd}</Text>
                  )
                }
                </Flex>
                <Flex columnFlex center flex={2}>
                {
                  val.premium === "Yes" ? (
                    <SvgTick fill={SUCCESS} />
                  ) : val.premium === 'No' ? (
                    <SvgRoundClose fill={ERROR} height={16} width={16} />
                  ) : (
                    <Text>{val.premium}</Text>
                  )
                }
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      ))}
      </Flex>
        </Flex>
  );
};

export default DetailedFeaturesComparison;
