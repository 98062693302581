import React, { useState,ReactElement } from "react";
import "react-pro-sidebar/dist/css/styles.css";

import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import './header.css'
import {
  ProSidebar,
  SidebarHeader,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import image from '../../../../src/assets/images/theme_zita_logo.png'
import down from '../../../../src/assets/images/down1.png'
import right from '../../../../src/assets/images/rightf1.png'
import './mobile.css'
import SvgFooterHeaderArrow from "../../../icons/SvgFooterHeaderArrow";




const About = [
  {
    name: "Applicant Tracking System",
    routerPath: "https://www.zita.ai/applicant-tracking-system",
  },
  {
    name: "Candidate Matching",
    routerPath: "https://www.zita.ai/candidate-matching",
  },
  {
    name: "Comparative Analysis",
    routerPath: "https://www.zita.ai/comparative-analysis",
  },
  {
    name: "Team Collaboration",
    routerPath: "https://www.zita.ai/team-collaboration",
  },
  {
    name: "In App Mailbox Integration",
    routerPath: "https://www.zita.ai/inapp-mailboxintegration",
  },
  {
    name: "Calendar & Interview Scheduler",
    routerPath: "https://www.zita.ai/in-appcalendar-interview-scheduler",
  },
  {
    name: "Interview Question By Ai",
    routerPath: "https://www.zita.ai/interview-questions",
  },
  {
    name: "Reports & Insights",
    routerPath: "https://www.zita.ai/reports",
  },

];
const Docs = [
  {
    name: "Create & Post Job",
    routerPath: "https://www.zita.ai/create-and-postjob",
  },
  {
    name: "Branded Career Pages",
    routerPath: "https://www.zita.ai/branded-career-page",
  },
  {
    name: "Talent Sourcing",
    routerPath: "https://www.zita.ai/talent-sourcing",
  },
  {
    name: "Customizable Hiring Board",
    routerPath: "https://www.zita.ai/kanban-hiring-board",
  },
  {
    name: "Human Like Ai Matching",
    routerPath: "https://www.zita.ai/human-like-ai-matching",
  },
  {
    name: "Zita Profile View",
    routerPath: "https://www.zita.ai/zita-profile-view",
  },

];


const insights = [
  {
    name: "Zita ATS",
    routerPath: "https://www.zita.ai/zita-ats",
  },
  {
    name: "Roadmap",
    routerPath: "https://www.zita.ai/roadmap",
  },
  {
    name: "Blog",
    routerPath: "https://www.zita.ai/blog",
  },

 

];

const MegaMenuMobile = () => {
  const isSmallDevice = useMediaQuery({ query: "only screen and (max-width : 768px)" });
  const [click, setClick] = useState(false);
  const [submenu1Openmain, setSubmenu1Openmain] = useState(false);
  const [submenu1Open, setSubmenu1Open] = useState(false);
  const [submenu2Open, setSubmenu2Open] = useState(false);
  const [submenu3Open, setSubmenu3Open] = useState(false);
  const [submenu4Open, setSubmenu4Open] = useState(false);

  const [submenu5Open, setSubmenu5Open] = useState(false);
  const [submenu6Open, setSubmenu6Open] = useState(false);
  const [submenu7Open, setSubmenu7Open] = useState(false);
  const [submenu8Open, setSubmenu8Open] = useState(false);

  const [submenu9Open, setSubmenu9Open] = useState(false);
  const [submenu10Open, setSubmenu10Open] = useState(false);
  const [submenu11Open, setSubmenu11Open] = useState(false);
  const [submenu12Open, setSubmenu12Open] = useState(false);

  const [submenu13Open, setSubmenu13Open] = useState(false);
  const [submenu14Open, setSubmenu14Open] = useState(false);
  const [submenu15Open, setSubmenu15Open] = useState(false);

  const handleClick = () => setClick(!click);
  const handleClick1=()=>{
    setClick(!click);
    window.location.reload();
  }
  const toggleSubmenumain = () => setSubmenu1Openmain(!submenu1Openmain);
  const toggleSubmenu1 = () => setSubmenu1Open(!submenu1Open);
  const toggleSubmenu2 = () => setSubmenu2Open(!submenu2Open);
  const toggleSubmenu3 = () => setSubmenu3Open(!submenu3Open);
  const toggleSubmenu4 = () => setSubmenu4Open(!submenu4Open);

  const toggleSubmenu5 = () => setSubmenu5Open(!submenu5Open);
  const toggleSubmenu6 = () => setSubmenu6Open(!submenu6Open);
  const toggleSubmenu7 = () => setSubmenu7Open(!submenu7Open);
  const toggleSubmenu8 = () => setSubmenu8Open(!submenu8Open);

  const toggleSubmenu9 = () => setSubmenu9Open(!submenu9Open);
  const toggleSubmenu10 = () => setSubmenu10Open(!submenu10Open);
  const toggleSubmenu11 = () => setSubmenu11Open(!submenu11Open);
  const toggleSubmenu12 = () => setSubmenu12Open(!submenu12Open);

  const toggleSubmenu13 = () => setSubmenu13Open(!submenu13Open);
  const toggleSubmenu14 = () => setSubmenu14Open(!submenu14Open);
  const toggleSubmenu15 = () => setSubmenu15Open(!submenu15Open);
  const handleKnowledgeBaseClick = () => {
    setClick(false); // Close the menu if it's open
    window.location.href = "https://www.zita.ai/knowledgebase" 
  };
  return (
    <div className="mega-menu-wrapper">
      <div className="mega-swicher"></div>
      {isSmallDevice &&
      <div className="mob-header multi-mob-header">
        <button className="toggler-menu" onClick={handleClick}>
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>}
      {/* End Header */}

      <ProSidebar
        className={click ? "mega-mobile-menu menu-open" : "mega-mobile-menu"}
      >
        <SidebarHeader>
          <div className="logo position-static">
            <a href="index.html">
              <img src={image} alt="brand logo" style={{width:'80px'}}/>
            </a>
          </div>
          <div role="button" className="fix-icon text-dark" onClick={handleClick} style={{ visibility: "hidden" }}>
            <img src="images/icon/close_001.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}

          {/* End logo */}
        </SidebarHeader>
        <SidebarContent>
          <Menu >
            <SubMenu title="Recruit & Engage">
              {Docs.map((val, i) => (
                <MenuItem key={i}>
                  <a href={val.routerPath}>{val.name}</a>
                </MenuItem>
              ))}
            </SubMenu>
            <SubMenu title="Track & Collaborate">
              {About.map((val, i) => (
                <MenuItem key={i} style={{ whiteSpace: 'normal' }}>
                  <a href={val.routerPath} style={{ whiteSpace: 'normal' }}>{val.name}</a>
                </MenuItem>
              ))}
            </SubMenu>
            {/* <MenuItem onClick={handleClick}>
              <Link to="/virtual">Knowledge Base</Link>
            </MenuItem> */}
            {/* Knowledge Base SubMenu */}
         
            <MenuItem onClick={handleClick}>
              <a href="https://app.zita.ai/plan_and_pricing">Plan And Pricing</a>
            </MenuItem>

            <SubMenu 
            className="knowledge-base-submenu"
              title={
                <span  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span role="button"  onClick={handleKnowledgeBaseClick}>
                   
                  Knowledge Base 
                  </span>
                  {/* <span style={{ position: 'absolute', right: '15px' ,height:'42px',width:'38%',display:'flex',justifyContent:'end',alignItems:'center'}}  role="button" onClick={toggleSubmenumain}> {submenu1Openmain ? (<img
                      src={`/images/icon/down1.svg`}
                      alt="icon"
                      style={{ width: '11px', height: '11px' }}
                      className=""
                    />) : (<img
                      src={`/images/icon/right1.svg`}
                      alt="icon"
                      className=""
                      style={{ width: '11px', height: '11px' }}
                    />)}
                    </span> */}
                </span>
              }
            >
<SubMenu
  title={
    <span role="button" onClick={toggleSubmenu1} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span>
        Setup Your Account 
      </span>
      <span style={{ position: 'absolute', right: '15px' }}>
        {submenu1Open ? (
          <img
            src={down}
            alt="icon"
            className=""
            style={{ width: '12px', height: '7px' }}
          />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}
      </span>
    </span>
  }
>
  <MenuItem onClick={handleClick1} >
  <a  href="https://www.zita.ai/knowledgebase/setup-your-account" >Overview</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/setup-your-account#freetrial">Start Your Free Trial</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/setup-your-account#subscription"  style={{ whiteSpace: 'normal' }}>Start Your Subscription</a>
  </MenuItem>
</SubMenu>

<SubMenu
  title={
    <span role="button" onClick={toggleSubmenu2} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span >
        Setup Your Profile</span><span style={{ position: 'absolute', right: '15px' }}> {submenu2Open ?(
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/setup-your-profile">Overview</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/setup-your-profile#profile-details">Profile Details</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/setup-your-profile#notification" style={{ whiteSpace: 'normal' }}>Email & In-app Notifications</a>
  </MenuItem >
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/setup-your-profile#password-update" style={{ whiteSpace: 'normal' }}>Changing Your Password</a>
  </MenuItem>
</SubMenu>

<SubMenu
  title={
    <span role="button"  onClick={toggleSubmenu3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span >
        Career Page</span><span style={{ position: 'absolute', right: '15px' }}> {submenu3Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/career-page">Overview</a>
  </MenuItem >
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/career-page#setup" style={{ whiteSpace: 'normal' }}>Setting-up your Career page</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/career-page#social-media" style={{ whiteSpace: 'normal' }}>Share jobs on social media </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/career-page#website-integration" style={{ whiteSpace: 'normal' }}>Link your Zita Career page to the exiting website.</a>
  </MenuItem>
</SubMenu>

<SubMenu
  title={
    <span role="button"  onClick={toggleSubmenu4} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span >
        Subscription and Pricing</span><span style={{ position: 'absolute', right: '15px' }}> {submenu4Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/subscription-and-pricing">Overview</a>
  </MenuItem>
  <MenuItem onClick={handleClick1} >
    <a href="https://www.zita.ai/knowledgebase/subscription-and-pricing#manage-subscription">Manage Subscription</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/subscription-and-pricing#upgrade-plans">Upgrading Plans </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/subscription-and-pricing#payment-methods" style={{ whiteSpace: 'normal' }}>Payment Methods</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/subscription-and-pricing#invoice" style={{ whiteSpace: 'normal' }}>Invoices & Billing Information</a>
  </MenuItem>
</SubMenu>

<SubMenu
  title={
    <span role="button" onClick={toggleSubmenu5} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span >
        Job Creation & Posting</span><span style={{ position: 'absolute', right: '15px' }}> {submenu5Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/job-creation-posting">Overview</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/job-creation-posting#job-creation" style={{ whiteSpace: 'normal' }}>Job Creation and Posting Processes</a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/job-creation-posting#ai-parsing">AI Jd Generation</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/job-creation-posting#templates" style={{ whiteSpace: 'normal' }}>JD Template customization</a>
  </MenuItem>

</SubMenu>

<SubMenu
  title={
    <span role="button"  onClick={toggleSubmenu6} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span >
        Job Management</span><span style={{ position: 'absolute', right: '15px' }}> {submenu6Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/job-management">Overview</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/job-management#job-posting">
      Manage Job Postings</a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/job-management#job-metrics">Job Metrics</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/job-management#jd-action">JD Action</a>
  </MenuItem>

</SubMenu>


<SubMenu
  title={
    <span role="button" onClick={toggleSubmenu7} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span style={{ whiteSpace: 'normal' }}>
        Candidate Sourcing & Management</span><span style={{ position: 'absolute', right: '15px' }}> {submenu7Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/candidate-sourcing-management">Overview</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/candidate-sourcing-management#first-candidate"  style={{ whiteSpace: 'normal' }}>
      Create your First Candidate</a>
  </MenuItem>

  <MenuItem onClick={handleClick1} >
    <a href="https://www.zita.ai/knowledgebase/candidate-sourcing-management#social-media-integration" style={{ whiteSpace: 'normal' }}>Social Media Integration for Sourcing</a>
  </MenuItem>
</SubMenu>

<SubMenu
  title={
    <span role="button"  onClick={toggleSubmenu8} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span >
        Candidate Management</span><span style={{ position: 'absolute', right: '15px' }}> {submenu8Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/candidate-management">Overview </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/candidate-management#database">My Database </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/candidate-management#applicants-profile" style={{ whiteSpace: 'normal' }}>Applicants Profile Page</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/candidate-management#zita-match"  style={{ whiteSpace: 'normal' }}>
      Zita Match Candidates Profile Page</a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/candidate-management#inbuild" style={{ whiteSpace: 'normal' }}>In-Built Candidate Messaging Platform</a>
  </MenuItem>

</SubMenu>

<SubMenu
  title={
    <span  role="button" onClick={toggleSubmenu9} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span style={{ whiteSpace: 'normal' }}>
        Resume Screening and Analysis</span><span style={{ position: 'absolute', right: '15px' }}> {submenu9Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/resumescreening-and-analysis">Overview </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/resumescreening-and-analysis#ai-resume-parsing"  style={{ whiteSpace: 'normal' }}>
    AI Resume Parsing and Basic AI Matching</a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/resumescreening-and-analysis#smart-filter" style={{ whiteSpace: 'normal' }}>Smart Filters for Resume Screening</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/resumescreening-and-analysis#advanced-ai-matching"  style={{ whiteSpace: 'normal' }}>
    Advanced AI Matching with Descriptive Analytics</a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/resumescreening-and-analysis#customizable-weightage" style={{ whiteSpace: 'normal' }}>Customizable Weightage in Matching Analysis</a>
  </MenuItem>
  
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/resumescreening-and-analysis#ai-resume-comparitive" style={{ whiteSpace: 'normal' }}>AI Resume Comparative Analysis and Recommendations to Hire</a>
  </MenuItem>
</SubMenu>


<SubMenu
  title={
    <span role="button"  onClick={toggleSubmenu10} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span >
        Workflow Management</span><span style={{ position: 'absolute', right: '15px' }}> {submenu10Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/workflow-management">Overview </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/workflow-management#workflow" style={{ whiteSpace: 'normal' }}>
      Workflow Customization </a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/workflow-management#manage-workflow" style={{ whiteSpace: 'normal' }}>Manage the workflow</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/workflow-management#hiring-workflow" style={{ whiteSpace: 'normal' }}>
      Hiring Workflow Management</a>
  </MenuItem>


</SubMenu>

<SubMenu
  title={
    <span  role="button" onClick={toggleSubmenu11} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span style={{ whiteSpace: 'normal' }}>
        Hiring Team Collaboration Tools</span><span style={{ position: 'absolute', right: '15px' }}> {submenu11Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/hiring-team-collaboration">Overview </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/hiring-team-collaboration#team-collabration">
      Team Collaboration</a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/hiring-team-collaboration#team-communication">
      Team Communication</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/hiring-team-collaboration#email" style={{ whiteSpace: 'normal' }}>
      Email and Calendar Integrations</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/hiring-team-collaboration#email-templete" style={{ whiteSpace: 'normal' }}>
      Email Template Database </a>
  </MenuItem>

</SubMenu>

<SubMenu
  title={
    <span role="button"  onClick={toggleSubmenu12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span style={{ whiteSpace: 'normal' }}>
        Interview and Assessment Tools</span><span style={{ position: 'absolute', right: '15px' }}> {submenu12Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/interview-assessment-tools">Overview </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/interview-assessment-tools#inbuild" style={{ whiteSpace: 'normal' }}>
      In-Built Interview Scheduling</a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/interview-assessment-tools#ai-generated" style={{ whiteSpace: 'normal' }}>
      AI-Generated Interview Questions </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/interview-assessment-tools#evaluate-form" style={{ whiteSpace: 'normal' }}>
      Basic and Customizable Evaluation Forms</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/interview-assessment-tools#interview-slot-picker" style={{ whiteSpace: 'normal' }}>

    In-Built Interview Slot Picker</a>
  </MenuItem>

</SubMenu>

<SubMenu
  title={
    <span role="button"  onClick={toggleSubmenu13} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span >
        Analytics & Reporting</span><span style={{ position: 'absolute', right: '15px' }}> {submenu13Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/analytics-reporting">Overview </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/analytics-reporting#dashbord-overview" style={{ whiteSpace: 'normal' }}>
      Analytics Dashboard Overview </a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/analytics-reporting#applicant-sourcing" style={{ whiteSpace: 'normal' }}>
      Applicants Sourcing Report </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/analytics-reporting#passive-candidate" style={{ whiteSpace: 'normal' }}>
      Passive Candidates Sourcing</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/analytics-reporting#job-metrics">

      Job Metrics</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/analytics-reporting#sourcing-performance" style={{ whiteSpace: 'normal' }}>

      Sourcing Performance Report</a>
  </MenuItem>

</SubMenu>

<SubMenu
  title={
    <span role="button"  onClick={toggleSubmenu14} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span >
      Support & Assistance </span><span style={{ position: 'absolute', right: '15px' }}> {submenu14Open ? (
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/support-and-assisstance">Overview </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/support-and-assisstance#access-to-online" style={{ whiteSpace: 'normal' }}>
      Access to Online Resources </a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/support-and-assisstance#email-support">
      Email Support </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/support-and-assisstance#support-form">
      Support Form</a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/support-and-assisstance#priority-support">

      Priority Support</a>
  </MenuItem>


</SubMenu>

<SubMenu
  title={
    <span  role="button" onClick={toggleSubmenu15} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span >
        Add-On Features</span><span style={{ position: 'absolute', right: '15px' }}> {submenu15Open ?(
        <img
        src={down}
        alt="icon"
        className=""
        style={{ width: '12px', height: '7px' }}
      />
        ) : (
          <img
            src={right}
            alt="icon"
            className=""
            style={{ width: '10px', height: '12px' }}
          />
        )}</span>
    </span>
  }
>
  <MenuItem onClick={handleClick1}>
    <a  href="https://www.zita.ai/knowledgebase/add-on-features">Overview </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a  href="https://www.zita.ai/knowledgebase/add-on-features#type-of-addon">
      Type of Add-On </a>
  </MenuItem>

  <MenuItem onClick={handleClick1}>
    <a  href="https://www.zita.ai/knowledgebase/add-on-features#how-to-purchase" style={{ whiteSpace: 'normal' }}>
      How to Purchase Add-On  </a>
  </MenuItem>
  <MenuItem onClick={handleClick1}>
    <a href="https://www.zita.ai/knowledgebase/add-on-features#add-on-validity" >
      Add-On Validity 
      </a>
  </MenuItem>


</SubMenu>


{/* ... Repeat for Submenus 3, 4, and 5 ... */}
</SubMenu>

            {/* <MenuItem onClick={handleClick}>
              <Link to="/blog">Blog</Link>
            </MenuItem> */}

<SubMenu title="Insights">
              {insights.map((val, i) => (
                <MenuItem key={i} style={{ whiteSpace: 'normal' }}>
                  <a href={val.routerPath} style={{ whiteSpace: 'normal' }}>{val.name}</a>
                </MenuItem>
              ))}
            </SubMenu>


     

            {/* End Docs SubMenu */}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default MegaMenuMobile;