/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useFormik } from 'formik';
import SvgInfo from '../../../icons/SvgInfo';
import { AppDispatch, RootState } from '../../../store';
import { WARNING } from '../../../uikit/Colors/colors';
import Flex from '../../../uikit/Flex/Flex';
import { getDateString, isEmpty } from '../../../uikit/helper';
import Loader from '../../../uikit/Loader/Loader';
import Text from '../../../uikit/Text/Text';
import SvgRight from '../../../icons/SvgRight';
import { Button, LinkWrapper } from '../../../uikit';
import SingleButton from '../../common/SingleButton';
import { permissionMiddleWare } from '../../Login/store/middleware/loginMiddleWare';
import {
  backendProcessMiddleWare,
  creditsPurchaseMiddleWare,
} from '../../ordersummarymodule/store/ordersummarymiddleware';
import {
  createCheckoutMiddleWare,
  stripeMiddleWare,
} from '../../talentsourcingmodule/store/middleware/talentSoucringMiddleware';
import AddOnCard from './AddOnCard';
import styles from './managesubscriptionscreen.module.css';
import PlansandFeatures from './PlansandFeatures';
import {
  billingPortalMiddleWare,
  createCheckoutSubscriptionMiddleWare,
  manageSubscriptionMiddleWare,
  renewSubscriptionMiddleWare,
} from './store/managesubscriptionmiddleware';
import SubscriptionPlan from './SubscriptionPlan';

type Props = {};
const ManageSubscriptionScreen = ({ }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isLoader, setLoader] = useState<any>(true);
  const [isPlanChanged, setPlanChanged] = useState(false);
  const [isNewUser, setNewUser] = useState(false);
  const [isRemoveUser, setRemoveUser] = useState(false);
  const [isRenew, setRenew] = useState(false);
  const [isCancelOne, setCancelOne] = useState(false);
  const [managesub, setmanagesub] = useState('');
  const [componentHeight, setComponentHeight] = useState(window.innerHeight);

  // setting the helmat title
  document.title = 'Subscription & Billing';

  useEffect(() => {
    // Name('Manage Subscription');
    const handleResize = () => {
      setComponentHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const history = useHistory();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const modal_popup: any = query.get('modal_popup');
  const session_id: any = query.get('session_id');
  const planFocus: any = query.get('planFocus');
  const session: any = query.get('session');

  

  // // url based api call trigger ofr stripe update
  // useEffect(() => {
  //   if (!isEmpty(session_id)) {
  //     setLoader(true);
  //     dispatch(backendProcessMiddleWare({ session_id })).then(() => {
  //       dispatch(manageSubscriptionMiddleWare())
  //         .then(() => {
  //           if (query.has('session_id')) {
  //             query.delete('session_id');
  //             history.replace({
  //               search: query.toString(),
  //             });
  //           }
  //           setLoader(false);
  //         })
  //         .then(() => {
  //           dispatch(permissionMiddleWare());
  //         });
  //     });
  //   }
  //   if (!isEmpty(session)) {
  //     setLoader(true);

  //     dispatch(creditsPurchaseMiddleWare({ session })).then(() => {
  //       dispatch(manageSubscriptionMiddleWare())
  //         .then(() => {
  //           if (query.has('session')) {
  //             query.delete('session');
  //             history.replace({
  //               search: query.toString(),
  //             });
  //           }
  //           setLoader(false);
  //         })
  //         .then(() => {
  //           dispatch(permissionMiddleWare());
  //         });
  //     });
  //   }
  // }, []);
  useEffect(() => {
    if (!isEmpty(planFocus)) {
      // var elmnt: any = document.getElementById('plans_and_features__plan');
      // elmnt.scrollIntoView();
      if (query.has('planFocus')) {
        query.delete('planFocus');
        history.replace({
          search: query.toString(),
        });
      }
    }
  });

  // url based show popup
  useEffect(() => {
    setLoader(true);
    if (isEmpty(session_id) && isEmpty(session)) {
      dispatch(manageSubscriptionMiddleWare()).then(() => {
        if (modal_popup === 'plan_changed') {
          setPlanChanged(true);
        }
        if (modal_popup === 'user_ase') {
          setNewUser(true);
        }
        if (modal_popup === 'user_dec') {
          setRemoveUser(true);
        }
        setLoader(false);
      });
    }
  }, [session_id, session]);


  

  const {
    subscription,
    totalUserManger,
    downgrade,
    free_expired,
    total_plan,
    yearly_plan,
    monthly_plan,
    free_plan,
    total_user,
    is_plan,
  } = useSelector(({ manageSubscriptionReducers, permissionReducers }: RootState) => ({
    subscription: manageSubscriptionReducers.subscription,
    available: manageSubscriptionReducers.available,
    totalUserManger: manageSubscriptionReducers.total_user,
    downgrade: manageSubscriptionReducers.downgrade,
    free_expired: manageSubscriptionReducers.free_expired,
    total_plan: manageSubscriptionReducers.total_plan,
    yearly_plan: manageSubscriptionReducers.yearly_plan,
    monthly_plan: manageSubscriptionReducers.monthly_plan,
    free_plan: manageSubscriptionReducers.free_plan,
    total_user: manageSubscriptionReducers.total_user,
    is_plan: permissionReducers.is_plan,
  }));

  useEffect(() => {
    if (subscription === undefined) {
      setLoader(true);
    }
    if (subscription !== undefined) {
      setmanagesub(subscription.plan_name);
      setLoader(false);
    }
  }, [subscription]);

  

  const getPlanId: any = subscription && subscription?.plan_id_id.toString();
  interface addtocart {
    id: any;
    checked: boolean;
  }
  const initialValues = {
    addtocart2:[],
    addtocart: [],
    plan: [],
  };
  // Handle form submission
  const handleSubmit1 = (values) => {
    // Add submission logic here
  };
  // Define form validation logic if needed
  const handleValidation = (values) => {
    const errors = {};

    return errors;
  };
  // Initialize Formik
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit1,
    validate: handleValidation,
  });

  //this function is for setting local storeage
  const generatevalue=()=>{
    const addonIdsFromAddToCart2 = formik.values.addtocart2
    .filter((item) => item.checked)
    .map((item) => item.addonid);
  
    const add_count2 = formik.values.addtocart2
    .map((item) => {
      let quantity = item.quantity ?? 1; // Default quantity to 1 if undefined or null
  
      // Apply different logic based on addonid
      switch (item.addonid) {
        case 1:
          quantity /= 5;
          break;
        case 2:
        case 11: // Adding new case for addonid 11
          quantity /= 100;
          break;
        case 4:
          quantity /= 20;
          break;
        default:
          break;
      }
      return { ...item, quantity }; // Return new item with updated quantity
    })
    .filter((item) => item.checked) // Filter checked items
    .map((item) => item.quantity); // Map to quantity
  
    const stipids2 = formik.values.addtocart2
    .filter((item) => item.checked)
    .map((item) => item.id);

    if(addonIdsFromAddToCart2.length>0 &&add_count2.length>0&&stipids2.length>0){
    localStorage.setItem('add_on_id', JSON.stringify(addonIdsFromAddToCart2));
    localStorage.setItem('add_on_count', JSON.stringify(add_count2));
    localStorage.setItem('stipids', JSON.stringify(stipids2));}
  
    console.log('sssss??',addonIdsFromAddToCart2,add_count2,formik.values.addtocart2)
  
  }

  //if the user click buy now button then this useeffect will called
  useEffect(()=>{
   if(formik.values.addtocart2.length>0 && !isEmpty(formik.values.addtocart2))
   {
    generatevalue()
   }

  },[formik.values.addtocart2])

  // renew button function
  const hanldeRenew = () => {
    setLoader(true);
    dispatch(
      renewSubscriptionMiddleWare({
        key: getPlanId,
      }),
    ).then(() => {
      setLoader(false);
      dispatch(manageSubscriptionMiddleWare()).then(() => {
        setRenew(true);
      });
    });
  };
  const handleInvoice = () => {
    setLoader(true);
    dispatch(billingPortalMiddleWare({})).then((res) => {
      window.location.replace(res.payload.url);
      setLoader(false);
    });
  };
  const elementsRef = useRef(null);
  const elementToFocusRef = useRef(null);
 
  useEffect(() => {
    if (elementToFocusRef.current) {
      elementToFocusRef.current.focus();
    }
  }, [elementToFocusRef]);
  useEffect(() => {
    if (elementsRef.current) {
      elementsRef.current.focus();
    }
  }, [elementsRef]);
  // plan card function
  const handleFocus = () => {
    var elmnt: any = document.getElementById('plans_and_features__plan');
    elmnt.scrollIntoView();
  };

  if (isLoader) {
    return <Loader />;
  }

  function isAnyItemChecked(addtocart) {
    return addtocart.some((item) => item.checked);
  }
  const anyChecked = isAnyItemChecked(formik.values.addtocart);
  const addonIdsFromAddToCart = formik.values.addtocart
    .filter((item) => item.checked)
    .map((item) => item.addonid);
  const lastIndex = formik.values.addtocart.length - 1;
  const add_count = formik.values.addtocart
    .map((item) => {
      let quantity = item.quantity ?? 1; // Default quantity to 1 if undefined or null

      // Apply different logic based on addonid
      switch (item.addonid) {
        case 1:
          quantity /= 5;
          break;
        case 2:
        case 11: // Adding new case for addonid 11
          quantity /= 100;
          break;
        case 4:
          quantity /= 20;
          break;
        default:
          break;
      }
      return { ...item, quantity }; // Return new item with updated quantity
    })
    .filter((item) => item.checked) // Filter checked items
    .map((item) => item.quantity); // Map to quantity

  const stipids = formik.values.addtocart
    .filter((item) => item.checked)
    .map((item) => item.id);
     const valuetolocal = () => {
    if (formik.values.plan.length === 0) {
      localStorage.setItem('current', 'false');
    } else {
      localStorage.setItem('current', 'true');
    }
    localStorage.setItem('add_on_id', JSON.stringify(addonIdsFromAddToCart));
    localStorage.setItem('add_on_count', JSON.stringify(add_count));
    localStorage.setItem('stipids', JSON.stringify(stipids));
    console.log('2222',addonIdsFromAddToCart,add_count,stipids)
  };
  //this function is for buy now button
  const valuetolocal2 = () => {
    if (formik.values.plan.length === 0) {
      localStorage.setItem('current', 'false');
    } else {
      localStorage.setItem('current', 'true');
    }
  };
  const currentval = formik.values?.plan[0]?.plan === undefined ? false : true;
  const back_navigation = () => {
    if (is_plan) {
      history.push('/account_setting');
    }
  };

  const redirect_ordersummary = () => {
    const planKey =
      formik.values?.plan[0]?.plan === undefined
        ? 0
        : formik.values?.plan[0]?.plan;
    const queryParams = `?key=${planKey}&count=1&current=${currentval}`;
    // history.push(`/order_summary${queryParams}`);
    window.location.assign(
      window.location.origin + `/order_summary${queryParams}`,
    );
  };

  //this function is for buy now button
  const redirect_ordersummary2 = () => {
    const planKey =
      formik.values?.plan[0]?.plan === undefined
        ? 0
        : formik.values?.plan[0]?.plan;
    const queryParams = `?key=${planKey}&count=1&current=${currentval}`;
    // history.push(`/order_summary${queryParams}`);
    window.location.assign(
      window.location.origin + `/order_summary${queryParams}`,
    );
  };

  console.log('wwwwwwww',formik.values.plan.length)

  return (
    <>
      {!isLoader && (
        <>
          <Flex row className={styles.ribbon} marginBottom={5} between>
            <Flex marginTop={9} marginLeft={8}>
              <Text size={16} bold color="theme">
                Subscription & Billing
              </Text>
            </Flex>
            <Flex>
              <div className={styles.triangle}></div>
            </Flex>
          </Flex>
          <Flex row>
            <Flex row marginTop={5} marginLeft={16}>
              <Flex
                style={{
                  fontSize: '15px',
                  color: '#581845',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => back_navigation()}
              >
                Account Settings
              </Flex>
              <Flex marginTop={7} marginLeft={5}>
                <SvgRight fill={'#581845'}></SvgRight>
              </Flex>
              <Flex
                marginLeft={5}
                style={{ fontSize: '15px', fontWeight: 'bold' }}
              >
                Subscription & Billing
              </Flex>
            </Flex>
          </Flex>
          <Flex
            className={styles.overAll}
            style={{
              overflowY: 'scroll',
              maxHeight:
                formik.values.plan.length === 0 && !anyChecked
                  ? componentHeight - 140
                  : componentHeight - 177,
              padding: '10px 10px',
            }}
          >
            <Flex row center between className={styles.titleStyle}>
              <Flex>
                <Text size={14} bold>
                  Subscription
                </Text>
              </Flex>
              <Flex row>
                {subscription &&
                  subscription.is_active === true &&
                  subscription.plan_id_id !== 1 &&
                  isEmpty(subscription.subscription_changed_to) ? (
                  <Button onClick={() => setCancelOne(true)} types="secondary">
                    Cancel Subscription
                  </Button>
                ) : subscription && subscription.plan_id_id !== 1 ? (
                  <Button onClick={hanldeRenew} types="primary">
                    Renew Subscription
                  </Button>
                ) : (
                  ''
                )}
                {subscription && subscription.plan_id_id !== 1 && (
                  <Button
                    onClick={handleInvoice}
                    style={{ marginLeft: '15px' }}
                  >
                    Invoices & Payment Info
                  </Button>
                )}
              </Flex>
            </Flex>
            {subscription &&
              subscription.plan_id_id === 1 &&
              free_expired === 1 && (
                <Flex columnFlex>
                  <Flex middle row center className={styles.warningFlex1}>
                    <SvgInfo height={16} width={16} fill={'#2E6ADD'} />
                    <Text
                      style={{
                        color: '#2E6ADD',
                        marginRight: '3px',
                        fontSize: '13px',
                        marginLeft: '7px',
                      }}
                      bold
                    >
                      Heads Up!{' '}
                    </Text>
                    <Text size={13} className={styles.warningText1}>
                      {`Your free trial ends on ${getDateString(
                        subscription?.subscription_end_ts,
                        'll',
                      )}. Please `}
                      <Text size={13} bold color="link" onClick={handleFocus}>
                        upgrade{' '}
                      </Text>
                      to a paid plan to get uninterrupted access and enjoy more
                      zita platform features along with your branded careers
                      page.
                    </Text>
                  </Flex>
                </Flex>
              )}

            {subscription &&
              subscription.is_active === true &&
              !isEmpty(subscription.subscription_changed_to) &&
              subscription.plan_id_id !== 1 &&
              Number(subscription.subscription_changed_to) !== -2 && (
                <Flex columnFlex>
                  <Flex middle row center className={styles.warningFlex1}>
                    <SvgInfo fill={'#2E6ADD'} height={16} width={16} />
                    <Text size={13} className={styles.warningText1}>
                      <Text
                        style={{
                          color: '#2E6ADD',
                          marginRight: '3px',
                          fontSize: '13px',
                        }}
                        bold
                      >
                        Heads Up!{' '}
                      </Text>
                      {`You have cancelled  your subscription on  ${getDateString(
                        subscription.subscription_changed_date,
                        'll',
                      )}. You have ${subscription.subscription_remains_days === 1
                          ? subscription.subscription_remains_days + ' day'
                          : subscription.subscription_remains_days + ' days'
                        } left to use the platform until your current subscription ends. Please click to `}
                      <Text
                        onClick={hanldeRenew}
                        style={{ fontSize: '13px' }}
                        bold
                        color="link"
                      >
                        Renew{' '}
                      </Text>
                      your subscription.
                    </Text>
                  </Flex>
                </Flex>
              )}

            {subscription &&
              subscription.is_active === true &&
              free_expired === 0 &&
              subscription.plan_id_id === 1 && (
                <Flex columnFlex>
                  <Flex middle row center className={styles.warningFlex1}>
                    <SvgInfo fill={'#2E6ADD'} height={16} width={16} />
                    <Text size={13} className={styles.warningText1}>
                      {`Your free trial expired. Please `}
                      <Text size={13} bold color="link" onClick={handleFocus}>
                        upgrade{' '}
                      </Text>
                      to access plan features.
                    </Text>
                  </Flex>
                </Flex>
              )}

            {subscription &&
              subscription.is_active === true &&
              Number(subscription.subscription_changed_to) === -2 &&
              subscription.plan_id_id !== 1 && (
                <Flex columnFlex>
                  <Flex middle row center className={styles.warningFlex1}>
                    <SvgInfo fill={'#2E6ADD'} height={16} width={16} />
                    <Text size={13} className={styles.warningText1}>
                      {`Your subscription expired. Please `}
                      <Text size={13} bold color="link" onClick={handleFocus}>
                        renew{' '}
                      </Text>
                      your subscription to access plan features
                    </Text>
                  </Flex>
                </Flex>
              )}
            <SingleButton
              btnTitle="OK"
              title={`User removed successfully. Total users for this subscription is ${subscription && subscription.no_of_users
                }`}
              open={isRemoveUser}
              btnOnclick={() => {
                setRemoveUser(false);
                if (query.has('modal_popup')) {
                  query.delete('modal_popup');
                  history.replace({
                    search: query.toString(),
                  });
                }
              }}
              svgTick
            />

            <SingleButton
              btnTitle="OK"
              title={
                'Payment Successfull. Your plan has been changed successfully.'
              }
              open={isPlanChanged}
              btnOnclick={() => {
                setPlanChanged(false);
                if (query.has('modal_popup')) {
                  query.delete('modal_popup');
                  history.replace({
                    search: query.toString(),
                  });
                }
                const storedAddOnId = localStorage.getItem('add_on_id');
                const storedAddOnCount = localStorage.getItem('add_on_count');
                const storedStipids = localStorage.getItem('stipids');
                dispatch(
                  createCheckoutMiddleWare({
                    can_count: storedAddOnCount,
                    plan: storedStipids,
                    add_on: storedAddOnId,
                    manage_sub: 'yes',
                    discounts: sessionStorage.getItem('addonid')
                  }),
                ).then((response) => {
                  dispatch(stripeMiddleWare()).then(async (stripRes) => {
                    const stripe = await loadStripe(stripRes.payload.publicKey);
                    if (response.payload.sessionId) {
                      stripe
                        ?.redirectToCheckout({
                          sessionId: response.payload.sessionId,
                        })
                        .then((resp) => {
                          setLoader(false);
                        });
                    }
                  });
                  sessionStorage.removeItem('addonid')
                });
              }}
              svgTick
            />
            <SingleButton
              btnTitle="OK"
              title={'Subscription renewed successfully.'}
              open={isRenew}
              btnOnclick={() => setRenew(false)}
              svgTick
            />

            <SingleButton
              btnTitle="OK"
              title={
                <Flex marginLeft={16}>
                  <Text>
                    Payment Successful. New users purchased successfully.
                  </Text>
                  <Text>
                    Total Users: {subscription && subscription.no_of_users}
                  </Text>
                </Flex>
              }
              open={isNewUser}
              btnOnclick={() => {
                setNewUser(false);
                if (query.has('modal_popup')) {
                  query.delete('modal_popup');
                  history.replace({
                    search: query.toString(),
                  });
                }
              }}
              svgTick
            />
            {subscription !== null && (
              <SubscriptionPlan
                setCancelOne={setCancelOne}
                isCancelOne={isCancelOne}
                setRenew={() => setRenew(true)}
              />
            )}
            {/* <div ref={} tabIndex={-1}> */}
            <PlansandFeatures
              setmanagesub={setmanagesub}
              formik={formik}
              subscription={subscription}
              totalUserManger={totalUserManger}
              downgrade={downgrade}
              total_plan_array={total_plan}
              yearly_plan={yearly_plan}
              monthly_plan={monthly_plan}
              free_plan={free_plan}
              focusRef={elementToFocusRef}
              elementsRef={elementsRef}
            />
            {/* </div> */}
            <AddOnCard
              managesub={managesub}
              formik1={formik}
              focustoRef={elementsRef}
              redirect_ordersummary2={redirect_ordersummary2}
              valuetolocal2={valuetolocal2}
            />
            <div ref={elementToFocusRef} tabIndex={-1}>
              <Flex
                style={{
                  justifyContent: ' flex-end',
                  bottom:
                    formik.values.plan.length === 0 && !anyChecked ? '' : '5px',
                  position:
                    formik.values.plan.length === 0 && !anyChecked
                      ? ''
                      : 'fixed',
                  right:
                    formik.values.plan.length === 0 && !anyChecked
                      ? ''
                      : '25px',
                }}
              >
                {subscription && subscription.plan_id_id === 1 ? (
                  ''
                ) : (
                  <Flex row marginRight={8}>
                    <Button
                      types="secondary"
                      style={{ margin: '0 10px 0 0' }}
                      onClick={() => {
                        if (subscription) {
                          setmanagesub(subscription.plan_name);
                        }
                        if (Array.isArray(formik.values?.addtocart)) {
                          const resetData = formik.values?.addtocart.map(
                            (item) => ({
                              ...item,
                              checked: false,
                            }),
                          );
                          formik.setFieldValue('addtocart', resetData);
                        } else {
                          // Initialize addtocart if it's not an array
                          formik.setFieldValue('addtocart', []);
                        }

                        formik.setFieldValue('plan', []);
                      }}
                    >
                      Cancel
                    </Button>
                    <Flex disabled={formik.values?.plan.length === 0 && !anyChecked}>
                      <Button
                        disabled={
                          formik.values?.plan.length === 0 && !anyChecked
                        }
                        onClick={() => {
                          valuetolocal();
                          redirect_ordersummary()
                        }}
                      >
                        Proceed
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </div>
          </Flex>
        </>
      )}
    </>
  );
};

export default ManageSubscriptionScreen;
