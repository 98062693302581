const defaultProps = {
    fill: '#FCC203',
    width: 16,
    height: 16,
  };
  
  const SvgModuleicon = ({ width, height, fill }: typeof defaultProps) => {
    return (
        <svg width="16" height="16" viewBox="0 0 14 14" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M7.20004 3.85967C7.33337 3.73967 7.49337 3.66634 7.66671 3.66634C7.84671 3.66634 8.00004 3.73967
        8.14004 3.85967C8.26671 3.99967 8.33337 4.15967 8.33337 4.33301C8.33337 4.51301 8.26671 4.66634 8.14004
        4.80634C8.00004 4.93301 7.84671 4.99967 7.66671 4.99967C7.49337 4.99967 7.33337 4.93301 7.20004 4.80634C7.07337
        4.66634 7.00004 4.51301 7.00004 4.33301C7.00004 4.15967 7.07337 3.99967 7.20004 3.85967ZM5.53337 6.97967C5.53337
        6.97967 6.98004 5.83301 7.50671 5.78634C8.00004 5.74634 7.90004 6.31301 7.85337 6.60634L7.84671 6.64634C7.75337
        6.99967 7.64004 7.42634 7.52671 7.83301C7.27337 8.75967 7.02671 9.66634 7.08671 9.83301C7.15337 10.0597 7.56671
        9.77301 7.86671 9.57301C7.90671 9.54634 7.94004 9.51967 7.97337 9.49967C7.97337 9.49967 8.02671 9.44634 8.08004
        9.51967C8.09337 9.53967 8.10671 9.55967 8.12004 9.57301C8.18004 9.66634 8.21337 9.69967 8.13337 
        9.75301L8.10671 9.76634C7.96004 9.86634 7.33337 10.3063 7.08004 10.4663C6.80671 10.6463 5.76004 11.2463
        5.92004 10.0797C6.06004 9.25968 6.24671 8.55301 6.39337 7.99967C6.66671 6.99967 6.78671 6.54634 6.17337
        6.93967C5.92671 7.08634 5.78004 7.17967 5.69337 7.23967C5.62004 7.29301 5.61337 7.29301 5.56671 
        7.20634L5.54671 7.16634L5.51337 7.11301C5.46671 7.04634 5.46671 7.03967 5.53337 6.97967ZM13.6667
        6.99967C13.6667 10.6663 10.6667 13.6663 7.00004 13.6663C3.33337 13.6663 0.333374 10.6663 0.333374
        6.99967C0.333374 3.33301 3.33337 0.333008 7.00004 0.333008C10.6667 0.333008 13.6667 3.33301 13.6667
        6.99967ZM12.3334 6.99967C12.3334 4.05301 9.94671 1.66634 7.00004 1.66634C4.05337 1.66634 1.66671
        4.05301 1.66671 6.99967C1.66671 9.94634 4.05337 12.333 7.00004 12.333C9.94671 12.333 12.3334 9.94634
        12.3334 6.99967Z" fill={fill}/>
        </svg>
        
    );
  };
  SvgModuleicon.defaultProps = defaultProps;
  
  export default SvgModuleicon;