import { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

import { GARY_4, PRIMARY } from '../../uikit/Colors/colors';
import Totalcount from '../../globulization/TotalCount';
import InputCheckBox from '../../uikit/InputCheckbox/InputCheckBox';
import Toast from '../../uikit/Toast/Toast';
import Loader from '../../uikit/Loader/Loader';

import { AppDispatch } from '../../store';
import Flex from '../../uikit/Flex/Flex';
import SvgDownload from '../../icons/SvgDownload';
import Text from '../../uikit/Text/Text';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import SvgLock from '../../icons/SvgLock';
import { ERROR_MESSAGE } from '../constValue';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import {
  bulkActionSourcingMiddleWare,
  bulkDownloadActionMiddleWare,
  createCheckoutMiddleWare,
  parsedTextMiddleWare,
  stripeMiddleWare,
} from './store/middleware/talentSoucringMiddleware';
import styles from './bulkaction.module.css';
import Talentsourcingmodalcountexite from './talentsourcingmodalcountexite';
import NoCountModal from './NoCountModal';
import Detailsmodel from './detailsmodel';
var FileSaver = require('file-saver');

const cx = classNames.bind(styles);

type Props = {
  searchResult?: number;
  isCheckAll: boolean;
  handleSelectAll: () => void;
  isCheckArray: string[];
  source_limit: number;
  setSourceLimit: (arg: number) => void;
  setCandidatesLimit: (arg: string) => void;
  isCandidatesLimit: string;
  setSuccess: (arg: boolean) => void;
  setNoLimit: (arg: boolean) => void;
  setNoPermission: (arg: boolean) => void;
  setNoCount: (arg: boolean) => void;
  setCandiList: (arg: string[]) => void;
  setFree: (arg: boolean) => void;
  planID?: number;
  setIsCheck: (arg: any) => void;
  resume_unlock_credits?: any;
  add_on_plans?: any;
  client_url?: any;
  setopensubcription?: (val: any) => void;
  lockedCandidatesCount?: any;
  unlockcan: any;
  setunlockcan: any;
  list_candidate: any;
  candi_list: any;
  setSearchData: any;
};
const BulkAction = ({
  searchResult,
  client_url,
  isCheckAll,
  handleSelectAll,
  isCheckArray,
  source_limit,
  setSourceLimit,
  setCandidatesLimit,
  isCandidatesLimit,
  setSuccess,
  setNoLimit,
  setNoPermission,
  setopensubcription,
  setNoCount,
  setCandiList,
  setFree,
  planID,
  setIsCheck,
  add_on_plans,
  resume_unlock_credits,
  lockedCandidatesCount,
  unlockcan,
  setunlockcan,
  list_candidate,
  candi_list,
  setSearchData,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [selectcard, setselectcard] = useState('');
  const [ismodelopen, setmodalopen] = useState(false);
  const [totalcount, settotalcount] = useState<any>();
  const [creditlimit, setcreditlimit] = useState<any>();
  const [allowingdata, setallowingdata] = useState<any>();
  const [isnocount, setcount] = useState(false);
  const [isSubmitLoader, setSubmitLoader] = useState(false);
  const [isshowtitle, setshowtitle] = useState(false);
  const [isOpenPlanDetails, setOpenPlanDetails] = useState(false);
  const [finalcandidates, setfinalcandidates] = useState(0)
  const [candidates, setcandidates] = useState<any>([]);

  // useEffect(() => {
  //   setcandidates(candi_list)
  // }, [])
  // bulk unlock function
  const handleUnlockSubmit = () => {
    if (resume_unlock_credits !== 0) {
      const filterData = (input, count) => {
        const items = input.filter(Boolean).map(item => item.trim());
        if (count <= items.length) {
          return items.slice(0, count);
        } else {
          return items;
        }
      }
      const count = resume_unlock_credits;
      const filteredData = filterData(isCheckArray, count);
      //this below function is used to filter only locked candidates from all list
      let filteredData1 = isCheckArray && isCheckArray.filter((hash, index) => {
        const candidateHash = hash;
        const correspondingCandidate = list_candidate.find(candidate => {
          return candidate.candidate_hash === candidateHash;
        });
        return correspondingCandidate && correspondingCandidate.unlock_status === "locked";
      });
      //removing duplicate item after unlocking
      const flattenedCandiList = candi_list.flat();
      const filteredCandidates = list_candidate.filter(candidate => {
        return flattenedCandiList.includes(String(candidate.id));
      });
      const filteredCandidateHashes = filteredCandidates.map(candidate => candidate.candidate_hash);
      let filteredData1Unique = filteredData1.filter(hash => !filteredCandidateHashes.includes(hash));
      if (filteredData1Unique.length > resume_unlock_credits) {
        setmodalopen(true);
        settotalcount(filteredData1Unique.length);
        setcreditlimit(resume_unlock_credits);
        setallowingdata(filteredData);
      }
      if (filteredData1Unique.length !== 0 && filteredData1Unique.length <= resume_unlock_credits) {
        setfinalcandidates(filteredData1Unique.length)
        if (isCheckArray.length === filteredData1Unique.length) {
          {
            dispatch(
              bulkActionSourcingMiddleWare({
                candi_list: filteredData1Unique,
                unlock: 'unlock',
              }),
            )
              .then((response) => {
                // setSearchData(response.payload.data)
                filteredData1Unique = []
                setIsCheck([])
                
                setCandiList(response.payload.candi_list);
                if (response.payload.unlock_can_list) {
                  dispatch(
                    parsedTextMiddleWare({
                      unlock_can_list: response.payload.unlock_can_list,

                    }),
                  ).then((res) => {
                    if (res.payload.success === false) {
                      Toast(
                        'Oops! Something went wrong. Please try again shortly.',
                        'LONG',
                        'error',
                      );
                    }
                  })
                  dispatch(SubsriptionMiddleWare())
                  setCandiList(response.payload.candi_list);
                  setCandidatesLimit(response.payload.candi_limit);
                  setSourceLimit(response.payload.source_limit);
                  setselectcard(response.payload.unlock_can_list);
                  if (
                    response.payload.success === true &&
                    response.payload.unlock_can_list.length !== 0
                  ) {
                    setSuccess(true);
                  }
                }

                if (response.payload.success === 'no_limit') {
                  setNoLimit(true);
                }
                if (response.payload.success === 'no_permission') {
                  setNoPermission(true);
                }
                if (response.payload.success === 'no_count') {
                  setopensubcription(true);
                }
                if (response.payload.success === false) {
                  Toast(
                    'Oops! Something went wrong. Please try again shortly.',
                    'LONG',
                    'error',
                  );
                }
                setIsCheck([])
                setunlockcan([])
                filteredData1Unique = []
              })
          }
        }
        else {
          setOpenPlanDetails(true)
        }
      }
    }
    else {
      setopensubcription(true);
      setshowtitle(true);
    }
  };
  const dispatchfunction = () => {
    let filteredData1 = isCheckArray && isCheckArray.filter((hash, index) => {
      const candidateHash = hash;
      const correspondingCandidate = list_candidate.find(candidate => {
        return candidate.candidate_hash === candidateHash;
      });
      return correspondingCandidate && correspondingCandidate.unlock_status === "locked";
    });
    const flattenedCandiList = candi_list.flat();
    const filteredCandidates = list_candidate.filter(candidate => {
      return flattenedCandiList.includes(String(candidate.id));
    });
    const filteredCandidateHashes = filteredCandidates.map(candidate => candidate.candidate_hash);
    let filteredData1Unique = filteredData1.filter(hash => !filteredCandidateHashes.includes(hash));
    {
      dispatch(
        bulkActionSourcingMiddleWare({
          candi_list: filteredData1Unique ,
          unlock: 'unlock',
        }),
      )
        .then((response) => {
          filteredData1Unique = []
          setIsCheck([])
          setCandiList(response.payload.candi_list);
          if (response.payload.unlock_can_list) {
            dispatch(
              parsedTextMiddleWare({
                unlock_can_list: response.payload.unlock_can_list,

              }),
            ).then((res) => {
              if (res.payload.success === false) {
                Toast(
                  'Oops! Something went wrong. Please try again shortly.',
                  'LONG',
                  'error',
                );
              }
            })
            dispatch(SubsriptionMiddleWare())
            setCandiList(response.payload.candi_list);
            setCandidatesLimit(response.payload.candi_limit);
            setSourceLimit(response.payload.source_limit);
            setselectcard(response.payload.unlock_can_list);
            if (
              response.payload.success === true &&
              response.payload.unlock_can_list.length !== 0
            ) {
              setSuccess(true);
            }
          }

          if (response.payload.success === 'no_limit') {
            setNoLimit(true);
          }
          if (response.payload.success === 'no_permission') {
            setNoPermission(true);
          }
          if (response.payload.success === 'no_count') {
            setopensubcription(true);
          }
          if (response.payload.success === false) {
            Toast(
              'Oops! Something went wrong. Please try again shortly.',
              'LONG',
              'error',
            );
          }
          setIsCheck([])
          setunlockcan([])
          filteredData1Unique = []
        })

    }
  }
  // they unlock the candidate with only available candidate count 
  const handleunlockavailablecount = (val) => {
    setmodalopen(false);
    dispatch(
      bulkActionSourcingMiddleWare({
        candi_list: val,
        unlock: 'unlock',
      }),
    )
      .then((response) => {
        if (response.payload.unlock_can_list) {
          dispatch(
            parsedTextMiddleWare({
              unlock_can_list: response.payload.unlock_can_list,

            }),
          ).then((res) => {
            if (res.payload.success === false) {
              Toast(
                'Oops! Something went wrong. Please try again shortly.',
                'LONG',
                'error',
              );
            }
          })
          dispatch(SubsriptionMiddleWare())
          setCandiList(response.payload.candi_list);
          setCandidatesLimit(response.payload.candi_limit);
          setSourceLimit(response.payload.source_limit);
          setselectcard(response.payload.unlock_can_list);
          if (
            response.payload.success === true &&
            response.payload.unlock_can_list.length !== 0
          ) {
            setSuccess(true);
          }
        }

        if (response.payload.success === 'no_limit') {
          setNoLimit(true);
        }
        if (response.payload.success === 'no_permission') {
          setNoPermission(true);
        }
        if (response.payload.success === 'no_count') {
          setNoCount(true);
        }
        if (response.payload.success === false) {
          Toast(
            'Oops! Something went wrong. Please try again shortly.',
            'LONG',
            'error',
          );
        }
        setIsCheck([]);
      })
  }

  // bulk download submit
  const handleDownloadSubmit = () => {
    if (isCheckArray.length !== 0) {
      dispatch(
        bulkDownloadActionMiddleWare({
          candi_list: isCheckArray,
          download: 'download',
        }),
      )
        .then((response) => {
          if (response.payload.file_path) {
            FileSaver.saveAs(
              window.location.protocol + '//' + response.payload.file_path,
              `Talent Sourcing_Candidate Profile_${moment(new Date()).format('ll')}.zip`,
              Toast('Resume exported successfully.', 'LONG', 'success'),
            );
          }
        })
        .catch(() => {
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
  };


  const checkArray = isCheckArray.length === 0 ? false : true;
  const value = isCheckArray.map(val => val[0]).filter(id => id !== undefined);

  return (
    <>
      {isSubmitLoader && <Loader />}

      {/* the contect cridit count exsit when the select more then the contect cridit open this model */}
      {ismodelopen &&
        <Talentsourcingmodalcountexite
          setmodalopen={setmodalopen}
          ismodalopen={ismodelopen}
          totalcount={totalcount}
          creditlimit={creditlimit}
          allowingdata={allowingdata}
          handleunlockavailablecount={handleunlockavailablecount}
          setNoCount={setopensubcription}
        />}
      {isOpenPlanDetails && <Detailsmodel
        open={isOpenPlanDetails}
        setOpenPlanDetails={setOpenPlanDetails}
        value={value.length}
        finalcandidates={finalcandidates}
        dispatchfunction={dispatchfunction}
      />
      }
      <Flex row center between wrap className={styles.overAll} width={'70%'}>
        <Flex row center>
          <InputCheckBox
            name="selectAll"
            id={'selectAll'}
            onChange={handleSelectAll}
            checked={isCheckAll}
          />
          <Text
            id="bulkaction__search_results"
            className={styles.searchTextStyle}
          >
            <Totalcount
              name="Search Results"
              numbers={searchResult}
            />
          </Text>
        </Flex>
        {checkArray && (
          <Flex row center>
            <Flex row center className={styles.bulkSelection}>
              <Flex marginRight={0}>
                <Text color="theme">{`Selected ${value.length} Candidates `}</Text>
              </Flex>

              <Flex row className={styles.bulkButton}>
                <Flex
                  disabled={lockedCandidatesCount === 0}
                  row
                  center
                  marginRight={20}
                  style={{
                    paddingLeft: '5px',
                    borderLeft: '1px solid #581845',
                    cursor: 'pointer',
                    alignItems: "center"
                  }}
                  onClick={handleUnlockSubmit}
                > <Flex marginBottom={3} style={{ cursor: 'pointer', }} disabled={lockedCandidatesCount === 0}>
                    <SvgLock fill={PRIMARY} height={15} width={15} />
                  </Flex>
                  <Flex disabled={lockedCandidatesCount === 0} >
                    <Text bold
                      style={{ marginLeft: '4px', cursor: 'pointer', }}
                      color="theme"
                    >
                      Unlock Contacts
                    </Text>
                  </Flex>
                  {resume_unlock_credits === 0 &&
                    <Flex marginLeft={4} style={{ paddingBottom: '4px', cursor: 'pointer', }}>
                      <SvgSubcriptioncrown
                        height={16}
                        width={16}
                        fill=""
                      />
                    </Flex>}
                </Flex>
                <Flex
                  row
                  center
                  style={{
                    paddingLeft: '5px',
                    borderLeft: '1px solid #581845',
                    cursor: 'pointer',
                  }}
                  onClick={handleDownloadSubmit}
                >
                  <SvgDownload width={14} height={14} />
                  <Text bold style={{ marginLeft: '10px' }} color="theme">
                    Export Resumes
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
        {/* <Flex row center>
          <Text>
            <Totalcount
              name="Sourcing Contact Credits"
              numbers={resume_unlock_credits}
            />
          </Text>
        </Flex> */}
      </Flex>
    </>
  );
};
export default BulkAction;
