import React from "react";
import { Link } from "react-router-dom";
import SvgInstagram from "../../../icons/SvgInstagram";
import SvgLinkedinFooter from "../../../icons/SvgLinkedinFooter";
import SvgFaceBook from "../../../icons/SvgFaceBook";

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/",
  },
  {
    icon: "fa-twitter",
    link: "https://twitter.com/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/",
  },
  {
    icon: "fa-meetup",
    link: "https://www.facebook.com/",
  },
  // {
  //   icon: "fa-pinterest",
  //   link: "https://twitter.com/",
  // },
  {
    icon: "fa-instagram",
    link: "https://www.linkedin.com/",
  },
];

const CopyRight = () => {
  return (
    <div
      className="d-md-flex justify-content-between"
      style={{ marginTop: "20px" }}
    >
      <ul className="order-md-last" style={{display:'flex'}}>
        <li>
          <p style={{ color: "#fff" }}>
            {new Date().getFullYear()} Sense7ai,Inc &#169; All Rights Reserved
          </p>
        </li>

        <li style={{ marginRight: "10px", color: "white" }}>|</li>

        <li>
          <a href="https://www.zita.ai/privacy-policy" style={{ marginRight: "10px" }} className="hoverstyle">
          Privacy Policy  
          </a>
        </li>
        <li style={{ marginRight: "10px", color: "white" }}>|</li>
        <li>
          <a href="https://www.zita.ai/terms-and-conditions" onClick={() => window.scrollTo(0, 0)} style={{ marginRight: "0px" }} className="hoverstyle">
            Terms And Conditions
          </a>
        </li>
      </ul>
      <div className="col-lg-2 order-lg-2 mb-10" style={{ padding: "0" }}>
        <ul
          className=" d-flex
              marginleft
              social-icon
              footeralignment
              md-mt-10
              "
        >
          <li>
            <a
              href="https://www.facebook.com/ZitaAI?mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer"
            >
              <SvgFaceBook/>
            </a>
          </li>
          {/* <li>
            <a
              href="https://twitter.com/i/flow/login?redirect_after_login=%2Finnovspace"
              target="_blank"
              rel="noreferrer"
            >
             
              <img
                        src={`images/icon/twitterx.svg`}
                        alt="icon"
                        className=""
                        style={{display:'flex',alignItems:'center',width:'19px',marginTop:'7px'}}
                    />
              
            </a>
          </li> */}
          <li>
            <a
              href="https://www.linkedin.com/company/zita-ai/"
              target="_blank"
              rel="noreferrer"
            >
             <SvgLinkedinFooter/>
             
            </a>
          </li>
          {/* <li>
            <a
              href="https://www.pinterest.com/innovspace/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-pinterest fa-paint-col"></i>
            </a>
          </li> */}
          <li style={{marginTop:'1px'}}>
            <a
              href="https://www.instagram.com/zita.ai?igsh=MWduenA4d2hzbXY3cw=="
              target="_blank"
              rel="noreferrer"
            >
              <SvgInstagram/>
            </a>
          </li>
        </ul>
        {/* End .social-icon */}
      </div>
    </div>
  );
};

export default CopyRight;
