import * as React from 'react';
const SvgFilterRefresh = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={19}
    fill="none"
    viewBox='0 0 16 19'
    {...props}
  >
    <path
      fill="#581845"
      d="M7 18.45c-2.017-.25-3.688-1.13-5.013-2.637C.662 14.303 0 12.533 0 10.5c0-1.1.217-2.154.65-3.162A7.797 7.797 0 0 1 2.5 4.7l1.425 1.425A5.635 5.635 0 0 0 2.487 8.1 5.97 5.97 0 0 0 2 10.5c0 1.467.467 2.762 1.4 3.887.933 1.125 2.133 1.813 3.6 2.063v2Zm2 0v-2c1.45-.267 2.646-.958 3.588-2.075C13.529 13.258 14 11.967 14 10.5c0-1.667-.583-3.083-1.75-4.25C11.083 5.083 9.667 4.5 8 4.5h-.075l1.1 1.1-1.4 1.4-3.5-3.5 3.5-3.5 1.4 1.4-1.1 1.1H8c2.233 0 4.125.775 5.675 2.325C15.225 6.375 16 8.267 16 10.5c0 2.017-.662 3.78-1.988 5.287-1.325 1.509-2.995 2.396-5.012 2.663Z"
    />
  </svg>
);
export default SvgFilterRefresh;