const defaultProps = {
    fill: '#424242',
    width: '13',
    height: '13',
    className: '',
};

interface Props {
    fill?: string;
    width?: string | number;
    height?: string | number;
    className?: string;
}

const Svgsubcriptionduration = ({ fill, width, height, className }: Props) => (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.952381 2.85714H7.61905V1.90476H0.952381V2.85714ZM0.952381 9.52381C0.690476 9.52381 0.466349 
9.43064 0.28 9.24429C0.0936508 9.05794 0.00031746 8.83365 0 8.57143V1.90476C0 1.64286 0.0933334 1.41873 
0.28 1.23238C0.466667 1.04603 0.690794 0.952698 0.952381 0.952381H1.42857V0H2.38095V0.952381H6.19048V0H7.14286V0.952381H7.61905C7.88095
0.952381 8.10524 1.04571 8.2919 1.23238C8.47857 1.41905 8.57175 1.64317 8.57143 1.90476V4.60714C8.42064
4.53571 8.26587 4.47619 8.10714 4.42857C7.94841 4.38095 7.78571 4.34524 7.61905
4.32143V3.80952H0.952381V8.57143H3.95238C4.00794 8.74603 4.07349 8.9127 4.14905 9.07143C4.2246 
9.23016 4.31381 9.38095 4.41667 9.52381H0.952381ZM7.14286 10C6.48413 10 5.9227 9.76778 5.45857 
9.30333C4.99444 8.83889 4.76222 8.27746 4.7619 7.61905C4.7619 6.96032 4.99413 6.39889 5.45857
5.93476C5.92302 5.47063 6.48444 5.23841 7.14286 5.2381C7.80159 5.2381 8.36317 5.47032 8.82762 5.93476C9.29206
6.39921 9.52413 6.96063 9.52381 7.61905C9.52381 8.27778 9.29159 8.83937 8.82714 9.30381C8.3627 9.76825 7.80127
10.0003 7.14286 10ZM7.94048 8.75L8.27381 8.41667L7.38095 7.52381V6.19048H6.90476V7.71429L7.94048 8.75Z" fill="#581845"/>
</svg> 
);

Svgsubcriptionduration.defaultProps = defaultProps;

export default Svgsubcriptionduration;
