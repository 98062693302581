const defaultProps = {
    fill: '#424242',
    width: '13',
    height: '13',
    className: '',
};

interface Props {
    fill?: string;
    width?: string | number;
    height?: string | number;
    className?: string;
}

const Svgsubcriptiondoller = ({ fill, width, height, className }: Props) => (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 0C2.23884 0 0 2.23884 0 5C0 7.76116 2.23884 10 5 10C7.76116 10 10 7.76116 10 5C10 2.23884
    7.76116 0 5 0ZM5 9.15179C2.70759 9.15179 0.848214 7.29241 0.848214 5C0.848214 2.70759 2.70759 0.848214
    5 0.848214C7.29241 0.848214 9.15179 2.70759 9.15179 5C9.15179 7.29241 7.29241 9.15179 5 9.15179ZM5.53237
    4.74107L5.24888 4.67522V3.17634C5.67299 3.23438 5.93527 3.5 5.97991 3.82589C5.98549 3.87054 6.02344 
    3.9029 6.06808 3.9029H6.5692C6.62165 3.9029 6.66295 3.85714 6.65848 3.80469C6.5904 3.10938 6.01786 
    2.66295 5.25335 2.58594V2.22098C5.25335 2.17188 5.21317 2.1317 5.16406 2.1317H4.85045C4.80134 2.1317 
    4.76116 2.17188 4.76116 2.22098V2.58929C3.97098 2.66629 3.35268 3.10268 3.35268 3.91741C3.35268 4.67188 
    3.90848 5.03571 4.49219 5.17522L4.76786 5.24554V6.83817C4.27455 6.77232 3.99777 6.50893 3.94085 6.15402C3.93415
    6.11161 3.89621 6.08036 3.85268 6.08036H3.33705C3.2846 6.08036 3.2433 6.125 3.24777 6.17746C3.29799 6.79129
    3.76339 7.35603 4.7567 7.42857V7.77902C4.7567 7.82812 4.79688 7.8683 4.84598 7.8683H5.16295C5.21205 7.8683
    5.25223 7.82813 5.25223 7.7779L5.25 7.42411C6.12388 7.3471 6.74888 6.87946 6.74888 6.04018C6.74777 5.26563
    6.25558 4.91964 5.53237 4.74107ZM4.76674 4.56027C4.70424 4.54241 4.65179 4.52567 4.59933 4.50446C4.2221 
    4.3683 4.04688 4.14844 4.04688 3.86496C4.04688 3.45982 4.3538 3.22879 4.76674 3.17634V4.56027ZM5.24888
    6.84152V5.34933C5.28348 5.35938 5.31473 5.36719 5.3471 5.37388C5.875 5.5346 6.05246 5.75781 6.05246 
    6.10045C6.05246 6.53683 5.72433 6.79911 5.24888 6.84152Z" fill="#581845" />
    </svg>
);

Svgsubcriptiondoller.defaultProps = defaultProps;

export default Svgsubcriptiondoller;
