const defaultProps = {
    fill:'',
    width: 26,
    height: 22,
};

const Svgchatbotsendicon = ({ fill, width, height }: typeof defaultProps) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_11021_28" fill="white">
            <path d="M9.7525 9.18205L2.80173 10.3402C2.72182 10.3535 2.64682 10.3877 2.58429 10.4392C2.52176 10.4907 
            2.4739 10.5578 2.44551 10.6337L0.0489189 17.0547C-0.179944 17.6453 0.437431 18.2083 1.00405 17.924L17.615 
            9.61855C17.7298 9.56101 17.8264 9.47266 17.8938 9.36338C17.9613 9.25411 17.997 9.12822 17.997 8.99979C17.997
             8.87137 17.9613 8.74548 17.8938 8.6362C17.8264 8.52693 17.7298 8.43858 17.615 8.38103L1.00405 0.0755413C0.437431 
             -0.207768 -0.179944 0.355159 0.0489189 0.944849L2.44644 7.36592C2.47482 7.4418 2.52269 7.50887 2.58522 7.56038C2.64775
              7.6119 2.72274 7.64604 2.80265 7.65938L9.75343 8.81753C9.79678 8.82445 9.83626 8.8466 9.86475 8.88001C9.89325
               8.91341 9.9089 8.95588 9.9089 8.99979C9.9089 9.0437 9.89325 9.08617 9.86475 9.11957C9.83626 9.15298 9.79678
                9.17513 9.75343 9.18205" />
        </mask>
        <path d="M9.7525 9.18205L2.80173 10.3402C2.72182 10.3535 2.64682 10.3877 2.58429 10.4392C2.52176 10.4907 2.4739 
        10.5578 2.44551 10.6337L0.0489189 17.0547C-0.179944 17.6453 0.437431 18.2083 1.00405 17.924L17.615 9.61855C17.7298
         9.56101 17.8264 9.47266 17.8938 9.36338C17.9613 9.25411 17.997 9.12822 17.997 8.99979C17.997 8.87137 17.9613 8.74548
          17.8938 8.6362C17.8264 8.52693 17.7298 8.43858 17.615 8.38103L1.00405 0.0755413C0.437431 -0.207768 -0.179944 0.355159
           0.0489189 0.944849L2.44644 7.36592C2.47482 7.4418 2.52269 7.50887 2.58522 7.56038C2.64775 7.6119 2.72274 7.64604 
           2.80265 7.65938L9.75343 8.81753C9.79678 8.82445 9.83626 8.8466 9.86475 8.88001C9.89325 8.91341 9.9089 8.95588 9.9089
            8.99979C9.9089 9.0437 9.89325 9.08617 9.86475 9.11957C9.83626 9.15298 9.79678 9.17513 9.75343 9.18205" fill={fill} />
        <path d="M2.80173 10.3402L2.63737 9.3538L2.63711 9.35385L2.80173 10.3402ZM2.44551 10.6337L1.5089 10.2833L1.50864 
        10.284L2.44551 10.6337ZM0.0489189 17.0547L0.981433 17.4161L0.985789 17.4044L0.0489189 17.0547ZM1.00405 17.924L0.556837
         17.0296L0.555673 17.0302L1.00405 17.924ZM17.615 9.61855L18.0622 10.513L18.0631 10.5126L17.615 9.61855ZM17.615
          8.38103L18.0631 7.48703L18.0622 7.48661L17.615 8.38103ZM1.00405 0.0755413L1.45126 -0.818886V-0.818886L1.00405 
          0.0755413ZM0.0489189 0.944849L0.985823 0.595025L0.98117 0.583037L0.0489189 0.944849ZM2.44644 7.36592L1.50961 
          7.71571L1.50982 7.71627L2.44644 7.36592ZM2.80265 7.65938L2.63804 8.64574L2.63829 8.64578L2.80265 7.65938ZM9.75343
           8.81753L9.58907 9.80396L9.59581 9.80503L9.75343 8.81753ZM9.58815 8.19565L2.63737 9.3538L2.96608 11.3266L9.91686 
           10.1685L9.58815 8.19565ZM2.63711 9.35385C2.38401 9.39609 2.14649 9.50423 1.94845 9.66739L3.22014 11.211C3.14716 
           11.2711 3.05963 11.311 2.96634 11.3266L2.63711 9.35385ZM1.94845 9.66739C1.7504 9.83054 1.5988 10.043 1.5089 
           10.2833L3.38213 10.984C3.349 11.0726 3.29313 11.1509 3.22014 11.211L1.94845 9.66739ZM1.50864 10.284L-0.887951 
           16.7051L0.985789 17.4044L3.38239 10.9833L1.50864 10.284ZM-0.883523 16.6934C-1.44162 18.1337 0.0648276 19.5139 
           1.45243 18.8179L0.555673 17.0302C0.810035 16.9026 1.08173 17.157 0.98136 17.4161L-0.883523 16.6934ZM1.45126 
           18.8185L18.0622 10.513L17.1678 8.72412L0.556837 17.0296L1.45126 18.8185ZM18.0631 10.5126C18.3438 10.3719 18.5798
            10.1559 18.7447 9.88871L17.0429 8.83806C17.073 8.78945 17.1159 8.75015 17.167 8.72455L18.0631 10.5126ZM18.7447 
            9.88871C18.9097 9.62155 18.997 9.31377 18.997 8.99979H16.997C16.997 8.94266 17.0129 8.88666 17.0429 8.83806L18.7447
             9.88871ZM18.997 8.99979C18.997 8.68582 18.9097 8.37804 18.7447 8.11088L17.0429 9.16153C17.0129 9.11292 16.997 
             9.05692 16.997 8.99979H18.997ZM18.7447 8.11088C18.5798 7.84371 18.3438 7.62771 18.0631 7.48703L17.167 9.27503C17.1159 
             9.24944 17.073 9.21014 17.0429 9.16153L18.7447 8.11088ZM18.0622 7.48661L1.45126 -0.818886L0.556837 0.969968L17.1678 
             9.27546L18.0622 7.48661ZM1.45126 -0.818886C0.0664973 -1.51127 -1.44297 -0.135299 -0.883332 1.30666L0.98117 
             0.583037C1.08308 0.845618 0.808366 1.09573 0.556837 0.969968L1.45126 -0.818886ZM-0.887907 1.29464L1.50961
             7.71571L3.38326 7.01612L0.985745 0.595054L-0.887907 1.29464ZM1.50982 7.71627C1.59972 7.95661 1.75132 8.16904 
             1.94937 8.3322L3.22106 6.78857C3.29405 6.8487 3.34992 6.92699 3.38305 7.01556L1.50982 7.71627ZM1.94937 8.3322C2.14742
              8.49536 2.38494 8.6035 2.63804 8.64574L2.96727 6.67302C3.06054 6.68859 3.14808 6.72844 3.22106 6.78857L1.94937 
              8.3322ZM2.63829 8.64578L9.58907 9.80393L9.91778 7.83113L2.96701 6.67298L2.63829 8.64578ZM9.59581 9.80503C9.40424 
              9.77446 9.22985 9.67659 9.10396 9.529L10.6255 8.23102C10.4427 8.01662 10.1893 7.87445 9.91104 7.83003L9.59581 
              9.80503ZM9.10396 9.529C8.97806 9.38141 8.9089 9.19378 8.9089 8.99979H10.9089C10.9089 8.71799 10.8084 8.44541 
              10.6255 8.23102L9.10396 9.529ZM8.9089 8.99979C8.9089 8.8058 8.97806 8.61817 9.10396 8.47058L10.6255 9.76857C10.8084
               9.55417 10.9089 9.2816 10.9089 8.99979H8.9089ZM9.10396 8.47058C9.22985 8.32299 9.40424 8.22513 9.59581 
               8.19455L9.91104 10.1696C10.1893 10.1251 10.4427 9.98296 10.6255 9.76857L9.10396 8.47058Z" fill= {fill}
            mask="url(#path-1-inside-1_11021_28)" />
    </svg>
);

Svgchatbotsendicon.defaultProps = defaultProps;

export default Svgchatbotsendicon;

