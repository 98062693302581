import * as React from 'react';

const SvgCalendaricon = (props) => (

  <svg

    xmlns="http://www.w3.org/2000/svg"

    width={11}

    height={11}

    fill="none"

    viewBox='0 0 10 10'

    {...props}

  >

    <path

      fill="#581845"

      d="M.714 3.571v5.715h8.572V1.429H7.857v.357a.357.357 0 1 1-.714 0v-.357H2.857v.357a.357.357 0 1 1-.714 0v-.357H.714v1.428h8.572v.714H.714ZM2.857.714h4.286V.357a.357.357 0 1 1 .714 0v.357h1.786a.357.357 0 0 1 .357.357v8.572a.357.357 0 0 1-.357.357H.357A.357.357 0 0 1 0 9.643V1.07A.357.357 0 0 1 .357.714h1.786V.357a.357.357 0 1 1 .714 0v.357ZM2.5 5h.714a.357.357 0 0 1 0 .714H2.5A.357.357 0 0 1 2.5 5Zm0 2.143h.714a.357.357 0 1 1 0 .714H2.5a.357.357 0 1 1 0-.714ZM4.643 5h.714a.357.357 0 0 1 0 .714h-.714a.357.357 0 0 1 0-.714Zm0 2.143h.714a.357.357 0 1 1 0 .714h-.714a.357.357 0 1 1 0-.714ZM6.786 5H7.5a.357.357 0 0 1 0 .714h-.714a.357.357 0 1 1 0-.714Zm0 2.143H7.5a.357.357 0 1 1 0 .714h-.714a.357.357 0 1 1 0-.714Z"

    />

  </svg>

);

export default SvgCalendaricon;

