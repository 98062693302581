import { createRef, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import SvgDotMenu from '../../icons/SvgDotMenu';
import SvgArrowUp from '../../icons/SvgArrowUp';
import styles from './dndtitle.module.css';
import { IStageColumn } from './dndBoardTypes';
import { KANBAN_COLUMN_WIDTH } from './applicantPipeLineTypes';



type Props = {
  column: IStageColumn;
  setSortApplicant: (columnId: number, arg: string, backword?: boolean) => void;
  index: number;
  onSelectAll?: (data: IStageColumn) => void;
  onUnselectAll?: (data: IStageColumn) => void;
  columnSelected?: boolean;
  stages_name?: any;
};
const DndTitleMap = ({
  column,
  setSortApplicant,
  index,
  onSelectAll,
  onUnselectAll,
  columnSelected,
  stages_name
}: Props) => {
  const [isDropDown, setDropDown] = useState(false);
  const myRef = createRef<any>();
  const handleOpenDrop = () => setDropDown(!isDropDown);

  const handleClickOutside = (event: { target: any }) => {
    if (myRef.current && !myRef.current.contains(event.target)) {
      setDropDown(false);
    }
  };

  // outside click function
  useEffect(() => {
    if (typeof Window !== 'undefined') {
      document.addEventListener('click', handleClickOutside, true);
    }
    return () => {
      if (myRef) {
        if (typeof Window !== 'undefined') {
          document.removeEventListener('click', handleClickOutside, true);
        }
      }
    };
  });
  const selectall = () => {
    onSelectAll(column);
    setDropDown(false);
  }
  const unselectall = () => {
    onUnselectAll(column);
    setDropDown(false);
  }
  // date sort function
  const hanldeDateSort = (indexValue: number) => {
    setSortApplicant(column.columnId, 'date', false);
    setDropDown(false);
  };
  // date sort backword function
  const hanldeDatebackwordSort = (indexValue: number) => {
    setSortApplicant(column.columnId, 'datebackword', true);
    setDropDown(false);
  };
  //name sort function
  const hanldeNameSort = (indexValue: number) => {
    setSortApplicant(column.columnId, 'name', false);

    setDropDown(false);
  };
  //name sort backword function
  const hanldeNamebackwordSort = (indexValue: number) => {
    setSortApplicant(column.columnId, 'namebackword', true);

    setDropDown(false);
  };
  // match sort function
  const hanldeMatchSort = (indexValue: number) => {
    setSortApplicant(column.columnId, 'match', false);
    setDropDown(false);
  };

  // match sort backword function
  const hanldeMatchbackwordSort = (indexValue: number) => {
    setSortApplicant(column.columnId, 'matchbackword', true);
    setDropDown(false);
  };

  //meeting scheduled sort function
  const hanldeMeetingScheduled = (indexValue: number) => {
    setSortApplicant(column.columnId, 'scheduled', false);
    setDropDown(false);
  };

  //meeting not scheduled sort function
  const hanldeMeetingNotScheduled = (indexValue: number) => {
    setSortApplicant(column.columnId, 'notscheduled', true);
    setDropDown(false);
  };

  //staging based  sort function
  const hanldestaging = (indexValue: number, val: any) => {
    setSortApplicant(column.columnId, val.label, true);
    setDropDown(false);
  };
  // pushing the data in this format to show the stages as dropdown in the  sorting
  const transformedData = stages_name?.map(item => ({
    value: item?.stage_id,
    label: item?.stage_name,
  }));
  return (
    <div
      style={{ left: column?.left, borderBottomColor: column?.stage_color, minWidth: KANBAN_COLUMN_WIDTH }}
      className={styles.colTitle}
    >
      <Flex row center>
        <Text
          style={{
            color: column?.stage_color,
            borderBottom: 3,
            fontWeight: 500,
          }}
        >
          {column?.title}
        </Text>
        <Text
          style={{ marginLeft: 4, color: column?.stage_color, fontWeight: 500 }}
        >
          ({column?.total})
        </Text>
      </Flex>

      {column?.total !== 0 && <div ref={myRef}>
        <div
          onKeyPress={() => { }}
          role={'button'}
          tabIndex={-1}
          onClick={handleOpenDrop}
          className={styles.svgSort}
        >
          <SvgDotMenu width={14} height={14} fill={column?.stage_color} />
        </div>
        {isDropDown && (
          <Flex className={styles.dropDownFlex}>
            {!columnSelected ? (
              <>
                <Text size={13} style={{
                  padding: '6px 15px',
                  color: '#999999'
                }}>
                  Option
                </Text>
                <Text
                  onClick={selectall}
                  className={styles.dropDate}
                >
                  {'Select All'}
                </Text>
                <Dropdown.Divider style={{ margin: '0px' }} />
                <Text size={13} style={{
                  padding: '6px 15px',
                  color: '#999999'
                }}>
                  Sort By
                </Text>
                <Dropdown drop="right" className={styles.dropDate} >
                  <Dropdown.Toggle
                    className={styles.customToggle}
                    style={{
                      borderColor: 'unset',
                      backgroundColor: 'unset',
                      boxShadow: 'none',
                      padding: '0px 5px',
                      marginRight: '5px',
                      color: 'black',
                      border: 'none',
                      fontSize: '13px',
                      textTransform: 'capitalize',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    Applied Date
                    <SvgArrowUp
                      width={10}
                      height={10}
                      strokeWidth={25}
                      viewBox="0 0 380 380"
                      style={{
                        transform: 'scaleY(-1)',
                        rotate: '90deg',
                        transition: 'transform 0.2s ease',
                        cursor: 'pointer',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={styles.rightbox}>
                    <Dropdown.Item onClick={() => hanldeDateSort(index)}>
                      <Text
                      >
                        {'Applied Date (Newest First)'}
                      </Text>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => hanldeDatebackwordSort(index)} >
                      <Text
                      >
                        {'Applied Date (Oldest First)'}
                      </Text>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown drop="right" className={styles.dropDate} >
                  <Dropdown.Toggle
                    className={styles.customToggle}
                    style={{
                      borderColor: 'unset',
                      backgroundColor: 'unset',
                      boxShadow: 'none',
                      padding: '0px 5px',
                      marginRight: '5px',
                      color: 'black',
                      border: 'none',
                      fontSize: '13px',
                      textTransform: 'capitalize',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    Name
                    <SvgArrowUp
                      width={10}
                      height={10}
                      strokeWidth={25}
                      viewBox="0 0 380 380"
                      style={{
                        transform: 'scaleY(-1)',
                        rotate: '90deg',
                        transition: 'transform 0.2s ease',
                        cursor: 'pointer',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={styles.rightbox}>
                    <Dropdown.Item onClick={() => hanldeNameSort(index)}>
                      <Text
                      >
                        {'Name (A-Z)'}
                      </Text>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => hanldeNamebackwordSort(index)}>
                      <Text
                      >
                        {' Name (Z-A)'}
                      </Text>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown drop="right" className={styles.dropDate} >
                  <Dropdown.Toggle
                    className={styles.customToggle}
                    style={{
                      borderColor: 'unset',
                      backgroundColor: 'unset',
                      boxShadow: 'none',
                      padding: '0px 5px',
                      marginRight: '5px',
                      color: 'black',
                      border: 'none',
                      fontSize: '13px',
                      textTransform: 'capitalize',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    Match Score
                    <SvgArrowUp
                      width={10}
                      height={10}
                      strokeWidth={25}
                      viewBox="0 0 380 380"
                      style={{
                        transform: 'scaleY(-1)',
                        rotate: '90deg',
                        transition: 'transform 0.2s ease',
                        cursor: 'pointer',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={styles.rightbox}>
                    <Dropdown.Item onClick={() => hanldeMatchSort(index)}>
                      <Text
                      >
                        {'Match Score (High - Low)'}
                      </Text>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => hanldeMatchbackwordSort(index)}>
                      <Text
                      >
                        {'Match Score (Low - High)'}
                      </Text>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* {column.stage_name !== "New Applicants" &&
                  <Dropdown drop="right" className={styles.dropDate} >
                    <Dropdown.Toggle
                      className={styles.customToggle}
                      style={{
                        borderColor: 'unset',
                        backgroundColor: 'unset',
                        boxShadow: 'none',
                        padding: '0px 5px',
                        marginRight: '5px',
                        color: 'black',
                        border: 'none',
                        fontSize: '13px',
                        textTransform: 'capitalize',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      Meeting Scheduled
                      <SvgArrowUp
                        width={10}
                        height={10}
                        strokeWidth={25}
                        viewBox="0 0 380 380"
                        style={{
                          transform: 'scaleY(-1)',
                          rotate: '90deg',
                          transition: 'transform 0.2s ease',
                          cursor: 'pointer',
                        }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={styles.rightbox}>
                      <Dropdown.Item onClick={() => hanldeMeetingScheduled(index)} >
                        <Text
                        >
                          {'Meeting Scheduled'}
                        </Text>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => hanldeMeetingNotScheduled(index)}>
                        <Text
                        >
                          {'Meeting Not Scheduled'}
                        </Text>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>} */}
                {/* {column.stage_name !== "New Applicants" &&
                  <Dropdown drop="right" className={styles.dropDate} >
                    <Dropdown.Toggle
                      className={styles.customToggle}
                      style={{
                        borderColor: 'unset',
                        backgroundColor: 'unset',
                        boxShadow: 'none',
                        padding: '0px 5px',
                        marginRight: '5px',
                        color: 'black',
                        border: 'none',
                        fontSize: '13px',
                        textTransform: 'capitalize',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      Status
                      <SvgArrowUp
                        width={10}
                        height={10}
                        strokeWidth={25}
                        viewBox="0 0 380 380"
                        style={{
                          transform: 'scaleY(-1)',
                          rotate: '90deg',
                          transition: 'transform 0.2s ease',
                          cursor: 'pointer',
                        }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={styles.rightbox}>
                      {transformedData.map((val, i) => {
                        return (
                          <Dropdown.Item onClick={() => hanldestaging(i, val)} key={i}>
                            <Text>
                              {val.label}
                            </Text>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>} */}
              </>
            ) : (
              <>
                <Text size={13} style={{
                  padding: '6px 15px',
                  color: '#999999'
                }}>
                  Option
                </Text>
                <Text
                  onClick={unselectall}
                  className={styles.dropDate}
                >
                  {'Unselect All'}
                </Text>
                <Dropdown.Divider style={{ margin: '0px' }} />
                <Text size={13} style={{
                  padding: '6px 15px',
                  color: '#999999'
                }}>
                  Sort By
                </Text>
                <Dropdown drop="right" className={styles.dropDate} >
                  <Dropdown.Toggle
                    className={styles.customToggle}
                    style={{
                      borderColor: 'unset',
                      backgroundColor: 'unset',
                      boxShadow: 'none',
                      padding: '0px 5px',
                      marginRight: '5px',
                      color: 'black',
                      border: 'none',
                      fontSize: '13px',
                      textTransform: 'capitalize',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    Applied Date
                    <SvgArrowUp
                      width={10}
                      height={10}
                      strokeWidth={25}
                      viewBox="0 0 380 380"
                      style={{
                        transform: 'scaleY(-1)',
                        rotate: '90deg',
                        transition: 'transform 0.2s ease',
                        cursor: 'pointer',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={styles.rightbox}>
                    <Dropdown.Item onClick={() => hanldeDateSort(index)}>
                      <Text
                      >
                        {'Applied Date (Newest First)'}
                      </Text>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => hanldeDatebackwordSort(index)} >
                      <Text
                      >
                        {'Applied Date (Oldest First)'}
                      </Text>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown drop="right" className={styles.dropDate} >
                  <Dropdown.Toggle
                    className={styles.customToggle}
                    style={{
                      borderColor: 'unset',
                      backgroundColor: 'unset',
                      boxShadow: 'none',
                      padding: '0px 5px',
                      marginRight: '5px',
                      color: 'black',
                      border: 'none',
                      fontSize: '13px',
                      textTransform: 'capitalize',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    Name
                    <SvgArrowUp
                      width={10}
                      height={10}
                      strokeWidth={25}
                      viewBox="0 0 380 380"
                      style={{
                        transform: 'scaleY(-1)',
                        rotate: '90deg',
                        transition: 'transform 0.2s ease',
                        cursor: 'pointer',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={styles.rightbox}>
                    <Dropdown.Item onClick={() => hanldeNameSort(index)}>
                      <Text
                      >
                        {'Name (A-Z)'}
                      </Text>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => hanldeNamebackwordSort(index)}>
                      <Text
                      >
                        {' Name (Z-A)'}
                      </Text>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown drop="right" className={styles.dropDate} >
                  <Dropdown.Toggle
                    className={styles.customToggle}
                    style={{
                      borderColor: 'unset',
                      backgroundColor: 'unset',
                      boxShadow: 'none',
                      padding: '0px 5px',
                      marginRight: '5px',
                      color: 'black',
                      border: 'none',
                      fontSize: '13px',
                      textTransform: 'capitalize',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    Match Score
                    <SvgArrowUp
                      width={10}
                      height={10}
                      strokeWidth={25}
                      viewBox="0 0 380 380"
                      style={{
                        transform: 'scaleY(-1)',
                        rotate: '90deg',
                        transition: 'transform 0.2s ease',
                        cursor: 'pointer',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={styles.rightbox}>
                    <Dropdown.Item onClick={() => hanldeMatchSort(index)}>
                      <Text
                      >
                        {'Match Score (High - Low)'}
                      </Text>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => hanldeMatchbackwordSort(index)}>
                      <Text
                      >
                        {'Match Score (Low - High)'}
                      </Text>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* {column.stage_name !== "New Applicants" &&
                  <Dropdown drop="right" className={styles.dropDate} >
                    <Dropdown.Toggle
                      className={styles.customToggle}
                      style={{
                        borderColor: 'unset',
                        backgroundColor: 'unset',
                        boxShadow: 'none',
                        padding: '0px 5px',
                        marginRight: '5px',
                        color: 'black',
                        border: 'none',
                        fontSize: '13px',
                        textTransform: 'capitalize',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      Meeting Scheduled
                      <SvgArrowUp
                        width={10}
                        height={10}
                        strokeWidth={25}
                        viewBox="0 0 380 380"
                        style={{
                          transform: 'scaleY(-1)',
                          rotate: '90deg',
                          transition: 'transform 0.2s ease',
                          cursor: 'pointer',
                        }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={styles.rightbox}>
                      <Dropdown.Item onClick={() => hanldeMeetingScheduled(index)} >
                        <Text
                        >
                          {'Meeting Scheduled'}
                        </Text>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => hanldeMeetingNotScheduled(index)}>
                        <Text
                        >
                          {'Meeting Not Scheduled'}
                        </Text>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>} */}
                {/* {column.stage_name !== "New Applicants" &&
                  <Dropdown drop="right" className={styles.dropDate} >
                    <Dropdown.Toggle
                      className={styles.customToggle}
                      style={{
                        borderColor: 'unset',
                        backgroundColor: 'unset',
                        boxShadow: 'none',
                        padding: '0px 5px',
                        marginRight: '5px',
                        color: 'black',
                        border: 'none',
                        fontSize: '13px',
                        textTransform: 'capitalize',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      Status
                      <SvgArrowUp
                        width={10}
                        height={10}
                        strokeWidth={25}
                        viewBox="0 0 380 380"
                        style={{
                          transform: 'scaleY(-1)',
                          rotate: '90deg',
                          transition: 'transform 0.2s ease',
                          cursor: 'pointer',
                        }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={styles.rightbox}>
                      {transformedData.map((val, i) => {
                        return (
                          <Dropdown.Item onClick={() => hanldestaging(i, val)} key={i}>
                            <Text>
                              {val.label}
                            </Text>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>} */}
              </>
            )}

          </Flex>
        )}

      </div>}
    </div>
  );
};

export default DndTitleMap;