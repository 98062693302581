import { useEffect, useState } from 'react';
import DatePicker from "react-multi-date-picker"
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Flex,
  InputRadio,
  InputSwitch,
  Loader,
  Text,
} from '../../../uikit';
import { getDateString, uniqueArray } from '../../../uikit/helper';
import { AppDispatch, RootState } from '../../../store';
import { dashboardCalenderMiddleWare } from '../../dashboardmodule/empdashboard/store/dashboardmiddleware';
import { SvgCalendar } from '../../../icons';
import { calendarRoute } from '../../../appRoutesPath';
import {
  EVENT_FILTER_OPTION,
  EVENT_TYPE,
  EventFilterOption,
  ICalendarEvent,
  ICalendarEventTableItem,
  IEvent,
  IEventData,
  IEventOrganiser,
  IEventTableItem,
  IEventTeamMember,
} from '../types';
import { TeamMemberType } from '../../calendarModule/types';
import { getUsersByCompanyMiddleware } from '../../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
import EventsMenu from '../components/eventsMenu';
import EventDeletePopUpModal from '../components/deleteEvent';
import TableWrapper from '../components/tableWrapper';
import {
  deleteGoogleEventMiddleware,
  deleteOutlookEventMiddleware,
} from '../../calendarModule/store/middleware/calendarmiddleware';
import {
  deleteCalendarEventMiddleWare,
  getCalendarEventsMiddleWare,
} from './store/middleware/eventsmiddleware';
import styles from './calendarEvents.module.css';
import CalendarEventsTable from './calendarEventsTable';

const CalendarEventsPage = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const [event, setEvent] = useState([
    { title: '', start: '', end: '', web_url: '' },
  ]);
  const [deleteEvent, setDeleteEvent] = useState<{
    open: boolean;
    event: IEvent | ICalendarEvent;
    type: 'event' | 'calendar';
    eventid: any;
  } | null>(null);
  const [teamMembers, setTeamMembers] = useState<IEventTeamMember[]>([]);
  const [selectedPeople, setSelectedPeople] = useState<number[]>([]);
  const [filters, setFilters] = useState<{
    type: EVENT_TYPE;
    activeRadio: string;
    isPast: boolean;
  }>({
    type: EVENT_TYPE.MY_EVENTS,
    activeRadio: EVENT_FILTER_OPTION.UPCOMING,
    isPast: false,
  });

  const [showDropDownMenu, setShowDropDownMenu] = useState<boolean>(false);
  const gotoCalander = () => {
    // history.push(calendarRoute, { openScheduleEvent: true });
    const params = new URLSearchParams();
    params.append('action', 'open-scheduler-form');
    const url = `${calendarRoute}?${params}`;
    window.open(url);
  };
  const handleDropDown = () => {
    setShowDropDownMenu((state) => !state);
  };
  const formik = useFormik({
    initialValues: { date: new Date() },
    onSubmit: () => { },
  });

  const { is_plan, scheduleEventState, user, integration } = useSelector(
    ({
      permissionReducers,
      calendarEventsReducers,
      userProfileReducers,
      SubscriptionReducers,
    }: RootState) => ({
      is_plan: permissionReducers.is_plan,
      scheduleEventState: calendarEventsReducers,
      user: userProfileReducers?.user,
      integration: SubscriptionReducers?.integration,
    }),
  );

  // plan based page redirection condition
  useEffect(() => {
    if (!is_plan) {
      sessionStorage.setItem('MyAccountDropdown', '1');
      history.push('/account_setting/subscription');
    }
  });
  const getCurrentDate = (type: any) => {
    if (type === EVENT_FILTER_OPTION.DATE) {
      return getDateString(formik.values.date, 'DD/MM/YYYY');
    }
    return undefined;
  };

  const getTrue = (type: any) => {
    if (type === EVENT_FILTER_OPTION.DATE) {
      return 'False';
    }
    return 'True';
  };

  // setstate for fetch and store the current timeZone from our divece
  const [currentTimeZone, setCurrentTimeZone] = useState(null);

  // Getting the current timeZone from our device
  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setCurrentTimeZone(userTimeZone);
  }, []);

  // useEffect(() => {
  //   dispatch(getUsersByCompanyMiddleware())
  //     .then((res) => {
  //       setTeamMembers(
  //         res.payload.users.map((doc: TeamMemberType) => {
  //           return {
  //             id: doc.userId,
  //             user: doc.firstName,
  //             user__first_name: doc.firstName,
  //             user__last_name: doc.lastName,
  //             full_name: `${doc.firstName} ${doc.lastName}`,
  //             name_id: doc.userId,
  //           };
  //         }),
  //       );
  //     })

  //   dispatch(
  //     getCalendarEventsMiddleWare({
  //       event: filters.type === EVENT_TYPE.MY_EVENTS ? 'True' : 'False',
  //       date: getCurrentDate(filters.activeRadio),
  //       other_user: selectedPeople.length !== 0 ? selectedPeople : undefined,
  //       timeZone: currentTimeZone
  //     }),
  //   ).then((res) => {
  //     setSelectedPeople(
  //       ((res.payload as IEventData).teammembers || [])
  //         .filter((doc) =>
  //           filters.type === EVENT_TYPE.MY_EVENTS
  //             ? doc.user === user.id
  //             : doc.user !== user.id,
  //         )
  //         .map((doc) => doc.user),
  //     );
  //   });
  // }, []);

  useEffect(() => {
    // Fetch users by company
    const getUsers = async () => {
      try {
        const res = await dispatch(getUsersByCompanyMiddleware());
        setTeamMembers(
          res.payload.users.map((doc: TeamMemberType) => ({
            id: doc.userId,
            user: doc.firstName,
            user__first_name: doc.firstName,
            user__last_name: doc.lastName,
            full_name: `${doc.firstName} ${doc.lastName}`,
            name_id: doc.userId,
          })),
        );
      } catch (error) {
        // Handle error if needed
      }
    };

    // Fetch calendar events
    const getCalendarEvents = async () => {
      try {
        const res = await dispatch(
          getCalendarEventsMiddleWare({
            event: filters.type === EVENT_TYPE.MY_EVENTS ? 'True' : 'False',
            date: getCurrentDate(filters.activeRadio),
            other_user:
              selectedPeople.length !== 0 ? selectedPeople : undefined,
            timeZone: currentTimeZone,
          }),
        );
        setSelectedPeople(
          ((res.payload as IEventData).teammembers || [])
            .filter((doc) =>
              filters.type === EVENT_TYPE.MY_EVENTS
                ? doc.user === user.id
                : doc.user !== user.id && selectedPeople.includes(doc.user),
            )
            .map((doc) => doc.user),
        );
      } catch (error) {
        // Handle error if needed
      }
    };

    // Call the functions
    getUsers();
    getCalendarEvents();

    // Cleanup logic
    return () => {
      // Add cleanup logic here if needed
    };
  }, [filters?.activeRadio]);

  useEffect(() => {
    if (selectedPeople?.length > 0) {
      const filterNonUser = selectedPeople.filter(
        (nonuser) => nonuser.toString() !== user.id.toString(),
      );
      if (filterNonUser.length > 0) {
        dispatch(
          getCalendarEventsMiddleWare({
            event:
              filters.type === EVENT_TYPE.MY_EVENTS
                ? getTrue(filters.activeRadio)
                : 'False',
            date: getCurrentDate(filters.activeRadio),
            other_user:
              selectedPeople.length !== 0 ? selectedPeople : undefined,
            timeZone: currentTimeZone,
          }),
        );
        // .then((res) => {
        //   setSelectedPeople(((res.payload as IEventData).teammembers || []).filter(doc => filters.type === EVENT_TYPE.MY_EVENTS ? doc.user === user.id : doc.user !== user.id).map(doc => doc.id))
        // });
      }
    }
  }, [
    filters.type,
    filters.activeRadio,
    filters.isPast,
    selectedPeople.toString(),
  ]);

  const getEventsData = (date?: Date) => {
    if (date) {
      dispatch(
        getCalendarEventsMiddleWare({
          event: filters.type === EVENT_TYPE.MY_EVENTS ? 'True' : 'False',
          date:
            filters.activeRadio === EVENT_FILTER_OPTION.DATE
              ? getDateString(date, 'DD/MM/YYYY')
              : undefined,
          other_user: selectedPeople.length !== 0 ? selectedPeople : undefined,
          timeZone: currentTimeZone,
        }),
      );
    }
    // .then((res) => {
    //   setSelectedPeople(((res.payload as IEventData).teammembers || []).filter(doc => filters.type === EVENT_TYPE.MY_EVENTS ? doc.user === user.id : doc.user !== user.id).map(doc => doc.id))
    // });
  };

  const handleJoinEvent = (doc: IEvent) => {
    window.open(doc.join_url, '_blank');
  };
  // const handleEditEvent = (doc: IEvent) => {
  //   history.push(calendarRoute, { openScheduleEvent: true });
  // };
  const handleDeleteEvent = (id: any) => {
    if (
      integration.google !== null &&
      integration.outlook !== null &&
      deleteEvent !== null
    ) {
      if (integration.google === true) {
        dispatch(
          deleteGoogleEventMiddleware({ eventId: deleteEvent?.eventid }),
        ).then(() => {
          setDeleteEvent(null);
          dispatch(
            getCalendarEventsMiddleWare({
              event: filters.type === EVENT_TYPE.MY_EVENTS ? 'True' : 'False',
              date: getCurrentDate(filters.activeRadio),
              other_user:
                selectedPeople.length !== 0 ? selectedPeople : undefined,
              timeZone: currentTimeZone,
            }),
          );
        });
      }
      if (integration.outlook === true) {
        dispatch(
          deleteOutlookEventMiddleware({ eventId: deleteEvent?.eventid }),
        ).then(() => {
          setDeleteEvent(null);
          dispatch(
            getCalendarEventsMiddleWare({
              event: filters.type === EVENT_TYPE.MY_EVENTS ? 'True' : 'False',
              date: getCurrentDate(filters.activeRadio),
              other_user:
                selectedPeople.length !== 0 ? selectedPeople : undefined,
              timeZone: currentTimeZone,
            }),
          );
        });
      }
    }
    // dispatch(
    //   deleteCalendarEventMiddleWare({
    //     params: { cal_id: id },
    //     props: {
    //       event: filters.type === EVENT_TYPE.MY_EVENTS ? 'True' : 'False',
    //       date:
    //         filters.activeRadio === EVENT_FILTER_OPTION.DATE
    //           ? getCurrentDate(filters.activeRadio)
    //           : undefined,
    //       other_user: selectedPeople.length !== 0 ? selectedPeople : undefined,
    //     },
    //   }),
    // ).then(() => {
    //   setDeleteEvent(null);
    // });
  };

  const handleCalendarJoinEvent = (doc: ICalendarEvent) => {
    window.open(doc.join_url, '_blank');
  };
  const handleCalendarEditEvent = (doc: ICalendarEvent) => {
    history.push(calendarRoute, { recurringEventId: doc.eventId });
  };
  // const handleCalendarDeleteEvent = (doc: ICalendarEvent) => {
  //   dispatch(
  //     deleteEventMiddleWare({
  //       eventid: doc.id,
  //       event: filters.type === EVENT_TYPE.MY_EVENTS ? 'True' : 'False',
  //     }),
  //   );
  // };

  const handlePeopleChange = (value: number) => {
    setSelectedPeople(prev => {
      if (prev.includes(value) && user.id !== value) {
        // If value is already in the array, remove it
        return prev.filter(item => item !== value);
      } else if (user.id !== value) {
        // If value is not in the array, add it
        return [...prev, value];
      }
      else {
        return prev;
      }
    });
  };

  const eventsList = (): ICalendarEventTableItem[] => {
    const organisers = uniqueArray(scheduleEventState.org_name);
    if (filters.activeRadio === EVENT_FILTER_OPTION.DATE) {
      return scheduleEventState?.calevents_events.map((doc) => {
        const emails = ((doc.email as string) || '').split(',');
        return {
          ...doc,
          email: scheduleEventState?.calevents_interviewer.filter((s) =>
            emails.includes(s.email),
          ),
          // interviewers: emails.map(em => scheduleEventState?.calevents_interviewer[em]),
          organisers: scheduleEventState.org_name,
        };
      });
    }
    if (
      filters.activeRadio === EVENT_FILTER_OPTION.UPCOMING ||
      filters.activeRadio === EVENT_FILTER_OPTION.PAST
    ) {
      if (filters.activeRadio === EVENT_FILTER_OPTION.PAST) {
        return scheduleEventState?.calevents_past_event.map((doc) => {
          const emails = ((doc.email as string) || '').split(',');
          return {
            ...doc,
            email: scheduleEventState?.calevents_interviewer.filter((s) =>
              emails.includes(s.email || ''),
            ),
            organisers,
          };
        });
      }

      return scheduleEventState?.calevents_upcoming_event.map((doc) => {
        const emails = ((doc.email as string) || '').split(',');
        return {
          ...doc,
          email: scheduleEventState?.calevents_interviewer.filter((s) =>
            emails.includes(s.email || ''),
          ),
          organisers,
        };
      });
    }

    return [];
  };

  const renderTable = () => {
    if (scheduleEventState?.isLoading) {
      return <Loader />;
    }
    return (
      <CalendarEventsTable
        list={eventsList()}
        pastEvents={filters.isPast}
        activeRadio={filters.activeRadio}
        deleteState={scheduleEventState?.deleteState}
        onJoin={handleCalendarJoinEvent}
        onEdit={handleCalendarEditEvent}
        onDelete={(doc) =>
          setDeleteEvent({
            open: true,
            event: doc,
            type: 'calendar',
            eventid: doc?.eventId,
          })
        }
        integration={integration}
      />
    );
  };

  const pastUpcoming = (
    <Flex row center>
      <Text
        size={13}
        className={styles.textStyles}
        style={{ marginRight: '10px' }}
      >
        Upcoming Events
      </Text>
      <InputRadio
        checked={filters.activeRadio === EVENT_FILTER_OPTION.PAST}
        onClick={() =>
          // setFilters((past) => ({ ...past, isPast: !past.isPast }))
          setFilters({
            type: EVENT_TYPE.MY_EVENTS,
            activeRadio: EVENT_FILTER_OPTION.UPCOMING,
            isPast: false,
          })
        }
      />
      <Text
        size={13}
        className={styles.textStyles}
        style={{ marginLeft: '10px' }}
      >
        Past Events
      </Text>
    </Flex>
  );

  // css for adjusting the padding of time view.
  const datePickerStyles = {
    padding: "2px 5px 2px 35px"
  };

  const eventDate = (
    <Flex marginLeft={10}>
      <div style={{ position: 'relative', display: 'flex' }} className={styles.datepick}>
        <DatePicker
          id="calendar___open"
          format="DD/MM/YYYY"
          value={getDateString(formik.values.date, 'DD/MM/YYYY')}
          onChange={(date) => {
            formik.setFieldValue('date', date.format('MM/DD/YYYY'));
            getEventsData(date.toDate());
          }}
          className={styles.datePicker}
          style={datePickerStyles}
        />
        <div style={{ position: 'absolute', left: 7, top: 5 }}>
          <label htmlFor="calendar___open">
            <SvgCalendar width={16} height={16} />
          </label>
        </div>
      </div>
    </Flex>
  );
  return (
    <>
      {deleteEvent && (
        <EventDeletePopUpModal
          {...deleteEvent}
          onClose={() => setDeleteEvent(null)}
          onConfirm={handleDeleteEvent}
        />
      )}
      <Flex center between row className={styles.Container}>
        <Flex row center>
          {EventFilterOption &&
            EventFilterOption.map((item, index) => {
              return (
                <Flex marginRight={10} key={index}>
                  <InputRadio
                    label={item.id === '3' ? eventDate : item.label}
                    checked={filters.activeRadio === item.value}
                    onClick={() => {
                      if (item.id !== '3') {
                        formik.setFieldValue('date', new Date());
                      }
                      // setFilters((past) => ({
                      //   ...past,
                      //   activeRadio: item.value,
                      // }));
                      setFilters({
                        type: EVENT_TYPE.MY_EVENTS,
                        activeRadio: item.value,
                        isPast: false,
                      });
                      // setActiveFilter(EVENT_FILTER_OPTION.PAST_AND_UPCOMING);
                      // getData(EVENT_FILTER_OPTION.PAST_AND_UPCOMING);
                    }}
                  />
                </Flex>
              );
            })}

          {/* <Flex marginRight={10}>
            
            <InputRadio
              label={pastUpcoming}
              checked={
                filters.activeRadio === EVENT_FILTER_OPTION.PAST_AND_UPCOMING
              }
              onClick={() => {
                setFilters((past) => ({
                  ...past,
                  activeRadio: EVENT_FILTER_OPTION.PAST_AND_UPCOMING,
                }));
                // setActiveFilter(EVENT_FILTER_OPTION.PAST_AND_UPCOMING);
                // getData(EVENT_FILTER_OPTION.PAST_AND_UPCOMING);
              }}
            />
          </Flex>
          <Flex>
            <InputRadio
              label={eventDate}
              checked={filters.activeRadio === EVENT_FILTER_OPTION.DATE}
              onClick={() => {
                setFilters((past) => ({
                  ...past,
                  activeRadio: EVENT_FILTER_OPTION.DATE,
                  isPast: false,
                }));
                // setPastEvents(false);
                // setActiveFilter(EVENT_FILTER_OPTION.DATE);
                // getData(EVENT_FILTER_OPTION.DATE);
              }}
            />
          </Flex> */}
        </Flex>

        <Flex row>
          <EventsMenu
            showDropDownMenu={showDropDownMenu}
            eventType={filters.type}
            onEventType={(v: EVENT_TYPE) => {
              setFilters((past) => ({
                ...past,
                type: v,
              }));
            }}
            handleDropDown={handleDropDown}
            onPeopleChange={handlePeopleChange}
            selectedPeople={selectedPeople}
            teamMembers={scheduleEventState.teammembers}
            currentUser={user}
          />

          {/* <Button className={styles.scheduleButton} onClick={gotoCalander}>
            Schedule Events
          </Button> */}
        </Flex>
      </Flex>
      <Flex
        style={{
          padding: '10px 16px',
          height: window.innerHeight - 200,
          overflow: 'auto',
        }}
      >
        {renderTable()}
      </Flex>
    </>
  );
};

export default CalendarEventsPage;
