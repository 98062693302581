import axios from 'axios';
import classNames from 'classnames/bind';
import moment from 'moment';
import { createRef, useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SvgBell from '../../../icons/SvgBell';
import SvgClose from '../../../icons/SvgClose';
import { notificationApi } from '../../../routes/apiRoutes';
import { AppDispatch, RootState } from '../../../store';
import Modal from '../../../uikit/Modal/Modal';
import Button from '../../../uikit/Button/Button';
import Card from '../../../uikit/Card/Card';
import Flex from '../../../uikit/Flex/Flex';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import CustomTooltip from '../../../uikit/Tooltip/tooltip';
import Text from '../../../uikit/Text/Text';
import Toast from '../../../uikit/Toast/Toast';
import ProfileView from '../../applicantpipelinemodule/ProfileView';
import styles from '../empnavbar/notification.module.css';
import { notificationDeleteMiddleWare, notificationPostMiddleWare } from '../empnavbar/store/navbarmiddleware';
import { OthersEntity } from '../empnavbar/navbarTypes';



const cx = classNames.bind(styles);
type Props = {
    setjd_id: (val: any) => void;
    setnotificationdata: (val: any) => void;
}
const CandidateNotification = ({ setjd_id, setnotificationdata }: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [isData, setData] = useState<any>();
    const myRef = createRef<any>();
    const [isJd, setJd] = useState('0');
    const [isCandi, setCandi] = useState();
    const [isProfile, setProfile] = useState(false);
    const [modelopen, setmodelopen] = useState(false);
    const [check, setcheck] = useState(false);
    const [isMessageTab, setMessageTab] = useState(1);
    const dropDownRef = useRef(null);
    const history = useHistory();

    const {
        permission,
        super_user,
        roles,
        career_page_setting,
        jd_form,
        merged_results,
        jd_listing
    } = useSelector(
        ({ permissionReducers, careerViewPageReducers, dashboardReducers }: RootState) => {
            return {
                permission: permissionReducers.Permission,
                super_user: permissionReducers.super_user,
                roles: permissionReducers.roles,
                career_page_setting: dashboardReducers.setting,
                merged_results: dashboardReducers.merged_results,
                jd_form: careerViewPageReducers.jd_form,
                jd_listing: dashboardReducers.jd_listing
            };
        },
    );

    // Calling notification API to fetch the notification details.
    useEffect(() => {
        axios.get(notificationApi).then((res) => {
            setData(res.data);
        });
    }, []);
    // Calling notification API to fetch the notification details.
    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(notificationApi).then((res) => {
                setData(res.data);
            });
        }, 4500);
        return () => clearInterval(interval);
    }, []);

    // Function for closing the notification modal when click outside of the notification modal.
    const handleClickOutside = (event: { target: any }) => {
        if (myRef.current && !myRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    // useEffect for closing the notification modal when click outside of the notification modal.
    useEffect(() => {
        if (typeof Window !== 'undefined') {
            document.addEventListener('click', handleClickOutside, true);
        }
        return () => {
            if (myRef) {
                if (typeof Window !== 'undefined') {
                    document.removeEventListener('click', handleClickOutside, true);
                }
            }
        };
    });

    // notification delete function
    const handleDelete = () => {
        dispatch(notificationDeleteMiddleWare()).then(() => {
            axios.get(notificationApi).then((res) => {
                setData(res.data);
                setmodelopen(false);
                Toast('Notifications cleared successfully');
            });
        });
        setcheck(true);
        setmodelopen(false);
        setOpen(false);
    };

    // notification read function
    const handleReadNotification = (id: number) => {
        dispatch(notificationPostMiddleWare({ id })).then(() => {
            axios.get(notificationApi).then((res) => {
                setData(res.data);
            });
        });
        setOpen(false);
    };
    const handlecleartab = () => {
        sessionStorage.removeItem('superUserTab');
        sessionStorage.removeItem('superUserFalseTab');
        sessionStorage.removeItem('superUserTabTwo');
    }
    const myfunction = () => {
        {
            setOpen(true);
        }
    };

    // function for close the notification popup 
    const handleclose = () => {
        setOpen(false);
    };

    const godatabase = (list) => {
        setjd_id(list.action_object_object_id)
    }

    //description for all notification in toLowerCase.
    // -->newapplicant
    // -->invitetoapply
    // -->candidatemessage
    // -->inactive
    // -->stages

    // function for handle click when click the notification.
    const handlenotificationclick = (data?: any) => {
        setnotificationdata(data)
        handleReadNotification(data?.id);
        godatabase(data);
        handlecleartab();
    }

    return (
        <Flex>
            <div ref={myRef} style={{ position: 'relative' }}>
                <ProfileView
                    open={isProfile}
                    cancel={() => {
                        setProfile(false);
                    }}
                    jobId={isJd}
                    candidateId={isCandi}
                    inviteIconNone
                    activeState={isMessageTab}
                    applicantpipeline={false}
                />
                <CustomTooltip title="Notifications">
                    <div
                        onClick={() => setOpen(!isOpen)}
                        tabIndex={-1}
                        onKeyDown={() => { }}
                        role="button"
                        className={styles.hoverback}
                        style={{ position: 'relative', padding: ' 5px' }}
                    >
                        {isData && isData?.total_unread !== 0 && (
                            <div className={styles.countStyle}>
                                <Text color="white" style={{ fontSize: 10, marginTop: '2px' }}>
                                    {isData && isData.total_unread}
                                </Text>
                            </div>
                        )}
                        <SvgBell fill={'#ffffff'} height={20} width={20} />
                    </div>
                </CustomTooltip>
                {isOpen && (
                    <Card
                        className={
                            isData && isData.total !== 0 ? styles.overAll : styles.overAllOne
                        }
                    >
                        {isData && isData?.total !== 0 ? (
                            <>
                                <Flex row center between className={styles.headerStyle}>
                                    <Text color="theme" style={{ fontSize: '14px' }} bold>
                                        Notifications
                                    </Text>
                                    <Flex onClick={handleclose} style={{ cursor: 'pointer' }}>
                                        <SvgClose width={10} height={10} fill={'#888888'} />
                                    </Flex>
                                </Flex>
                                <hr className={styles.line} />
                                <Flex columnFlex className={styles.scrollStyle}>
                                    {isData && isData?.today && isData?.today?.length !== 0 && (
                                        <Flex columnFlex className={cx('borderBottom')}>
                                            {isData &&
                                                isData?.today?.map(
                                                    (list: OthersEntity, index: number) => {
                                                        let getPath; 
                                                        if (list?.description?.toLowerCase() === 'invitetoapply' || list?.description?.toLowerCase() === 'newapplicant' || list?.description?.toLowerCase() === 'newjob') {
                                                            const formattedJobTitle = jd_listing && jd_listing?.find(e => Number(e?.id) === Number(list?.action_object_object_id))?.job_title
                                                            getPath = `/${career_page_setting.career_page_url}/career_job_view/${list?.action_object_object_id}/${formattedJobTitle}`;
                                                        }
                                                        return (
                                                            <>
                                                                <Flex key={index + list?.id}>
                                                                    {index === 0 && (
                                                                        <Text
                                                                            style={{
                                                                                padding: '8px 16px 0px 16px',
                                                                                fontWeight: 600,
                                                                                fontSize: '13px',
                                                                            }}
                                                                            bold
                                                                        >
                                                                            TODAY
                                                                        </Text>
                                                                    )}
                                                                    {list?.description?.toLowerCase() !== "null" && list?.description?.toLowerCase() !== "" && list?.description !== null && list?.description !== undefined && (
                                                                        <>
                                                                            {' '}
                                                                            {index === 0 ? (
                                                                                ''
                                                                            ) : (
                                                                                <hr className={styles.hr_line} />
                                                                            )}
                                                                            <LinkWrapper
                                                                                to={getPath}
                                                                                onClick={() => {
                                                                                    handlenotificationclick(list)
                                                                                }}
                                                                            >
                                                                                <Flex
                                                                                    row
                                                                                    center
                                                                                    between
                                                                                    className={styles.hoverStyle}
                                                                                >
                                                                                    <Flex className={styles.wordContainer}>
                                                                                        <Text
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            {list?.verb}
                                                                                        </Text>
                                                                                    </Flex>
                                                                                    <Flex row>
                                                                                        <Flex end>
                                                                                            <Text
                                                                                                style={{
                                                                                                    marginLeft: 3,
                                                                                                    fontSize: '13px',
                                                                                                    display: 'flex',
                                                                                                }}
                                                                                                color="gray"
                                                                                            >
                                                                                                {moment(list?.timestamp).fromNow()}
                                                                                            </Text>
                                                                                        </Flex>
                                                                                        <Flex width={15}>
                                                                                            {list?.unread && (
                                                                                                <div
                                                                                                    className={styles.readStyle}
                                                                                                />
                                                                                            )}
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                </Flex>
                                                                            </LinkWrapper>{' '}
                                                                        </>
                                                                    )}
                                                                </Flex>
                                                            </>
                                                        );
                                                    },
                                                )}
                                        </Flex>
                                    )}
                                    {isData &&
                                        isData?.yesterday &&
                                        isData?.yesterday?.length !== 0 && (
                                            <Flex columnFlex className={cx('borderBottom')}>
                                                {isData &&
                                                    isData?.yesterday?.map(
                                                        (list: OthersEntity, index: number) => {
                                                            let getPath;
                                                            if (list?.description?.toLowerCase() === 'invitetoapply' || list?.description?.toLowerCase() === 'newapplicant'|| list?.description?.toLowerCase() === 'newjob') {
                                                                const formattedJobTitle = jd_listing && jd_listing?.find(e => Number(e?.jd_id_id) === Number(list?.action_object_object_id))?.job_title
                                                                getPath = `/${career_page_setting.career_page_url}/career_job_view/${list?.action_object_object_id}/${formattedJobTitle}`;
                                                            }
                                                            return (
                                                                <Flex key={index + list.id}>
                                                                    {index === 0 && (
                                                                        <Text
                                                                            style={{
                                                                                padding: '16px 16px 0px 16px',
                                                                                fontWeight: 600,
                                                                                fontSize: '13px',
                                                                            }}
                                                                            bold
                                                                        >
                                                                            YESTERDAY
                                                                        </Text>
                                                                    )}
                                                                    {list?.description?.toLowerCase() !== "null" && list?.description?.toLowerCase() !== "" && list?.description !== null && list?.description !== undefined && (
                                                                        <>
                                                                            {' '}
                                                                            {index === 0 ? (
                                                                                ''
                                                                            ) : (
                                                                                <hr className={styles.hr_line} />
                                                                            )}
                                                                            <LinkWrapper
                                                                                to={getPath}
                                                                                onClick={() => {
                                                                                    handlenotificationclick(list)
                                                                                }}
                                                                            >
                                                                                <Flex
                                                                                    row
                                                                                    center
                                                                                    between
                                                                                    className={styles.hoverStyle}
                                                                                >
                                                                                    <Flex className={styles.wordContainer}>
                                                                                        <Text
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            {list?.verb}
                                                                                        </Text>
                                                                                    </Flex>
                                                                                    <Flex row>
                                                                                        <Flex end>
                                                                                            <Text
                                                                                                style={{
                                                                                                    marginLeft: 3,
                                                                                                    fontSize: '13px',
                                                                                                    display: 'flex',
                                                                                                }}
                                                                                                color="gray"
                                                                                            >
                                                                                                {moment(list?.timestamp).fromNow()}
                                                                                            </Text>
                                                                                        </Flex>
                                                                                        <Flex width={15}>
                                                                                            {list?.unread && (
                                                                                                <div
                                                                                                    className={styles.readStyle}
                                                                                                />
                                                                                            )}
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                </Flex>
                                                                            </LinkWrapper>{' '}
                                                                        </>
                                                                    )}
                                                                </Flex>
                                                            );
                                                        },
                                                    )}
                                            </Flex>
                                        )}
                                    {isData && isData.others && isData.others.length !== 0 && (
                                        <Flex columnFlex>
                                            {isData &&
                                                isData.others.map(
                                                    (list: OthersEntity, index: number) => {
                                                        let getPath;
                                                        if (list?.description?.toLowerCase() === 'invitetoapply' || list?.description?.toLowerCase() === 'newapplicant'|| list?.description?.toLowerCase() === 'newjob') {
                                                            const formattedJobTitle = jd_listing && jd_listing?.find(e => Number(e?.jd_id_id) === Number(list?.action_object_object_id))?.job_title
                                                            getPath = `/${career_page_setting.career_page_url}/career_job_view/${list?.action_object_object_id}/${formattedJobTitle}`;
                                                        }
                                                        return (
                                                            <Flex key={index + list.id}>
                                                                {index === 0 && (
                                                                    <Text
                                                                        style={{
                                                                            padding: '16px 16px 0px 16px',
                                                                            fontWeight: 600,
                                                                            fontSize: '13px',
                                                                        }}
                                                                        bold
                                                                    >
                                                                        OLDER
                                                                    </Text>
                                                                )}
                                                                {list?.description?.toLowerCase() !== "null" && list?.description?.toLowerCase() !== "" && list?.description !== null && list?.description !== undefined && (
                                                                    <>
                                                                        {' '}
                                                                        {index === 0 ? (
                                                                            ''
                                                                        ) : (
                                                                            <hr className={styles.hr_line} />
                                                                        )}
                                                                        <LinkWrapper
                                                                            to={getPath}
                                                                            onClick={() => {
                                                                                handlenotificationclick(list)
                                                                            }}
                                                                        >
                                                                            <Flex
                                                                                row
                                                                                center
                                                                                between
                                                                                className={styles.hoverStyle}
                                                                            >
                                                                                <Flex className={styles.wordContainer}>
                                                                                    <Text
                                                                                        style={{
                                                                                            fontSize: '13px',
                                                                                        }}
                                                                                    >
                                                                                        {list?.verb}
                                                                                    </Text>
                                                                                </Flex>
                                                                                <Flex row>
                                                                                    <Flex end>
                                                                                        <Text
                                                                                            style={{
                                                                                                marginLeft: 3,
                                                                                                fontSize: '13px',
                                                                                                display: 'flex',
                                                                                            }}
                                                                                            color="gray"
                                                                                        >
                                                                                            {moment(list?.timestamp).fromNow()}
                                                                                        </Text>
                                                                                    </Flex>
                                                                                    <Flex width={15}>
                                                                                        {list?.unread && (
                                                                                            <div
                                                                                                className={styles.readStyle}
                                                                                            />
                                                                                        )}
                                                                                    </Flex>
                                                                                </Flex>
                                                                            </Flex>
                                                                        </LinkWrapper>{' '}
                                                                    </>
                                                                )}
                                                            </Flex>
                                                        );
                                                    },
                                                )}
                                        </Flex>
                                    )}
                                </Flex>
                                <hr className={styles.hr_lineforbutton} />
                                <div>
                                    <Button
                                        className={styles.model_open}
                                        onClick={() => setmodelopen(true)}
                                    >
                                        Clear All
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Flex>
                                <Flex row center between className={styles.headerStyle}>
                                    <Text color="theme" style={{ fontSize: '14px' }} bold>
                                        Notifications
                                    </Text>
                                    <Flex onClick={handleclose} style={{ cursor: 'pointer' }}>
                                        <SvgClose width={10} height={10} fill={'#888888'} />
                                    </Flex>
                                </Flex>
                                <hr className={styles.line} />

                                <Flex style={{ textAlign: 'center' }}>
                                    <Flex
                                        style={{
                                            position: 'absolute',
                                            top: '47%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    >
                                        <SvgBell fill={'#888888'} height={16} width={16} />
                                    </Flex>

                                    <Text
                                        size={13}
                                        style={{
                                            position: 'absolute',
                                            top: '47%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            marginTop: '7px',
                                            color: '#888888',
                                        }}
                                    >
                                        <br />
                                        You don’t have any notification.
                                    </Text>
                                </Flex>
                            </Flex>
                        )}
                    </Card>
                )}
            </div>

            <Flex ref={dropDownRef} onClick={myfunction}>
                <Modal open={modelopen} data-bs-backdrop="static">
                    <Flex className={styles.model}>
                        <Flex className={styles.confirm_title}>
                            This action will clear all the notifications.
                        </Flex>
                        <Flex className={styles.confirm_txt}>Are you sure to proceed?</Flex>
                        <Flex row end style={{ marginTop: '10px' }}>
                            <Flex>
                                <Button
                                    style={{ backgroundColor: '#888888', borderColor: '#888888' }}
                                    onClick={() => setmodelopen(false)}
                                >
                                    Cancel
                                </Button>
                            </Flex>
                            <Flex>
                                <Button
                                    className={styles.Btn_clear}
                                    onClick={handleDelete}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Clear
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Modal>
            </Flex>
        </Flex>
    );
};

export default CandidateNotification;
