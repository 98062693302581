import * as React from 'react';
const SvgPasswordlock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={11}
    fill="none"
    viewBox='0 0 9 11'
    {...props}
  >
    <path
      fill="#581845"
      d="M4.132 5.681a.517.517 0 0 0-.516.517v1.55a.517.517 0 0 0 1.033 0v-1.55a.517.517 0 0 0-.517-.517Zm2.583-2.066V2.583a2.582 2.582 0 1 0-5.165 0v1.034A1.55 1.55 0 0 0 0 5.165V8.78a1.55 1.55 0 0 0 1.55 1.55h5.165a1.55 1.55 0 0 0 1.549-1.55V5.165a1.55 1.55 0 0 0-1.55-1.55ZM2.583 2.583a1.55 1.55 0 0 1 3.098 0v1.034H2.582V2.582ZM7.23 8.781a.517.517 0 0 1-.516.516H1.55a.517.517 0 0 1-.517-.517V5.165a.517.517 0 0 1 .517-.516h5.165a.517.517 0 0 1 .516.516V8.78Z"
    />
  </svg>
);
export default SvgPasswordlock;