export const profileUserList = [
  {
    name: 'USA, Hawaii',
  },
  {
    name: '9994886594',
  },
  {
    name: 'USA, Hawaii',
  },
  {
    name: 'USA, Hawaii',
  },
];

export const hireList = [
  { value: 3.0, label: 'YES', paddingleft: 50 },
  { value: 2.0, label: 'Neutral', paddingleft: 50 },
  { value: 1.0, label: 'NO', paddingleft: 50 },
];

export const  Typeofinterviewquestion = [
  { value: '1', label: 'General' },
  { value: '2', label: 'IQ Based' },
  { value: '3', label: 'Technical' },
  { value: '4', label: 'Coding' },
  { value: '5', label: 'Behavioral' },
];
export const  Difficultylevel = [
  { value: '1', label: 'Easy' },
  { value: '2', label: 'Medium' },
  { value: '3', label: 'Hard' },
  
];
export const level = [
  { value: 'General', label: 'General',  paddingleft:50},
  { value: 'IQ Based', label: 'IQ Based', paddingleft:50 },
  { value: 'Technical', label: 'Technical',  paddingleft:50 },
  { value: 'Coding', label: 'Coding',  paddingleft:50 },
  { value: 'Behavioral', label: 'Behavioral',  paddingleft:50 },

];
 
export const DummyinterviewData = [
  {
    date: 'Oct 10, 2023',
    time: '12:00 am - 1:00 am', 
    questions: [
      {
        difficulty: 'Easy',
        questionNumber: 1,
        questionText: 'How are you manoj , abinesh and sivaprasath?',
        showAnswer: false
      },
      {
        difficulty: 'Hard',
        questionNumber: 2,
        questionText: 'What were the challenges and how did you overcome them?',
        showAnswer: false
      },
      // Add more questions as needed
    ]
  },
  {
    date: 'Oct 10, 2023',
    time: '12:00 am - 1:00 am', 
    questions: [
      {
        difficulty: 'Easy',
        questionNumber: 1,
        questionText: 'Describe a situation where you had to work under tight deadlines. How did you manage your time and prioritize tasks?',
        showAnswer: false
      },
      {
        difficulty: 'Medium',
        questionNumber: 1,
        questionText: 'How do you approach debugging and troubleshooting when you encounter a complex issue in your code?',

        showAnswer: false
      }, 
      {
        difficulty: 'Hard',
        questionNumber: 1,
        questionText: 'Can you describe a recent project where you collaborated with a product team to design and develop user-facing features?',

        showAnswer: false
      }, 
    ]
  },
  // Add more interview sets as needed
];