import * as React from 'react';

const defaultProps = {
  fill: '#ffffff',
  // width: 22,
  // height: 22,
};

const SvgOverview = ({ fill }: typeof defaultProps) => {
  return (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill={fill}
    
  >
    <path
      d="M12.333 11.867v-1.534a.32.32 0 0 0-.1-.233A.32.32 0 0 0 12 10a.32.32 0 0 0-.233.1.32.32 0 0 0-.1.233v1.534a.627.627 0 0 0 .2.466l1.016 1.017a.32.32 0 0 0 .467 0 .32.32 0 0 0 0-.467l-1.017-1.016Zm-9 2.133c-.366 0-.68-.13-.942-.392A1.281 1.281 0 0 1 2 12.667V3.333c0-.366.13-.68.392-.942.261-.261.575-.391.941-.391h9.334c.366 0 .68.13.942.392.261.261.391.575.391.941V7.8a5.496 5.496 0 0 0-.65-.259 4.002 4.002 0 0 0-.683-.158v-4.05H3.333v9.334h4.034c.033.244.086.477.158.7.073.222.159.433.258.633h-4.45Zm0-2v.667-9.334 4.05-.05V12Zm1.334-1.333c0 .189.064.347.192.475a.642.642 0 0 0 .474.191h2.05c.034-.233.087-.46.159-.683.072-.222.153-.439.241-.65h-2.45a.646.646 0 0 0-.475.192.642.642 0 0 0-.191.475Zm0-2.667c0 .189.064.347.192.475a.643.643 0 0 0 .474.192h3.4a5.1 5.1 0 0 1 1.192-.834c.44-.222.909-.372 1.408-.45A1.97 1.97 0 0 0 11 7.341a6.513 6.513 0 0 0-.333-.008H5.333a.646.646 0 0 0-.475.192.642.642 0 0 0-.191.475Zm0-2.667c0 .19.064.348.192.476A.642.642 0 0 0 5.333 6h5.334a.645.645 0 0 0 .475-.192.642.642 0 0 0 .191-.475.646.646 0 0 0-.192-.475.642.642 0 0 0-.474-.191H5.333a.646.646 0 0 0-.475.192.642.642 0 0 0-.191.474Zm7.333 10a3.213 3.213 0 0 1-2.359-.975A3.21 3.21 0 0 1 8.667 12c0-.922.325-1.708.975-2.359A3.21 3.21 0 0 1 12 8.667c.922 0 1.708.325 2.359.975.65.65.975 1.436.974 2.358 0 .922-.325 1.708-.975 2.359a3.21 3.21 0 0 1-2.358.974Z"
    />
  </svg>
);
};

SvgOverview.defaultProps = defaultProps;

export default SvgOverview;