
const defaultProps = {
    fill: '#ffffff',
    width: 22,
    height: 22,
  };
  
  const SvgMeetingicon = ({ width, height, fill }: typeof defaultProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M4.18781 3.31603C4.94318 3.31603 5.55556 2.57399 5.55556 1.65845C5.55556 0.742906 4.94318 0 4.18781 0C3.43243 0 2.81958 0.742906 2.81958 1.65845C2.81958 2.57399 3.43535 3.31603 4.18781 3.31603Z" fill="#666666"/>
<path d="M11.8135 3.31603C12.5689 3.31603 13.1808 2.57399 13.1808 1.65845C13.1808 0.742906 12.5689 0 11.8135 0C11.0582 0 10.4453 0.742906 10.4453 1.65845C10.4453 2.57399 11.0572 3.31603 11.8135 3.31603Z" fill="#666666"/>
<path d="M15.4007 1.67578H13.7796C13.7703 2.88378 12.8929 3.86456 11.8136 3.86456C10.7343 3.86456 9.85632 2.88378 9.84756 1.67578H6.15387C6.1456 2.88378 5.26718 3.86456 4.18786 3.86456C3.10855 3.86456 2.23108 2.88378 2.22233 1.67578H0.599683C0.440665 1.6759 0.288251 1.73357 0.175808 1.83616C0.0633654 1.93876 0.000128812 2.07787 0 2.22296V15.4542C0.000128932 15.5992 0.0633514 15.7383 0.175808 15.8408C0.288265 15.9433 0.44071 16.0009 0.599683 16.0009H15.4007C15.4795 16.001 15.5574 15.9869 15.6302 15.9594C15.7029 15.932 15.769 15.8917 15.8246 15.8409C15.8803 15.7901 15.9244 15.7298 15.9545 15.6635C15.9846 15.5971 16.0001 15.526 16 15.4542V2.22342C16.0001 2.15155 15.9847 2.08034 15.9546 2.01391C15.9246 1.94747 15.8805 1.88708 15.8248 1.83622C15.7691 1.78535 15.7031 1.745 15.6303 1.71747C15.5575 1.68994 15.4795 1.67578 15.4007 1.67578ZM14.801 14.907H1.07735V5.57358H14.801V14.907Z" fill="#666666"/>
<path d="M2.35938 6.69922H4.08219V8.27246H2.35938V6.69922Z" fill="#666666"/>
<path d="M5.5459 6.69922H7.2692V8.27246H5.5459V6.69922Z" fill="#666666"/>
<path d="M8.73218 6.69922H10.456V8.27246H8.73218V6.69922Z" fill="#666666"/>
<path d="M11.9182 6.69922H13.6425V8.27246H11.9182V6.69922Z" fill="#666666"/>
<path d="M2.35938 9.4375H4.08219V11.0099H2.35938V9.4375Z" fill="#666666"/>
<path d="M5.5459 9.4375H7.2692V11.0099H5.5459V9.4375Z" fill="#666666"/>
<path d="M8.73218 9.4375H10.456V11.0099H8.73218V9.4375Z" fill="#666666"/>
<path d="M11.9182 9.4375H13.6425V11.0099H11.9182V9.4375Z" fill="#666666"/>
<path d="M2.35938 12.1719H4.08219V13.7447H2.35938V12.1719Z" fill="#666666"/>
<path d="M5.5459 12.1719H7.2692V13.7447H5.5459V12.1719Z" fill="#666666"/>
<path d="M8.73218 12.1719H10.456V13.7447H8.73218V12.1719Z" fill="#666666"/>
<path d="M11.9182 12.1719H13.6425V13.7447H11.9182V12.1719Z" fill="#666666"/>
</g>
<defs> 
<rect width="16" height="16" fill="white"/> 
</defs>
</svg>
    );
  };
  SvgMeetingicon.defaultProps = defaultProps;
  
  export default SvgMeetingicon;
  