import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { TOUR_DATA } from '../../../../actions/actions';
import {tour_data} from '../../../../routes/apiRoutes';
import {Tourdata} from '../../tourTypes'

export const tourdataget = createAsyncThunk(
    TOUR_DATA,
    async (
      { skip_id,rating,notes,restart_status,explore }: Tourdata,
      { rejectWithValue },
    ) => {
      try {
        const { data } = await axios.get(tour_data, {
          params: {skip_id,rating,notes,restart_status,explore},
        });
        return data;
      } catch (error) {
        const typedError = error as Error;
        return rejectWithValue(typedError);
      }
    },
  );
  