import { useEffect, useState } from 'react';
import Card from '../../uikit/Card/Card';
import Flex from '../../uikit/Flex/Flex';
import { isEmpty, lowerCase, toCamelCase } from '../../uikit/helper';
import Status from '../../uikit/Status/Status';
import Text from '../../uikit/Text/Text';
import {
  Jd,
  LocationEntity,
  Profile,
  QualificationEntity,
  SkillsEntity,
} from './createJdTypes';
import JdDetails from './JdDetails';
import styles from './previewtitle.module.css';
import RolesandResponsibilities from './RolesandResponsibilities';

type Props = {
  jdDetails: Jd | any;
  profile: Profile | any;
  jd_view?: boolean;
  location: LocationEntity | any;
  qualification: QualificationEntity[] | any;
  skills: SkillsEntity[] | any;
  nice_to_have: string
};
const PreviewTitle = ({
  profile,
  jdDetails,
  location,
  jd_view,
  qualification,
  skills,
  nice_to_have,
}: Props) => {
  const [filteredSkills, setFilteredSkills] = useState([]);
  useEffect(() => {
    // Check if skillsString is not null or undefined
    if (nice_to_have !== null && nice_to_have !== undefined) {
      // Split the string by commas
      const skillsArray = nice_to_have.split(',');

      // Filter out empty skill values
      const filteredSkillsArray = skillsArray.filter(skill => skill.trim() !== '');

      // Update the state with the filtered skills array
      setFilteredSkills(filteredSkillsArray);
    }
  }, [nice_to_have]);
  return jd_view ? (
    <Flex >
      <Flex columnFlex>
        <JdDetails
          jdDetails={jdDetails}
          location={location}
          qualification={qualification}
        />
        <Card className={styles.rolesandresponsibilitiescard}>
          {profile && jdDetails.is_ds_role === true ? (
            <RolesandResponsibilities jdDetails={jdDetails} profile={profile} />
          ) : (
            <>
              <td
              style={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                className={styles.des}
                dangerouslySetInnerHTML={{
                  __html: jdDetails.richtext_job_description,

                }}

              />
            </>
          )}

          {skills && (
            <Flex>
              <Text
                bold
                size={14}
                color="primary"
                style={{ paddingTop: 0 }}
              >
                Mandatory Skills
              </Text>
              <Flex row center wrap className={styles.statusContainer}>
                {skills.map((skillList: any, index: number) => {
                  const exp =
                    Number(skillList.experience) === 0
                      ? ''
                      : Number(skillList.experience) === 1
                        ? `- ${skillList.experience} Year`
                        : `- ${skillList.experience} Years`;
                  return (
                    <>
                      {!isEmpty(skillList.skill) && (
                        <div
                          key={index + skillList.skill}
                          className={styles.statusList}
                        >
                          <Status label={lowerCase(skillList.skill) + exp} />
                        </div>
                      )}
                    </>
                  );
                })}
              </Flex>
            </Flex>
          )}
          {filteredSkills && filteredSkills.length > 0 && (
            <Flex>
              <Flex className={styles.borderbottomline}>
                <Text
                  bold
                  size={14}
                  color="primary"
                  style={{ paddingTop: 15 }}
                >
                  Nice to have Skills
                </Text></Flex>
              <Flex row center wrap className={styles.statusContainer}>
                {filteredSkills.map((skillList: any, index: number) => {

                  return (
                    <>
                      {!isEmpty(skillList) && (
                        <div
                          key={index}
                          className={styles.statusList}
                        >
                          <Status label={lowerCase(skillList)} />
                        </div>
                      )}
                    </>
                  );
                })}
              </Flex>
            </Flex>
          )}
        </Card>
      </Flex>
    </Flex>
  ) : (
    <Flex className={styles.cardOverAll}>
      <Flex columnFlex>
        <Text bold size={14} className={styles.title}>
          {toCamelCase(jdDetails.job_title)}
        </Text>
        <JdDetails
          jdDetails={jdDetails}
          location={location}
          qualification={qualification}
        />
        <Card className={styles.rolesandresponsibilitiescard}>
          {profile && jdDetails.is_ds_role === true ? (
            <RolesandResponsibilities jdDetails={jdDetails} profile={profile} />
          ) : (
            <>
              <td
              style={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                className={styles.des}
                dangerouslySetInnerHTML={{
                  __html: jdDetails.richtext_job_description,
                  // __html: jdDetails.reactquill_job_description,
                }}
              />
            </>
          )}

          {skills && (
            <Flex>
              <Flex className={styles.borderbottomline}>
                <Text
                  bold
                  size={14}
                  color="primary"
                  style={{ paddingTop: 15 }}
                >
                  Mandatory Skills
                </Text></Flex>
              <Flex row center wrap className={styles.statusContainer}>
                {skills.map((skillList: any, index: number) => {
                  const exp =
                    Number(skillList.experience) === 0
                      ? ''
                      : Number(skillList.experience) === 1
                        ? ` - ${skillList.experience} Year`
                        : ` - ${skillList.experience} Years`;
                  return (
                    <>
                      {!isEmpty(skillList.skill) && (
                        <div
                          key={index + skillList.skill}
                          className={styles.statusList}
                        >
                          <Status label={lowerCase(skillList.skill) + exp} />
                        </div>
                      )}
                    </>
                  );
                })}
              </Flex>
            </Flex>
          )}
          {filteredSkills && filteredSkills.length > 0 && (
            <Flex>
              <Flex className={styles.borderbottomline}>
                <Text
                  bold
                  size={14}
                  color="primary"
                  style={{ paddingTop: 15 }}
                >
                  Nice to have Skills
                </Text></Flex>
              <Flex row center wrap className={styles.statusContainer}>
                {filteredSkills.map((skillList: any, index: number) => {

                  return (
                    <>
                      {!isEmpty(skillList) && (
                        <div
                          key={index}
                          className={styles.statusList}
                        >
                          <Status label={lowerCase(skillList)} />
                        </div>
                      )}
                    </>
                  );
                })}
              </Flex>
            </Flex>
          )}
        </Card>
      </Flex>
    </Flex>
  );
};

export default PreviewTitle;
