import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import './header.css'
import SvgZitaLogo from "../../../icons/SvgZitaLogo";
import image from '../../../../src/assets/images/theme_zita_logo.png'
import SvgHuman from "../../../icons/SvgHuman";
import { Flex } from "../../../uikit";
import MegaMenu from "./MegaMenu";
import MegaMenuMobile from "./MegaMenuMobile";






const Header = () => {
   
    const isSmallDevice = useMediaQuery({ query: "only screen and (max-width : 768px)" });
  const [isOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
    
  }

  const changeBackground = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-one bg-none  fixed"
            : "theme-main-menu   sticky-menu theme-menu-one bg-none"
        }
       
      >
        <div className="d-flex align-items-center justify-content-between ">
          <div className="d-flex align-items-center justify-content-between makecenternav">
            <div className="logo" >
                <a href="https://www.zita.ai"> 
            <img
            src={image}
            alt="Zita"
            style={{ width: "80px", marginBottom:'5px'}}
          /></a>
            </div>
            {/* End Logo */}
            <nav
              id="mega-menu-holder"
              className="navbar navbar-expand-lg"
              style={{}}
            >
              <div className="container nav-container" >
                <div
                  className="navbar-collapse collapse"
                  id="navbarSupportedContent"
                >
                  <div className="d-lg-flex align-items-center" >
                    <MegaMenu />
                    {/* End MegaMenu */}
                  </div>
                </div>
              </div>
            </nav>

            {/* End nav */}
          </div>
          { isSmallDevice ? (
                <><a href="https://www.app.zita.ai/login" target="_blank" rel="noreferrer">
                    {/* <img
                src={`/images/icon/human.svg`}
                alt="icon"
                
                style={{width:'50px',height:'50px',marginRight:'65px'}}
                className=""
            /> */}
            <Flex   style={{width:'50px',height:'50px',marginRight:'60px'}}>
            <SvgHuman/></Flex>
            </a></>
              ) :(<>
          <div className="d-flex align-items-center justify-content-between">
            <div className="right-button-group d-flex align-items-center justify-content-center">
              <a href="https://www.app.zita.ai/login"  target="_blank" rel="noreferrer">
              <div  className="loginnav" >
                Login
              </div>
              </a>
            </div>
            <div className="right-button-group d-flex align-items-center justify-content-center">
            <a href="https://www.app.zita.ai/recruiter/1"  target="_blank" rel="noreferrer">
              <div  className="signupnav" >
                Get Started
              </div>
              </a>
            </div> 
            </div></>)}

            {/* End right-button-group  */}
         
        </div>

        <MegaMenuMobile />
        {/*     End Mega Menu for Mobile */}
     
      </div>
      {/* /.theme-main-menu */}
    </>
  );
};

export default Header;
