import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import { AppDispatch, RootState } from '../../store';
import SvgAngle from '../../icons/SvgAngle';
import Svgresume from '../../icons/Svgresumeicon';
import Flex from '../../uikit/Flex/Flex';
import { isEmpty, lowerCase, notSpecified } from '../../uikit/helper';
import { workYear } from '../common/commonHelper';
import Status from '../../uikit/Status/Status';
import Text from '../../uikit/Text/Text';
import Tab from '../../uikit/Tabs/Tab';
import Loader from '../../uikit/Loader/Loader';
import Tabs from '../../uikit/Tabs/Tabs';
import Toast from '../../uikit/Toast/Toast';
import styles from './abouttab.module.css';
import ResumeCoverTab from './ResumeCoverTab';
import Resume from './Resume';
import Questionnaire from './Questionnaire';


const AboutTab = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isloader, setloader] = useState(false);
  const [issuccessfalse, setsuccessfalse] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [isblock, setblock] = useState(false);
  const {
    can_id,
    jd_id
  } =
    useSelector(({ applicantProfileInitalReducers }: RootState) => {
      return {
        can_id: applicantProfileInitalReducers.can_id,
        jd_id: applicantProfileInitalReducers?.jd_id,
      };
    });

  const {
    candidate_details,
    total_exp,
    skills,
    personalInfo,
    source,
    create,
    questionnaire,
    Career_Trajectory,
    Achievements,
    Industry_Engagement,
    education,
    behaviour,
    Networking,
    summary,
    Expertise_and_Skills,
    isLoading,
    errorforprofile
  } =
    useSelector(({ applicantProfileInitalReducers, profilesummeryReducers }: RootState) => {
      return {
        source: applicantProfileInitalReducers.source,
        candidate_details: applicantProfileInitalReducers.candidate_details,
        create: applicantProfileInitalReducers.status_id === null || applicantProfileInitalReducers.status_id === undefined ? '' : applicantProfileInitalReducers.status_id[0].created_on === undefined ? '' : applicantProfileInitalReducers.status_id[0].created_on,
        initialLoader: applicantProfileInitalReducers.isLoading,
        questionnaire: applicantProfileInitalReducers.questionnaire,
        total_exp: applicantProfileInitalReducers.total_exp
          ? applicantProfileInitalReducers.total_exp
          : [
            {
              total_exp_year: 0,
              total_exp_month: 0,
            },
          ],
        skills: applicantProfileInitalReducers.skills
          ? applicantProfileInitalReducers.skills
          : [
            {
              id: 0,
              application_id_id: 0,
              soft_skill: '',
              tech_skill: '',
              updated_at: '',
            },
          ],
        personalInfo: applicantProfileInitalReducers.personalInfo
          ? applicantProfileInitalReducers.personalInfo
          : [
            {
              application_id: 0,
              user_id_id: 0,
              firstname: '',
              lastname: '',
              email: '',
              contact_no: 0,
              country_id: 0,
              state_id: 0,
              city_id: 0,
              zipcode: '',
              Date_of_birth: 0,
              linkedin_url: '',
              career_summary: '',
              gender_id: 0,
              updated_at: '',
              code_repo: '',
              visa_sponsorship: false,
              remote_work: false,
              type_of_job_id: 0,
              available_to_start_id: 0,
              industry_type_id: 0,
              curr_gross: '',
              current_currency: '',
              exp_gross: 0,
              salary_negotiable: false,
              current_country_id: 0,
              current_state_id: 0,
              current_city_id: 0,
              current1_country: '',
              current2_country: '',
              current3_country: '',
              relocate: false,
              current_city__name: '',
              current_country__name: '',
              current_state__name: '',
              type_of_job__label_name: '',
              available_to_start__label_name: '',
              industry_type__label_name: '',
              country__name: '',
              city__name: '',
              state__name: '',
            },
          ],
        Career_Trajectory: profilesummeryReducers.Career_Trajectory,
        Achievements: profilesummeryReducers.Achievements,
        Industry_Engagement: profilesummeryReducers.Industry_Engagement,
        education: profilesummeryReducers.education,
        behaviour: profilesummeryReducers.behaviour,
        Networking: profilesummeryReducers.Networking,
        summary: profilesummeryReducers.summary,
        Expertise_and_Skills: profilesummeryReducers.Expertise_and_Skills,
        isLoading: profilesummeryReducers.isLoading,
        errorforprofile: profilesummeryReducers.error
      };
    });

  // setting the loader based on the api responce for profile summary.
  useEffect(() => {
    if (isLoading === true) {
      setloader(true);
    }
    if (isLoading === false) {
      setloader(false);
      setsuccessfalse(false);
      localStorage.setItem('profile_summery', 'false');
    }
  }, [isLoading])

  // function for show more or less in the profile summery.
  const handleToggleShowMore = () => {
    setShowMore(!showMore);
  };

  const totalYear =
    total_exp && total_exp[0].total_exp_year !== 0
      ? total_exp && total_exp[0].total_exp_year > 1
        ? `${total_exp[0].total_exp_year} Years`
        : `${total_exp[0].total_exp_year} Year`
      : '';

  const totalMonths =
    total_exp && total_exp[0].total_exp_month !== 0
      ? total_exp && total_exp[0].total_exp_month > 1
        ? `${total_exp[0].total_exp_month} Months`
        : `${total_exp[0].total_exp_month} Month`
      : '';

  const relocate = isEmpty(personalInfo[0].relocate)
    ? 'Not Specified'
    : personalInfo[0].relocate === true
      ? 'YES'
      : 'NO';
  const remotework = isEmpty(personalInfo[0].remote_work)
    ? 'Not Specified'
    : personalInfo[0].relocate === true
      ? 'YES'
      : 'NO';
  const perAnnumGross = isEmpty(personalInfo[0].curr_gross) ? '' : 'Per Annum';

  const perAnnumExpGross = isEmpty(personalInfo[0].exp_gross)
    ? ''
    : `Per Annum ${personalInfo[0].salary_negotiable === false ? '' : '- Negotiable'
    }`;

  const currentGross =
    notSpecified(personalInfo[0].curr_gross) !== 'Not Specified'
      ? `${getSymbolFromCurrency(personalInfo[0].current_currency)} ${personalInfo[0].curr_gross
      } ${perAnnumGross}`
      : notSpecified(personalInfo[0].curr_gross);

  const expGross =
    notSpecified(personalInfo[0].exp_gross) !== 'Not Specified'
      ? `${getSymbolFromCurrency(personalInfo[0].current_currency)} ${personalInfo[0].exp_gross
      } ${perAnnumExpGross}`
      : notSpecified(personalInfo[0].exp_gross);

  const getFresher =
    total_exp &&
      total_exp[0].total_exp_year === 0 &&
      total_exp &&
      total_exp[0].total_exp_year === 0
      ? true
      : false;

  let currentLocation;
  if (
    isEmpty(personalInfo[0].city__name) &&
    isEmpty(personalInfo[0].state__name) &&
    isEmpty(personalInfo[0].country__name)
  ) {
    currentLocation = `Not Specified`;
  }

  const city__name = isEmpty(personalInfo[0].city__name)
    ? ''
    : `${personalInfo[0].city__name}, `;
  const state__name = isEmpty(personalInfo[0].state__name)
    ? ''
    : `${personalInfo[0].state__name}, `;

  const country__name = isEmpty(personalInfo[0].country__name)
    ? ''
    : `${personalInfo[0].country__name}`;

  let workLocation;
  if (
    isEmpty(personalInfo[0].current_state__name) &&
    isEmpty(personalInfo[0].current_country__name) &&
    isEmpty(personalInfo[0].current_city__name)
  ) {
    workLocation = 'Not Specified';
  }
  let Authorized;
  if (
    isEmpty(personalInfo[0].current1_country) &&
    isEmpty(personalInfo[0].current2_country) &&
    isEmpty(personalInfo[0].current3_country)
  ) {
    Authorized = 'Not Specified';
  }
  const date = isEmpty(create) ? '' : create.slice(0, create.indexOf('T'));
  const aboutData = [
    {
      lable: 'Applied Source:',
      value: notSpecified(source),
    },
    {
      lable: 'Applied Date:',
      value: notSpecified(date),
    },
    {
      lable: 'Contact Number:',
      value: notSpecified(personalInfo[0].contact_no),
    },
    {
      lable: 'Current  Location:',
      value:
        currentLocation === 'Not Specified'
          ? currentLocation
          : `${city__name}${state__name}${country__name}`,
    },
    {
      lable: 'Email ID:',
      value: notSpecified(personalInfo[0].email),
    },
    // {
    //   lable: 'LinkedIn:',
    //   value: notSpecified(personalInfo[0].linkedin_url),
    // },
    // {
    //   lable: 'GitHub:',
    //   value: notSpecified(personalInfo[0].code_repo),
    // },

    {
      lable: 'Experience:',
      value: getFresher ? 'Fresher' : `${totalYear} ${totalMonths}`,
    },
    {
      lable: 'Qualification:',
      value: notSpecified(candidate_details[0].qualification),
    },
  ];

  const aboutData1 = [
    // {
    //   lable: 'Job Type:',
    //   value: notSpecified(personalInfo[0].type_of_job__label_name),
    // },
    // {
    //   lable: 'Availability:',
    //   value: notSpecified(personalInfo[0].available_to_start__label_name),
    // },
    {
      lable: 'Preferred Work Location:',
      value:
        workLocation === 'Not Specified'
          ? 'Not Specified'
          : `${personalInfo[0].current_city__name}, ${personalInfo[0].current_state__name}, ${personalInfo[0].current_country__name}`,
    },
    // {
    //   lable: 'Willing to Relocate:',
    //   value: relocate,
    // },
    {
      lable: 'Remote Availability:',
      value: remotework,
    },
    // {
    //   lable: 'Industry Type:',
    //   value: notSpecified(personalInfo[0].industry_type__label_name),
    // },
    {
      lable: 'Current Gross Salary:',
      value: currentGross,
    },
    // {
    //   lable: 'Expected Gross Salary:',
    //   value: expGross,
    // },
    {
      lable: 'Countries Authorized to Work:',
      value:
        Authorized === 'Not Specified'
          ? 'Not Specified'
          : `${personalInfo[0]?.current1_country}, ${personalInfo[0]?.current2_country}, ${personalInfo[0]?.current3_country}`,
    },
  ];
  const techSkillSplit =
    (candidate_details && candidate_details[0]?.skills?.length === 0) || candidate_details[0]?.skills === null
      ? []
      : candidate_details[0]?.skills?.replace(',,', ',').split(',');
  const softSkillSplit =
    (skills && skills?.length === 0) || skills[0]?.soft_skill === null
      ? []
      : skills[0]?.soft_skill?.replace(',,', ',').split(',');



  return (
    <Flex row>
      <Flex
        flex={6}
        className={styles.overAll}
        height={window.innerHeight - 93}
        style={{ display: 'flex' }}
      >
        {/* <Text  bold className={styles.aboutCandidateStyle}>
          About Candidate
        </Text>
        {aboutData.map((list) => {
          return (
            <Flex key={list.lable} row center  className={styles.flexLineHeight}>
              <Text bold color='theme' className={styles.lableWidth}>
                {list.lable}
              </Text>
              {list.lable === 'Experience:' && list.value === 'Fresher' ? (
                <Text   style={{fontSize:'13px'}}>
                  {notSpecified(workYear(candidate_details[0].work_exp))}
                </Text>
              ) : (
                <Text   style={{fontSize:'13px'}}>{list.value}</Text>
              )}
            </Flex>
          );
        })} */}
        <Flex>
          <Text bold className={styles.jobPreferenceStyles}>
            Job Preferences
          </Text>
          {aboutData1.map((list) => {
            return (
              <Flex
                key={list.lable}
                row
                center
                className={styles.flexLineHeight}
              >
                <Text
                  color="theme"
                  style={{ fontSize: '13px' }}
                  className={styles.lableWidth}
                >
                  {list.lable}
                </Text>
                <Text style={{ fontSize: '13px' }}>{list.value}</Text>
              </Flex>
            );
          })}
        </Flex>
        <Flex> {techSkillSplit[0] !== "" && techSkillSplit.length > 0 && techSkillSplit[0] !== undefined ? (
          <>
            <Text bold className={styles.jobPreferenceStyleproffesional}>
              Professional Skills
            </Text>
            <Flex row center wrap>
              {techSkillSplit &&
                techSkillSplit.map((skilsList, index) => {
                  return (
                    skilsList !== ' ' && (
                      <Flex
                        key={skilsList + index}
                        className={styles.skillStyle}
                        style={{ fontSize: '13px' }}
                      >
                        <Status label={lowerCase(skilsList)} />
                      </Flex>
                    )
                  );
                })}
            </Flex>
          </>
        ) : ''}
        </Flex>
        <Flex>
          {softSkillSplit.length !== 0 && (
            <>
              {softSkillSplit[0] !== '' && (
                <>
                  <Text bold className={styles.softSkillStyle}>
                    Soft Skills
                  </Text>
                  <Flex row center wrap>
                    {softSkillSplit &&
                      softSkillSplit.map((skilsList, index) => {
                        return (
                          skilsList !== ' ' && (
                            <Flex
                              key={skilsList + index}
                              className={styles.skillStyle}
                              style={{ fontSize: '13px', color: '#581845' }}
                            >
                              <Status label={lowerCase(skilsList)} />
                            </Flex>
                          )
                        );
                      })}
                  </Flex>
                </>
              )}
            </>
          )}
        </Flex>
        <Flex>
          {questionnaire && questionnaire.length !== 0 && (
            <>
              <Text bold className={styles.softSkillStyle}>
                Applicant questionnaire
              </Text>
              <Flex>
                <Questionnaire issingletab={false} />
              </Flex>
            </>
          )}
        </Flex>
        <Flex>
          <Text bold className={styles.softSkillStyle}>
            Professional Profile Analysis
          </Text>
          {isloader ?
            <Flex center middle height={100}>
              <Flex center middle><Loader withOutOverlay size={'medium'} /></Flex>
              <Text color='gray'>Profile is being analysed.</Text>
            </Flex>
            : <>
              {issuccessfalse !== null &&
                <>
                  {issuccessfalse ?
                    <Flex height={100} center middle>
                      <Text color='gray'>No information available for this section</Text>
                    </Flex>
                    :
                    <>
                      {summary !== undefined ?
                        <>
                          <Flex className={styles.position_relative} marginBottom={10}>
                            <Flex row center><Text bold>Summary</Text></Flex>
                            <ul>
                              <li style={{ listStyle: 'none', marginLeft: '5px' }}>
                                <Flex style={{ display: 'block' }}>
                                  <Text size={13}>{(summary &&
                                    summary?.length !== 0 ||
                                    summary !== undefined) ?
                                    summary : 'No information available for this section'
                                  }</Text>
                                </Flex>
                              </li>
                              {!showMore &&
                                <Flex center middle marginTop={5}>
                                  <Text onClick={handleToggleShowMore} color='theme' bold style={{ cursor: 'pointer' }}>
                                    {showMore ? (
                                      <span>
                                        {' View less'}
                                        <Text style={{ marginLeft: '4px' }}><SvgAngle width={12} height={12} fill={'#581845'} up={true} /></Text>
                                      </span>
                                    ) : (
                                      <span>
                                        {' View more'}
                                        <Text style={{ marginLeft: '4px' }}><SvgAngle width={12} height={12} fill={'#581845'} up={false} /></Text>
                                      </span>
                                    )}
                                  </Text>
                                </Flex>}
                            </ul>
                          </Flex>
                          {showMore && (
                            <Flex>
                              <Flex row center><div className={styles.myaccountdropdownline}></div> <Text bold>Career Trajectory</Text></Flex>
                              <ul className={styles.borderline}>
                                <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Roles and Responsibilities </Text>
                                    <ul style={{ marginTop: '7px' }}>
                                      {Object.entries(Career_Trajectory['Roles and Responsibilities'])?.map(([skill, proficiency]) => (
                                        <li style={{ marginLeft: '35px', listStyle: 'circle', marginBottom: '10px', fontSize: '13px' }} key={skill}>
                                          {Object.keys(proficiency)?.map((key, index) => (
                                            <div key={index}>
                                              <strong>{key}:</strong> {proficiency[key]}
                                            </div>
                                          ))}
                                        </li>
                                      ))}
                                    </ul>
                                  </Flex>
                                </li>
                                <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Career Progressions and Promotions: </Text>
                                    <Text>{Career_Trajectory['Career Progressions and Promotions'] &&
                                      Career_Trajectory['Career Progressions and Promotions']?.length === 0 ?
                                      Career_Trajectory['Career Progressions and Promotions'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                              </ul>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold>Achievements</Text></Flex>
                              <ul className={styles.borderline}>
                                <li style={{ listStyle: 'disc', marginLeft: '25px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Significant Contributions: </Text>
                                    <Text>{(Achievements['Significant Contributions'] &&
                                      Achievements['Significant Contributions']?.length !== 0 ||
                                      Achievements['Significant Contributions'] !== undefined) ?
                                      Achievements['Significant Contributions'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                                <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Impact: </Text>
                                    <Text>{(Achievements['Impact'] &&
                                      Achievements['Impact']?.length !== 0 ||
                                      Achievements['Impact'] !== undefined) ?
                                      Achievements['Impact'] :
                                      'No information available for this section'}
                                    </Text>
                                  </Flex>
                                </li>
                              </ul>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold>Expertise and Skills</Text></Flex>
                              <Flex className={styles.borderline}>
                                <ul>
                                  {(Expertise_and_Skills['Projects and Achievements Showcasing Skills'] === undefined ||
                                    Expertise_and_Skills['Technical Skills and Proficiency Levels'] === "No information available for this section") ?
                                    <>
                                      <li style={{ listStyle: 'disc', marginLeft: '25px' }}>
                                        <Flex style={{ display: 'block' }}>
                                          <Text bold size={13} >Technical Skills and Proficiency Levels: </Text>
                                          <Text>No information available for this section</Text>
                                        </Flex>
                                      </li>
                                    </> :
                                    <>
                                      <li style={{ listStyle: 'disc', marginLeft: '25px' }}> <Text bold size={13} >Technical Skills and Proficiency Levels</Text></li>
                                      <ul style={{ marginTop: '7px' }}>
                                        {Object?.entries(Expertise_and_Skills['Technical Skills and Proficiency Levels'])?.map(([skill, proficiency]) => (
                                          <li style={{ marginLeft: '35px', listStyle: 'circle', marginBottom: '10px', fontSize: '13px' }} key={skill}>
                                            <strong>{skill}:</strong> {proficiency}
                                          </li>
                                        ))}
                                      </ul>
                                    </>}
                                  <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                    <Flex style={{ display: 'block' }}>
                                      <Text bold>Projects and Achievements Showcasing Skills: </Text>
                                      <Text>{(Expertise_and_Skills['Projects and Achievements Showcasing Skills'] &&
                                        Expertise_and_Skills['Projects and Achievements Showcasing Skills']?.length !== 0 ||
                                        Expertise_and_Skills['Projects and Achievements Showcasing Skills'] !== undefined) ?
                                        Expertise_and_Skills['Projects and Achievements Showcasing Skills'] : 'No information available for this section'
                                      }</Text>
                                    </Flex>
                                  </li>
                                </ul>
                              </Flex>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold>Industry Engagement</Text></Flex>
                              <ul className={styles.borderline}>
                                <li style={{ listStyle: 'disc', marginLeft: '25px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Contributions to the Industry: </Text>
                                    <Text>{(Industry_Engagement['Contributions to the Industry'] &&
                                      Industry_Engagement['Contributions to the Industry']?.length !== 0 ||
                                      Industry_Engagement['Contributions to the Industry'] !== undefined) ?
                                      Industry_Engagement['Contributions to the Industry'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                                <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Professional Circles: </Text>
                                    <Text>{(Industry_Engagement['Professional Circles'] &&
                                      Industry_Engagement['Professional Circles']?.length !== 0 ||
                                      Industry_Engagement['Professional Circles'] !== undefined) ?
                                      Industry_Engagement['Professional Circles'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                              </ul>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold>Networking and Professional Development </Text></Flex>
                              <Flex className={styles.borderline}>
                                <li style={{ listStyle: 'disc', marginLeft: '15px' }}><Text bold style={{ marginLeft: '-7px' }}>Networking Engagement</Text></li>
                                <ul>
                                  <li style={{ marginLeft: '35px', listStyle: 'circle', marginTop: '7px' }}>
                                    <Flex style={{ display: 'block' }}>
                                      <Text bold>Professional Network: </Text>
                                      <Text>{(Networking['Networking Engagement']['Professional Network'] &&
                                        Networking['Networking Engagement']['Professional Network']?.length !== 0 ||
                                        Networking['Networking Engagement']['Professional Network'] !== undefined) ?
                                        Networking['Networking Engagement']['Professional Network'] : 'No information available for this section'
                                      }</Text>
                                    </Flex>
                                  </li>
                                  <li style={{ marginLeft: '35px', listStyle: 'circle', marginBottom: '10px' }}>
                                    <Flex style={{ display: 'block' }}>
                                      <Text bold>Engagement in Industry-Relevant Events: </Text>
                                      <Text>{(Networking['Networking Engagement']['Engagement in Industry-Relevant Events'] &&
                                        Networking['Networking Engagement']['Engagement in Industry-Relevant Events']?.length !== 0 ||
                                        Networking['Networking Engagement']['Engagement in Industry-Relevant Events'] !== undefined) ?
                                        Networking['Networking Engagement']['Engagement in Industry-Relevant Events'] : 'No information available for this section'
                                      }</Text>
                                    </Flex>
                                  </li>
                                </ul>
                                <li style={{ listStyle: 'disc', marginLeft: '15px' }}><Text bold style={{ marginLeft: '-7px' }}>Continuous Learning and Adaptability</Text></li>
                                <ul>
                                  <li style={{ marginLeft: '35px', listStyle: 'circle', marginTop: '7px' }}>
                                    <Flex style={{ display: 'block' }}>
                                      <Text bold>Ongoing Education and Skill Development: </Text>
                                      <Text>{(Networking['Continuous Learning and Adaptability']['Ongoing Education and Skill Development'] &&
                                        Networking['Continuous Learning and Adaptability']['Ongoing Education and Skill Development']?.length !== 0 ||
                                        Networking['Continuous Learning and Adaptability']['Ongoing Education and Skill Development'] !== undefined) ?
                                        Networking['Continuous Learning and Adaptability']['Ongoing Education and Skill Development'] : 'No information available for this section'
                                      }</Text>
                                    </Flex>
                                  </li>
                                  <li style={{ marginLeft: '35px', listStyle: 'circle', marginBottom: '10px' }}>
                                    <Flex style={{ display: 'block' }}>
                                      <Text bold>Adaptation to Industry Innovations: </Text>
                                      <Text>{(Networking['Continuous Learning and Adaptability']['Adaptation to Industry Innovations'] &&
                                        Networking['Continuous Learning and Adaptability']['Adaptation to Industry Innovations']?.length !== 0 ||
                                        Networking['Continuous Learning and Adaptability']['Adaptation to Industry Innovations'] !== undefined) ?
                                        Networking['Continuous Learning and Adaptability']['Adaptation to Industry Innovations'] : 'No information available for this section'
                                      }</Text>
                                    </Flex>
                                  </li>
                                </ul>
                              </Flex>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold >Education and Alignment with Career Goals </Text></Flex>
                              <Flex className={styles.borderline}><Text style={{ marginLeft: '25px', marginBottom: '10px' }}>
                                {(education && education?.length !== 0 || education !== undefined) ?
                                  education : 'No information available for this section'
                                }</Text></Flex>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold>Behavioral and Social Insights </Text></Flex>
                              <ul className={styles.borderline}>
                                <li style={{ listStyle: 'disc', marginLeft: '25px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Social Media and Professional Behavior: </Text>
                                    <Text>{(behaviour['Social Media and Professional Behavior'] &&
                                      behaviour['Social Media and Professional Behavior']?.length !== 0 ||
                                      behaviour['Social Media and Professional Behavior'] !== undefined) ?
                                      behaviour['Social Media and Professional Behavior'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                                <li style={{ listStyle: 'disc', marginLeft: '25px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Mobility and Ambition: </Text>
                                    <Text>{(behaviour['Mobility and Ambition'] &&
                                      behaviour['Mobility and Ambition']?.length !== 0 ||
                                      behaviour['Mobility and Ambition'] !== undefined) ?
                                      behaviour['Mobility and Ambition'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                                <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Consistency: </Text>
                                    <Text>{(behaviour['Consistency'] &&
                                      behaviour['Consistency']?.length === 0 ||
                                      behaviour['Consistency'] === undefined) ?
                                      behaviour['Consistency'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                                <Flex center middle marginTop={5}>
                                  <Text onClick={handleToggleShowMore} color='theme' bold style={{ cursor: 'pointer' }}>
                                    {showMore ? (
                                      <span>
                                        {' View less'}
                                        <Text style={{ marginLeft: '4px' }}><SvgAngle width={12} height={12} fill={'#581845'} up={true} /></Text>
                                      </span>
                                    ) : (
                                      <span>
                                        {' View more'}
                                        <Text style={{ marginLeft: '4px' }}><SvgAngle width={12} height={12} fill={'#581845'} up={false} /></Text>
                                      </span>
                                    )}
                                  </Text>
                                </Flex>
                              </ul>

                              <div className={styles.myaccountdropdowndotinlast}></div>
                            </Flex>
                          )}
                        </> : <Flex height={100} center middle>
                          <Text color='gray'>No information available for this section</Text>
                        </Flex>}
                    </>
                  }
                </>}
            </>}
        </Flex>
      </Flex>
      <Flex
        height={window.innerHeight - 115}
        style={{
          border: '0.3px solid #C3C3C3',
          width: '0.5px',
          margin: '15px 5px 10px 5px',
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
      ></Flex>
      <Flex flex={6.4} style={{ marginTop: ' 6px' }}>
        <Tabs>
          <Tab title="Resume">
            <Resume />
          </Tab>
          <Tab title="Cover Letter">
            <ResumeCoverTab />
          </Tab>
        </Tabs>
      </Flex>
    </Flex >
  );
};

export default AboutTab;
