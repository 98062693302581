/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
  fill: '#ff0000',
  width: 22,
  height: 22,
};

const SvgNotInterested = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox = "0 0 20 20"
    >
      <path d="M7.2 0C6.24522 0 5.32955 0.421427 4.65442 1.17157C3.97928 1.92172 3.6 2.93913 3.6 4C3.6 5.06087 3.97928 6.07828 4.65442 6.82843C5.32955 7.57857 6.24522 8 7.2 8C8.15478 8 9.07045 7.57857 9.74558 6.82843C10.4207 6.07828 10.8 5.06087 10.8 4C10.8 2.93913 10.4207 1.92172 9.74558 1.17157C9.07045 0.421427 8.15478 0 7.2 0ZM7.2 2C7.67739 2 8.13523 2.21071 8.47279 2.58579C8.81036 2.96086 9 3.46957 9 4C9 4.53043 8.81036 5.03914 8.47279 5.41421C8.13523 5.78929 7.67739 6 7.2 6C6.72261 6 6.26477 5.78929 5.92721 5.41421C5.58964 5.03914 5.4 4.53043 5.4 4C5.4 3.46957 5.58964 2.96086 5.92721 2.58579C6.26477 2.21071 6.72261 2 7.2 2ZM7.2 9C4.797 9 0 10.33 0 13V16H8.55C8.3235 15.394 8.18088 14.7534 8.127 14.1H1.71V13C1.71 12.36 4.527 10.9 7.2 10.9C7.65 10.9 8.1 10.95 8.55 11.03C8.78094 10.3985 9.09984 9.81136 9.495 9.29C8.649 9.1 7.839 9 7.2 9ZM13.95 9C11.7 9 9.9 11 9.9 13.5C9.9 16 11.7 18 13.95 18C16.2 18 18 16 18 13.5C18 11 16.2 9 13.95 9ZM13.95 10.5C15.444 10.5 16.65 11.84 16.65 13.5C16.65 14.06 16.515 14.58 16.272 15L12.6 10.92C12.978 10.65 13.446 10.5 13.95 10.5ZM11.628 12L15.3 16.08C14.922 16.35 14.454 16.5 13.95 16.5C12.456 16.5 11.25 15.16 11.25 13.5C11.25 12.94 11.385 12.42 11.628 12Z" fill="#ED4857"/>
    </svg>
  );
};
SvgNotInterested.defaultProps = defaultProps;

export default SvgNotInterested;
