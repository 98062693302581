import Cookies from 'universal-cookie';
import { useFormik } from 'formik';
import {
  momentLocalizer,
  SlotInfo,
  Calendar as BigCalendar,
  DateLocalizer,
  EventWrapperProps,
} from 'react-big-calendar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';

import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Flex,
  Text,
  Button,
  LinkWrapper,
  SelectTag,
  Loader,
  Toast,
  Modal,
} from '../../uikit';
import { SvgCalendar } from '../../icons';
import SvgOutlookcalendar from '../../icons/SvgOutlookcalendarname';
import SvgGooglecalendar from '../../icons/SvgGooglecalendarname';
import { AppDispatch, RootState } from '../../store';
import {
  getGoogleEventsMiddleware,
  checkAuthMiddleware,
  syncOutlookMiddleWare,
  getUsersByCompanyMiddleware,
  friendsEventsMiddleware,
  getApplicantsMiddleware,
  IntergratemailMiddleWare,
  geteventsOnEventOpen,
} from '../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
import { TopLineLoader } from '../../uikit/v2/Loader';
import { IntegrateEntity } from '../applicantpipelinemodule/applicantPipeLineTypes';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { userProfileMiddleWare } from '../accountsettingsmodule/userprofilemodule/store/middleware/userprofilemiddleware';
import { timezonesdata } from '../meetingscheduler/events/eventType';
import ColorEvent from './calendar-components/ColorEvent';
import {
  CALENDAR,
  CalendarType,
  GoogleEventType,
  OutlookEventType,
  ZitaEventType,
  EventPopUpDetails,
  UserType,
  UserInfo,
  TeamMemberType,
  SlotRangeType,
  EditEventDetails,
  CalendarEventType,
  CalendarOptions,
  ApplicantTypes,
  meetingFormProps,
} from './types';

import {
  deleteGoogleEventMiddleware,
  deleteOutlookEventMiddleware,
  googleEditEventMiddleware,
  outlookEditEventMiddleware,
  verifyEventMiddleware,
  getUrlMiddleware,
} from './store/middleware/calendarmiddleware';
import styles from './styles/calendarScreen.module.css';
import CalendarTypeMenu from './CalendarTypeMenu';
import {
  formatEventTitle,
  getEventMeetingType,
  meetingFormInitialState,
  SlotRangeInitialState,
} from './util';
import EventPopUpModal from './EventPopUpModal';
import { setColor } from './colors';
//import ToolBar from './calendar-components/ToolBar';
import SimpleToolBar from './calendar-components/SimpleToolBar';
import WeekHeader from './calendar-components/WeekHeader';
import MeetingSchedulingScreen from './MeetingSchedulingScreen';
import CalendarScreenLoader from './CalendarScreenLoader';
interface stateType {
  openScheduleEvent: boolean;
  recurringEventId: string;
}
import 'react-big-calendar/lib/css/react-big-calendar.css';

const Calendar = () => {
  const history = useHistory();
  const [noContentheight, setnoContentheight] = useState(
    window.innerHeight - 121,
  );
  // subcription modal
  const [isopensubcription, setopensubcription] = useState(false);
  const cookies = new Cookies();
  const { state: locationState, search } = useLocation<stateType>();
  const param = new URLSearchParams(search);
  const dispatch: AppDispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState<UserInfo>({
    id: null,
    name: null,
  });
  const [currentUserEvents, setCurrentUserEvents] = useState<
    CalendarEventType[]
  >([]);
  const [throughApplicant, setthroughApplicant] = useState(false);
  const [updatestate, setupodatestate] = useState(false);
  const [eventPopUpDetails, setEventPopUpDetails] =
    useState<EventPopUpDetails>();
  const [selectedEvent, setSelectedEvent] = useState<{
    eventId: string | undefined;
    recurringEventId: string | undefined;
  }>({
    eventId: undefined,
    recurringEventId: undefined,
  });
  const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<number[]>([0]);
  const [teamMemberEvents, setTeamMemberEvents] = useState<CalendarEventType[]>(
    [],
  );
  const [currentEventId, setCurrentEventId] = useState<string>();
  const [visibleEvents, setVisibleEvents] = useState<CalendarEventType[]>([]);
  const [openScheduleForm, setOpenScheduleForm] = useState<boolean>(false);
  const [isCalendarIntegrated, setIsCalendarIntegrated] =
    useState<boolean>(false);
  const [calendarProvider, setCalendarProvider] = useState<CALENDAR | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTopLineLoading, setIsTopLineLoading] = useState<boolean>(false);
  const [currentCalendarType, setCurrentCalendarType] = useState<CalendarType>(
    CalendarType.MyCalendar,
  );
  const [tz, _] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const [slotRange, setSlotRange] = useState<SlotRangeType>(
    SlotRangeInitialState,
  );
  const [showEventPopUpModal, setShowEventPopUpModal] =
    useState<boolean>(false);
  const [isEditEvent, setIsEditEvent] = useState<boolean>(false);
  const [editEventDetails, setEditEventDetails] = useState<EditEventDetails[]>(
    [],
  );
  const [isEventOrganizer, setIsEventOrganizer] = useState<boolean>(false);
  const [isEventPrivateName, setIsEventPrivateName] = useState<any>();
  const [globalZones, setGlobalZones] = useState<
    { label: string; value: string }[]
  >([]);
  const [currentTimeZone, setCurrentTimeZone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const [localizer, setLocalizer] = useState<DateLocalizer>(
    momentLocalizer(moment),
  );
  const [showDropDownMenu, setShowDropDownMenu] = useState<boolean>(false);
  const [isApplicantname, setASpplicantname] = useState<string>();
  const [isJdname, setJdname] = useState<string>();
  const [isjdid, setJdid] = useState<string>();
  const [iscandidatemail, setcandidatemail] = useState<string>();
  const [iscandidateid, setcandidateid] = useState<string>();
  const [myCalendarOptions, setMyCalendarOptions] = useState<CalendarOptions>({
    personalEvents: true,
    zitaEvents: true,
  });
  const [applicants, setApplicants] = useState<UserType[]>([]);

  const [openmodel, setopenmodel] = useState(false);
  const [meetingForm, setMeetingForm] = useState<meetingFormProps>(
    meetingFormInitialState,
  );
  const [value, setvalue] = useState('');
  const [evaluate, setevaluate] = useState(false);
  const [evaluatedata, setevaluatedata] = useState({});
  const [viewMeetingSummary, setViewMeetingSummary] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [sample, setsample] = useState([]);
  const [summary, setsummary] = useState('');
  const [selectlist, setselectlist] = useState([]);
  const [tick, settick] = useState([]);
  const [person, setperson] = useState([]);
  const [editid, seteditid] = useState('');
  const [ShowEditButton, setShowEditButton] = useState(false);
  const [datalist, setDatalist] = useState(null);
  const [change, setchange] = useState(false);
  const [isbuttonloading, setbuttonloading] = useState(false);
  interface Interviewer {
    userId: any;
    firstName?: string;
    lastName?: string;
    role?: string;
  }
  interface checkedValues {
    id: any;
    value: any;
    firstName?: string;
    lastName?: string;
    role?: string;
    success?: boolean;
  }
  interface questionid {
    id: any;
    checked: any;
  }
  interface MyFormValues {
    interviewers: Interviewer[];
    checkedValues: checkedValues[];
    // LevelType:string;
    brieftext: string;
    checkedValuesError?: string;
    questionid: questionid[];
    questionidError?: string;
  }

  const initialValues: MyFormValues = {
    interviewers: [],
    checkedValues: [],
    // LevelType: '',
    brieftext: '',
    questionid: [],
  };
  const handleCompanyPageValid = (
    values: MyFormValues,
  ): Partial<MyFormValues> => {
    const errors: Partial<MyFormValues> = {};
    const allFieldsBlank =
      values.brieftext === '' && values.checkedValues.length === 0;

    if (!allFieldsBlank) {
      if (values.brieftext === '') {
        errors.brieftext = '';
      }

      if (values.checkedValues?.length === 0) {
        errors.checkedValuesError = '';
      }
    }
    if (formik.values?.brieftext?.length > 150) {
      errors.brieftext = 'Length should not exceed 150 charector';
    }
    return errors;
  };
  const handleSubmit = () => {
    if (
      formik.values.brieftext !== '' ||
      formik.values.checkedValues.length !== 0
    ) {
      if (permissioninclude.includes('AI Interview Question')) {
        if (
          formik.values.brieftext !== '' &&
          formik.values.checkedValues.length !== 0
        ) {
          setViewMeetingSummary(false);
          setShowPopup(true);
          localStorage.setItem('return', 'false');
        }
      } else if (permissioninclude.includes('Addon AI Interview Question')) {
        if (
          formik.values.brieftext !== '' &&
          formik.values.checkedValues.length !== 0
        ) {
          setViewMeetingSummary(false);
          setShowPopup(true);
          localStorage.setItem('return', 'false');
        }
      } else {
        setopensubcription(true);
        setOpenScheduleForm(false);
        cookies.set('meetingForm', JSON.stringify(meetingForm), { path: '/' });
        cookies.set('formik.values', JSON.stringify(formik.values), {
          path: '/',
        });
        cookies.set('sample', JSON.stringify(sample), { path: '/' });
        localStorage.setItem('return', 'false');
      }
    } else {
      setViewMeetingSummary(true);
      setopenmodel(false);
      localStorage.setItem('return', 'false');
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => handleSubmit(),
    validate: handleCompanyPageValid,
  });
  document.title = 'Calendar';
  const [teamCalendarOptions, setTeamCalendarOptions] =
    useState<CalendarOptions>({
      personalEvents: true,
      zitaEvents: true,
    });

  const { userProfile } = useSelector(({ userProfileReducers }: RootState) => {
    return {
      userProfile: userProfileReducers?.user,
    };
  });
  const { permission, super_user, roles, permissioninclude, user_id } =
    useSelector(
      ({
        permissionReducers,
        userProfileReducers,
        dashboardEmpReducers,
      }: RootState) => {
        return {
          permission: permissionReducers.Permission,
          super_user: permissionReducers.super_user,
          roles: permissionReducers.roles,
          permissioninclude: permissionReducers.data,
          user_id: userProfileReducers.user.id,
        };
      },
    );

  const handleIntegrateTab = () => {
    sessionStorage.setItem('CommunicationDropdown', '1');
    window.location.href = '/account_setting/integration';
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  useEffect(() => {
    const action = param.get('action');
    if (param && action && action === 'open-scheduler-form') {
      setOpenScheduleForm(true);
      if (query.has('action')) {
        query.delete('action');
        history.replace({
          search: query.toString(),
        });
      }
    }
    if (
      locationState &&
      locationState?.recurringEventId &&
      locationState?.recurringEventId &&
      currentUserEvents.length !== 0
    ) {
      const event = currentUserEvents.filter(
        (doc) =>
          doc.recurringEventId === locationState?.recurringEventId ||
          doc.eventId === locationState?.recurringEventId,
      );
      if (event.length !== 0) {
        handleOpenEventForm(event[0]);
      } else {
        toast.error('Unable to find event', {
          duration: 3500,
        });
      }
    }
  }, [JSON.stringify(locationState), currentUserEvents.length]);

  const { email, mail } = useSelector(
    ({ applicantIntegratemailReducers }: RootState) => {
      return {
        email:
          applicantIntegratemailReducers.email !== undefined
            ? applicantIntegratemailReducers.email[0]?.email
            : '',
        mail: applicantIntegratemailReducers?.mail,
      };
    },
  );
  useEffect(() => {
    dispatch(IntergratemailMiddleWare());
    dispatch(userProfileMiddleWare()).then((res) => {
      setvalue(res.payload.user.id);
    });
  }, []);
  useEffect(() => {
    if (currentUser.id) {
      setColor(currentUser.id);
    }
  }, [currentUser.id]);

  useEffect(() => {
    if (currentCalendarType === CalendarType.MyCalendar) {
      if (myCalendarOptions.personalEvents && myCalendarOptions.zitaEvents) {
        setVisibleEvents(currentUserEvents);
      } else if (
        myCalendarOptions.personalEvents &&
        !myCalendarOptions.zitaEvents
      ) {
        setVisibleEvents(() => {
          return currentUserEvents.filter((event) => {
            if (!event.title?.includes('Zita event')) return event;
          });
        });
      } else if (
        !myCalendarOptions.personalEvents &&
        myCalendarOptions.zitaEvents
      ) {
        setVisibleEvents(() => {
          return currentUserEvents.filter((event) => {
            if (event.title?.includes('Zita event')) return event;
          });
        });
      } else {
        setVisibleEvents([]);
      }
    } else {
      if (
        teamCalendarOptions.personalEvents &&
        teamCalendarOptions.zitaEvents
      ) {
        setVisibleEvents(teamMemberEvents);
      } else if (
        teamCalendarOptions.personalEvents &&
        !teamCalendarOptions.zitaEvents
      ) {
        setVisibleEvents(() => {
          return teamMemberEvents.filter((event) => {
            if (!event.title?.includes('Zita event')) return event;
          });
        });
      } else if (
        !teamCalendarOptions.personalEvents &&
        teamCalendarOptions.zitaEvents
      ) {
        setVisibleEvents(() => {
          return teamMemberEvents.filter((event) => {
            if (event.title?.includes('Zita event')) return event;
          });
        });
      } else {
        setVisibleEvents([]);
      }
    }
  }, [
    currentCalendarType,
    selectedTeamMembers,
    myCalendarOptions.personalEvents,
    myCalendarOptions.zitaEvents,
    teamCalendarOptions.personalEvents,
    teamCalendarOptions.zitaEvents,
    teamMemberEvents,
    currentUserEvents,
  ]);
  useEffect(() => {
    setTeamMemberEvents((events) => {
      return [
        ...events.filter((event) => event.userId !== currentUser.id),
        ...currentUserEvents,
      ];
    });
  }, [currentUserEvents]);

  useEffect(() => {
    authenticateCalendarProvider();

    dispatch(getUsersByCompanyMiddleware()).then((resp) => {
      const meetingevent = param.get('eventhandeler');
      if (meetingevent === 'true') {
        setOpenScheduleForm(true);
        if (param.has('eventhandeler')) {
          param.delete('eventhandeler');
          // history.replace({
          //   search: param.toString(),
          // });
        }
        // localStorage.setItem('eventhandeler', 'false');
        handleCloseEventPop();
        setCurrentEventId(param.get('eventhandelerid'));
        if (param.get('checkstatus') === CALENDAR.Google) {
          dispatch(
            googleEditEventMiddleware({
              eventId: param.get('eventhandelerid'),
            }),
          ).then((res) => {
            if (res.payload.data === true) {
              setEditEventDetails(
                res.payload.events.map((event: ZitaEventType) =>
                  getEditEventsDetails(event, resp.payload.users),
                ),
              );
              setIsEditEvent(true);
              setOpenScheduleForm(true);
            }
          });
        } else if (param.get('checkstatus') === CALENDAR.Outlook) {
          dispatch(
            outlookEditEventMiddleware({
              eventid: param.get('eventhandelerid'),
            }),
          ).then((res) => {
            if (res.payload.data === true) {
              setEditEventDetails(
                res.payload.events.map((event: ZitaEventType) =>
                  getEditEventsDetails(event, resp.payload.users),
                ),
              );
              setIsEditEvent(true);
              setOpenScheduleForm(true);
            }
          });
        }
      }
      setTeamMembers(
        resp.payload.users.map((user: TeamMemberType) => {
          setColor(user.userId);
          return {
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            email: user.email,
            calendarEmail: user.calendarEmail,
          };
        }),
      );
    });

    setchange(true);

    dispatch(getApplicantsMiddleware()).then((res: any) => {
      setApplicants(
        res.payload.applicants.map((user: ApplicantTypes) => {
          return {
            email: user.email,
            value: user.userId,
            label: [user.firstName, user.lastName].filter(Boolean).join(' '),
          };
        }),
      );
    });

    const timezones = moment.tz.names();
    setGlobalZones(
      timezones.map((items) => {
        return { label: items, value: items };
      }),
    );
  }, []);

  useEffect(() => { }, [openScheduleForm, change]);
  useEffect(() => {
    const schedulevent = localStorage.getItem('scheduleven');
    if (schedulevent === 'true') {
      setOpenScheduleForm(true);
      localStorage.setItem('scheduleven', 'false');
    }
  }, []);

  useEffect(() => {
    const Applicantname = localStorage.getItem('Applicantname');
    const jdname = localStorage.getItem('Jdname');
    const jdid = localStorage.getItem('jd_id');
    const can_mail = localStorage.getItem('can_mail');
    const can_id = localStorage.getItem('can_id');
    if (Applicantname && Applicantname !== '') {
      setASpplicantname(Applicantname);
      setJdname(jdname);
      setJdid(jdid);
      setcandidatemail(can_mail);
      setcandidateid(can_id);
      setOpenScheduleForm(true);
    }
  }, [localStorage.getItem('Applicantname')]);

  const returnValue = localStorage.getItem('return');
  useEffect(() => {
    if (returnValue === 'true') {
      setOpenScheduleForm(true);
      if (typeof setViewMeetingSummary === 'function') {
        // setopenmodel(true);

        const meetingFormCookie = cookies.get('meetingForm');
        const formikValuesCookie = cookies.get('formik.values');
        const samplecookie = cookies.get('sample');
        const safelyParse = (value1) => {
          if (typeof value1 === 'string') {
            try {
              return JSON.parse(value1);
            } catch (error) {
              return {};
            }
          } else if (typeof value1 === 'object' && value1 !== null) {
            return value1; // The value is already an object
          } else {
            return {}; // Fallback for other cases
          }
        };

        let meetingFormData = safelyParse(meetingFormCookie);
        let formikValues = safelyParse(formikValuesCookie);
        let samplevalue = safelyParse(samplecookie);
        // Convert date strings back to Date objects for meetingFormData
        if (meetingFormData.startTime && meetingFormData.startTime.value) {
          meetingFormData.startTime.value = new Date(
            meetingFormData.startTime.value,
          );
        }
        if (meetingFormData.endTime && meetingFormData.endTime.value) {
          meetingFormData.endTime.value = new Date(
            meetingFormData.endTime.value,
          );
        }

        setMeetingForm(meetingFormData);
        formik.setValues(formikValues);
        setsample(samplevalue);
        localStorage.setItem('return', 'false');
      }
    }
  }, []);

  // setting the boolean value based on requirenment and condition.
  useEffect(() => {
    const isApplicantNamePresent = localStorage.getItem('Applicantname') !== '';
    setthroughApplicant(isApplicantNamePresent);

    const isParamNamePresent = param.get('name') !== '';
    setupodatestate(isParamNamePresent);
  }, [localStorage.getItem('Applicantname'), param.get('name')]);

  useEffect(() => {
    const handleResize = () => {
      setnoContentheight(window.innerHeight - 121);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleEventScheduleForm = () => {
    localStorage.setItem('Applicantname', '');
    localStorage.setItem('jdname', '');
    localStorage.setItem('can_mail', '');
    localStorage.setItem('can_id', '');
    localStorage.setItem('return', 'false');
    setASpplicantname('');
    setJdname('');
    if (calendarProvider) handleGetEvents(calendarProvider);
    setIsEditEvent(false);
    setSlotRange(SlotRangeInitialState);
    setOpenScheduleForm((prevState) => !prevState);
    formik.resetForm();
    localStorage.setItem('return', 'false');
  };
  const handlefunction1 = () => {
    const userResponse = window.confirm(
      'Do you want to leave this site? Changes you made may not be saved.',
    );
    if (userResponse) {
      localStorage.removeItem('return');
      setOpenScheduleForm(false);
      setViewMeetingSummary(false);
      setShowPopup(false);
      formik.resetForm();
      setIsEditEvent(false);
      setSlotRange(SlotRangeInitialState);
      formik.resetForm();
      setsample([]);
      localStorage.setItem('return', 'false');
      cookies.remove('meetingForm', { path: '/' });
      cookies.remove('formik.values', { path: '/' });
      cookies.remove('sample', { path: '/' });
      localStorage.setItem('return', 'false');

      // Removing the url params using this function.
      const removeQueryParam = (name) => {
        if (query.has(name)) {
          query.delete(name);
          history.replace({ search: query.toString() });
        }
      };
      removeQueryParam('jobId');
      removeQueryParam('name');
      removeQueryParam('jobTitle');
      removeQueryParam('emailid');
      removeQueryParam('can_id');
      removeQueryParam('id');
    }
  };
  const handlefunction2 = () => {
    localStorage.removeItem('return');
    setOpenScheduleForm(false);
    setViewMeetingSummary(false);
    setShowPopup(false);
    formik.resetForm();
    setIsEditEvent(false);
    setSlotRange(SlotRangeInitialState);
    formik.resetForm();
    setsample([]);
    localStorage.setItem('return', 'false');
    cookies.remove('meetingForm', { path: '/' });
    cookies.remove('formik.values', { path: '/' });
    cookies.remove('sample', { path: '/' });
    localStorage.setItem('return', 'false');
  };

  const handleOpenEventForm = (event: CalendarEventType) => {
    if (userProfile || email) {
      let eventData = {
        ...getEventPopupDetails(eventPopUpDetails, event),
      };

      setIsEventOrganizer(
        eventData?.organizer.email === email ||
        eventData?.organizer.email === userProfile?.email,
      );
      setEventPopUpDetails(() => ({
        ...eventData,
        eventId: event.eventId,
        syncedBy: event.syncedBy,
        recurringEventId: null,
        attendees: event.attendees || [],
      }));
      handleOpenEditForm({
        ...eventData,
        eventId: event.eventId,
        syncedBy: null,
        recurringEventId: event.recurringEventId,
        attendees: event.attendees || [],
      });
    }
  };

  const handleOpenEditForm = (e: EventPopUpDetails) => {
    handleCloseEventPop();
    setSelectedEvent({
      eventId: e.eventId,
      recurringEventId: e.recurringEventId,
    });
    if (calendarProvider === CALENDAR.Google) {
      dispatch(googleEditEventMiddleware({ eventId: e.eventId })).then(
        (res) => {
          if (res.payload.data === true) {
            setEditEventDetails(
              res.payload.events.map((event: ZitaEventType) =>
                getEditEventsDetails(event, teamMembers),
              ),
            );
            setIsEditEvent(true);
            setOpenScheduleForm(true);
            setsummary(res.payload.summary);
            setselectlist(res.payload.interview_values);
            settick(res.payload.check_question);
            setperson(res.payload.attendes);
            seteditid(res.payload.events[0].jd_id);
          }
        },
      );
    } else if (calendarProvider === CALENDAR.Outlook) {
      dispatch(outlookEditEventMiddleware({ eventid: e.eventId })).then(
        (res) => {
          if (res.payload.data === true) {
            setEditEventDetails(
              res.payload.events.map((event: ZitaEventType) =>
                getEditEventsDetails(event, teamMembers),
              ),
            );
            setIsEditEvent(true);
            setOpenScheduleForm(true);
            setsummary(res.payload.summary);
            setselectlist(res.payload.interview_values);
            settick(res.payload.check_question);
            setperson(res.payload.attendes);
            seteditid(res.payload.events[0].jd_id);
          }
        },
      );
    }
  };

  const getUserNameFromResponse = (user: {
    user__first_name: string;
    user__last_name: string;
  }): string => {
    let name = '';
    if (user.user__first_name) {
      name += `${user.user__first_name} `;
    }
    if (user.user__last_name) {
      name += user.user__last_name;
    }
    return name;
  };

  const extractGoogleEvents = (
    events: GoogleEventType[],
    userId: number,
    userName: string,
  ): CalendarEventType[] => {
    return events.map((event) => {
      const eventData: any = {
        userId,
        title: event.summary,
        start: new Date(event.start.dateTime),
        end: new Date(event.end.dateTime),
        link: 'hangoutLink' in event ? event.hangoutLink : null,
        eventId:
          event?.privateCopy === true
            ? event.eventId
              ? event.eventId
              : event.id
            : event.id,
        color: '#fcba03',
        organizer: {
          email: event.organizer.email,
          full_name: event.organizer.full_name,
        },
        syncedBy: userName,
        recurringEventId: event?.recurringEventId,
      };

      if ('attendees' in event) {
        eventData['attendees'] = getGoogleAttendees(event);
      }
      return eventData;
    });
  };
  const getGoogleAttendees = (event: GoogleEventType) => {
    const res = event.attendees
      .filter((doc) => doc.email)
      .map((doc) => doc.email);
    return res;
  };

  // getting email address and store  while clicking into the calender event
  const getAttendees = (event: OutlookEventType) => {
    const res = event.description.attendees
      .filter((doc) => doc?.emailAddress?.address)
      .map((doc) => doc.emailAddress.address);
    return res;
  };

  // getting  overall sechuduled event data and store  while clicking into the calender event
  const extractOutlookEvents = (
    events: OutlookEventType[],
    userId: number,
    userName: string,
  ): CalendarEventType[] => {
    return events.map((event) => ({
      userId,
      title: event.title,
      start: new Date(event.start_time),
      end: new Date(event.end_time),
      eventId: event.event_id,
      attendees: getAttendees(event),
      organizer: {
        email: event.description?.organizer?.emailAddress?.address,
        full_name: event.description?.organizer?.emailAddress?.full_name,
      },
      link: event.web_url ? event.web_url : null,
      color: '#fcba03',
      syncedBy: userName,
      recurringEventId: event.event_id,
      privateCopy: false,
    }));
  };

  const handleGetEvents = (calendarAccount: CALENDAR) => {
    if (calendarAccount === CALENDAR.Google) {
      dispatch(getGoogleEventsMiddleware({ tz })).then((res) => {
        if (res.payload.data) {
          setIsTopLineLoading(false);
        }
        setCurrentUser(() => {
          return {
            name: getUserNameFromResponse(res.payload.user[0]),
            id: res.payload.userId,
          };
        });
        if (res.payload?.events?.length !== 0) {
          const events = extractGoogleEvents(
            res.payload.events,
            res.payload.userId,
            res.payload.user[0].user__first_name,
          );

          setCurrentUserEvents(events);
          if (teamMemberEvents.length <= 0) {
            setTeamMemberEvents(events);
          }
        }
        setIsLoading(false);
      });
    } else {
      dispatch(syncOutlookMiddleWare()).then((res) => {
        const events = extractOutlookEvents(
          res.payload.events,
          res.payload.userId,
          res.payload.user[0].user__first_name,
        );

        setCurrentUser(() => {
          return {
            name: getUserNameFromResponse(res.payload.user[0]),
            id: res.payload.userId,
          };
        });

        setCurrentUserEvents(events);
        if (teamMemberEvents.length <= 0) {
          setTeamMemberEvents(events);
        }
        setIsLoading(false);
        if (res.payload.data) {
          setIsTopLineLoading(false);
        }
      });
    }
  };

  const authenticateCalendarProvider = () => {
    dispatch(checkAuthMiddleware()).then((res) => {
      const payload = res.payload as IntegrateEntity;
      if (payload.status === true) {
        if (payload.account === 'google') {
          setCalendarProvider(CALENDAR.Google);
          handleGetEvents(CALENDAR.Google);
        } else {
          setCalendarProvider(CALENDAR.Outlook);
          handleGetEvents(CALENDAR.Outlook);
        }
        localStorage.setItem('userId', payload.user);
        setIsCalendarIntegrated(true);
      } else {
        setIsCalendarIntegrated(false);
        setIsLoading(false);
      }
    });
  };

  const handleTeamMemberEvents = (userId: number) => {
    if (
      selectedTeamMembers.filter((member: number) => member === userId)
        .length === 0
    ) {
      setIsTopLineLoading(true);
      setSelectedTeamMembers((prevState) => [...prevState, userId]);
      dispatch(friendsEventsMiddleware({ userId })).then((res) => {
        if (res?.payload?.data === 'user not authenticated') {
          setIsTopLineLoading(false);
        } else if (res?.payload?.data === false) {
          setIsTopLineLoading(false);
        } else if (res?.payload?.account === 'Google') {
          setTeamMemberEvents((prevState) => [
            ...prevState,
            ...res.payload.events.map((event: GoogleEventType) => {
              const attendeesWithFullName =
                'attendees' in event
                  ? event.attendees.filter((attendee) => attendee.email)
                  : [];
              const eventData: any = {
                userId,
                title: event.summary,
                start: new Date(event.start.dateTime),
                end: new Date(event.end.dateTime),
                link: 'hangoutLink' in event ? event.hangoutLink : null,
                organizer: event.organizer,
                synced: res.payload.user[0].user__first_name,
                eventId: event.id,
                attendees: attendeesWithFullName.map(
                  (attendee) => attendee.email,
                ),
              };

              return eventData;
            }),
          ]);
        } else if (res?.payload?.account === 'Outlook') {
          setTeamMemberEvents((prevState) => [
            ...prevState,
            ...res.payload.events.map((event: OutlookEventType) => {
              return {
                userId,
                title: event.title,
                start: new Date(event.start_time),
                end: new Date(event.end_time),
                attendees: getAttendees(event),
                organizer: {
                  email: event.description?.organizer?.emailAddress?.address,
                  full_name:
                    event.description?.organizer?.emailAddress?.full_name,
                },
                link: event.web_url ? event.web_url : null,
                synced: null,
                eventId: event.event_id,
              };
            }),
          ]);
        }
        setIsTopLineLoading(false);
      });
    } else {
      setTeamMemberEvents(
        teamMemberEvents.filter((event) => event.userId !== userId),
      );
      setSelectedTeamMembers(
        selectedTeamMembers.filter((member: number) => member !== userId),
      );
    }
  };

  const handleCalendarType = () => {
    if (currentCalendarType === CalendarType.MyCalendar) {
      setCurrentCalendarType(CalendarType.TeamCalendar);
    } else {
      setCurrentCalendarType(CalendarType.MyCalendar);
    }
  };

  const handleCloseEventPop = () => {
    setShowEventPopUpModal(false);
    // setEventPopUpDetails({});
  };

  const handleRemoveEvent = (setOpenEventDeleteModal = null) => {
    if (calendarProvider === CALENDAR.Google) {
      setbuttonloading(true);
      dispatch(
        deleteGoogleEventMiddleware({ eventId: eventPopUpDetails?.eventId! }),
      )
        .then((res: any) => {
          if (res.payload.status === true) {
            handleCloseEventPop();
            setTeamMemberEvents((events) =>
              events.filter(
                (event) => event.eventId !== eventPopUpDetails?.eventId,
              ),
            );
            setCurrentUserEvents((events) =>
              events.filter(
                (event) => event.eventId !== eventPopUpDetails?.eventId,
              ),
            );
            setbuttonloading(false);
            Toast('Event cancelled successfully.', 'LONG', 'success');
          } else {
            setbuttonloading(false);
            Toast('Event deletion failed. Please try again.', 'LONG', 'error');
          }
        })
        .catch((err: any) => {
          setOpenEventDeleteModal(false);
          setbuttonloading(false);
          Toast('Event deletion failed. Please try again.', 'LONG', 'error');
        });
    } else {
      setbuttonloading(true);
      dispatch(
        deleteOutlookEventMiddleware({ eventId: eventPopUpDetails?.eventId! }),
      )
        .then((res: any) => {
          if (res.payload.status === true) {
            handleCloseEventPop();
            setTeamMemberEvents((events) =>
              events.filter(
                (event) => event.eventId !== eventPopUpDetails.eventId,
              ),
            );
            setCurrentUserEvents((events) =>
              events.filter(
                (event) => event.eventId !== eventPopUpDetails.eventId,
              ),
            );
            setbuttonloading(false);
            Toast('Event cancelled successfully.', 'LONG', 'success');
          } else {
            setbuttonloading(false);
            Toast('Event deletion failed. Please try again.', 'LONG', 'error');
          }
        })
        .catch((err: any) => {
          setbuttonloading(false);
          Toast('Event deletion failed. Please try again.', 'LONG', 'error');

          setOpenEventDeleteModal(false);
        });
    }
    setOpenEventDeleteModal(false);
  };

  function getEventPopupDetails(
    prevEvent: EventPopUpDetails,
    event: CalendarEventType,
  ) {
    return {
      ...prevEvent,
      title: event.title,
      startDate: new Date(event.start),
      endDate: new Date(event.end),
      link: event.link,
      organizer: event.organizer,
      isZitaEvent: event?.title?.includes('Zita event'),
      canEdit: event.userId === currentUser.id,
      eventId: event.eventId,
      syncedBy: null,
      recurringEventId: event.recurringEventId,
      attendees: event.attendees || [],
    };
  }
  function getEventPopupDetailsattendees(
    prevEvent: EventPopUpDetails,
    event: CalendarEventType,
    attendees: any,
  ) {
    return {
      ...prevEvent,
      title: event.title,
      startDate: new Date(event.start),
      endDate: new Date(event.end),
      link: event.link,
      organizer: event.organizer,
      isZitaEvent: event.title.includes('Zita event'),
      canEdit: event.userId === currentUser.id,
      eventId: event.eventId,
      syncedBy: null,
      recurringEventId: event.recurringEventId,
      attendees: attendees || [],
    };
  }

  const formattedDat = (datestring) => {
    const dateObject = new Date(datestring);
    return dateObject;
  };

  // checking the date and time to evaluate
  const verifyDate = (newdate) => {
    const expirationDate = moment(newdate);
    const currentDate = moment();
    if (currentDate.isAfter(expirationDate)) {
      return true;
    } else {
      return false;
    }
  };

  // Select event on calender for view already sheduled event.
  const handleOnSelectEvent = (event: CalendarEventType) => {
    setevaluate(null);
    setShowEditButton(false);
    setDatalist(null);
    const payload = {
      attendees: JSON.stringify(event.attendees),
      organizer: event.organizer.email,
      user_id: event.userId,
    };
    let eventData = {
      ...getEventPopupDetails(eventPopUpDetails, event),
    };
    setEventPopUpDetails(eventData);

    // dispatch for getevent organizer name and attendees
    dispatch(geteventsOnEventOpen(payload)).then((res) => {
      if (res.payload.success === true) {
        setIsEventOrganizer(res.payload.organizer);
        setIsEventPrivateName(res.payload.private_name);
        let eventDatas = {
          ...getEventPopupDetailsattendees(
            eventPopUpDetails,
            event,
            res.payload.attendees,
          ),
        };
        setEventPopUpDetails(eventDatas);
        if (userProfile || email) {
          setShowEventPopUpModal(true);
          dispatch(
            verifyEventMiddleware({
              eventId: event.eventId,
              calendarProvider: calendarProvider,
            }),
          ).then((response) => {
            if (response.payload.success === true) {
              const datetoday = new Date();
              if (response.payload.data.length > 0) {
                setShowEditButton(response.payload.slotter);
                setDatalist(response.payload.eventDetails);
                if (verifyDate(response.payload.data[0].s_time)) {
                  setevaluate(true);
                  setevaluatedata(response.payload.data[0]);
                } else {
                  setevaluate(false);
                }
              }
            } else {
              setevaluate(false);
            }
          });
        }
      }
    });
  };

  const handleMyCalendarOptions = (options: CalendarOptions) => {
    setMyCalendarOptions(options);
  };

  const handleTeamCalendarOptions = (options: CalendarOptions) => {
    setTeamCalendarOptions(options);
  };

  const handleDropDown = () => {
    setShowDropDownMenu((state) => !state);
  };

  const handleChangeTimeZone = (timezone: string) => {
    const timezonee = timezonesdata.find((t) => t.label === timezone);
    moment.tz.setDefault(timezonee.timezone);
    setLocalizer(momentLocalizer(moment));
    setCurrentTimeZone(timezonee.timezone);
  };

  const handleJoinMeeting = (eventId: string, link) => {
    if (calendarProvider === CALENDAR.Google) {
      window.open(eventPopUpDetails.link);
    } else if (calendarProvider === CALENDAR.Outlook) {
      dispatch(getUrlMiddleware({ event_id: eventId })).then((res) => {
        if (!res.payload.data.error) {
          if (res.payload.data.onlineMeeting.joinUrl) {
            window.open(res.payload.data.onlineMeeting.joinUrl);
          } else if (res.payload.data.onlineMeeting.joinURL) {
            window.open(res.payload.data.onlineMeeting.joinURL);
          }
        } else {
          window.open(link)
        }
      }
      );
    }
  };

  // copy the event in onclick event in calender
  const handleCopyMeeting = (eventId: string, link) => {
    if (calendarProvider === CALENDAR.Google) {
      navigator?.clipboard?.writeText(eventPopUpDetails.link);
      Toast('Link copied', 'LONG', 'success');
    } else if (calendarProvider === CALENDAR.Outlook) {
      dispatch(getUrlMiddleware({ event_id: eventId })).then((res) => {
        if (!res.payload.data.error) {
          if (res.payload.data.onlineMeeting.joinURL) {
            navigator?.clipboard?.writeText(
              res.payload.data.onlineMeeting.joinURL,
            );
            Toast('Link copied', 'LONG', 'success');
          }
          if (res.payload.data.onlineMeeting.joinUrl) {
            navigator?.clipboard?.writeText(
              res.payload.data.onlineMeeting.joinUrl,
            );
            Toast('Link copied', 'LONG', 'success');
          }
        } else {
          navigator?.clipboard?.writeText(link)
          Toast('Link copied', 'LONG', 'success');
        }
      });
    }
  };

  const getEditEventsDetails = (
    event: ZitaEventType,
    val: any,
  ): EditEventDetails => {
    const inter = event.attentee;
    const int = inter;

    const interviewerEmails = event.interviewers.split(',');
    const interviewers = val.filter(
      (member) =>
        interviewerEmails.includes(member.email) ||
        interviewerEmails.includes(member.calendarEmail),
    );
    return {
      applicant: {
        name: event.applicant,
        id: event.cand_id,
      },
      jobRole: {
        label: JSON.parse(event.jd).label,
        value: JSON.parse(event.jd).value,
      },
      startDateTime: new Date(event.s_time),
      endDateTime: new Date(event.e_time),
      timeZone: event.timezone,
      attentee: int,
      interviewers: interviewers,
      eventType: getEventMeetingType(event.event_type),
      location: event.location,
      notes: event.notes,
      privateNotes: event.private_notes,
      remainder: event.remainder,
    };
  };
  const handleEditEvent = () => {
    handleCloseEventPop();
    setSelectedEvent({
      eventId: eventPopUpDetails.eventId,
      recurringEventId: eventPopUpDetails.recurringEventId,
    });
    if (calendarProvider === CALENDAR.Google) {
      dispatch(
        googleEditEventMiddleware({ eventId: eventPopUpDetails.eventId }),
      ).then((res) => {
        if (res.payload.data === true) {
          setEditEventDetails(
            res.payload.events.map((event: ZitaEventType) =>
              getEditEventsDetails(event, teamMembers),
            ),
          );
          setIsEditEvent(true);
          setOpenScheduleForm(true);
          setsummary(res.payload.summary);
          setselectlist(res.payload.interview_values);
          settick(res.payload.check_question);
          setperson(res.payload.attendes);
          seteditid(res.payload.events[0].jd_id);
        }
      });
    } else if (calendarProvider === CALENDAR.Outlook) {
      dispatch(
        outlookEditEventMiddleware({ eventid: eventPopUpDetails.eventId }),
      ).then((res) => {
        if (res.payload.data === true) {
          setEditEventDetails(
            res.payload.events.map((event: ZitaEventType) =>
              getEditEventsDetails(event, teamMembers),
            ),
          );
          setIsEditEvent(true);
          setOpenScheduleForm(true);
          setsummary(res.payload.summary);
          setselectlist(res.payload.interview_values);
          settick(res.payload.check_question);
          setperson(res.payload.attendes);
          seteditid(res.payload.events[0].jd_id);
        }
      });
    }
  };

  const handleOnSelectSlot = (slotInfo: SlotInfo) => {
    setSlotRange(() => {
      const date = new Date(slotInfo.start);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setHours(0);
      return {
        start: slotInfo.start,
        end: slotInfo.end,
        date,
      };
    });
    setOpenScheduleForm(true);
  };

  const IntegrationMenuView = (
    <div>
      <div className={styles.calendarLogo}>
        <Text bold size={16} color="theme">
          Calendar
        </Text>
        <div className={styles.triangle}> </div>
      </div>
      <Flex height={noContentheight} center middle>
        <Flex center flex={1} middle columnFlex>
          <Text color="placeholder" style={{ marginBottom: 16 }}>
            Integrate your calendar with zita application to schedule your
            meetings
          </Text>
          <Flex
            onClick={() => {
              handleIntegrateTab();
            }}
          >
            <Button>Integrate</Button>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tzone = `${moment
    .tz(userTimezone)
    .format('Z')} (${userTimezone})`.trim();

  const TimeZoneView = (
    <div style={{ marginLeft: '10px', width: '200px' }}>
      <SelectTag
        labelBold
        options={timezonesdata}
        isSearchable={true}
        defaultValue={{ label: tzone, value: tzone }}
        onChange={(option) => handleChangeTimeZone(option.label)}
      />
    </div>
  );

  const NavigateEvaluate = (navigatedata: any) => {
    history.push(
      `applicant_profile_view/${navigatedata.jd_id}/${navigatedata.cand_id}?question`,
    );
  };

  useEffect(() => {
    if (param.get('emailid')) {
      setMeetingForm((form) => ({
        ...form,
        applicant: {
          ...form.applicant,
          email: param.get('emailid')?.toString(),
          id: Number(param.get('can_id')),
        },
        job: {
          ...form.job,
          label: param.get('jobTitle')?.toString(),
          value: Number(param.get('id')),
        },
      }));
    }
  }, []);

  const CalendarHeaderView = (
    <>
      <div className={styles.calendarLogo}>
        {/* <SvgCalendar width={30} height={30} /> */}
        <Text bold size={16} color="theme">
          Calendar
        </Text>
        <Flex row height={10} middle marginTop={1} end>
          <Flex marginTop={2} row marginRight={-20}>
            {' '}
            {mail === 'GOOGLE' && <SvgGooglecalendar />}
            {mail === 'OUTLOOK' && <SvgOutlookcalendar />}{' '}
          </Flex>
          <Flex marginRight={15}>
            <Text>{email}</Text>
          </Flex>
        </Flex>
        <div className={styles.triangle}> </div>
      </div>

      <Flex row between>
        {' '}
        <Flex className={styles.calendarInputs} marginTop={10}>
          <Flex row center marginRight={15}>
            <Text size={13} color="theme">
              Time zone:
            </Text>
            {TimeZoneView}
          </Flex>

          <Flex row center>
            <Text size={13} color="theme">
              Calendar View:
            </Text>
            <CalendarTypeMenu
              style={{
                margin: '0px 10px',
                fontSize: '13px',
              }}
              handleTeamMemberEvents={handleTeamMemberEvents}
              currentCalendarType={currentCalendarType}
              user_id={user_id}
              handleCalendarType={handleCalendarType}
              selectedTeamMembers={selectedTeamMembers}
              teamMembers={teamMembers}
              showDropDownMenu={showDropDownMenu}
              handleDropDown={handleDropDown}
              myCalendarOptions={myCalendarOptions}
              teamCalendarOptions={teamCalendarOptions}
              handleMyCalendarOptions={handleMyCalendarOptions}
              handleTeamCalendarOptions={handleTeamCalendarOptions}
            />
          </Flex>
        </Flex>
        {/* <Flex marginTop={10}>
          <Button
            className={styles.scheduleButton}
            onClick={handleEventScheduleForm}
          >
            Schedule Events
          </Button>
        </Flex> */}
      </Flex>
    </>
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          // paddingBottom: 15,
        }}
      >
        <Toaster position="top-right" reverseOrder={false} />
        {/* <TopLineLoader show={isTopLineLoading} /> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '-webkit-fill-available',
          }}
        >
          {isCalendarIntegrated === true ? (
            <>
              <div className={styles.calenderContent}>
                {CalendarHeaderView}
                {teamMemberEvents && (
                  <>
                    {currentTimeZone && (
                      <>
                        <style>
                          {`
          .rbc-agenda-empty {
            color: #888888 !important; 
             }
            `}
                        </style>
                        <BigCalendar
                          localizer={localizer}
                          events={visibleEvents}
                          dayLayoutAlgorithm={'no-overlap'}
                          defaultView={'work_week'}
                          views={[
                            'day',
                            'work_week',
                            'week',
                            'month',
                            'agenda',
                          ]}
                          // onShowMore={(events, date) =>
                          //   useState({ showModal: true, events })
                          // }
                          // onSelectSlot={(slotInfo) => {
                          //   handleOnSelectSlot(slotInfo);
                          // }}
                          onSelectEvent={handleOnSelectEvent}
                          selectable
                          popup={true}
                          className={styles.calendar}
                          titleAccessor={formatEventTitle}
                          tooltipAccessor={null}
                          formats={{
                            eventTimeRangeFormat: () => '',
                          }}
                          showAllEvents={true}
                          components={{
                            toolbar: SimpleToolBar,
                            event: ColorEvent,
                            week: {
                              header: WeekHeader,
                            },
                            work_week: {
                              header: WeekHeader,
                            },
                          }}
                          messages={{
                            noEventsInRange:
                              'No events are scheduled for this period.', // Custom message
                          }}
                        />
                      </>
                    )}
                    {showEventPopUpModal && (
                      <EventPopUpModal
                        handleCloseEventPopUpModal={() => handleCloseEventPop()}
                        handleEditEvent={handleEditEvent}
                        handleRemoveEvent={handleRemoveEvent}
                        isEventOrganizer={isEventOrganizer}
                        ShowEditButton={ShowEditButton}
                        isEventPrivateName={isEventPrivateName}
                        joinMeeting={handleJoinMeeting}
                        copyMeeting={handleCopyMeeting}
                        showEventPopUpModal={showEventPopUpModal}
                        eventPopUpDetails={eventPopUpDetails}
                        calendarProvider={calendarProvider}
                        evaluate={evaluate}
                        evaluatedata={evaluatedata}
                        NavigateEvaluate={NavigateEvaluate}
                        currentUser={currentUser}
                        currentUserEvents={currentUserEvents}
                        datalist={datalist}
                        isbuttonloading={isbuttonloading}
                      />
                    )}
                  </>
                )}
                {openScheduleForm && (
                  <MeetingSchedulingScreen
                    permissioninclude={permissioninclude}
                    setopensubcription={setopensubcription}
                    value={value}
                    meetingForm={meetingForm}
                    setMeetingForm={setMeetingForm}
                    username={currentUser.name}
                    applicants={applicants}
                    currentUser={currentUser}
                    currentUserEvents={currentUserEvents}
                    EventId={currentEventId}
                    eventId={currentEventId}
                    cand_name={param.get('name') || isApplicantname}
                    jd_name={param.get('jobTitle') || isJdname}
                    jd_id={Number(param.get('jobId')) || Number(isjdid)}
                    cand_email={param.get('emailid') || iscandidatemail}
                    cand_id={
                      Number(param.get('can_id')) || Number(iscandidateid)
                    }
                    APPLY={throughApplicant}
                    update={updatestate}
                    calendarProvider={calendarProvider}
                    editEventDetails={isEditEvent ? editEventDetails[0] : null}
                    teamMembers={teamMembers}
                    openScheduleForm={openScheduleForm}
                    setOpenScheduleForm={setOpenScheduleForm}
                    handleEventScheduleForm={handleEventScheduleForm}
                    slotRange={slotRange}
                    openmodel={openmodel}
                    setopenmodel={setopenmodel}
                    setIsTopLineLoading={setIsTopLineLoading}
                    formik={formik}
                    viewMeetingSummary={viewMeetingSummary}
                    setViewMeetingSummary={setViewMeetingSummary}
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    handleSubmit={handleSubmit}
                    handlefunction1={handlefunction1}
                    sample={sample}
                    setsample={setsample}
                    summary={summary}
                    tick={tick}
                    person={person}
                    editid={editid}
                    selectlist={selectlist}
                    {...selectedEvent}
                  />
                )}
              </div>
            </>
          ) : (
            <>{IntegrationMenuView}</>
          )}
        </div>

        {isTopLineLoading && (
          <Flex
            style={{
              position: ' fixed',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              zIndex: 100000000,
            }}
          >
            <Loader />
          </Flex>
        )}
      </div>
      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          differece_key={['Ai_interviewquestion']}
          addon_name={['AI Interview Questions Generation']}
          handlefunction={handlefunction2}
        />
      )}
    </>
  );
};

export default Calendar;
