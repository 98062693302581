import { createSlice } from "@reduxjs/toolkit";
import { tourdataget } from "../middleware/middleware";
import { Tourmiddeleware } from "../../tourTypes";


const tourdata: Tourmiddeleware = {
    isLoading: false,
    error: '',
    skip_id: 0,
    rating: 0,
    notes: '',
    is_first_login:false,
    is_steps:false,
};

const TourReducer = createSlice({
    name: "tour_data",
    initialState: tourdata,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(tourdataget.pending, (state) => {
            state.isLoading = true;
            state.error = '';
        })
        builder.addCase(tourdataget.fulfilled, (state,action) => {
            state.isLoading = false;
            state.is_first_login=action.payload[0]?.is_first_login;
            state.skip_id=action.payload[0]?.skip_id;
            state.is_steps=action.payload[0]?.is_steps;
        })
        builder.addCase(tourdataget.rejected, (state, action) => {
            state.isLoading = false;
            if (typeof action.payload === 'string') {
                state.error = action.payload;
            }
        });
    }
})

export const TourdataReducer=TourReducer.reducer;