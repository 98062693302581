/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
    fill: '#581845',
    width: 24,
    height: 24,
};

const SvgHelpTour = ({ width, height, fill }: typeof defaultProps) => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.165" cy="9.165" r="9.165" fill="white" />
            <path d="M8.25 14.6667H10.0833V12.8333H8.25V14.6667ZM9.16667 0C4.10667 0 0 4.10667 0 9.16667C0 14.2267 4.10667 18.3333 9.16667 18.3333C14.2267 18.3333 18.3333 14.2267 18.3333 9.16667C18.3333 4.10667 14.2267 0 9.16667 0ZM9.16667 16.5C5.12417 16.5 1.83333 13.2092 1.83333 9.16667C1.83333 5.12417 5.12417 1.83333 9.16667 1.83333C13.2092 1.83333 16.5 5.12417 16.5 9.16667C16.5 13.2092 13.2092 16.5 9.16667 16.5ZM9.16667 3.66667C7.14083 3.66667 5.5 5.3075 5.5 7.33333H7.33333C7.33333 6.325 8.15833 5.5 9.16667 5.5C10.175 5.5 11 6.325 11 7.33333C11 9.16667 8.25 8.9375 8.25 11.9167H10.0833C10.0833 9.85417 12.8333 9.625 12.8333 7.33333C12.8333 5.3075 11.1925 3.66667 9.16667 3.66667Z" fill="#581845" />
        </svg>
        // <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <circle cx="10" cy="10" r="10" fill="#581845" />
        //     <path d="M9.25 15.6667H11.0833V13.8333H9.25V15.6667ZM10.1667 1C5.10667 1 1 5.10667 1 10.1667C1 15.2267 5.10667 19.3333 10.1667 19.3333C15.2267 19.3333 19.3333 15.2267 19.3333 10.1667C19.3333 5.10667 15.2267 1 10.1667 1ZM10.1667 17.5C6.12417 17.5 2.83333 14.2092 2.83333 10.1667C2.83333 6.12417 6.12417 2.83333 10.1667 2.83333C14.2092 2.83333 17.5 6.12417 17.5 10.1667C17.5 14.2092 14.2092 17.5 10.1667 17.5ZM10.1667 4.66667C8.14083 4.66667 6.5 6.3075 6.5 8.33333H8.33333C8.33333 7.325 9.15833 6.5 10.1667 6.5C11.175 6.5 12 7.325 12 8.33333C12 10.1667 9.25 9.9375 9.25 12.9167H11.0833C11.0833 10.8542 13.8333 10.625 13.8333 8.33333C13.8333 6.3075 12.1925 4.66667 10.1667 4.66667Z" fill="white" />
        // </svg>


    );
};
SvgHelpTour.defaultProps = defaultProps;

export default SvgHelpTour;