import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Flex } from '../uikit';

const PrivilageUser = () => {
  return (
    <>
      <Flex row center>
        <div>User privilage development on process </div>
      </Flex>
    </>
  );
};

export default PrivilageUser;
