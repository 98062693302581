const SvgGooglemail = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      fill="none"
      viewBox="0 0 150 150"
      {...props}
    ><path
fill="#4285F4"
d="M11.373 50V24.249L5.38 16.943.01 12.888v32.567C.009 47.97 1.537 50 3.418 50h7.955Z"
/>
<path
fill="#34A853"
d="M38.645 50h7.954c1.887 0 3.41-2.037 3.41-4.544V12.889l-6.085 4.643-5.279 6.717V50Z"
/>
<path
fill="#EA4335"
d="m11.372 24.249-.815-10.062.815-9.63L25.01 18.19 38.645 4.557l.912 9.11-.912 10.582L25.01 37.882 11.372 24.249Z"
/>
<path
fill="#FBBC04"
d="M38.645 4.557V24.25l11.364-11.36v-6.06c0-5.62-4.813-8.823-8.182-5.453l-3.182 3.181Z"
/>
<path
fill="#C5221F"
d="m.009 12.888 5.226 5.225 6.138 6.136V4.557L8.19 1.377C4.815-1.995.01 1.21.01 6.828v6.06Z"
/>
</svg>);
export default SvgGooglemail;