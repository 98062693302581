export const industryType = [
  { value: '1', label: 'Any' },
  { value: '2', label: 'Education' },
  { value: '7', label: 'Healthcare' },
  { value: '4', label: 'Insurance & Finance' },
  { value: '6', label: 'Manufacturing' },
  { value: '3', label: 'Marketing & Advertising' },
  { value: '8', label: 'Software & Engineering' },
  { value: '5', label: 'Supply Chain Management' },
];

export const SuperAdmin = [
  {
    card_name: 'Profiles',
    Icon: 'SvgProfiles',
    discription:
      "Manage your company's identity and your personal user settings, including logos, and branding.",
    button: 'Manage Profiles',
    link: '/account_setting/profiles',
  },
  {
    card_name: 'Build Careers Page',
    Icon: 'SvgBuildCareers',
    discription:
      'Streamline the design of your careers portal with customizable branding and layout options.',
    button: 'Manage Careers Page',
    link: '/account_setting/build_careers_page',
  },
  {
    card_name: 'Subscription & Billing',
    Icon: 'SvgSubsandBills',
    discription:
      'Manage your subscription, track add-on status, and monitor credit allocations with ease.',
    button: 'Manage Subscription',
    link: '/account_setting/subscription',
  },
  {
    card_name: 'Manage Users',
    Icon: 'SvgManageUsers',
    discription:
      'Adjust team member roles, permissions, and access by inviting, updating, or removing users.',
    button: 'Manage Team',
    link: '/account_setting/manage_user',
  },
  {
    card_name: 'Integrations',
    Icon: 'SvgIntegrations',
    discription:
      'Configure integrations with calendar and email platforms to enhance connectivity and functionality.',
    button: 'Manage Integrations',
    link: '/account_setting/integration',
  },
  {
    card_name: 'Hiring Workflow',
    Icon: 'SvgWorkflow',
    discription:
      'Create, modify, and manage your hiring pipelines to optimize recruitment workflows.',
    button: 'Manage Workflow',
    link: '/account_setting/hiring_workflow',
  },
  {
    card_name: 'Templates Hub',
    Icon: 'SvgTemplatesHub',
    discription:
      'Customize templates for job descriptions and candidate emails, ensuring consistency and saving time.',
    button: 'Manage Templates',
    link: '/account_setting/templates_hub',
  },
  {
    card_name: 'Notification Preferences',
    Icon: 'SvgNotificationPref',
    discription:
      'Customize and personalize your alert settings for different activities with tailored email notifications.',
    button: 'Manage Notifications',
    link: '/account_setting/email_notification',
  },
];

export const Admin = [
  {
    card_name: 'Profiles',
    Icon: 'SvgProfiles',
    discription:
      "Manage your company's identity and your personal user settings, including logos, and branding.",
    button: 'Manage Profiles',
    link: '/account_setting/profiles',
  },
  {
    card_name: 'Build Careers Page',
    Icon: 'SvgBuildCareers',
    discription:
      'Streamline the design of your careers portal with customizable branding and layout options.',
    button: 'Manage Careers Page',
    link: '/account_setting/build_careers_page',
  },
  {
    card_name: 'Integrations',
    Icon: 'SvgIntegrations',
    discription:
      'Configure integrations with calendar and email platforms to enhance connectivity and functionality.',
    button: 'Manage Integrations',
    link: '/account_setting/integration',
  },
  {
    card_name: 'Hiring Workflow',
    Icon: 'SvgWorkflow',
    discription:
      'Create, modify, and manage your hiring pipelines to optimize recruitment workflows.',
    button: 'Manage Workflow',
    link: '/account_setting/hiring_workflow',
  },
  {
    card_name: 'Templates Hub',
    Icon: 'SvgTemplatesHub',
    discription:
      'Customize templates for job descriptions and candidate emails, ensuring consistency and saving time.',
    button: 'Manage Templates',
    link: '/account_setting/templates_hub',
  },
];

export const Hiring = [
  {
    card_name: 'Profiles',
    Icon: 'SvgProfiles',
    discription:
      "Manage your company's identity and your personal user settings, including logos, and branding.",
    button: 'Manage Profiles',
    link: '/account_setting/profiles',
  },
  {
    card_name: 'Integrations',
    Icon: 'SvgIntegrations',
    discription:
      'Configure integrations with calendar and email platforms to enhance connectivity and functionality.',
    button: 'Manage Integrations',
    link: '/account_setting/integration',
  },
  {
    card_name: 'Hiring Workflow',
    Icon: 'SvgWorkflow',
    discription:
      'Create, modify, and manage your hiring pipelines to optimize recruitment workflows.',
    button: 'Manage Workflow',
    link: '/account_setting/hiring_workflow',
  },
  {
    card_name: 'Templates Hub',
    Icon: 'SvgTemplatesHub',
    discription:
      'Customize templates for job descriptions and candidate emails, ensuring consistency and saving time.',
    button: 'Manage Templates',
    link: '/account_setting/templates_hub',
  },
];
