import React, { useEffect, useState } from 'react';
import { StageData } from '../../hooks/useStages/types';
import Text from '../../uikit/Text/Text';
import Flex from '../../uikit/Flex/Flex';
import InputSwitch from '../../uikit/Switch/InputSwitch';
import SvgView from '../../icons/SvgView';
import SvgEdit from '../../icons/SvgEdit';
import SvgTickBox from '../../icons/SvgTickBox';
import SvgCloseBox from '../../icons/SvgCloseBox';

interface StageCardProps {
  doc: StageData;
  handleNewApplicantViewEmailTemplate: (stageName: any, stageID?: any) => void;
  selectedTemplate: any;
  editfunction: any;
  isNewStageEmailScreen: any;
  isEditEmailContent: any;
  isShowSelectTag: any;
  setShowCreateError: any;
}

export const NewApplicantstageEmail: React.FC<StageCardProps> = (props) => {
  const {
    doc,
    handleNewApplicantViewEmailTemplate,
    selectedTemplate,
    editfunction,
    isNewStageEmailScreen,
    isEditEmailContent,
    isShowSelectTag,
    setShowCreateError,
  } = props;

  return (
    <>
      <Flex row marginBottom={10} marginTop={10}>
        <Flex flex={1}>
          <Text size={13}>{doc.stage_name}</Text>
        </Flex>

        <Flex flex={1} row center middle>
          <Flex marginLeft={12}>
            <InputSwitch checked disabled />
          </Flex>
          <Text color="theme">ON</Text>
        </Flex>

        <Flex flex={1} row center middle>
          <Flex
            title="View email"
            onClick={() => {
              if (
                isNewStageEmailScreen === true ||
                isEditEmailContent === true
              ) {
                if (isShowSelectTag) {
                  setShowCreateError(
                    'Please create email content to complete the automation rule',
                  );
                } else if (!isShowSelectTag) {
                  setShowCreateError(
                    'Please complete editing the email content to proceed.',
                  );
                }
                return;
              }
              if (
                isNewStageEmailScreen === false ||
                isEditEmailContent === false
              ) {
                setShowCreateError('');
                handleNewApplicantViewEmailTemplate(doc.stage_name, doc.id);
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <SvgView height={18} width={18} />
          </Flex>
          <Flex
            title="Edit email"
            marginLeft={20}
            onClick={() => {
                if (
                  isNewStageEmailScreen === true ||
                  isEditEmailContent === true
                ) {
                  if (isShowSelectTag) {
                    setShowCreateError(
                      'Please create email content to complete the automation rule',
                    );
                  } else if (!isShowSelectTag) {
                    setShowCreateError(
                      'Please complete editing the email content to proceed.',
                    );
                  }
                  return;
                }
                if (
                  isNewStageEmailScreen === false ||
                  isEditEmailContent === false
                ) {
                  setShowCreateError('');
                  editfunction(selectedTemplate, doc.id, doc.stage_name);
                }
              }}
            style={{ cursor: 'pointer' }}
          >
            <SvgEdit width={14} height={14} fill={'#581845'} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
