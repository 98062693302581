const defaultProps = {
  fill: '#581845',
  width: 14,
  height: 14,
};

const Svgchatbot = ({ fill, width, height }: typeof defaultProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 17"
  >
    <path
      d="M0.0608694 14.2031L6.12178 8.14214L0.0608694 2.08122L2.08117 0.060915L8.14209 6.12183L14.203 0.060915L16.2233
 2.08122L10.1624 8.14214L16.2233 14.2031L14.203 16.2234L8.14209 10.1624L2.08117 16.2234L0.0608694 14.2031Z"
      fill="white"
    />
  </svg>
);

Svgchatbot.defaultProps = defaultProps;

export default Svgchatbot;
