/* eslint max-len: ["error", { "code": 2000 }] */

const defaultProps = {
  fill: '#34cc65',
  width: 24,
  height: 24,
};

const SvgJobapplication = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 128 128"
      fill={fill}
    >
      <path
        fill="#34cc65"
        d="M68.8 44.9c-.2-1-.8-1.9-1.7-2.3-3-1.5-9.5-3.7-9.5-3.7v-2.2l.2-.1c1.3-.9 2.1-2.4 2.4-4v-.2h.2c1 0 1.9-.6 2.2-1.5.2-.4.3-.8.3-1.3 0-.3-.1-.6-.2-.9-.1-.3-.2-.6-.5-.8l-.6-.4.1-.7c1.1-4.7-2.6-9-7.5-9.1h-.8c-4.9.1-8.6 4.4-7.5 9.1l.1.7-.4.5c-.2.2-.4.5-.5.8-.1.2-.1.5-.1.8 0 .5.1.9.3 1.3.4.9 1.2 1.5 2.2 1.5h.2v.2c.3 1.6 1.1 3 2.4 4l.2.1v2.2s-6.5 2.2-9.5 3.7c-.9.5-1.5 1.3-1.7 2.3-.5 3-.6 8-.6 8h31c-.1-.1-.2-5.1-.7-8z"
      />
      <path
        fill="#34cc65"
        d="M96.9 94v22.7c0 .3-.2.5-.5.5H11.5c-.3 0-.5-.2-.5-.5V11.3c0-.3.2-.5.5-.5h84.9c.3 0 .5.2.5.5V44l7.8-10.6c.2-.2.4-.5.6-.7V11.3c0-4.9-4-8.9-8.9-8.9H11.5c-4.9 0-8.9 4-8.9 8.9v105.4c0 4.9 4 8.9 8.9 8.9h84.9c4.9 0 8.9-4 8.9-8.9V82.9L98 92.7c-.3.5-.7.9-1.1 1.3z"
      />
      <path
        fill="#34cc65"
        d="M66.5 93.8H44.4c-1 0-1.9.8-1.9 1.9 0 1 .8 1.9 1.9 1.9h22.1c1 0 1.9-.8 1.9-1.9 0-1-.8-1.9-1.9-1.9zm0-13H23.4c-1 0-1.9.8-1.9 1.9 0 1 .8 1.9 1.9 1.9h43.1c1 0 1.9-.8 1.9-1.9 0-1.1-.8-1.9-1.9-1.9zm0-13.1H23.4c-1 0-1.9.8-1.9 1.9s.8 1.9 1.9 1.9h43.1c1 0 1.9-.8 1.9-1.9s-.8-1.9-1.9-1.9zm55.3-32.4c-1.9-1.4-4.1-1.9-6.4-1.6-2.3.3-4.3 1.6-5.7 3.4L79.1 78.7c-.2.2-.3.5-.4.8l-3.6 16c-.2.8.1 1.6.8 2.1.3.2.6.3 1 .4.4 0 .9 0 1.3-.3l14.2-8.2c.2-.1.5-.3.6-.6l30.7-41.6c2.8-3.8 2-9.2-1.9-12zM81.4 91.1l-.9-.9 1.5-6.8 5.5 4.1-6.1 3.6z"
      />
    </svg>
  );
};
SvgJobapplication.defaultProps = defaultProps;

export default SvgJobapplication;
