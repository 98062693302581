import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { WebsiteEventRoute, availbleslot, calendar_slotter, eventSchedulerApi, slotterApi } from '../../../../../routes/apiRoutes';
import { EVENT_SCHEDULER_FORM_DETAILS,SLOTTER_FORM_DETAILS } from '../../../../../actions/actions';
import { WebsiteEventPayload } from '../../ScheduleTypes';
import { paramsSerializer } from '../../../../../utility/helpers';


export const getScheduleMiddleWare = createAsyncThunk(
  EVENT_SCHEDULER_FORM_DETAILS,
  async (id : number | undefined, { rejectWithValue }) => {
  try {
    const url = id ? `${eventSchedulerApi}?pk=${id}` : eventSchedulerApi;
    const config = id ? { transformRequest: (_a, headers) => { delete headers.common.Authorization; } } : {};
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    const typedError = error as Error;
    return rejectWithValue(typedError);
  }
});
  
export const postScheduleMiddleWare = createAsyncThunk(
  EVENT_SCHEDULER_FORM_DETAILS,
    async ({ formData }: any, { rejectWithValue }) => {
      try {
        const data = await axios.post(eventSchedulerApi,formData);
        return data;
      } catch (error) {
        const typedError = error as Error;
        return rejectWithValue(typedError);
      }
    },
  );
  
export const deleteScheduleMiddleWare = createAsyncThunk(  
  EVENT_SCHEDULER_FORM_DETAILS,
    async (id : number | undefined ,{rejectWithValue }) => {
      try {
        const url = id ? `${eventSchedulerApi}?pk=${id}` : '';
        const {data} = await axios.delete(url);
        return data;
      } catch (error) {
        const typedError = error as Error;
        return rejectWithValue(typedError);
      }
    },
  );

  export const getSlotterMiddleware = createAsyncThunk(
    SLOTTER_FORM_DETAILS,
    async ({ uid, event_id, selecteddate, selectedtime ,jd_id}: {
      uid: string | undefined,
      event_id ?: any,
      selecteddate?: string | undefined,
      selectedtime?: string | undefined,
      jd_id?: string | undefined
    }, { rejectWithValue }) => {
    try {
      const url = uid && event_id !== undefined && selectedtime !== undefined ? 
      `${slotterApi}/${uid}?event_id=${event_id}&date=${selecteddate}&time=${selectedtime}&jd_id=${jd_id}`: 
       `${slotterApi}/${uid}?event_id=${event_id}&jd_id=${jd_id}`;
      const config = { transformRequest: (_a, headers) => { delete headers.common.Authorization; } } 
      const {data} = await axios.get(url,config);
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  });

  
  export const getAvailbleSlot = createAsyncThunk(
    EVENT_SCHEDULER_FORM_DETAILS,
    async (id : number | undefined, { rejectWithValue }) => { 
    try {
      const url = id ? `${availbleslot}?pk=${id}` : availbleslot;
      const config = id ? { transformRequest: (_a, headers) => { delete headers.common.Authorization; } } : {};
      const {data} = await axios.get(url,config); 
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  });
  

  export const postCalendarSlotterMiddleware = createAsyncThunk(
    EVENT_SCHEDULER_FORM_DETAILS,
      async ({ formData01 }: any, { rejectWithValue }) => {
        try {
          const configs = {
            transformRequest: (_a, headers) => {
              delete headers.common.Authorization;
            },
          };
          const { data } = await axios.post(calendar_slotter,formData01,{
            headers: { Authorization: undefined}}
          );
          return data;
        } catch (error) {
          const typedError = error as Error;
          return rejectWithValue(typedError);
        }
      },
    );


  export const getwebsiteeventmiddleware = createAsyncThunk(
    EVENT_SCHEDULER_FORM_DETAILS,
    async (
      {
        id,
        email,
        website
      }: WebsiteEventPayload,
      { rejectWithValue },) => { 
    try {
      const config = id ? { transformRequest: (_a, headers) => { delete headers.common.Authorization; } } : {};
      const url = id ? `${WebsiteEventRoute}?id=${id}` : WebsiteEventRoute
      const {data} = await axios.get(url,config); 
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  });

  export const postwebsiteeventmiddleware = createAsyncThunk(
    EVENT_SCHEDULER_FORM_DETAILS,
      async ({ formData }: any, { rejectWithValue }) => {
        try {
          const configs = {
            transformRequest: (_a, headers) => {
              delete headers.common.Authorization;
            },
          };
          const {data } = await axios.post(WebsiteEventRoute,formData,{
            headers: { Authorization: undefined}}
          );
          return data;
        } catch (error) {
          const typedError = error as Error;
          return rejectWithValue(typedError);
        }
      },
    );
