import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  jobSelect,
  reports,
  homeRoute,
  jobCreateNonDs,
} from '../../../appRoutesPath';
import SvgSearch from '../../../icons/SvgSearch';
import { routesPath } from '../../../routes/routesPath';
import { RootState, AppDispatch } from '../../../store';
import { WHITE } from '../../../uikit/Colors/colors';
import InputSearch from '../../../uikit/InputSearch/InputSearch';
import { permissionMiddleWare } from '../../Login/store/middleware/loginMiddleWare';
import InputSearchnav from '../../../uikit/InputSearch/inputsearchnav';

import { searchOptions } from './mock';
import styles from './navbar.module.css';

type Props = {
  onButtonClick: () => void;
  onbuttonchange: () => void;
};
const NavigationSearch = ({ onButtonClick, onbuttonchange }) => {
  const dispatch: AppDispatch = useDispatch();
  const [isPermission, setPermission] = useState([]);
  const [isPlan_id, setPlan_id] = useState();
  const history = useHistory();
  const [initial, setinital] = useState('');
  const formik = useFormik({
    initialValues: { value: '' },
    onSubmit: () => {},
  });
  const { permission, super_user, plan_id, roles } = useSelector(
    ({ permissionReducers }: RootState) => {
      return {
        permission: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        plan_id: permissionReducers.plan_id,
        roles: permissionReducers.roles,
      };
    },
  );

  // search redirection condition
  const searchNavigate = (value: string) => {
    switch (value.toLocaleLowerCase()) {
      //dashboard
      case 'dashboard':
        return history.push(homeRoute);
      case 'home':
        return history.push(homeRoute);

      //Analytics
      case 'reports':
        return history.push(reports);
      case 'analytics':
        return history.push(reports);

      //Post New Job:
      case 'post job':
        return (
          history.push(jobCreateNonDs),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'create job':
        return (
          history.push(jobCreateNonDs),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'post new jobs':
        return (
          history.push(jobCreateNonDs),
          sessionStorage.setItem('JobsDropdown', '1')
        );

      //Hiring Workflow:
      case 'workflow':
        return (
          history.push('/account_setting/hiring_workflow'),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'workflow settings':
        return (
          history.push('/account_setting/hiring_workflow'),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'hiring workflow':
        return (
          history.push('/account_setting/hiring_workflow'),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'customize workflow':
        return (
          history.push('/account_setting/hiring_workflow'),
          sessionStorage.setItem('JobsDropdown', '1')
        );
      case 'customize hiring board':
        return (
          history.push('/account_setting/hiring_workflow'),
          sessionStorage.setItem('JobsDropdown', '1')
        );

      //job list
      case 'applicants pipeline':
        return (
          history.push(routesPath.MY_JOB_POSTING),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );
      case 'job postings':
        return (
          history.push(routesPath.MY_JOB_POSTING),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );
      case 'job listings':
        return (
          history.push(routesPath.MY_JOB_POSTING),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );
      case 'job lists':
        return (
          history.push(routesPath.MY_JOB_POSTING),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );
      case 'view jobs':
        return (
          history.push(routesPath.MY_JOB_POSTING),
          sessionStorage.setItem('ViewJobsDropdown', '1')
        );

      //Passive Candidates Database: part 1
      case 'applicants importing':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'bulk import':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'bulk upload':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'resume upload':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'resume importing':
        return (
          history.push(routesPath.BULK_IMPORT),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'bulk import candidates':
        return history.push(routesPath.BULK_IMPORT);

      case 'upload candidates':
        return history.push(routesPath.BULK_IMPORT);

      case 'add candidates':
        return history.push(routesPath.BULK_IMPORT);

      //part 2
      case 'upload applicants':
        return (
          history.push(`/bulk_import?tab=1`),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'add applicants':
        return (
          history.push('/bulk_import?tab=1'),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'bulk import applicants':
        return (
          history.push('/bulk_import?tab=1'),
          sessionStorage.setItem('CandidateDropdown', '1')
        );

      //Source Candidates:
      case 'talent sourcing':
        return (
          history.push('/candidates_sourcing?tab=0'),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'source candidates':
        return (
          history.push('/candidates_sourcing?tab=0'),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'sourcing':
        return (
          history.push('/candidates_sourcing?tab=0'),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'candidates importing':
        return (
          history.push('/candidates_sourcing?tab=0'),
          sessionStorage.setItem('CandidateDropdown', '1')
        );

      //All Candidates:
      case 'database':
        return (
          history.push(routesPath.MYDATABASE),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'candidates':
        return (
          history.push(routesPath.MYDATABASE),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'invite candidates':
        return (
          history.push(routesPath.MYDATABASE),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'match candidate':
        return (
          history.push(routesPath.MYDATABASE),
          sessionStorage.setItem('CandidateDropdown', '1')
        );
      case 'all candidates':
        return (
          history.push(routesPath.MYDATABASE),
          sessionStorage.setItem('CandidateDropdown', '1')
        );

      //Event Scheduler:
      case 'event scheduler':
        return (
          history.push('/event_scheduler'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );
      case 'slotter':
        return (
          history.push('/event_scheduler'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );

      //Integrations:
      case 'calendar integration':
        return (
          history.push('/account_setting/integration'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );
      case 'integrations':
        return (
          history.push('/account_setting/integration'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );
      case 'email integration':
        return (
          history.push('/account_setting/integration'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );
      case 'mail integration':
        return (
          history.push('/account_setting/integration'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );

      //Calendar:
      case 'calendar':
        return (
          history.push('/calendar'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );
      case 'events':
        return (
          history.push('/calendar'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );
      case 'agenda':
        return (
          history.push('/calendar'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );
      case 'meetings':
        return (
          history.push('/calendar'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );

      //mail
      case 'email':
        return (
          history.push('/mail'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );
      case 'inbox':
        return (
          history.push('/mail'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );
      case 'mailbox':
        return (
          history.push('/mail'),
          sessionStorage.setItem('CommunicationDropdown', '1')
        );

      //Build Careers Page:
      case 'build careers page':
        return (
          history.push('/account_setting/build_careers_page'),
          sessionStorage.setItem('BrandDropdown', '1')
        );
      case 'careers page':
        return (
          history.push('/account_setting/build_careers_page'),
          sessionStorage.setItem('BrandDropdown', '1')
        );
      case 'setup careers page':
        return (
          history.push('/account_setting/build_careers_page'),
          sessionStorage.setItem('BrandDropdown', '1')
        );

      //Profiles
      case 'user profile':
        return (
          // super_user === true &&
          history.push('/account_setting/profiles'),
          sessionStorage.setItem('MyAccountDropdown', '1'),
          permission.includes('manage_account_settings')
        );
      case 'profile update':
        sessionStorage.setItem('MyAccountDropdown', '1');
        history.push('/account_setting/profiles');
        break;
      case 'profiles':
        sessionStorage.setItem('MyAccountDropdown', '1');
        history.push('/account_setting/profiles');
        break;
      case 'company profile':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/profiles');

      //Subscription & Billing:
      case 'manage subscription':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');
      case 'contact credits':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');
      case 'billing':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');
      case 'pricing':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');
      case 'plans':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');
      case 'subscription':
        sessionStorage.setItem('MyAccountDropdown', '1');
        return history.push('/account_setting/subscription');

      //Manage Users:
      case 'manage users':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting/manage_user')
        );
      case 'invite user':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting/manage_user')
        );
      case 'invite team':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting/manage_user')
        );
      case 'add team':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting/manage_user')
        );

      //Support Form:
      case 'support':
      case 'help':
      case 'feedback':
      case 'support form':
        return window.open(
          'https://share.hsforms.com/1_3IRNtXMTI6Xa3AGdP_pVwqmqrf',
        );

      //Change Password:
      case 'password change':
        onButtonClick();
        break;
      case 'update password':
        onButtonClick();
        break;
      case 'change password':
        onButtonClick();
        break;

      //Account Settings
      case 'account settings':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting')
        );
      case 'settings':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting')
        );

      //Templates
      case 'templates':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting/templates_hub')
        );
      case 'templates hub':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting/templates_hub')
        );
      case 'email templates':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting/templates_hub')
        );
      case 'jd templates':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/account_setting/templates_hub')
        );

      //Jobs Workflow:
      case 'jobs workflow':
        return (
          sessionStorage.setItem('MyAccountDropdown', '1'),
          history.push('/jobs_workflow')
        );
    }
  };

  useEffect(() => {
    if (formik.values.value !== '') {
      var a = formik.values.value;
      setinital(a.replace(/\b[a-z]/g, (match) => match.toUpperCase()));
      searchNavigate(a.toLocaleUpperCase());
    }
  }, [formik.values.value]);

  const optionsArray: any = searchOptions;

  useEffect(() => {
    // Dispatch the permission middleware action when the component mounts
    dispatch(permissionMiddleWare())
      .then((res) => {
        setPermission(res.payload.Permission);
        setPlan_id(res.payload.plan_id);
      })
      .catch((error) => {
        // Handle errors if the middleware fails
      });
  }, []);

  // search list condition
  useEffect(() => {
    optionsArray.push(
      'Dashboard',
      'Home',

      'Workflow Settings',
      'Workflow',
      'Hiring Workflow',
      'Customize Workflow',
      'Customize Hiring Board',

      'Applicants Pipeline',
      'Job Postings',
      'Job Listings',
      'Job Lists',
      'View Jobs',

      'Database',
      'Candidates',
      'Invite Candidates',
      'Match Candidate',
      'All Candidates',

      'Event Scheduler',
      'Slotter',

      'Integrations',
      'Email Integration',
      'Mail Integration',
      'Calendar Integration',

      'Calendar',
      'Events',
      'Agenda',
      'Meetings',

      'Email',
      'Inbox',
      'Mailbox',

      'Profile Update',
      'User Profile',
      'Profiles',

      'Support',
      'Help',
      'Feedback',
      'Support Form',

      'Password Change',
      'Update Password',
      'Change Password',

      'Account Settings',
      'Settings',

      'Templates',
      'Templates Hub',
      'Email Templates',
      'JD Templates',

      'Jobs Workflow',
    );
    if (super_user === true) {
      optionsArray.push(
        'Manage Subscription',
        'Billing',
        'Contact Credits',
        'Pricing',
        'Plans',
        'Subscription',
        'Manage Users',
        'Invite User',
        'Invite Team',
        'Add Team',
      );
    }
    if (isPermission?.includes('reports')) {
      if (isPlan_id && isPlan_id !== 1) {
        optionsArray.push('Reports');
        optionsArray.push('Analytics');
      }
    }
    if (isPermission?.includes('my_database')) {
      optionsArray.push(
        'Database',
        'Candidates',
        'Invite Candidates',
        'Match Candidate',
        'All Candidates',
      );
    }
    if (isPermission?.includes('talent_sourcing')) {
      optionsArray.push(
        'Talent Sourcing',
        'Candidates Importing',
        'Sourcing',
        'Source Candidates',
      );
    }
    if (isPermission?.includes('bulkImport_candidates')) {
      optionsArray.push(
        'Bulk Import Candidates',
        'Upload Candidates',
        'Applicants Importing',
        'Upload Applicants',
        'Bulk Import',
        'Bulk Upload',
        'Resume Upload',
        'Resume Importing',
        'Add Candidates',
        'Add Applicants',
        'Upload Applicants',
        'Bulk Import Applicants',
      );
    }
    if (isPermission?.includes('create_post')) {
      optionsArray.push('Create Job', 'Post Job', 'Post New Jobs');
    }
    if (isPermission?.includes('manage_account_settings')) {
      optionsArray.push(
        'Careers Page',
        'Setup Careers Page',
        'Build Careers Page',
        'Company Profile',
      );
    }
  }, [searchOptions, plan_id, super_user, permission, isPermission]);

  const toFindDuplicates = (arry: string[]) =>
    arry.filter((item, index) => arry.indexOf(item) === index);
  const duplicateElementa = toFindDuplicates(optionsArray);

  const value = (e) => {
    console.log('2222222', e);
  };
  return (
    <div style={{ position: 'relative', width: '195px' }}>
      <div style={{ position: 'absolute', zIndex: 11, top: 3, left: 10 }}>
        <SvgSearch fill={'#581845'} />
      </div>

      <InputSearchnav
        style={styles.searchStyle}
        placeholder="Enter your search here..."
        options={duplicateElementa}
        initialValue={initial}
        onKeyDown={value}
        setFieldValue={formik.setFieldValue}
        name="value"
        onkeyPress={(event) => {
          if (event.key === 'Enter') {
            searchNavigate(event.target.value);
            formik.setFieldValue('value', event.target.value);
          }
        }}
      />
    </div>
  );
};

export default NavigationSearch;
