const SvgSearchGlass = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={106}
      height={106}
      fill="none"
      viewBox="0 0 106 106"
      {...props}
    >
      <path
        fill="#E1DFDD"
        d="M10.875 76.905a8.394 8.394 0 0 1 8.354 7.585c.442-.056.893-.085 1.351-.085 5.886 0 10.658 4.772 10.658 10.657 
        0 5.886-4.772 10.658-10.658 10.658-5.886 0-10.657-4.772-10.657-10.657 0-.48.031-.953.093-1.416a8.394 8.394 0
         0 1 .859-16.742ZM13.08 0c7.224 0 13.08 5.856 13.08 13.08s-5.856 13.08-13.08 13.08S0 20.304 0 13.08 5.856 
         0 13.08 0Zm81.375 2.842c5.886 0 10.658 4.772 10.658 10.658 0 5.886-4.772 10.657-10.658 10.657-5.886 0-10.657-4.771-10.657-10.657S88.569 2.842 94.454 2.842Z"
      />
      <path
        fill="#D7C7D2"
        d="M40.748 66.6c14.663 0 26.55-11.887 26.55-26.55S55.41 13.5 40.748 13.5c-14.664 0-26.55 11.887-26.55 26.55S26.083 66.6 40.748 66.6Z"
      />
      <path
        fill="#A5889C"
        d="M15.375 32.228A13.057 13.057 0 0 0 32.7 14.745a26.595 26.595 0 0 0-17.325 17.483Z"
      />
      <path
        fill="#581845"
        d="M69.743 23.01C60.092 7.074 39.353 1.972 23.41 11.612 7.468 21.252 2.352 41.987 11.981 57.936c9.63 15.95 
        30.36 21.08 46.316 11.461A33.75 33.75 0 0 0 69.743 23.01Zm-16.56 37.875c-11.255 6.794-25.885 
        3.194-32.7-8.048-6.75-11.26-3.158-25.858 8.047-32.7a23.79 23.79 0 0 1 32.7 8.048c6.755 11.26 3.161 25.86-8.047 32.7Z"
      />
      <path
        fill="#A5889C"
        d="m62.43 55.392 23.587 23.431-6.55 6.593L55.88 61.984l6.55-6.592Z"
      />
      <path
        fill="#581845"
        d="m77.441 68.04 20.714 20.577a6.247 6.247 0 1 1-8.806 8.864L68.635 76.904l8.806-8.864ZM72.688 65.599l1.06 
        1.06-6.57 6.571-1.061-1.06 6.57-6.571Zm-2.81-2.818 1.06 1.06-6.57 6.571-1.06-1.06 6.57-6.571Z"
      />
      <path
        fill="#fff"
        d="M58.027 38.347a2.865 2.865 0 1 1 0 5.73 2.865 2.865 0 0 1 0-5.73ZM53.04 27.48a4.087 4.087 0 1 1 0 8.175 4.087 4.087 0 0 1 0-8.175Z"
      />
      <path
        fill="#C8C6C4"
        d="M10.028 93.645c.277 0 .562.038.847.038a8.392 8.392 0 0 0 8.423-8.386c0-.265-.015-.53-.045-.795a10.65 10.65 0 0 0-9.225 9.143Z"
      />
    </svg>
  );
  export default SvgSearchGlass;