import { Dropdown } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames, { Value } from 'classnames/bind';
import _ from 'lodash';
import SvgSetting from '../../icons/SvgSetting';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import { Button, Loader, Toast } from '../../uikit';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import SvgEditStages from '../../icons/SvgEditStages';
import { AppDispatch, RootState } from '../../store';

import {
  // addJobPipelineStageMiddleWare,
  // deleteJobPipelineStageMiddleWare,
  // updateJobPipelineStageMiddleWare,
  getTemplateDataMiddleWare,
  // reorderJobPipelineStageMiddleWare,
} from '../../modules/accountsettingsmodule/templatesmodule/store/middleware/templatesmiddleware';
import Totalcount from '../../globulization/TotalCount';
import SvgFavourites from '../../icons/SvgFavourties';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import SvgHeart from '../../icons/SvgHeart';
import SvgMove from '../../icons/SvgMove';
import SvgDownload from '../../icons/SvgDownload';
import SvgComparative from '../../icons/Svgcomparative';
import SvgCsvDownload from '../../icons/SvgCsvDownload';
import SvgArtificialInt from '../../icons/SvgArtificialInt';
import SvgParsing from '../../icons/SvgParsing';
import SvgAiMatching from '../../icons/SvgAImatching';
import SvgAutomate from '../../icons/SvgAutomate';
import useUnsavedChangesWarning from '../common/useUnsavedChangesWarning';
import styles from './totalapplicant.module.css';
import MovePipelinePopup from './movepopup';
import { columnTypes } from './dndBoardTypes';
import EditStagesModal from './EditStages';
import ApplicantEmailAutomation from './ApplicantEmailAutomation';
import {
  createemailcontentpostMiddleWare,
  emailAutomationMiddleware,
  emailcontentpostMiddleWare,
  getKanbanStagesMiddleWare,
} from './store/middleware/applicantpipelinemiddleware';

const cx = classNames.bind(styles);

type Props = {
  jd_id: number;
  columns: columnTypes;
  total: number;
  filterTotalFav: () => void;
  isTotalFav: boolean;
  seletedCardsLength: number;
  moveDisabled: boolean;
  onExport?: () => void;
  onMove?: (stageId: number) => void;
  onCSVDownload?: () => void;
  onComparative?: any;
  Matching?: any;
  current_resume_count?: number;
  permission: any;
  current_plan?: number;
  comparative?: boolean | null;
  plan_details?: number[];
  open_subscription?: (a: any, b: any) => void;
  user_credits?: any;
  Stageid?: (val: any) => void;
  column_restriction?: () => void;
  setemailtemplate?: (val: any) => void;
  check_move?: any;
  showAutomatePopup?: any;
  setShowAutomatePopup?: any;
  job_details: any;
};

const TotalApplicant = ({
  jd_id,
  columns,
  total,
  filterTotalFav,
  isTotalFav,
  seletedCardsLength,
  moveDisabled,
  onExport,
  onMove,
  onCSVDownload,
  onComparative,
  Matching,
  current_resume_count,
  permission,
  current_plan,
  comparative,
  plan_details,
  open_subscription,
  user_credits,
  Stageid,
  setemailtemplate,
  column_restriction,
  showAutomatePopup,
  setShowAutomatePopup,
  check_move,
  job_details,
}: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [movePopup, setMovePopup] = useState(false);
  const [initialToggleStates, setInitialToggleStates] = useState({});
  const [isLoader, setLoader] = useState(false);


  const dispatch: AppDispatch = useDispatch();

  const {
    stages,
    isLoading,
    updateLoading,
    new_stages,
    default_stage,
    template,
  } = useSelector(
    ({ kanbanStagesReducers, EmailAutomationReducers }: RootState) => ({
      isLoading: kanbanStagesReducers.isLoading,
      stages: kanbanStagesReducers.stages,
      updateLoading: kanbanStagesReducers.update.isLoading,
      template: EmailAutomationReducers.template,
      default_stage: kanbanStagesReducers.default_stage,
      new_stages: kanbanStagesReducers.new_stages,
      // suggestions: kanbanStagesReducers.suggestion,
    }),
  );

  const handleOpenPopup = () => {
    setShowPopup(true);
  };
  const handleMoveOpenPipeline = () => {
    if (seletedCardsLength >= 2) {
      column_restriction();
      setMovePopup(true);
    }
  };
  const handleMoveClosePipeline = () => {
    setMovePopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleautomateOpen = () => {
    setLoader(true);
    setShowAutomatePopup(true);
    dispatch(emailAutomationMiddleware({ jd_id: jd_id.toString() }));
    dispatch(getKanbanStagesMiddleWare({ jd_id: jd_id })).then(()=>{
      setLoader(false);
    });


  };

  useEffect(() => {
    dispatch(getTemplateDataMiddleWare());
  }, []);

  useEffect(() => {
    dispatch(emailAutomationMiddleware({ jd_id: jd_id.toString() }));
  }, []);

  const clearTab = () => {
    sessionStorage.setItem('superUserTab', '7');
    sessionStorage.setItem('template', '1');
    sessionStorage.setItem('pipeline', '1');
    sessionStorage.setItem('button', '0');
  };

  const subscription_verification = () => {
    if (permission.includes('AI Comparative Analysis') === true) {
      return (
        <Flex
          row
          center
          style={{
            paddingLeft: '5px',
            borderLeft: '1px solid #581845',
            cursor:
              seletedCardsLength > 5 || seletedCardsLength <= 1
                ? 'default'
                : 'pointer',
          }}
          onClick={
            seletedCardsLength > 5 || seletedCardsLength <= 1
              ? ''
              : onComparative
          }
          title={
            seletedCardsLength > 5 || seletedCardsLength <= 1
              ? `Choose 2-5 applicants to compare with the 'Compare Analysis' option`
              : ' Compare Applicants'
          }
        >
          <SvgComparative />
          <Text bold style={{ marginLeft: '10px' }} color={'theme'}>
            Compare Applicants
          </Text>
        </Flex>
      );
    } else {
      if (plan_details.includes(current_plan) === true) {
        if (comparative === true) {
          return (
            <Flex
              row
              center
              style={{
                paddingLeft: '5px',
                borderLeft: '1px solid #581845',
                cursor:
                  seletedCardsLength > 5 || seletedCardsLength <= 1
                    ? 'default'
                    : 'pointer',
              }}
              onClick={
                seletedCardsLength > 5 || seletedCardsLength <= 1
                  ? ''
                  : onComparative
              }
              title={
                seletedCardsLength > 5 || seletedCardsLength <= 1
                  ? `Choose 2-5 applicants to compare with the 'Compare Analysis' option`
                  : ' Comparative Analysis'
              }
            >
              <SvgComparative />
              <Text bold style={{ marginLeft: '5px' }} color={'theme'}>
                Compare Applicants
              </Text>
            </Flex>
          );
        } else {
          return (
            <Flex
              row
              center
              style={{
                paddingLeft: '5px',
                borderLeft: '1px solid #581845',
                cursor:
                  seletedCardsLength > 5 || seletedCardsLength <= 1
                    ? 'default'
                    : 'pointer',
              }}
              onClick={
                seletedCardsLength > 5 || seletedCardsLength <= 1
                  ? () => {}
                  : () => open_subscription(true, 1)
              }
              title={
                seletedCardsLength > 5 || seletedCardsLength <= 1
                  ? `Choose 2-5 applicants to compare with the 'Compare Analysis' option`
                  : ' Comparative Analysis'
              }
            >
              <SvgComparative />
              <Text bold style={{ marginLeft: '5px' }} color={'theme'}>
                Compare Applicants
              </Text>
              <Flex style={{ padding: '0px 0px 3px 5px' }}>
                <SvgSubcriptioncrown />
              </Flex>
            </Flex>
          );
        }
      }
    }
  };

  return (
    <>
      {column_restriction()}
      <Flex row center between className={styles.overAll}>
        <Flex row>
          <Flex marginRight={5}>
            <Text>
              Total Applicants: <Text bold>{total}</Text>
            </Text>
          </Flex>

          <Flex marginLeft={10} row center>
            {/* <Totalcount
              name="AI Resume Parsing Credits"
              numbers={current_resume_count}
            /> */}
            <Flex title="AI Resume Parsing Credits">
              <Flex>
                <SvgParsing width={20} height={20} />
              </Flex>
            </Flex>
            <Flex marginLeft={2}>
              <Text bold>: {current_resume_count}</Text>
            </Flex>
          </Flex>

          <Flex marginLeft={10} row center>
            {/* <Totalcount
            name="AI Matching Credits"
            numbers={user_credits?.ai_match_count}
          /> */}
            <Flex title="AI Matching with Descriptive Analytics Credits.">
              <SvgAiMatching width={20} height={20} />
            </Flex>
            <Flex marginLeft={2}>
              <Text bold>: {user_credits?.ai_match_count}</Text>
            </Flex>
          </Flex>
        </Flex>

        {/* {seletedCardsLength > 1 && ( */}
        <Flex row center>
          <Flex row center className={styles.bulkSelection}>
            <Flex row>
              {seletedCardsLength > 0 ? (
                <Flex row center marginRight={10}>
                  <Text bold color={'theme'}>
                    Selected{' '}
                    <Text bold color={'theme'}>
                      {seletedCardsLength}
                    </Text>{' '}
                    Applicants
                  </Text>
                </Flex>
              ) : (
                ''
              )}

              <Flex
                row
                title={
                  seletedCardsLength >= 2
                    ? 'Move'
                    : 'Select at least 2 applicants to move to other stages.'
                }
                center
                marginRight={10}
                style={{
                  cursor: seletedCardsLength >= 2 ? 'pointer' : 'default',
                  paddingLeft: seletedCardsLength > 0 ? '5px' : '0px',
                  borderLeft:
                    seletedCardsLength > 0 ? '1px solid #581845' : 'none',
                }}
                onClick={handleMoveOpenPipeline}
              >
                <SvgMove width={12} height={12} />
                <Text bold style={{ marginLeft: '10px' }} color={'theme'}>
                  Move
                </Text>
              </Flex>
              <Flex
                row
                center
                title={
                  seletedCardsLength >= 2
                    ? 'Export Resumes'
                    : 'Select at least 2 applicants to export resumes.'
                }
                style={{
                  paddingLeft: '5px',
                  borderLeft: '1px solid #581845',

                  cursor: seletedCardsLength >= 2 ? 'pointer' : 'default',
                }}
                onClick={onExport}
                marginRight={10}
              >
                <SvgDownload width={14} height={14} />
                <Text bold style={{ marginLeft: '10px' }} color={'theme'}>
                  Export Resumes
                </Text>
              </Flex>

              {subscription_verification()}
            </Flex>
          </Flex>
        </Flex>
        {/* )} */}
        <MovePipelinePopup
          openMovePopup={movePopup}
          handleClosePipelinePopup={handleMoveClosePipeline}
          onMove={(id) => {
            onMove(id);
            handleMoveClosePipeline();
          }}
          Stageid={Stageid}
          check_move={check_move}
        />

        <Flex row center marginRight={10} style={{ alignItems: 'center' }}>
          <Button
            className={styles.btnStyle}
            types="primary"
            onClick={filterTotalFav}
          >
            <Flex row center style={{ cursor: 'pointer' }}>
              <SvgHeart width={13} height={12} filled={isTotalFav} />
              <Text
                style={{ marginLeft: '5px' }}
                color="theme"
                title={'Favourite Applicants'}
              >
                Favourites
              </Text>
            </Flex>
          </Button>
          <Dropdown className="dropdownButton dropleft">
            <CustomTooltip title="Settings">
              <Dropdown.Toggle
                // onClick={handleOpenPopup}
                style={{
                  borderColor: 'unset',
                  backgroundColor: 'unset',
                  boxShadow: 'none',
                  padding: '0px',
                  marginRight: '5px',
                }}
                id="dropdown-basic"
              >
                {/* <SvgEditStages height={16} width={16} /> */}
                <SvgSetting width={16} height={16} fill="#581845" />
              </Dropdown.Toggle>
            </CustomTooltip>

            <Dropdown.Menu style={{ minWidth: '5rem' }}>
              <Dropdown.Item onClick={handleOpenPopup}>
                <Flex row center className={styles.dropDownListStyle}>
                  <SvgEditStages height={16} width={16} fill="#333333" />
                  <Text style={{ marginLeft: 10 }}>Edit Stages</Text>
                </Flex>
              </Dropdown.Item>

              <Dropdown.Item onClick={onCSVDownload}>
                <Flex row center className={styles.dropDownListStyle}>
                  <SvgCsvDownload height={16} width={16} fill={'#1a1a1a'} />
                  <Text style={{ marginLeft: 10 }}>Download CSV</Text>
                </Flex>
              </Dropdown.Item>

              <Dropdown.Item onClick={handleautomateOpen}>
                <Flex row center className={styles.dropDownListStyle}>
                  <SvgAutomate />
                  <Text style={{ marginLeft: 10 }}>Automations</Text>
                </Flex>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <Button className={styles.btn1Style} types="primary">
            <SvgList width={16} height={16} fill="#581845" />
          </Button> */}
        </Flex>
      </Flex>
      <ApplicantEmailAutomation
        open={showAutomatePopup}
        stages={stages}
        setShowAutomatePopup={setShowAutomatePopup}
        jd_id={jd_id}
        default_stage={default_stage}
        setInitialToggleStates={setInitialToggleStates}
        job_details={job_details}
        isLoader={isLoader}
        setLoader={setLoader}
      />

      <EditStagesModal
        open={showPopup}
        jd_id={jd_id}
        stages={stages}
        columns={columns}
        handleClosePopup={handleClosePopup}
      />
      {updateLoading && <Loader />}
    </>
  );
};
// const ActionsButton = ({ onEditStages, onEditPipeline }) => {
//   return (
//     <>

//     </>
//   );
// };
export default TotalApplicant;
