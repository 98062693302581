
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { RootState } from '../../../store';
import SvgRoundClose from '../../../icons/SvgRoundClose';
import SvgTick from '../../../icons/SvgTick';
import { ERROR, SUCCESS } from '../../../uikit/Colors/colors';
import Flex from '../../../uikit/Flex/Flex';
import Text from '../../../uikit/Text/Text';
import Button from '../../../uikit/Button/Button';
import SvgSvgCollapsePlus from '../../../icons/SvgCollapsePlus';
import SvgSvgCollapseMinus from '../../../icons/SvgCollapseMinus';
import styles from './DetailedFeatureswebsite.module.css';




type Props = {
    isCompare: boolean;
    setCompare: (any) => void;
};
const DetailedFeaturesWebsite = (
    {
        isCompare,
        setCompare,
    }: Props
) => {

    const {  web_content_01, web_content_02 } =
useSelector(({ manageSubscriptionwebsiteReducers}: RootState) => ({
    web_content_01:manageSubscriptionwebsiteReducers.web_content_01,
    web_content_02:manageSubscriptionwebsiteReducers.web_content_02,
}));

    const [selectedItemIndices, setSelectedItemIndices] = useState([]);
    const ismobile = useMediaQuery({ query: '(max-width: 400px)' });
    const handleTitleClick = (index) => {
        if (selectedItemIndices.includes(index)) {
            setSelectedItemIndices((prevIndices) =>
                prevIndices.filter((prevIndex) => prevIndex !== index)
            );
        } else {
            setSelectedItemIndices((prevIndices) => [...prevIndices, index]);
        }
    };



    // useEffect(() => {
    //     if (isCompare) {
    //         setSelectedItemIndices(Array.from({ length: products.length }, (_, i) => i));
    //     } else {
    //         // If isCompare is false, include all indices
    //         setSelectedItemIndices([]);
    //     }
    // }, [isCompare]);

    // useEffect(() => {
    //     // Check if selectedItemIndices contains all indices
    //     const isEmpty = selectedItemIndices.length === 0;
    //     if(isEmpty){
    //     setCompare(!isEmpty);}
    //     const containsAllIndices = selectedItemIndices.length === products.length;
    //     if(containsAllIndices){
    //     setCompare(containsAllIndices);}
    // }, [selectedItemIndices]);

    

    return (
        <Flex columnFlex between marginBottom={20} >
            <Flex marginTop={40} marginBottom={10}>
             <Text size={16} bold className={styles.heading} align='center' > Comparison features by plans</Text></Flex>
            <Flex className={styles.sticky} marginTop={10}>
                {/* <Text size={14} bold> Comparison features by plans</Text> */}
         
            <Flex row className={styles.background}>
        
                <Flex flex={6}></Flex>
                {web_content_01 && web_content_01.length > 0 &&
                    web_content_01.map((plan, ind) => (

                        <Flex columnFlex center key={ind} flex={2}>
                            <Flex center className={styles.makecenter}>
                                <Text bold className={styles.subheading}>{plan.name}</Text>
                                <Text className={styles.subheading}>{plan.price}</Text>
                            </Flex>

                 </Flex>))}
            </Flex> 
             </Flex>


            {web_content_02 && web_content_02.length > 0 &&
                web_content_02.map((product, index) => (

                    <Flex key={index} >
                        <Flex center between className={styles.singleTile} >
                            <Text style={{ marginLeft: 8}} bold className={styles.subheading}>
                                {product.title}
                            </Text>
                            {/* <Button
                            onClick={() => handleTitleClick(index)}
                            types="link"
                        >
                            
                            <Flex className={styles.alignitem}>
                                {selectedItemIndices.includes(index) ? (
                                    <Text
                                        bold
                                        color="link"
                                        style={{ marginRight: 5, marginLeft: 5 }}
                                    >
                                        <SvgSvgCollapseMinus></SvgSvgCollapseMinus>
                                    </Text>
                                ) : (
                                    <Text bold color="link" style={{ marginRight: 5, marginLeft: 5 }}>
                                        <SvgSvgCollapsePlus></SvgSvgCollapsePlus>
                                    </Text>
                                )}</Flex>

                        </Button> */}
                        </Flex>

                        {
                            // selectedItemIndices.includes(index) && 
                            <>
                                {/* <Flex row>
                        <Flex flex={6}></Flex>
                    {product.plan.length>0 &&
                    product.plan.map((plan,ind)=>(
                        
                        <Flex columnFlex  center key={ind} flex={2}>
                            <Flex  center className={styles.makecenter}>
                                <Text>{plan.name}</Text>
                                <Text>{plan.price}</Text>
                            </Flex>
                          
                        </Flex>))}</Flex> */}

                                {product.features.map((pricing, i) => (

                                    <Flex row center className={styles.borderBottom} key={i}>
                                        <Flex flex={6} className={styles.subheading}>
                                            {pricing.titlepricing}
                                        </Flex>
                                        <Flex columnFlex center flex={2}>
                                            {
                                                pricing.freetrial === 'Yes'||pricing.freetrial==='Include' ? (<SvgTick fill={SUCCESS} />) : (
                                                    pricing.freetrial === 'No' ? (<SvgRoundClose fill={ERROR} height={16} width={16} />) : (
                                                        <Text className={styles.subheading}>{pricing.freetrial}</Text>
                                                    )
                                                )
                                            }

                                        </Flex>
                                        <Flex columnFlex center flex={2}>
                                            {
                                                pricing.standerd === 'Yes'||pricing.standerd==='Include' ? (<SvgTick fill={SUCCESS} />) : (
                                                    pricing.standerd === 'No' ? (<SvgRoundClose fill={ERROR} height={16} width={16} />) : (
                                                        <Text className={styles.subheading}>{pricing.standerd}</Text>
                                                    )
                                                )
                                            }
                                        </Flex>
                                        <Flex columnFlex center flex={2}>
                                            {
                                                pricing.premium === 'Yes' ||pricing.premium==='Include'? (<SvgTick fill={SUCCESS} />) : (
                                                    pricing.premium === 'No' ? (<SvgRoundClose fill={ERROR} height={16} width={16} />) : (
                                                        <Text className={styles.subheading}>{pricing.premium}</Text>
                                                    )
                                                )
                                            }
                                        </Flex>
                                        <Flex columnFlex center flex={2}>
                                            {
                                                pricing.enterprice === 'Yes'||pricing.enterprice==='Include' ? (<SvgTick fill={SUCCESS} />) : (
                                                    pricing.enterprice === 'No' ? (<SvgRoundClose fill={ERROR} height={16} width={16} />) : (
                                                        <Text className={styles.subheading}>{pricing.enterprice}</Text>
                                                    )
                                                )
                                            }
                                        </Flex>
                                    </Flex>
                                ))}

                            </>}

                    </Flex>

                ))}



        </Flex>
    );
};

export default DetailedFeaturesWebsite;
