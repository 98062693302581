import 'moment-timezone';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';

import { Modal, CrossButton } from '../../uikit/v2';
import { AppDispatch } from '../../store';
import { level } from '../myjobposting/mock';
import {
  Flex,
  Text,
  Button,
  CheckBox,
  InputCheckBox,
  InputRadio,
  InputText,
  ErrorMessage,
  Loader,
  Toast,
} from '../../uikit';
import { toCamelCase } from '../../uikit/helper';
import Tab from '../../uikit/Tab/Tab';
import Tabs from '../../uikit/Tab/Tabs';
import { userProfileMiddleWare } from '../accountsettingsmodule/userprofilemodule/store/middleware/userprofilemiddleware';
import SvgCross from '../../icons/SvgCross';
import SvgClose from '../../icons/SvgClose';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { THIS_FIELD_REQUIRED } from '../constValue';
import useUnsavedChangesWarning from '../common/useUnsavedChangesWarning';
import { isEmpty } from '../../uikit/helper';
import { backendProcessMiddleWare, creditsPurchaseMiddleWare } from '../ordersummarymodule/store/ordersummarymiddleware';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import { permissionMiddleWare } from '../Login/store/middleware/loginMiddleWare';
import { manageSubscriptionMiddleWare } from '../accountsettingsmodule/managesubscription/store/managesubscriptionmiddleware';
import {
  CALENDAR,
  meetingFormProps,
  TeamMemberType,
  SlotRangeType,
  EditEventDetails,
  EventMeetingType,
  CalendarEventType,
  UserType,
  ApplicantTypes,
  UserInfo,
} from './types';
import './styles/addInterviewers.css';
import { QuestionListModel } from './QuestionlistModel';
import MeetingSchedulingForm from './MeetingSchedulingForm';
import MeetingSummary from './MeetingSummary';
import styles from './styles/createScheduleForm.module.css';
import { getDateFromDateTime, meetingFormInitialState } from './util';
import { Interview_question_middleware } from './store/middleware/calendarmiddleware';
import { LevelValue, MyFormValues1, levellist, Errorshow } from './Questiontype';


interface Props {
  username?: string;
  cand_id?: number;
  APPLY?: Boolean;
  update?: boolean;
  cand_name?: string;
  cand_email?: string;
  jd_id?: number;
  jd_name?: string;
  EventId?: any;
  eventId?: any;
  recurringEventId?: string | null;
  openScheduleForm?: boolean;
  setOpenScheduleForm?: any;
  teamMembers?: TeamMemberType[];
  editEventDetails?: EditEventDetails | null;
  calendarProvider?: CALENDAR | null;
  currentUserEvents?: CalendarEventType[];
  slotRange?: SlotRangeType;
  applicants?: UserType[];
  currentUser?: UserInfo;
  params?: URLSearchParams;
  setIsTopLineLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  handleEventScheduleForm?: () => void;
  openmodel?: any;
  setopenmodel?: any;
  setMeetingForm?: any;
  meetingForm?: any;
  value?: any;
  permissioninclude?: any;
  setopensubcription?: (val: any) => void;
  formik?: any;
  viewMeetingSummary?: any;
  setViewMeetingSummary?: any;
  showPopup?: any;
  setShowPopup?: any;
  handleSubmit?: any;
  handlefunction1?: any;
  sample?: any;
  setsample?: any;
  summary?: any;
  selectlist?: any;
  tick?: any;
  person?: any;
  editid?: any;
}

const MeetingSchedulingScreen = ({
  openScheduleForm,
  setopensubcription,
  handleEventScheduleForm,
  teamMembers,
  APPLY,
  editEventDetails,
  EventId,
  eventId,
  recurringEventId,
  calendarProvider,
  cand_name,
  jd_name,
  cand_id,
  jd_id,
  cand_email,
  currentUserEvents,
  username,
  slotRange,
  setIsTopLineLoading,
  applicants,
  currentUser,
  params,
  setOpenScheduleForm,
  openmodel,
  setopenmodel,
  setMeetingForm,
  meetingForm,
  value,
  permissioninclude,
  formik,
  viewMeetingSummary,
  setViewMeetingSummary,
  showPopup,
  setShowPopup,
  handleSubmit,
  handlefunction1,
  sample,
  setsample,
  summary,
  selectlist,
  tick,
  person,
  editid,
  update
}: Props) => {

  const { search } = useLocation();
  const [addquestion, setaddquestion] = useState('')
  const dispatch: AppDispatch = useDispatch();
  const [currentApplicantId, setCurrentApplicantId] = useState<number | null>(
    null,
  );
  const [isnotset, setnotset] = useState<any>(false)
  const [isunchecked, setUnchecked] = useState<any>(false);
  const tabInitial: any =
    sessionStorage.getItem('interviewer') === null
      ? '0'
      : sessionStorage.getItem('interviewer');

  const [interviewer, setinterviewer] = useState<any>(tabInitial);
  const [currentUserLabel, setCurrentUserLabel] = useState<string>(null);
  const queryParams = new URLSearchParams(search);

  const updateCurrentApplicantId = (applicantId: number) => {
    setCurrentApplicantId(applicantId);
  };
  const [updating, setupdating] = useState<any>(false);
  const [isSubmitLoader, setSubmitLoader] = useState(false);
  const [ishandleformik, sethandleformik] = useState<any>([]);
  const [isblockquestion, setblockquestion] = useState<any>([]);
  const [allids, setallids] = useState([])
  const [errorstate, seterrorstate] = useState(false)
  const [validateerror, setvalidateerror] = useState(false)
  const cookies = new Cookies();

  useEffect(() => {
    if (editEventDetails) {
      setMeetingForm((form) => {
        return {
          ...form,
          applicant: {
            ...form.applicant,
            id: editEventDetails.applicant.id,
            name: editEventDetails.applicant.name,
          },
          job: { ...form.job, label: editEventDetails.jobRole.label },
          date: {
            ...form.date,
            value: getDateFromDateTime(editEventDetails.startDateTime),
          },
          startTime: {
            ...form.startTime,
            value: new Date(editEventDetails.startDateTime),
          },
          startDateTime: editEventDetails.startDateTime,
          endDateTime: editEventDetails.endDateTime,
          endTime: {
            ...form.endTime,
            value: new Date(editEventDetails.endDateTime),
          },
          timeZone: {
            ...form.timeZone,
            value: editEventDetails.timeZone,
          },
          eventType: {
            ...form.eventType,
            value: editEventDetails.eventType,
          },
          interviewer: editEventDetails.interviewers,
          notes: editEventDetails.notes,
          location: {
            ...form.location,
            name: editEventDetails.location,
          },
          attentee: editEventDetails.attentee,
          privateNotes: editEventDetails.privateNotes,
          reminder: editEventDetails.remainder
        };
      });
    }
  }, [editEventDetails]);

  // setting the job details and candidate details in the sechedule event form
  useEffect(() => {
    // const canIdFromQuery = queryParams.get('can_id');
    // const parsedCanId = canIdFromQuery.includes('?')
    //   ? canIdFromQuery.split('?')[0]
    //   : canIdFromQuery;

    if (APPLY) {
      setMeetingForm((form) => {
        return {
          ...form,
          applicant: {
            ...form.applicant,
            id: cand_id.toString(),
            name: localStorage.getItem('Applicantname'),
          },
          job: { ...form.job, label: localStorage.getItem('Jdname') },
        };
      });
    } else if (queryParams.get('id')) {
      setMeetingForm((form) => {
        return {
          ...form,
          applicant: {
            ...form.applicant,
            id: queryParams.get('can_id'),
            // id: parsedCanId,
            name: queryParams.get('name'),
          },
          job: {
            ...form.job,
            label: queryParams.get('jobTitle'),
            value: JSON.parse(queryParams.get('jobId')),
          },
        };
      });
    }
    if (cand_name !== '' && cand_name !== undefined) {
      setMeetingForm((form) => {
        return {
          ...form,
          applicant: {
            ...form.applicant,
            id: cand_id,
            name: cand_name,
          },
          job: {
            ...form.job,
            label: jd_name,
            value: jd_id,
          },
        };
      });
    }
  }, [APPLY, cand_id, update]);

  const sessionParam = queryParams.get('session');
  const canIDParam = queryParams.get('can_id');

  // Splitting canID to separate session value
  // const [canIDValue, sessionValue] = canIDParam?.split('?session=');
  const [canIDValue, sessionValue] = (canIDParam || '').split('?session=');

  // Ensure sessionValue is not null
  const session = sessionValue || sessionParam;

  // Ensure canIDValue is not null
  const canID = canIDValue || queryParams.get('can_id');

  // const session: any = queryParams.get('session');
  const session_id: any = queryParams.get('session_id');

  useEffect(() => {
    if (!isEmpty(session)) {
      dispatch(creditsPurchaseMiddleWare({ session })).then(() => {
        dispatch(permissionMiddleWare());

        dispatch(SubsriptionMiddleWare())
          .then(() => {
            dispatch(SubsriptionMiddleWare())
            dispatch(permissionMiddleWare());
          })
          .then(() => {
            if (query.has('session')) {
              query.delete('session');
              history.replace({
                search: query.toString(),
              });
            }
          }).then(() => {
            dispatch(SubsriptionMiddleWare())
            dispatch(permissionMiddleWare());

          });
      })
    }
    if (!isEmpty(session_id)) {
      dispatch(backendProcessMiddleWare({ session_id })).then(() => {
        dispatch(SubsriptionMiddleWare());
        dispatch(manageSubscriptionMiddleWare())
          .then(() => {
            dispatch(permissionMiddleWare());
            dispatch(SubsriptionMiddleWare());
          })
          .then(() => {
            if (query.has('session_id')) {
              query.delete('session_id');
              history.replace({
                search: query.toString(),
              });
            }
          })
          .then(() => {
            dispatch(SubsriptionMiddleWare())
            dispatch(permissionMiddleWare());
          });
      })
    }
  }, [session]);

  useEffect(() => {
    return () => setMeetingForm(meetingFormInitialState);
  }, []);

  function splitName(username1) {
    const parts = username1?.trim()?.split(/\s+/);
    const firstName = parts?.length > 0 && parts[0] || '';
    const lastName = parts?.slice(1)?.join(' ') || '';
    return {
      firstName,
      lastName,
    };
  }
  const [value1, setvalue1] = useState(value);
  const [newquestion, setnewquestion] = useState([]);
  const [error, seterror] = useState(false);
  const [field, setfield] = useState([])
  const [open, setopen] = useState(false)

  useEffect(() => {
    const returnValue = localStorage.getItem('return');
    if (returnValue !== 'true' || meetingForm?.interviewer?.length === 0) {
      const { firstName, lastName } = splitName(username);
      const newData = { firstName, lastName, role: '', userId: value1, success: false };

      // Check if userId already exists in the interviewers array
      const userIdExists = formik.values?.interviewers.some(e => e?.userId === newData?.userId);

      if (!userIdExists) {
        const updatedInterviewers = [...formik.values?.interviewers, newData];
        formik.setFieldValue('interviewers', updatedInterviewers);
        sessionStorage.setItem('interviewer', '0');
      }
    }
  }, []);

  useEffect(() => {
    if (slotRange.date) {
      setMeetingForm((form) => ({
        ...form,
        date: { ...form.date, value: slotRange?.date },
        startTime: { ...form.startTime, value: slotRange?.start },
        endTime: { ...form.endTime, value: slotRange?.end },
      }));

      return () => setMeetingForm(meetingFormInitialState);
    }
  }, [slotRange]);

  function deepCompare(obj1, obj2) {

    // Check all conditions for equality
    return obj1.applicant.id === obj2.applicant.id &&
      obj1.date.value === obj2.date.value &&
      obj1.endTime.value === obj2.endTime.value &&
      obj1.startTime.value === obj2.startTime.value &&
      obj1.timeZone.value === obj2.timeZone.value &&
      obj1.eventType.value === obj2.eventType.value &&
      obj1.location.value === obj2.location.value &&
      obj1.interviewer.length === obj2.interviewer.length &&
      obj1.reminder.value === obj2.reminder.value &&
      obj1.reminder.format === obj2.reminder.format &&
      obj1.notes === obj2.notes &&
      obj1.privateNotes === obj2.privateNotes;
  }
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const history = useHistory();
  const handleCloseSchedulingForm = () => {
    const expectedMeetingForm = {
      applicant: {
        id: null,
        name: '',
        email: '',
        error: false
      },
      attendee: [],
      date: {
        value: null,
        error: false
      },
      endDateTime: null,
      endTime: {
        value: null,
        errorMessage: null
      },
      eventType: {
        value: null,
        error: false
      },
      interviewer: [],
      job: {
        label: '',
        value: 0,
        error: false
      },
      location: {
        isHave: false,
        value: '',
        error: false
      },
      notes: "",
      privateNotes: "",
      reminder: {
        value: 15,
        format: 'mins'
      },
      startDateTime: null,
      startTime: {
        value: null,
        errorMessage: null
      },
      timeZone: {
        value: 'Asia/Calcutta',
        error: false
      }
    };
    const isDifferent = deepCompare(meetingForm, expectedMeetingForm);
    if (!isDifferent) {
      const userResponse = window.confirm("Do you want to leave this site? Changes you made may not be saved.");
      if (userResponse) {
        [
          'applicant',
          'applicant_id',
          'jd',
          'jd_id',
          'eDate',
          'timezone',
          'event_type',
          'notes',
          'private_notes',
          'location',
          'interviewers',
        ].forEach((item) => localStorage.removeItem(item));

        // Removing the url params using this function.
        const removeQueryParam = (name) => {
          if (query.has(name)) {
            query.delete(name);
            history.replace({ search: query.toString() });
          }
        };
        removeQueryParam('jobId');
        removeQueryParam('name');
        removeQueryParam('jobTitle');
        removeQueryParam('emailid');
        removeQueryParam('can_id');
        removeQueryParam('id');

        localStorage.setItem('Applicantsname', '');
        localStorage.setItem('Jdname', '');
        localStorage.setItem('jdid', '');
        localStorage.setItem('can_mail', '');
        localStorage.setItem('can_id', '');
        setViewMeetingSummary(false);
        setShowPopup(false)
        setMeetingForm(meetingFormInitialState);
        localStorage.setItem('return', 'false');
        handleEventScheduleForm();
        cookies.remove('meetingForm', { path: '/' });
        cookies.remove('formik.values', { path: '/' });
        cookies.remove('sample', { path: '/' })
        localStorage.setItem('return', 'false');
        setsample([])
      }
    }
    else {
      [
        'applicant',
        'applicant_id',
        'jd',
        'jd_id',
        'eDate',
        'timezone',
        'event_type',
        'notes',
        'private_notes',
        'location',
        'interviewers',
      ].forEach((item) => localStorage.removeItem(item));

      localStorage.setItem('Applicantsname', '');
      localStorage.setItem('Jdname', '');
      localStorage.setItem('jdid', '');
      localStorage.setItem('can_mail', '');
      localStorage.setItem('can_id', '');
      setViewMeetingSummary(false);
      setShowPopup(false)
      setMeetingForm(meetingFormInitialState);
      handleEventScheduleForm();
      cookies.remove('meetingForm', { path: '/' });
      cookies.remove('formik.values', { path: '/' });
      cookies.remove('sample', { path: '/' })
      localStorage.setItem('return', 'false');
      setsample([])
    }
  };
  useEffect(() => {
    // This function will be called before the page unloads
    const handlePageUnload = (event) => {
      if (query.has('jobId')) {
        query.delete('jobId');
        history.replace({
          search: query.toString(),
        });
      }
      if (query.has('name')) {
        query.delete('name');
        history.replace({
          search: query.toString(),
        });
      }
      if (query.has('jobTitle')) {
        query.delete('jobTitle');
        history.replace({
          search: query.toString(),
        });
      }
      if (query.has('emailid')) {
        query.delete('emailid');
        history.replace({
          search: query.toString(),
        });
      }
      if (query.has('can_id')) {
        query.delete('can_id');
        history.replace({
          search: query.toString(),
        });
      }
      if (query.has('id')) {
        query.delete('id');
        history.replace({
          search: query.toString(),
        });
      }
      const expectedMeetingForm = {
        applicant: {
          id: null,
          name: '',
          email: '',
          error: false
        },
        attendee: [],
        date: {
          value: null,
          error: false
        },
        endDateTime: null,
        endTime: {
          value: null,
          errorMessage: null
        },
        eventType: {
          value: null,
          error: false
        },
        interviewer: [],
        job: {
          label: '',
          value: 0,
          error: false
        },
        location: {
          isHave: false,
          value: '',
          error: false
        },
        notes: "",
        privateNotes: "",
        reminder: {
          value: 15,
          format: 'mins'
        },
        startDateTime: null,
        startTime: {
          value: null,
          errorMessage: null
        },
        timeZone: {
          value: 'Asia/Calcutta',
          error: false
        }
      };
      const isDifferent = deepCompare(meetingForm, expectedMeetingForm);
      cookies.remove('meetingForm', { path: '/' });
      cookies.remove('formik.values', { path: '/' });
      cookies.remove('sample', { path: '/' })
      localStorage.setItem('return', 'false');
      if (!isDifferent) {
        const message = "Do you want to leave this site? Changes you made may not be saved.";
        event.returnValue = message;
      }
    };
    window.addEventListener('beforeunload', handlePageUnload);
    return () => {
      window.removeEventListener('beforeunload', handlePageUnload);
    };

  }, [meetingForm]);


  const handleCloseSchedulingForm1 = () => {
    {
      [
        'applicant',
        'applicant_id',
        'jd',
        'jd_id',
        'eDate',
        'timezone',
        'event_type',
        'notes',
        'private_notes',
        'location',
        'interviewers',
      ].forEach((item) => localStorage.removeItem(item));
      localStorage.setItem('Applicantsname', '');
      localStorage.setItem('Jdname', '');
      localStorage.setItem('jdid', '');
      localStorage.setItem('can_mail', '');
      localStorage.setItem('can_id', '');
      setViewMeetingSummary(false);
      setShowPopup(false)
      setMeetingForm(meetingFormInitialState);
      handleEventScheduleForm();
      localStorage.setItem('return', 'false');
      setsample([])
    }
  };
  const handlefunction = () => {
    setopenmodel(false);
    setShowPopup(false)
    setViewMeetingSummary(false);
    setShowPopup(false);
    setOpenScheduleForm(true);
  };



  const nextEvent = () => {
    if (
      formik.values.brieftext !== '' ||
      formik.values.checkedValues.length !== 0
    ) {
      setViewMeetingSummary(false);
      setShowPopup(true);
      localStorage.setItem('return', 'false');
    } else {
      setViewMeetingSummary(true);
      setopenmodel(true);
      localStorage.setItem('return', 'false');
    }
  };

  const handlechange = () => {
    if (allQuestions.length === 0) {
      seterror(true)
    }
    if (!(allQuestions.length === 0)) {
      seterror(false)
      setViewMeetingSummary(true);
      setopenmodel(false);
    }

  };
  const handlechange1 = () => {
    setViewMeetingSummary(true);
    setopenmodel(true);
  };

  const isQuestionChecked = (id: any) => {
    return formik.values.questionid?.some(item => item?.id === id && item?.checked);
  }

  const handlecheck = (id: any, checked: boolean) => {
    const updatedQuestions = [...formik.values.questionid];
    const index = updatedQuestions?.findIndex(item => item?.id === id);

    if (index !== -1) {
      updatedQuestions[index].checked = checked;
    } else {
      updatedQuestions.push({ id, checked });
    }

    formik.setFieldValue('questionid', updatedQuestions);
  }
  // const id_questions: any[] = formik.values.questionid
  //   .filter(question => question.checked)
  //   .map(question => question.id);


  const update_state = (data) => {
    const updatedArray = sample?.map((item, index) =>
      index === parseInt(interviewer) ? { ...item, success: true, question: data } : item
    );
    // Set the new array to state
    setsample(updatedArray);
  }
  function extractQuestions(dataArray) {
    const allQuestions = dataArray?.map(item => item.question)?.reduce((acc, current) => {
      return acc?.concat(current);
    }, []);

    return allQuestions;
  }
  const allQuestions = extractQuestions(allids);
  const filteredQuestions = allQuestions?.filter(item => typeof item === 'number');

  const initialValues1: MyFormValues1 = {
    levellist: [],
    question: [],
    questionid: [],
    addquestion: [],
    Errorshow: [],
    loader: [],
    errorfield: [],
    required: [],
    showstate: []
  };

  const childhandleCompanyPageValid = (values: MyFormValues1) => {
    let empty = false
    const errors: { levellist?: Partial<levellist>[] } = {};
    const sumValues = (levels: LevelValue[]) => {
      let easySum = 0;
      let mediumSum = 0;
      let hardSum = 0;

      levels.forEach((item) => {
        if (item.easy !== '0') {
          const easyValue = parseInt(item.easy) || 0;
          easySum += easyValue;
          if (easyValue === 0) empty = true;
        }

        if (item.medium !== '0') {
          const mediumValue = parseInt(item.medium) || 0;
          mediumSum += mediumValue;
          if (mediumValue === 0) empty = true;
        }

        if (item.hard !== '0') {
          const hardValue = parseInt(item.hard) || 0;
          hardSum += hardValue;
          if (hardValue === 0) empty = true;
        }
      });

      return { easySum, mediumSum, hardSum };
    };

    values?.levellist?.forEach((data, index) => {
      const sums = sumValues(data.level);
      const total = sums.easySum + sums.mediumSum + sums.hardSum;
      // data.level?.forEach((level1) => {
      //   const existingError: Partial<levellist> = errors.levellist?.[index] || {};

      //   if (level1.iseasycheck && (!level1.easy || parseInt(level1.easy) === 0)) {
      //     errors.levellist = errors.levellist || [];
      //     errors.levellist[index] = {
      //       ...existingError,
      //       showError: "Please specify the count of questions to be generated.",
      //       id: data.id
      //     };
      //   }

      //   if (level1?.ismediumcheck && (!level1.medium || parseInt(level1?.medium) === 0)) {
      //     errors.levellist = errors.levellist || [];
      //     errors.levellist[index] = {
      //       ...existingError,
      //       showError: "Please specify the count of questions to be generated.",
      //       id: data.id
      //     };
      //   }

      //   if (level1?.ishardcheck && (!level1.hard || parseInt(level1?.hard) === 0)) {
      //     errors.levellist = errors.levellist || [];
      //     errors.levellist[index] = {
      //       ...existingError,
      //       showError: "Please specify the count of questions to be generated.",
      //       id: data.id
      //     };
      //   }
      // });
      if (total > 15) {
        errors.levellist = errors.levellist || [];
        const existingError: Partial<levellist> = errors.levellist[index] || {};
        if (sample[index]?.success === false) {
          errors.levellist[index] = {
            ...existingError,
            totalError: "Questions count should not exceed 15.",
            id: data.id

          };
        }
      }
    });
    if (errors?.levellist && errors.levellist?.length === 0) {
      delete errors?.levellist;
    }

    return errors;
  };
  const [questionerror, setquestionerror] = useState(false)

  useEffect(() => {
    if (formik.values?.checkedValues?.length !== 0 && formik.values?.checkedValues !== undefined) {
      if (isunchecked === false) {

        const mappedQuestions = formik.values?.checkedValues?.map((item, index) => {
          const matchingTick = tick.find(tickItem => tickItem?.id?.toString() === item?.id?.toString());
          return {
            id: item?.id,
            question: matchingTick ? matchingTick?.question : []
          };
        });
        formik1.setFieldValue('question', mappedQuestions);
      }
      const mappedArray1 = formik.values?.checkedValues?.map(item => ({
        id: item?.id,
        loader: false,
      }));
      formik1.setFieldValue('loader', mappedArray1);
      const mappedArray2 = formik.values?.checkedValues?.map(item => ({
        errorfield: false,
      }));
      formik1.setFieldValue('errorfield', mappedArray2);
      const mappedArray3 = formik.values?.checkedValues?.map(item => ({
        required: false,
      }));
      formik1.setFieldValue("required", mappedArray3);
      const mappedArray4 = formik.values?.checkedValues?.map(item => ({
        showstate: false,
      }));
      formik1.setFieldValue("showstate", mappedArray4);
    }
  }, [formik.values?.checkedValues, tick]);

  const formik1 = useFormik({
    initialValues: initialValues1,
    onSubmit: () => { },
    validate: childhandleCompanyPageValid,
  });
  //this defaultLevels is for removing the check box
  const defaultLevels = [
    { name: 'IQ Based', easy: '0', iseasycheck: false, medium: '0', ismediumcheck: false, hard: '0', ishardcheck: false, checked: true },
    { name: 'Technical', easy: '0', iseasycheck: false, medium: '0', ismediumcheck: false, hard: '0', ishardcheck: false, checked: true },
    { name: 'Coding', easy: '0', iseasycheck: false, medium: '0', ismediumcheck: false, hard: '0', ishardcheck: false, checked: true },
    { name: 'Behavioral', easy: '0', iseasycheck: false, medium: '0', ismediumcheck: false, hard: '0', ishardcheck: false, checked: true },
    { name: 'General', easy: '0', iseasycheck: false, medium: '0', ismediumcheck: false, hard: '0', ishardcheck: false, checked: true }
  ];
  useEffect(() => {
    if (formik.values?.checkedValues?.length !== 0) {
      const mappedArray = formik.values?.checkedValues?.map(item => ({
        id: item?.id,
        //level:[],
        level: [...defaultLevels],
        role: item?.role,
        success: false,
        lastname: item?.lastName,
        firstname: item?.firstName
      }));
      childhandleCompanyPageValid(formik1.values)
      formik1.setFieldValue('levellist', mappedArray);
    }
    //formikval.setFieldValue('questionid', []);
  }, [formik.values.checkedValues]);
  useEffect(() => {
    if (meetingForm?.attentee?.length !== 0 && tick.length !== 0 && meetingForm?.attentee?.length === person?.length && isunchecked === false) {
      const mappedArray = meetingForm?.attentee?.map(item => ({
        firstName: item.firstName,
        lastName: item.lastName,
        role: item.role,
        userId: item.userId,
        success: false,
      }))
      formik.setFieldValue('interviewers', mappedArray);

      if (isnotset === false) {
        const mappedArray1 = meetingForm?.attentee?.map(item => {
          const questionsForUser = getQuestionsForUser(item?.userId, selectlist);
          if (Number(questionsForUser.Id) === Number(item?.userId) && questionsForUser?.Question?.length !== 0) {
            return {
              firstName: item.firstName,
              lastName: item.lastName,
              role: item.role,
              id: item.userId,
              success: false,
              // Add other properties as needed
            };
          } return null;
        }).filter(item => item !== null);
        formik.setFieldValue('checkedValues', mappedArray1);
      }
      const mappedArray3 = meetingForm?.attentee?.map(item => {
        // Check if the ID is not in isunchecked before updating questions 
        const questionsForUser = getQuestionsForUser(item?.userId, selectlist);
        return {
          id: item?.userId,
          question: questionsForUser,
          success: questionsForUser?.Question?.length === 0 ? false : true,
        }
      });

      const updatedTick = tick.map(item => ({
        ...item,
        id: Number(item?.id), // Convert the 'id' to a number
        question: item?.question // Keep the 'question' array as is
      }));
      formik1.setFieldValue('question', updatedTick);
      setsample(mappedArray3)
      formik.setFieldValue('brieftext', summary);
    } else {
      if (meetingForm?.attentee?.length !== 0 && meetingForm?.attentee?.length === person?.length && tick?.length === 0) {
        const mappedArray = meetingForm?.attentee?.map(item => ({
          firstName: item.firstName,
          lastName: item.lastName,
          userId: item.userId,
          success: false,
        }))

        formik.setFieldValue('interviewers', mappedArray);
        // formik.setFieldValue('brieftext', '')
        // formik.setFieldValue('checkedValues', []);
        const mappedArray3 = meetingForm?.attentee?.map(item => {
          return {
            id: item.userId,
            question: [],
            success: false,
          };
        });

        const isIdAlreadyPresent = (id) => {
          return sample.some(item => item?.id === id);
        };

        mappedArray3.forEach(item => {
          if (!isIdAlreadyPresent(item?.id)) {
            sample.push(item);
          }
        });
      }
    }
  }, [meetingForm, tick, person])

  function getQuestionsForUser(userId, selectlist1) {
    // Ensure selectlist is an array
    let arraySelectlist;
    if (!Array.isArray(selectlist1)) {
      arraySelectlist = Object.values(selectlist);
    } else {
      arraySelectlist = selectlist1;
    }

    // Find the user object with a matching Id
    const userObject = arraySelectlist?.find(user => Number(user?.Id) === Number(userId));

    // Return the found object or a default object if not found
    return userObject || { Id: userId, Question: [], Role: null };
  }

  // updating the value when changing the role and onclick the check box
  useEffect(() => {
    if (editEventDetails) {
      if (formik.values?.checkedValues && meetingForm?.attentee) {
        const interviewerIds = meetingForm?.attentee?.map(i => i.userId);

        const updatedCheckedValues = formik.values.checkedValues?.map((item) => {
          if (interviewerIds.includes(Number(item.id))) {
            const correspondingInterviewer = meetingForm?.attentee?.find(i => i.userId === item.id);
            return {
              ...item,
              firstName: correspondingInterviewer?.firstName,
              lastName: correspondingInterviewer?.lastName,
              role: correspondingInterviewer?.role,
              id: correspondingInterviewer?.userId,
              success: false,
            };
          }
          return item;
        });
        const interviewerI = meetingForm?.attentee?.map(i => i.userId);
        const newCheckedValues = formik.values?.checkedValues?.filter(u => interviewerI.includes(u.id));

        if (formik.values?.checkedValues === undefined) {
          formik.setFieldValue('checkedValues', updatedCheckedValues);
        }
        else {
          const finalupdate = newCheckedValues.map(i => i.id);
          const finalpushvalues = updatedCheckedValues?.filter(u => finalupdate.includes(u.id));
          formik.setFieldValue('checkedValues', finalpushvalues);
        }
      }
    }
    else {
      const interviewerIds = formik.values?.interviewers?.map(i => i.userId);
      const newCheckedValues = formik.values?.checkedValues?.filter(u => interviewerIds.includes(u.id));
      formik.setFieldValue('checkedValues', newCheckedValues);
    }
  }, [updating, isunchecked, meetingForm.attentee, formik.values?.interviewers]);

  const plan = 6;
  return (
    <>
      <Modal
        onClose={handleCloseSchedulingForm}
        open={openScheduleForm}
        closeModalOnOuterClick={false}
      >

        {viewMeetingSummary === false ? (
          showPopup === false ?
            (<MeetingSchedulingForm
              updateCurrentApplicantId={updateCurrentApplicantId}
              applicants={applicants}
              currentUser={currentUser}
              currentUserEvents={currentUserEvents}
              currentUserLabel={currentUserLabel}
              setCurrentUserLabel={setCurrentUserLabel}
              calendarProvider={calendarProvider}
              handleCloseSchedulingForm={handleCloseSchedulingForm}
              meetingForm={meetingForm}
              setMeetingForm={setMeetingForm}
              setViewMeetingSummary={setViewMeetingSummary}
              teamMembers={teamMembers}
              username={username}
              cand_name={cand_name}
              jd_name={jd_name}
              editEventDetails={editEventDetails}
              cand_email={cand_email}
              cand_id={cand_id}
              jd_id={jd_id}
              setOpenScheduleForm={setOpenScheduleForm}
              setopenmodel={setopenmodel}
              openmodel={openmodel}
              formik={formik}
              plan={plan}
            />) : (
              <QuestionListModel
                interviewer={interviewer}
                setinterviewer={setinterviewer}
                isblockquestion={isblockquestion}
                setblockquestion={setblockquestion}
                setUnchecked={setUnchecked}
                formikval={formik}
                setsample={setsample}
                isQuestionChecked={isQuestionChecked}
                handlecheck={handlecheck}
                handlechange1={handlechange1}
                handlefunction1={handlefunction1}
                handlechange={handlechange}
                meetingForm={meetingForm}
                setShowPopup={setShowPopup}
                setViewMeetingSummary={setViewMeetingSummary}
                sample={sample}
                update_state={update_state}
                setnewquestion={setnewquestion}
                newquestion1={newquestion}
                setallids={setallids}
                setaddquestion={setaddquestion}
                formik={formik1}
                setquestionerror={setquestionerror}
                questionerror={questionerror}
                errorstate={errorstate}
                seterrorstate={seterrorstate}
                setvalidateerror={setvalidateerror}
                validateerror={validateerror}
                field={field}
                setfield={setfield}
                editid={editid}
                tick={tick}
              />
            )
        ) : openmodel === false ? (
          <MeetingSummary
            currentUserLabel={currentUserLabel}
            applicants={applicants}
            meetingForm={meetingForm}
            username={username}
            nextEvent={nextEvent}
            teamMembers={teamMembers}
            editEventDetails={editEventDetails}
            handleCloseSchedulingForm={handleCloseSchedulingForm}
            handleCloseSchedulingForm1={handleCloseSchedulingForm1}
            currentApplicantId={currentApplicantId}
            // extraNotes={extraNotes}
            EventId={EventId}
            eventId={eventId}
            recurringEventId={recurringEventId}
            setIsTopLineLoading={setIsTopLineLoading}
            setOpenScheduleForm={setOpenScheduleForm}
            setopenmodel={setopenmodel}
            formik={formik}
            question={filteredQuestions}
            addquestion={JSON.stringify(addquestion)}
          />
        ) : (
          <>
            <Flex
              style={{
                backgroundColor: '#FFF',
                width: '500px',
                height: 'auto',
                padding: '25px',
              }}
            >
              <Flex
                style={{ borderBottom: '0.5px solid #581845' }}
                marginBottom={10}
              >
                <Text size={14} bold style={{ marginBottom: '5px' }}>
                  Generate Interview Questions by AI
                </Text>
              </Flex>


              <Text color="theme" style={{ marginBottom: '4px' }}  >
                Pick the interviewer for question generation.{formik.values.brieftext !== '' || formik.values.checkedValues.length !== 0 ? (<span >*</span>) : ('')}
              </Text>
              <Flex row style={{ display: 'flex', flexWrap: 'wrap', margin: '0 0 2px 0' }}>
                {formik.values?.interviewers?.map((user) => {
                  const isChecked = formik.values.checkedValues.some((cv) => {
                    return cv.id === user.userId;
                  });

                  const handleCheckboxChange = (id?: any) => {
                    setupdating(!updating)
                    setnotset(true);
                    const updatedValues = [...formik.values.checkedValues];
                    const index = updatedValues?.findIndex((cv) => cv.id === user.userId);
                    if (isChecked) {
                      if (index !== -1) {
                        updatedValues.splice(index, 1);
                      }
                      const updatedSample = sample.filter((item) => item.id !== user?.userId);
                      const existingIndex = formik.values?.question?.findIndex(obj => obj?.id === user?.userId);
                      if (isblockquestion.length === 0) {
                        setblockquestion([user?.userId])
                      }
                      else {
                        setblockquestion([...isblockquestion, user?.userId])
                      }
                      setsample(updatedSample);
                    } else {
                      updatedValues.push({
                        id: user.userId,
                        value: true,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        role: user.role,
                      });
                      const newSample = {
                        id: user.userId,
                        success: false,
                        question: [],
                      };
                      const existingIndex = sample?.findIndex(entry => entry?.id === id);
                      const existingind = formik1.values.question.findIndex(entry => entry?.id === id);

                      // If found, update the existing entry with newSample
                      const updatedSample = [...sample];
                      const updatequestion = [...formik1.values.question];

                      // Ensure the entry and the property 'question' exist before updating
                      if (existingind !== -1 && updatequestion[existingind] && updatequestion[existingind].question) {
                        updatequestion[existingind].question = [];
                        formik1.setFieldValue('question', updatequestion);
                      }

                      // Ensure the entry and the property 'question' exist before updating
                      if (existingIndex !== -1 && updatedSample[existingIndex] && updatedSample[existingIndex].question) {
                        updatedSample[existingIndex].question = [];
                        updatedSample[existingIndex].success = false;
                        setsample(updatedSample);
                      }
                      else {
                        setsample([...sample, newSample]);
                      }

                    }
                    formik.setFieldValue('checkedValues', updatedValues);
                  };
                  return (
                    <Flex
                      row
                      key={user.userId}
                      style={{ margin: '0 0 10px 0', width: '50%' }}
                    >
                      <InputCheckBox
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(user.userId)}
                      />

                      <Text
                        style={{ margin: '0 0px 0 6px' }}
                      >{`${toCamelCase(user?.firstName)} ${toCamelCase(user?.lastName)}`}</Text>
                    </Flex>
                  );
                })}
                {(formik.errors.checkedValuesError === '') ? (
                  <Flex style={{
                    marginTop: '-4px',
                    marginBottom: '2px'
                  }}><Text color='error'>Please select the interviewer for the interview.</Text></Flex>
                ) : ('')}
              </Flex>

              <Flex style={{ margin: '0 0 10px 0' }}>
                <label
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '4px',
                  }}
                >
                  <p style={{ color: '#581845', fontSize: '13px' }}>
                    A brief summary of the interview.{formik.values.brieftext !== '' || formik.values.checkedValues.length !== 0 ? (<span >*</span>) : ('')}
                  </p>
                </label>
                <InputText
                  // label={'A brief summary of the interview.'}
                  value={formik.values.brieftext}
                  placeholder="Add a brief summary of the interview."
                  style={{ marginBottom: '5px' }}
                  onChange={(e) => {
                    if (formik.values?.brieftext?.length === 0) {
                      formik?.setFieldValue(
                        'brieftext',
                        e.target.value?.trim(),
                      );
                    } else {
                      formik?.setFieldValue('brieftext', e.target.value);
                    }
                  }}
                  required={formik.values.brieftext !== '' || formik.values.checkedValues.length !== 0}
                />
                <ErrorMessage
                  name={'brieftext'}
                  errors={formik.errors}
                  touched={formik.touched}
                />
              </Flex>

              {(formik.errors.brieftext === '') ? (
                <Flex style={{
                  marginTop: '-10px',
                  marginBottom: '2px'
                }}><Text color='error'>Please add summary of the interview.</Text></Flex>
              ) : ('')}
              <Flex style={{ borderTop: '0.5px solid #c3c3c3' }} >
                <Flex row between marginTop={10}>
                  <Button types="secondary" onClick={handlefunction} width='85px'>
                    Back
                  </Button>

                  <Flex row>
                    <Button types="close" onClick={handlefunction1} width='85px'>
                      Cancel
                    </Button>
                    {isSubmitLoader ? (
                      <Flex style={{ margin: '3px 0 0 15px' }} width={85} middle>
                        <Loader size="small" withOutOverlay />
                      </Flex>
                    ) :
                      <Button
                        style={{ margin: '0 0 0 10px', width: '85px', cursor: 'pointer' }}
                        onClick={handleSubmit}
                      >
                        {formik.values.brieftext !== '' || formik.values.checkedValues.length !== 0 ? (
                          <Flex row center style={{ cursor: 'pointer' }} >
                            <Text color="white" bold style={{ cursor: 'pointer' }}>Generate</Text>
                            {permissioninclude.includes('AI Interview Question') ? '' :
                              permissioninclude.includes('Addon AI Interview Question') ? '' :
                                (
                                  <Flex marginBottom={2} marginLeft={4} style={{ cursor: 'pointer' }}>
                                    <SvgSubcriptioncrown height={14} width={14} fill="" />
                                  </Flex>
                                )}
                          </Flex>
                        ) : (
                          'Skip'
                        )}
                      </Button>}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </Modal>
    </>
  );
};

export default MeetingSchedulingScreen; 
