const defaultProps = {
    fill: '#424242',
    width: '13',
    height: '13',
    className: '',
};

interface Props {
    fill?: string;
    width?: string | number;
    height?: string | number;
    className?: string;
}

const Svgsubcriptionrefresh = ({ fill, width, height, className }: Props) => (
    <svg width={width} height={height} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.5 9C3.24375 9 2.17969 8.56406 1.30781 7.69219C0.435937 6.82031 0 5.75625 0 4.5C0 3.24375 0.435937 
2.17969 1.30781 1.30781C2.17969 0.435937 3.24375 0 4.5 0C5.14687 0 5.76563 0.1335 6.35625 0.4005C6.94688 0.6675
7.45313 1.04963 7.875 1.54688V0H9V3.9375H5.0625V2.8125H7.425C7.125 2.2875 6.71494 1.875 6.19481 1.575C5.67469 
1.275 5.10975 1.125 4.5 1.125C3.5625 1.125 2.76562 1.45312 2.10938 2.10938C1.45312 2.76562 1.125 3.5625 1.125
4.5C1.125 5.4375 1.45312 6.23438 2.10938 6.89063C2.76562 7.54688 3.5625 7.875 4.5 7.875C5.22188 7.875 5.87344 
7.66875 6.45469 7.25625C7.03594 6.84375 7.44375 6.3 7.67813 5.625H8.85938C8.59688 6.61875 8.0625 7.42969 7.25625
8.05781C6.45 8.68594 5.53125 9 4.5 9Z" fill="#581845"/>
</svg>

);

Svgsubcriptionrefresh.defaultProps = defaultProps;

export default Svgsubcriptionrefresh;
