import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { isEmpty } from '../../uikit/helper';
import Loader from '../../uikit/Loader/Loader';
import { createCheckoutMiddleWare, stripeMiddleWare } from '../talentsourcingmodule/store/middleware/talentSoucringMiddleware';
import { createCheckoutSubscriptionMiddleWare } from '../accountsettingsmodule/managesubscription/store/managesubscriptionmiddleware';
import { AppDispatch } from '../../store';
import { backendProcessMiddleWare } from '../ordersummarymodule/store/ordersummarymiddleware';
import Toast from '../../uikit/Toast/Toast';
import { ERROR_MESSAGE } from '../constValue';

const CheckSignUpActivate = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const dispatch: AppDispatch = useDispatch();
  const query = useQuery();

  const tocken: any = query.get('tocken');
  const is_staff: any = query.get('is_staff');
  const username: any = query.get('username');
  const plan_id: any = query.get('plan_id');
  const stripename: any = query.get('stripe_id');
  const count=query.get('count')
  const plan_id_array=query.get('plan_id_array')
 

  // check url params based signup redirection
  useEffect(() => {
    if (
      !isEmpty(tocken) &&
      !isEmpty(is_staff) &&
      !isEmpty(username) &&
      !isEmpty(plan_id)
    ) {
      localStorage.setItem('loginUserCheck', is_staff);
      localStorage.setItem('token', tocken);
      localStorage.setItem('loginUserId', is_staff === 'true' ? '0' : username);

      if (Number(plan_id) !== 1) {
        if(!isEmpty(stripename)&&!isEmpty(count)&&!isEmpty(plan_id_array)){
          const stripenameres = stripename;
          const countres=count;
          const plan_id_arrayres=plan_id_array;
        dispatch(
          createCheckoutSubscriptionMiddleWare({
            plan: stripenameres,
            count: countres,
            plan_name:plan_id_arrayres,
          }),
        ).then((response) => {
          dispatch(stripeMiddleWare()).then(async (stripRes) => {
            const stripe = await loadStripe(stripRes.payload.publicKey);
            if (response.payload.sessionId) {
              stripe
                ?.redirectToCheckout({
                  sessionId: response.payload.sessionId,
                })
            }
          });
        }); }
        else{
          // Toast('Data is not come from backend', 'MEDIUM','error');
        } 
      } else {
        // sessionStorage.setItem('superUserTab', '0');
        // sessionStorage.setItem('superUserFalseTab', '0');
        window.location.replace(
          window.location.origin + '/account_setting/profiles',
        );
      }
    }
  },[]);
  return <Loader />;
};

export default CheckSignUpActivate;
