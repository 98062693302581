import classNames from 'classnames/bind';
import { Dropdown } from 'react-bootstrap';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SvgDownload from '../../icons/SvgDownload';
import Totalcount from '../../globulization/TotalCount';
import SvgHeart from '../../icons/SvgHeart';
import SvgDoublestar from '../../icons/Svgdoublestar';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import { GARY_4, PRIMARY } from '../../uikit/Colors/colors';
import Flex from '../../uikit/Flex/Flex';
import { Button, Loader } from '../../uikit';
import { SelectTag } from '../../uikit';
import SvgFavourites from '../../icons/SvgFavourties';
import InputCheckBox from '../../uikit/InputCheckbox/InputCheckBox';
import Text from '../../uikit/Text/Text';
import SvgParsing from '../../icons/SvgParsing';
import { sortOptions } from './mock';
import styles from './zitaaction.module.css';

const cx = classNames.bind(styles);
type Props = {
  total: number;
  filterTotalFav: () => void;
  isTotalFav: boolean;
  handleSelectAll: () => void;
  isCheckAll: boolean;
  hanldeDownload: () => void;
  ai_matching: () => void;
  bulk_matching: number[];
  resume_credits: number;
  Permissions: string[];
  setopensubcription: (a: any) => void;
  current_resume_count?: any;
  handlesortby: (selectedValue: string) => void;
  isCheck: string[];
  isSortOptions: {
    value: string;
    label: string;
  };
  setSortOptions: Dispatch<
    SetStateAction<{
      value: string;
      label: string;
    }>
  >;
  plan_details: number[];
  ai_credits: boolean | null;
  current_plan: number | any;
  aimatchLoader: Boolean;
  updateloader: boolean;
};
const ZitaAction = ({
  total,
  filterTotalFav,
  isTotalFav,
  isCheckAll,
  handleSelectAll,
  hanldeDownload,
  ai_matching,
  isCheck,
  setSortOptions,
  isSortOptions,
  handlesortby,
  bulk_matching,
  resume_credits,
  Permissions,
  plan_details,
  ai_credits,
  current_plan,
  setopensubcription,
  current_resume_count,
  aimatchLoader,
  updateloader,
}: Props) => {
  const downloadCheck = isCheck.length === 0;

  const update_function = () => {
    if (
      Permissions.includes(
        'Advanced AI matching - Resume matching to multiple jobs',
      ) === true
    ) {
      return (
        <Flex row className={styles.bulkButton}>
          {updateloader ? (
            <Flex row center>
              <Flex
                className={styles.updateBtnLoader}
                style={{
                  paddingLeft: '5px',
                  borderLeft: '1px solid #581845',
                  cursor: 'pointer',
                }}
              >
                <Text>In progress...</Text>
              </Flex>
            </Flex>
          ) : (
            <Flex
              row
              center
              style={{
                paddingLeft: '5px',
                borderLeft: '1px solid #581845',
                cursor: 'pointer',
              }}
              onClick={ai_matching}
              title="Advanced AI Matching"
            >
              <Flex marginRight={5}>
                <SvgDoublestar />
              </Flex>
              <Text color="theme" style={{ marginLeft: '5px' }}>
                Advanced AI Matching
              </Text>
            </Flex>
          )}
        </Flex>
      );
    } else {
      if (plan_details.includes(current_plan) === true) {
        if (ai_credits === true) {
          return (
            <Flex row className={styles.bulkButton}>
              {updateloader ? (
                <Flex row center>
                  <Flex
                    className={styles.updateBtnLoader}
                    style={{
                      paddingLeft: '5px',
                      borderLeft: '1px solid #581845',
                      cursor: 'pointer',
                    }}
                  >
                    <Text>In progress...</Text>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  row
                  center
                  style={{
                    paddingLeft: '5px',
                    borderLeft: '1px solid #581845',
                    cursor: 'pointer',
                  }}
                  onClick={ai_matching}
                  title="Advanced AI Matching"
                >
                  <Flex marginRight={5}>
                    <SvgDoublestar />
                  </Flex>
                  <Text color="theme">Advanced AI Matching</Text>
                </Flex>
              )}
            </Flex>
          );
        } else {
          return (
            <Flex row className={styles.bulkButton}>
              <Flex
                row
                center
                style={{
                  paddingLeft: '5px',
                  borderLeft: '1px solid #581845',
                  cursor: 'pointer',
                }}
                onClick={() => setopensubcription(true)}
                title="Advanced AI Matching"
              >
                <Flex marginRight={5}>
                  <SvgDoublestar />
                </Flex>
                <Text color="theme">Advanced AI Matching</Text>
                <Flex marginLeft={5} marginBottom={2}>
                  <SvgSubcriptioncrown />
                </Flex>
              </Flex>
            </Flex>
          );
        }
      }
    }
  };

  return (
    <Flex row center between className={styles.overAll}>
      <Flex row>
        <Flex marginTop={2}>
          <InputCheckBox
            onChange={handleSelectAll}
            checked={isCheckAll}
            id="zitaaction__checkbox"
            disabled={total === 0}
          />
        </Flex>

        <Flex marginLeft={5} row>
          <Totalcount name="Total Candidates" numbers={total} />
          <Flex style={{ padding: '1px 0px 0px 12px' }}>
            {/* <Totalcount
              name="AI Resume Parsing Credits"
              numbers={resume_credits}
            /> */}
            <Flex row center>
              <Flex title="AI Resume Parsing Credits">
                <SvgParsing width={15} height={15} />
              </Flex>
              <Flex marginLeft={2}>
                <Text>: {resume_credits}</Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {/* <div title="Favourite Candidates" className="pointer">
          <SvgHeart
            height={18}
            width={18}
            onClick={filterTotalFav}
            filled={isTotalFav}
          />
        </div> */}
      <Flex>
        {
          downloadCheck === false && (
            //   <SvgDownload
            //   height={18}
            //   width={18}
            //   fill={downloadCheck ? GARY_4 : PRIMARY}
            // />
            <Flex row center className={styles.bulktab}>
              <Flex row center className={styles.bulkSelection}>
                <Flex marginRight={0}>
                  <Text color="theme">{`Selected ${isCheck.length} Candidates`}</Text>
                </Flex>

                <Flex row className={styles.bulkButton}>
                  <Flex
                    row
                    center
                    style={{
                      paddingLeft: '5px',
                      borderLeft: '1px solid #581845',
                      cursor: 'pointer',
                    }}
                    onClick={hanldeDownload}
                    title="Export Resumes"
                  >
                    <SvgDownload width={14} height={14} />
                    <Text style={{ marginLeft: '10px' }} color="theme">
                      Export Resumes
                    </Text>
                  </Flex>
                </Flex>
                {update_function()}
              </Flex>
            </Flex>
          )
          //   <Flex row center className={styles.bulktab}>
          //   <Flex row center className={styles.bulkSelection}>
          //     <Flex marginRight={0}>
          //       <Text color="theme">{`Selected ${isCheck.length} Candidates`}</Text>
          //     </Flex>

          //     <Flex row className={styles.bulkButton}>
          //       <Flex
          //         row
          //         center
          //         style={{
          //           paddingLeft: '5px',
          //           borderLeft: '1px solid #581845',
          //           cursor: 'pointer',
          //         }}
          //         onClick={hanldeDownload}
          //         title="Download Resumes"
          //       >
          //         <SvgDownload width={14} height={14} />
          //         <Text style={{ marginLeft: '10px' }} color="theme">
          //             Export Resumes
          //         </Text>
          //       </Flex>
          //     </Flex>
          //   </Flex>
          // </Flex>
        }
      </Flex>
      <Flex row>
        <Flex className={styles.sortbybar}>
          <Text className={styles.sortText}>Sort By:</Text>
          <div className={styles.selectTagStyle}>
            <SelectTag
              stylechangess1
              id="mydatabasebulkaction__sort"
              value={isSortOptions}
              options={sortOptions}
              onChange={(options) => {
                setSortOptions(options);
                handlesortby(options.value);
              }}
            />
          </div>
        </Flex>
        <Flex title="Favourite Candidates">
          <div
            className={styles.pointer}
            // tabIndex={-1}
            role={'button'}
            onKeyPress={() => { }}
            onClick={filterTotalFav}
          >
            <Flex row center style={{ cursor: 'pointer' }}>
              <SvgHeart filled={isTotalFav} />
              <Text
                style={{ marginLeft: '5px' }}
                color="theme"
              >
                Favourites
              </Text>
            </Flex>
          </div>
        </Flex>
      </Flex>

      {/*        
     
        <div
          className={cx({ svgDownload: !downloadCheck, svgNone: downloadCheck })}
          title="Download Resume"
          onClick={hanldeDownload}
          tabIndex={-1}
          role={'button'}
          onKeyPress={() => { }}
        >
          <SvgDownload
            height={18}
            width={18}
            fill={downloadCheck ? GARY_4 : PRIMARY}
          />
        </div> */}
    </Flex>
  );
};

export default ZitaAction;
