import * as React from 'react';
const SvgIntegrations = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={26}
    fill="none"
    viewBox='0 0 27 26'
    {...props}
  >
    <path
      stroke="#581845"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M25.154 3.787v13.006a2.787 2.787 0 0 1-2.787 2.787H3.787A2.787 2.787 0 0 1 1 16.793V3.787m24.154 0A2.787 2.787 0 0 0 22.367 1H3.787A2.787 2.787 0 0 0 1 3.787m24.154 0v.301a2.79 2.79 0 0 1-1.325 2.373l-9.29 5.717a2.79 2.79 0 0 1-2.924 0l-9.29-5.715A2.79 2.79 0 0 1 1 4.089v-.302"
    />
    <path fill="#eee8ec" d="M12 14a2 2 0 0 1 2-2h12.368v13.263H12z" />
    <path
      fill="#581845"
      d="M14.812 17.433h8.416v-1.897a.38.38 0 0 0-.115-.267.34.34 0 0 0-.255-.121h-7.677a.35.35 0 0 0-.254.12.38.38 0 0 0-.115.268zm.37 7.198a.92.92 0 0 1-.693-.292 1.02 1.02 0 0 1-.279-.73v-8.073q0-.435.279-.728a.92.92 0 0 1 .692-.293h1.064v-1.41h.648v1.41h4.3v-1.41h.601v1.41h1.064q.415 0 .693.293t.278.728v3.32a1.7 1.7 0 0 0-.297-.084 10 10 0 0 0-.304-.051v-.656h-8.416v5.545q0 .146.115.267a.34.34 0 0 0 .254.122h4.03q.049.177.122.33.073.151.153.302zm7.676.632q-1.005 0-1.705-.736-.7-.735-.7-1.793 0-1.056.7-1.793a2.26 2.26 0 0 1 1.705-.736q1.006 0 1.705.736t.7 1.793q0 1.058-.7 1.793a2.27 2.27 0 0 1-1.705.736m.995-1.137.33-.345-1.094-1.15v-1.72h-.462v1.926z"
    />
  </svg>
);
export default SvgIntegrations;