export const templateHubStyles = `
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 24px;
    position: relative;
    vertical-align: middle;
    width: 75px;
    border-radius: 5px;
    border: 1px solid #581845;
}
.ql-snow .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 8px;
    padding-right: 2px;
    position: relative;
    width: 100%;
    color: #581845;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: unset;
    max-height: 350px;
    overflow-y: scroll;
    border-radius: 5px;
    padding: 5px 0px;
    width: 200px;
    border: none;
    font-size: 13px;
}
.ql-snow.ql-toolbar .ql-picker-label:hover{
    background-color: unset;
    margin: 0px;
    border-radius: 5px;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: unset;
    border-radius: 5px;
    color: #581845;
    border: none;
}
.ql-snow .ql-picker-options .ql-picker-item{
    padding: 5px;
}

.ql-snow.ql-toolbar .ql-picker-item.ql-selected{
    color: #444;    
    margin: 0;
}
.ql-snow.ql-toolbar .ql-picker-item:hover{
    color: #444;
    margin: 0;
}
.ql-toolbar.ql-snow .ql-picker-label{
    border: none;
}
.ql-snow .ql-editor p {
    font-size: 13px;
    line-height: 30px;
}
// .ql-snow .ql-editor p br {
//     display: contents;
// }
.tag-break{
    display: none;
  }
`;

export const emailAutomationStyles = `
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 24px;
    position: relative;
    vertical-align: middle;
    width: 75px;
    border-radius: 5px;
    border: 1px solid #581845;
}
.ql-snow .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 8px;
    padding-right: 2px;
    position: relative;
    width: 100%;
    color: #581845;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: unset;
    max-height: 350px;
    overflow-y: scroll;
    border-radius: 5px;
    padding: 5px 0px;
    width: 200px;
    border: none;
    height: 155px;
    right: 0;
    font-size: 13px;
}
.ql-snow.ql-toolbar .ql-picker-label:hover{
    background-color: unset;
    margin: 0px;
    border-radius: 5px;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: unset;
    border-radius: 5px;
    color: #581845;
    border: none;
}
.ql-snow .ql-picker-options .ql-picker-item{
    padding: 5px;
}

.ql-snow.ql-toolbar .ql-picker-item.ql-selected{
    color: #444;    
    margin: 0;
}
.ql-snow.ql-toolbar .ql-picker-item:hover{
    color: #444;
    margin: 0;
}
.ql-toolbar.ql-snow .ql-picker-label{
    border: none;
}
.ql-snow .ql-editor p {
    font-size: 13px;
    line-height: 30px;
}
.tag-break{
    display: none;
  }
`;
