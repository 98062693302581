// import * as React from 'react';
const defaultProps = {
  fill: '',
  width: 22,
  height: 22,
};
const Svgeditingnotes = ({ width, height, fill }: typeof defaultProps) => (
    <svg width="12" height="15" viewBox="0 1 12 12"   xmlns="http://www.w3.org/2000/svg">
<path d="M11.8744 2.47768L9.40876 0.118157C9.36931 0.0802585 9.32257 0.0503137 9.27118 0.0300392C9.21979 
0.00976462 9.1648 -0.000439186 9.10935 1.449e-05C9.05389 0.000468166 8.99908 0.0115716 8.94805
 0.032684C8.89702 0.0537964 8.85076 0.0844982 8.81196 0.123037L7.42383 1.49505L10.4913 4.43012L11.8795
  3.05871C11.9579 2.98095 12.0014 2.87611 12.0005 2.76718C11.9995 2.65826 11.9542 2.55415 11.8744 2.47768Z" fill={fill}/>
<path d="M1.68935 11.3498L0.642608 10.3477L0 12.001L1.68935 11.3498Z" fill={fill}/>
<path d="M2.9943 9.04762C3.11937 9.18966 3.29682 9.27822 3.48841 9.2942C3.67999 9.31018 3.87036 9.25232 
4.01848 9.13307L8.99499 4.02951L7.8654 2.94922L2.88889 8.05278C2.76886 8.19894 2.71266 8.3851 2.73238
 8.57117C2.7521 8.75724 2.84614 8.92834 2.9943 9.04762Z" fill={fill}/>
<path d="M1.94861 7.15069C1.88019 7.19202 1.82232 7.24792 1.77935 7.31417C1.73637 7.38042 1.70942
 7.4553 1.70052 7.53315C1.69163 7.611 1.70102 7.68979 1.72799 7.76361C1.75495 7.83742 1.79877 7.90431
  1.85619 7.95925C1.9136 8.01419 1.9831 8.05574 2.05942 8.08077C2.13574 8.10579 2.21693 8.11363 2.29682
   8.10371C2.37672 8.09379 2.45325 8.06636 2.52066 8.02349C2.58808 7.98062 2.6446 7.92342 2.68598
    7.85621L7.66122 2.75632L6.9245 2.05078L1.94861 7.15069Z" fill={fill}/>
<path d="M4.21754 9.3184C4.14925 9.35979 4.0915 9.41568 4.04862 9.4819C4.00575 9.54812 3.97888
 9.62295 3.97003 9.70073C3.96117 9.77851 3.97058 9.85723 3.99753 9.93097C4.02447 10.0047 4.06824
  10.0715 4.12557 10.1264C4.18291 10.1813 4.25231 10.2229 4.32854 10.248C4.40477 10.273 4.48584 
  10.281 4.56567 10.2711C4.6455 10.2613 4.72202 10.234 4.78944 10.1913C4.85686 10.1486 4.91346 10.0916 
  4.95495 10.0245L9.93145 4.92037L9.19408 4.21484L4.21754 9.3184Z" fill={fill}/>
</svg>

  );
  Svgeditingnotes.defaultProps = defaultProps;
  export default Svgeditingnotes;
  
  
   