
const defaultProps = {
    fill: '',
    width: 24,
    height: 24,
  };
  
  const SvgEventcopy = ({ width, height, fill  }: typeof defaultProps) => {
    return ( 
      <svg
      xmlns="http://www.w3.org/2000/svg"
      className="copyicon1_svg__svg-icon"
      style={{
        width: '19.5px',
        height: '19.5px',
        verticalAlign: 'middle',
        
        overflow: 'hidden',
      }}
      fill= {fill}
      viewBox="0 0 1024 1024"
    >
      <path d="M758 201.2H166c-35.3 0-64 28.7-64 64v592c0 35.3 28.7 64 64 64h592c35.3 0 64-28.7 64-64v-592c0-35.4-28.7-64-64-64zM504.8 664.5 411.3 758c-21.5 21.5-50.1 33.4-80.6 33.4s-59-11.8-80.6-33.4c-21.5-21.5-33.4-50.1-33.4-80.6 0-30.4 11.8-59 33.4-80.6l76.8-76.8c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-76.8 76.8c-9.4 9.4-14.6 22-14.6 35.3s5.2 25.9 14.6 35.3c9.4 9.4 22 14.6 35.3 14.6s25.9-5.2 35.3-14.6l93.5-93.5c17-17 19.5-43.7 6-63.4-10-14.6-6.3-34.5 8.3-44.5 14.6-10 34.5-6.3 44.5 8.3 30.9 45.2 25.2 106.2-13.5 144.9zm169-139L597 602.3c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l76.8-76.8c9.4-9.4 14.6-22 14.6-35.3s-5.2-25.9-14.6-35.3c-19.5-19.5-51.1-19.5-70.6 0l-93.5 93.5c-17 17-19.5 43.7-6 63.4 10 14.6 6.3 34.5-8.3 44.5-5.5 3.8-11.8 5.6-18 5.6-10.2 0-20.2-4.9-26.4-13.9-30.9-45.2-25.2-106.1 13.5-144.8l93.5-93.5c44.4-44.4 116.7-44.4 161.1 0 21.5 21.5 33.4 50.1 33.4 80.6 0 30.4-11.9 59-33.4 80.5z" />
      <path d="M858 101.2H266c-35.3 0-64 28.7-64 64v16h576c35.3 0 64 28.7 64 64v576h16c35.3 0 64-28.7 64-64v-592c0-35.4-28.7-64-64-64z" />
    </svg>
    
    );
  };
  SvgEventcopy.defaultProps = defaultProps;
  
  export default SvgEventcopy;
  