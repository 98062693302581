import { useSelector } from 'react-redux';
import { useState } from 'react';
import { RootState } from '../../store';
import Text from '../../uikit/Text/Text';
import Flex from '../../uikit/Flex/Flex';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import LinkWrapper from '../Link/LinkWrapper';
import SingleButton from '../../modules/common/SingleButton';
import styles from './blur.module.css';


type Props = {
    width?: any;
    height?: any;
    content?: string;
    Addon?: boolean;
    Addondata?: any;
    upgrade?: boolean;
    rightalign?: string;
    marginTop?: number;
    iscount?: boolean;
    count?: number;
    handlefunction: (a: any) => void;
    popuponly?: boolean;
}

const Blur = ({
    width,
    height,
    content,
    Addon,
    Addondata,
    upgrade,
    rightalign,
    marginTop,
    iscount,
    count,
    handlefunction,
    popuponly
}: Props) => {
    const [isNoPermission, setNoPermission] = useState(false);
    const {
        super_user
    } = useSelector(({ permissionReducers }: RootState) => ({
        super_user: permissionReducers.super_user
    }));

    return (
        <>
            <Flex className={styles.drop} row>
                <Flex className={styles.drop1} height={height} style={{ right: rightalign }} width={width} marginTop={marginTop}>
                    <Flex middle center marginBottom={15}>
                        <Flex className={styles.crownicons} center middle>
                            <SvgSubcriptioncrown />
                        </Flex>
                    </Flex>
                    <Flex center middle style={{ textAlign: 'center' }} className={styles.overallback}>
                        {iscount ?
                            <>
                                <Flex middle center>{count}+ Applicants</Flex>
                                <Flex middle center>
                                    {super_user === true ?
                                        <Text color='theme' bold size={13} style={{ cursor: 'pointer' }} onClick={() => handlefunction(true)}>Upgrade{' '}</Text>
                                        :
                                        <Text color='theme' bold size={13} style={{ cursor: 'pointer' }} onClick={() => setNoPermission(true)}>Upgrade{' '}</Text>}
                                </Flex>
                            </> :
                            <Text >
                                {/* If upgrade is available */}
                                {upgrade &&
                                    <>  {super_user === true ?
                                        <Text color='theme' bold size={13} style={{ marginRight: '2.5px', cursor: 'pointer' }} onClick={() => handlefunction(true)} >Upgrade{' '}</Text>
                                        :
                                        <Text color='theme' bold size={13} style={{ marginRight: '2.5px', cursor: 'pointer' }} onClick={() => setNoPermission(true)} >Upgrade{' '}</Text>
                                    }</>
                                }

                                {/* If addon and upgrade are available */}
                                {upgrade && Addon && <Text>or purchase</Text>}

                                {/* If addon is available */}
                                {Addon &&
                                    <>  {super_user === true ?
                                        <Text color='theme' bold size={13} style={{ marginRight: '2.5px', cursor: 'pointer' }} onClick={() => handlefunction(true)}>{' '}Add-on{' '}</Text>
                                        :
                                        <Text color='theme' bold size={13} style={{ marginRight: '2.5px', cursor: 'pointer' }} onClick={() => setNoPermission(true)}>{' '}Add-on{' '}</Text>}</>
                                }
                                {/* After addon or upgrate text */}
                                {content}
                            </Text>}
                    </Flex>
                </Flex>
            </Flex>
            <SingleButton
                btnTitle="OK"
                title={'Please contact your company admin to upgrade.'}
                open={isNoPermission}
                btnOnclick={() => setNoPermission(false)}
            />
        </>
    )
}
export default Blur;
