import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TooltipLite from 'react-tooltip-lite';
import { useFormik } from 'formik';
import { useStages } from '../../hooks/useStages';
import {
  Button,
  Card,
  InputSwitch,
  Loader,
  Modal,
  SelectTag,
  Toast,
} from '../../uikit';
import Text from '../../uikit/Text/Text';
import Flex from '../../uikit/Flex/Flex';
import styles from '../applicantpipelinemodule/applicantemailautomation.module.css';
import SvgEdit from '../../icons/SvgEdit';
import SvgView from '../../icons/SvgView';
import SvgPlusCircle from '../../icons/SvgAddCircle';
import { StageData } from '../../hooks/useStages/types';
import SvgClose from '../../icons/SvgClose';
import { AppDispatch, RootState } from '../../store';
import { isEmpty } from '../../uikit/helper';
import { companyPageInitalMiddleWare } from '../accountsettingsmodule/store/middleware/accountsettingmiddleware';
import SingleButton from '../common/SingleButton';
import AutomationEmailedit from './AutomationEmailedit';
import { NewApplicantstageEmail } from './NewApplicantstageEmail';
import ApplicantEmailImportScreen from './ApplicantEmailImportScreen';
import {
  createemailcontentpostMiddleWare,
  emailAutomationMiddleware,
  emailcontentpostMiddleWare,
  getKanbanStagesMiddleWare,
} from './store/middleware/applicantpipelinemiddleware';

type Props = {
  open: boolean;
  stages: StageData[];
  setShowAutomatePopup: any;
  jd_id: number;
  default_stage: any;
  setInitialToggleStates: any;
  job_details: any;
  isLoader: any;
  setLoader: any;
};

const ApplicantEmailAutomation = ({
  open,
  stages,
  setShowAutomatePopup,
  jd_id,
  default_stage,
  setInitialToggleStates,
  job_details,
  isLoader,
  setLoader,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectTagCount, setSelectTagCount] = useState(0);
  const [isShowImportTemplate, setShowImportTemplate] = useState(false);
  const [isContentLenError, setContentLenError] = useState(false);
  const [isNewApplicantEditEmail, setNewApplicantEditEmail] = useState(true);
  const [templatemodel, settemplatemodel] = useState(false);
  const [istemplate, settemplate] = useState(false);
  const [istoggle, settoggle] = useState({});
  const [isAutomateError, setAutomateError] = useState('');
  const [newStageEmailDone, setnewStageEmailDone] = useState(false);
  const [TitleError, setTitleError] = useState(false);
  const [isTooltipOpensetting, setIsTooltipOpensetting] = useState(false);
  const [isTooltipKey, setTooltipKey] = useState(0);
  const [SelectedTemplate, setSelectedTemplate] = useState(null);
  const [isDefualtToggleID, setDefualtToggleID] = useState<any>();
  const [isShowCreateError, setShowCreateError] = useState('');
  const [isShowSelectTag, setShowSelectTag] = useState(false);
  const [isNewStageEmailScreen, setNewStageEmailScreen] = useState(false);
  const [Contentvalue, setContentvalue] = useState<any>(null);
  const [isContentError, setContentError] = useState(false);
  const [isContent, setContent] = useState('');
  const [isTitle, setTitle] = useState('');
  const [isSubject, setSubject] = useState('');
  const [errorsubject, seterrorsubject] = useState(false);
  const [SubjectError, setSubjectError] = useState(false);
  const [SubjectExist, setSubjectExist] = useState(false);
  const [TitleExist, setTitleExist] = useState(false);
  const [isReload, setReload] = useState(false);
  const [isEditEmailContent, setEditEmailContent] = useState(false);
  const [isViewEmailContent, setViewEmailContent] = useState(false);
  const [isShowEmailScreen, setShowEmailScreen] = useState(false);
  const [toggledStageName, setToggledStageName] = useState('');
  const [selectedStageOptionName, setSelectedStageOptionName] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [toggleStates, setToggleStates] = useState<any>();
  const [ImportTemplateID, setImportTemplateID] = useState<any>();
  const [isContentMinLen, setContentMinLen] = useState(false);
  const [isTagIntemation, setTagIntemation] = useState(false);
  const [isDisableTag, setDisableTag] = useState('');

  //Test Creation
  const [disableAutomateRule, setDisableAutomateRule] = useState(false);

  const handleEditEmailTemplate = () => {
    if (
      Contentvalue !== null ||
      isContent !== null ||
      isTitle !== null ||
      isSubject !== null
    ) {
      setContentvalue(null);
      setContent(null);
      setSubject(null);
      setTitle(null);
    }
    setEditEmailContent(true);
    setViewEmailContent(false);
    setNewStageEmailScreen(false);
    setShowCreateError('');
  };

  const editfunction = (item: any, id?: any, name?: any) => {
    if (Contentvalue !== null) {
      setContentvalue(null);
    }
    handleEditEmailTemplate();
    const selected = template?.find(
      (t) => t?.stage === id && Number(t?.jd_id_id) === Number(jd_id),
    );
    const newapplicant = template?.find(
      (temp) =>
        temp?.stage === 0 || (null && Number(temp?.jd_id_id) === Number(jd_id)),
    );
    if (id === 0) {
      setSelectedTemplate(newapplicant);
      setContentvalue(newapplicant);
    } else if (id !== 0) {
      setSelectedTemplate(selected);
      setContentvalue(selected);
    }
    setShowEmailScreen(true);
    setNewStageEmailScreen(false);
    setToggledStageName(name);
    setSelectedStageOptionName(name);
    setDefualtToggleID(id);
    // setnewstageToggleID(id);
  };

  const formik = useFormik({
    initialValues: {
      userMessage: '',
      userSubject: '',
      userTitle: '',
    },
    onSubmit: () => {},
  });

  const {
    default_stages,
    new_stages,
    template,
    title,
    applicants_list,
    company_detail,
    user,
  } = useSelector(
    ({
      kanbanStagesReducers,
      EmailAutomationReducers,
      applicantPipeLineDataReducers,
      myJobPostingDataReducers,
      companyPageReducers,
      userProfileReducers,
    }: RootState) => {
      return {
        default_stages: kanbanStagesReducers?.default_stage,
        new_stages: kanbanStagesReducers?.new_stages,
        template: EmailAutomationReducers.template,
        title: EmailAutomationReducers.title,
        applicants_list: applicantPipeLineDataReducers.applicants_list,
        final_list: myJobPostingDataReducers.final_list,
        company_detail: companyPageReducers.company_detail,
        user: userProfileReducers.user,
      };
    },
  );

  useEffect(() => {
    dispatch(companyPageInitalMiddleWare());
  }, []);

  useEffect(() => {
    const initialStates = {};
    setInitialToggleStates(initialStates);
  }, [default_stages]);

  // to store the Selected Stage Mail Content that was Edited
  useEffect(() => {
    if (template) {
      const selected = template?.find(
        (t) =>
          t?.stage === isDefualtToggleID &&
          Number(t?.jd_id_id) === Number(jd_id),
      );
      setSelectedTemplate(selected);
    }
    setToggledStageName(toggledStageName);
  }, [template]);

  const emailToggleIds = stages
    ?.filter((stage) => stage.email_toggle === true)
    ?.map((stage) => stage.id);

  const handleViewEmailTemplate = (stageName: any, stageID?: any) => {
    if (Contentvalue !== null) {
      setContentvalue(null);
    }
    const selected = template?.find(
      (t) => t?.stage === stageID && Number(t?.jd_id_id) === Number(jd_id),
    );
    setShowCreateError('');
    setToggledStageName(stageName);
    setSelectedStageOptionName(stageName);
    setDefualtToggleID(stageID);
    setSelectedTemplate(selected);
    setViewEmailContent(true);
    setShowEmailScreen(true);
    setEditEmailContent(false);
  };

  const handleMouseEntersetting = () => {
    setIsTooltipOpensetting(true);
  };
  const handleMouseLeavesetting = () => {
    setIsTooltipOpensetting(false);
  };

  useEffect(() => {
    setTooltipKey((prevKey) => prevKey + 1);
  }, [isTooltipOpensetting]);

  const handleImportTemplate = () => {
    setShowImportTemplate(true);
    settemplatemodel(!templatemodel);
    setShowCreateError('');
    setAutomateError('');
  };

  const handleSelectChange = (index, option) => {
    setNewStageEmailScreen(true);
    setShowEmailScreen(true);
    setViewEmailContent(false);
    setSelectedOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = option;
      return newOptions;
    });
    setDisableAutomateRule(!disableAutomateRule);
    handleToggleOption(index, option);
    setAutomateError('');
  };

  const handleScroll = () => {
    const selectTagCalc = document.getElementById('selectTagScroll');
    if (selectTagCalc) {
      selectTagCalc.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleautomateClose = () => {
    if (Contentvalue !== null) {
      setContentvalue(null);
    }
    const filteredToggleStates = Object.fromEntries(
      Object.entries(istoggle)?.filter(([stageId]) =>
        emailToggleIds.includes(parseInt(stageId)),
      ),
    );
    settoggle(filteredToggleStates);
    setDisableAutomateRule(false);
    setSelectTagCount(0);
    setShowSelectTag(false);
    setShowAutomatePopup(false);
    setShowEmailScreen(false);
    setSelectedOptions([]);
    setNewStageEmailScreen(false);
    setEditEmailContent(false);
    setViewEmailContent(false);
    setAutomateError('');
    setnewStageEmailDone(false);
    setTitleExist(false);
    setSubjectError(false);
    setContentError(false);
    setTitleError(false);
  };

  const handlePopupCancel = () => {
    const filteredToggleStates = Object.fromEntries(
      Object.entries(istoggle)?.filter(([stageId]) =>
        emailToggleIds.includes(parseInt(stageId)),
      ),
    );
    setDisableAutomateRule(false);
    settoggle(filteredToggleStates);
    setSelectTagCount(0);
    setShowSelectTag(false);
    setShowEmailScreen(false);
    setSelectedOptions([]);
    setNewStageEmailScreen(false);
    setEditEmailContent(false);
    setViewEmailContent(false);
    setAutomateError('');
    setnewStageEmailDone(false);
    setTitleExist(false);
    setSubjectError(false);
    setContentError(false);
    setTitleError(false);
  };

  const handleToggleOption = (index, option?: any, StageID?: any) => {
    setSelectedOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = {
        ...newOptions[index],
        selected: !newOptions[index]?.selected || false,
      };
      return newOptions;
    });
    setAutomateError('');
    setToggledStageName(option?.label);
    setSelectedStageOptionName(option?.label);
    setDefualtToggleID(option?.value);
  };

  const handleToggleStage = (stageId, stagename?: any) => {
    settoggle((prevState) => ({
      ...prevState,
      [stageId]: !prevState[stageId],
    }));
    setToggledStageName(stagename);
    setSelectedStageOptionName(stagename);
    setNewStageEmailScreen(false);
    setAutomateError('');
    handleSendEmail({ [stageId]: !istoggle[stageId] });
  };

  const emailtemplate = template?.filter(
    (mytemplate) => Number(mytemplate.jd_id_id) === Number(jd_id),
  );

  const handleSendEmail = (togglefunc?: any) => {
    setLoader(true);

    const [[stageId, toggleValue]] = Object.entries(togglefunc);

    setLoader(false);

    let formData = new FormData();

    formData.append('jd_id', jd_id.toString());
    formData.append('stage_id', JSON.stringify([Number(stageId)]));
    formData.append('email_toggle', JSON.stringify([toggleValue]));
    dispatch(emailcontentpostMiddleWare({ formData })).then((res) => {
      const foundStage = res.payload.data.find(
        (item) => item.id === Number(stageId),
      );
      if (res.payload.success === true) {
        dispatch(getKanbanStagesMiddleWare({ jd_id: jd_id }));
        setLoader(false);
        Toast('Automation rule enabled successfully.');
      }
      if (foundStage && !foundStage.email_toggle) {
        dispatch(getKanbanStagesMiddleWare({ jd_id: jd_id }));
        setLoader(false);
        Toast('Automation rule disabled successfully.');
      }
    });
    setShowSelectTag(false);
    setShowEmailScreen(false);
    setViewEmailContent(false);
    setEditEmailContent(false);
    setNewStageEmailScreen(false);
    setNewApplicantEditEmail(false);
  };

  const handleScrollToSelectTag = () => {
    const selectFlexScroll = document.getElementById('selectFlexScroll');
    if (selectFlexScroll) {
      selectFlexScroll.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const selectFlexScroll = document.getElementById('selectFlexScroll');
    if (isShowSelectTag) {
      if (selectFlexScroll) {
        selectFlexScroll.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isShowSelectTag]);

  const handleNewAutomateRule = () => {
    if (
      selectTagCount < new_stages?.length ||
      matchedStagesLength !== newCount
    ) {
      setShowSelectTag(true);
      setSelectTagCount((prevCount) => prevCount + 1);
      setEditEmailContent(false);
      setViewEmailContent(false);
      setDisableAutomateRule(!disableAutomateRule);
      if (isShowEmailScreen) {
        setNewStageEmailScreen(true);
      } else {
        setNewStageEmailScreen(false);
      }
      setShowCreateError('');
      handleScrollToSelectTag();
    }
  };

  const handleNewAutomateRuleClose = () => {
    setShowSelectTag(false);
    setSelectTagCount(0);
    setSelectedOptions([]);
    setEditEmailContent(false);
    setViewEmailContent(false);
    setNewStageEmailScreen(false);
    setShowEmailScreen(false);
    setAutomateError('');
    setShowCreateError('');
    setDisableAutomateRule(false);
    setTitleExist(false);
    setSubjectError(false);
    setContentError(false);
    setTitleError(false);
  };

  const handleModelClose = () => {
    settemplatemodel(false);
    setNewStageEmailScreen(!isNewStageEmailScreen);
    if (isNewStageEmailScreen) {
      setNewStageEmailScreen(true);
    } else if (!isNewStageEmailScreen) {
      setNewStageEmailScreen(false);
    }
    setAutomateError('');
    setShowCreateError('');
  };

  const parser = new DOMParser();
  const handlemessage = (values) => {
    const doc = parser.parseFromString(values, 'text/html');
    const textNodes = doc.querySelectorAll('body')[0].textContent;
    const texttrim = textNodes.trim();
    return texttrim;
  };

  const htmlToPlainText = (html) => {
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent;
  };

  const plainTextDescription = htmlToPlainText(isContent);

  const handleCreateEmailTemplate = () => {
    const selectedStages = selectedOptions?.find(
      (option) => option.selected,
    )?.selected;

    const selectedStagesID = selectedOptions?.find(
      (option) => option.selected,
    )?.value;

    const NewStage = { [selectedStagesID]: selectedStages };

    const NewStageId = selectedStages;

    const emailDesc = handlemessage(isContent);

    if (isSubject === '' || isSubject === null) {
      setSubjectError(true);
    } else {
      setSubjectError(false);
    }

    if (isTitle === '' || isTitle === null) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }

    if (
      (isContent === '' && isSubject === '' && isTitle === '') ||
      (isContent === null && isSubject === null && isTitle === null) ||
      isContent === null ||
      isSubject === null ||
      isTitle === null
    ) {
      if (isSubject === '' || isSubject === null) {
        setSubjectError(true);
      } else {
        setSubjectError(false);
      }

      if (isTitle === '' || isTitle === null) {
        setTitleError(true);
      } else {
        setTitleError(false);
      }

      if (isContent === '' || isContent === null) {
        setContentError(true);
      } else {
        setContentError(false);
      }
    } else if (
      TitleError === false &&
      isContentError === false &&
      SubjectError === false &&
      isContentLenError === false &&
      isContentMinLen === false
    ) {
      let formData = new FormData();
      if (Contentvalue !== null) {
        formData.append('template_id', Contentvalue?.id);
      }

      if (Contentvalue === null && ImportTemplateID) {
        formData.append('template_id', ImportTemplateID);
      }

      formData.append('jd_id', jd_id.toString());
      formData.append('rich_text', isContent);
      formData.append('subject', isSubject);
      formData.append('text', plainTextDescription);
      formData.append('candidate_id', '');
      formData.append('title', isTitle);
      formData.append('stageName', toggledStageName);
      formData.append('isactive', 'False');
      formData.append('stage_id', JSON.stringify([Number(isDefualtToggleID)]));
      formData.append('toggle', JSON.stringify([NewStageId]));

      setLoader(true);
      dispatch(createemailcontentpostMiddleWare({ formData })).then((res) => {
        if (res.payload.success === true) {
          if (Contentvalue !== null) {
            if (isShowSelectTag) {
              Toast('Automation rule enabled successfully.', 'LONG', 'success');

              dispatch(
                emailAutomationMiddleware({ jd_id: jd_id.toString() }),
              ).then(() => {
                setReload(false);
                if (res.payload.success === true) {
                  dispatch(getKanbanStagesMiddleWare({ jd_id: jd_id }));
                  setLoader(false);
                  setReload(false);
                  setShowSelectTag(false);
                  setnewStageEmailDone(true);
                  setEditEmailContent(false);
                  setViewEmailContent(false);
                  setToggledStageName(toggledStageName);
                  setSelectedStageOptionName(toggledStageName);
                  setShowEmailScreen(false);
                  setImportTemplateID('');
                }
              });
              dispatch(getKanbanStagesMiddleWare({ jd_id: jd_id }));
            } else if (!isShowSelectTag) {
              Toast('Email template updated successfully.', 'LONG', 'success');

              dispatch(
                emailAutomationMiddleware({ jd_id: jd_id.toString() }),
              ).then(() => {
                setReload(false);
                if (res.payload.success === true) {
                  dispatch(getKanbanStagesMiddleWare({ jd_id: jd_id }));
                  setLoader(false);
                  setReload(false);
                  setShowSelectTag(false);
                  setnewStageEmailDone(true);
                  setEditEmailContent(false);
                  setViewEmailContent(false);
                  setToggledStageName(toggledStageName);
                  setSelectedStageOptionName(toggledStageName);
                  setShowEmailScreen(false);
                  setImportTemplateID('');
                }
              });
              dispatch(getKanbanStagesMiddleWare({ jd_id: jd_id }));
            }
          } else {
            Toast('Automation rule enabled successfully.', 'LONG', 'success');
            dispatch(
              emailAutomationMiddleware({ jd_id: jd_id.toString() }),
            ).then(() => {
              setReload(false);
              if (res.payload.success === true) {
                dispatch(getKanbanStagesMiddleWare({ jd_id: jd_id }));
                setLoader(false);
                setReload(false);
                setShowSelectTag(false);
                setnewStageEmailDone(true);
                setShowEmailScreen(false);
                setEditEmailContent(false);
                setViewEmailContent(false);
                setToggledStageName(toggledStageName);
                setSelectedStageOptionName(toggledStageName);
                setShowEmailScreen(false);
                setImportTemplateID('');
              }
            });
            dispatch(getKanbanStagesMiddleWare({ jd_id: jd_id }));
          }
        } else {
          Toast(
            'Oops! Something went wrong. Please try again shortly.',
            'LONG',
            'error',
          );
        }
        setSelectedOptions([]);
      });
    }
  };

  const { NEW_APPLICANT_STAGE } = useStages(stages);

  const matchedStages = new_stages?.filter((stage) =>
    emailtemplate?.map((templateeeee) => templateeeee.stage === stage.id),
  );

  const matchedStagesLength = matchedStages.length;

  const newCount = new_stages.length;

  const Stagesoptions = new_stages
    ?.filter(
      (stage) =>
        !emailtemplate?.some((mytemplate) => mytemplate.stage === stage.id),
    )
    ?.map((stage) => ({
      label: stage.stage_name,
      value: stage.id,
    }));

  const hasUpperCaseWord = (parts: string[]) => {
    return parts.some((word) => word === word.toUpperCase());
  };

  const changeCase = (str: string) => {
    const parts = str.split(' ');

    if (hasUpperCaseWord(parts) === true) {
      return parts
        .map((word) =>
          word === word.toUpperCase() ? word : word.toLowerCase(),
        )
        .join(' ');
    }
    if (hasUpperCaseWord(parts) === false) {
      return str.toLowerCase();
    }
  };

  // intially updating in state the toggle button checking is true or false based on the response.
  useEffect(() => {
    const updatedToggleStates = {};

    default_stages?.forEach((stage) => {
      if (
        stage &&
        typeof stage === 'object' &&
        'email_toggle' in stage &&
        stage.email_toggle
      ) {
        updatedToggleStates[stage.id] = true;
      }
    });
    settoggle((prevState) => ({ ...prevState, ...updatedToggleStates }));
    setToggleStates((prevState) => ({ ...prevState, ...updatedToggleStates }));
  }, [default_stages]);

  useEffect(() => {
    const updatedToggleStates = {};

    new_stages?.forEach((stage) => {
      if (
        stage &&
        typeof stage === 'object' &&
        'email_toggle' in stage &&
        stage.email_toggle
      ) {
        updatedToggleStates[stage.id] = true;
      }
    });
    settoggle((prevState) => ({ ...prevState, ...updatedToggleStates }));
    setToggleStates((prevState) => ({ ...prevState, ...updatedToggleStates }));
  }, [new_stages]);

  useEffect(() => {
    if (Contentvalue !== null && Contentvalue !== undefined) {
      setContent(Contentvalue?.templates);
      setSubject(Contentvalue?.subject);
      setTitle(Contentvalue?.name);
    }
    dispatch(emailAutomationMiddleware({ jd_id: jd_id.toString() }));
  }, [dispatch, Contentvalue]);

  const handleOptionSwitch = (id, label, value) => {
    // if (isNewStageEmailScreen === true || isEditEmailContent === true) {
    //   setShowCreateError(
    //     'Please create email content to complete the automation rule',
    //   );
    //   return;
    // }
    // if (isNewStageEmailScreen === false || isEditEmailContent === false) {
    //   setShowCreateError('');
    //   handleToggleOption(id, label, value);
    // }

    if (isNewStageEmailScreen || isEditEmailContent) {
      if (isShowSelectTag) {
        setShowCreateError(
          'Please create email content to complete the automation rule',
        );
      } else if (!isShowSelectTag) {
        setShowCreateError(
          'Please complete editing the email content to proceed.',
        );
      }
      return;
    } else if (!isNewStageEmailScreen || !isEditEmailContent) {
      if (isShowSelectTag) {
        setAutomateError(
          'Please create email content to complete the automation rule',
        );
      } else if (!isShowSelectTag) {
        setAutomateError(
          'Please complete editing the email content to proceed.',
        );
      }
      return;
    } else {
      setShowCreateError('');
      handleToggleOption(id, label, value);
    }
  };

  {
    console.log(
      'Statements',
      isNewStageEmailScreen,
      isEditEmailContent,
      isShowSelectTag,
    );
  }

  const handleSwitch = (id, name) => {
    if (isNewStageEmailScreen || isEditEmailContent) {
      if (isShowSelectTag) {
        setShowCreateError(
          'Please create email content to complete the automation rule',
        );
      }
      if (!isShowSelectTag) {
        setShowCreateError(
          'Please complete editing the email content to proceed.',
        );
      }
      return;
    }
    if (isShowSelectTag) {
      setAutomateError(
        'Please create email content to complete the automation rule',
      );
    }
    if (!isShowSelectTag) {
      setAutomateError(
        '',
      );
      handleToggleStage(id, name);
    }
    setShowCreateError('');
  };

  return (
    <>
      <Modal open={open}>
        <Flex
          className={styles.automatePopup}
          width={isShowEmailScreen ? window.innerWidth / 1.3 : ''}
          maxHeight={window.innerHeight - 102}
        >
          {isLoader && <Loader />}
          {/* Popup Heading */}
          <Flex className={styles.popUpheadingflex}>
            <Text bold size={14}>
              Automations
            </Text>

            <Flex onClick={handleautomateClose}>
              <SvgClose
                width={10}
                height={10}
                fill={'#888888'}
                cursor={'pointer'}
              />
            </Flex>
          </Flex>

          {/* Popup Content */}
          <Flex between row className={styles.templateContent}>
            <Flex
              width={isShowEmailScreen ? '50%' : ''}
              className={styles.leftContent}
            >
              <Flex marginBottom={15}>
                <Text>
                  You can adjust and auto-send emails with a simple toggle as
                  applicants move through stages.
                </Text>
              </Flex>

              <Flex
                style={{
                  display: 'flex',
                  maxHeight: window.innerHeight - 333,
                }}
              >
                <Flex
                  title={
                    new_stages?.length === 0 && Stagesoptions?.length === 0
                      ? 'New automation is yet to be created in pipeline.'
                      : new_stages?.length !== 0 &&
                        selectedOptions?.length === Stagesoptions?.length &&
                        'All stage emails automated. Add stages to enable more rules.'
                  }
                  direction_tooltip="left"
                >
                  <div
                    onMouseEnter={handleMouseEntersetting}
                    onMouseLeave={handleMouseLeavesetting}
                  >
                    <Button
                      types="secondary"
                      className={styles.newAutomateBtn}
                      disabled={
                        (new_stages?.length !== 0 &&
                          Stagesoptions?.length <= 0) ||
                        disableAutomateRule
                        // || matchedStagesLength === Stagesoptions.length
                      }
                      onClick={() => {
                        if (
                          isNewStageEmailScreen === true ||
                          isEditEmailContent === true ||
                          isEmpty(selectedOptions)
                        ) {
                          if (isShowSelectTag) {
                            setShowCreateError(
                              'Please create email content to complete the automation rule',
                            );
                          } else if (!isShowSelectTag) {
                            setShowCreateError(
                              'Please complete editing the email content to proceed.',
                            );
                          }
                          return;
                        } else if (
                          isNewStageEmailScreen === false ||
                          isEditEmailContent === false ||
                          isEmpty(selectedOptions)
                        )
                          setShowCreateError('');
                        handleNewAutomateRule();
                      }}
                    >
                      <Flex row center width={'-webkit-fill-available'}>
                        <SvgPlusCircle fill="#581845" />
                        <Text
                          color="theme"
                          bold
                          size={13}
                          style={{ marginLeft: '5px' }}
                        >
                          Add new automation rule
                        </Text>
                      </Flex>
                    </Button>
                  </div>
                </Flex>

                <Flex row marginBottom={5}>
                  <Flex flex={1}>
                    <Text bold size={14}>
                      Stage Name
                    </Text>
                  </Flex>
                  <Flex flex={1} center middle>
                    <Text bold size={14}>
                      Toggle
                    </Text>
                  </Flex>
                  <Flex flex={1} middle>
                    <Text bold size={14}>
                      Email Template
                    </Text>
                  </Flex>
                </Flex>

                <Flex style={{ overflow: 'scroll' }}>
                  <NewApplicantstageEmail
                    doc={NEW_APPLICANT_STAGE}
                    handleNewApplicantViewEmailTemplate={
                      handleViewEmailTemplate
                    }
                    selectedTemplate={SelectedTemplate}
                    editfunction={editfunction}
                    isNewStageEmailScreen={isNewStageEmailScreen}
                    isEditEmailContent={isEditEmailContent}
                    isShowSelectTag={isShowSelectTag}
                    setShowCreateError={setShowCreateError}
                  />

                  {default_stages?.map((stage) => (
                    <Flex key={stage.id} row marginBottom={10} marginTop={10}>
                      <Flex flex={1}>
                        <Text>{stage.stage_name} </Text>
                      </Flex>

                      <Flex flex={1} row center middle>
                        <Flex
                          marginLeft={
                            istoggle && istoggle[stage.id] === true ? 12 : 16
                          }
                        >
                          <InputSwitch
                            onClick={() => {
                              handleSwitch(stage.id, stage.stage_name);
                            }}
                            checked={istoggle && istoggle[stage.id] === true}
                          />
                        </Flex>
                        {istoggle && istoggle[stage.id.toString()] === true ? (
                          <Text color="theme">ON</Text>
                        ) : (
                          <Text color="theme">OFF</Text>
                        )}
                      </Flex>

                      <Flex flex={1} row center middle>
                        <Flex
                          title="View email"
                          onClick={() => {
                            if (
                              isNewStageEmailScreen === true ||
                              isEditEmailContent === true
                            ) {
                              if (isShowSelectTag) {
                                setShowCreateError(
                                  'Please create email content to complete the automation rule',
                                );
                              } else if (!isShowSelectTag) {
                                setShowCreateError(
                                  'Please complete editing the email content to proceed.',
                                );
                              }
                              return;
                            }
                            if (
                              isNewStageEmailScreen === false ||
                              isEditEmailContent === false
                            ) {
                              setShowCreateError('');
                              handleViewEmailTemplate(
                                stage.stage_name,
                                stage.id,
                              );
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <SvgView height={18} width={18} />
                        </Flex>
                        <Flex
                          title="Edit email"
                          marginLeft={20}
                          onClick={() => {
                            if (
                              isNewStageEmailScreen === true ||
                              isEditEmailContent === true
                            ) {
                              if (isShowSelectTag) {
                                setShowCreateError(
                                  'Please create email content to complete the automation rule',
                                );
                              } else if (!isShowSelectTag) {
                                setShowCreateError(
                                  'Please complete editing the email content to proceed.',
                                );
                              }
                              return;
                            }
                            if (
                              isNewStageEmailScreen === false ||
                              isEditEmailContent === false
                            ) {
                              setShowCreateError('');
                              editfunction(
                                SelectedTemplate,
                                stage.id,
                                stage.stage_name,
                              );
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <SvgEdit width={14} height={14} fill={'#581845'} />
                        </Flex>
                      </Flex>
                    </Flex>
                  ))}

                  {new_stages?.map((stage) => {
                    const matchingTemplate = emailtemplate?.find(
                      (templatesssss) => templatesssss.stage === stage.id,
                    );

                    if (matchingTemplate) {
                      return (
                        <Flex
                          key={stage.id}
                          row
                          marginBottom={10}
                          marginTop={10}
                        >
                          <Flex flex={1}>
                            <Text>{stage.stage_name}</Text>
                          </Flex>
                          <Flex flex={1} row center middle>
                            <Flex
                              marginLeft={
                                istoggle &&
                                istoggle[stage.id.toString()] === true
                                  ? 12
                                  : 16
                              }
                            >
                              <InputSwitch
                                onClick={() => {
                                  if (
                                    isNewStageEmailScreen ||
                                    isEditEmailContent
                                  ) {
                                    if (isShowSelectTag) {
                                      setShowCreateError(
                                        'Please create email content to complete the automation rule',
                                      );
                                    } else if (!isShowSelectTag) {
                                      setShowCreateError(
                                        'Please complete editing the email content to proceed.',
                                      );
                                    }
                                    return;
                                  }
                                  setShowCreateError('');
                                  handleToggleStage(stage.id, stage.stage_name);
                                }}
                                checked={
                                  istoggle &&
                                  istoggle[stage.id.toString()] === true
                                }
                              />
                            </Flex>
                            {istoggle &&
                            istoggle[stage.id.toString()] === true ? (
                              <Text color="theme">ON</Text>
                            ) : (
                              <Text color="theme">OFF</Text>
                            )}
                          </Flex>
                          <Flex flex={1} row center middle>
                            <Flex
                              title="View email"
                              onClick={() => {
                                if (
                                  isNewStageEmailScreen ||
                                  isEditEmailContent
                                ) {
                                  if (isShowSelectTag) {
                                    setShowCreateError(
                                      'Please create email content to complete the automation rule',
                                    );
                                  } else if (!isShowSelectTag) {
                                    setShowCreateError(
                                      'Please complete editing the email content to proceed.',
                                    );
                                  }
                                  return;
                                }
                                setShowCreateError('');
                                handleViewEmailTemplate(
                                  stage.stage_name,
                                  stage.id,
                                );
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <SvgView height={18} width={18} />
                            </Flex>
                            <Flex
                              title="Edit email"
                              marginLeft={20}
                              onClick={() => {
                                if (
                                  isNewStageEmailScreen ||
                                  isEditEmailContent
                                ) {
                                  if (isShowSelectTag) {
                                    setShowCreateError(
                                      'Please create email content to complete the automation rule',
                                    );
                                  } else if (!isShowSelectTag) {
                                    setShowCreateError(
                                      'Please complete editing the email content to proceed.',
                                    );
                                  }
                                  return;
                                }
                                setShowCreateError('');
                                editfunction(
                                  matchingTemplate,
                                  stage.id,
                                  stage.stage_name,
                                );
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <SvgEdit
                                width={14}
                                height={14}
                                fill={'#581845'}
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                      );
                    }

                    return null;
                  })}

                  <Flex id="selectFlexScroll">
                    {isShowSelectTag && (
                      <Flex>
                        {Array?.from({ length: 1 })?.map((_, index) => {
                          return (
                            <Flex
                              key={index}
                              row
                              marginBottom={10}
                              marginTop={10}
                              center
                              middle
                            >
                              <Flex flex={1}>
                                {selectedOptions[index] ? (
                                  <Flex>
                                    <Text>{selectedOptions[index].label}</Text>
                                  </Flex>
                                ) : (
                                  <Flex width={120} onClick={handleScroll}>
                                    <SelectTag
                                      id="selectTagScroll"
                                      placeholder={'Select the stage'}
                                      options={Stagesoptions?.filter(
                                        (option) =>
                                          !selectedOptions?.some(
                                            (selected) =>
                                              selected.value === option.value,
                                          ),
                                      )}
                                      lineStyle
                                      onChange={(option) => {
                                        handleSelectChange(index, option);
                                      }}
                                    />
                                  </Flex>
                                )}
                              </Flex>
                              <Flex flex={1} row center middle>
                                <Flex
                                  marginLeft={
                                    selectedOptions[index]?.selected || false
                                      ? 12
                                      : 16
                                  }
                                >
                                  <InputSwitch
                                    checked={
                                      selectedOptions[index]?.selected || false
                                    }
                                    onClick={() => {
                                      handleOptionSwitch(
                                        index,
                                        selectedOptions[index]?.label,
                                        selectedOptions[index]?.value,
                                      );
                                    }}
                                  />
                                </Flex>
                                {selectedOptions[index]?.selected || false ? (
                                  <Text color="theme">ON</Text>
                                ) : (
                                  <Text color="theme">OFF</Text>
                                )}
                              </Flex>

                              <Flex flex={1} row center middle>
                                <Flex
                                  row
                                  center
                                  middle
                                  marginRight={35}
                                  onClick={handleNewAutomateRuleClose}
                                >
                                  <SvgClose
                                    width={10}
                                    height={10}
                                    fill={'#888888'}
                                    cursor={'pointer'}
                                  />
                                </Flex>
                              </Flex>
                            </Flex>
                          );
                        })}
                      </Flex>
                    )}
                  </Flex>
                  <Flex>
                    <Text color="error" size={14}>
                      {isAutomateError}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {isShowEmailScreen && (
              <>
                <Flex
                  height={window.innerHeight - 239}
                  style={{ borderLeft: '0.5px solid gray', margin: '0px 15px' }}
                />
                <Flex width={'50%'}>
                  <Flex>
                    <Card
                      height={
                        isShowCreateError
                          ? window.innerHeight - 268
                          : window.innerHeight - 239
                      }
                      className={styles.screenCards}
                    >
                      <Flex
                        height={window.innerHeight - 239}
                        style={{ display: 'flex', overflowY: 'scroll' }}
                      >
                        {isNewStageEmailScreen && (
                          <>
                            <Flex
                              center
                              middle
                              style={{ height: '-webkit-fill-available' }}
                            >
                              <Flex
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Text size={13}>
                                  Get started with your email:{' '}
                                  <Text
                                    size={13}
                                    bold
                                    color="link"
                                    onClick={() => {
                                      handleEditEmailTemplate();
                                      setAutomateError('');
                                      setShowCreateError('');
                                      setNewApplicantEditEmail(false);
                                    }}
                                  >
                                    Create{' '}
                                  </Text>
                                  <Text size={13}>your mail from scratch</Text>
                                </Text>
                                <Text>
                                  or pick from{' '}
                                  <Text
                                    bold
                                    color="link"
                                    onClick={() => {
                                      handleImportTemplate();
                                      setAutomateError('');
                                      setShowCreateError('');
                                    }}
                                  >
                                    Our Template
                                  </Text>
                                </Text>
                              </Flex>
                            </Flex>
                          </>
                        )}

                        {isEditEmailContent && (
                          <Flex>
                            <Flex
                              marginBottom={10}
                              style={{
                                paddingBottom: 10,
                                borderBottom: '0.5px solid #581845',
                              }}
                            >
                              {!isShowSelectTag ? (
                                <Text size={14} bold>
                                  Email content for {toggledStageName}
                                </Text>
                              ) : (
                                <Text size={14} bold>
                                  Email content for {selectedStageOptionName}
                                </Text>
                              )}
                            </Flex>
                            <AutomationEmailedit
                              handleImportTemplate={handleImportTemplate}
                              isContent={isContent}
                              isContentError={isContentError}
                              isContentLenError={isContentLenError}
                              setContent={setContent}
                              Contentvalue={Contentvalue}
                              errorsubject={errorsubject}
                              seterrorsubject={seterrorsubject}
                              isSubject={isSubject}
                              setSubject={setSubject}
                              setSubjectExist={setSubjectExist}
                              SubjectError={SubjectError}
                              setSubjectError={setSubjectError}
                              jd_id={jd_id}
                              setContentLenError={setContentLenError}
                              template={template}
                              handlemessage={handlemessage}
                              isTitle={isTitle}
                              setTitle={setTitle}
                              TitleExist={TitleExist}
                              setTitleExist={setTitleExist}
                              TitleError={TitleError}
                              setTitleError={setTitleError}
                              setContentError={setContentError}
                              ImportTemplateID={ImportTemplateID}
                              SubjectExist={SubjectExist}
                              handlePopupCancel={handlePopupCancel}
                              isNewStageEmailScreen={isNewStageEmailScreen}
                              handleCreateEmailTemplate={
                                handleCreateEmailTemplate
                              }
                              setShowCreateError={setShowCreateError}
                              isContentMinLen={isContentMinLen}
                              setContentMinLen={setContentMinLen}
                              job_details={job_details}
                              isTagIntemation={isTagIntemation}
                              setTagIntemation={setTagIntemation}
                              isDisableTag={isDisableTag}
                              setDisableTag={setDisableTag}
                              // handleIntemationPoppup={()=> handleIntemationPoppup(isDisableTag)}
                            />
                          </Flex>
                        )}

                        {isViewEmailContent && (
                          <Flex>
                            <Flex
                              marginBottom={10}
                              style={{
                                paddingBottom: 10,
                                borderBottom: '0.5px solid #581845',
                              }}
                            >
                              {!isShowSelectTag ? (
                                <Text size={14} bold>
                                  Email content for {toggledStageName}
                                </Text>
                              ) : (
                                <Text size={14} bold>
                                  Email content for {selectedStageOptionName}
                                </Text>
                              )}
                            </Flex>

                            {SelectedTemplate === undefined ? (
                              <Flex center middle height={'50vh'}>
                                <Loader withOutOverlay size="medium" />
                              </Flex>
                            ) : (
                              <Flex
                                key={SelectedTemplate?.id}
                                className={styles.descriptionpopup}
                              >
                                <Flex
                                  row
                                  center
                                  className={styles.popupheading}
                                ></Flex>
                                <Flex style={{ overflow: 'scroll' }}>
                                  <Flex>
                                    <Flex marginBottom={10}>
                                      <Text bold>Subject</Text>
                                      <Text>{SelectedTemplate?.subject}</Text>
                                    </Flex>
                                    <Flex>
                                      <Text
                                        bold
                                        size={13}
                                        style={{ margin: '5px 0px 2px 0px' }}
                                      >
                                        Email Content
                                      </Text>
                                      <Text style={{ wordBreak: 'break-all' }}>
                                        <div
                                          className={
                                            styles.automationEmailContent
                                          }
                                          dangerouslySetInnerHTML={{
                                            __html: SelectedTemplate?.templates,
                                          }}
                                        />
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </Flex>
                            )}
                          </Flex>
                        )}
                      </Flex>
                    </Card>

                    <Flex marginTop={10}>
                      <Text color="error">{isShowCreateError}</Text>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>

          {isShowImportTemplate && (
            <Flex>
              <ApplicantEmailImportScreen
                template={template}
                formik={formik}
                setSubject={setSubject}
                settemplate={settemplate}
                hanldeClose={handleModelClose}
                istemplate={istemplate}
                open={templatemodel}
                setContent={setContent}
                setEditEmailContent={setEditEmailContent}
                settemplatemodel={settemplatemodel}
                setNewStageEmailScreen={setNewStageEmailScreen}
                setTitle={setTitle}
                setImportTemplateID={setImportTemplateID}
              />
            </Flex>
          )}
        </Flex>
      </Modal>
      {
        <Modal open={isTagIntemation}>
          <Flex
            className={styles.intemationPopup}
            width={window.innerWidth - 940}
          >
            <Flex>
              <Text>
                Please complete your company details in your profile setup. This
                will enable you to use the {changeCase(isDisableTag)} tag
                effectively in your email communications
              </Text>
            </Flex>
            <Flex center middle marginTop={16}>
              <Button onClick={() => setTagIntemation(false)}>OK</Button>
            </Flex>
          </Flex>
        </Modal>
      }
    </>
  );
};

export default ApplicantEmailAutomation;
