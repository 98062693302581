/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
  fill: '#581845',
  width: 22,
  height: 22,
};

const SvgLocation = ({ width, height, fill }: typeof defaultProps) => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={width}
    //   height={height}
    //   viewBox="0 0 24 24"
    //   fill={fill}
    // >
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 20.26 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4974 10.0013C10.9617 10.0013 11.3593 9.83797 11.6902 9.5113C12.0211 9.18464 12.1862 8.79241 12.1857 8.33464C12.1857 7.8763 12.0202 7.4838 11.6893 7.15714C11.3584 6.83047 10.9611 6.66741 10.4974 6.66797C10.0331 6.66797 9.63555 6.8313 9.30465 7.15797C8.97376 7.48464 8.80859 7.87686 8.80915 8.33464C8.80915 8.79297 8.9746 9.18547 9.3055 9.51214C9.6364 9.8388 10.0337 10.0019 10.4974 10.0013ZM10.4974 18.0221C10.3849 18.0221 10.2723 18.0013 10.1598 17.9596C10.0472 17.918 9.94872 17.8624 9.86431 17.793C7.81027 16.0013 6.27677 14.338 5.26381 12.803C4.25086 11.268 3.74438 9.83408 3.74438 8.5013C3.74438 6.41797 4.42334 4.75825 5.78127 3.52214C7.13919 2.28602 8.71123 1.66797 10.4974 1.66797C12.2841 1.66797 13.8565 2.28602 15.2144 3.52214C16.5723 4.75825 17.251 6.41797 17.2504 8.5013C17.2504 9.83463 16.744 11.2688 15.731 12.8038C14.718 14.3388 13.1845 16.0019 11.1305 17.793C11.0461 17.8624 10.9476 17.918 10.8351 17.9596C10.7225 18.0013 10.61 18.0221 10.4974 18.0221Z"
        fill={fill}
      />
    </svg>

    //   <path d="M12 0c-4.198 0-8 3.403-8
    //   7.602 0 4.198 3.469 9.21 8 16.398
    //   4.531-7.188 8-12.2 8-16.398 0-4.199-3
    //   .801-7.602-8-7.602zm0 11c-1.657
    //   0-3-1.343-3-3s1.343-3 3-3 3 1.3
    //   43 3 3-1.343 3-3 3z" />{' '}
    // </svg>
  );
};

SvgLocation.defaultProps = defaultProps;

export default SvgLocation;
