import * as React from 'react';
const SvgNotinterests = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#555"
      d="M7.2 0c-.955 0-1.87.421-2.546 1.172C3.98 1.922 3.6 2.939 3.6 4c0 1.06.38 2.078 1.054 2.828C5.33 7.578 6.245 8 7.2 8c.955 0 1.87-.421 2.546-1.172C10.42 6.078 10.8 5.061 10.8 4c0-1.06-.38-2.078-1.054-2.828C9.07.422 8.155 0 7.2 0Zm0 2c.477 0 .935.21 1.273.586C8.81 2.96 9 3.47 9 4c0 .53-.19 1.04-.527 1.414A1.714 1.714 0 0 1 7.2 6c-.477 0-.935-.21-1.273-.586A2.118 2.118 0 0 1 5.4 4c0-.53.19-1.04.527-1.414A1.714 1.714 0 0 1 7.2 2Zm0 7C4.797 9 0 10.33 0 13v3h8.55a7.097 7.097 0 0 1-.423-1.9H1.71V13c0-.64 2.817-2.1 5.49-2.1.45 0 .9.05 1.35.13.23-.631.55-1.219.945-1.74C8.649 9.1 7.839 9 7.2 9Zm6.75 0C11.7 9 9.9 11 9.9 13.5s1.8 4.5 4.05 4.5S18 16 18 13.5 16.2 9 13.95 9Zm0 1.5c1.494 0 2.7 1.34 2.7 3 0 .56-.135 1.08-.378 1.5L12.6 10.92c.378-.27.846-.42 1.35-.42ZM11.628 12l3.672 4.08c-.378.27-.846.42-1.35.42-1.494 0-2.7-1.34-2.7-3 0-.56.135-1.08.378-1.5Z"
    />
  </svg>
);
export default SvgNotinterests;