import { useSelector, useDispatch } from 'react-redux';
import TooltipLite from 'react-tooltip-lite';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import { useState, useEffect } from 'react';
import { jobCreateNonDs } from '../../../appRoutesPath';
import SvgCompany from '../../../icons/SvgCompany';
import SvgNewTab from '../../../icons/SvgNewTab';
import SvgMail from '../../../icons/SvgMail';
import SvgLocation from '../../../icons/SvgLocation';
import { AppDispatch, RootState } from '../../../store';
import {
  companyPageInitalMiddleWare,
  companyPagePostMiddleWare,
  logoMiddleWare,
} from '../../accountsettingsmodule/store/middleware/accountsettingmiddleware';
import SvgSubscription from '../../../icons/SvgSubscription';

import { userProfilePostMiddleWare } from '../../accountsettingsmodule/userprofilemodule/store/middleware/userprofilemiddleware';
import Toast from '../../../uikit/Toast/Toast';
import SvgDot from '../../../icons/SvgDot';
import {
  locationCityMiddleWare,
  locationStateMiddleWare,
  locationMiddleWare,
} from '../../createjdmodule/store/middleware/createjdmiddleware';
import SvgCreditsavailable from '../../../icons/SvgCreditsavailable';
import SvgCredits from '../../../icons/SvgCredits';
import SvgLocationicon from '../../../icons/SvgLocationicon';
import Loader from '../../../uikit/Loader/Loader';
import SvgUpload from '../../../icons/SvgUpload';
import SvgMobilet from '../../../icons/SvgMobilet';
import SvgMobile from '../../../icons/SvgMobile';
import SvgGlobe from '../../../icons/SvgGlobe';

// import { RootState } from '../../../store';
import Button from '../../../uikit/Button/Button';
import Card from '../../../uikit/Card/Card';
import { BLACK, PRIMARY } from '../../../uikit/Colors/colors';
import Flex from '../../../uikit/Flex/Flex';
import { getDateString, isEmpty, unlimitedHelper } from '../../../uikit/helper';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import Text from '../../../uikit/Text/Text';
import CustomTooltip from '../../../uikit/Tooltip/tooltip';
import { FILE_2MB, imageFileAccept, mediaPath } from '../../constValue';
import SvgCloseSmall from '../../../icons/SvgCloseSmall';
import SubscriptionModal from '../../subscriptionmodule/subscriptionmoduleScreen';
import SvgSubcriptioncrown from '../../../icons/Subscriptioncrown';
import styles from './profilecard.module.css';
import { CountryEntity, StateEntity, CityEntity } from './Companytype';
import { dashBoardMiddleWare } from './store/dashboardmiddleware';

const ProfileCard = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    company_name,
    logo,
    user_info,
    plan,
    contact_count,
    job_count,
    candidate_count,
    career_page_url,
    permission,
    address,
    mobile_no,
    status,
    zipcode,
    weburl,
    data3,
    data2,
    datafin,
    company,
    cityid,
    stateid,
    countryid,
    countryidfin,
    noofemp,
    industryid,
    current_jd_count,
  } = useSelector(
    ({
      dashboardEmpReducers,
      permissionReducers,
      companyPageReducers,
      locationReducers,
      SubscriptionReducers,
    }: RootState) => {
      return {
        company: companyPageReducers,
        countryidfin: locationReducers.country,
        industryid: companyPageReducers.company_detail.industry_type_id__value,
        noofemp: companyPageReducers.company_detail.no_of_emp,
        weburl: companyPageReducers.company_detail.company_website,
        datafin: companyPageReducers,
        address: companyPageReducers.company_detail.address,
        zipcode: companyPageReducers.company_detail.zipcode,
        data3: permissionReducers,
        data2: dashboardEmpReducers,
        mobile_no: companyPageReducers.company_detail.contact,
        company_name: dashboardEmpReducers.company_name,
        logo: dashboardEmpReducers.logo,
        user_info: dashboardEmpReducers.user_info,
        plan: dashboardEmpReducers.plan,
        job_count: dashboardEmpReducers.job_count,
        candidate_count: dashboardEmpReducers.candidate_count,
        contact_count: dashboardEmpReducers.contact_count,
        career_page_url: dashboardEmpReducers.career_page_url,
        status: dashboardEmpReducers.plan.is_active,
        permission: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        stateid: companyPageReducers.company_detail.state_id,
        cityid: companyPageReducers.company_detail.city_id,
        countryid: companyPageReducers.company_detail.country_id,
        current_jd_count: SubscriptionReducers.current_jd_count,
      };
    },
  );

  const logoPath = isEmpty(logo) ? 'logo.png' : logo;

  const clearTab = () => {
    sessionStorage.removeItem('superUserTab');
    sessionStorage.removeItem('superUserFalseTab');
  };

  const [isGetCountry, setCountry] = useState<CountryEntity[]>([]);
  const [getState, setState] = useState<StateEntity[]>([]);
  const [getCity, setCity] = useState<CityEntity[]>([]);
  const [isopensubcription, setopensubcription] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // applying delay time for tooltip opening.
  useEffect(() => { 
    let timeout: NodeJS.Timeout; 
    if (isHovered) { 
        // Set a timeout to open the tooltip after the delay
        timeout = setTimeout(() => { 
            setIsTooltipOpen(true);
        }, 1000); // 5 seconds delay
    } else { 
        // Clear the timeout if the cursor leaves the component
        clearTimeout(timeout);
        // Close the tooltip immediately
        setIsTooltipOpen(false);
    }

    // Cleanup function to clear the timeout when the component unmounts or is updated
    return () => clearTimeout(timeout);
}, [isHovered]);

  // handle function for when the cursor enter into the children they set as true.
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  // handle function for when the cursor leave from the children they set as false.
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  
  useEffect(() => {
    //  dispatch(companyPageInitalMiddleWare());
    dispatch(locationMiddleWare({}));
  }, []);

  useEffect(() => {
    if (countryidfin && countryidfin.length !== 0) {
      setCountry(countryidfin);
    }
  }, [countryidfin]);
  useEffect(() => {
    if (!isEmpty(countryid)) {
      dispatch(
        locationStateMiddleWare({
          country: countryid.toString(),
        }),
      ).then((res) => {
        if (res.payload.states && res.payload.states.length !== 0) {
          setState(res.payload.states);
        }
      });
    }
  }, [countryid]);
  useEffect(() => {
    if (!isEmpty(stateid)) {
      dispatch(locationCityMiddleWare({ state: stateid.toString() })).then(
        (res) => {
          if (res.payload.city && res.payload.city.length !== 0) {
            setCity(res.payload.city);
          }
        },
      );
    }
  }, [stateid]);

  const [state, setstate] = useState('');
  const [city, setcity] = useState('');
  const [country, setcountry] = useState('');
  useEffect(() => {
    if (
      getCity.length !== 0 &&
      isGetCountry.length !== 0 &&
      getState.length !== 0
    ) {
      cityhand();
      statehand();
      countryhand();
    }
  }, [getCity, getState, isGetCountry]);

  function cityhand() {
    if (getCity.length !== 0) {
      {
        if (cityid !== null) {
          {
            setcity(getCity.find((option) => option.id === cityid).name);
          }
        }
      }
    }
  }
  function countryhand() {
    if (isGetCountry.length !== 0) {
      if (countryid !== null) {
        setcountry(isGetCountry.find((option) => option.id === countryid).name);
      }
    }
  }

  function statehand() {
    if (getState.length !== 0) {
      if (stateid !== null) {
        setstate(getState.find((option) => option.id === stateid).name);
      }
    }
  }
  const [isShow, setShow] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [isMb, setMb] = useState(false);
  const handleChangeImag = (e: any) => {
    e.preventDefault();
    var fileExt = e.target.value;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));

    if (imageFileAccept.indexOf(fileExt) < 0) {
      if (!isEmpty(fileExt)) {
        alert(
          'Invalid file selected, valid files are of ' +
          imageFileAccept.toString() +
          ' types.',
        );
      }
    } else if (e.target.files && e.target.files[0].size / 1024 / 1024 > 2) {
      setMb(true);
    } else {
      setLoader(true);
      const formData = new FormData();
      //alert("insilde else")
      if (e.target.files[0] !== undefined) {
        //alert("append")
        formData.append('logo', e.target.files[0]);
        // formData.append('company_name', company_name);
        // formData.append('company_website', weburl);
        // formData.append('contact', mobile_no);

        // formData.append('industry_type', Tostring(industryid));
        // formData.append('no_of_emp', Tostring(noofemp));
        // formData.append('address', address);
        // formData.append('country', Tostring(countryid));
        // formData.append('state', Tostring(stateid));
        // formData.append('city', Tostring(cityid));
        // formData.append('zipcode', zipcode);
        // formData.append('email', user_info.email);
      } else {
        formData.append('image_null', '');
      }
      //alert("00000")
      dispatch(
        logoMiddleWare({
          formData,
        }),
      ).then((res: any) => {
        dispatch(dashBoardMiddleWare());
        if (res.payload.success) {
          setLoader(false);
          Toast('Company logo saved successfully.', 'LONG', 'success');
          setShow(false);
          // dispatch(companyPageInitalMiddleWare());
        }
      });
      setMb(false);
    }
  };

  function Tostring(res: any) {
    if (res !== null && res !== undefined) {
      return res.toString();
    } else {
      return '';
    }
  }
  const handleRemoveProfile = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append('logo', '');
    // formData.append('company_name', company_name);
    // formData.append('company_website', weburl);
    // formData.append('contact', mobile_no);
    // formData.append('industry_type', industryid.toString());
    // formData.append('no_of_emp', noofemp.toString());
    // formData.append('address', address);
    // formData.append('country', countryid.toString());
    // formData.append('state', stateid.toString());
    // formData.append('city', cityid.toString());
    // formData.append('zipcode', zipcode);
    // formData.append('email', user_info.email);
    dispatch(
      logoMiddleWare({
        formData,
      }),
    ).then((res: any) => {
      if (res.payload.success) {
        dispatch(dashBoardMiddleWare()).then(() => {
          setLoader(false);
          Toast('Company logo removed successfully.', 'LONG', 'success');
        });
        setShow(false);
      }
    });
  };

  return (
    <Flex marginLeft={3}>
      <Card className={styles.profileCardMain}>
        <Flex middle marginTop={15}>
          <Flex className={styles.overAll}>
            <label
              htmlFor="upload_profile___bannersetip__img"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
              style={{ margin: 0 ,display:'flex',justifyContent:'center'}}
            >
              <input
                id="upload_profile___bannersetip__img"
                type="file"
                onChange={handleChangeImag}
                accept="image/*"
                className={styles.fileStyle}
              />
              <Flex className={styles.imgContainer}>
                {isEmpty(logoPath) || logoPath === 'logo.png' ? (
                  <>
                    {isLoader ? (
                      <Flex center middle>
                        <Loader withOutOverlay size="medium" />
                      </Flex>
                    ) : (
                      <Flex columnFlex center middle>
                        <SvgUpload />
                        <Text
                          color="black"
                          align="center"
                        // style={{ paddingLeft: 4, paddingRight: 4 }}
                        >
                          Upload your company logo
                        </Text>
                      </Flex>
                    )}
                  </>
                ) : (
                  <>
                    {isLoader ? (
                      <Flex center middle>
                        <Loader withOutOverlay size="medium" />
                      </Flex>
                    ) : (
                      <img
                        style={{ objectFit: 'cover' }}
                        className={styles.imgStyle}
                        src={mediaPath + logoPath}
                        alt="profile"
                      //key={Math.random().toString()}
                      />
                    )}
                  </>
                )}

                {isShow && (
                  <Flex columnFlex center middle className={styles.changeStyle}>
                    <SvgUpload />
                    <Text
                      color="theme"
                      bold
                      align="center"
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      {isEmpty(logoPath) || logoPath === 'logo.png'
                        ? 'Upload your company logo'
                        : 'Change logo'}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </label>
            {isShow &&
              !isEmpty(logoPath) &&
              logoPath !== 'default.jpg' &&
              logoPath !== 'logo.png' && (
                <CustomTooltip title="Remove Profile Picture">
                  <div
                    className={styles.svgClose}
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                    onClick={handleRemoveProfile}
                    tabIndex={-1}
                    role="button"
                    onKeyDown={() => { }}
                  >
                    <SvgCloseSmall />
                  </div>
                </CustomTooltip>
              )}

            {isMb && (
              <Text size={12} color="error">
                {FILE_2MB}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex marginTop={12}>
          <Text bold align="center" size={14} className={styles.companyColor}>
            {company_name}
          </Text>

          <Text style={{ marginBottom: 7, fontSize: '13px' }} align="center">
            {user_info.email}
          </Text>
          {/* <Text align="center" bold style={{ fontSize: '13px' }}>
            Last Login on: {getDateString(user_info?.last_login, 'll hh:mm A')}
          </Text> */}
        </Flex>

        <Flex
          marginLeft={20}
          marginRight={20}
          className={styles.line}
          marginBottom={20}
          marginTop={20}
        ></Flex>
        <Flex marginLeft={20}>
          <Flex>
            {weburl === null || weburl === 'https://' ? (
              <Flex row center>
                <Flex marginBottom={2}>
                  <SvgGlobe height={16} width={16} fill={'#581845'} />
                </Flex>
                <Flex marginLeft={7}>
                  <LinkWrapper
                    // onClick={() => {
                    //   sessionStorage.setItem('superUserTabTwo', '0');
                    //   sessionStorage.setItem('superUserFalseTab', '0');
                    //   sessionStorage.setItem('superUserTab', '0');
                    // }}
                    onClick={() => { sessionStorage.setItem('MyAccountDropdown', '1') }}
                    to={'/account_setting/profiles'}
                  >
                    <Text
                      style={{
                        color: '#581845',
                        fontSize: '13px',
                        // marginLeft: ' 10px',
                      }}
                      bold
                    >
                      Add Website URL
                    </Text>
                  </LinkWrapper>
                </Flex>
              </Flex>
            ) : (
              <Flex row center className={styles.weburls}>
                <Flex>
                  <SvgGlobe height={16} width={16} fill={'#581845'} />
                </Flex>
                <Flex
                  marginLeft={8}
                  className={styles.weburl}
                  style={{
                    // marginBottom: '4px',
                    color: '#581845',
                    fontWeight: 600,
                    fontSize: '13px',
                  }}
                >
                  <a target={'_blank'} rel="noreferrer" href={weburl}>
                    <TooltipLite
                      background={'#757274'}
                      content={weburl}
                      distance={10}
                      direction={'down'}
                      isOpen={isTooltipOpen}
                      arrow={true}
                      arrowSize={7}
                    >
                      <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '235px' }}
                      >
                        {' '}
                        {weburl}
                      </div>{' '}
                    </TooltipLite>
                  </a>
                </Flex>
              </Flex>
            )}
          </Flex>

          <Flex style={{ cursor: 'default' }} marginTop={7}>
            {mobile_no !== '' ? (
              <Flex row center>
                <Flex>
                  <SvgMobilet height={16} width={16} fill={'#581845'} />
                </Flex>
                <Flex marginLeft={5}>
                  <Text style={{ fontSize: '13px !important' }}>
                    <PhoneInput
                      value={mobile_no}
                      inputStyle={{
                        border: 'none',
                        padding: 'inherit',
                        height: '30px',
                        cursor: 'default',
                        fontSize: '13px',
                      }}
                      showDropdown={false}
                      defaultErrorMessage="false"
                      disableDropdown={true}
                      disableSearchIcon={true}
                      country={null}
                      disabled={true}
                      buttonStyle={{ display: 'none' }}
                      dropdownStyle={{ display: 'none' }}
                    />
                  </Text>
                </Flex>
              </Flex>
            ) : (
              ''
            )}
          </Flex>
          <Flex marginTop={3}>
            {address !== null ? (
              <Flex row marginTop={3}>
                <Flex marginRight={1}>
                  <SvgLocationicon height={16} width={16} fill={'#581845'} />
                </Flex>
                <Flex
                  marginLeft={5}
                  style={{ textTransform: 'capitalize' }}
                  className={styles.address}
                  title={`${address}, ${city}, ${state}, ${country}, ${zipcode}`}
                >
                  {address}, {city}, {state}, {country}, {zipcode}
                </Flex>
              </Flex>
            ) : (
              <Flex row center marginTop={3}>
                <Flex>
                  <SvgLocationicon height={16} width={16} fill={'#581845'} />
                </Flex>
                <Flex marginLeft={5}>
                  <LinkWrapper
                    onClick={() => {
                      clearTab();
                      sessionStorage.setItem('MyAccountDropdown', '1');
                    }}
                    to={'/account_setting/profiles'}
                  >
                    <Text
                      style={{
                        color: '#581845',
                        fontSize: '13px',
                        // marginLeft: '5px',
                      }}
                      bold
                    >
                      Add Company Address
                    </Text>
                  </LinkWrapper>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          marginLeft={20}
          marginRight={20}
          className={styles.line}
          marginBottom={20}
          marginTop={20}
        ></Flex>
        <Flex>
          <Flex marginLeft={18}>
            <Text
              style={{ marginLeft: 2, fontWeight: 550, fontSize: '14px' }}
              bold
            >
              Subscription
            </Text>
          </Flex>
        </Flex>
        <Flex row between>
          <Flex>
            <Flex marginLeft={20}>
              <Flex>
                <Text style={{ marginTop: 5, fontSize: '13px' }}>
                  Plan:
                  {plan.plan_id_id === 1 ? (
                    <Text
                      style={{
                        marginBottom: 2,
                        fontSize: '13px',
                        marginLeft: 3,
                      }}
                    >
                      Free Trial
                    </Text>
                  ) : (
                    <Text
                      style={{
                        marginBottom: 2,
                        fontSize: '13px',
                        marginLeft: 3,
                      }}
                    >
                      {`${plan.plan_name}`}
                    </Text>
                  )}
                </Text>
                <Text style={{ marginTop: 5, fontSize: '13px' }}>
                  Status:{' '}
                  {status === false ? (
                    <Text
                      style={{
                        color: '#FF0000',
                        fontWeight: 600,
                        fontSize: '13px',
                      }}
                    >
                      Expired
                    </Text>
                  ) : (
                    <Text
                      style={{
                        color: '#00BE4B',
                        fontWeight: 600,
                        fontSize: '13px',
                      }}
                    >
                      Active
                    </Text>
                  )}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex marginRight={20}>
            <Text
              style={{
                marginTop: 5,
                whiteSpace: 'nowrap',
                fontSize: '13px',
              }}
            >
              Renewal: {getDateString(plan.subscription_valid_till, 'll')}
            </Text>
            <Flex>
              <Text
                style={{
                  marginTop: 5,
                  whiteSpace: 'nowrap',
                  fontSize: '13px',
                }}
              >
                Frequency: {plan.plan_status}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          marginLeft={20}
          marginRight={20}
          className={styles.line}
          marginTop={20}
          marginBottom={20}
        ></Flex>
        {/* <Flex row> */}
        {/* <Flex marginLeft={23} className={styles.pointer} marginTop={5}> {permission.includes('create_post') && (
            <LinkWrapper to={jobSelect}>
              <Button style={{ marginBottom: 8 }} className={styles.buttonsize}>Post Job</Button>
            </LinkWrapper>
          )}</Flex> */}

        {permission.includes('create_post') === false ? (
          <Flex marginLeft={12} marginRight={12} marginTop={6} marginBottom={6}>
            <LinkWrapper
              target={isEmpty(career_page_url) ? '_parent' : '_blank'}
              to={
                isEmpty(career_page_url)
                  ? `/account_setting/build_careers_page`
                  : `/${career_page_url}/careers`
              }
            >
              <Button className={styles.buttonsizeauto}>
                {/* <Flex row center className={styles.pointer} > */}
                {/* <Text bold style={{ color: "white", marginLeft: 123 }} > */}
                Careers Page
                {/* </Text> */}
                {/* </Flex> */}
              </Button>
            </LinkWrapper>
          </Flex>
        ) : (
          <Flex row between style={{ padding: '0 20px' }}>
            <Flex className={styles.pointer}>
              {permission.includes('create_post') && (
                <>
                  {current_jd_count !== 0 ? (
                    <>
                      <LinkWrapper to={jobCreateNonDs}>
                        <Button className={styles.buttonsize}>Post Job</Button>
                      </LinkWrapper>
                    </>
                  ) : (
                    <Button
                      style={{ cursor: 'pointer' }}
                      className={styles.style1}
                      types="primary"
                      onClick={() => setopensubcription(true)}
                    >
                      <Flex row>
                        <Flex style={{ cursor: 'pointer' }}>
                          <Text color="white">Post Job</Text>
                        </Flex>
                        <Flex
                          marginLeft={5}
                          marginTop={1}
                          style={{ cursor: 'pointer' }}
                        >
                          <SvgSubcriptioncrown height={14} width={14} fill="" />
                        </Flex>
                      </Flex>
                    </Button>
                  )}
                </>
              )}
            </Flex>
            <Flex>
              <LinkWrapper
                target={isEmpty(career_page_url) ? '_parent' : '_blank'}
                onClick={() => isEmpty(career_page_url) ? sessionStorage.setItem('MyAccountDropdown', '1') : ''}
                to={
                  isEmpty(career_page_url)
                    ? `/account_setting/build_careers_page`
                    : `/${career_page_url}/careers`
                }
              >
                <Button className={styles.buttonsize}>
                  {/* <Flex row center className={styles.pointer} > */}
                  {/* <Text bold style={{ color: "white", marginLeft: 10 }} > */}
                  Careers Page
                  {/* </Text> */}
                  {/* </Flex> */}
                </Button>
              </LinkWrapper>
            </Flex>
          </Flex>
        )}
        {/* </Flex> */}
      </Card>
      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          addon_name={['Job Posting Credits']}
          differece_key={['job_post']}
          purpose={
            <div>
              <strong>
                <text
                  style={{
                    color: '#2E6ADD',
                    marginRight: '3px',
                    fontSize: '13px',
                  }}
                >
                  Heads Up!
                </text>
              </strong>
              <strong>Job Posting Limit Exceeds:</strong> To continue posting
              new jobs, you can &apos;Upgrade your plan&apos; or &apos;Buy
              additional Job Posting add-on&apos;.
            </div>
          }
          purpose_freetrial={
            <div>
              <strong>
                <text
                  style={{
                    color: '#2E6ADD',
                    marginRight: '3px',
                    fontSize: '13px',
                  }}
                >
                  Heads Up!
                </text>
              </strong>
              <strong>Job Posting Limit Exceeds:</strong> To continue posting
              new jobs, you must &apos;Upgrade your plan&apos;.
            </div>
          }
          purpose_addon={
            <div>
              <strong>
                <text
                  style={{
                    color: '#2E6ADD',
                    marginRight: '3px',
                    fontSize: '13px',
                  }}
                >
                  Heads Up!
                </text>
              </strong>
              <strong>Job Posting Limit Exceeds:</strong> To continue posting
              new jobs, you must &apos;Buy additional Job Posting add-on&apos;.
            </div>
          }
        />
      )}
    </Flex>
  );
};

export default ProfileCard;
