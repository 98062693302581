const SvgArchive = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="none"
      viewBox=" 0 0 12 12"
      {...props}
    >
      <path
        fill={props.fill}
        d="M11.143 0H.857C.63 0 .412.126.251.351.091.577 0 .881 
        0 1.2V3c0 .318.09.623.251.849.16.225.379.351.606.351v6.6c0 .318.09.623.251.848.16.226.379.352.606.352h8.572c.227 
        0 .445-.126.606-.351.16-.225.25-.53.25-.849V4.2c.228 
        0 .446-.126.607-.351.16-.226.251-.53.251-.849V1.2c0-.318-.09-.623-.251-.849C11.589.126 11.37 
        0 11.143 0Zm-.857 10.8H1.714V4.2h8.572v6.6Zm.857-7.8H.857V1.2h10.286V3ZM4.286 6.6c0-.16.045-.312.125-.424.08-.113.19-.176.303-.176h2.572c.113 
        0 .222.063.303.176.08.112.125.265.125.424 0 .16-.045.312-.125.424-.08.113-.19.176-.303.176H4.714c-.113 
        0-.222-.063-.303-.176a.739.739 0 0 1-.125-.424Z"
      />
    </svg>
  );
  export default SvgArchive;