/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
  fill: '#581845',
  width: 24,
  height: 24,
  
};

const SvgInterested = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={width}
      width={height}
      fill={fill}
      viewBox="0 0 24 24"
    >
      {/* <circle cx={12} cy={4} r={2} /> */}
      <path d="M16.7077 9.27273L18 10.8109L11.9723 18L8.76923 14.1818L10.0615 12.6436L11.9723 14.9127L16.7077 9.27273ZM7.38462 0C8.36388 0 9.30303 0.459739 9.99547 1.27808C10.6879 2.09642 11.0769 3.20633 11.0769 4.36364C11.0769 5.52095 10.6879 6.63085 9.99547 7.44919C9.30303 8.26753 8.36388 8.72727 7.38462 8.72727C6.40535 8.72727 5.4662 8.26753 4.77376 7.44919C4.08132 6.63085 3.69231 5.52095 3.69231 4.36364C3.69231 3.20633 4.08132 2.09642 4.77376 1.27808C5.4662 0.459739 6.40535 0 7.38462 0ZM7.38462 2.18182C6.89498 2.18182 6.42541 2.41169 6.07919 2.82086C5.73297 3.23003 5.53846 3.78498 5.53846 4.36364C5.53846 4.94229 5.73297 5.49724 6.07919 5.90641C6.42541 6.31558 6.89498 6.54545 7.38462 6.54545C7.87425 6.54545 8.34382 6.31558 8.69004 5.90641C9.03626 5.49724 9.23077 4.94229 9.23077 4.36364C9.23077 3.78498 9.03626 3.23003 8.69004 2.82086C8.34382 2.41169 7.87425 2.18182 7.38462 2.18182ZM7.38462 9.81818C8.01231 9.81818 8.76923 9.91636 9.60923 10.1018L8.06769 11.9236L7.38462 11.8909C4.64308 11.8909 1.75385 13.4836 1.75385 14.1818V15.3818H7.47692L9.23077 17.4545H0V14.1818C0 11.28 4.92 9.81818 7.38462 9.81818Z" fill="#1976D2"/>
    </svg>
  );
};
SvgInterested.defaultProps = defaultProps;

export default SvgInterested;
