import { useState, useEffect, SetStateAction } from 'react';
import classNames from 'classnames/bind';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import SvgSearch from '../../icons/SvgSearch';
import Button from '../../uikit/Button/Button';
import Flex from '../../uikit/Flex/Flex';
import InputText from '../../uikit/InputText/InputText';
import Text from '../../uikit/Text/Text';
import Modal from '../../uikit/Modal/Modal';
import { CANCEL } from '../constValue';
import Card from '../../uikit/Card/Card';
import { RootState } from '../../store';
import { Toast } from '../../uikit';
import { MessageTemplates } from './applicantProfileTypes';
import styles from './messagetemplate.module.css';
import MessageTemplateList from './MessageTemplateList';
import { messagesTemplatesMiddleWare } from './store/middleware/applicantProfileMiddleware';

const cx = classNames.bind(styles);

type Props = {
  // messageTemplate: MessageTemplates[];
  formik?: FormikProps<any>;
  open?: boolean;
  hanldeClose?: () => void;
  user?: string;
  setSubject?: any;
  template?: boolean;
  settemplate?: (val: boolean) => void;
};
const MessageTemplate = ({
  // messageTemplate,
  formik,
  open,
  hanldeClose,
  user,
  setSubject,
  template,
  settemplate,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<MessageTemplates[]>([]);
  const [valuelist, setvaluelist] = useState(null);
  const [applybtn, setapplybtn] = useState(null);
  const [indextick, setindextick] = useState(null);

  const { messageTemplate } = useSelector(
    ({ messageTemplateReducers }: RootState) => {
      return {
        messageTemplate: messageTemplateReducers.messageTemplate,
      };
    },
  ); 

  const emailContent = messageTemplate?.filter ((filter)=>(filter.jd_id_id === null))


  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setSearchTerm(e.target.value);
    searchfunction(e.target.value);
  };

  const update = (val, index) => {
    setindextick(index);
    setvaluelist(val);
    settemplate(true);
  };

  const applyfun = (val) => {
    setapplybtn(val);
  };

  const handleCopy = (templates: string, subject: any) => {
    formik.setFieldValue('userMessage', templates);
    formik.setFieldValue('userSubject', subject);
    hanldeClose();
    setSubject(subject);
    Toast('Email template added successfully.', 'LONG', 'success');
  };

  useEffect(() => {
    setSearchResults(emailContent);
  }, [messageTemplate]);

  useEffect(() => {
    if (template === false) {
      setvaluelist(null);
      setindextick(null);
      setapplybtn(null);
    }
  }, [template]);

  const searchfunction = (val: any) => {
    const results = emailContent.filter(
      (tempList) =>
        tempList.name.toLowerCase().includes(val.toLowerCase()) ||
        tempList.templates.toLowerCase().includes(val.toLowerCase()),
    );
    setSearchResults(results);
  }; 
  const close = () => {
    if (template === true) {
      hanldeClose();
      if (formik.values.userMessage === '') {
        settemplate(false);
      }
    } else {
      hanldeClose();
      setSearchTerm('');
      setvaluelist(null);
      setindextick(null);
      setSearchResults(emailContent);
    }
  };

  return (
    <Modal open={open}> 
      <Flex
        columnFlex
        className={styles.overAll}
        width={valuelist ? window.innerWidth / 1.3 : '600px'}
      >
        <Text bold className={styles.insertStyles}>
          {/* Insert Message Template */}
          Email Template
        </Text>
        <Flex row center style={{ padding: '10px 0px 10px 0px' }}>
          <InputText
            id="messsageTemplate_search_id"
            placeholder="Search by template name..."
            className={styles.inputchanges}
            value={searchTerm}
            onChange={handleChange}
            // actionRight={() => (
            //   <label htmlFor={'messsageTemplate_search_id'} style={{ margin: 0,right:0 }}>
            //     <SvgSearch />
            //   </label>
            // )}
          />

          <Flex
            style={{ position: 'absolute' }}
            marginTop={1.5}
            middle
            center
            marginLeft={5}
          >
            <SvgSearch />
          </Flex>
          <Flex
            middle
            center
            row
            marginLeft={15}
            className={styles.totalcountchanges}
            style={{ color: '#581845', fontsize: '13px' }}
          >
            <Flex>
              <Text color="black">Total Search Count :</Text>
            </Flex>
            <Flex marginLeft={5}>
              <Text color="black">{searchResults?.length}</Text>
            </Flex>
          </Flex>
        </Flex>
        {/* <InputText
          id="messsageTemplate_search_id"
          placeholder="Search by template name"
          value={searchTerm}
          onChange={handleChange}
          actionRight={() => (
            <label htmlFor={'messsageTemplate_search_id'} style={{ margin: 0,left:8 }}>
              <SvgSearch />
            </label>
          )}
        /> */}
        <Flex row>
          <Flex
            style={{
              width: valuelist === null ? '100%' : '50%',
            }}
            columnFlex
            height={window.innerHeight - 277}
            marginTop={5}
            className={cx('scrollStyle')}
          >
            <Flex>
              {searchResults && searchResults.length !== 0 ? (
                searchResults.map((list, index) => {
                  return (
                    <MessageTemplateList
                      key={list.name + index}
                      list={list}
                      formik={formik}
                      hanldeClose={() => {
                        hanldeClose();
                        setSearchTerm('');
                        setindextick(null);
                        setvaluelist(null);
                      }}
                      update={update}
                      applyfun={applyfun}
                      indextick={indextick}
                      index={index}
                      searchTerm={searchTerm}
                      user={user !== '' ? user : ''}
                      valuelist={valuelist}
                      messageTemplate={searchResults}
                    />
                  );
                })
              ) : (
                <Flex flex={1} middle center>
                  <Text style={{ padding: 24 }} align="center" color="gray">
                    No result found
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
          {valuelist !== null && (
            <>
              <Flex height={innerHeight - 277} className={styles.border}></Flex>
              <Flex style={{ width: '50%' }} marginTop={5}>
                <Flex>
                  <Flex
                    className={styles.descCardstyles}
                    height={innerHeight - 277}
                  > 
                    <Flex marginBottom={5} center middle>
                      <Text bold size={13}>
                        {applybtn.name}
                      </Text>
                    </Flex>
                    <Flex
                      style={{
                        overflow: 'scroll',
                        padding: '0px 10px 10px 10px',
                      }}
                    >
                      <Flex row marginBottom={5}>
                        <Text bold>Subject: {applybtn.subject}</Text>
                      </Flex>
                      <Flex>
                        <Flex marginBottom={5}>
                          <Text bold>Content:</Text>
                        </Flex>
                        <div
                          className={styles.templatealignment}
                          dangerouslySetInnerHTML={{ __html: valuelist }}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
        <Flex columnFlex row center end className={styles.botomBtncontainer}>
          <Flex row width={130} style={{ justifyContent: 'space-between' }}>
            <Button
              types="close"
              onClick={() => {
                close();
              }}
            >
              {CANCEL}
            </Button>

            <Button
              types="primary"
              disabled={searchResults?.length === 0 ? true : false}
              onClick={() => {
                handleCopy(valuelist, applybtn.subject);
              }}
            >
              Add
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default MessageTemplate;
