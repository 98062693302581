import { ICardSelectionMap } from './applicantPipeLineTypes';
import { IStageColumn } from './dndBoardTypes';
import styles from './dndtitle.module.css';
import DndTitleMap from './DndTitleMap';

type Props = {
  columns: IStageColumn[];
  setSortApplicant: (columnId: number, arg: string, backword?: boolean) => void;
  onSelectAll?: (data: IStageColumn) => void;
  onUnselectAll?: (data: IStageColumn) => void;
  cardSelectionMap: ICardSelectionMap;
  new_apply_count: any;
  active_resume: any;
  stages_name?: any;
};
const DndTitle = ({
  columns,
  setSortApplicant,
  onSelectAll,
  onUnselectAll,
  cardSelectionMap,
  new_apply_count,
  active_resume,
  stages_name
}: Props) => {
  const isColumnSelected = (cardData: IStageColumn) => {
    const arrValue = Array.from(cardSelectionMap.values()).filter(
      (doc) => doc.section === cardData?.section,
    )?.length;
    if (cardData !== undefined) {
      const values = cardData.items.filter((val) =>
        active_resume.includes(val.candidate_id_id),
      );

      if (cardData.title === 'New Applicants') {
        if (values.length === arrValue && new_apply_count.length !== 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (arrValue === cardData?.total) {
          return true;
        }
        return false;
      }
    }
  };

  return (
    <div className={styles.col}>
      {columns.map((column, index) => {
        return (
          <DndTitleMap
            stages_name={stages_name}
            column={column}
            setSortApplicant={setSortApplicant}
            index={index}
            key={index + `${column?.title}`}
            onSelectAll={onSelectAll}
            onUnselectAll={onUnselectAll}
            columnSelected={isColumnSelected(column)}
          />
        );
      })}
    </div>
  );
};

export default DndTitle;
