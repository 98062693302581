import SvgDownload from '../../icons/SvgDownload';
import SvgDuplicate from '../../icons/SvgDuplicate';
import SvgInactivate from '../../icons/SvgInactivate';
import Svgwhatjobs from '../../icons/Svgwhatjobs';
import { LINK } from '../../uikit/Colors/colors';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import { Button } from '../../uikit';
import SvgRight from '../../icons/SvgRight';
import SvgNewTab from '../../icons/SvgNewTab';
import { toCamelCase } from '../../uikit/helper';
import { Jd } from './jdViewTypes';
import styles from './jdtitle.module.css';



type Props = {
  handleDownload: () => void;
  hanldeInactive: () => void;
  jdDetails: Jd;
  career_page_url: string;
  whatjob: any[];
  super_user: boolean;
  permission: any[];
  current_jd_count: number;
  setopensubcription: (val: any) => void;
};
const JdTitle = ({
  handleDownload,
  hanldeInactive,
  whatjob,
  jdDetails,
  career_page_url,
  super_user,
  permission,
  current_jd_count,
  setopensubcription,
}: Props) => {
  const permissions = permission.includes('create_post');
  const formattedJobTitle = jdDetails?.job_title.replace(/\s+/g, '-');
  return (
    <Flex>
      {/* <Flex row className={styles.ribbon} between>
          

          <Flex  row marginTop={10} marginLeft={8} >
            <Flex>
            <Text size={16} bold color="theme" >
              Job Posting 
            </Text></Flex>
            <Flex  marginTop={8} marginLeft={8} >
            <SvgRight fill={'#581845'} ></SvgRight></Flex>
            <Flex  marginTop={1} marginLeft={3}>
            <Text size={16} bold color="theme" >
            {jdDetails.job_title}</Text>
            </Flex>

          </Flex>
          <Flex >

            <div className={styles.triangle}></div>
          </Flex>
        
      </Flex> */}
      <Flex marginBottom={5}>
        <Text bold size={14} color='theme'>Job Metrics</Text>
      </Flex>
      <Flex row center between className={styles.jobDesFlex}>
        <Flex row center>
          <Text size={14} bold color="theme" style={{ marginRight: 15 }}>
            {toCamelCase(jdDetails.job_title)}
          </Text>
          {permissions === true ? (
            <>
              {jdDetails.is_ds_role === true ? (
                <>
                  {current_jd_count !== 0 ? (
                    <CustomTooltip title="Duplicate Job">
                      <div className={styles.svgMargin} >
                        <LinkWrapper
                          to={`/jobs/create_ds/${jdDetails.id}?duplicate=duplicate`}
                        >
                          <Text color="link" bold>
                            <SvgDuplicate
                              width={19}
                              height={19}
                              fill={'#581845'}
                            />
                          </Text>
                        </LinkWrapper>
                      </div>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip title="Duplicate Job">
                      <div className={styles.svgMargin} >
                        <Text
                          color="link"
                          bold
                          onClick={() => setopensubcription(true)}
                        >
                          <SvgDuplicate width={19} height={19} fill={'#979797'} />
                        </Text>
                      </div>
                    </CustomTooltip>
                  )}
                </>
              ) : (
                <>
                  {current_jd_count !== 0 ? (
                    <CustomTooltip title="Duplicate Job">
                      <div className={styles.svgMargin}  >
                        <LinkWrapper to={`/jobs/create_your_job/${jdDetails.id}`}>
                          <Text color="link" bold>
                            <SvgDuplicate width={19} height={19} />
                          </Text>
                        </LinkWrapper>
                      </div>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip title="Duplicate Job">
                      <div className={styles.svgMargin} >
                        <Text
                          color="link"
                          bold
                          onClick={() => setopensubcription(true)}
                        >
                          <SvgDuplicate width={19} height={19} fill={'#979797'} />
                        </Text>
                      </div>
                    </CustomTooltip>
                  )}
                </>
              )}

              {jdDetails.jd_status__label_name === 'Active' && (
                <CustomTooltip title="Inactivate Job">
                  <div
                    tabIndex={-1}
                    role={'button'}
                    onKeyPress={() => { }}
                    onClick={hanldeInactive}
                    className={styles.svgMargin}
                  >
                    <SvgInactivate width={19} height={19} fill={'#581845'} />
                  </div>
                </CustomTooltip>
              )}
              {jdDetails.jd_status__label_name === 'Inactive' && (
                <CustomTooltip title="Inactivate Job">
                  <div
                    tabIndex={-1}
                    role={'button'}
                    className={styles.svgMargin}
                  >
                    <SvgInactivate width={19} height={19} fill={'#979797'} />
                  </div>
                </CustomTooltip>
              )}
            </>
          ) : (
            ''
          )}
        </Flex>
        <Flex row center>
          <Flex row end center>
            <Flex marginRight={10}>
              <LinkWrapper
                to={`/applicant_pipe_line/${jdDetails?.id}/`}
              >
                <Flex row>
                  <Button types="secondary">View Applicants</Button>
                </Flex>
              </LinkWrapper>
            </Flex>
            <Flex>
              <LinkWrapper
                to={`/bulk_import?tab=1&jd_ids=${jdDetails?.id}`}
              >
                <Flex row>
                  <Button 
                  types="primary"
                  disabled = {permission.includes('bulkImport_candidates') ? false : true}
                  >Add New Applicants</Button>
                </Flex>
              </LinkWrapper>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default JdTitle;
