import * as React from 'react';
const defaultProps = {
  fill: '#581845',
  width: 22,
  height: 22,
};

const SvgJobTitleIcon = ({width, height, fill}:typeof defaultProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox='0 0 16 16'
    // {...props}
  >
    <path
      fill={fill}
      d="M13.334 4c.386 0 .7.133.946.394.254.273.387.573.387.94v7.333c0 .367-.133.667-.387.94-.246.26-.56.393-.946.393H2.667c-.387 0-.7-.133-.947-.393-.253-.273-.387-.573-.387-.94V5.334c0-.367.134-.667.387-.94.247-.26.56-.394.947-.394h2.667V2.667c0-.387.133-.7.386-.947.247-.253.56-.387.947-.387h2.667c.386 0 .7.134.946.387.254.247.387.56.387.947V4h2.667ZM2.667 5.333v7.334h10.667V5.334H2.667ZM9.334 4V2.667H6.667V4h2.667ZM8 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm3 6H5v-.746c0-.834 1.333-1.5 3-1.5s3 .666 3 1.5V12Z"
    />
  </svg>
);
export default SvgJobTitleIcon;