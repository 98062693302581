/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
  fill: '#581845',
  width: 14,
  height: 14,
};

const SvgArrowRight = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.71983 8.70129C7.64614 8.76995 7.58704 8.85275 7.54605 8.94475C7.50506 9.03675 7.48301 
            9.13607 7.48124 9.23677C7.47946 9.33747 7.49799 9.4375 7.53571 9.53089C7.57343 9.62428 7.62957 9.70911
             7.70079 9.78033C7.77201 9.85155 7.85684 9.90769 7.95023 9.94541C8.04362 9.98314 8.14365 10.0017 8.24435
              9.99988C8.34505 9.99811 8.44437 9.97606 8.53637 9.93507C8.62837 9.89408 8.71117 9.83498 8.77983
               9.76129L12.7798 5.76129C12.8496 5.69179 12.905 5.6092 12.9428 5.51825C12.9806 5.4273 13 5.32978 13
                5.23129C13 5.1328 12.9806 5.03529 12.9428 4.94434C12.905 4.85338 12.8496 4.77079 12.7798 4.70129L8.77983
                 0.701291C8.71117 0.627604 8.62837 0.568502 8.53637 0.527511C8.44437 0.486519 8.34505 0.464478 8.24435
                  0.462701C8.14365 0.460924 8.04362 0.479448 7.95023 0.517169C7.85684 0.55489 7.77201 0.611035 7.70079
                   0.682254C7.62957 0.753472 7.57343 0.838306 7.53571 0.931695C7.49799 1.02508 7.47946 1.12511 7.48124
                    1.22581C7.48301 1.32652 7.50506 1.42583 7.54605 1.51783C7.58704 1.60983 7.64614 1.69263 7.71983
                     1.76129L10.4398 4.48129L0.749829 4.48129C0.550917 4.48129 0.360151 4.56031 0.219499 4.70096C0.078846
                      4.84161 -0.000170708 5.03238 -0.000170708 5.23129C-0.000170708 5.4302 0.078846 5.62097 0.219499
                       5.76162C0.360151 5.90227 0.550917 5.98129 0.749829 5.98129L10.4398 5.98129L7.71983 8.70129Z"
        fill="#581845"
      />
    </svg>
  );
};
SvgArrowRight.defaultProps = defaultProps;
export default SvgArrowRight;
