import { Flex } from '../../../uikit';
import Button from '../../../uikit/Button/Button';
import { getDateString, isEmpty } from '../../../uikit/helper';
import Text from '../../../uikit/Text/Text';
import styles from './subscriptionplan.module.css';

export type tableDataTypes = {
  plan: number | undefined;
  invoice: string | undefined;
  billing: string;
  plan_price: number;
  totalUser: number;
  total_price: number;
  next_billing: string | undefined;
  current_status: boolean | undefined;
  subscription_changed_to: any;
  free_expired: any;
};
type Props = {
  data: any;
};

const sidebar = sessionStorage.getItem('EmpToggle');
const sidebar1 = sidebar === '1';
const PlanTable = (data: Props) => {
  return (
    <Flex marginTop={15} row style={{ marginBottom: '15px', paddingLeft: '6px' }} between >
      <Flex
        center
        className={styles.boxshadow}
        style={{ width: '117px', display: 'flex' }}
      >
        <Text style={{ color: '#581845' }}>Subscription Plan</Text>
        <Text className={styles.textstyle}>
            <Text bold>{data.data[0].plan_name}</Text>
        </Text>
      </Flex>
      <Flex
        center
        className={styles.boxshadow}
        style={{ width: '117px', display: 'flex' }}
      >
        <Text style={{ color: '#581845' }}>Invoice Date</Text>
        <Text className={styles.textstyle}>
          <Text bold>{getDateString(data.data[0].invoice, 'll')}</Text>
        </Text>
      </Flex>
      <Flex
        center
        className={styles.boxshadow}
        style={{ width: '117px', display: 'flex' }}
      >
        <Text style={{ color: '#581845' }}>Billing Frequency</Text>
        <Text className={styles.textstyle}>

          {data.data[0].plan !== 1?(data.data[0].days!==365 ? (
            <Text bold>Monthly</Text>
          ):(<Text bold>Annual</Text>)):(
            <Text bold>-</Text>)}
         
        </Text>
      </Flex>

      <Flex
        center
        className={styles.boxshadow}
        style={{ width: '117px', display: 'flex' }}
      >
        <Text style={{ color: '#581845' }}>Plan Price</Text>
        <Text className={styles.textstyle}>
          <Text bold>
            {data.data[0].plan_price !==0 ? `$${data.data[0].plan_price}`:'Free'}
          </Text>
        </Text>
      </Flex>
      <Flex
        center
        className={styles.boxshadow}
        style={{ width: '117px', display: 'flex' }}
      >
        <Text style={{ color: '#581845' }}>Total Users</Text>
        <Text className={styles.textstyle}>
          <Text bold>{data.data[0].totalUser}</Text>
        </Text>
      </Flex>
      <Flex
        center
        className={styles.boxshadow}
        style={{ width: '117px', display: 'flex' }}
      >
        <Text style={{ color: '#581845' }}>Available Add-Ons</Text>
        <Text className={styles.textstyle}>
          <Text bold>{data.data[0].avail_addons}</Text>
        </Text>
      </Flex>
      <Flex
        center
        className={styles.boxshadow}
        style={{ width: '117px', display: 'flex' }}
      >
        <Text style={{ color: '#581845' }}>Next Billing Date</Text>
        <Text className={styles.textstyle}>
          <Text bold>{getDateString(data.data[0].next_billing, 'll')}</Text>
        </Text>
      </Flex>
      <Flex
        center
        className={styles.boxshadow}
        style={{ width: '117px', display: 'flex' }}
      >
        <Text style={{ color: '#581845' }}>Plan Status</Text>
        <Text className={styles.textstyle}>
          {data.data[0].current_status === true &&
            isEmpty(data.data[0].subscription_changed_to) &&
            data.data[0].plan !== 1 && (
              <Text bold style={{ color: '#00BE4B' }}>
                {' '}
                Active{' '}
              </Text>
            )}
          {data.data[0].plan === 1 && (
            <>
              {data.data[0].plan === 1 && data.data[0].free_expired === 0 ? (
                <Text bold style={{ color: 'red' }}>
                  Expired
                </Text>
              ) : (
                <Text bold style={{ color: '#00BE4B' }}>
                  Active{' '}
                </Text>
              )}
            </>
          )}

          {data.data[0].current_status === false &&
            data.data[0].free_expired !== 0 && (
              <Text bold style={{ color: 'red' }}>
                Inactive
              </Text>
            )}
          {data.data[0].current_status === true &&
            Number(data.data[0].subscription_changed_to) === -1 &&
            data.data[0].plan !== 1 && (
              <Text bold style={{ color: 'blue' }}>
                Cancelled
              </Text>
            )}
          {data.data[0].current_status === true &&
            Number(data.data[0].subscription_changed_to) === -2 &&
            data.data[0].plan !== 1 && (
              <Text bold style={{ color: 'red' }}>
                Expired
              </Text>
            )}
        </Text>
      </Flex>
    </Flex>
  );
};
export default PlanTable;
