import * as React from 'react';
const SvgShareLink = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={17}
    fill="none"
    {...props}
  >
    <path
      stroke="#581845"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.469 6.125h1.484a1.484 1.484 0 0 1 1.485 1.484v7.125a1.484 1.484 0 0 1-1.485 1.485H2.047a1.484 1.484 0 0 1-1.485-1.485V7.61a1.484 1.484 0 0 1 1.485-1.484H3.53M9.47 3.75 6.5.781m0 0L3.531 3.75M6.5.781v10.131"
    />
  </svg>
);
export default SvgShareLink;