export const Comparativeanalysis = [
  { value: '1', label: 'Skills' },
  { value: '2', label: 'Industry Specific Experience' },
  { value: '3', label: 'Roles and Responsibilities' },
  { value: '4', label: 'Domain Specific Experience' },
  { value: '5', label: 'Experience' },
  { value: '6', label: 'Certification' },
  { value: '7', label: 'Technical Tools and Languages' },
  { value: '8', label: 'Location Alignment' },
  { value: '9', label: 'Soft Skills' },
  { value: '10', label: 'Cultural Fit' },
  { value: '11', label: 'Qualification' }, 
  { value: '12', label: 'References and Recommendation' },
];
 