import React, { createRef, useRef } from 'react';
import { useState, useEffect } from 'react';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import SvgHelp from '../../icons/SvgHelp';
import Card from '../../uikit/Card/Card';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Button from '../../uikit/Button/Button';
import SvgDocument from '../../icons/SvgDocument';
import SvgSupport from '../../icons/SvgSupport';
import SvgMailsupport from '../../icons/SvgMailsupport';
import styles from './help.module.css';

type Props = {
  show?: boolean;
  candiStyle?: boolean;
};

const Help = ({ show, candiStyle }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [helpOpen, sethelpOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      sethelpOpen(true);
    }, 500);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current === null &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEmailCommunication = (e) => {
    setIsOpen(false);
    e.preventDefault();
    const toAddress = 'support@zita.ai';
    const mailtoLink = `mailto:${toAddress}`;
    try {
      window.open(mailtoLink, '_blank');
    } catch (error) {}
    //window.open(mailtoLink, '_blank');
  };

  const documents = () => {
    setIsOpen(false);
    window.open('https://zita.ai/knowledgebase', '_blank');
  };

  const help_click = () => {
    setIsOpen(false);
    // window.open(
    //   'https://share.hsforms.com/1_3IRNtXMTI6Xa3AGdP_pVwqmqrf',
    //   '_blank',
    // );
    if (show === true) {
      window.open(
        'https://share.hsforms.com/1_3IRNtXMTI6Xa3AGdP_pVwqmqrf',
        '_blank',
      );
    } else if (show === false) {
      window.open(
        'https://share.hsforms.com/1PpyfSm-ARC6q35HA-T58WQqmqrf',
        '_blank',
      );
    }
  };

  return (
    <>
      <Flex className={candiStyle === true ? '' : styles.App}>
        <div
          ref={buttonRef}
          role="button"
          style={{ marginBottom: candiStyle === true && 20}}
          className={helpOpen ? styles.help_icon : styles.help_icon1}
          onClick={(e) => toggleDropdown()}
        >
          <SvgHelp fill="white" height={17} width={17} />
          {helpOpen ? <span style={{ marginLeft: '5px' }}>Help</span> : ''}
        </div>
        {isOpen && (
          <Flex style={{bottom: candiStyle===true ? 80 : 60}} className={styles.dropdown} ref={dropdownRef}>
            <div
              role="button"
              className={styles.dropdown_value}
              onMouseDown={(e) => {
                e.stopPropagation();
                documents();
              }}
            >
              <SvgDocument />
              <Text
                color="theme"
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              >
                Documentation
              </Text>
            </div>
            <div
              role="button"
              className={styles.dropdown_value}
              onMouseDown={(e) => {
                e.stopPropagation();
                help_click();
              }}
            >
              <SvgSupport />
              <Text
                color="theme"
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              >
                Support Form
              </Text>
            </div>
            <div
              role="button"
              className={styles.dropdown_value}
              onMouseDown={(e) => {
                e.stopPropagation();
                handleEmailCommunication(e);
              }}
            >
              <SvgMailsupport />
              <Text
                color="theme"
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              >
                Email Support
              </Text>
            </div>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default Help;
