import { useEffect, useState } from 'react';
import { Button, Flex, LinkWrapper } from '../../../../uikit';
import styles from './confirmationdialog.module.css';

type Props = {
  message: string;
  onConfirm: (boolean) => void;
  onCancel: (boolean) => void;
  userrole: string;
  superuser: boolean;
  permission?: any;
};

const IntegrationPopup = ({
  message,
  onConfirm,
  onCancel,
  userrole,
  superuser,
  permission,
}: Props) => {
  const [value, setvalue] = useState(0);
  useEffect(() => {
    if (superuser === true) {
      setvalue(4);
    } else {
      if (userrole === 'Admin') {
        setvalue(2);
      } else {
        if (permission.includes('manage_account_settings')) {
          setvalue(2);
        } else {
          setvalue(1);
        }
      }
    }
  }, []);
  return (
    <>
      <Flex className={styles.container}>
        <Flex row className={styles.message}>
          {'Integrate your calendar inside zita application to create events'}
        </Flex>
        <Flex row end>
          <Button
            types={'primary'}
            className={styles.cancel}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LinkWrapper to={`/account_setting/integration?tab=${value}`}>
            <Button className={styles.button} onClick={onConfirm}>
              Integrate Calendar
            </Button>
          </LinkWrapper>
        </Flex>
      </Flex>
    </>
  );
};

export default IntegrationPopup;
