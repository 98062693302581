import React from "react";
import { Link } from "react-router-dom";
import './footer.css'
import image from '../../../../src/assets/images/Zita.png'

const Footer = () => {
  return (
    <div
      className="row footerpadding"
      style={{ justifyContent: "space-between" }}
    >
      <div className="col-lg-1 col-md-12 footer-about-widget">
        <a href="https://www.zita.ai" className="logo">
          {/* <img src="images/logo/logo_innov.png" alt="coworking space coimbatore" style={{width:"140px"}} /> */}
          <img
            src={image}
            alt="Zita"
            style={{ width: "140px", marginTop: "-15px" }}
          />
        </a>
      </div>
      {/* /.footer-list */}
      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title" >Mailing Address</h5>
        <ul style={{marginTop:"6px"}}>
          {/* <li>
            <div className="mail-address">
            5473 Blair Rd Ste 100 PMB 745087

Dallas, Texas 75231-4227 US
            </div>
          </li>
          <li>
            <div className="mail-address">
              Sense7ai Inc,277/1A, Annamalai
              Industrial Park, Kalapatti, Coimbatore,Tamil Nadu, India, 641048.
            </div>
          </li> */}
          <li>
            <div className="mail-address">
             Email: <a href="mailto:support@zita.ai" style={{cursor:'pointer'}}>support@zita.ai</a>
            </div>
          </li>
          {/* <li>
            <div className="mail-address">
          Email: <a href="mailto:support@zita.ai" style={{cursor:'pointer'}}>support@zita.ai</a>
            </div>
          </li> */}
       
   
            </ul>
      </div>
      {/* /.footer-list */}
      <div className="col-lg-2 col-md-4 footer-list" style={{minWidth:"280px"}}>
        <h5 className="footer-title" >Features</h5>
        <ul >
          <li>
            <a href="https://www.zita.ai/applicant-tracking-system" onClick={() => window.scrollTo(0, 0)}>Applicant Tracking System </a>
          </li>
          <li>
            <a href="https://www.zita.ai/create-and-postjob"  onClick={() => window.scrollTo(0, 0)}>Create And Post Job</a>
          </li>
          <li>
            <a href="https://www.zita.ai/branded-career-page" onClick={() => window.scrollTo(0, 0)}>Branded Career Pages</a>
          </li>
          <li>
            <a href="https://www.zita.ai/human-like-ai-matching" onClick={() => window.scrollTo(0, 0)}>Human-Like AI Matching</a>
          </li>
          <li>
            <a href="https://www.zita.ai/candidate-matching" onClick={() => window.scrollTo(0, 0)}>Candidate Matching</a>
          </li>
          <li>
            <a href="https://www.zita.ai/kanban-hiring-board" onClick={() => window.scrollTo(0, 0)}>Customizable Hiring Board</a>
          </li>
          <li>
            <a href="https://www.zita.ai/talent-sourcing" onClick={() => window.scrollTo(0, 0)}>Talent Sourcing</a>
          </li>
          <li>
            <a href="https://www.zita.ai/comparative-analysis"  onClick={() => window.scrollTo(0, 0)}>Comparative Analysis</a>
          </li>
          <li>
            <a href="https://www.zita.ai/zita-profile-view" onClick={() => window.scrollTo(0, 0)}>Zita Profile View</a>
          </li>
          <li>
            <a href="https://www.zita.ai/in-appcalendar-interview-scheduler"  onClick={() => window.scrollTo(0, 0)}>Calendar & Interview Scheduler</a>
          </li>
          <li>
            <a href="https://www.zita.ai/interview-questions"  onClick={() => window.scrollTo(0, 0)}>Interview Questions by AI</a>
          </li>
          <li>
            <a href="https://www.zita.ai/team-collaboration"  onClick={() => window.scrollTo(0, 0)}>Team Collaboration</a>
          </li>
          <li>
            <a href="https://www.zita.ai/inapp-mailboxintegration"  onClick={() => window.scrollTo(0, 0)}>In app Mailbox Integration</a>
          </li>
          <li>
            <a href="https://www.zita.ai/reports"  onClick={() => window.scrollTo(0, 0)}>Reports & Insights</a>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}

      <div className="col-lg-2 col-md-4 footer-list">
        <h5 className="footer-title" >Help Center</h5>
        <ul >
          <li>
            <a href="https://www.zita.ai/knowledgebase" onClick={() => window.scrollTo(0, 0)}>Knowledge Base</a>
          </li>
        </ul>
      </div>
      <div className="col-lg-2 col-md-4 footer-list">
        <h5 className="footer-title" >Insights</h5>
        <ul >
          <li>
          <a
              href="https://www.app.zita.ai/plan_and_pricing"
              target="_blank"
              rel="noreferrer"
            >Plan & Pricing</a>
          </li>
          <li>
            <a href="https://www.zita.ai/zita-ats" onClick={() => window.scrollTo(0, 0)}>Zita ATS</a>
          </li>
          <li>
            <a href="https://www.zita.ai/roadmap" onClick={() => window.scrollTo(0, 0)}>Roadmap</a>
          </li>
          <li>
            <a href="https://www.zita.ai/blog" onClick={() => window.scrollTo(0, 0)}>Blog</a>
          </li>
          <li>
            <a href="https://www.zita.ai/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</a>
          </li>
        </ul>
      </div>

      {/* /.about-widget */}
    </div>
    //.row
  );
};

export default Footer;
