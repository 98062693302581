import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SvgEdit } from '../../../../icons';
import SvgDelete from '../../../../icons/SvgDelete';
import {
  Button,
  Text,
  Flex,
  Toast,
  LinkWrapper,
  Loader,
} from '../../../../uikit';
import styles from '../emailtemplates/emailtable.module.css';
import { AppDispatch, RootState } from '../../../../store';
import {
  emailtemplatesdeleteMiddleWare,
  emailtemplatesgetMiddleWare,
} from '../../store/middleware/accountsettingmiddleware';
import Modal from '../../../../uikit/v2/Modal/Modal';
import SvgNoData from '../../../../icons/SvgNoData';
import SvgAdd from '../../../../icons/SvgAdd';
import TemplateDescriptionmodal from './templatedescriptionModal';
import Emailopenmodal from './emailopenModal';

type props = {
  handleOpenEmailModal: () => void;
  itemvalue: any;
  setitemvalue: any;
  setTitle: any;
  setDescription: any;
  setSubject: any;
  filteredData: any;
  setFilteredData: any;
  searchInput: any;
  setSearchInput: any;
  istemplateid?: any;
};

const Table = ({
  handleOpenEmailModal,
  itemvalue,
  setitemvalue,
  setTitle,
  setDescription,
  setSubject,
  filteredData,
  setFilteredData,
  searchInput,
  setSearchInput,
  istemplateid,
}: props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isTempDescModal, setTempDescModal] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [DeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isdelete, setdelete] = useState<any>();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [buttonflexHeight, setbuttonflexHeight] = useState(
    window.innerHeight - 260,
  );
  const [isLoader, setLoader] = useState(false);

  const { data } = useSelector(({ emailTemplateReducers }: RootState) => {
    return {
      data: emailTemplateReducers.data,
    };
  });

  const isTitleAlreadyExists = (titles: string) => {
    const titleExists = emailTemplates?.some(
      (template) => template.isTitle === titles,
    );
    return titleExists;
  };

  const isSubjectAlreadyExists = (Subject: string) => {
    const subjectExists = emailTemplates?.some(
      (template) => template.isSubject === Subject,
    );
    return subjectExists;
  };

  // useEffect function for scroll and focus the perticular template data based on update or create the template.
  useEffect(() => {
    const targetElement = document.getElementById(istemplateid);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      // setEditedId(null)
    }
  }, [istemplateid, filteredData]);

  const divStyle = {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '30px',
    borderTop: '0px',
    width: '360px',
  };

  function formatDate(dateString: string | number | Date) {
    const date = new Date(dateString);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const day = isNaN(date.getDate()) ? '' : date.getDate();
    const month = isNaN(date.getMonth()) ? '' : months[date.getMonth()];
    const year = isNaN(date.getFullYear()) ? '' : date.getFullYear();

    return `${month} ${day}, ${year}`;
  }

  const handleTempDescModal = (template) => {
    setSelectedTemplate(template);
    setTempDescModal(!isTempDescModal);
    if (itemvalue !== null) {
      setitemvalue(null);
    }
  };

  const handleDeletePopupClose = () => {
    setDeletePopupOpen(false);
  };

  const editfunction = (item: any) => {
    handleOpenEmailModal();
    setitemvalue(item);
  };

  const onDeletefunction = (item: any) => {
    setDeletePopupOpen(true);
    setdelete(item.id);
  };

  const confirmdeletefun = () => {
    const id = isdelete;
    dispatch(emailtemplatesdeleteMiddleWare(id)).then((res) => {
      if (res.payload.success === true) {
        setDeletePopupOpen(false);
        dispatch(emailtemplatesgetMiddleWare({ user: '1' }));
        Toast('Template deleted successfully.', 'LONG', 'success');
      } else {
        Toast(
          'Oops! Something went wrong. Please try again shortly.',
          'LONG',
          'error',
        );
        setDeletePopupOpen(false);
      }
    });
  };
  useEffect(() => {
    if (itemvalue !== null && itemvalue !== undefined) {
      setTitle(itemvalue.name);
      setDescription(itemvalue.templates);
      setSubject(itemvalue.subject);
    }
    dispatch(emailtemplatesgetMiddleWare({ user: '1' }));
  }, []);

  useEffect(() => {
    const filtered = data?.filter((template) =>
      template.name.toLowerCase().includes(searchInput.toLowerCase()),
    );
    setFilteredData(filtered);
  }, [searchInput, data]);

  useEffect(() => {
    setLoader(true);
    dispatch(emailtemplatesgetMiddleWare({ user: '1' })).then((res) => {
      setEmailTemplates(res.payload.data);
      setLoader(false);
    });
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setbuttonflexHeight(window.innerHeight - 260);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const emailContent = filteredData?.filter(
    (filter) => filter.jd_id_id === null,
  );

  return (
    <Flex
      className="table-responsisssve "
      style={{ overflowY: 'scroll', display: 'flex' }}
      height={emailTemplates?.length !== 0 && window.innerHeight - 260}
    >
      {isLoader && <Loader />}
      <Flex>
        <table
          className="table"
          style={{ paddingLeft: 'none', marginBottom: '0rem' }}
        >
          <thead className={styles.stickyheader}>
            <tr>
              <th
                className={styles.padchange}
                style={{ width: '20%', borderBottom: '0', borderTop: '0' }}
              >
                <Text color="theme" bold className={styles.tabborder1}>
                  Template Title
                </Text>
              </th>
              <th
                className={styles.padchange}
                style={{ width: '30%', borderBottom: '0', borderTop: '0' }}
              >
                <Text color="theme" bold className={styles.tabborder1}>
                  Subject
                </Text>
              </th>
              <th
                className={styles.padchange}
                style={{ width: '12%', borderBottom: '0', borderTop: '0' }}
              >
                <Text color="theme" bold className={styles.tabborder1}>
                  Created By
                </Text>
              </th>
              <th
                className={styles.padchange}
                style={{ width: '10%', borderBottom: '0', borderTop: '0' }}
              >
                <Text color="theme" bold className={styles.tabborder1}>
                  Created On
                </Text>
              </th>
              <th
                className={styles.padchange}
                style={{ width: '6%', borderBottom: '0', borderTop: '0' }}
              >
                <Text color="theme" bold className={styles.tabborder2}>
                  Actions
                </Text>
              </th>
            </tr>
          </thead>
          {emailContent !== undefined &&
            emailContent.length > 0 &&
            emailContent.slice().map((template) => (
              <tbody
                key={template.id}
                style={{ paddingTop: 20 }}
                className={styles.tablebody}
                id={template.id?.toString()}
              >
                <tr id={template.id?.toString()}>
                  <td className={styles.padchang} style={{ borderTop: '0' }}>
                    <Flex
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleTempDescModal(template)}
                    >
                      <Text bold color="theme" className={styles.pointer}>
                        {template.name}
                      </Text>
                    </Flex>
                  </td>
                  <td className={styles.padchang} style={divStyle}>
                    {template.subject}
                  </td>
                  <td className={styles.padchang} style={{ borderTop: '0' }}>
                    <Flex className={styles.pointer}>
                      <Text title={template.full_name}>
                        {template.full_name}
                      </Text>
                    </Flex>
                  </td>
                  <td className={styles.padchang} style={{ borderTop: '0' }}>
                    {formatDate(template.created_on)}
                  </td>

                  <td className={styles.padchang} style={{ borderTop: '0' }}>
                    {template.name !== '' &&
                      template.subject !== '' &&
                      template.full_name !== '' &&
                      template.created_on !== '' ? (
                      <>
                        <Flex row className={styles.actionBtnContainer}>
                          <Flex
                            onClick={() => template.full_name !== 'Zita' && editfunction(template)}
                            style={{ cursor: template.full_name === 'Zita' ? 'default' : 'pointer' }}
                            title={template.full_name === 'Zita' && 'Default templates cannot be modified.'}
                          >
                            <SvgEdit
                              width={12}
                              height={12}
                              fill={
                                template.full_name === 'Zita'
                                  ? '#58184570'
                                  : '#581845'
                              }
                            />
                          </Flex>
                          <Flex
                            title={template.full_name === 'Zita' && 'Default templates cannot be deleted.'}
                            onClick={() => template.full_name !== 'Zita' && onDeletefunction(template)}
                            style={{ cursor: template.full_name === 'Zita' ? 'default' : 'pointer' }}
                          >
                            <SvgDelete
                              width={16}
                              height={16}
                              fill={
                                template.full_name === 'Zita'
                                  ? '#58184570'
                                  : '#581845'
                              }
                            />
                          </Flex>
                        </Flex>
                      </>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
        {!searchInput && filteredData?.length === 0 && (
          <Flex middle marginTop={6} center wrap height={buttonflexHeight}>
            <Flex center>
              <Flex center>
                <Text bold className={styles.postyet1} size={16}>
                  No Email Templates - Yet !
                </Text>
              </Flex>
              <Flex marginBottom={5}>
                <Flex center middle>
                  <Text className={styles.postyet2} size={13}>
                    Looks like you haven’t created any templates
                  </Text>
                </Flex>
                <Flex center middle>
                  <Text className={styles.postyet3} size={13}>
                    No worries, just click on “Add Template” to kick start
                  </Text>
                </Flex>
              </Flex>
              <Flex center middle className={styles.postyet4}>
                <Button onClick={handleOpenEmailModal}>
                  <Flex row center className={styles.addtemplateBtn}>
                    <SvgAdd height={10} width={10} fill="#FFFFFF" />
                    <Text
                      bold
                      color="white"
                      size={13}
                      style={{ marginLeft: '10px' }}
                    >
                      Add Template
                    </Text>
                  </Flex>
                </Button>
              </Flex>{' '}
            </Flex>{' '}
          </Flex>
        )}
        {searchInput && filteredData?.length === 0 && (
          <Flex className={styles.nodataavailicon}>
            <Flex flex={8} center middle style={{ display: 'flex' }}>
              <SvgNoData width={16} height={16} fill={'#888888'} />
              <Text style={{ marginTop: '2px' }} size={13} color="placeholder">
                No templates available
              </Text>
            </Flex>
          </Flex>
        )}
        <Flex>
          {isTempDescModal && (
            <>
              <TemplateDescriptionmodal
                open={true}
                handleTempDescModal={handleTempDescModal}
                emailTemplates={emailTemplates}
                selectedTemplate={selectedTemplate}
              />
            </>
          )}
          {DeletePopupOpen && (
            <>
              <Modal open={true}>
                <Flex className={styles.deletepopup}>
                  <Flex>
                    <Text>
                      This action will permenently remove the email template and
                      its content.
                    </Text>
                    <Text>Are you sure to proceed?</Text>
                  </Flex>
                  <Flex className={styles.delBtnContainer}>
                    <Flex
                      row
                      center
                      width={'140px'}
                      style={{ justifyContent: 'space-between' }}
                    >
                      <Button types="close" onClick={handleDeletePopupClose}>
                        Cancel
                      </Button>
                      <Button onClick={confirmdeletefun}>Delete</Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Modal>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Table;
