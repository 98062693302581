import classNames from 'classnames/bind';
import { FormikProps } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import SvgBag from '../../../icons/SvgBag';
import SvgLocation from '../../../icons/SvgLocation';
import Button from '../../../uikit/Button/Button';
import Card from '../../../uikit/Card/Card';
import { GARY_4 } from '../../../uikit/Colors/colors';
import Flex from '../../../uikit/Flex/Flex';
import { enterKeyPress, isEmpty } from '../../../uikit/helper';
import InputText from '../../../uikit/InputText/InputText';
import Text from '../../../uikit/Text/Text';
import { mediaPath } from '../../constValue';
import SvgJobTitles from '../../../icons/SvgJobTitles';
import { InputSearch, InputSwitch } from '../../../uikit';
import SvgJobTitleIcon from '../../../icons/SvgJobTitleIcon';
import SvgIntomark from '../../../icons/Intomark';
import BannerCardList from './BannerCardList';
import { CareerPageSetting, JdFormEntity } from './buildCareerPageTypes';
import styles from './careerviewbanner.module.css';

const cx = classNames.bind(styles);

type Props = {
  career_page_setting: CareerPageSetting;
  jd_form: JdFormEntity[];
  total: number;
  formik: FormikProps<any>;
  jd_active: boolean;
  setCount?: (a?: any) => void;
  job_location?: any;
  job_title?: any;
};

const CareerViewBanner = ({
  career_page_setting,
  jd_form,
  total,
  formik,
  jd_active,
  setCount,
  job_location,
  job_title
}: Props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 850px)' });

  const [showRemoteJobs, setShowRemoteJobs] = useState(false);

  // Step 2: Filter jd_form based on toggle switch status
  const filteredJdForm = showRemoteJobs
    ? jd_form.filter((job) => job.work_space_type === '3')
    : jd_form;
  const handlelocationClear = () => {
    formik.setValues({
      ...formik.values,
      job_location: '',
    });
    formik.handleSubmit();
  };
  const handletitleClear = () => {
    formik.setValues({
      ...formik.values,
      job_title: '',
    });
    formik.handleSubmit();
  };
  const fontFamily = career_page_setting.page_font;
  useEffect(() => {
    setCount(jd_form.length)
  }, [filteredJdForm])
  return (
    <Flex height={"100%"} width={"100%"} className={styles.overAll}>
      <Flex className={styles.imgFlex}>
        <div style={{ position: 'relative', width: '100%' }}>
          <img
            alt="Banner"
            src={mediaPath + career_page_setting.banner_img}
            className={styles.bannerStyle}
          />
          <div className={styles.overLay} />
        </div>
        <Flex center middle className={styles.innerText}>
          <Text
            align="center"
            color="white"
            bold
            style={{
              fontSize: career_page_setting.banner_heading_size,
              fontFamily,
            }}
          >
            {career_page_setting.banner_header_text}
          </Text>
          <Text
            align="center"
            color="white"
            style={{
              fontSize: career_page_setting.banner_font_size,
              fontFamily,
            }}
            className={styles.bannerTextStyle}
          >
            {career_page_setting.banner_text}
          </Text>
        </Flex>
      </Flex>
      <Flex height={"100%"} style={{ padding: '10px 10px 7px 30px' }}>
        {jd_active === true && (
          <Card className={styles.searchCard} >
            <Flex row={!isMobile} center>
              <Flex marginRight={10}>
                <Text bold style={{ whiteSpace: 'nowrap' }}>
                  Im Interested in
                </Text>
              </Flex>
              <Flex style={{ position: 'relative', width: '50%' }}>
                <div className={styles.svgBagStyle}>
                  <SvgJobTitleIcon height={18} width={18} fill={career_page_setting.button_color} />
                </div>
                <InputSearch
                  initialValue={formik.values.job_title}
                  placeholder="Job Title"
                  options={job_title}
                  CrossiconSpace
                  frontIconSpace
                  truncatewidth
                  labelBold
                  setFieldValue={formik.setFieldValue}
                  style={styles.jobTitleInput}
                  onkeyPress={(e) => {
                    if (e.target.innerText) {
                      formik.setFieldValue('job_title', e.target.innerText)
                    }
                    else {
                      formik.setFieldValue('job_title', e.target.value)
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.innerText) {
                      formik.setFieldValue('job_title', e.target.innerText)
                    }
                    else {
                      formik.setFieldValue('job_title', e.target.value)
                    }
                  }}
                />
                {/* <InputText
                  value={formik.values.job_title}
                  onChange={formik.handleChange('job_title')}
                  className={styles.jobTitleInput}
                  placeholder="Job Title"
                  onKeyPress={(e) => enterKeyPress(e, formik.handleSubmit)}
                  style={{ width: '100%' }}
                /> */}
                {formik.values.job_title !== '' && (
                  <Flex
                    className={styles.jobtitleCloseIcon}
                    onClick={handletitleClear}
                  >
                    <SvgIntomark width={14} height={14} fill="#888888" />
                  </Flex>
                )}
              </Flex>
              <Flex marginLeft={10}>
                <Text bold style={{ whiteSpace: 'nowrap' }}>
                  Located in
                </Text>
              </Flex>
              <div
                className={styles.locationDiv}
                style={{
                  position: 'relative',
                  width: '50%',
                  margin: !isMobile ? '0 10px' : '10px 0',
                }}
              >
                <div className={styles.svgLocation}>
                  <SvgLocation
                    height={16}
                    width={16}
                    fill={career_page_setting.button_color}
                  />
                </div>
                <InputSearch
                  initialValue={formik.values.job_location}
                  placeholder="Location"
                  options={job_location}
                  frontIconSpace
                  CrossiconSpace
                  truncatewidth
                  labelBold
                  setFieldValue={formik.setFieldValue}
                  style={styles.locationInput}
                  onkeyPress={(e) => {
                    if (e.target.innerText) {
                      formik.setFieldValue('job_location', e.target.innerText)
                    }
                    else {
                      formik.setFieldValue('job_location', e.target.value)
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.innerText) {
                      formik.setFieldValue('job_location', e.target.innerText)
                    }
                    else {
                      formik.setFieldValue('job_location', e.target.value)
                    }
                  }}
                  disabled={showRemoteJobs}
                />
                {/* <InputText
                  onChange={formik.handleChange('job_location')}
                  value={formik.values.job_location}
                  className={styles.locationInput}
                  placeholder="Location"
                  onKeyPress={(e) => enterKeyPress(e, formik.handleSubmit)}
                  style={{ width: '100%' }}
                  disabled={showRemoteJobs}
                /> */}
                {formik.values.job_location !== '' && (
                  <Flex
                    className={styles.locationCloseIcon}
                    onClick={handlelocationClear}
                  >
                    <SvgIntomark width={14} height={14} fill="#888888" />
                  </Flex>
                )}
              </div>

              <Button
                disabled={
                  formik.values.job_title === '' &&
                  formik.values.job_location === ''
                }
                style={{
                  backgroundColor: career_page_setting.button_color,
                  borderColor: career_page_setting.button_color,
                }}
                onClick={formik.handleSubmit}
              >
                Search
              </Button>

              <Flex row center marginLeft={10}>
                <InputSwitch
                  onClick={() => setShowRemoteJobs(!showRemoteJobs)}
                  checked={showRemoteJobs}
                />
                <Text color='theme' size={13}>
                  Remote
                </Text>
              </Flex>
            </Flex>
          </Card>
        )}

        <div style={{ height: "100%", width: "100%" }}
          className={cx('paddingStyle', 'marginAuto', {
            disPlayStyle: jd_form && jd_form.length === 0,
          })}
        >
          {/* {jd_form && jd_form.length !== 0 ? (
            <BannerCardList
              key={Date.now()}
              total={total}
              jd_form={filteredJdForm}
              career_page_setting={career_page_setting} 
              showRemoteJobs={showRemoteJobs}            />
          ) : (
            <Flex flex={1} center middle height={232}>
              {jd_active === true && (
                <Text align="center" color="gray">
                  No jobs found
                </Text>
              )}
              {jd_active !== true && (
                <Text align="center" color="gray">
                  No Current Openings
                </Text>
              )}
            </Flex>
          )} */}
          <BannerCardList
            key={Date.now()}
            total={total}
            jd_form={filteredJdForm}
            career_page_setting={career_page_setting}
            showRemoteJobs={showRemoteJobs}
            jd_active={jd_active}
          />
        </div>
      </Flex>
    </Flex>
  );
};
export default CareerViewBanner;
