import { useEffect, useState } from 'react';
import SvgAdd from '../../../../icons/SvgAdd';
import SvgBack from '../../../../icons/SvgBack';
import { Button, Text, Flex, Modal } from '../../../../uikit';
import CustomTooltip from '../../../../uikit/Tooltip/tooltip';
import SvgSearch from '../../../../icons/SvgSearch';
import SvgIntomark from '../../../../icons/Intomark';
import { toCamelCase } from '../../../../uikit/helper';
import styles from './emailtemplateScreen.module.css';
import Emailopenmodal from './emailopenModal';
import Table from './table';

type props = {
  handleBack?: () => void;
  setTitle?: any;
  setDescription?: any;
  setEmailTemplates?: any;
  setSubject?: any;
  emailTemplates?: any;
};

const Emailtemplatescreen = ({
  handleBack,
  setTitle,
  setDescription,
  setEmailTemplates,
  setSubject,
  emailTemplates,
}: props) => {
  const [isOpenEmailModal, setOpenEmailModal] = useState(false);
  const [itemvalue, setitemvalue] = useState<any>(null);
  const [istemplateid, settemplateid] = useState<any>(null);
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [searchInputTemp, setSearchInputTemp] = useState('');
  const [isTempTagIntemation, setTempTagIntemation] = useState(false);
  const [isTempDisableTag, setTempDisableTag] = useState('');

  const handleOpenEmailModal = () => {
    setOpenEmailModal(!isOpenEmailModal);
    settemplateid(null);
    if (itemvalue !== null) {
      setitemvalue(null);
    }
  };

  const handleInputChange = (e) => {
    setSearchInputTemp(e.target.value);
  };

  const handleSearchButtonClick = () => {
    setSearchInput(searchInputTemp);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  const handleInputClear = () => {
    setSearchInputTemp('');
    setSearchInput('');
  };
  const hasUpperCaseWord = (parts: string[]) => {
    return parts.some(word => word === word.toUpperCase());
  }

  const changeCase = (str: string) => {
    const parts = str.split(' ');

    if (hasUpperCaseWord(parts)===true) {
      return parts
      .map(word => (word === word.toUpperCase() ? word : word.toLowerCase()))
      .join(' ');
    }    
    if (hasUpperCaseWord(parts)===false) {
      return str.toLowerCase()
    }
  }
  return (
    <Flex
      column
      className={styles.overflowContainer}
      style={{ padding: '0px 10px' }}
    >
      <Flex row end className={styles.titleBar}>
        <Flex row between>
          <Flex row marginRight={20}>
            <Flex
              title={
                filteredData?.length === 0 &&
                !searchInput &&
                'Please Add Email templates to Search.'
              }
            >
              <input
                type="text"
                disabled={filteredData?.length === 0 && !searchInput}
                placeholder="Search by template title"
                value={searchInputTemp}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                className={styles.searchbarstyle}
                style={{
                  fontSize: '13px',
                }}
              />
            </Flex>
            {searchInputTemp.trim() !== '' && (
              <button className={styles.crossIcon} onClick={handleInputClear}>
                <SvgIntomark width={14} height={14} fill="#888888" />
              </button>
            )}
            <Flex className={styles.searchicons}>
              <Button
                types="link"
                onClick={handleSearchButtonClick}
                onKeyDown={() => {}}
              >
                <div style={{ marginTop: '-22px' }}>
                  <SvgSearch width={12} height={12} fill="#ffffff" />
                </div>
              </Button>
            </Flex>
          </Flex>
          {(filteredData?.length > 0 ||
            (searchInput && filteredData?.length === 0)) && (
            <Button onClick={handleOpenEmailModal}>
              <Flex row center className={styles.addtemplateBtn}>
                <SvgAdd height={10} width={10} fill="#FFFFFF" />
                <Text
                  bold
                  color="white"
                  size={13}
                  style={{ marginLeft: '10px' }}
                >
                  Add Template
                </Text>
              </Flex>
            </Button>
          )}
        </Flex>
      </Flex>
      {isOpenEmailModal && (
        <>
          <Emailopenmodal
            open={true}
            handleOpenEmailModal={handleOpenEmailModal}
            itemvalue={itemvalue}
            setEmailTemplates={setEmailTemplates}
            emailTemplates={emailTemplates}
            settemplateid={settemplateid}
            isTempTagIntemation={isTempTagIntemation}
            setTempTagIntemation={setTempTagIntemation}
            isTempDisableTag={isTempDisableTag}
            setTempDisableTag={setTempDisableTag}
          />
        </>
      )}
      <Flex>
        <Table
          handleOpenEmailModal={handleOpenEmailModal}
          setitemvalue={setitemvalue}
          itemvalue={itemvalue}
          setTitle={setTitle}
          setDescription={setDescription}
          setSubject={setSubject}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          istemplateid={istemplateid}
        />
      </Flex>
      {
        <Modal open={isTempTagIntemation}>
          <Flex className={styles.TempHubintemationPopup} width={window.innerWidth - 940}>
            <Flex>
              <Text>
                Please complete your company details in your profile setup. This
                will enable you to use the {changeCase(isTempDisableTag)} tag effectively in
                your email communications
              </Text>
            </Flex>
            <Flex center middle marginTop={16}>
              <Button onClick={() => setTempTagIntemation(false)}>OK</Button>
            </Flex>
          </Flex>
        </Modal>
      }
    </Flex>
  );
};

export default Emailtemplatescreen;
