/* eslint max-len: ["error", { "code": 2000 }] */
import { useEffect, useRef } from 'react';

const useApiInterval = (callback, intervalTime, isActive) => {
  const savedCallback = useRef(() => {});
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!isActive) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      return;
    }

    const tick = () => {
      savedCallback.current();
    };

    const setupInterval = () => {
      intervalId.current = setInterval(tick, intervalTime);
    };

    const lastApiCall = localStorage.getItem('lastApiCall');
    const now = Date.now();

    let initialDelay = intervalTime;
    if (lastApiCall) {
      const lastApiCallTime = Number(lastApiCall);
      const elapsedTime = now - lastApiCallTime;
      if (elapsedTime < intervalTime) {
        initialDelay = intervalTime - elapsedTime;
      } else {
        tick();
      }
    } else {
      tick();
    }

    const initialTimeout = setTimeout(() => {
      tick();
      setupInterval();
    }, initialDelay);

    return () => {
      clearTimeout(initialTimeout);
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [intervalTime, isActive]);
};

export default useApiInterval;
