import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ProgressBar from "@ramonak/react-progress-bar";
import { useMediaQuery } from 'react-responsive';
import { Bothcandidateidjobid } from '../../routes/apiRoutes';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import { Card, Modal, Toast } from '../../uikit';
import SvgDone from '../../icons/SvgDone';
import SvgClose from '../../icons/Svgnotmatch';
import { GARY_7, WHITE } from '../../uikit/Colors/colors';

import { AppDispatch, RootState } from '../../store';
import Tab from '../../uikit/Tabs/Tab';
import Blur from '../../uikit/Blur/blur';
import { Loader } from '../../uikit';
import { Button, LinkWrapper } from '../../uikit';
import Tabs from '../../uikit/Tabs/Tabs';
import SvgAngle from '../../icons/SvgAngle';
import SvgNoData from '../../icons/SvgNoData';
import SvgModuleicon from '../../icons/SvgModuleicon';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { config } from '../constValue';
import { removeUnderScores, lowerCase } from '../../uikit/helper';
import { WeightagematchinggetMiddleWare, WeightagematchingpostMiddleWare } from '../createjdmodule/store/middleware/createjdmiddleware';
import SvgUpArrow from '../../icons/SvgArrowUp';
import SvgArrowDown1 from '../../icons/SvgArrowDown1';
import {
  MatchArray,
  MatchJobArray,
  MatchLocationArray,
  WithoutArray,
  WithoutJobArray,
  WithoutLocationArray,
} from './matchAnalysisTab';
import styles from './matchinganalysistab.module.css';
import AllMatchTab from './AllMatchTab';
import { CandidatejobidMatchMiddleWare, WeightagematchingMiddleWare, applicantMatchMiddleWare } from './store/middleware/applicantProfileMiddleware';
import MatchingAnalysisbasicTab from './matchinganalysisbasic';
import Matchinganalysisweightage from './matchinganalysisweightage';
export interface DateEntity {
  not_matched_skills: [];
  not_matched_qualification: [];
}

//Dummy data for subcription purpose
const Matchingdummydata = () => {
  // subscription setstate
  const [isopensubcription, setopensubcription] = useState(false);

  const data = [
    {
      criteria: 'Technical skills',
      score: 10,
      weightage: 20,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      criteria: 'Roles and Responsibilities',
      score: 30,
      weightage: 20,
      description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      criteria: 'Experience',
      score: 20,
      weightage: 20,
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
      criteria: 'Technical Tools and Languages',
      score: 20,
      weightage: 20,
      description: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      criteria: 'Soft Skills',
      score: 20,
      weightage: 20,
      description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.',
    }
  ];

  return (
    <>
      <Blur
        width='100%'
        content='Feature to view the detailed matching score and descriptive analysis.'
        height={window.innerHeight - 135}
        rightalign='6px'
        marginTop={4}
        Addon
        upgrade
        handlefunction={setopensubcription}
      />
      <Flex row flex={12} height={window.innerHeight - 120} style={{ overflow: 'hidden' }}>
        <Flex flex={6} className={styles.overAll}>
          <Flex row between style={{ padding: '10px 10px 0px 10px' }}>
            <Text bold style={{ fontSize: '14px', marginBottom: '5px' }}>
              Score Analysis
            </Text>
            <Flex row className={styles.overallScore}>
              {['Profile Compatibility', 'Enhanced Matching'].map((title, index) => (
                <Flex key={index} row style={{ paddingLeft: index === 1 ? '20px' : 0 }}>
                  <Text size={13} style={{ marginRight: '10px' }}>{`${title} Score:`}</Text >
                  <ProgressBar
                    completed={10}
                    width="200px"
                    borderRadius='4px'
                    labelColor="black"
                    labelSize="13px"
                    labelAlignment="center"
                    labelClassName={styles.progressbarlabel}
                    isLabelVisible={true}
                  />
                  <Flex
                    style={{
                      position: 'absolute',
                      color: 'black',
                      width: "200px",
                      justifyContent: "center"
                    }}
                  >
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Flex row className={styles.btnwithContent}>
            <Flex row className={styles.aligncenter}>
              <Text>Adjust the weightage for job-candidate matching criteria</Text>
              <label className={styles.InfoiconchangeStyle}>
                <SvgModuleicon />
              </label>
            </Flex>
          </Flex>
          <Flex height={window.innerHeight - 224} style={{ overflow: "hidden", padding: "10px" }}>
            {['tech', 'nontech'].map((type, i) => (
              <Flex key={i} className={styles[`${type}cardstyles`]} marginBottom={20}>
                <Card>
                  <Flex style={{ padding: "15px" }}>
                    <Flex row marginBottom={10}>
                      <Flex style={{ width: "20%" }}>
                        <Text bold>{`${type === 'tech' ? 'Profile Compatibility' : 'Enhanced Matching'} Criteria`}</Text>
                      </Flex>
                      {['Score (100)', 'Weightage', 'Description'].map((label, index) => (
                        <Flex key={index} style={{ width: index === 2 ? '40%' : '20%', display: index === 1 ? 'flex' : 'block' }}>
                          <Text bold>{label}</Text>
                        </Flex>
                      ))}
                    </Flex>
                    {data.map((item, inde) => (
                      <Flex key={inde} className={styles.innerSliderbarStyle}>
                        <Flex className={styles.infohead1}>
                          <Text>{item.criteria}</Text>
                        </Flex>
                        <Flex className={styles.infohead2}>
                          <ProgressBar
                            completed={item.score}
                            bgColor="#581845"
                            width="100%"
                            height="6px"
                            borderRadius="4px"
                            labelColor="black"
                            labelAlignment="outside"
                          />
                        </Flex>
                        <Flex className={styles.infohead3}>
                          <Text>{item.score}</Text>
                        </Flex>
                        <Flex width={'40%'}>
                          <Flex>
                            <>
                              <Flex>
                                <Text>{item.description}</Text>
                              </Flex>
                              <Flex row center style={{ cursor: 'pointer' }}>
                                <Flex>
                                  <Text color="theme" bold>
                                    View Less
                                  </Text>
                                </Flex>
                                <Flex width={5}></Flex>
                                <Flex>
                                  <SvgUpArrow width={10} height={10} fill={"#581845"} />
                                </Flex>
                              </Flex>
                            </>
                          </Flex>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </Card>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          differece_key={['Ai_matching_applicant']}
          addon_name={'AI Resume Matching with Descriptive Analytics'}
        />
      )}
    </>
  )
}
export default Matchingdummydata;