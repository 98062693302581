import { CSSProperties } from 'react';
import classNames from 'classnames/bind';
import Text from '../Text/Text';
import CustomTooltip from '../Tooltip/tooltip';
import styles from './progressbar.module.css';


/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
  strokeWidth: 8,
  verticalWidth: '100%',
  roundProgressHeight: 100,
};
const cx = classNames.bind(styles);
type Props = {
  percentage: number;
  type?: 'hr' | 'round';
  verticalWidth?: string | number;
  matchingpercentage?: boolean;
  changingpercentageinmatching?: string | number;
  block_descriptive?: boolean | null;
  checkvalue?: any;
  checkingblur?: boolean;
} & typeof defaultProps;

const ProgressBar = ({
  strokeWidth,
  percentage,
  changingpercentageinmatching,
  matchingpercentage,
  type = 'round',
  verticalWidth,
  roundProgressHeight,
  block_descriptive,
  checkvalue,
  checkingblur
}: Props) => {
  const verticalPercentage = percentage;
  const radius = 50 - strokeWidth / 2;
  const pathDescription = ` 
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle: CSSProperties = {
    strokeLinecap: 'round',
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return type === 'round' ? (
    <svg
      className={styles.CircularProgressbar}
      viewBox="0 0 100 100"
      width={roundProgressHeight}
      height={roundProgressHeight}
    >
      <path
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: '#d6d6d6',
        }}
      />

      <path
        d={pathDescription}
        strokeWidth={strokeWidth}
        className={cx({
          countStyle1: verticalPercentage < 40,
          countStyle2: verticalPercentage >= 40 && verticalPercentage < 69,
          countStyle3: verticalPercentage > 69,
        })}
        fillOpacity={0}
        style={progressStyle}
      />

      <text
        x={50}
        y={50}
        style={{
          fill: '#424242',
          fontSize: '24px',
          dominantBaseline: 'central',
          textAnchor: 'middle',
          fontWeight: 'bold',
        }}
      >
        {`${percentage}%`}
      </text>
    </svg>
  ) : (
    <>
      {checkingblur === undefined ?
        <div className={styles.progressVertical} style={{ width: verticalWidth }}>
          <div
            style={{ width: `${changingpercentageinmatching ? changingpercentageinmatching : verticalPercentage}%` }}
            className={matchingpercentage ? styles.progressmatch : styles.progress}
          />
          <Text
            bold
            color="black"
            className={matchingpercentage ? styles.percentTextmatch : styles.percentText}
          >{`${changingpercentageinmatching ? changingpercentageinmatching : verticalPercentage}%`}</Text>
        </div> :
        <>
          {block_descriptive === false ? (
            <div
              className={styles.progressVertical}
              style={{ width: verticalWidth }}
            >
              <div
                style={{
                  width: `${checkvalue && checkvalue?.first_name !== '' && checkvalue?.email !== '' ? changingpercentageinmatching
                    ? changingpercentageinmatching
                    : verticalPercentage : 0
                    }%`,
                }}
                className={
                  matchingpercentage ? styles.progressmatch : styles.progress
                }
              />
              <Text
                bold
                color="black"
                className={
                  matchingpercentage ? styles.percentTextmatch : styles.percentText
                }
              >{`${checkvalue && checkvalue?.first_name !== '' && checkvalue?.email !== '' ?
                (changingpercentageinmatching
                  ? changingpercentageinmatching
                  : verticalPercentage) : 0
                }%`}</Text>
            </div>
          ) : (
            <CustomTooltip title="Upgrade or purchase an add-on to view scores.">
              <div
                className={styles.progressVertical_hide}
                style={{ width: verticalWidth, cursor: 'pointer' }}
              >
                <div
                  style={{
                    width: `${checkvalue && checkvalue?.first_name !== '' && checkvalue?.email !== '' ? (changingpercentageinmatching ? changingpercentageinmatching : 70)
                      : 70
                      }%`,
                  }}
                  className={
                    matchingpercentage ? styles.progressmatch : styles.progress
                  }
                />
                <Text
                  bold
                  color="black"
                  className={
                    matchingpercentage ? styles.percentTextmatch : styles.percentText
                  }
                >
                  {checkvalue && checkvalue?.first_name !== '' && checkvalue?.email !== '' ? '70%' : '0%'}
                </Text>
              </div>
            </CustomTooltip>
          )}
        </>
      }
    </>
  );
};

ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
