import classNames from 'classnames/bind';
import SvgRadioWithLine from '../../icons/SvgRadioWithLine';
import SvgAngle from '../../icons/SvgAngle';
import { Flex } from '../../uikit';
import Text from '../../uikit/Text/Text';
import styles from './screeningstatustab.module.css';
import { DummyinterviewData } from './mock';

const cx = classNames.bind(styles);
const Interviewquestiondummycard = () => {


    //changing level radio thumb based on value
    const handlelevelradio = (val) => {
        const value = val.toLowerCase();
        if (value === 'easy') {
            return <SvgRadioWithLine fill="#34CC65" width={16} height={16} />;
        }
        if (value === 'medium') {
            return <SvgRadioWithLine fill="#F29111" width={16} height={16} />;
        }
        if (value === 'hard') {
            return <SvgRadioWithLine fill="#ED4857" width={16} height={16} />;
        }
        return null;
    };
    return (
        <Flex style={{ overflow: 'hidden' }} height={window.innerHeight - 165}>
            {DummyinterviewData.map((interview, index) => (
                <Flex key={index} marginBottom={20}>
                    <Flex row between center style={{ backgroundColor: '#D7C7D2', borderRadius: '4px 4px 0px 0px', padding: '5px 10px' }}>
                        <Flex row>
                            <Text>{` Onsite interview / ${interview.date} / ${interview.time}`}</Text>
                        </Flex>
                    </Flex> 
                    <Flex className={styles.cardview} style={{ padding: '0px 10px' }}>
                        <Flex marginTop={5}>
                            <Text style={{ textTransform: "capitalize" }} bold>
                                General
                            </Text>
                            {interview.questions.map((question, qIndex) => ( 
                                <Flex key={qIndex}>
                                    <Flex row marginTop={5} marginBottom={5}>
                                        <Flex marginRight={7} marginTop={1}>
                                            {handlelevelradio(question.difficulty)}
                                        </Flex>
                                        <Flex>
                                            <Text color='theme'>{question.difficulty}</Text>
                                        </Flex>
                                    </Flex>
                                    <Flex row style={{ borderBottom: question.questionNumber === 2 ? '' : '1px solid #C3C3C3', paddingBottom: '10px' }} marginLeft={1}>
                                        <Flex row>
                                            <Flex>
                                                <Text>{question.questionNumber}.</Text>
                                            </Flex>
                                            <Flex row style={{ textAlign: "justify" }}>
                                                <Text>{question.questionText}
                                                    <Text
                                                        style={{ cursor: "pointer" }}>
                                                        <Text color="theme" bold style={{ marginLeft: '5px', marginRight: '5px' }}>Optimal answer</Text>
                                                        <SvgAngle
                                                            width={12}
                                                            height={12}
                                                            fill={"#581845"}
                                                            up={false} />
                                                    </Text>
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            ))}
        </Flex>
    )
}
export default Interviewquestiondummycard;