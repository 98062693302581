import * as React from 'react';
const SvgWorkflow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={25}
    fill="none"
    viewBox='0 0 27 25'
    {...props}
  >
    <path
      fill="#581845"
      d="M6.5 13.167h13.667v2.566H21.5v-3.9H14V9.167h-1.334v2.666h-7.5v3.9H6.5zM10 16.667H1.667A1.667 1.667 0 0 0 0 18.333v5A1.667 1.667 0 0 0 1.667 25H10a1.667 1.667 0 0 0 1.667-1.667v-5A1.667 1.667 0 0 0 10 16.667m-8.333 6.666v-5H10v5zM25 16.667h-8.333A1.667 1.667 0 0 0 15 18.333v5A1.667 1.667 0 0 0 16.667 25H25a1.667 1.667 0 0 0 1.667-1.667v-5A1.667 1.667 0 0 0 25 16.667m-8.333 6.666v-5H25v5zM9.167 8.333H17.5a1.667 1.667 0 0 0 1.667-1.666v-5A1.667 1.667 0 0 0 17.5 0H9.167A1.667 1.667 0 0 0 7.5 1.667v5a1.667 1.667 0 0 0 1.667 1.666m0-6.666H17.5v5H9.167z"
    />
  </svg>
);
export default SvgWorkflow;