/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
  fill: '#979797',
  width: 24,
  height: 24,
};

const SvgMailsupport = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 12.8588L11.5414 12.4002L12.9114 11.0002H9.66675V10.3335H12.9114L11.5414 8.9335L12.0001
         8.47483L14.1921 10.6668L12.0001 12.8588ZM2.74341 11.0002C2.44741 11.0002 2.19408 10.8946 1.98341
          10.6835C1.7723 10.4728 1.66675 10.2195 1.66675 9.9235V3.41016C1.66675 3.11416 1.7723 2.86083 1.98341
           2.65016C2.19408 2.43905 2.44741 2.3335 2.74341 2.3335H11.2567C11.5527 2.3335 11.8061 2.43905 12.0167
            2.65016C12.2279 2.86083 12.3334 3.11416 12.3334 3.41016V7.0155C12.2779 7.01061 12.2223
             7.00683 12.1667 7.00416C12.1112 7.0015 12.0556 7.00016 12.0001 7.00016C11.9445 7.00016 11.889
              7.00083 11.8334 7.00216C11.7779 7.0035 11.7223 7.00661 11.6667 7.0115V3.49816L6.93341
               6.9235L2.33341 3.5015V9.9235C2.33341 10.0431 2.37186 10.1413 2.44875 10.2182C2.52564 10.2951
                2.62386 10.3335 2.74341 10.3335H8.34475C8.33986 10.3891 8.33675 10.4446 8.33541 10.5002C8.33408
                 10.5557 8.33342 10.6113 8.33342 10.6668C8.33342 10.7224 8.33475 10.7779 8.33741 10.8335C8.34008
                  10.8891 8.34386 10.9446 8.34875 11.0002H2.74341ZM2.76141 3.00016L6.93341 6.11016L11.2307 3.00016H2.76141Z"
        fill="#581845"
      />
    </svg>
  );
};
SvgMailsupport.defaultProps = defaultProps;

export default SvgMailsupport;
