import axios from 'axios';
import classNames from 'classnames/bind';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import { useHistory, useLocation,useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { AppDispatch, RootState } from '../../store';
import Totalcount from '../../globulization/TotalCount';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import { getDateString, isEmpty } from '../../uikit/helper';
import Loader from '../../uikit/Loader/Loader';
import Toast from '../../uikit/Toast/Toast';
import SvgInfinity from '../../icons/SvgInfinity';
import { inviteToApplyApi, zitaMatchDownloadApi } from '../../routes/apiRoutes';
import { config, ERROR_MESSAGE } from '../constValue';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import { zitaMatchProgressMiddleWare } from '../zitamatchcandidatemodule/store/middleware/zitamatchcandidatemiddleware';
import MyDataBaseFilter from './MyDataBaseFilter'; // eslint-disable-line
import styles from './mydatabasescreen.module.css';
import { qualificationFilterHelper } from './myDataBaseScreenHelper';
import MyDataBaseSearchAction from './MyDataBaseSearchAction'; // eslint-disable-line
import MyDataBaseTabs from './MyDataBaseTabs'; // eslint-disable-line
import { SkillListEntity } from './myDataBaseTypes';
import {
  myDataBaseDataMiddleWare,
  myDataBaseInitalMiddleWare,
} from './store/middleware/mydatabasemiddleware';
import { candidate_source, experienceOption, sortOptions } from './mock';



var querystring = require('querystring');

const cx = classNames.bind(styles);

export type MyDataFormProps = {
  searchValue: string;
  locationSearch: string;
  reLocateValue: string;
    jobType: string;
  experience: SkillListEntity;
  skillValue: SkillListEntity[];
  jobTitle: string;
  applicantOnly: string;
  candidatesource: SkillListEntity;
};

const initial: MyDataFormProps = {
  searchValue: '',
  locationSearch: '',
  reLocateValue: '',
    jobType: '',
  experience: experienceOption[0],
  skillValue: [],
  jobTitle: '',
  applicantOnly: '0',
  candidatesource: candidate_source[0],
};
type ParamsType = {
  jd_id: string;
};

const MyDataBaseScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const { jd_id } = useParams<ParamsType>();
  const history = useHistory();
  const getMydataBaseTabKey: any =
    sessionStorage.getItem('getMydataBaseTabKey') === null
      ? ''
      : sessionStorage.getItem('getMydataBaseTabKey');
  const [isBachelors, setBachelors] = useState(false);
  const [isDoctorate, setDoctorate] = useState(false);
  const [isDiploma, setDiploma] = useState(false);
  const [isMasters, setMasters] = useState(false);
  const [isAny, setAny] = useState(true);
  const [isOther, setOther] = useState(false);
  const [isPage, setPage] = useState(0);
  const [tabKey, setTabKey] = useState(getMydataBaseTabKey);
  const [isInviteLoader, setInviteLoader] = useState(false);
  const [isFav, setFav] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any>([]);
  const [isDownloadLoader, setDownLoadLoader] = useState(false);
  const [isSortOptions, setSortOptions] = useState(sortOptions[0]);
  const [isSearchValue, setSearchValue] = useState<any>('');
  const [change, setchange] = useState(false);
  const [match_val, setmatch_val] = useState<any>();
  const addFavFilter = isFav ? 'add' : '';
  const [ispagecount, setpagecount] = useState<any>();
  const [isZitamatchfilter, setZitamatchfilter] = useState(false);
  const [isZitamatch, setZitamatch] = useState(false);
  const [iscandidate,setcandidate]=useState("")
  const [isMatchai,setMatchai]=useState<any>('')

  // document.title = 'All Candidates';
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const getTab: any = query.get('tab');

  useEffect(() => {
    localStorage.setItem('freeCheck', 'true');
     dispatch(myDataBaseInitalMiddleWare());
    dispatch(SubsriptionMiddleWare());
  }, []);



  const {
    initalLoader,
    candidate_available,
    job_title,
    datas,
    dataLoader,
    totalCount,
    jobId,
    is_plan,
    blocked_resume,
    database,
    candidate_location,
    candidate_name
  } = useSelector(
    ({
      myDataBaseDataReducers,
      myDataBaseInitalReducers,
      permissionReducers,
      SubscriptionReducers,
    }: RootState) => {
      return {
        initalLoader: myDataBaseInitalReducers.isLoading,
        candidate_available: myDataBaseInitalReducers.candidate_available,
        candidate_location: myDataBaseDataReducers.candidate_location,
        job_title: myDataBaseInitalReducers.job_title,
        datas: myDataBaseDataReducers.data,
        blocked_resume: myDataBaseDataReducers.blocked_resume,
        dataLoader: myDataBaseDataReducers.isLoading,
        totalCount: myDataBaseDataReducers.total_count,
        jobId: myDataBaseDataReducers.jd,
        is_plan: permissionReducers.is_plan,
        database: SubscriptionReducers.database,
        candidate_name: myDataBaseDataReducers.candidate_name
      };
    },
  );

  useEffect(() => {
    setpagecount(database);
  }, [database]);

  const filteredArray = datas.filter(
    (item) => !blocked_resume.includes(item.id),
  );
  useEffect(() => {
    if (!isEmpty(getTab)) {
      setTabKey(getTab);
      if (query.has('tab')) {
        query.delete('tab');
        history.replace({
          search: query.toString(),
        });
      }
    }
  }, []);
  useEffect(() => {
    if (!is_plan) {
      sessionStorage.setItem('MyAccountDropdown','1')
      history.push('/account_setting/subscription');
    }
  });

  const cleartick = () => {
    setIsCheck([]);
  };

  const formik = useFormik({
    initialValues: initial,
    onSubmit: () => { },
  });

  const handleBachelor = () => {
    setBachelors(!isBachelors);
    setAny(false);
  };

  const handleDoctorate = () => {
    setDoctorate(!isDoctorate);
    setAny(false);
  };
  const handleDiploma = () => {
    setDiploma(!isDiploma);
    setAny(false);
  };

  const handleMaster = () => {
    setMasters(!isMasters);
    setAny(false);
  };

  const handleOther = () => {
    setOther(!isOther);
    setAny(false);
  };

  const handleAny = () => {
    setAny(!isAny);
    setBachelors(false);
    setDoctorate(false);
    setDiploma(false);
    setMasters(false);
    setOther(false);
  };

  const qualificationOption = [
    {
      value: 'Bachelors',
      label: 'Bachelors',
      checked: isBachelors,
      onChange: handleBachelor,
      width: 110,
      padding: 10,
    },
    {
      value: 'Masters',
      label: 'Masters',
      checked: isMasters,
      onChange: handleMaster,
      width: 80,
      margin: 8,
    },
    {
      value: 'Doctorate',
      label: 'Doctorate',
      checked: isDoctorate,
      onChange: handleDoctorate,
      width: 110,
    },
    {
      value: 'Diploma',
      label: 'Diploma',
      checked: isDiploma,
      onChange: handleDiploma,
      width: 80,
    },
    {
      value: 'Others',
      label: 'Others',
      checked: isOther,
      onChange: handleOther,
      width: 110,
    },
    {
      value: 'Any',
      label: 'any',
      width: 80,
      checked: isAny,
      onChange: handleAny,
    },
  ];
  useEffect(() => {
    if (
      isBachelors === false &&
      isDoctorate === false &&
      isMasters === false &&
      isOther === false &&
      isDiploma === false
    ) {
      setAny(true);
    }
  }, [isBachelors, isDoctorate, isDiploma, isMasters, isOther]);

  const qaValue = qualificationFilterHelper(
    isAny,
    isBachelors,
    isDoctorate,
    isDiploma,
    isMasters,
    isOther,
  );

  const skillsOptionsList =
    formik.values.skillValue &&
    formik.values.skillValue.map((optionList: { value: string }) => {
      return optionList.value;
    });

  const candiList = filteredArray.map((list) => {
    return list.id.toString();
  });

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(candiList);
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleCheckBoxClick = (e: {
    target: { id: string; checked: boolean };
  }) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id));
    }
  };

  useEffect(() => {
    if (isCheck && isCheck.length !== filteredArray?.length) {
      setIsCheckAll(false);
    }
  }, [isCheck]);

  if (
    isCheck.length === filteredArray.length &&
    isCheckAll === false &&
    filteredArray.length !== 0
  ) {
    setIsCheckAll(true);
  }

  useEffect(()=>{ 
    if (jd_id) {
      const storedValue = localStorage.getItem('notification');

      if (storedValue) {
        formik.setFieldValue('jobTitle', jd_id);
        dispatch(zitaMatchProgressMiddleWare({jd_id:jd_id}))
        localStorage.removeItem('notification');
      }else{
      formik.setFieldValue('jobTitle', jd_id);
      setZitamatch(true);
      setZitamatchfilter(true); 
     dispatch(zitaMatchProgressMiddleWare({jd_id:jd_id}))
    }
    }
  },[])

  useEffect(() => {
    if (jd_id) {
      setIsCheck([]);
    if (change === false) {
      
      setInviteLoader(true);
      // checking the matching filter
      let matchingValue;
      if (match_val === 'AI matching') {
        matchingValue = true;
        setMatchai('true')
      } else if (match_val === 'Basic matching') {
        matchingValue = false;
        setMatchai('false')
      } else {
        matchingValue = '';
        setMatchai('')
      }
      
     if(formik.values.jobTitle!=="")
      dispatch(

        myDataBaseDataMiddleWare({
          jobTitle: formik.values.jobTitle,
          fav: addFavFilter,
          experience: formik.values.experience.value,
          educationLevel: qaValue,
          typeofJob: formik.values.jobType,
          location: formik.values.locationSearch,
          skill_match: skillsOptionsList,
         relocate: formik.values.reLocateValue,
         candidate: formik.values.searchValue,
          userType: tabKey,
          sort: isSortOptions.value,
          page: isPage + 1,
          pagecount: ispagecount,
          applicant_only: formik.values.applicantOnly,
          matching: matchingValue,
          zitamatchfilter:isZitamatchfilter ? "1" : "0",
          
        }),
      ).then((res) => {
        dispatch(SubsriptionMiddleWare());
        setInviteLoader(false);
      });
    }
    }
    else{
    setIsCheck([]);
    if (change === false) {
      
      setInviteLoader(true);
      // checking the matching filter
      let matchingValue;
      if (match_val === 'AI matching') {
        matchingValue = true;
        setMatchai('true')
      } else if (match_val === 'Basic matching') {
        matchingValue = false;
        setMatchai('false')
      } else {
        matchingValue = '';
        setMatchai('')
      }

      dispatch(

        myDataBaseDataMiddleWare({
          jobTitle: formik.values.jobTitle,
          fav: addFavFilter,
          experience: formik.values.experience.value,
          educationLevel: qaValue,
          typeofJob: formik.values.jobType,
          location: formik.values.locationSearch,
          skill_match: skillsOptionsList,
         relocate: formik.values.reLocateValue,
         candidate: formik.values.searchValue,
          userType: tabKey,
          sort: isSortOptions.value,
          page: isPage + 1,
          pagecount: ispagecount,
          applicant_only: formik.values.applicantOnly,
          matching: matchingValue,
          zitamatchfilter:isZitamatchfilter ? "1" : "0",
          
        }),
      ).then((res) => {
        dispatch(SubsriptionMiddleWare());
        setInviteLoader(false);
      });
    }
  }
  }, [
    formik.values,
    isAny,
    isBachelors,
    isDoctorate,
    isMasters,
    isDiploma,
    isOther,
    tabKey,
    isFav,
    isSortOptions,
    isPage,
    change,
    match_val,
    ispagecount,
    isZitamatchfilter
  ]);

  // filter refresh function
  const hanldeRefresh = () => {
    setAny(true);
    setBachelors(false);
    setDoctorate(false);
    setDiploma(false);
    setMasters(false);
    setOther(false);
    // formik.resetForm();
    formik.setValues(prevValues => ({
      ...prevValues,
      searchValue: '',
      locationSearch: '',
      reLocateValue: '',
      jobType: '',
      experience: experienceOption[0],
      skillValue: [],
      applicantOnly: '0',
      candidatesource: candidate_source[0],
      // Keep jobTitle unchanged
      jobTitle: prevValues.jobTitle
     
    }));
    setSearchValue('');
  };

  // To Clear Search Bar
  const handleSearchClose = () => {
    setSearchValue('');
    formik.setFieldValue('searchValue', '');
    dispatch(
      myDataBaseDataMiddleWare({
        jobTitle: formik.values.jobTitle,
        fav: addFavFilter,
        experience: formik.values.experience.value,
        educationLevel: qaValue,
        typeofJob: formik.values.jobType,
        location: formik.values.locationSearch,
        skill_match: skillsOptionsList,
        relocate: formik.values.reLocateValue,
        candidate: '',
        userType: tabKey,
        sort: isSortOptions.value,
        page: isPage + 1,
        pagecount: ispagecount,
        matching:isMatchai,
        applicant_only: formik.values.applicantOnly,
        zitamatchfilter:isZitamatchfilter ? "1" : "0",
      }),
    );
  };

  // invite function
  const hanldeInvite = (can_id: number) => {
    setInviteLoader(true);
    const data = querystring.stringify({
      jd_id: jobId,
      candi_id: can_id,
    });
    axios
      .post(inviteToApplyApi, data, config)
      .then(() => {
        dispatch(
          myDataBaseDataMiddleWare({
            jobTitle: formik.values.jobTitle,
            fav: addFavFilter,
            experience: formik.values.experience.value,
            educationLevel: qaValue,
            typeofJob: formik.values.jobType,
            location: formik.values.locationSearch,
            skill_match: skillsOptionsList,
            relocate: formik.values.reLocateValue,
            candidate: formik.values.searchValue,
            userType: tabKey,
            sort: isSortOptions.value,
            page: isPage + 1,
            pagecount: ispagecount,
            matching:isMatchai,
            applicant_only: formik.values.applicantOnly,
            zitamatchfilter:isZitamatchfilter ? "1" : "0",
          }),
        ).then(() => {
          setInviteLoader(false);
          Toast('Candidate invited successfully.');
        });
      })
      .catch(() => {
        setInviteLoader(false);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  // fav filter function
  const handleFav = () => {
    setFav(!isFav);
  };
  useEffect(() => { }, []);

  // resume download function
  const hanldeDownload = () => {
    if (isCheck.length !== 0) {
      setDownLoadLoader(true);
      const data = querystring.stringify({
        jd: jobId,
        candi_id: isCheck.toString(),
        download: 'download',
      });

      axios
        .post(zitaMatchDownloadApi, data, config)
        .then((response) => {
          setDownLoadLoader(false);
          if (response.data.file_path) {
            saveAs(
              window.location.protocol + '//' + response.data.file_path,
              `Candidates_Profiles_${getDateString(new Date(), 'll')}.zip`,
            );
          }
          Toast('Resume downloaded successfully.');
        })
        .catch(() => {
          setDownLoadLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
  };

  // they set page number 0 when change tab
  useEffect(() => {
    setPage(0);
  }, [tabKey]);

  const refreshdata = () => {
    dispatch(
      myDataBaseDataMiddleWare({
        jobTitle: formik.values.jobTitle,
        fav: addFavFilter,
        experience: formik.values.experience.value,
        educationLevel: qaValue,
        typeofJob: formik.values.jobType,
        location: formik.values.locationSearch,
        skill_match: skillsOptionsList,
        relocate: formik.values.reLocateValue,
        candidate: formik.values.searchValue,
        userType: tabKey,
        sort: isSortOptions.value,
        page: isPage + 1,
        pagecount: ispagecount,
        applicant_only: formik.values.applicantOnly,
        zitamatchfilter:isZitamatchfilter ? "1" : "0",
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>All Candidates</title>
      </Helmet>
      <Flex row className={styles.ribbon} between>
        <Flex className={styles.titleContainer}>
          <Text size={16} bold color="theme">
            All Candidates
          </Text>
        </Flex>
        <Flex>
          <div className={styles.triangle}></div>
        </Flex>
      </Flex>
      <Flex row>
        {initalLoader && <Loader />}
        {isInviteLoader && <Loader />}
        {isDownloadLoader && <Loader />}

        <div className={cx('tabsContainer')}>
          <MyDataBaseSearchAction
            jobTitle={job_title}
            formik={formik}
            setSearchValue={setSearchValue}
            isSearchValue={isSearchValue}
            handleSearchClose={handleSearchClose}
            refreshdata={refreshdata}
            candidate_name={candidate_name}
          />
          <div className={cx('filterOverAll')} style={{ paddingRight: '10px' }}>
            <MyDataBaseFilter
              setchange={setchange}
              formik={formik}
              filterFormik={formik}
              qualificationOption={qualificationOption}
              hanldeRefresh={hanldeRefresh}
              qaValue={qaValue}
              skillsOptionsList={skillsOptionsList}
              tabKey={tabKey}
              setTabKey={setTabKey}
              isPage={isPage}
              addFavFilter={addFavFilter}
              isSortOptions={isSortOptions}
              setSortOptions={setSortOptions}
              setmatch_val={setmatch_val}
              setcandidate={setcandidate}
              candidate_location={candidate_location}
              setZitamatchfilter={setZitamatchfilter}
              isZitamatchfilter={isZitamatchfilter}
              isZitamatch={isZitamatch}
              setZitamatch={setZitamatch}
              iscandidate={iscandidate}
            />
          </div>
          <div className={styles.tabsStyle}>
            <MyDataBaseTabs
              cleartick={cleartick}
              totalCount={totalCount}
              data={filteredArray}
              tabKey={tabKey}
              setTabKey={setTabKey}
              filterFormik={formik}
              qaValue={qaValue}
              skillsOptionsList={skillsOptionsList}
              jobId={jobId}
              hanldeInvite={hanldeInvite}
              isFav={isFav}
              handleFav={handleFav}
              handleSelectAll={handleSelectAll}
              isCheckAll={isCheckAll}
              isCheck={isCheck}
              handleCheckBoxClick={handleCheckBoxClick}
              hanldeDownload={hanldeDownload}
              setSortOptions={setSortOptions}
              isSortOptions={isSortOptions}
              isPage={isPage}
              setPage={setPage}
              addFavFilter={addFavFilter}
              pagination_count={database}
              setpagecount={setpagecount}
              iscandidate={iscandidate}
              isZitamatchfilter={isZitamatchfilter}
              setInviteLoader={setInviteLoader}
              ispagecount={ispagecount}
              isMatchai={isMatchai}
            />
          </div>
        </div>
      </Flex>
    </>
  );
};

export default MyDataBaseScreen;
