import SvgCheckedCircle from '../../icons/SvgCheckedCircle';
import { Flex, InputCheckBox } from '../../uikit';
import style from './styles/SelectTeamMemberIcon.module.css';
import { Colors } from './types';

interface Props {
  checked: boolean;
  label: string;
  onClick: () => void;
  color: Colors;
  disabled?: boolean;
  loader?: boolean;
  title?:any;
}

const SelectTeamMemberCheckBox = ({
  checked,
  label,
  onClick,
  color,
  disabled = false,
  loader,
  title
}: Props) => {
  return (
    <>
      <Flex className={style.checkboxContainer} row center marginTop={2}> 
        <Flex className={style.checkbox} style={{}} title={title}>
          <InputCheckBox
            checked={checked}
            disabled={loader ? true : disabled}
            onChange={() => {
              if (!disabled) onClick();
            }}
          />
        </Flex>

        <Flex
          className={style.dot}
          style={{ backgroundColor: color?.borderColor }}
        ></Flex>
        <Flex className={style.label} >{label}</Flex>
      </Flex>
    </>
  );
};

export default SelectTeamMemberCheckBox;
