export const monthOptions = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

export const genderOptions = [
  { value: '1', label: 'Male' },
  { value: '2', label: 'Female' },
  { value: '3', label: 'Others' },
  { value: '4', label: 'Do not prefer to answer' },
];

export const currencyOptions = [
  { value: 'INR', label: 'INR' },
  { value: 'USD', label: 'USD' },
  { value: 'CAD ', label: 'CAD ' },
];

export const expYearOptions = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
];

export const availabilityOptions = [
  { value: '1', label: 'Immediate' },
  { value: '2', label: 'In a Month' },
  { value: '3', label: 'Within 3 Months' },
  { value: '4', label: 'More than 3 Months' },
  { value: '5', label: 'Unavailable' },
];

export const birthYearOptions = [
  { value: '1970', label: '1970' },
  { value: '1971', label: '1971' },
  { value: '1972', label: '1972' },
  { value: '1973', label: '1973' },
  { value: '1974', label: '1974' },
  { value: '1975', label: '1975' },
  { value: '1976', label: '1976' },
  { value: '1977', label: '1977' },
  { value: '1978', label: '1978' },
  { value: '1979', label: '1979' },
  { value: '1980', label: '1980' },
  { value: '1981', label: '1981' },
  { value: '1982', label: '1982' },
  { value: '1983', label: '1983' },
  { value: '1984', label: '1984' },
  { value: '1985', label: '1985' },
  { value: '1986', label: '1986' },
  { value: '1987', label: '1987' },
  { value: '1988', label: '1988' },
  { value: '1989', label: '1989' },
  { value: '1990', label: '1990' },
  { value: '1991', label: '1991' },
  { value: '1992', label: '1992' },
  { value: '1993', label: '1993' },
  { value: '1994', label: '1994' },
  { value: '1995', label: '1995' },
  { value: '1996', label: '1996' },
  { value: '1997', label: '1997' },
  { value: '1998', label: '1998' },
  { value: '1999', label: '1999' },
  { value: '2000', label: '2000' },
  { value: '2001', label: '2001' },
  { value: '2002', label: '2002' },
  { value: '2003', label: '2003' },
];
