import { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import PDF from 'react-pdf-js-infinite';
import FileViewer from 'react-file-viewer';
import { DocumentViewer } from 'react-documents';
import Flex from '../../uikit/Flex/Flex';
import SvgDownload from '../../icons/SvgDownload';
import Text from '../../uikit/Text/Text';
import Button from '../../uikit/Button/Button';
import Tab from '../../uikit/Tabs/Tab';
import Collapse from '../../uikit/Collapse/Collapse';
import Svgmaximize from '../../icons/Svgmaximize';
import Svgminimize from '../../icons/Svgminimize';
import SvgAngle from '../../icons/SvgAngle';
import { isEmpty, notSpecified } from '../../uikit/helper';
import { RootState } from '../../store';
import styles from './resumecover.module.css';

const cx = classNames.bind(styles);

const ResumeCoverTab = () => {
  const [isOpen, setOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [zoomLeveltxt, setZoomLeveltxt] = useState<number>(0.63);
  const [base64, setBase64] = useState<any>('');

  const [text, settext] = useState('');
  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    setZoomLeveltxt((prevZoomLevel) => prevZoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(0.7, prevZoomLevel - 0.1));
    setZoomLeveltxt((prevZoomLevel) => Math.max(0.3, prevZoomLevel - 0.1));
  };

  const { candidate_details, cover_letter } = useSelector(
    ({ applicantProfileInitalReducers }: RootState) => {
      return {
        candidate_details: applicantProfileInitalReducers.candidate_details,
        cover_letter:
          applicantProfileInitalReducers.cover_letter?.length !== 0
            ? applicantProfileInitalReducers.cover_letter
            : [
                {
                  id: 0,
                  candidate_id_id: 0,
                  jd_id_id: 0,
                  cover_letter: '',
                  source: '',
                  created_on: '',
                },
              ],
      };
    },
  );

  const file = useMemo(
    () => process.env.REACT_APP_HOME_URL + 'media/' + candidate_details[0].file,
    [],
  );
  var lastStr = candidate_details[0]?.file?.lastIndexOf('.');
  var filename = candidate_details[0]?.file?.substring(lastStr + 1);
  const update = () => {
    if (filename === 'txt') {
      fetch(file)
        .then((response) => response.text())
        .then((data) => settext(data));
    }
  };
  const coverLetter =
    notSpecified(cover_letter && cover_letter[0].cover_letter) ===
    'Not Specified'
      ? true
      : false;

  const docs = [{ uri: file }];
  const zoomStyle = {
    transform: `scale(${zoomLevel})`,
    transformOrigin: '0 0',
    transition: 'transform 0.25s ease-in-out',
  };
  const zoomStyletxt = {
    transform: `scale(${zoomLeveltxt})`,
    transformOrigin: '0 0',
    transition: 'transform 0.25s ease-in-out',
  };
  const downloadFile = () => {
    const fileUrl = file; // Replace with your desired file URL

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = candidate_details[0].file; // Provide a default file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  };

  useEffect(() => {
    const convertToBase64 = async () => {
      try {
        const response = await fetch(file);
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onloadend = () => {
          // Set the Base64 string (reader.result includes the data type)
          setBase64(reader.result);
        };

        // Read the blob as a Data URL
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error converting PDF to Base64:', error);
      }
    };

    convertToBase64();
  }, [file]);

  const fileType = file?.split('/');
  const fileTypeCondition = fileType[5]?.includes('.pdf');


  return (
    <>
      {candidate_details[0].file === null ? (
        <Flex center middle height={window.innerHeight}>
          <Text color="gray">Not Specified </Text>
        </Flex>
      ) : (
        <Flex
          columnFlex
          className={styles.overAllresume}
          height={window.innerHeight - 142}
        >
          <div
            style={{
              textAlign: 'right',
              backgroundColor: '#EEE8EC',
              position: 'sticky',
              top: '0',
              zIndex: 5, // Adjust top position as needed
            }}
          >
            <Flex style={{ marginTop: '5px', marginBottom: '5px' }} row end>
              <Flex
                onClick={downloadFile}
                title="Download Resume"
                style={{ marginRight: '13px', cursor: 'pointer' }}
              >
                <SvgDownload width={16} height={16} />
              </Flex>
              {/* <Flex onClick={handleZoomIn} title='Maximize' style={{ marginRight: '13px', cursor: 'pointer' }} >
                <Svgmaximize width={16} height={16} />
              </Flex>
              <Flex onClick={handleZoomOut} title='Minimize' style={{ marginRight: '13px', cursor: 'pointer' }}>
                <Svgminimize width={16} height={16} />
              </Flex> */}
            </Flex>
            {/* {update()} */}
          </div>
          {file !== '' && (
            <Flex
              style={{
                height: window.innerHeight - 172,
                borderRadius: '5px',
                border: '1px solid #dfdfdf',
                marginTop: '5px',
              }}
            >
              <div
                style={{
                  transform: 'scale(1)',
                  transformOrigin: '0px 0px',
                  transition: 'transform 0.25s ease-in-out 0s',
                }}
              >
                <div style={{ width: '100%', height: window.innerHeight - 172 }} className={styles.resumeview}>
                  {/* {fileTypeCondition === true ? (
                    <iframe
                      src={base64}
                      width="100%"
                      height={window.innerHeight - 68} // Use 100% height to fill the container
                      style={{ border: 'none' }}
                      title="Viewer"
                    />
                  ) : (
                    <>
                      {fileTypeCondition === false && (
                        <embed
                          title="Viewer"
                          src={
                            'https://docs.google.com/viewer?url=' +
                            file +
                            '&embedded=true&chrome=false'
                          }
                          width="600"
                          height={window.innerHeight - 174}
                          style={{
                            overflow: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: 'rgba(26, 26, 26, 0.3) white',
                            borderRadius: '4px', // Add border: 'none' to remove iframe border
                          }}
                        />
                      )}
                    </>
                  )} */}
                  <DocumentViewer
                    url={file}
                    viewerUrl={`https://docs.google.com/viewer?url=${file}&embedded=true&chrome=false`}
                    overrideLocalhost="https://react-doc-viewer.firebaseapp.com/"
                  />
                </div>
              </div>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

export default ResumeCoverTab;
