import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store';
import Flex from '../../../uikit/Flex/Flex';
import Loader from '../../../uikit/Loader/Loader';
import Pangination from '../../../uikit/Pagination/Pangination';
import { SubsriptionMiddleWare } from '../../navbar/empnavbar/store/navbarmiddleware';
import { option } from '../../myjobposting/mock';
import { isEmpty } from '../../../uikit/helper';
import CareerNavBar from './CareerNavBar';
import CareerViewBanner from './CareerViewBanner';
import { careerViewPageMiddleWare } from './store/middleware/buildyourcareerpagemiddleware';
import styles from './careerviewscreen.module.css';
import CareerViewFooter from './CareerViewFooter';

type ParamsType = {
  pageUrl: string;
};
const CareerViewScreen = () => {

  const dispatch: AppDispatch = useDispatch();
  const { pageUrl } = useParams<ParamsType>();
  const [isPage, setPage] = useState(0);
  const [componentHeight, setComponentHeight] = useState(window.innerHeight - 71);
  const [ispagecount, setpagecount] = useState<any>(null);
  const [isLoader, setLoader] = useState<any>(false);
  const [isCount, setCount] = useState<any>(0);
  const [islength, setlength] = useState<any>();

  document.title = 'Careers';

  useEffect(() => { 
    dispatch(
      careerViewPageMiddleWare({
        pageUrl,
        page: isPage + 1,
        user_id: getLoginUserId ,
        pagecount: 0
      }),
    )
    localStorage.setItem('freeCheck', 'true');
  }, []);
  const getLoginUserId: any =
    localStorage.getItem('loginUserId') !== null
      ? localStorage.getItem('loginUserId')
      : '0';



  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 71);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {
    isLoading,
    career_page_setting,
    company_detail,
    jd_form,
    total,
    jd_active,
    login_user,
    user_detail,
    image,
    careers_page,
    count,
    filter_count,
    career_page_url,
    job_title,
    job_location
  } = useSelector(({ careerViewPageReducers, permissionReducers }: RootState) => {
    return {
      isLoading: careerViewPageReducers.isLoading,
      career_page_setting: careerViewPageReducers.career_page_setting,
      company_detail: careerViewPageReducers.company_detail,
      jd_form: careerViewPageReducers.jd_form,
      total: careerViewPageReducers.total,
      jd_active: careerViewPageReducers.jd_active,
      login_user: careerViewPageReducers.login_user,
      user_detail: careerViewPageReducers.user_detail,
      image: careerViewPageReducers.image,
      count: careerViewPageReducers.count,
      careers_page: careerViewPageReducers.careers_page,
      filter_count: careerViewPageReducers.filter_count,
      career_page_url: permissionReducers.carrer_page,
      job_title: careerViewPageReducers.job_title,
      job_location: careerViewPageReducers.job_location
    };
  });
  useEffect(() => {
    setpagecount(careers_page)
  }, [careers_page])

  useEffect(() => {
    setLoader(true)
    if (ispagecount !== null) {
      dispatch(
        careerViewPageMiddleWare({
          pageUrl,
          page: isPage + 1,
          user_id: getLoginUserId,
          pagecount: ispagecount
        }),
      ).then((res) => {  
        setLoader(false)
      })
    }
  }, [isPage, ispagecount]);

  const usersPerPage = careers_page;
  const pageCount = Math.ceil(filter_count / usersPerPage);
  const handleSetPagination = (a: number) => {
    setPage(a);
  };

  // formik submit
  const handleSubmit = (values: any) => {
    setLoader(true)
    dispatch(
      careerViewPageMiddleWare({
        pageUrl,
        job_title: values.job_title,
        job_location: values.job_location,
        user_id: getLoginUserId,
        pagecount: ispagecount
      }),
    ).then((res) => {  
      setLoader(false)
    })
  };
  const formik = useFormik({
    initialValues: { job_title: '', job_location: '' },
    onSubmit: handleSubmit,
  });

  // custom styles for page selection text box and dropdown.
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#581845' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
      justifyContent: "center",
      '&:hover': {
        backgroundColor: state.isSelected ? 'none' : '#D7C7D2',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #581845' : '1px solid #d7c7d2',
      fontSize: '12px',
      minHeight: '16px',
      lineHeight: '16px',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '1px solid #581845',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      height: '16px',
      lineHeight: '16px',
      textAlign: 'center',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      margin: '5px 0',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform 0.3s ease',
      height: '16px',
      marginTop: '-20px'
    }),
    menu: (provided) => ({
      ...provided,
      top: 'auto',
      bottom: 22,
    }),
  };

  if (isLoading || isLoader || ispagecount === null) {
    return <Loader />;
  }
  return (
    <>{ispagecount !== null && !isLoader && !isLoading &&
      <Flex columnFlex>
        <CareerNavBar
          career_page_setting={career_page_setting}
          company_detail={company_detail}
          loginUser={login_user ? false : true}
          fName={user_detail && user_detail.first_name}
          lName={user_detail && user_detail.last_name}
          image={image}
        />
        <div
          style={{
            height: componentHeight,
            overflowY: 'scroll',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Flex>
            <CareerViewBanner
              career_page_setting={career_page_setting}
              jd_form={jd_form}
              total={total}
              formik={formik}
              jd_active={jd_active}
              setCount={setCount}
              job_title={job_title}
              job_location={job_location}
            />
            {isCount !== 0 &&
              <Flex row center between flex={12}>
                <Flex flex={4}></Flex>
                <Flex middle center flex={4}>
                  <Pangination
                    maxPages={pageCount - 1}
                    currentPage={isPage}
                    setCurrentPage={handleSetPagination}
                  />
                </Flex>
                <Flex row center end flex={4} marginRight={30}>
                  <Flex style={{ marginRight: '10px', fontSize: '13px' }}>Jobs per page :</Flex>

                  <Select
                    options={option}
                    value={option.find((e) => e.value === ispagecount)}
                    onChange={(e) => {
                      setpagecount(e.value);
                    }}
                    menuPosition="fixed"
                    styles={customStyles}
                  />
                </Flex>
              </Flex>}
          </Flex>

          <CareerViewFooter
            career_page_setting={career_page_setting}
            company_detail={company_detail}
          />

        </div>
      </Flex>
    }</>
  );
};

export default CareerViewScreen;
