import * as React from 'react';
const SvgAutomate = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M7.544 1.8h1.91c.132 0 .238.107.238.239 0 2.17 2.337 3.527 4.206 2.44a.236.236 0 0 1 .323.088l.957 1.666a.24.24 0 0 1-.087.325c-1.869 1.086-1.869 3.798 0 4.884a.24.24 0 0 1 .087.325l-.957 1.666a.237.237 0 0 1-.323.087c-1.868-1.086-4.205.272-4.205 2.44a.24.24 0 0 1-.237.239H7.545a.237.237 0 0 1-.239-.238c0-2.17-2.336-3.527-4.205-2.44a.237.237 0 0 1-.325-.088l-.955-1.666a.24.24 0 0 1 .086-.325c1.87-1.086 1.87-3.798 0-4.884a.24.24 0 0 1-.087-.325l.956-1.666a.24.24 0 0 1 .325-.087c1.869 1.086 4.205-.272 4.205-2.44 0-.132.107-.24.238-.24m-2.028.239C5.516.912 6.423 0 7.544 0h1.91c1.12 0 2.028.912 2.028 2.039a1.02 1.02 0 0 0 .508.881 1.01 1.01 0 0 0 1.012 0 2.02 2.02 0 0 1 2.77.747l.956 1.666c.56.974.227 2.221-.742 2.784a1.02 1.02 0 0 0-.507.883 1.02 1.02 0 0 0 .507.883 2.05 2.05 0 0 1 .742 2.784l-.957 1.666a2.018 2.018 0 0 1-2.769.746 1.01 1.01 0 0 0-1.383.373c-.09.155-.137.33-.137.51A2.033 2.033 0 0 1 9.454 18H7.545a2.033 2.033 0 0 1-2.028-2.039 1.02 1.02 0 0 0-.507-.881 1.01 1.01 0 0 0-1.013 0 2.02 2.02 0 0 1-2.769-.747l-.957-1.666a2.05 2.05 0 0 1 .742-2.784A1.02 1.02 0 0 0 1.52 9a1.02 1.02 0 0 0-.507-.883 2.049 2.049 0 0 1-.742-2.784l.956-1.666a2.018 2.018 0 0 1 2.769-.746 1.01 1.01 0 0 0 1.383-.372c.09-.155.137-.33.137-.509zm5.967 8A1.2 1.2 0 0 0 12.08 9a1.2 1.2 0 0 0-.597-1.04l-3.58-2.078a1.19 1.19 0 0 0-1.63.44c-.105.182-.16.389-.16.6v4.156a1.2 1.2 0 0 0 .597 1.04 1.19 1.19 0 0 0 1.193 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAutomate;