
const defaultProps = {
    fill: '#979797',
    width: 18,
    height: 20,
  };
  
  const SvgApplicantprofile = ({ width, height, fill }: typeof defaultProps) => {
    return (
       
       <svg width={width} height={height} viewBox="0 -3.5 21 21"
        fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M6 4.3155H9.66667L6 0.92475V4.3155ZM1.33333 0H6.66667L10.6667 3.699V11.097C10.6667 11.424 10.5262 11.7376 10.2761 11.9689C10.0261 12.2001 9.68696 12.33 9.33333 12.33H1.33333C0.979711 12.33 0.640573 12.2001 0.390524 11.9689C0.140476 11.7376 0 11.424 0 11.097V1.233C0 0.548685 0.593333 0 1.33333 0ZM6.66667 11.097V10.4805C6.66667 9.66055 4.88667 9.2475 4 9.2475C3.11333 9.2475 1.33333 9.66055 1.33333 10.4805V11.097H6.66667ZM4 6.165C3.64638 6.165 3.30724 6.29491 3.05719 6.52614C2.80714 6.75737 2.66667 7.07099 2.66667 7.398C2.66667 7.72501 2.80714 8.03863 3.05719 8.26986C3.30724 8.50109 3.64638 8.631 4 8.631C4.35362 8.631 4.69276 8.50109 4.94281 8.26986C5.19286 8.03863 5.33333 7.72501 5.33333 7.398C5.33333 7.07099 5.19286 6.75737 4.94281 6.52614C4.69276 6.29491 4.35362 6.165 4 6.165Z" fill="#581845"/>
</svg>
    );
  };
  SvgApplicantprofile.defaultProps = defaultProps;
  
  export default SvgApplicantprofile;