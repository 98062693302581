import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  CHATBOT_DATA,
  CHATBOT_FEEDBACK,
  CHATBOT_TIMER,
} from '../../../../actions/actions';
import {
  ChatbotfeedbackApi,
  ChatbotgetApi,
  ChatbotTimerApi,
} from '../../../../routes/apiRoutes';
import { Chatbotthumb, timer } from '../../ChatbotTypes';
const headers = new Headers();

export const chatbotcheckmiddleware = createAsyncThunk(
  CHATBOT_DATA,
  async ({ formData }: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(ChatbotgetApi, formData);
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);

export const chatbotfeedbackmiddleware = createAsyncThunk(
  CHATBOT_FEEDBACK,
  async ({ formData }: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(ChatbotfeedbackApi, formData);
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);

export const chatbotdataget = createAsyncThunk(
  CHATBOT_DATA,
  async (
    { thumb, thread, chat_id, chatclose }: Chatbotthumb,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await axios.get(ChatbotgetApi, {
        params: { thumb, thread, chat_id, chatclose },
      });
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);

export const chatbottimermiddleware = createAsyncThunk(
  CHATBOT_TIMER,
  async ({ count }: timer, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(ChatbotTimerApi, {
        params: { count },
      });
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);

// export const chatbotfeedbackmiddleware = createAsyncThunk(
//   ChatbotfeedbackApi,
//   async ({ feedback_data }: ChatbotFeedbackdata, { rejectWithValue }) => {
//     try {
//       const data = await fetch(ChatbotgetApi, {
//         method: 'POST',
//         headers,
//         body: feedback_data,
//       });
//       return await data.json();
//     } catch (error) {
//       const typedError = error as Error;
//       return rejectWithValue(typedError);
//     }
//   },
// );
