import React, { CSSProperties, Fragment, ReactElement, useEffect, useRef, useState } from 'react';
import TooltipLite from 'react-tooltip-lite';

type Props = {
    children?: ReactElement<any, any> | ReactElement<any, any>[];
    title?: React.ReactNode;
    style?: CSSProperties;
    tooltip_distance?: number;
    direction_tooltip?: string;
}

const CustomTooltip = ({ children, title, style, tooltip_distance, direction_tooltip }: Props) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [tooltipKey, setTooltipKey] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    // handle function for when the cursor enter into the children they set as true.
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    // handle function for when the cursor leave from the children they set as false.
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    
    // applying delay time for tooltip opening.
    useEffect(() => { 
        let timeout: NodeJS.Timeout; 
        if (isHovered) { 
            // Set a timeout to open the tooltip after the delay
            timeout = setTimeout(() => { 
                setIsTooltipOpen(true);
            }, 1000); // 1 seconds delay
        } else { 
            // Clear the timeout if the cursor leaves the component
            clearTimeout(timeout);
            // Close the tooltip immediately
            setIsTooltipOpen(false);
        }

        // Cleanup function to clear the timeout when the component unmounts or is updated
        return () => clearTimeout(timeout);
    }, [isHovered]);

    // Watch for changes in the title prop and update tooltip visibility accordingly
    useEffect(() => {
        setTooltipKey(prevKey => prevKey + 1);
    }, [title]);

    return (
        <>
            <TooltipLite
                key={tooltipKey}
                isOpen={isTooltipOpen}
                content={title}
                distance={tooltip_distance !== undefined ? tooltip_distance : 10}
                direction={direction_tooltip ? direction_tooltip : "down"}
                background={'#757274'}
                arrow={true}
                arrowSize={7}
                styles={style}
                zIndex={9999999999}
            >
                {React.Children.map(children, child => {
                    return (
                        <React.Fragment>
                            {/* Attach event handlers to each child */}
                            {React.cloneElement(child, {
                                onMouseEnter: () => {
                                    handleMouseEnter();
                                },
                                onMouseLeave: () => {
                                    handleMouseLeave();
                                }
                            })}
                        </React.Fragment>
                    );
                })}

            </TooltipLite>
        </>
    );
}

export default CustomTooltip;
