// import * as React from 'react';
const defaultProps = {
    fill: '',
    width: 22,
    height: 22,
  };
  const Svgcalenderscheduler = ({ width, height, fill }: typeof defaultProps) => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.8 2.09998H3.2C1.98497 2.09998 1 3.08495 1 4.29998V9.79997C1 11.015 1.98497 12 3.2 12H9.8C11.015 12 12 11.015 12 9.79997V4.29998C12 3.08495 11.015 2.09998 9.8 2.09998Z" stroke="#581845" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.3 1V3.2M8.7 1V3.2M1 5.4H12" stroke="#581845" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
  
  Svgcalenderscheduler.defaultProps = defaultProps;
  export default Svgcalenderscheduler;
  