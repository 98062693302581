import React from 'react';
import Lottie from 'react-lottie';
import { Flex, Modal } from '../uikit';
import SvgClose from '../icons/SvgClose';
import styles from './loder.module.css';

interface Props {
  data: any;
  height: any;
  width: any;
  check?: boolean;
  closemodelwindow?: any;
}

const MyLottieComponent = ({
  data,
  height,
  width,
  check,
  closemodelwindow,
}: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const comparative_close = () => {
    closemodelwindow();
  };

  return (
    <div>
      {check === true ? (
        <Modal open={true}>
          <Flex className={styles.comparativeloader}>
            <Flex end onClick={() => comparative_close()}>
              <SvgClose
                width={10}
                height={10}
                fill={'#888888'}
                cursor={'pointer'}
              />
            </Flex>
            <Lottie
              options={defaultOptions}
              height={height}
              width={width}
              isClickToPauseDisabled={true}
            />
          </Flex>
        </Modal>
      ) : (
        <Flex className={styles.container}>
          <Flex className={styles.screen}>
            <Lottie
              options={defaultOptions}
              height={height}
              width={width}
              isClickToPauseDisabled={true}
            />
          </Flex>
        </Flex>
      )}

      {/* <Modal>
                <Flex style={{height:'600px',weight:'600px',backgroundColor:'#ffffff'}}>
                     <Lottie options={defaultOptions} height={400} width={400} />
                </Flex>
            </Modal> */}
    </div>
  );
};
export default MyLottieComponent;
