import * as React from 'react';
const SvgUsername = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    viewBox='0 0 10 10'
    {...props}
  >
    <path
      fill="#581845"
      d="M5 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm0 1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm0 4.375c1.669 0 5 .831 5 2.5V10H0V8.125c0-1.669 3.331-2.5 5-2.5Zm0 1.188c-1.856 0-3.813.912-3.813 1.312v.688h7.625v-.688c0-.4-1.956-1.313-3.812-1.313Z"
    />
  </svg>
);
export default SvgUsername;