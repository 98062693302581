import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames/bind';
import { saveAs } from 'file-saver';
import axios from 'axios';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Button from '../../uikit/Button/Button';
import { getBlur, getDateString, getFocus, isEmpty } from '../../uikit/helper';
import Pangination from '../../uikit/Pagination/Pangination';
import { AppDispatch, RootState } from '../../store';
import { inviteToApplyApi, zitaMatchDownloadApi } from '../../routes/apiRoutes';
import Loader from '../../uikit/Loader/Loader';
import Toast from '../../uikit/Toast/Toast';
import { InputSearch, InputText, Modal } from '../../uikit';
import { enterKeyPress } from '../../uikit/helper';
import SvgSearch from '../../icons/SvgSearch';
import SvgRefresh from '../../icons/SvgRefresh';
import SvgLocation from '../../icons/SvgLocation';
import SvgNoCandidate from '../../icons/SvgNoCandidate';
import SvgIntomark from '../../icons/Intomark';
import ParsingLoadingModal from '../bulkimportmodule/ParsingLoadingModal';
import {
  applicantPipeLineDataMiddleWare,
  applicantPipeLineMiddleWare,
} from '../applicantpipelinemodule/store/middleware/applicantpipelinemiddleware';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { qualificationFilterHelper } from '../common/commonHelper';
import { config, ERROR_MESSAGE } from '../constValue';
import ProfileView from '../applicantpipelinemodule/ProfileView';
import {
  applicantFavoriteMiddleWare,
  jdMatchMiddleWare,
} from '../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
import { option } from '../myjobposting/mock';
import ZitaMatchFilters from './ZitaMatchFilters';
import styles from './zitamatchcandidate.module.css';
import {
  zitaMatchCandidateMiddleWare,
  zitaMatchDataCandidateMiddleWare,
  zitaMatchProgressMiddleWare,
} from './store/middleware/zitamatchcandidatemiddleware';
import ZitaAction from './ZitaAction';
import JobTitleCard from './JobTitleCard';
import { SkillListEntity } from './zitaMatchCandidateTypes';
import { sortOptions } from './mock';
import ZitaMatchDataCard from './ZitaMatchDataCard';
import ZitaMatchCandidateDrawer from './ZitaMatchCandidateDrawer';
import AimatchModule from './AiMatchModule';


const cx = classNames.bind(styles);

var querystring = require('querystring');

type ParamsType = {
  jdId: string;
};

const ZitaMatchCandidate = () => {
  const myRef = useRef<any>();

  const selectInputRef = useRef<any>();
  const [isOut, setOut] = useState(false);
  const { jdId } = useParams<ParamsType>();
  const history = useHistory();
  const [location, setlocation] = useState('');
  const [profilevalue, setprofilevalue] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const [isMatchRadio, setMatchRadio] = useState('');
  const [isProfile, setProfile] = useState('');
  const [isBachelors, setBachelors] = useState(false);
  const [isDiploma, setisDiploma] = useState(false);
  const [isDoctorate, setDoctorate] = useState(false);
  const [isMasters, setMasters] = useState(false);
  const [isAny, setAny] = useState(true);
  const [isOther, setOther] = useState(false);
  const [isSearch, setSearch] = useState('');
  const [isSkills, setSkills] = useState<any>();
  const [isSkillOption, setSkillOption] = useState<any>('');
  const [isExperience, setExperience] = useState('');
  const [isJobType, setJobType] = useState('');
  const [isCandiStatus, setCandiStatus] = useState('');
  const [isRelocate, setRelocate] = useState(false);
  const [isLocation, setLocation] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [islocationsearch, setlocationsearch] = useState('');
  const [isCheck, setIsCheck] = useState<any>([]);
  const [isTotalFav, setTotalFav] = useState(false);
  const [isInviteLoader, setInviteLoader] = useState(false);
  const [isDownloadLoader, setDownLoadLoader] = useState(false);
  const [isProfileView, setProfileView] = useState(false);
  const [isPage, setPage] = useState(0);
  const [isSortOptions, setSortOptions] = useState(sortOptions[0]);
  const [aimodule, setaimodule] = useState(false);
  const [totalcount, settotalcount] = useState(0);
  const [compareAi, setcompareAi] = useState(0);
  const [processcandidate, setprocesscandidate] = useState(null);

  const [aimatchLoader, setaimatchLoader] = useState(false);
  const [isopensubcription, setopensubcription] = useState(false);
  const [isMatchingStatus, setMatchingStatus] = useState('');
  const [isParse, setParse] = useState(false);
  const [updateloader, setupdateloader] = useState(false)
  const [reloader, setreloader] = useState(false);
  const [ispagecount, setpagecount] = useState<any>(null);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const getCandiId = query.get('candi_id');
  const getid = query.get('id')
  const favAdd = isTotalFav ? 'add' : '';

  useEffect(() => {

    dispatch(zitaMatchCandidateMiddleWare({ jd_id: jdId })).then(() => {
      if (!isEmpty(getCandiId)) {
        setProfileView(true);
      }
    });
    dispatch(
      zitaMatchDataCandidateMiddleWare({
        jd_id: jdId, invite_candidate: getid === 'success' ? true : false
      }),
    ).then((res) => {
      setupdateloader(res.payload.status)
    })

  }, []);





  const {
    jd_id,
    job_details,
    datas,
    total_applicants,
    favLoader,
    zitaLoader,
    initalLoader,
    applicants_count,
    is_plan,
    bulk_matching,
    resume_credits,
    Permissions,
    plan_details,
    ai_credits,
    current_plan,
    current_resume_count,
    status,
    statuszita,
    zita_match,
    candidate_location

  } = useSelector(
    ({
      zitaMatchCandidateReducers,
      zitaMatchDataCandidateReducers,
      applicantFavReducers,
      permissionReducers,
      SubscriptionReducers,
      zitaMatchprogressReducers,
    }: RootState) => {
      return {
        jd_id: zitaMatchCandidateReducers.jd_id,
        job_details: zitaMatchCandidateReducers.job_details,
        datas: zitaMatchDataCandidateReducers.data,
        current_resume_count: SubscriptionReducers.current_resume_count,
        total_applicants: zitaMatchDataCandidateReducers.total_count,
        favLoader: applicantFavReducers.isLoading,
        zitaLoader: zitaMatchDataCandidateReducers.isLoading,
        initalLoader: zitaMatchCandidateReducers.isLoading,
        applicants_count: zitaMatchCandidateReducers.applicants_count,
        is_plan: permissionReducers.is_plan,
        bulk_matching: zitaMatchDataCandidateReducers.bulk_matching,
        resume_credits: zitaMatchDataCandidateReducers.resume_credits,
        Permissions: permissionReducers.data,
        plan_details: zitaMatchDataCandidateReducers.plan_details,
        ai_credits: zitaMatchDataCandidateReducers.ai_credits,
        current_plan: zitaMatchDataCandidateReducers.current_plan,
        status: zitaMatchprogressReducers.status,
        statuszita: zitaMatchDataCandidateReducers.status,
        zita_match: SubscriptionReducers.zita_match,
        candidate_location: zitaMatchDataCandidateReducers.candidate_location
      };
    },
  );

  useEffect(() => {
    setpagecount(zita_match)
  }, [zita_match])

  document.title = 'Zita Match Candidates';
  // dispatching the API's when the user buy the add-on
  const useQuerys = new URLSearchParams(window.location.search);
  const querys = useQuerys;
  const session_id: any = querys.get('session_id');
  const session: any = querys.get('session');
  useEffect(() => {
    if (!isEmpty(session)) {
      setTimeout(() => {
        dispatch(
          zitaMatchDataCandidateMiddleWare({
            jd_id: jdId, invite_candidate: getid === 'success' ? true : false
          }),
        );
      }, 1000);
    }
  }, [session]);

  useEffect(() => {
    setupdateloader(status)
  }, [status]);

  // setTimeout(() => {
  //   if(updateloader===true){
  //   dispatch(zitaMatchProgressMiddleWare({
  //     jd_id: jdId,
  //   }));}
  // }, 10000);

  // useEffect(() => {

  //   if (updateloader) {
  //     const timeoutId = setTimeout(() => {
  //       dispatch(zitaMatchProgressMiddleWare({
  //         jd_id: jdId,
  //       })).then((res)=>{
  //         if(res.payload.status===false && reloader===false)
  //         {
  //           Toast('Advanced AI matching applied successfully.');
  //              dispatch(
  //     zitaMatchDataCandidateMiddleWare({
  //       jd_id: jdId,
  //     }),
  //   )
  //         }
  //       })
  //     }, 8000);


  //     return () => clearTimeout(timeoutId);
  //   }
  // });

  const refreshloaderoff = () => {
    setSearch('');
    setlocationsearch('');
    dispatch(
      zitaMatchDataCandidateMiddleWare({
        jd_id: jdId, invite_candidate: getid === 'success' ? true : false
      }),
    )
    dispatch(zitaMatchProgressMiddleWare({
      jd_id: jdId,
    })).then((res) => {
      if (res.payload.status === false) {
        // Toast('Advanced AI matching applied successfully.');

      }
    })
  }


  const module_update = (val) => {
    setaimodule(val);
  };

  useEffect(() => {
    if (!is_plan) {
      sessionStorage.setItem('MyAccountDropdown', '1')
      history.push('/account_setting/subscription');
    }
  });

  const usersPerPage = ispagecount;
  const pageCount = Math.ceil(total_applicants / usersPerPage);

  const skillsOptionsList =
    isSkillOption &&
    isSkillOption.map((optionList: { value: string }) => {
      return optionList.value;
    });

  const filterTotalFav = () => {
    setTotalFav(!isTotalFav);
  };

  // handleclick fuction for the name or email or location searching
  const handleSearch = () => {
    dispatch(
      zitaMatchDataCandidateMiddleWare({
        jd_id: jdId,
        profile_match: isProfile,
        fav: favAdd,
        candidate: isSearch,
        location: islocationsearch,
        sort: isSortOptions.value,
        work_experience: isExperience,
        relocate: isRelocate ? '1' : '0',
        invite: isCandiStatus,
        profile_view: isProfile,
        education_level: qaValue,
        type_of_job: isJobType,
        preferred_location: isLocation ? '1' : '0',
        skill_match: skillsOptionsList,
        page: isPage + 1,
        pagecount: ispagecount,
        matching: isMatchingStatus,
        invite_candidate: getid === 'success' ? true : false
      }),
    );
  }

  const handleSearchSubmit = () => {
    setIsCheck([]);

    dispatch(
      zitaMatchDataCandidateMiddleWare({
        jd_id: jdId,
        profile_match: isProfile,
        fav: favAdd,
        candidate: isSearch,
        location: islocationsearch,
        sort: isSortOptions.value,
        work_experience: isExperience,
        relocate: isRelocate ? '1' : '0',
        invite: isCandiStatus,
        profile_view: isProfile,
        education_level: qaValue,
        type_of_job: isJobType,
        preferred_location: isLocation ? '1' : '0',
        skill_match: skillsOptionsList,
        page: isPage + 1,
        pagecount: ispagecount,
        matching: isMatchingStatus,
        invite_candidate: getid === 'success' ? true : false
      }),
    );
  };

  const onClearSearch = () => {
    setSearch('');

    dispatch(
      zitaMatchDataCandidateMiddleWare({
        jd_id: jdId,
        profile_match: isProfile,
        fav: favAdd,
        candidate: '',
        location: islocationsearch,
        sort: isSortOptions.value,
        work_experience: isExperience,
        relocate: isRelocate ? '1' : '0',
        invite: isCandiStatus,
        profile_view: isProfile,
        education_level: qaValue,
        type_of_job: isJobType,
        preferred_location: isLocation ? '1' : '0',
        skill_match: skillsOptionsList,
        page: isPage + 1,
        pagecount: ispagecount,
        matching: isMatchingStatus,
        invite_candidate: getid === 'success' ? true : false
      }),
    );
  };

  const handleCloseParse = () => {
    setaimatchLoader(false);
    setParse(false);
    setIsCheck([]);
    setreloader(true);
    // dispatch(
    //   zitaMatchDataCandidateMiddleWare({
    //     jd_id: jdId,
    //   }),
    // )
    dispatch(
      zitaMatchProgressMiddleWare({
        jd_id: jdId,
      }),
    ).then((res) => {
      setupdateloader(res.payload.status)
    })


  };

  const hanldeMatch = (listValue: SkillListEntity) => {
    setMatchRadio(listValue.label);
  };
  const hanldeProfile = (listValue: SkillListEntity) => {
    setProfile(listValue.label);
    setprofilevalue(listValue.value);
  };

  const handleBachelor = () => {
    setBachelors(!isBachelors);
    setAny(false);
  };
  const handleDiploma = () => {
    setisDiploma(!isDiploma);
    setAny(false);
  };

  const handleDoctorate = () => {
    setDoctorate(!isDoctorate);
    setAny(false);
  };

  const handleMaster = () => {
    setMasters(!isMasters);
    setAny(false);
  };

  const handleOther = () => {
    setOther(!isOther);
    setAny(false);
  };

  const handleAny = () => {
    setAny(!isAny);
    setBachelors(false);
    setisDiploma(false);
    setDoctorate(false);
    setMasters(false);
    setOther(false);
  };

  const qualificationOption = [
    {
      value: 'Bachelors',
      label: 'Bachelor',
      checked: isBachelors,
      onChange: handleBachelor,
      width: 110,
    },
    {
      value: 'Masters',
      label: 'Master',
      checked: isMasters,
      onChange: handleMaster,
      width: 110,
    },
    {
      value: 'Doctorate',
      label: 'Doctorate',
      checked: isDoctorate,
      onChange: handleDoctorate,
      width: 87,
    },
    {
      value: 'Diploma',
      label: 'Diploma',
      checked: isDiploma,
      onChange: handleDiploma,
      width: 110,
    },
    {
      value: 'Others',
      label: 'Other',
      checked: isOther,
      onChange: handleOther,
      width: 110,
    },
    {
      value: 'Any',
      label: 'any',
      width: 110,
      checked: isAny,
      onChange: handleAny,
    },
  ];

  useEffect(() => {
    if (
      isBachelors === false &&
      isDoctorate === false &&
      isMasters === false &&
      isDiploma === false &&
      isOther === false
    ) {
      setAny(true);
    }
  }, [isBachelors, isDoctorate, isMasters, isDiploma, isOther]);

  const qaValue = qualificationFilterHelper(
    isAny,
    isBachelors,
    isDoctorate,
    isDiploma,
    isMasters,
    isOther,
  );

  const handleExperience = (selectedValue: string) => {
    if (!change) {
      setIsCheck([]);
      dispatch(
        zitaMatchDataCandidateMiddleWare({
          jd_id: jdId,
          profile_match: isMatchRadio,
          fav: favAdd,
          candidate: isSearch,
          location: islocationsearch,
          sort: isSortOptions.value,
          work_experience: selectedValue,
          relocate: isRelocate ? '1' : '0',
          invite: isCandiStatus,
          profile_view: isProfile,
          education_level: qaValue,
          type_of_job: isJobType,
          preferred_location: isLocation ? '1' : '0',
          skill_match: skillsOptionsList,
          page: isPage + 1,
          pagecount: ispagecount,
          matching: isMatchingStatus,
          invite_candidate: getid === 'success' ? true : false
        }),
      );
    }
  };

  const handlesortby = (selectedValue: string) => {
    dispatch(
      zitaMatchDataCandidateMiddleWare({
        jd_id: jdId,
        profile_match: isProfile,
        fav: favAdd,
        candidate: isSearch,
        location: islocationsearch,
        sort: selectedValue,
        work_experience: isExperience,
        relocate: isRelocate ? '1' : '0',
        invite: isCandiStatus,
        profile_view: isProfile,
        education_level: qaValue,
        type_of_job: isJobType,
        preferred_location: isLocation ? '1' : '0',
        skill_match: skillsOptionsList,
        page: isPage + 1,
        pagecount: ispagecount,
        matching: isMatchingStatus,
        invite_candidate: getid === 'success' ? true : false
      }),
    );
  };

  const handleRelocate = () => {
    setRelocate(!isRelocate);
    setchange(true);
  };
  const handleLocation = () => {
    setLocation(!isLocation);

    setchange(true);
  };

  const candiList = datas.map((list) => {
    return list.id.toString();
  });

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(candiList);
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // function for clearing the location when  click the cross icon.
  const onClearLocationsearch = () => {
    setlocationsearch('');
    dispatch(
      zitaMatchDataCandidateMiddleWare({
        jd_id: jdId,
        profile_match: isProfile,
        fav: favAdd,
        candidate: isSearch,
        location: '',
        sort: isSortOptions.value,
        work_experience: isExperience,
        relocate: isRelocate ? '1' : '0',
        invite: isCandiStatus,
        profile_view: isProfile,
        education_level: qaValue,
        type_of_job: isJobType,
        preferred_location: isLocation ? '1' : '0',
        skill_match: skillsOptionsList,
        page: isPage + 1,
        pagecount: ispagecount,
        matching: isMatchingStatus,
        invite_candidate: getid === 'success' ? true : false
      }),
    );
  };
  const datafun = () => {
    dispatch(
      zitaMatchDataCandidateMiddleWare({
        jd_id: jdId,
        profile_match: isMatchRadio,
        fav: favAdd,
        candidate: isSearch,
        location: islocationsearch,
        sort: isSortOptions.value,
        work_experience: isExperience,
        relocate: isRelocate ? '1' : '0',
        invite: isCandiStatus,
        profile_view: isProfile,
        education_level: qaValue,
        type_of_job: isJobType,
        preferred_location: isLocation ? '1' : '0',
        skill_match: skillsOptionsList,
        page: isPage + 1,
        pagecount: ispagecount,
        matching: isMatchingStatus,
        invite_candidate: getid === 'success' ? true : false
      }),
    );
    setIsCheck([]);
  };

  const handleClick = (e: { target: { id: string; checked: boolean } }) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id));
    }
  };

  useEffect(() => {
    if (isCheck && isCheck.length !== datas?.length) {
      setIsCheckAll(false);
    }
  }, [isCheck]);

  const [change, setchange] = useState(false);
  if (
    isCheck.length === datas.length &&
    isCheckAll === false &&
    datas.length !== 0
  ) {
    setIsCheckAll(true);
  }

  useEffect(() => {
    if (!change) {
      datafun();
    }
  }, [
    isSkillOption,
    isBachelors,
    isDoctorate,
    isDiploma,
    isMasters,
    isAny,
    isOther,
    isMatchRadio,
    isProfile,
    isJobType,
    isCandiStatus,
    isTotalFav,
    isRelocate,
    isLocation,
    favLoader,
    isInviteLoader,
    isPage,
    change,
    ispagecount
  ]);
  // filter refesh function
  const hanldeRefresh = () => {
    setDoctorate(false);
    setMasters(false);
    setAny(true);
    setBachelors(false);
    setisDiploma(false);
    setOther(false);
    setSearch('');
    setlocationsearch('');
    setMatchRadio('');
    setExperience('');
    setProfile('');
    setSkillOption('');
    setJobType('');
    setCandiStatus('');
    setRelocate(false);
    setLocation(false);
    setSortOptions({ value: 'match', label: 'Match Score' });
    dispatch(
      zitaMatchDataCandidateMiddleWare({
        jd_id: jdId,
        profile_match: '',
        fav: favAdd,
        candidate: '',
        location: '',
        sort: 'match',
        work_experience: '',
        relocate: '0',
        invite: isCandiStatus,
        profile_view: '',
        education_level: '',
        type_of_job: '',
        preferred_location: '0',
        skill_match: '',
        page: isPage + 1,
        pagecount: ispagecount,
        matching: isMatchingStatus,
        invite_candidate: getid === 'success' ? true : false
      }),
    );
  };

  const handleradiovclear = () => {
    setMatchRadio('');
  };
  const wrapperRef = useRef(null);
  const handleexpclear = () => {
    if (!change) {
      setExperience('');
      dispatch(
        zitaMatchDataCandidateMiddleWare({
          jd_id: jdId,
          profile_match: isMatchRadio,
          fav: favAdd,
          candidate: isSearch,
          location: islocationsearch,
          sort: isSortOptions.value,
          work_experience: '',
          relocate: isRelocate ? '1' : '0',
          invite: isCandiStatus,
          profile_view: isProfile,
          education_level: qaValue,
          type_of_job: isJobType,
          preferred_location: isLocation ? '1' : '0',
          skill_match: skillsOptionsList,
          page: isPage + 1,
          pagecount: ispagecount,
          matching: isMatchingStatus,
          invite_candidate: getid === 'success' ? true : false
        }),
      );
    }
  };
  const handlematchingclear = () => {
    if (!change) {
      setMatchingStatus('');
      dispatch(
        zitaMatchDataCandidateMiddleWare({
          jd_id: jdId,
          profile_match: isMatchRadio,
          fav: favAdd,
          candidate: isSearch,
          location: islocationsearch,
          sort: isSortOptions.value,
          work_experience: '',
          relocate: isRelocate ? '1' : '0',
          invite: isCandiStatus,
          profile_view: isProfile,
          education_level: qaValue,
          type_of_job: isJobType,
          preferred_location: isLocation ? '1' : '0',
          skill_match: skillsOptionsList,
          page: isPage + 1,
          pagecount: ispagecount,
          matching: '',
          invite_candidate: getid === 'success' ? true : false
        }),
      );
    }
  };

  const hanleprofileclear = () => {
    setProfile('');
    setprofilevalue('');
  };

  const hanlejobtypeclear = () => {
    setJobType('');
  };

  const matchingclear = () => {
    setMatchingStatus('');
  };

  //Ai matching

  const Aiapi_call = () => {
    setaimodule(false);
    setaimatchLoader(true);
    setParse(true)

    const filteredIds = datas
      .filter(
        (val) =>
          isCheck.includes(val.id.toString()) && val.candidate_ai === false,
      )
      .map((val) => val.id);
    dispatch(jdMatchMiddleWare({ jd_id: jdId, candidates: filteredIds, location: 'zita' })).then(
      (res) => {
        if (res.payload.suceess === true) {
          setaimatchLoader(false);
          setupdateloader(false)
          setParse(false);
          setIsCheck([]);
          dispatch(
            zitaMatchDataCandidateMiddleWare({
              jd_id: jdId, invite_candidate: getid === 'success' ? true : false
            }),
          );
        } else {
          setaimatchLoader(false);
          setParse(false);
          setIsCheck([]);
          setupdateloader(false);
          dispatch(zitaMatchProgressMiddleWare({
            jd_id: jdId,
          }))
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        }
      },
    );
  };

  const Ai_matching = () => {
    var check = update_count();

    if (check.length === 0) {
      setprocesscandidate(check.length);
      module_update(true);
    } else {
      if (check.length !== isCheck.length) {
        settotalcount(isCheck.length);
        setcompareAi(isCheck.length - check.length);
        setprocesscandidate(check.length);
        module_update(true);
      } else {
        Aiapi_call();
      }
    }
  };

  const update_count = () => {
    var convertedArray = isCheck.map(function (element) {
      return parseInt(element, 10);
    });
    var nonUniqueNumbers = convertedArray.filter(
      (element) => !bulk_matching.includes(element),
    );

    return nonUniqueNumbers;
  };

  // resume download function
  const hanldeDownload = () => {
    if (isCheck.length !== 0) {
      setDownLoadLoader(true);
      const data = querystring.stringify({
        jd: jd_id,
        candi_id: isCheck.toString(),
        download: 'download',
      });

      axios
        .post(zitaMatchDownloadApi, data, config)
        .then((response) => {
          setDownLoadLoader(false);
          if (response.data.file_path) {
            saveAs(
              window.location.protocol + '//' + response.data.file_path,
              `Candidates_Profiles_${getDateString(new Date(), 'll')}.zip`,
            );
          }
          Toast('Resume exported successfully.');
        })
        .catch(() => {
          setDownLoadLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
  };

  // filter fav function
  const hanldeFav = (can_id: number) => {
    dispatch(applicantFavoriteMiddleWare({ jd_id, can_id }));
  };

  // invite function
  const hanldeInvite = (can_id: number) => {
    setInviteLoader(true);
    const data = querystring.stringify({
      jd_id,
      candi_id: can_id,
    });
    axios
      .post(inviteToApplyApi, data, config)
      .then(() => {
        setInviteLoader(false);
        Toast('Candidate invited successfully.');
      })
      .catch(() => {
        setInviteLoader(false);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  // custom styles for page selection text box and dropdown.
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#581845' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
      justifyContent: "center",
      '&:hover': {
        backgroundColor: state.isSelected ? 'none' : '#D7C7D2',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #581845' : '1px solid #d7c7d2',
      fontSize: '12px',
      minHeight: '16px',
      lineHeight: '16px',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '1px solid #581845',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      height: '16px',
      lineHeight: '16px',
      textAlign: 'center',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      margin: '5px 0',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform 0.3s ease',
      height: '16px',
      marginTop: '-20px'
    }),
    menu: (provided) => ({
      ...provided,
      top: 'auto',
      bottom: 22,
    }),
  };
  const handleApplicant = () => {
    dispatch(applicantPipeLineMiddleWare({ jd_id })).then((res) => {
      dispatch(
        applicantPipeLineDataMiddleWare({
          jd_id: res.payload.jd_id,
        }),
      );
    });
  };

  // pagination function
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [isPage, pageCount]);

  const handleSetPagination = (a: number) => {
    setPage(a);
    if (datas.length !== 0) {
      getFocus(datas[0].id.toString());
      getBlur(datas[0].id.toString());
    }
  };

  // close applicant and candidate view function
  const handleClose = () => {
    dispatch(
      zitaMatchDataCandidateMiddleWare({
        jd_id: jdId,
        profile_match: isProfile,
        fav: favAdd,
        candidate: isSearch,
        work_experience: isExperience,
        relocate: isRelocate ? '1' : '0',
        invite: isCandiStatus,
        profile_view: isProfile,
        education_level: qaValue,
        type_of_job: isJobType,
        preferred_location: isLocation ? '1' : '0',
        skill_match: skillsOptionsList,
        page: isPage + 1,
        pagecount: ispagecount,
        invite_candidate: getid === 'success' ? true : false
      }),
    );

    if (query.has('candi_id')) {
      query.delete('candi_id');
      history.replace({
        search: query.toString(),
      });
    }
    setProfileView(false);
  };

  // setting the location search data in the setstate.
  const handleLocationset = (e) => {
    if (e?.target?.innerHTML !== '' && e !== '') {
      const data = e.target.innerHTML
      setlocationsearch(data)
    }
    else if (e !== '') {
      setlocationsearch(e.target.value)
    }
  } 

  return (
    <Flex className={styles.overAll}>
      <AimatchModule
        module_update={module_update}
        aimodule={aimodule}
        totalcount={totalcount}
        compareAi={compareAi}
        Aiapi_call={Aiapi_call}
        processcandidate={processcandidate}
      />

      {!isEmpty(getCandiId) && (
        <ZitaMatchCandidateDrawer
          activeState={0}
          open={isProfileView}
          cancel={handleClose}
          jobId={jdId}
          candidateId={getCandiId}
        />
      )}
      {!isEmpty(getCandiId) && (
        <ProfileView
          open={isProfileView}
          cancel={handleClose}
          jobId={jdId}
          candidateId={getCandiId}
          applicantpipeline={false}
        />
      )}
      {favLoader !== true && zitaLoader && <Loader />}
      {initalLoader && <Loader />}
      {isDownloadLoader && <Loader />}
      {isInviteLoader && <Loader />}
      {ispagecount === null && <Loader />}

      <Flex row className={styles.titleContainer}>
        <Text bold size={16} color="theme">
          Zita Match Candidates
        </Text>
        <JobTitleCard job_details={job_details} />
        <div className={styles.triangle}> </div>
      </Flex>

      <Flex row between marginBottom={15}>
        <Flex>
          <Flex
            row
            style={{ position: 'relative', overFlowX: 'auto' }}
            className={styles.searchbox}
          >
            <Flex row className={styles.searchstyle}>
              <Text className={styles.jobstext} style={{ fontSize: '13px' }}>
                Candidates
              </Text>
              <Flex row className={styles.searchboxoverall}>
                <InputText
                  ref={myRef}
                  value={isSearch}
                  className={styles.boxstyle}
                  onChange={(e) => setSearch(e.target.value)}
                  id="zitamatchfilters__search"
                  placeholder="Search candidate by name or email"
                  actionRight={() => (
                    <label
                      htmlFor={'zitamatchfilters__search'}
                      style={{ margin: 0 }}
                      tabIndex={-1}
                      role={'button'} // eslint-disable-line
                    >
                      {isSearch.trim() !== '' && (
                        <button
                          className={styles.crossIcon}
                          onClick={onClearSearch}
                        >
                          <SvgIntomark width={14} height={14} fill="#888888" />
                        </button>
                      )}
                    </label>
                  )}
                  onKeyPress={(e) => enterKeyPress(e, handleSearchSubmit)}
                />
                <Flex className={styles.middleline}></Flex>
                <Flex className={styles.locationicon}>
                  <SvgLocation width={18} height={18} fill={'#581845'} />
                </Flex> 
                <InputSearch
                  labelBold
                  initialValue={islocationsearch}
                  options={candidate_location}
                  onChange={(e) => handleLocationset(e)}
                  onkeyPress={(e) => handleLocationset(e)}
                  placeholder="Select candidate location"
                  style={styles.boxstyle}
                  setFieldValue={(e) => handleLocationset(e)}
                />
                
                {islocationsearch.trim() !== '' && (
                  <button
                    className={styles.crossIcon}
                    onClick={onClearLocationsearch}
                  >
                    <SvgIntomark width={14} height={14} fill="#888888" />
                  </button>
                )}
                <Flex className={styles.searchicons} onClick={() => handleSearch()} style={{ cursor: 'pointer' }}>
                  <SvgSearch width={12} height={12} fill="#ffffff" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          {applicants_count === 0 ? (
            <Flex row center>
              <Flex>
                {updateloader ? (<Flex row center>

                  <Flex className={styles.updateBtnLoader}>
                    <Loader size="small" withOutOverlay />
                  </Flex>
                  <Text className={styles.process}>
                    AI Matching in progress...
                  </Text>
                  <Flex onClick={refreshloaderoff} marginLeft={5}>
                    <SvgRefresh width={22} height={22} />
                  </Flex>
                </Flex>
                ) : ('')}
              </Flex>
              <Button
                disabled={true}
                className={styles.btnStyle}
                types="primary"
                onClick={handleApplicant}
                style={{ marginRight: '10px' }}
              >
                Back to pipeline
              </Button>
            </Flex>
          ) : (<Flex row center>
            <Flex row center>
              {updateloader ? (<Flex row center >

                <Flex className={styles.updateBtnLoader}>
                  <Loader size="small" withOutOverlay />
                </Flex>
                <Text className={styles.process}>
                  AI Matching in progress...
                </Text>
                <Flex onClick={refreshloaderoff} marginLeft={5}>
                  <SvgRefresh width={22} height={22} />
                </Flex>
              </Flex>
              ) : ('')}
            </Flex>
            <Link to={`/applicant_pipe_line/${jd_id}`} target="blank">
              <Button
                className={styles.btnStyle}
                types="primary"
                onClick={handleApplicant}
                style={{ marginRight: '10px' }}
              >
                Back to pipeline
              </Button>
            </Link>
          </Flex>
          )}
        </Flex>
      </Flex>

      <Flex>
        <ZitaMatchFilters
          setchange={setchange}
          isSkillOption={isSkillOption}
          handleBachelor={handleBachelor}
          handleDoctorate={handleDoctorate}
          handleMaster={handleMaster}
          handleOther={handleOther}
          isBachelors={isBachelors}
          isdiploma={isDiploma}
          handleDiploma={handleDiploma}
          isDoctorate={isDoctorate}
          isMasters={isMasters}
          isOther={isOther}
          setLocation={setLocation}
          setRelocate={setRelocate}
          setSearch={setSearch}
          isSearch={isSearch}
          isAny={isAny}
          handleSearchSubmit={handleSearchSubmit}
          isMatchRadio={isMatchRadio}
          hanldeMatch={hanldeMatch}
          handleExperience={handleExperience}
          setExperience={setExperience}
          setSkills={setSkills}
          setSkillOption={setSkillOption}
          isSkills={isSkills}
          isJobType={isJobType}
          hanlejobtypeclear={hanlejobtypeclear}
          setJobType={setJobType}
          handleexpclear={handleexpclear}
          hanldeProfile={hanldeProfile}
          handleradiovclear={handleradiovclear}
          isProfile={isProfile}
          profilevalue={profilevalue}
          qualificationOption={qualificationOption}
          isCandiStatus={isCandiStatus}
          setCandiStatus={setCandiStatus}
          hanleprofileclear={hanleprofileclear}
          isRelocate={isRelocate}
          handleRelocate={handleRelocate}
          isLocation={isLocation}
          handleLocation={handleLocation}
          hanldeRefresh={hanldeRefresh}
          isExperience={isExperience}
          isMatchingStatus={isMatchingStatus}
          setMatchingStatus={setMatchingStatus}
          matchingclear={matchingclear}
          handlematchingclear={handlematchingclear}
        />
      </Flex>
      <Flex>
        <ZitaAction
          total={total_applicants}
          filterTotalFav={filterTotalFav}
          current_resume_count={current_resume_count}
          isTotalFav={isTotalFav}
          handleSelectAll={handleSelectAll}
          isCheckAll={isCheckAll}
          hanldeDownload={hanldeDownload}
          isCheck={isCheck}
          setSortOptions={setSortOptions}
          isSortOptions={isSortOptions}
          handlesortby={handlesortby}
          ai_matching={Ai_matching}
          bulk_matching={bulk_matching}
          resume_credits={resume_credits}
          Permissions={Permissions}
          plan_details={plan_details}
          ai_credits={ai_credits}
          current_plan={current_plan}
          setopensubcription={setopensubcription}
          aimatchLoader={aimatchLoader}
          updateloader={updateloader}
        />
      </Flex>

      <div
        // className={styles.cards}
        ref={wrapperRef}
        style={{
          height: window.innerHeight - 303 + 54,
          overflowY: 'scroll',
          paddingRight: 0,
          paddingTop: 0,
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
        }}
      >
        {total_applicants === 0 && (
          <Flex
            height={'100%'}
            flex={1}
            center
            middle
            style={{ display: 'flex' }}
          >
            <SvgNoCandidate style={{ filter: 'opacity(0.6)' }} />
            <Text color="gray">No Candidate Found</Text>
          </Flex>
        )}

        {datas &&
          datas.map((dataList, index) => {
            return (
              <ZitaMatchDataCard
                hanldeFav={hanldeFav}
                dataList={dataList}
                key={index + dataList.first_name}
                index={index}
                jobId={jd_id}
                isCheck={isCheck}
                handleClick={handleClick}
                hanldeInvite={hanldeInvite}
                isProfile={isProfile}
                favAdd={favAdd}
                isSearch={isSearch}
                isExperience={isExperience}
                isRelocate={isRelocate}
                isCandiStatus={isCandiStatus}
                qaValue={qaValue}
                isJobType={isJobType}
                isLocation={isLocation}
                skillsOptionsList={skillsOptionsList}
                isPage={isPage}
              />
            );
          })}
        <div style={{ width: '100%' }}>
          {total_applicants !== 0 && ispagecount !== null &&
            <Flex style={{ paddingTop: '5px' }} between center row width={"100%"} flex={12}>
              <Flex flex={4}></Flex>
              <Flex flex={4} middle>
                <Pangination
                  maxPages={pageCount - 1}
                  currentPage={isPage}
                  setCurrentPage={handleSetPagination}
                />
              </Flex>
              <Flex row center end flex={4}>
                <Flex style={{ marginRight: '10px', fontSize: '13px' }}>Candidates per page :</Flex>
                <Select
                  options={option}
                  value={option.find((e) => e.value === ispagecount)}
                  onChange={(e) => {
                    setpagecount(e.value);
                  }}
                  menuPosition="fixed"
                  styles={customStyles}
                />
              </Flex>
            </Flex>}
        </div>
      </div>

      {isopensubcription && (
        <SubscriptionModal
          currentplan={current_plan}
          nextplan={current_plan + 1}
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          addon_name={['Resume Matching For Multiple Jobs(AI)']}
          differece_key={['AI_matching']}
        />
      )}
      <Modal open={isParse}>
        <Flex className={styles.overAllloader}>
          <Flex column>
            <Flex row center>
              {aimatchLoader && <Loader withOutOverlay size={'small'} />}

              <Text
                className={styles.flexConatiner}
                type="titleMedium"
              >
                Advanced AI Matching is running in background.....
              </Text>
            </Flex>
            <Flex className={styles.des}>
              <Text className={styles.des}>
                This might take a few minutes to process. Moving away from this page will not stop
                the process.
              </Text>
            </Flex>

          </Flex>

          <Flex row center middle marginTop={10}>

            <Button onClick={handleCloseParse} className={styles.btn}>
              OK
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
};

export default ZitaMatchCandidate;
