 import { memo } from 'react'; 

const defaultProps = {
  fill: '#581845',
  width: 24,
  height: 24,
};

const Svgchatmessage = ({ fill, width, height }: typeof defaultProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 29 28" 
  >
<g >
<path d="M9.20087 20C8.94603 20 8.70159 19.8933 8.5211 19.7033C8.34062 19.5133 8.23883 19.2555 8.23802 18.9864V15.8007H3.21787C2.36473 15.7997 1.54679 15.4414 0.943529 14.8043C0.340266 14.1672 0.000941437 13.3035 0 12.4025V3.39814C0.000941437 2.4972 0.340266 1.63346 0.943529 0.996396C1.54679 0.359331 2.36473 0.000994187 3.21787 0H17.4049C18.258 0.000994187 19.0759 0.359331 19.6792 0.996396C20.2824 1.63346 20.6218 2.4972 20.6227 3.39814V6.4749C20.6227 6.65663 20.5543 6.83094 20.4327 6.95945C20.311 7.08795 20.146 7.16014 19.9739 7.16014C19.8018 7.16014 19.6367 7.08795 19.5151 6.95945C19.3934 6.83094 19.325 6.65663 19.325 6.4749V3.39814C19.3245 2.86053 19.122 2.34511 18.762 1.96495C18.402 1.5848 17.914 1.37097 17.4049 1.3704H3.21787C2.70878 1.37097 2.22068 1.5848 1.8607 1.96495C1.50072 2.34511 1.29826 2.86053 1.29773 3.39814V12.4004C1.29826 12.938 1.50072 13.4534 1.8607 13.8336C2.22068 14.2137 2.70878 14.4276 3.21787 14.4281H8.57488C8.82976 14.4284 9.07414 14.5355 9.25437 14.7258C9.4346 14.9161 9.53597 15.1742 9.53624 15.4433V18.1262L12.7572 14.7243C12.8463 14.6298 12.9522 14.5548 13.0689 14.5038C13.1856 14.4528 13.3107 14.4267 13.437 14.427H14.5655C14.7376 14.427 14.9026 14.4992 15.0243 14.6277C15.146 14.7562 15.2144 14.9306 15.2144 15.1123C15.2144 15.294 15.146 15.4683 15.0243 15.5968C14.9026 15.7253 14.7376 15.7975 14.5655 15.7975H13.5767L9.87974 19.7011C9.79083 19.7957 9.68506 19.8708 9.56855 19.9221C9.45205 19.9734 9.32709 19.9999 9.20087 20Z" fill={fill}/>
<path d="M14.4665 8.58528H14.4075C14.2355 8.58528 14.0704 8.51309 13.9487 8.38459C13.8271 8.25608 13.7587 8.08178 13.7587 7.90005C13.7587 7.71832 13.8271 7.54404 13.9487 7.41554C14.0704 7.28704 14.2355 7.21484 14.4075 7.21484H14.4665C14.6386 7.21484 14.8036 7.28704 14.9253 7.41554C15.047 7.54404 15.1153 7.71832 15.1153 7.90005C15.1153 8.08178 15.047 8.25608 14.9253 8.38459C14.8036 8.51309 14.6386 8.58528 14.4665 8.58528ZM10.3076 8.58528H10.2492C10.0771 8.58528 9.91203 8.51309 9.79034 8.38459C9.66866 8.25608 9.6003 8.08178 9.6003 7.90005C9.6003 7.71832 9.66866 7.54404 9.79034 7.41554C9.91203 7.28704 10.0771 7.21484 10.2492 7.21484H10.3076C10.4797 7.21484 10.6447 7.28704 10.7664 7.41554C10.8881 7.54404 10.9565 7.71832 10.9565 7.90005C10.9565 8.08178 10.8881 8.25608 10.7664 8.38459C10.6447 8.51309 10.4797 8.58528 10.3076 8.58528ZM6.14871 8.58528H6.09028C5.9182 8.58528 5.75314 8.51309 5.63145 8.38459C5.50977 8.25608 5.44141 8.08178 5.44141 7.90005C5.44141 7.71832 5.50977 7.54404 5.63145 7.41554C5.75314 7.28704 5.9182 7.21484 6.09028 7.21484H6.14871C6.3208 7.21484 6.48583 7.28704 6.60751 7.41554C6.7292 7.54404 6.79756 7.71832 6.79756 7.90005C6.79756 8.08178 6.7292 8.25608 6.60751 8.38459C6.48583 8.51309 6.3208 8.58528 6.14871 8.58528Z" fill={fill}/>
<path d="M22.8392 19.9987C22.6203 19.9986 22.4106 19.9067 22.2559 19.7433L20.2442 17.6184H18.192C17.6341 17.6177 17.0994 17.3833 16.7049 16.9668C16.3105 16.5502 16.0886 15.9855 16.0879 15.3964V10.2962C16.0884 9.70707 16.3103 9.14224 16.7048 8.72566C17.0992 8.30908 17.6341 8.07479 18.192 8.07422H26.2293C26.7871 8.07493 27.3219 8.30924 27.7164 8.72579C28.1108 9.14234 28.3327 9.70711 28.3334 10.2962V15.3964C28.3327 15.9855 28.1108 16.5502 27.7164 16.9668C27.3219 17.3833 26.7871 17.6177 26.2293 17.6184H23.6659V19.1262C23.6659 19.2408 23.6445 19.3543 23.6029 19.4602C23.5614 19.5661 23.5005 19.6623 23.4237 19.7433C23.3469 19.8243 23.2558 19.8886 23.1555 19.9324C23.0552 19.9762 22.9477 19.9987 22.8392 19.9987ZM18.192 9.44462C17.9782 9.44491 17.7733 9.53474 17.6221 9.69438C17.4709 9.85401 17.3859 10.0704 17.3856 10.2962V15.3964C17.3859 15.6222 17.4709 15.8386 17.6221 15.9982C17.7733 16.1579 17.9782 16.2477 18.192 16.248H20.4399C20.5483 16.2476 20.6558 16.2701 20.756 16.3139C20.8562 16.3578 20.9472 16.4221 21.0237 16.5034L22.3687 17.9237V17.1188C22.3689 16.8876 22.456 16.6659 22.6108 16.5024C22.7655 16.3388 22.9754 16.2468 23.1944 16.2463H26.2293C26.4431 16.2461 26.648 16.1563 26.7992 15.9966C26.9504 15.837 27.0354 15.6206 27.0357 15.3948V10.2946C27.0354 10.0688 26.9504 9.85241 26.7992 9.69277C26.648 9.53314 26.4431 9.4433 26.2293 9.44302L18.192 9.44462Z" fill={fill}/>
</g>
<defs> 
<rect width="28.3333" height="20" fill="white"/>
 
</defs>
</svg>
);

Svgchatmessage.defaultProps = defaultProps;

export default memo(Svgchatmessage);
