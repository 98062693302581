import * as React from 'react';
const SvgMobilet = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="581845"
    viewBox='0 0 16 16'
    {...props}
  >
    <path
      fill="#581845"
      d="m12.866 14.443 1.9-1.897a1.227 1.227 0 0 0 .002-1.741l-2.04-2.048a1.231 1.231 0 0 0-1.743-.002L9.383 10.35a7.23 7.23 0 0 1-2.268-1.543 7.245 7.245 0 0 1-1.542-2.27L7.175 4.94a1.227 1.227 0 0 0 .002-1.74l-2.04-2.05a1.23 1.23 0 0 0-1.744-.003l-1.9 1.896a2.136 2.136 0 0 0-.596 1.843c.386 2.362 1.64 4.707 3.528 6.602 1.892 1.892 4.234 3.15 6.596 3.546.67.113 1.361-.11 1.845-.591Zm-1.63-.673c-2.103-.35-4.199-1.484-5.9-3.19-1.701-1.705-2.83-3.802-3.174-5.905a.839.839 0 0 1 .236-.727l1.866-1.861L6.24 4.065 4.097 6.2l-.016.016.142.386a8.584 8.584 0 0 0 5.09 5.101l.385.143 2.156-2.151 1.975 1.978-1.866 1.862a.835.835 0 0 1-.728.236Z"
    />
  </svg>
);
export default SvgMobilet;