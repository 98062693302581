import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Flex, Text } from '../../../uikit';
import { toCamelCase } from '../../../uikit/helper';
import {
  EVENT_FILTER_OPTION,
  IEvent,
  IEventInterviewer,
  IEventOrganiser,
  IEventTableItem,
} from '../types';
import SvgDotMenu from '../../../icons/SvgDotMenu';
import CustomTooltip from '../../../uikit/Tooltip/tooltip';
import InterviewerIcon from '../../calendarModule/InterviewerIcon';
import SvgCalendar from '../../../icons/SvgCalendar';
import { JOIN_EVENTS } from '../utils';
import { RootState } from '../../../store';
import styles from './eventsTable.module.css';


export interface DateEntity {
  label: string;
  value: string;
}
export type MyJobFormProps = {
  jobTitle: string;
  jobId: string;
  postedOn: DateEntity;
  jobType: string;
  location: string;
};

interface Props {
  list: IEventTableItem[];
  pastEvents: boolean;
  deleteState: any;
  activeRadio: any;
  onJoin?: (doc: IEvent) => void;
  // onEdit?: (doc: IEvent) => void;
  onDelete?: (doc: IEvent) => void;
}
const EventSchedulerScreen: React.FC<Props> = (props) => {
  const {
    pastEvents,
    list,
    activeRadio,
    deleteState,
    onJoin,
    // onEdit,
    onDelete,
  } = props;

  const { Permission } = useSelector(({ userProfileReducers }: RootState) => {
    return {
      Permission: userProfileReducers.user.id,
    };
  });

  const renderInterviewers = (interviewrs: IEventInterviewer[]) => {
    const show = interviewrs.slice(0, 4);
    const hidden = interviewrs.slice(4, interviewrs.length);
    return (
      <Flex row wrap>
        {show.map((doc, sIndex) => (
          <InterviewerIcon name={doc.full_name} key={sIndex} index={sIndex} />
        ))}
        {hidden && hidden.length > 0 && (
          <InterviewerIcon name={`+ ${hidden.length}`} title={hidden.map(doc => doc.full_name).toString()} />
        )}
      </Flex>
    );
  };

  const renderOrganiser = (interviewrs: IEventOrganiser[]) => {
    const show = interviewrs.slice(0, 4);
    const hidden = interviewrs.slice(4, interviewrs.length);
    return (
      <Flex row wrap>
        {show.map((doc, sIndex) => (
          doc.full_name
          // <InterviewerIcon name={doc.full_name} key={sIndex} index={sIndex} />
        ))}
        {/* {hidden && hidden.length > 0 && (
          <InterviewerIcon
            name={`+ ${hidden.length}`}
            title={hidden.map((doc) => doc.full_name).toString()}
          />
        )} */}
      </Flex>
    );
  };

  const renderTable = () => {
    if (list.length === 0) {
      return (
        <Flex
          column
          center
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Flex marginBottom={2}>
            <SvgCalendar width={16} height={16} fill={'#888888'} stroke={'#888888'} />
          </Flex>
          <Text style={{ color: '#888888' }}>{`No ${activeRadio === EVENT_FILTER_OPTION.PAST
            ? 'past'
            : activeRadio === EVENT_FILTER_OPTION.UPCOMING ? 'upcoming' : ''
            } events`}</Text>
        </Flex>
      );
    }
    return (
      <table className="table" style={{ paddingLeft: 'none', height: 'fit-content' }}>
        <thead>
          <tr>
            <th className={styles.hpadchange}>
              <Text bold className={styles.tableboarder}>
                Event Title
              </Text>
            </th>
            <th className={styles.hpadchange}>
              <Text bold className={styles.tableboarder}>
                Date
              </Text>
            </th>
            <th className={styles.hpadchange}>
              <Text bold className={styles.tableboarder}>
                Timings
              </Text>
            </th>
            <th className={styles.hpadchange}>
              <Text bold className={styles.tableboarder}>
                Duration
              </Text>
            </th>
            <th className={styles.hpadchange}>
              <Text bold className={styles.tableboarder}>
                Event Type
              </Text>
            </th>
            <th className={styles.hpadchange}>
              <Text bold className={styles.tableboarder}>
                Interviewers
              </Text>
            </th>{' '}
            <th className={styles.hpadchange}>
              <Text bold className={styles.tableboarder}>
                Organiser
              </Text>
            </th>
            <th className={styles.hpadchange}>
              <Text bold className={styles.tableboarderAction}>
                Actions
              </Text>
            </th>
          </tr>
        </thead>

        <tbody style={{ paddingTop: 20 }} className={styles.tablebody}>
          {list.map((doc, index) => {
            return (
              <>
                <tr style={{ height: 50 }}>
                  <td className={styles.padchanges} style={{}}>
                    <Text className={styles.stBold}>
                      {`${toCamelCase(doc.event_id__event_name)} with ${toCamelCase(doc.candidate_name)}`}
                    </Text>
                  </td>
                  <td className={styles.padchang}>
                    <Flex
                      row
                      top
                    //  className={styles.hellothere}
                    >
                      <Text className={styles.stBold}>{doc.date}</Text>
                    </Flex>
                  </td>
                  <td className={styles.padchanges}>
                    <Text className={styles.stBold}>{doc.time}</Text>
                  </td>
                  <td className={styles.padchanges}>
                    <Text className={styles.stBold}>
                      {doc.event_id__duration}
                    </Text>
                  </td>

                  <td className={styles.padchanges} style={{}}>
                    <Text className={styles.stBold}>
                      {doc.event_id__event_type}
                    </Text>
                  </td>
                  <td className={styles.padchanges} style={{}}>
                    {renderInterviewers(doc.interviewers)}
                  </td>
                  <td className={styles.padchanges} style={{}}>
                    <Text className={styles.stBold}>
                      {doc.event_id__interviewer}
                      {/* {renderOrganiser(doc.organisers)} */}
                      {/* backend  */}
                    </Text>
                  </td>

                  <td className={styles.padchangesmiddle}>
                    {/* <Text className={styles.stBold}> */}
                    <Dropdown className="dropdownButton   dropleft">
                      <CustomTooltip title="Actions">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          disabled={Number(Permission) !== Number(doc.event_id__emp_id) && !JOIN_EVENTS.includes(doc.event_id__event_type)}
                          style={{
                            borderColor: 'unset !important',
                            backgroundColor: 'unset !important',
                            boxShadow: 'none !important',
                          }}
                        >
                          {/* <Flex center middle> */}
                          <SvgDotMenu width={14} height={14} fill="#581845" />
                          {/* </Flex> */}
                        </Dropdown.Toggle>
                      </CustomTooltip>

                      <Dropdown.Menu style={{ minWidth: '5rem' }}>
                        {JOIN_EVENTS.includes(doc.event_id__event_type) && <Dropdown.Item onClick={() => onJoin(doc)}>

                          {/* <Dropdown.Item onClick={() => onJoin(doc)}> */}
                          <Flex row center className={styles.dropDownListStyle}>
                            <Text style={{ marginLeft: 10 }}>Join</Text>
                          </Flex>
                        </Dropdown.Item>}

                        {/* <Dropdown.Item onClick={() => onEdit(doc)}>
                          <Flex row center className={styles.dropDownListStyle}>
                            <Text style={{ marginLeft: 10 }}>Edit</Text>
                          </Flex>
                        </Dropdown.Item> */}
                        {Number(Permission) === Number(doc.event_id__emp_id) && (
                          <Dropdown.Item onClick={() => onDelete(doc)}>
                            <Flex row center className={styles.dropDownListStyle}>
                              <Text style={{ marginLeft: 10 }}>Delete</Text>
                            </Flex>
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* </Text> */}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Flex column style={{ height: '100%' }}>
      {renderTable()}
    </Flex>
  );
};

export default EventSchedulerScreen;
