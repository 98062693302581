import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Button, Dropdown } from 'react-bootstrap';
import { Modal, Flex, Text } from '../../uikit';
import styles from './aimatch.module.css';

const cx = classNames.bind(styles);
type Props = {
  module_update: (val: Boolean) => void;
  aimodule: boolean;
  totalcount?: any;
  compareAi?: any;
  Aiapi_call?: () => void;
  processcandidate?: any;
};
const AimatchModule = ({
  module_update,
  aimodule,
  totalcount,
  compareAi,
  Aiapi_call,
  processcandidate,
}: Props) => {
  const [open, setopen] = useState(false);

  return (
    <Flex>
      <Modal open={aimodule}>
        <Flex className={styles.subscriptionmodule}>
          {processcandidate === 0 ? (
            <>
              <Text>
                The advanced AI matching has already been applied for all the
                selected candidates.
              </Text>
              <Flex
                row
                center
                marginTop={20}
                style={{ justifyContent: 'center' }}
              >
                <Button onClick={() => module_update(false)}>ok</Button>
              </Flex>
            </>
          ) : (
            <>
              <Text>
                {`Out of the ${totalcount} candidates you\'ve chosen, ${compareAi} 
                has already undergone Advanced AI Matching, and the process will 
                now be applied to the remaining ${processcandidate} candidates.`}
              </Text>
              <Flex row end marginTop={20}>
                <Button
                  variant="secondary"
                  style={{ marginRight: '10px' }}
                  onClick={() => module_update(false)}
                >
                  Cancel
                </Button>
                <Button onClick={() => Aiapi_call()}>Continue</Button>
              </Flex>
            </>
          )}
        </Flex>
      </Modal>
    </Flex>
  );
};

export default AimatchModule;
