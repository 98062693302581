import React from 'react';
import Text from '../Text';
const Required = () => {
  return (
    <>
      <Text size={12} color="theme">
        Completion of fields marked with an asterisk (*) is mandatory{' '}
      </Text>
    </>
  );
};

export default Required;
