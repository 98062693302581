const defaultProps = {
  fill: '#581845',
  width: 14,
  height: 14,
};

const SvgArrowLeft = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      width={width}
      height={height}
      cursor={'pointer'}
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.99984 0L4.93317 0.966667L2.5665 3.33333L10.6665 3.33333V4.66667L2.5665 4.66667L4.93317
       7.03333L3.99984 8L-0.000163078 4L3.99984 0Z"
        fill={fill}
      />
    </svg>
  );
};
SvgArrowLeft.defaultProps = defaultProps;
export default SvgArrowLeft;
