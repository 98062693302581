import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ProgressBar from '@ramonak/react-progress-bar';
import { useMediaQuery } from 'react-responsive';
import { Bothcandidateidjobid } from '../../routes/apiRoutes';
import { APPLICANT_PROFILE_MATCH } from '../../actions/actions';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import { Card, Modal, Toast } from '../../uikit';
import SvgDone from '../../icons/SvgDone';
import SvgClose from '../../icons/Svgnotmatch';
import { GARY_7, WHITE } from '../../uikit/Colors/colors';

import { AppDispatch, RootState } from '../../store';
import Tab from '../../uikit/Tabs/Tab';
import Blur from '../../uikit/Blur/blur';
import { Loader } from '../../uikit';
import { Button, LinkWrapper } from '../../uikit';
import Tabs from '../../uikit/Tabs/Tabs'; 
import SvgAngle from '../../icons/SvgAngle';
import SvgNoData from '../../icons/SvgNoData';
import SvgModuleicon from '../../icons/SvgModuleicon';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { config } from '../constValue';
import { removeUnderScores, lowerCase } from '../../uikit/helper';
import {
  WeightagematchinggetMiddleWare,
  WeightagematchingpostMiddleWare,
} from '../createjdmodule/store/middleware/createjdmiddleware';
import SvgUpArrow from '../../icons/SvgArrowUp';
import SvgArrowDown1 from '../../icons/SvgArrowDown1';
import {
  MatchArray,
  MatchJobArray,
  MatchLocationArray,
  WithoutArray,
  WithoutJobArray,
  WithoutLocationArray,
} from './matchAnalysisTab';
import styles from './matchinganalysistab.module.css';
import AllMatchTab from './AllMatchTab';
import {
  CandidatejobidMatchMiddleWare,
  WeightagematchingMiddleWare,
  applicantMatchMiddleWare,
} from './store/middleware/applicantProfileMiddleware';
import MatchingAnalysisbasicTab from './matchinganalysisbasic';
import Matchinganalysisweightage from './matchinganalysisweightage';
import Matchingdummydata from './matchingdummydata';
export interface DateEntity {
  not_matched_skills: [];
  not_matched_qualification: [];
}
export type MyJobFormProps = {
  Notmatch: DateEntity;
  data: string;
  // postedOn: DateEntity;
  // jobType: string;
  // location: string;
};
type Props = {
  updatr_overall?: (val: any) => void;
  };
const MatchingAnalysisTab = ({ updatr_overall }: Props) => {
  const [isloading, setloading] = useState(false);
  const [changingtab, setchangingtab] = useState(false);
  const [iscandidatesai, setcandidatesai] = useState<any>(null);
  const [technicalres,settechnicalres]=useState([])
  const [nontechnicalres,setnontechnicalres]=useState([])
  const [overallres,setoverallres]=useState(0);
  const [overallnontechres,setoverallnontechres]=useState(0);
  const [status,setstatus]=useState(false)
    const dispatch: AppDispatch = useDispatch();
  const {
    ai_matching,
    jd_id,
    can_id,
    ai_matching_plan,
    current_plan,
    active_resume,
    Permission,
    isLoading,
    apply_match,
    blocked_resume,
    candidates_ai,
    block_descriptive,
  } = useSelector(
    ({
      applicantProfileInitalReducers,
      candidatejdmatchReducers,
      weightageReducers,
      permissionReducers,
      SubscriptionReducers,
    }: RootState) => {
      return {
        can_id: applicantProfileInitalReducers.can_id,
        jd_id: applicantProfileInitalReducers?.jd_id,
        isLoading: candidatejdmatchReducers.isLoading,
        ai_matching: candidatejdmatchReducers?.ai_matching,
        ai_matching_plan: candidatejdmatchReducers.ai_matching_plan,
        notmatchedlocation:
          candidatejdmatchReducers.not_matched_data?.not_matched_location,
        location: candidatejdmatchReducers.source.jd_location,
        location_percent: candidatejdmatchReducers.location_percent,
        success: weightageReducers.success,
        non_tech: weightageReducers.non_tech,
        current_plan: SubscriptionReducers.current_plan,
        tech: weightageReducers.tech_skills,
        outputnontech: candidatejdmatchReducers.non_technical,
        outputtech: candidatejdmatchReducers.technical,
        active_resume: candidatejdmatchReducers.active_resume,
        Permission: permissionReducers.data,
        apply_match: candidatejdmatchReducers.apply_match,
        blocked_resume: candidatejdmatchReducers.blocked_resume,
        candidates_ai: candidatejdmatchReducers.candidates_ai,
        block_descriptive: candidatejdmatchReducers.block_descriptive,
      };
    },
  );

  const [innerTab, setInnerTab] = useState(0); // Default to 0 if no value is found

  useEffect(() => {
    const storedTab = localStorage.getItem('innernavigationtab');
    if (storedTab !== null) {
      setInnerTab(Number(storedTab)); // Set to the value from localStorage if it exists
    } else {
      setInnerTab(0); // Set to 0 if nothing is found
    }
  }, []);

  useEffect(() => {
        axios.get(Bothcandidateidjobid, {
      params: {
        jd_id,
        can_id
      }
    }).then((res) => { 
      setcandidatesai(res.data?.candidates_ai)
      settechnicalres(res.data?.technical)
      setnontechnicalres(res.data?.non_technical)
      setoverallres(res.data?.overall_percentage)
      setoverallnontechres(res.data?.non_tech_percentage)
      setstatus(res.data?.status)
      updatr_overall(res.data?.overall_percentage)
    })
  }, [])

  
  const dispatchhandling = () => {
    setloading(true);
    setchangingtab(true);
    axios.get(Bothcandidateidjobid, {
      params: {
        jd_id,
        can_id,
        matching: true
      }
    }).then((datas) => { 
      setcandidatesai(datas?.data?.candidates_ai)
      settechnicalres(datas.data?.technical)
      setnontechnicalres(datas.data?.non_technical)
      setoverallres(datas.data?.overall_percentage)
      setoverallnontechres(datas.data?.non_tech_percentage)
      updatr_overall(datas.data?.overall_percentage)
      setstatus(datas.data?.status)
      setloading(false)
    })
  }
  if (iscandidatesai === null || isloading) {
    return <Loader />;
  }


  return (
    <>
      {isloading && <Loader />}
      {block_descriptive === true ? (
        <>
          <Tabs>
            <Tab title="Score Analysis">
              <Matchingdummydata />
            </Tab>
            <Tab title="All Matching Jobs">
              <Flex flex={6.4}>
                <AllMatchTab
                  title={''}
                  inviteMessage={''}
                  align_width={false}
                />
              </Flex>
            </Tab>
          </Tabs>
                  </>
      ) : (
        <>
          {!blocked_resume.toString().includes(can_id.toString()) &&
          apply_match ? (
            <Tabs active={innerTab}>
              <Tab title="Score Analysis">
                <Matchinganalysisweightage
                  updatr_overall={updatr_overall}
                  technicalres={technicalres}
                  nontechnicalres={nontechnicalres}
                  overallres={overallres}
                  overallnontechres={overallnontechres}
                  status={status}
                />
              </Tab>
              <Tab title="All Matching Jobs">
                <Flex flex={6.4}>
                  <AllMatchTab
                    title={''}
                    inviteMessage={''}
                    align_width={false}
                  />
                                   </Flex>
              </Tab>
            </Tabs>
          ) : (
            <>
              {blocked_resume.toString().includes(can_id.toString()) &&
              apply_match ? (
                <Tabs>
                  <Tab title="Score Analysis">
                    <Matchingdummydata />
                  </Tab>
                  <Tab title="All Matching Jobs">
                    <Flex flex={6.4}>
                      <AllMatchTab
                        title={''}
                        inviteMessage={''}
                        align_width={false}
                      />
                                                      </Flex>
                  </Tab>
                </Tabs>
              ) : iscandidatesai && apply_match === false ? (
                <Tabs active={innerTab}>
                  <Tab title="Score Analysis">
                    <Matchinganalysisweightage
                      updatr_overall={updatr_overall}
                      technicalres={technicalres}
                      nontechnicalres={nontechnicalres}
                      overallres={overallres}
                      overallnontechres={overallnontechres}
                      status={status}
                    />
                  </Tab>
                  <Tab title="All Matching Jobs">
                    <Flex flex={6.4}>
                      <AllMatchTab
                        title={''}
                        inviteMessage={''}
                        align_width={false}
                      />
                                                      </Flex>
                  </Tab>
                </Tabs>
              ) : (
                <Flex row flex={12}>
                  <Flex flex={6}>
                    <MatchingAnalysisbasicTab
                      updatr_overall={updatr_overall}
                      dispatchhandling={() => dispatchhandling()}
                    />
                  </Flex>
                  <Flex
                    height={window.innerHeight - 115}
                    style={{
                      border: '0.3px solid #C3C3C3',
                      width: '0.5px',
                      margin: '15px 5px 10px 5px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                    }}
                  ></Flex>
                  <Flex flex={6.4}>
                    <AllMatchTab
                      title={''}
                      inviteMessage={''}
                      align_width={true}
                    />
                  </Flex>
                                  </Flex>
                   )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default MatchingAnalysisTab;
