import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AlignTop } from 'react-bootstrap-icons';
import { RootState } from '../../../store';
import { Button, LinkWrapper } from '../../../uikit';
import Card from '../../../uikit/Card/Card';
import Flex from '../../../uikit/Flex/Flex';
import SvgInfinity from '../../../icons/SvgInfinity';
import SingleButton from '../../common/SingleButton';
import SvgSubcriptioncrown from '../../../icons/Subscriptioncrown';
import Text from '../../../uikit/Text/Text';
import SvgDoublestar from '../../../icons/Svgdoublestar';
import styles from './addondetails.module.css';

const AddonDetails = () => {
  const [isNoPermission, setNoPermission] = useState(false);
  const { addon, expire_status, super_user } = useSelector(
    ({
      dashboardEmpReducers,
      SubscriptionReducers,
      permissionReducers,
    }: RootState) => {
      return {
        addon: dashboardEmpReducers.addon,
        expire_status: dashboardEmpReducers.expire_status,
        super_user: permissionReducers.super_user,
      };
    },
  );

  return (
    <Flex>
      <SingleButton
        btnTitle="OK"
        title={'Please contact your company admin to purchase.'}
        open={isNoPermission}
        btnOnclick={() => setNoPermission(false)}
      />
      {addon.length !== 0 && (
        <Card className={styles.jobActivityCard}>
          <Flex className={styles.overall} row>
            <Text bold size={14} className={styles.borderBottom}>
              Available Credits and Add-On Details
            </Text>
            <Flex marginTop={7}>
              <SvgDoublestar height={20} width={20} />
            </Flex>
          </Flex>
          {/* <Flex className={styles.scroll} row wrap>
          {Array.from({ length: Math.ceil(addon.length / 2) }).map((_, rowIndex) => (
            <Flex key={rowIndex} style={{ marginBottom: '10px' }}>
              {addon.slice(rowIndex * 2, (rowIndex + 1) * 2).map((data, i) => (
                <Flex key={i} style={{ margin: '10px', flex: 1 }} >
                  <Card className={styles.card}>
                    <Flex>
                      <Text>
                        {data.feature_id__feature_name}: {data.usage}
                      </Text>
                    </Flex>
                  </Card>
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex> */}
          {expire_status === false ? (
            <div className={styles.gridContainer}>
              {addon.map((data, i) => (
                <div key={i} className={styles.gridItem}>
                  <Flex className={styles.box}>
                    <Flex between row>
                      <Text className={styles.title}>{data.name}</Text>
                      <Text>
                        {data.usage === 'Unlimited' ? (
                          <Text
                            style={{
                              backgroundColor: '#eee8ec',
                              borderRadius: '20%',
                              padding: '5px',
                            }}
                          >
                            {/* <SvgInfinity width={16} height={16} /> */}{' '}
                            Unlimited
                          </Text>
                        ) : (
                          <Text
                            style={{
                              backgroundColor: '#eee8ec',
                              borderRadius: '20%',
                              padding: '5px',
                            }}
                          >
                            {data.usage}
                          </Text>
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                </div>
              ))}
            </div>
          ) : super_user === false ? (
            <Flex style={{ justifyContent: 'center', margin: '10px' }}>
              <Flex>
                <Text>
                  Your subscription has reached its expiration. To keep
                  accessing the full range of features, consider opting for an
                  upgrade
                </Text>
              </Flex>
              <Flex style={{ justifyContent: 'center', padding: '5px' }}>
                <Button onClick={() => setNoPermission(true)}>
                  <Flex row>
                    <Text color="white">Upgrade</Text>
                    <Flex marginLeft={5} marginBottom={1}>
                      <SvgSubcriptioncrown />
                    </Flex>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex style={{ justifyContent: 'center', margin: '10px' }}>
              <Flex>
                <Text>
                  Your subscription has reached its expiration. To keep
                  accessing the full range of features, consider opting for an
                  upgrade
                </Text>
              </Flex>
              <Flex style={{ justifyContent: 'center', padding: '5px' }}>
                <LinkWrapper to={'/account_setting/subscription'}>
                  <Button>
                    <Flex row>
                      <Text color="white">Upgrade</Text>
                      <Flex marginLeft={5} marginBottom={1}>
                        <SvgSubcriptioncrown />
                      </Flex>
                    </Flex>
                  </Button>
                </LinkWrapper>
              </Flex>
            </Flex>
          )}
        </Card>
      )}
    </Flex>
  );
};

export default AddonDetails;
