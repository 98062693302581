import { toCamelCase } from '../../uikit/helper';
import { Flex } from '../../uikit';
const InterviewerIcon = ({ name, initials, index, title }: { name: string; index?: number, initials?: string, title?: string }) => {
  const colors = [
    '#58BE4C',
    '#468FD0',
    '#F38961',
    '#CF6FEE',
    '#6FE2EE',
    '#5EF255',
    '#419697',
    '#E166F0',
    '#3F74D1',
    '#E5E35A',
  ];

  return (
    <Flex title={toCamelCase(title || initials || name)}>
      <p
        data-letters={
          initials || (name ? (name[0] || '').toUpperCase() + (name.split(' ')[1][0] || '').toUpperCase() : '')}
        style={{
          backgroundColor: colors[index !== undefined ? index + 1 : 0],
          borderRadius: '50%',
          // height: '1%',
          marginRight: '1%',
          marginLeft: "5px"
        }}
      />
    </Flex>
  );
};

export default InterviewerIcon;
