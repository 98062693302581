const SvgVector = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={18}
    fill="none"
    viewBox="0 0 10 18"
    {...props}
  >
    <path
      fill={props.fill}
      d="M4.583 17.334c-1.277 0-2.36-.445-3.25-1.334C.444 15.111 0 14.028 0 12.75V4C0 3.084.326 2.3.98 1.646A3.21 3.21 
      0 0 1 3.332.667a3.21 3.21 0 0 1 2.354.98A3.21 3.21 0 0 1 6.668 4v7.917c0 .583-.202 1.076-.604 1.48a2.012 2.012 
      0 0 1-1.48.603 2.012 2.012 0 0 1-1.479-.604 2.012 2.012 
      0 0 1-.604-1.479V4h1.25v7.917c0 .236.08.434.24.593.16.16.357.24.593.24s.434-.08.595-.24c.159-.16.239-.357.239-.593V4c0-.583-.202-1.076-.604-1.479a2.012 2.012 
      0 0 0-1.48-.604c-.583 0-1.076.201-1.479.604a2.012 2.012 0 0 0-.604 1.48v8.75c0 .916.326 1.7.98 2.354a3.21 3.21 
      0 0 0 2.353.979 3.21 3.21 0 0 0 2.354-.98 3.21 3.21 0 0 0 .98-2.354V4h1.25v8.75c0 1.278-.445 2.361-1.334 3.25-.889.89-1.972 1.334-3.25 1.334Z"
    />
  </svg>
);
export default SvgVector;