import React from 'react';
import { Button, Flex, Text } from '../../uikit';
import SvgHelp from '../../icons/SvgHelp';
import SvgHelpTour from '../../icons/SvgHelpTour';
import styles from './tour.module.css'



type Props = {
    text?: any;
    right?: any;
    left?: any;
    top?: any;
    icon?: any;
}

const Highlighter = ({
    text,
    right,
    left,
    top,
    icon,
}: Props) => {


    return (
        <>
            {icon ? (
                <Flex className={styles.iconcss} style={{ right: right, left: left, top: top, }}>
                    <SvgHelpTour fill={'#581845'} height={22} width={22} />
                    {/* <SvgHelp fill={'#581845'} height={22} width={22} /> */}
                </Flex>
            ) : (
                <Flex className={styles.duplicatesidebar} style={{ right: right, left: left, top: top, }}>
                    <Text style={{ padding: "1px 0px 1px 14px", color: '#581845' }}>
                        {text}
                    </Text>
                </Flex>
            )
            }

        </>
    );
};

export default Highlighter;
