import SvgCheckedCircle from '../../../icons/SvgCheckedCircle';
import { Flex, InputCheckBox } from '../../../uikit';
import style from './peopleCheckbox.module.css';


interface Props {
  checked: boolean;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  title?: string;
  tooltip_distance?: number;
}

const PeopleCheckbox = ({
  checked,
  label,
  onClick,
  disabled = false,
  title,
  tooltip_distance
}: Props) => {
  return (
    <>
      <Flex row center>
        <Flex className={style.checkbox} title={title && title}>
          <InputCheckBox
            checked={checked}
            onChange={() => {
              if (!disabled) onClick();
            }}
          />
        </Flex>
        <Flex className={style.checkboxContainer}> 
        <span className={style.label}>
          {label}
        </span>
      </Flex>
    </Flex>
    </>
  );
};

export default PeopleCheckbox;
