import * as React from 'react';
const SvgTemplatesHub = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    viewBox='0 0 25 25'
    {...props}
  >
    <path
      fill="#581845"
      d="M22.917 2.083V6.25H2.083V2.083zm0-2.083H2.083A2.083 2.083 0 0 0 0 2.083V6.25a2.083 2.083 0 0 0 2.083 2.083h20.834A2.083 2.083 0 0 0 25 6.25V2.083A2.083 2.083 0 0 0 22.917 0M6.25 12.5v10.417H2.083V12.5zm0-2.083H2.083A2.083 2.083 0 0 0 0 12.5v10.417A2.083 2.083 0 0 0 2.083 25H6.25a2.083 2.083 0 0 0 2.083-2.083V12.5a2.083 2.083 0 0 0-2.083-2.083M22.917 12.5v10.417H12.5V12.5zm0-2.083H12.5a2.083 2.083 0 0 0-2.083 2.083v10.417A2.083 2.083 0 0 0 12.5 25h10.417A2.083 2.083 0 0 0 25 22.917V12.5a2.083 2.083 0 0 0-2.083-2.083"
    />
  </svg>
);
export default SvgTemplatesHub;