import React from 'react';

const SvgRocket = () => {
    return (
        <>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2_185)">
                    <path d="M13.3735 26.6265C9.27195 22.5249 4.11594 30.0283 4.11594 30.0283L6.03758 29.4398L1.84297 34.3852L4.60477 33.6428L0 39.9999L6.35711 35.3952L5.61477 38.1569L10.56 33.9623L9.97156 35.884C9.97156 35.884 17.4751 30.728 13.3735 26.6265Z" fill="#F9D026" />
                    <path d="M13.3735 26.6265L0 39.9999L6.35711 35.3952L5.61477 38.1569L10.56 33.9623L9.97156 35.884C9.97156 35.884 17.4751 30.728 13.3735 26.6265Z" fill="#E7C224" />
                    <path d="M14.3138 17.4091L4.20361 15.7051L9.51518 10.3936L19.6253 12.0976L14.3138 17.4091Z" fill="#F74D37" />
                    <path d="M22.5908 25.6863L24.2949 35.7965L29.6064 30.4849L27.9024 20.3748L22.5908 25.6863Z" fill="#C43D2C" />
                    <path d="M36.3616 12.4912C39.6995 6.73882 40 0.000386963 40 0.000152588C40 0.000152588 33.2614 0.300387 27.509 3.63843C26.1814 4.4089 24.9065 5.34086 23.7788 6.46851C23.7788 6.46851 13.1558 17.0915 11.258 18.9893C9.36013 20.8872 9.3127 24.3508 12.4811 27.5191C15.6494 30.6875 19.1129 30.64 21.0109 28.7422C22.9087 26.8444 33.5317 16.2214 33.5317 16.2214C34.6592 15.0937 35.5913 13.8187 36.3616 12.4912Z" fill="#EDEEF0" />
                    <path d="M36.3617 12.4912C39.6996 6.73882 40.0001 0.000386963 40.0001 0.000152588L31.9354 8.06484L16.1346 23.8657L12.4812 27.5191C15.6496 30.6874 19.1131 30.6399 21.011 28.7421C22.9089 26.8443 33.5318 16.2213 33.5318 16.2213C34.6593 15.0937 35.5914 13.8187 36.3617 12.4912Z" fill="#DCDEE2" />
                    <path d="M40 0.000152588C40 0.000152588 33.2615 0.300387 27.509 3.63843L31.9354 8.06476L36.3617 12.4911C39.6996 6.73882 40 0.000386963 40 0.000152588Z" fill="#F74D37" />
                    <path d="M16.1344 23.8657L11.258 18.9893C9.36013 20.8872 9.3127 24.3508 12.4811 27.5191C15.6494 30.6875 19.1129 30.64 21.0109 28.7422L16.1344 23.8657Z" fill="#444242" />
                    <path d="M21.0107 28.7421L16.1343 23.8657L12.481 27.5191C15.6492 30.6874 19.1129 30.6399 21.0107 28.7421Z" fill="#3A3839" />
                    <path d="M26.9479 16.2369C28.7067 16.2369 30.1326 14.811 30.1326 13.0522C30.1326 11.2933 28.7067 9.86749 26.9479 9.86749C25.189 9.86749 23.7632 11.2933 23.7632 13.0522C23.7632 14.811 25.189 16.2369 26.9479 16.2369Z" fill="#BCE8F7" />
                    <path d="M21.3319 18.668L13.0637 24.5651L7.16666 32.8334L7.1665 32.8334L15.4349 26.9364L21.3319 18.668Z" fill="#F74D37" />
                    <path d="M15.4349 26.9364L21.3319 18.668L7.16666 32.8334L7.1665 32.8334L15.4349 26.9364Z" fill="#C43D2C" />
                    <path d="M40 7.62939e-05L31.9353 8.06476L36.3616 12.4911C39.6995 6.73883 40 0.000388794 40 7.62939e-05Z" fill="#C43D2C" />
                    <path d="M29.1996 15.3042C30.4433 14.0605 30.4433 12.0441 29.1996 10.8004L24.6958 15.3042C25.9394 16.548 27.9559 16.548 29.1996 15.3042Z" fill="#8EC1D6" />
                </g>
                <defs>
                    <clipPath id="clip0_2_185">
                        <rect width="40" height="40" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default SvgRocket;

