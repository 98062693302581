import moment from 'moment-timezone';

export const eventType = [
    { value: '1', label: 'On-site Interview' },
    { value: '2', label: 'Phone Interview' },
    // { value: '3', label: 'Microsoft Teams' },
    // { value: '4', label: 'Google Hangouts/Meet' },
];

export const eventTypeData = [
  { value: '1', label: 'On-site Interview' },
  { value: '2', label: 'Phone Interview' },
  { value: '3', label: 'Microsoft Teams' },
  { value: '4', label: 'Google Hangouts/Meet' },
];

export const days = [
  { value: '1', label: 'Calendar Days' },
  { value: '2', label: 'Week Days' },
];


// const durationFieldType = () => {
const durationField = []
const totalMinutesIn12Hours = 8 * 60; // 12 hours * 60 minutes/hour
const intervalMinutes = 15; // 15 minutes interval
const intervals = Math.floor(totalMinutesIn12Hours / intervalMinutes);

for (let i = 1; i <= intervals; i++) {
  const hours = Math.floor((intervalMinutes * i) / 60);
  const minutes = (intervalMinutes * i) % 60;
  durationField.push({
    value: String(i + 4), // Start with value '4' to avoid conflicts
    label: `${hours > 0 ? hours + ' hour' + (hours > 1 ? 's' : '') : ''}${hours > 0 && minutes > 0 ? ' ' : ''}${minutes > 0 ? minutes + ' minutes' : ''}`
  });
}
// }





export const duration = durationField


const getTimezones = () => {
  return moment.tz.names().map((time, index) => {
    const offset = moment.tz(time).format('Z');
    const value = index
    return {
      offset,
      value: value,
      label: `${moment.tz(time).format('Z')} (${time})`,
      timezone : time
    };
  });
};

export const timezonesdata = getTimezones();




export const timezonedisplay = [
  { value: "1", label: "Automatically detect and show the times in my invitee's time zone" },
  { value: "2", label: "Lock the timezone (best for in-person events)" }
]

function getTimeInMinutes(timeString) {
  const [time, period] = timeString.split(' ');
  const [hours, minutes] = time.split(':');
  const intHours = parseInt(hours, 10);
  const intMinutes = parseInt(minutes, 10);
  const convertedHours = (period === 'PM' && intHours !== 12) ? intHours % 12 + 12 : (period === 'AM' && intHours === 12) ? 0 : intHours;
  return convertedHours * 60 + intMinutes;

}



const timeToMinutes = (timeString: any) => {
  if (timeString !== undefined && timeString !== null && timeString !== "") {
    let totalMinutes = getTimeInMinutes(timeString)
    return totalMinutes;
  } else {
    return null
  }
}

const extractNumberFromString = (text) => {
  const match = text.match(/\d+/);
  let unit = text.includes('hour') || text.includes('hours') ? 60 : text.includes('minutes') ? 1 : null;
  if (unit === null){
    unit = 1
  }
  return match && unit ? parseInt(match[0], 10) * unit : null;
};


const splitDuration = (durationString) => {
  const matches = durationString.match(/(\d+)\s*hour[s]*\s*(\d+)\s*minute[s]*/i);
  if (!matches) {
    const matching = extractNumberFromString(durationString) 
    return { matching }
  }
  const hours = parseInt(matches[1], 10) || 0;
  const minutes = parseInt(matches[2], 10) || 0;

  return { hours, minutes };
};

export const generateIntervals = (day, durationstring) => { 
  if (durationstring !== null && durationstring !== undefined && day !== null && day !== undefined) {
    const startTime = timeToMinutes(day.starttime);
    const endTime = timeToMinutes(day.endtime); 
    const newdata = splitDuration(durationstring);
    const intervalsdata = []; 
    if (startTime !== null && endTime !== null) {
      if (newdata !== null) {
        if (Object.keys(newdata).length > 1) {
          const intervalHours = Number(newdata.hours);
          const intervalMinute = Number(newdata.minutes);
          for (let i = startTime + intervalHours* 60 + intervalMinute; i <= endTime; i += intervalHours * 60) {
            intervalsdata.push(i);
          }
          return intervalsdata.length > 0 ? false : true;
        } else {
          if(newdata?.matching !== null){
            let intervalHou = Number(newdata.matching);
            for (let i = startTime+intervalHou; i <= endTime; i += intervalHou) {
              intervalsdata.push(i);
            }
            return intervalsdata.length > 0 ? false : true;
          }
        }
      }
    }
    else {
      return null
    }
  };
}



export function checkIntervalOverlap(interval1, interval2) {
  const start1 = new Date('1970-01-01 ' + interval1.starttime);
  const end1 = new Date('1970-01-01 ' + interval1.endtime);
  
  const start2 = new Date('1970-01-01 ' + interval2.starttime);
  const end2 = new Date('1970-01-01 ' + interval2.endtime);
  if(interval2.starttime !== ''){
    if (start1.getTime() === start2.getTime()) {
      return false;
    }
    if (end1.getTime() === end2.getTime()) {
      return false;
    }
    if (start1 < start2 && start2 < end1) {
      return true;
    }
    if (start1 < start2 && start2 < end1 && start2 < end2) {
      return true;
    }
    if (start1 > start2) {
      return false;
    }
  }
  return null
}


export function endDaytimes(text,day,index,time){
  let newArray = time
  if(text === 'endtime'){
    let dayfind = day[index].starttime
    const indexToRemove = time.findIndex(item => item.label === dayfind);
    if (indexToRemove !== -1 && indexToRemove < time.length - 1) {
      newArray = time.slice(indexToRemove + 1);
      return newArray
    }
  }
  return newArray
}
function IntervelChecking(currentTime,interval){
  const [startTime, endTime] = interval.split(' - ');
  const isWithinInterval = (currentTime >= startTime && currentTime <= endTime);
  if (isWithinInterval) {
    return true
  } else {
    return false
  }
}

export const removeCurrentTime = (timeRange, currentTime, append) => {
  const [startTime, endTime] = timeRange.split(' - ');
  if (append !== true){
    if(IntervelChecking(currentTime,timeRange)){
      append = true
    }
  }
    // append = true

  if (isTimeGreaterThan(endTime, currentTime)) {
    return { result: true, append: append };
  } else {
    return { result: false, append: append };
  }
};


const isTimeGreaterThan = (time1, time2) => {
  // Convert times to minutes since midnight
  const toMinutes = (time) => {
    const [hours, minutes, period] = time.split(/:| /);
    let totalMinutes = parseInt(hours) * 60 + parseInt(minutes);

    // Adjust for PM
    if (period.toLowerCase() === 'pm') {
      totalMinutes += 12 * 60;
    }

    return totalMinutes;
  };

  const minutes1 = toMinutes(time1);
  const minutes2 = toMinutes(time2);
  return minutes1 > minutes2;
};