import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SvgEdit } from '../../../../icons';
import SvgDelete from '../../../../icons/SvgDelete';
import {
    Button,
    Text,
    Flex,
    Toast,
    LinkWrapper,
    Loader,
} from '../../../../uikit';

import { AppDispatch, RootState } from '../../../../store';
import {
    emailtemplatesdeleteMiddleWare,
    emailtemplatesgetMiddleWare,
} from '../../store/middleware/accountsettingmiddleware';
import Modal from '../../../../uikit/v2/Modal/Modal';
import SvgNoData from '../../../../icons/SvgNoData';
import SvgAdd from '../../../../icons/SvgAdd';
import styles from './table.module.css';
// import TemplateDescriptionmodal from './templatedescriptionModal';
// import Emailopenmodal from './emailopenModal';

type props = {
    filteredData?: any;
    onDeletefunction?: (val?: any) => void;
    oneditfunction?: (val?: any) => void;
    openviewfun?: (val?: any) => void;
};

const Table = ({
    filteredData,
    onDeletefunction,
    oneditfunction,
    openviewfun
}: props) => {
    const dispatch: AppDispatch = useDispatch();

    const { data, first_name, last_name } = useSelector(({ emailTemplateReducers, userProfileReducers }: RootState) => {
        return {
            data: emailTemplateReducers.data,
            first_name: userProfileReducers.user.first_name,
            last_name: userProfileReducers.user.last_name

        };
    });


    // getting user fullname
    const gettingname = () => {
        if (last_name) {
            const name = first_name + " " + last_name
            return name
        }
        else {
            const name = first_name
            return name
        }

    }

    function formatDate(dateString: string | number | Date) {
        const date = new Date(dateString);
        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        const day = isNaN(date.getDate()) ? '' : date.getDate();
        const month = isNaN(date.getMonth()) ? '' : months[date.getMonth()];
        const year = isNaN(date.getFullYear()) ? '' : date.getFullYear();

        return `${month} ${day}, ${year}`;
    }


    const parser = new DOMParser();
    const handlemessage = (values) => {
        const doc = parser.parseFromString(values, 'text/html');
        const { children } = doc.body;
        for (let i = 0; i < children.length; i++) {
            const { textContent } = children[i];
            const trimmedContent = textContent.trim();

            // Check if the text content has more than 10 words
            if (trimmedContent.split(/\s+/).length > 6) {
                return trimmedContent;
            }
        }
        return '';
    };

    // css for table over all div
    const tableContainerStyle: React.CSSProperties = {
        maxHeight: 'calc(100vh - 260px)',
        overflowY: 'auto',
    };

    // css for table 
    const tableStyle: React.CSSProperties = {
        width: '100%',
        borderCollapse: 'collapse' as 'collapse',
        paddingLeft: 'none',
        marginBottom: '0rem'
    };

    // css for table header row 
    const stickyHeaderStyle: React.CSSProperties = {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 2
    };

    return (
        <Flex
            className="table-responsisssve "
            style={{ overflowY: 'scroll', display: 'flex' }}
            height={window.innerHeight - 260}
        >
            <Flex>
                <div style={tableContainerStyle}>
                    <table
                        className="table"
                        style={tableStyle}
                    >
                        <thead>
                            <tr style={stickyHeaderStyle}>
                                <th
                                    className={styles.padchange}
                                    style={{ width: '20%', borderBottom: '0', borderTop: '0' }}
                                >
                                    <Text color="theme" bold className={styles.tabborder1}>
                                        Template Title
                                    </Text>
                                </th>
                                <th
                                    className={styles.padchange}
                                    style={{ width: '30%', borderBottom: '0', borderTop: '0' }}
                                >
                                    <Text color="theme" bold className={styles.tabborder1}>
                                        Subject
                                    </Text>
                                </th>
                                <th
                                    className={styles.padchange}
                                    style={{ width: '12%', borderBottom: '0', borderTop: '0' }}
                                >
                                    <Text color="theme" bold className={styles.tabborder1}>
                                        Created By
                                    </Text>
                                </th>
                                <th
                                    className={styles.padchange}
                                    style={{ width: '10%', borderBottom: '0', borderTop: '0' }}
                                >
                                    <Text color="theme" bold className={styles.tabborder1}>
                                        Created On
                                    </Text>
                                </th>
                                <th
                                    className={styles.padchange}
                                    style={{ width: '6%', borderBottom: '0', borderTop: '0' }}
                                >
                                    <Text color="theme" bold className={styles.tabborder2}>
                                        Actions
                                    </Text>
                                </th>
                            </tr>
                        </thead>
                        {filteredData && filteredData !== undefined &&
                            filteredData.length > 0 &&
                            filteredData.slice().reverse().map((template) => (
                                <tbody
                                    key={template.id}
                                    style={{ paddingTop: 20 }}
                                    className={styles.tablebody}
                                    id={template.id?.toString()}
                                >
                                    <tr id={template.id?.toString()}>
                                        <td className={styles.padchang} style={{ borderTop: '0' }}>
                                            <Flex
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => openviewfun(template)}
                                            >
                                                <Text bold color="theme" className={styles.pointer}>
                                                    {template.job_title}
                                                </Text>
                                            </Flex>
                                        </td>
                                        <td style={{ borderTop: '0' }}>
                                            <Flex className={styles.linetruncate}>{handlemessage(template.job_description)}</Flex>

                                        </td>
                                        <td className={styles.padchang} style={{ borderTop: '0' }}>
                                            <Flex className={styles.pointer}>
                                                <Text title={template.user_id === null ? "Zita" : gettingname()}>
                                                    {template.user_id === null ? "Zita" : gettingname()}
                                                </Text>
                                            </Flex>
                                        </td>
                                        <td className={styles.padchang} style={{ borderTop: '0' }}>
                                            {formatDate(template.created_on)}
                                        </td>

                                        <td className={styles.padchang} style={{ borderTop: '0' }}>
                                            {template.name !== '' &&
                                                template.subject !== '' &&
                                                template.full_name !== '' &&
                                                template.created_on !== '' ? (
                                                <>
                                                    <Flex row className={styles.actionBtnContainer}>
                                                        <Flex
                                                            onClick={() => template.user_id !== null && oneditfunction(template)}
                                                            style={{ cursor: template.user_id === null ? 'default' : 'pointer' }}
                                                            title={template.user_id === null && 'Default templates cannot be modified.'}
                                                        >
                                                            <SvgEdit
                                                                width={12}
                                                                height={12}
                                                                fill={
                                                                    template.user_id === null
                                                                        ? '#58184570'
                                                                        : '#581845'
                                                                }
                                                            />
                                                        </Flex>
                                                        <Flex
                                                            title={template.user_id === null && 'Default templates cannot be deleted.'}
                                                            onClick={() => template.user_id !== null && onDeletefunction(template)}
                                                            style={{ cursor: template.user_id === null ? 'default' : 'pointer' }}
                                                        >
                                                            <SvgDelete
                                                                width={16}
                                                                height={16}
                                                                fill={
                                                                    template.user_id === null
                                                                        ? '#58184570'
                                                                        : '#581845'
                                                                }
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                    </table>
                </div>
            </Flex>
        </Flex>
    );
};

export default Table;
