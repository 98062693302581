import { useState, useEffect } from 'react';
import Modal from '../../uikit/Modal/Modal';
import { Flex, Text } from '../../uikit';
import Button from '../../uikit/Button/Button';
import styles from './stage.module.css';
type Props = {
  Stages?: any;
  cancel: (a?: any) => void;
  bulk_move?: () => void;
  Total?: any;
  Truecount?: any;
  Falsecount?: any;
  check;
};

const Statusmodule = ({
  Stages,
  cancel,
  check,
  bulk_move,
  Total,
  Truecount,
  Falsecount,
}: Props) => {
  return (
    <div>
      <Modal open={Stages}>
        <Flex className={styles.overAll}>
          {check === 'bulk' ? (
            <>
              <Text style={{ marginTop: '8px' }}>
                {/* {`Out of the ${Total} applicants selected, ${Truecount} have a 'Completed' status.
  Only these completed applicants will be advanced to the next
  stage. Please update the status to 'Completed' for the remaining
   ${Falsecount} applicants and then move them separately.`} */}

                {/* {`Out of the ${Total} applicants selected, ${Truecount} have a 'Completed' status.
  Only these completed applicants will be advanced to the next
  stage. Please update the status to 'Completed' for the remaining
   ${Falsecount} applicants and then move them separately.`} */}

                Out of the {Total} selected applicants, {Truecount} has already achieved a &apos;Completed&apos; status. 
                Only applicants with this status will proceed to the next stage. 
                Please ensure that the remaining applicant&apos;s status is updated 
                to &apos;Completed&apos; and then advance them separately.

                </Text>
                  <Flex row end marginTop={16}>
                    <Button
                      types="close"
                      textSize={13}
                      onClick={() => cancel()}
                      className={styles.btnCancelStyle}
                    >
                      Cancel
                    </Button>
                    <Button
                      types="primary"
                      textSize={13}
                      onClick={() => bulk_move()}
                    >
                      Move
                    </Button>
                  </Flex>
                </>
                ) : (
                <>
                  {/* <Text style={{ marginTop: '8px' }}>
                In order to move the applicant, you must update the current
                stage status to &apos;Completed&apos; for the applicant.
              </Text> */}
                  <Text style={{ marginTop: '8px' }}>
                    To move the applicant to the next stage, you must first update
                    their status to &apos;Completed&apos;
                  </Text>
                  <Flex style={{ justifyContent: 'center', paddingTop: '16px' }}>
                    <Button types="primary" textSize={13} onClick={() => cancel()}>
                      OK
                    </Button>
                  </Flex>
                </>
          )}
              </Flex>
            </Modal>
        </div>
        );
};
        export default Statusmodule;
