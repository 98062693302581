import * as React from 'react';
const SvgQuestions = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#581845"
      d="M12.332.332H1.665C.932.332.332.932.332 1.665v12L2.999 11h9.333c.733 0 1.333-.6 1.333-1.334v-8c0-.733-.6-1.333-1.333-1.333Zm0 9.333H2.465l-.8.8v-8.8h10.667v8Zm-5.2-7c-.6 0-1.067.134-1.4.334-.4.266-.6.666-.533 1.133h1.333c0-.2.067-.333.2-.4s.267-.133.467-.133.4.066.533.2c.133.133.2.266.2.466s-.067.334-.133.467c-.134.133-.267.267-.4.333-.334.2-.6.4-.734.534-.266.2-.333.4-.333.733h1.333c0-.2.067-.333.067-.467.067-.133.2-.2.333-.333.334-.133.534-.333.734-.6.2-.267.266-.533.266-.8 0-.467-.2-.867-.533-1.133-.267-.2-.8-.334-1.4-.334ZM6.332 7v1.333h1.333V6.999H6.332Z"
    />
  </svg>
);
export default SvgQuestions;