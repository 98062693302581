/* eslint max-len: ["error", { "code": 2000 }] */
import * as React from 'react';
const SvgTimerClock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={12}
    fill="none"
    viewBox="0 0 11 12"
    {...props}
  >
    <path
      fill="#2E6ADD"
      d="M2.792 6.99a.313.313 0 0 1-.533.224.313.313 0 0 1 .221-.535c.17 0 .312.14.312.312M3 8.55a.313.313 0 0 0 0 .442c.122.12.321.12.442-.002A.311.311 0 1 0 3 8.55m1.996-3.774a.315.315 0 1 0-.001-.63.315.315 0 0 0 0 .63m-2.009.656c.123.12.322.12.442-.003l.003-.002.005-.005a.31.31 0 0 0 0-.442.31.31 0 0 0-.442 0l-.005.005-.005.005c-.12.12-.12.319.002.442M5.004 9.19a.31.31 0 0 0-.312.314.31.31 0 0 0 .315.312.313.313 0 1 0-.003-.626m1.56-.65a.31.31 0 0 0 0 .441.31.31 0 0 0 .441 0 .316.316 0 0 0 0-.444.313.313 0 0 0-.441.002m.955-1.874a.313.313 0 0 0-.003.626c.175 0 .312-.14.315-.314a.31.31 0 0 0-.312-.312M5.012 12A5.02 5.02 0 0 1 0 6.988a5.02 5.02 0 0 1 4.425-4.974v-.76h-.312A.28.28 0 0 1 3.83.973v-.69c0-.157.127-.282.282-.282H5.91c.158 0 .283.128.283.282v.69a.28.28 0 0 1-.283.283H5.6v.756c.945.11 1.81.486 2.519 1.049l.407-.408-.036-.037a.283.283 0 0 1 0-.4l.56-.56c.11-.11.29-.11.4 0l.906.906c.11.11.11.29 0 .4l-.56.56c-.11.11-.29.11-.4 0l-.037-.037-.408.408a4.98 4.98 0 0 1 1.073 3.094A5.02 5.02 0 0 1 5.012 12m3.847-5.012a3.85 3.85 0 0 0-3.847-3.847 3.85 3.85 0 0 0-3.848 3.847 3.85 3.85 0 0 0 3.848 3.848 3.85 3.85 0 0 0 3.847-3.848M7.025 4.955a.314.314 0 0 1 .017.425L5.766 6.875a.77.77 0 0 1-.219.656.78.78 0 0 1-1.098 0 .78.78 0 0 1 0-1.098c.18-.179.423-.25.656-.218L6.6 4.938a.314.314 0 0 1 .425.017M5.22 6.762a.31.31 0 0 0-.442 0 .31.31 0 0 0 0 .442.31.31 0 0 0 .442 0 .31.31 0 0 0 0-.442"
    />
  </svg>
);
export default SvgTimerClock;
