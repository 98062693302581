import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import RichText from '../common/RichText';
import styles from '../applicantpipelinemodule/automationemailedit.module.css';
import { AppDispatch, RootState } from '../../store';
import InputText from '../../uikit/InputText/InputText';
import Required from '../../uikit/Required/required';
import Button from '../../uikit/Button/Button';
import { isEmpty, lowerCase, toCamelCase } from '../../uikit/helper';
import EmailTemplateEditor from '../accountsettingsmodule/templatesmodule/emailtemplates/EmailTemplateEditor';
import { CityEntity, CountryEntity, StateEntity } from '../accountsettingsmodule/CompanyPageTypes';
import { locationCityMiddleWare, locationMiddleWare, locationStateMiddleWare } from '../createjdmodule/store/middleware/createjdmiddleware';
import { emailAutomationMiddleware } from './store/middleware/applicantpipelinemiddleware';

export const getDisabledEmailTags = (company_detail: any, Country: any, State: any, City: any) => {
  const Company_Website = company_detail.company_website;
  const Company_Location = Country !== '' || State !== '' || City !== '' ? `${Country}, ${State}, ${City}` : '';

  return [
    Company_Website === 'https://' ||
    Company_Website === '' ||
    Company_Website === null ||
    Company_Website === undefined
      ? 'Company Website URL'
      : '',
    Company_Location === '' ||
    Company_Location === null ||
    Company_Location === undefined
      ? 'Company Location'
      : '',
  ];
};

type Props = {
  handleImportTemplate: () => void;
  isContent: any;
  isContentError: any;
  isContentLenError: any;
  setContent: any;
  Contentvalue: any;
  errorsubject: any;
  seterrorsubject: any;
  isSubject: any;
  setSubject: any;
  setSubjectExist: any;
  SubjectError: any;
  setSubjectError: any;
  jd_id: any;
  setContentLenError: any;
  template: any;
  handlemessage: any;
  isTitle: any;
  setTitle: any;
  TitleExist: any;
  setTitleExist: any;
  TitleError: any;
  setTitleError: any;
  setContentError: any;
  ImportTemplateID: any;
  SubjectExist: any;
  handlePopupCancel: () => void;
  isNewStageEmailScreen: any;
  handleCreateEmailTemplate: any;
  setShowCreateError: any;
  isContentMinLen: any;
  setContentMinLen: any;
  job_details: any;
  isTagIntemation: any;
  setTagIntemation: any;
  isDisableTag: any;
  setDisableTag: any;
};

const AutomationEmailedit = ({
  handleImportTemplate,
  isContent,
  isContentError,
  isContentLenError,
  setContent,
  Contentvalue,
  errorsubject,
  seterrorsubject,
  isSubject,
  setSubject,
  setSubjectExist,
  SubjectError,
  setSubjectError,
  jd_id,
  setContentLenError,
  template,
  handlemessage,
  isTitle,
  setTitle,
  TitleExist,
  setTitleExist,
  TitleError,
  setTitleError,
  setContentError,
  ImportTemplateID,
  SubjectExist,
  handlePopupCancel,
  handleCreateEmailTemplate,
  isNewStageEmailScreen,
  setShowCreateError,
  isContentMinLen,
  setContentMinLen,
  job_details,
  isTagIntemation,
  setTagIntemation,
  isDisableTag,
  setDisableTag,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const { title, tags, applicants_list, company_detail, user, stateid, cityid, countryid, countryidfin } = useSelector(
    ({
      EmailAutomationReducers,
      applicantPipeLineDataReducers,
      companyPageReducers,
      userProfileReducers,
      locationReducers
    }: RootState) => ({
      title: EmailAutomationReducers.title,
      tags: EmailAutomationReducers.tags,
      applicants_list: applicantPipeLineDataReducers.applicants_list,
      company_detail: companyPageReducers.company_detail,
      user: userProfileReducers.user,
      stateid: companyPageReducers.company_detail.state_id,
      cityid: companyPageReducers.company_detail.city_id,
      countryid: companyPageReducers.company_detail.country_id,
      countryidfin: locationReducers.country,


    }),
  );
  const [isGetCountry, setCountry] = useState<CountryEntity[]>([]);
  const [getState, setState] = useState<StateEntity[]>([]);
  const [getCity, setCity] = useState<CityEntity[]>([]);
  const [state, setstate] = useState('');
  const [city, setcity] = useState('');
  const [country, setcountry] = useState('');
  const disabled_Email_Tags = getDisabledEmailTags(company_detail, country, state, city);
  useEffect(() => {
    //  dispatch(companyPageInitalMiddleWare());
    dispatch(locationMiddleWare({}));
  }, []);

  useEffect(() => {
    if (countryidfin && countryidfin.length !== 0) {
      setCountry(countryidfin);
    }
  }, [countryidfin]);
  useEffect(() => {
    if (!isEmpty(countryid)) {
      dispatch(
        locationStateMiddleWare({
          country: countryid.toString(),
        }),
      ).then((res) => {
        if (res.payload.states && res.payload.states.length !== 0) {
          setState(res.payload.states);
        }
      });
    }
  }, [countryid]);
  useEffect(() => {
    if (!isEmpty(stateid)) {
      dispatch(locationCityMiddleWare({ state: stateid.toString() })).then(
        (res) => {
          if (res.payload.city && res.payload.city.length !== 0) {
            setCity(res.payload.city);
          }
        },
      );
    }
  }, [stateid]);

  useEffect(() => {
    if (
      getCity.length !== 0 &&
      isGetCountry.length !== 0 &&
      getState.length !== 0
    ) {
      cityhand();
      statehand();
      countryhand();
    }
  }, [getCity, getState, isGetCountry]);

  function cityhand() {
    if (getCity.length !== 0) {
      {
        if (cityid !== null) {
          {
            setcity(getCity.find((option) => option.id === cityid).name);
          }
        }
      }
    }
  }
  function countryhand() {
    if (isGetCountry.length !== 0) {
      if (countryid !== null) {
        setcountry(isGetCountry.find((option) => option.id === countryid).name);
      }
    }
  }

  function statehand() {
    if (getState.length !== 0) {
      if (stateid !== null) {
        setstate(getState.find((option) => option.id === stateid).name);
      }
    }
  }

  const [errortitle, seterrortitle] = useState(false);

  useEffect(() => {
    const emailcontent = isContent;
    const result = handlemessage(emailcontent);

    if (isTitle?.length > 35) {
      seterrortitle(true);
    } else {
      seterrortitle(false);
    }

    if (isSubject?.length > 250) {
      seterrorsubject(true);
    } else {
      seterrorsubject(false);
    }

    if (isSubject !== '') {
      setSubjectError(false);
    }
    if (isTitle !== '') {
      setTitleError(false);
    }
    if (result?.length > 2000) {
      setContentLenError(true);
    } else {
      setContentLenError(false);
    }

    if (emailcontent !== null) {
      if (result?.length < 250) {
        setContentMinLen(true);
      }
      if (result?.length === 0 && result?.length < 250) {
        setContentMinLen(false);
      }

      if (result?.length !== 0 && result?.length > 251) {
        setContentMinLen(false);
      }
    } else {
      setContentMinLen(false);
    }

    if (isContentError === true) {
      setContentMinLen(false);
    }
  }, [isTitle, isContent, isSubject]);

  const handleContent = (e: any) => {
    const myContent = e;
    const myparser = new DOMParser();
    const doc = myparser.parseFromString(myContent, 'text/html');
    const textNodes = doc.querySelectorAll('body')[0].textContent.trim();
    const texttrim = textNodes.trimStart();

    setContent(myContent);
    if (isEmpty(texttrim)) {
      setContentError(true);
    } else if (!isEmpty(texttrim)) {
      setContentError(false);
      if (texttrim?.length < 250) {
        setContentMinLen(true);
      }
    }
  };
  

  const handletitle = (e: any) => {
    const inputValue = e.target.value;
    const titles = inputValue.trimStart();
    setTitle(titles);

    const titleAlreadyExists = title?.some((temp) => temp === titles);

    if (ImportTemplateID && titleAlreadyExists) {
      setTitleExist(false);
    } else if (titleAlreadyExists && !ImportTemplateID) {
      setTitleExist(true);
    }

    if (!titleAlreadyExists) {
      setTitleExist(false);
    }

    if (titles === '') {
      setTitleError(true);
    }
  };

  const handleSubject = (e: any) => {
    const inputValue = e.target.value;
    const subject = inputValue.trimStart();
    const subjectAlreadyExists = template?.some(
      (templatess) => templatess.subject === subject,
    );
    setSubject(subject);

    if (isSubject?.length > 250) {
      seterrorsubject(true);
    } else {
      seterrorsubject(false);
    }

    if (ImportTemplateID && subjectAlreadyExists) {
      setSubjectExist(false);
    } else if (subjectAlreadyExists && !ImportTemplateID) {
      setSubjectExist(true);
    }

    if (!subjectAlreadyExists) {
      setSubjectExist(false);
    }

    if (subject === '') {
      setSubjectError(true);
    }
  };

  useEffect(() => {
    if (Contentvalue !== null && Contentvalue !== undefined) {
      setContent(Contentvalue?.templates);
      setSubject(Contentvalue?.subject);
      setTitle(Contentvalue?.name);
    }
    dispatch(emailAutomationMiddleware({ jd_id: jd_id.toString() }));
  }, [dispatch, Contentvalue]);

  return (
    <>
      <>
        <Flex marginBottom={5}>
          <Required />
        </Flex>
        <Flex marginBottom={10}>
          <InputText
            required
            label="Template Title"
            onChange={handletitle}
            value={isTitle}
            maxLength={251}
            className={styles.templateInput}
          />
          {errortitle && (
            <Text color="error">
              Template title should not exceed 35 characters.
            </Text>
          )}
          {TitleError && <Text color="error">This field is required</Text>}
          {TitleExist && <Text color="error">Template name already exist</Text>}
        </Flex>
        <Flex marginBottom={10}>
          <InputText
            label="Subject"
            required
            className={styles.subjectcontentInput}
            onChange={handleSubject}
            value={isSubject}
            maxLength={251}
          />
          {SubjectExist && <Text color="error">Subject is already exist</Text>}
          {errorsubject && (
            <Text color="error">
              Subject content should not exceed 250 characters.
            </Text>
          )}
          {SubjectError && <Text color="error">This field is required</Text>}
        </Flex>
        <Flex>
          <Text color="theme" size={13} style={{ margin: '5px 0px 2px 0px' }}>
            Email Content *
          </Text>

          <EmailTemplateEditor
            handleChange={handleContent}
            value={isContent}
            className={styles.reactquillchange}
            height={'174px'}
            mailAutomation
            tags={tags}
            disabledTags={disabled_Email_Tags}
            isTagIntemation={isTagIntemation}
            setTagIntemation={setTagIntemation}
            isDisableTag={isDisableTag}
            setDisableTag={setDisableTag}
            // handleIntemationPoppup={() => handleIntemationPoppup(isDisableTag)}
          />
        </Flex>

        {isContentLenError && (
          <Text color="error">
            Email content should not exceed 2000 characters.
          </Text>
        )}

        {isContentMinLen && (
          <Text color="error">
            Email content should have a minimum of 250 characters.
          </Text>
        )}

        {isContentError && <Text color="error">This field is required</Text>}

        <Flex row between marginTop={10}>
          <Flex onClick={handleImportTemplate} style={{ cursor: 'pointer' }}>
            <Button types="secondary">Import</Button>
          </Flex>

          <Flex row between width={140}>
            <Button
              types="close"
              onClick={() => {
                handlePopupCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (isNewStageEmailScreen === true) {
                  setShowCreateError(
                    'Please create email content to complete the automation rule',
                  );
                  return;
                }
                if (isNewStageEmailScreen === false) {
                  setShowCreateError('');
                  handleCreateEmailTemplate();
                }
              }}
            >
              Apply
            </Button>
          </Flex>
        </Flex>
      </>
    </>
  );
};

export default AutomationEmailedit;

