import React, { useState } from 'react'
import { FormikProps } from 'formik';
import classNames from 'classnames/bind';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import styles from '../applicantpipelinemodule/applicantemailautomation.module.css'
import SvgSelected from '../../icons/SvgSelected';
import SvgNotselected from '../../icons/SvgNotselected';
import { HighlightText } from '../../uikit/HighlightText/HighlightText';
import { EmailTemplate } from '../../hooks/useStages/types';

const cx = classNames.bind(styles);


type Props = {
    list: EmailTemplate;
    formik: FormikProps<any>;
    hanldeClose: () => void;
    searchTerm: string;
    user?: string;
    update: (val: any | null, val2: any) => void;
    applyfun: (val: any) => void;
    indextick: any;
    index: any;
    valuelist: any;
    emailTemplate: any;
    setApplyAction: any;
    
}

const AutomateEmailTemplateList = ({
    list,
    formik,
    hanldeClose,
    searchTerm,
    user,
    update,
    applyfun,
    indextick,
    index,
    valuelist,
    emailTemplate,
    setApplyAction,
}: Props) => {

    const [isCollapse, setCollapse] = useState(false);

    const select = (val, val2) => {
        setApplyAction(false);
        update(val.templates, val2);
        applyfun(val);
    };

    return (
        <>
            <Flex center row>
                <Flex
                    width={'100%'}
                    className={
                        indextick !== null
                            ? index === indextick
                                ? styles.tempcardstylehilight
                                : styles.tempcardstyle
                            : styles.tempcardstyle
                    }
                >
                    <Flex className={styles.tempPadding}>
                        <Flex center className={styles.iconspace}>
                            {indextick !== null ? (
                                <>
                                    {index === indextick ? (
                                        <SvgSelected
                                            onClick={() => select(list, index)}
                                            className={styles.pointer}
                                        />
                                    ) : (
                                        <SvgNotselected
                                            onClick={() => select(list, index)}
                                            className={styles.pointer}
                                        />
                                    )}
                                </>
                            ) : (
                                <SvgNotselected
                                    onClick={() => select(list, index)}
                                    className={styles.pointer}
                                />
                            )}
                        </Flex>
                        <Flex
                            width={'100%'}
                            onClick={() => select(list, index)}
                            className={styles.listOverAll}
                        >
                            <Flex row center between>
                                <Text
                                    bold
                                    className={styles.listHeadingStyle}
                                    style={{ fontSize: '13px' }}
                                >
                                    <HighlightText
                                        value={list.name}
                                        className={styles.listHeadingStyles}
                                        higlight={searchTerm}
                                        user={user}
                                    />
                                </Text>
                            </Flex>
                            <HighlightText
                                className={cx({ trimStyle: !isCollapse })}
                                value={user === '' ? list.templates : list.templates_text}
                                higlight={searchTerm}
                                user={user}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export default AutomateEmailTemplateList