/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
    fill: '#ffc203',
    width: 24,
    height: 24,
};

const SvgDelete = ({ width, height, fill }: typeof defaultProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill={fill}
        >
            <path
                fill={fill}
                d="M7.179 9.75h.642c.086 0 .167-.03.228-.082a.265.265 
                0 0 0 .094-.2V4.407a.265.265 0 0 0-.094-.199.346.346 
                0 0 0-.228-.082H7.18a.346.346 0 0 0-.228.082.265.265 0 
                0 0-.094.2v5.062c0 .074.034.146.094.199.06.052.142.082.228.082Zm4.392-7.875H9.364L8.454.546a1.224 1.224 
                0 0 0-.47-.4A1.431 1.431 0 0 0 7.35 0h-2.7c-.222 0-.44.05-.634.146a1.224 1.224 
                0 0 0-.469.4l-.911 1.329H.429a.461.461 0 0 0-.303.11A.353.353 0 0 
                0 0 2.25v.375c0 .1.045.195.126.265.08.07.189.11.303.11h.428v7.875c0 
                .298.136.585.377.796.24.21.568.329.909.329h7.714c.341 
                0 .668-.118.91-.33.24-.21.376-.497.376-.795V3h.428c.114 
                0 .223-.04.303-.11A.353.353 0 0 0 12 2.625V2.25c0-.1-.045-.195-.126-.265a.462.462 
                0 0 0-.303-.11Zm-6.968-.682a.153.153 0 0 1 .059-.05.179.179 0 0 1 .08-.018h2.517c.028 0 .055.006.08.018.023.012.044.03.058.05l.468.682h-3.73l.468-.682Zm5.254 9.682H2.143V3h7.714v7.875ZM4.18 9.75h.642c.086 0 .167-.03.228-.082a.265.265 0 0 0 .094-.2V4.407a.265.265 0 0 0-.094-.199.346.346 0 0 0-.228-.082H4.18a.346.346 0 0 0-.228.082.265.265 0 0 0-.094.2v5.062c0 .074.034.146.094.199.06.052.142.082.228.082Z"
            />
        </svg>
    );
}
SvgDelete.defaultProps = defaultProps;

export default SvgDelete;
