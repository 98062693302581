import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import SvgAngle from '../../../icons/SvgAngle';
import Button from '../../../uikit/Button/Button';
import Card from '../../../uikit/Card/Card';
import { GARY_4, PRIMARY } from '../../../uikit/Colors/colors';
import Flex from '../../../uikit/Flex/Flex';
import { isEmpty } from '../../../uikit/helper';
import InputSwitch from '../../../uikit/Switch/InputSwitch';
import Text from '../../../uikit/Text/Text';
import { SubsriptionMiddleWare } from '../../navbar/empnavbar/store/navbarmiddleware';
import DetailedFeaturesComparison from './DetailedFeaturesComparison';
import { Subscription } from './manageSubscriptionTypes';
import { standard, freeData, premium, enterprise } from './mock';
import styles from './plansandfeatures.module.css';
import PriceCard from './PriceCard';




type Props = {
  subscription?: Subscription;
  totalUserManger: number;
  downgrade: number;
  total_plan_array: any;
  yearly_plan: any;
  monthly_plan: any;
  free_plan: any;
  formik:any;
  focusRef:any;
  setmanagesub:any;
  elementsRef:any;
};
const PlansandFeatures = ({
  subscription,
  totalUserManger,
  downgrade,
  total_plan_array,
  yearly_plan,
  monthly_plan,
  free_plan,
  formik,
  focusRef,
  setmanagesub,
  elementsRef
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isPlan, setPlan] = useState(true);
  const [isShowPrice, setPriceShow] = useState(true);
  const [isCompare, setCompare] = useState(false);
  const [freePlanBtn, setFreePlan] = useState('14 Days Trial');
  const [basicPlanBtn, setBasicPlan] = useState('Choose Plan');
  const [proPlanBtn, setProPlan] = useState('Choose Plan');
  const [isTotalUser, setTotalUser] = useState(0);
  const [isTotalUserBasic, setTotalUserBasic] = useState(0);
  const [isTotalUserPro, setTotalUserPro] = useState(0);
  const history = useHistory();
  const [isCount, setCount] = useState({ count: '', key: '' });
  const [isDefalutPlan, setDefaultPlan] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const count: any = query.get('count');
  const urlPlanId: any = query.get('key');

  // params based count and plan id updated in inital value
  useEffect(() => {
    if (!isEmpty(count) && !isEmpty(urlPlanId)) {
      setCount({ count, key: urlPlanId });
      if (query.has('count') && query.get('key')) {
        query.delete('count');
        query.delete('key');
        history.replace({
          search: query.toString(),
        });
      }
    }
  }, []);

  useEffect(() => {
    if (subscription) setTotalUser(subscription?.no_of_users);
    if (subscription) setTotalUserBasic(subscription?.no_of_users);
  }, [subscription]);

  // plan id based button condition check
  useEffect(() => {
    if (subscription && subscription.plan_id_id === 1) {
      setFreePlan('Current Plan');
    }
    if (
      (subscription &&
        subscription.plan_id_id === 2 &&
        Number(isTotalUserBasic) === subscription?.no_of_users &&
        isDefalutPlan === isPlan) ||
      (subscription &&
        subscription.plan_id_id === 3 &&
        Number(isTotalUserBasic) === subscription?.no_of_users &&
        isDefalutPlan === isPlan)
    ) {
      setBasicPlan('Current Plan');
    } else if (
      (subscription &&
        subscription.plan_id_id === 2 &&
        Number(isTotalUserBasic) !== subscription?.no_of_users &&
        isDefalutPlan === isPlan) ||
      (subscription &&
        subscription.plan_id_id === 3 &&
        Number(isTotalUserBasic) !== subscription?.no_of_users &&
        isDefalutPlan === isPlan)
    ) {
      setBasicPlan('Update Users');
    } else {
      setBasicPlan('Choose Plan');
    }

    if (
      (subscription &&
        subscription.plan_id_id === 4 &&
        Number(isTotalUserPro) === subscription?.no_of_users &&
        isDefalutPlan === isPlan) ||
      (subscription &&
        subscription.plan_id_id === 5 &&
        Number(isTotalUserPro) === subscription?.no_of_users &&
        isDefalutPlan === isPlan)
    ) {
      setProPlan('Current Plan');
    } else if (
      (subscription &&
        subscription.plan_id_id === 4 &&
        Number(isTotalUserPro) !== subscription?.no_of_users &&
        isDefalutPlan === isPlan) ||
      (subscription &&
        subscription.plan_id_id === 5 &&
        Number(isTotalUserPro) !== subscription?.no_of_users &&
        isDefalutPlan === isPlan)
    ) {
      setProPlan('Update Users');
    } else {
      setProPlan('Choose Plan');
    }
  }, [
    subscription,
    isTotalUser,
    isPlan,
    isDefalutPlan,
    isTotalUserPro,
    isTotalUserBasic,
  ]);

  useEffect(() => {
    if (
      (current_plan === 6) ||
      (current_plan === 7) ||
      (current_plan === 8) ||
      (current_plan === 9)
    ) {
      setPlan(false);
      setDefaultPlan(false);
    } else {
      setPlan(true);
      setDefaultPlan(true);
    }
  }, [subscription]);

  const cancelPlanCheck =
    subscription &&
    subscription?.is_active === true &&
    Number(subscription.subscription_changed_to) === -1;

  useEffect(() => {
    dispatch(SubsriptionMiddleWare())
  }, [])

  const {
    total_plan,
    current_plan,
    current_jd_count,
    current_resume_count,
  } = useSelector(
    ({
      SubscriptionReducers
    }: RootState) => ({
      current_plan: SubscriptionReducers.current_plan,
      current_jd_count: SubscriptionReducers.current_jd_count,
      total_plan: SubscriptionReducers.total_plan,
      current_resume_count: SubscriptionReducers.current_resume_count,
    }),
  );
  function preprocessAndParseData(dataString) {
    try {
      const correctedString = dataString.replace(/value/g, '"value"').replace(/,\s*\]/, ']');
      return JSON.parse(correctedString);
    } catch (e) { 
      return null;
    }
  }
  

  return (
    <Card className={styles.overAll} id="plans_and_features__plan">

      <Flex>
        <Flex row center between>
          <Flex>
            <Text size={14} bold>
              Plans and Features
            </Text>
          </Flex>
          <Flex>
            <div
              style={{ marginLeft: '5px' }}
              tabIndex={-1}
              role="button"
              onKeyDown={() => { }}
              onClick={() => setPriceShow(!isShowPrice)}
            >
              <SvgAngle fill={"#581845"} height={16} width={16} up={isShowPrice} />
            </div>
          </Flex>
        </Flex>

        {isShowPrice && (
          <>
            <Flex
              row
              center
              className={styles.switchFlex}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Text>Billed Monthly</Text>
              <div style={{ margin: '0px 5px 0px 10px' }}>
                <InputSwitch
                  offFill={PRIMARY}
                  checked={isPlan}
                  onClick={() => setPlan(!isPlan)}
                />
              </div>
              <Text>Billed Annually</Text>
            </Flex>

            <Flex columnFlex style={{ display: 'flex', alignItems: 'center' }}>
             <div ref={elementsRef} tabIndex={-1}>
              <Flex row>
                {isPlan === false ? (
                  monthly_plan?.map((plan, i) => (
                    <div key={plan.plan_id} style={{ margin: '0 0 0 10px' }}>
                      <PriceCard
                        setmanagesub={setmanagesub}
                        isPlan={isPlan}
                        isDefalutPlan={isDefalutPlan}
                        btnTitle={plan.button}
                        data={preprocessAndParseData(plan.data)}
                        headerTitle={plan.plan_name}
                        price={plan.final_price}
                        days={plan.days}
                        btnDisabled={plan.button === 'Current Plan' || cancelPlanCheck||(plan.is_plan&&plan.plan_id===1)}
                        planId={plan.plan_id}
                        totalUserManger={totalUserManger}
                        subscription={subscription}
                        userPrice={plan.user_price}
                        subheading={plan.sub_heading}
                        strip={plan.stripe_id}
                        plan_id={plan.plan_id}
                        focusRef={focusRef}
                        formik1={formik}
                      />
                    </div>
                  ))
                ) : (
                  yearly_plan?.map((plan, i) => (
                    <div key={plan.plan_id} style={{ margin: '0 0 0 10px' }}>
                      <PriceCard
                        setmanagesub={setmanagesub}
                        isPlan={isPlan}
                        isDefalutPlan={isDefalutPlan}
                        btnTitle={plan.button}
                        data={preprocessAndParseData(plan.data)}
                        headerTitle={plan.plan_name}
                        price={plan.final_price}
                        days={plan.days}
                        btnDisabled={plan.button === 'Current Plan' || cancelPlanCheck||(plan.is_plan&&plan.plan_id===1)}
                        planId={plan.plan_id}
                        totalUserManger={totalUserManger}
                        subscription={subscription}
                        userPrice={plan.user_price}
                        subheading={plan.sub_heading}
                        strip={plan.stripe_id}
                        plan_id={plan.plan_id}
                        focusRef={focusRef}
                        formik1={formik}
                        plandays={plan.subscription_value_days}
                      />
                    </div>
                  ))
                )}
              </Flex>
              </div>

              <Button
                onClick={() => setCompare(!isCompare)}
                types="link"
                style={{ margin: '25px 0' }}
              >
                <Flex row >
                  {isCompare ? (
                    <Text
                      bold
                      color="link"
                      style={{ marginRight: 2, position: 'relative', top: -1 }}
                    >
                      -
                    </Text>
                  ) : (
                    <Text bold color="link" style={{ marginRight: 5 }}>
                      +
                    </Text>
                  )}

                  <Text color="link" bold size={14}>
                    Detailed Features Comparison
                  </Text>
                </Flex>
              </Button>
              {isCompare && <DetailedFeaturesComparison />}
            </Flex>
          </>
        )}
      </Flex>
    </Card>
  );
};

export default PlansandFeatures;
