import * as React from 'react';
const SvgAiMatching = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={17}
    fill="none"
    {...props}
    viewBox='0 0 14 17'
  >
    <path
      fill="#fff"
      stroke="#FCD34D"
      strokeWidth={0.3}
      d="m9.059 7.266.139-.056.196.492.205-.505a.863.863 0 0 1 .429-.46l.5-.236-.5-.236a.863.863 0 0 1-.43-.46l-.205-.508-.205.507a.863.863 0 0 1-.43.461l-.502.236.515.246-.065.135m.353.384a.715.715 0 0 0-.353-.384m.353.384.276.693m-.277-.693.277.693m-.629-1.077-.668-.319.668.32Zm.63 1.077a.062.062 0 0 0 .116 0m-.117 0 .757-1.087a.713.713 0 0 0-.354.381l-.286.706m-.117 0 .06-.024.057.024m-.117 0 .059-.024-.08-.032m.138.056-.058-.024.08-.032m-.16 0 .08.032.08-.032m-.16 0a.087.087 0 0 1 .08-.053c.036 0 .068.022.08.053m-.16 0h.16"
    />
    <path
      fill="#fff"
      stroke="#FCD34D"
      strokeWidth={0.2}
      d="m11.685 8-.333.158.043.09a.475.475 0 0 0-.236.254m.526-.501-.986-.502.093.037a.575.575 0 0 1-.286.307l-.335.158m1.514 0-.333-.157m.333.157-.333-.157m-.193.658-.093-.037-.136.336-.131-.328a.576.576 0 0 0-.285-.309m.645.338-.19.47.19-.47Zm-.645-.338-.043.09.043-.09Zm0 0-.343-.163m0 0-.102-.049a.055.055 0 0 1 .031.049.055.055 0 0 1-.032.048m.103-.048-.103.048m1.284-.205a.575.575 0 0 1-.287-.308l-.136-.338.422.645Zm-1.284.205-.023-.048-.02-.042.02.042.023.048Z"
    />
    <path stroke="#581845" d="M10 1H1v15h12V4m-3-3v3h3m-3-3 3 3" />
    <path
      fill="#581845"
      d="m8.591 11.8.53-.531a.193.193 0 0 1 .283 0c.04.04.06.087.06.141 0 .054-.02.1-.06.141l-.618.624a.258.258 0 0 1-.188.08.258.258 0 0 1-.188-.08l-.322-.315a.176.176 0 0 1-.06-.138c0-.056.02-.104.06-.144a.193.193 0 0 1 .282 0l.221.221Zm.155 1.691c-.488 0-.901-.169-1.239-.507A1.686 1.686 0 0 1 7 11.746c0-.488.169-.901.507-1.239.338-.338.75-.507 1.239-.507.488 0 .9.169 1.239.507.338.338.507.751.506 1.239a1.636 1.636 0 0 1-.349 1.02l1.504 1.504c.05.05.074.112.074.188a.254.254 0 0 1-.262.262.254.254 0 0 1-.188-.074l-1.504-1.504a1.636 1.636 0 0 1-1.02.35Zm0-.537c.335 0 .62-.117.856-.352.235-.235.352-.52.352-.856 0-.336-.117-.621-.352-.856a1.166 1.166 0 0 0-.856-.353c-.336 0-.621.118-.856.353s-.353.52-.353.856c0 .335.118.62.353.856.235.235.52.352.856.352Z"
    />
    <path
      stroke="#581845"
      strokeWidth={0.5}
      d="M2 11.75h4M2 9.75h5M2 7.75h5M2 5.75h5M2 3.75h6M2 13.75h4"
    />
  </svg>
);
export default SvgAiMatching;