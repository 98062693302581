import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { AppDispatch, RootState } from '../../../store';
import Button from '../../../uikit/Button/Button'
import Flex from '../../../uikit/Flex/Flex'
import Text from '../../../uikit/Text/Text';
import SvgZitaLogo from '../../../icons/SvgZitaLogo';
import SvgUpArrow from '../../../icons/SvgArrowUp';
import SvgArrowDown from '../../../icons/SvgArrowDown';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import { loginAuth } from '../../../appRoutesPath';
import SvgArrowUp from '../../../icons/SvgArrowUp';
import SvgPdfDownload from '../../../icons/SvgPdfDownload';
import { InputSwitch, Loader } from '../../../uikit';
import { PRIMARY } from '../../../uikit/Colors/colors';
import styles from './websitesubscriptionscreen.module.css';
import DetailedFeaturesWebsite from './DetailedFeaturesWebsite';
import SubscriptionCalculation from './SubscriptionCalculation';
import PlansandFeatures from './PlansandFeatures';
import { manageSubscriptionMiddleWare, websiteSubscriptionScreenMiddleWare } from './store/managesubscriptionmiddleware';
import PriceCard from './PriceCard';
import PriceCardWebSite from './PriceCardWebSite';
import Header from './Header';
import Footer from './Footer';
import './footer.css'
import CopyRight from './CopyRight';


type Props = {
  setTab: (a: string) => void;

};

const WebsiteSubscriptionScreen = ({ setTab }: Props) => {
  const ismobile = useMediaQuery({ query: '(max-width: 400px)' });

  const dispatch: AppDispatch = useDispatch();
  const [isCompare, setCompare] = useState(false);
  const [isPlanSwitch, setPlanSwitch] = useState(false);
  const [isPlanSwitchmain, setPlanSwitchmain] = useState(false);
  const [componentHeight, setComponentHeight] = useState(window.innerHeight - 85);
  const [isLoader, setLoader] = useState(false);


  const { yearly_plan, monthly_plan } =
    useSelector(({ manageSubscriptionwebsiteReducers }: RootState) => ({

      yearly_plan: manageSubscriptionwebsiteReducers.yearly_plan,
      monthly_plan: manageSubscriptionwebsiteReducers.monthly_plan,

    }));


  const handleScrollToSubscription = () => {
    const subscriptionCalculationElement = document.getElementById('subscriptionCalculation');
    if (subscriptionCalculationElement) {
      subscriptionCalculationElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    setLoader(true)
    dispatch(websiteSubscriptionScreenMiddleWare()).then(() => {
      setLoader(false)
    })
  }, [])

  const handlePlanSwitchChange = () => {
    setPlanSwitch(!isPlanSwitch);
  };
  const handlePlanSwitchChangemain = () => {
    setPlanSwitchmain(!isPlanSwitchmain);
  };

  function preprocessAndParseData(dataString) {
    try {
      const correctedString = dataString.replace(/value/g, '"value"').replace(/,\s*\]/, ']');
      return JSON.parse(correctedString);
    } catch (e) {
      return null;
    }
  }
  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 85);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div>
        <Flex>
          {isLoader && <Loader />}
          <Flex className='mainheadingpadding' style={{boxShadow:'0 13px 35px -12px rgba(35,35,35,.1)'}}>
            <Header />
          </Flex>

          {/* Header */}
       

        </Flex>

        {/* Subscription Page */}
        <Flex style={{ height: componentHeight, overflow: 'scroll' }} >
        <Flex center middle className={styles.pageHeader}>
        <h1 style={{color:'white',fontWeight:'600',fontSize:'22px',marginTop:'7px'}}>
              Plan & Pricing
            </h1>
          </Flex>
         
         <div className={styles.contentHolder} >
          <Flex center middle className={ismobile ? styles.widthheadet : ""} marginTop={40} marginBottom={50}>
            <Flex marginBottom={10} marginTop={15}>
              <Text bold size={16} align='center' className={styles.heading}>
                Choose the best plan for your Business
              </Text>
            </Flex>
            <Flex>
              <Text size={13} align='center' className={styles.subheading}>
                {'Zita\'s Flexible Plan & Add-Ons: Craft Your Ideal Path to Growth - Elevate Your Business with the Perfect Fit'}
              </Text>
            </Flex>
          </Flex>



          <Flex row between marginBottom={5} marginTop={20} >

            <Flex width={"40%"}>
              <Text bold color='theme'>{' '}</Text>
            </Flex>

            <Flex row width={"35%"} style={{ justifyContent: "center" }}>
              <Flex marginRight={5}><Text className={styles.subheading}>Billed Monthly</Text></Flex>
              <InputSwitch
                offFill={PRIMARY}
                checked={isPlanSwitchmain}
                onClick={handlePlanSwitchChangemain}
              />
              <Text className={styles.subheading}>Billed Annually</Text>
            </Flex>



            <Flex width={"40%"} end style={{ cursor: 'pointer' }} onClick={handleScrollToSubscription}>
              <Text bold color='theme' className={styles.calculation}>Calculate Your Price</Text>
            </Flex>
          </Flex>

          <Flex columnFlex style={{ display: 'flex', alignItems: 'center' }} >
            <Flex row className={styles.pcview}>
              {isPlanSwitchmain === false ? (
                monthly_plan?.map((plan, i) => (
                  <div key={plan.plan_id} style={{ margin: '0 0 0 10px' }} >
                    <PriceCardWebSite
                      data={preprocessAndParseData(plan.data)}
                      headerTitle={plan.plan_name}
                      price={plan.final_price}
                      userPrice={plan.user_price}
                      subheading={plan.sub_heading}
                      days={plan.days}
                      indexparent={i}
                      isPlanSwitchmain={isPlanSwitchmain}
                      id={plan.plan_id}
                    />
                  </div>
                ))
              ) : (
                yearly_plan?.map((plan, i) => (
                  <div key={plan.plan_id} style={{ margin: '0 0 0 10px' }}>
                    <PriceCardWebSite
                      data={preprocessAndParseData(plan.data)}
                      headerTitle={plan.plan_name}
                      price={plan.final_price}
                      userPrice={plan.user_price}
                      subheading={plan.sub_heading}
                      days={plan.days}
                      indexparent={i}
                      isPlanSwitchmain={isPlanSwitchmain}
                      id={plan.plan_id}
                    />
                  </div>
                ))
              )}
            </Flex>

          </Flex>

          <Flex>
            <Flex>
              <Flex between row className={styles.alignitem}>
                <Flex row center>
                  {/* <Text size={14}>Comparison features by plans</Text> */}
                  <Flex center marginLeft={5}>
                    {/* <SvgPdfDownload/> */}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <DetailedFeaturesWebsite isCompare={isCompare} setCompare={setCompare}></DetailedFeaturesWebsite>
            <Flex marginTop={40} id="subscriptionCalculation">
              <SubscriptionCalculation
                isPlanSwitch={isPlanSwitch}
                setPlanSwitch={setPlanSwitch}
              />
            </Flex>
          </Flex>
          </div>  



          <Flex>
            <footer  style={{paddingTop:'30px',marginTop:'30px'}} className={`${styles.contentHolder} theme-footer-one`} >
              <div className="top-footer">
                <div >
                  <Footer />
                </div>
                {/* /.container */}
              </div>
              {/* /.top-footer */}

              <div className="">
                <div
                  className="bottom-footer-content"
                  style={{ borderTop: "0.3px solid #ffffff50", marginTop: "10px" }}
                >
                  <CopyRight />
                </div>
              </div>
            </footer>
          </Flex>
          </Flex>

   
      </div>

    </>
  )
}

export default WebsiteSubscriptionScreen