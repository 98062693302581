const SvgCSV = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="none"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill="#581845"
        d="M1.043 9.75c0 .688.469 1.25 1.044 1.25a.962.962 0 0 0 .666-.291.535.535 
        0 0 1 .733-.01c.1.09.157.214.161.346a.488.488 0 0 1-.14.355 1.962 1.962 
        0 0 1-.645.44 2.035 2.035 0 0 1-.775.16C.936 12 0 10.99 
        0 9.75S.936 7.5 2.087 7.5c.267.002.53.056.775.16.244.103.464.253.645.44a.488.488 
        0 0 1-.02.702.534.534 0 0 1-.734-.01.962.962 
        0 0 0-.667-.291c-.574-.001-1.043.562-1.043 1.249Zm5.192-.543c-.261-.073-.531-.147-.682-.24-.081-.05-.08-.063-.073-.119a.276.276 
        0 0 1 .036-.13.29.29 0 0 1 .095-.1c.3-.194 1-.107 1.292-.034a.543.543 0 0 0 .397-.05.504.504 
        0 0 0 .243-.304.483.483 0 0 0-.052-.38.52.52 
        0 0 0-.317-.234c-.138-.034-1.37-.326-2.142.173-.163.105-.3.243-.402.404a1.25 1.25 
        0 0 0-.185.53c-.13.993.89 1.276 1.5 1.444.787.219.856.308.834.475-.02.15-.082.209-.14.245-.3.192-.99.098-1.275.023a.543.543 
        0 0 0-.397.046.505.505 0 0 0-.247.302.482.482 0 0 0 .048.38.52.52 0 0 0 .315.237c.324.081.656.123.99.125.38 
        0 .803-.063 1.141-.279.167-.106.308-.247.411-.411.104-.165.168-.35.189-.54.143-1.082-.934-1.384-1.58-1.563Zm5.419-1.678a.542.542 
        0 0 0-.563.135.496.496 0 0 0-.104.168l-.813 2.181-.813-2.181a.508.508 0 0 0-.268-.284.543.543 
        0 0 0-.4-.019.514.514 0 0 0-.295.257.481.481 0 0 0-.02.382l1.304 3.5a.503.503 0 0 0 .191.24.538.538 
        0 0 0 .601 0 .503.503 0 0 0 .191-.24l1.304-3.5a.48.48 0 0 0-.02-.382.515.515 0 0 0-.295-.257ZM12 4v1.5a.49.49 
        0 0 1-.153.354.534.534 0 0 1-.738 0 .49.49 0 0 1-.152-.354v-1h-3.13a.533.533 0 0 1-.37-.146A.49.49 
        0 0 1 7.304 4V1H1.565v4.5a.49.49 0 0 1-.153.354.533.533 0 0 1-.737 0A.49.49 
        0 0 1 .522 5.5V1c0-.265.11-.52.305-.707C1.023.105 1.288 0 1.565 0h6.261a.542.542 0 0 1 .37.146l3.651 3.5A.5.5 0 0 1 12 4Zm-1.781-.5L8.348 1.707V3.5h1.87Z"
      />
    </svg>
  );
  export default SvgCSV;
  