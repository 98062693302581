export const qualificationFilterHelper = (
    // qualificationFilter: string,
    isAny: boolean,
    isBachelors: boolean,
    isDoctorate: boolean,
    isdiploma: boolean,
    isMasters: boolean,
    isOther: boolean,
  ) => {
    let qualificationFilter: string = '';
    if (isBachelors) {
      qualificationFilter += 'Bachelor,';
    }
    if (isDoctorate) {
      qualificationFilter += 'Doctorate,';
    }
    if (isMasters) {
      qualificationFilter += 'Master,';
    }
    if (isdiploma) {
      qualificationFilter += 'Diploma,';
    }
    if (isOther) {
      qualificationFilter += 'Other,';
    }
    qualificationFilter = qualificationFilter.replace(/,$/, '');
  
    if (!qualificationFilter) {
      qualificationFilter = '';
    }
    return qualificationFilter;
  };