import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { Dropdown } from 'react-bootstrap';
import { AppDispatch, RootState } from '../../store';
import { mediaPath } from '../constValue';
import { calendarRoute } from '../../appRoutesPath';
import Svgeditingnotes from '../../icons/editingnotes';
import SvgBoxEdit from '../../icons/SvgBoxEdit';
import SvgDotMenu from '../../icons/SvgDotMenu';
import { Button, LinkWrapper, Modal } from '../../uikit';
import Flex from '../../uikit/Flex/Flex';
import { firstNameChar, getDateString, isEmpty } from '../../uikit/helper';
import Text from '../../uikit/Text/Text';
import {
  deleteGoogleEventMiddleware,
  deleteOutlookEventMiddleware,
} from '../calendarModule/store/middleware/calendarmiddleware';
import styles from './interviewscorecardtab.module.css';
import { eventsApplicantsMiddleware } from './store/middleware/applicantProfileMiddleware';
// import { applicantIntegratemailReducers } from './store/reducer/applicantProfileReducer';
// import { googleEditEventMiddleware, outlookEditEventMiddleware } from '../calendarModule/store/middleware/calendarmiddleware';
// import { EventEntity } from './applicantProfileTypes';
const cx = classNames.bind(styles);
interface Props {
  hanldeRefresh?: any;
  seteventDeleteLoader?: (val?: any) => void;
}
export const meetingTitle = ({
  hanldeRefresh,
  seteventDeleteLoader,
}: Props) => [
    {
      title: 'Event Title',
      dataIndex: 'title',
      key: 'title',
      flex: 3,

      render: (value: string, attendeesevents) => (
        <Text
          style={{
            textOverflow: 'ellipsis',
            overflow: ' hidden',
            whiteSpace: 'nowrap',
            maxWidth: '350px',
            fontSize: '13px',
          }}
          title={value}
        >
          {value}
        </Text>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      index: 'index',
      flex: 1,
      render: (value: string) => (
        <Flex>
          <Text size={12}>{getDateString(value, 'll')}</Text>
        </Flex>
      ),
    },
    {
      title: 'Timings',
      dataIndex: 'time',
      key: 'time',
      flex: 1.5,
      render: (value: string) => (
        <Flex>
          <Text style={{ fontSize: '13px' }}>{value}</Text>
        </Flex>
      ),
    },
    {
      title: 'Duration',
      dataIndex: 'Time',
      key: 'Time',
      index: 'index',
      flex: 1.5,

      render: (value: number, index) => {
        const [duration, setduration] = useState(value);
        const [hours, sethours] = useState<any>();
        const [minutes, setminutes] = useState<any>();
        useEffect(() => {
          const values = Math.floor(value / 60);
          sethours(values);
          const min = value % 60;
          setminutes(min);
        }, [value]);
        return (
          <Flex between row style={{ borderRight: 'none', display: 'flex' }}>
            {hours === 0 ? '' : hours} {hours === 0 ? '' : 'Hour'}
            {minutes === 0 ? '' : ' '} {minutes === 0 ? '' : minutes}
            {minutes === 0 ? '' : ' Minutes'}
          </Flex>
        );
      },
    },
    {
      title: 'Created By',
      dataIndex: 'organizer',
      key: 'organizer',
      index: 'index',
      flex: 2,
      render: (value: string, index) => {
        const attendeesValue = index.attender;
        const [isColor, setColor] = useState<string[]>([]);
        useEffect(() => {
          // checkAuth();
          const colorCode = [
            '#d08014',
            '#d04343',
            '#db1f77',
            '#c0399f',
            '#6367de',
            '#286eb4',
            '#0f828f',
            '#7ca10c',
            '#925ace',
            '#647987',
          ];

          setColor(colorCode);
        }, []);
        return (
          <Flex row center>

            {attendeesValue.find((o) => o.org_id === index.org_id).image ===
              'default.jpg' ||
              isEmpty(
                attendeesValue.find((o) => o.org_id === index.org_id).image,
              ) ? (
              <div
                className={cx('profile')}
                style={{
                  backgroundColor: isColor[index.index % isColor.length],
                }}
              >
                { }
                <Text
                  color="black"
                  transform="uppercase"
                  className={styles.firstlastchar}
                >
                  {attendeesValue
                    .find((o) => o.org_id === index.org_id)
                    .first_name.charAt(0)}
                  {attendeesValue
                    .find((o) => o.org_id === index.org_id)
                    .last_name.charAt(0)}
                </Text>
              </div>
            ) : (
              <img
                alt="profile"
                height={30}
                width={30}
                style={{
                  borderRadius: '100%',
                  objectFit: 'cover',
                  marginRight: 8,
                  height: 30,
                  width: 30,
                }}
                src={
                  mediaPath +
                  attendeesValue.find((o) => o.org_id === index.org_id).image
                }
              />
            )}

            <Flex style={{ fontSize: '13px' }} middle>
              {/* {index.organizer.user__first_name +
              ' ' +
              index.organizer.user__last_name} */}
              {attendeesValue.find((o) => o.org_id === index.org_id).full_name}
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'web_url',
      key: 'web_url',
      align: 'center',
      index: 'index',

      render: (value: string, index) => {
        const history = useHistory();
        const dispatch: AppDispatch = useDispatch();
        const [openEventDeleteModal, setOpenEventDeleteModal] = useState(false);
        const handleClick = () => {
          const phrase = index.title;
          const match = phrase.match(/^\w+/);
          const firstWord = match ? match[0] : '';
          const params = new URLSearchParams();
          params.append('eventhandelerid', value);
          params.append('checkstatus', mail);
          params.append('eventhandeler', 'true');
          params.append('can_id_meeting',can_id);
          params.append('jd_id_meeting',jd_id);
          const url = `${calendarRoute}?${params}`;
          if (firstWord === 'Microsoft') {
            window.open(url);
            // window.open(calendarRoute);
            localStorage.setItem('eventhandeler', 'true');
            localStorage.setItem('eventhandelerid', value);
            
          } else {
            window.open(url);
            localStorage.setItem('eventhandeler', 'true');
            localStorage.setItem('eventhandelerid', value);
             
          }
        };


        const schedulehandleClick = () => {
          window.open(calendarRoute);
          localStorage.setItem('scheduleven', 'false');
        };
        const { can_id, jd_id, mail } = useSelector(
          ({
            applicantProfileInitalReducers,
            applicantIntegratemailReducers,
          }: RootState) => {
            return {
              can_id: applicantProfileInitalReducers.can_id,
              jd_id: applicantProfileInitalReducers.jd_id,
              mail: applicantIntegratemailReducers.mail,
            };
          },
        );

        // delete the secheduled enent.
        const handleDelete = () => {
          const phrase = index.title;
          const match = phrase.match(/^\w+/);
          const firstWord = match ? match[0] : '';
          seteventDeleteLoader(true);
          if (mail === 'OUTLOOK') {
            dispatch(deleteOutlookEventMiddleware({ eventId: value })).then(
              (res) => {
                if (res) {
                  {
                    hanldeRefresh();
                    dispatch(
                      eventsApplicantsMiddleware({ can_id: index.can_id }),
                    ).then(() => {
                      hanldeRefresh();
                      seteventDeleteLoader(false);
                    });
                  }
                }
              },
            );
          } else {
            dispatch(deleteGoogleEventMiddleware({ eventId: value })).then(
              (res) => {
                if (res) {
                  {
                    hanldeRefresh();
                    dispatch(
                      eventsApplicantsMiddleware({ can_id: index.can_id }),
                    ).then(() => {
                      hanldeRefresh();
                      seteventDeleteLoader(false);
                    });
                  }
                }
              },
            );
          }
        };

        // join url will open in new tab.
        const joiningUrl = () => {
          window.open(index.join_url, '_blank');
        };
        const { Permission } = useSelector(
          ({ userProfileReducers }: RootState) => {
            return {
              Permission: userProfileReducers.user.id,
            };
          },
        );

        // get the event based on outlook or gmail.
        const getEventHasMeeting = (title: string, inde) => {
          let eventType = new RegExp(/[a-zA-Z]+ [a-zA-Z]+ /).exec(title);
          if (eventType) {
            if (eventType[0].includes('Google Meet')) {
              return true;
            } else if (eventType[0].includes('Microsoft Teams')) {
              return true;
            } else if (inde.slotter === true) {
              if (inde.join_link === true) {
                return true;
              }
            }
          }
          return false;
        };

        // warning modal pop for delete event
        const DeleteWarningPopUp = (
          <Modal open={openEventDeleteModal}>
            <div className={styles.deleteWarningPopUp}>
              {/* <div className={styles.warningMessage}> */}
              <Flex flex={6} center>
                <Text color="black2" size={13} className={styles.insertStyles}>
                  Meeting will be canceled and notified to the attendees.
                </Text>
                <Text color="black2" size={13} className={styles.insertStyles}>
                  Are you sure to proceed further?
                </Text>
              </Flex>
              {/* <SvgInfo /> */}

              {/* </div> */}

              <div
                className={styles.actionButtonWrapper}
                style={{ marginTop: '20px' }}
              >
                <Button
                  className={styles.cancel}
                  style={{ marginRight: 8 }}
                  onClick={() => setOpenEventDeleteModal(false)}
                >
                  No, Thanks
                </Button>
                <Button
                  onClick={() => {
                    setOpenEventDeleteModal(false);
                    handleDelete();
                  }}
                  className={styles.deleteButton}
                >
                  Cancel Meeting
                </Button>
              </div>
            </div>
          </Modal>
        );
        return (
          <Flex
            between
            row
            style={{ borderRight: 'none', display: 'flex' }}
            center
            middle
          >
            <Dropdown className="dropdownButton dropleft">
              <Dropdown.Toggle
                disabled={
                  Number(Permission) !== Number(index.org_id) &&
                  !getEventHasMeeting(index.title, {})
                }
                // onClick={handleOpenPopup}
                style={{
                  borderColor: 'unset',
                  backgroundColor: 'unset',
                  boxShadow: 'none',
                  padding: '0px',
                  marginRight: '5px',
                }}
                id="dropdown-basic"
              >
                <SvgDotMenu width={16} height={16} fill="#581845" />
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ minWidth: '5rem' }}>
                {Number(Permission) === Number(index.org_id) ? (
                  <>
                    {index.slotter === true ? (
                      ''
                    ) : (
                      <>
                        <Dropdown.Item onClick={() => handleClick()}>
                          <Flex row center className={styles.dropDownListStyle}>
                            <Text style={{ cursor: 'pointer' }}>Edit</Text>
                          </Flex>
                        </Dropdown.Item>
                      </>
                    )}
                  </>
                ) : (
                  ''
                )}
                {getEventHasMeeting(index.title, index) && (
                  <Dropdown.Item onClick={joiningUrl}>
                    <Flex row center className={styles.dropDownListStyle}>
                      <Text style={{ cursor: 'pointer' }}>Join</Text>
                    </Flex>
                  </Dropdown.Item>
                )}
                {Number(Permission) === Number(index.org_id) && (
                  <Dropdown.Item onClick={() => setOpenEventDeleteModal(true)}>
                    <Flex row center className={styles.dropDownListStyle}>
                      <Text style={{ cursor: 'pointer' }}>Delete</Text>
                    </Flex>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            {openEventDeleteModal ? DeleteWarningPopUp : null}
          </Flex>
        );
      },
    },
  ];
export const meetingTitlepast = ({
  hanldeRefresh,
  seteventDeleteLoader,
}: Props) => [
    {
      title: 'Event Title',
      dataIndex: 'title',
      key: 'title',
      flex: 3,
      render: (value: string, attendeesevents) => (
        <Text
          style={{
            textOverflow: 'ellipsis',
            overflow: ' hidden',
            whiteSpace: 'nowrap',
            maxWidth: '350px',
            fontSize: '13px',
          }}
          title={value}
        >
          {value}
        </Text>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      index: 'index',
      flex: 1,
      render: (value: string) => (
        <Flex>
          <Text size={12}>{getDateString(value, 'll')}</Text>
        </Flex>
      ),
    },
    {
      title: 'Timings',
      dataIndex: 'time',
      key: 'time',
      flex: 1.5,
      render: (value: string) => (
        <Flex>
          <Text style={{ fontSize: '13px' }}>{value}</Text>
        </Flex>
      ),
    },
    {
      title: 'Duration',
      dataIndex: 'Time',
      key: 'Time',
      index: 'index',
      flex: 1.5,

      render: (value: number, index) => {
        const [duration, setduration] = useState(value);
        const [hours, sethours] = useState<any>();
        const [minutes, setminutes] = useState<any>();
        useEffect(() => {
          const values = Math.floor(value / 60);
          sethours(values);
          const min = value % 60;
          setminutes(min);
        }, [value]);
        return (
          <Flex between row style={{ borderRight: 'none', display: 'flex' }}>
            {hours === 0 ? '' : hours} {hours === 0 ? '' : 'Hour'}
            {minutes === 0 ? '' : ' '} {minutes === 0 ? '' : minutes}
            {minutes === 0 ? '' : ' Minutes'}
          </Flex>
        );
      },
    },
    {
      title: 'Created By',
      dataIndex: 'organizer',
      key: 'organizer',
      index: 'index',
      flex: 2,
      render: (value: string, index) => {
        const attendeesValue = index.attender;
        const [isColor, setColor] = useState<string[]>([]);
        useEffect(() => {
          // checkAuth();
          const colorCode = [
            '#d08014',
            '#d04343',
            '#db1f77',
            '#c0399f',
            '#6367de',
            '#286eb4',
            '#0f828f',
            '#7ca10c',
            '#925ace',
            '#647987',
          ];

          setColor(colorCode);
        }, []);
        return (
          <Flex row center>
            {isEmpty(
              attendeesValue.find((o) => o.org_id === index.org_id).image,
            ) ? (
              <div
                className={cx('profile')}
                style={{
                  backgroundColor: isColor[index.index % isColor.length],
                }}
              >
                { }
                <Text
                  color="black"
                  transform="uppercase"
                  className={styles.firstlastchar}
                >
                  {attendeesValue
                    .find((o) => o.org_id === index.org_id)
                    .first_name.charAt(0)}
                  {attendeesValue
                    .find((o) => o.org_id === index.org_id)
                    .last_name.charAt(0)}
                </Text>
              </div>
            ) : (
              <img
                alt="profile"
                height={30}
                width={30}
                style={{
                  borderRadius: '100%',
                  objectFit: 'cover',
                  marginRight: 8,
                  height: 30,
                  width: 30,
                }}
                src={
                  mediaPath +
                  attendeesValue.find((o) => o.org_id === index.org_id).image
                }
              />
            )}

            <Flex style={{ fontSize: '13px' }} middle>
              {/* {index.organizer.user__first_name +
              ' ' +
              index.organizer.user__last_name} */}
              {attendeesValue.find((o) => o.org_id === index.org_id).full_name}
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'web_url',
      key: 'web_url',
      align: 'center',
      index: 'index',

      render: (value: string, index) => {
        const history = useHistory();
        const dispatch: AppDispatch = useDispatch();
        const [openEventDeleteModal, setOpenEventDeleteModal] = useState(false);
        const handleClick = () => {
          const phrase = index.title;
          const match = phrase.match(/^\w+/);
          const firstWord = match ? match[0] : '';
          const params = new URLSearchParams();
          params.append('eventhandelerid', value);
          params.append('checkstatus', mail);
          params.append('can_id_meeting',can_id);
          params.append('jd_id_meeting',jd_id);
          params.append('eventhandeler', 'true');
          const url = `${calendarRoute}?${params}`;
          if (firstWord === 'Microsoft') {
            window.open(url);
            localStorage.setItem('eventhandeler', 'true');
            localStorage.setItem('eventhandelerid', value);
            localStorage.setItem('checkstatus', 'OUTLOOK');
          } else {
            window.open(url);
            localStorage.setItem('eventhandeler', 'true');
            localStorage.setItem('eventhandelerid', value);
            localStorage.setItem('checkstatus', 'GOOGLE');
          }
        };


        const schedulehandleClick = () => {
          window.open(calendarRoute);
          localStorage.setItem('scheduleven', 'false');
        };
        const { can_id, jd_id, mail } = useSelector(
          ({
            applicantProfileInitalReducers,
            applicantIntegratemailReducers,
          }: RootState) => {
            return {
              can_id: applicantProfileInitalReducers.can_id,
              jd_id: applicantProfileInitalReducers.jd_id,
              mail: applicantIntegratemailReducers.mail,
            };
          },
        );

        // delete the secheduled enent.
        const handleDelete = () => {
          const phrase = index.title;
          const match = phrase.match(/^\w+/);
          const firstWord = match ? match[0] : '';
          seteventDeleteLoader(true);
          if (mail === 'OUTLOOK') {
            dispatch(deleteOutlookEventMiddleware({ eventId: value })).then(
              (res) => {
                if (res) {
                  { 
                    hanldeRefresh();
                    dispatch(
                      eventsApplicantsMiddleware({ can_id: index.can_id }),
                    ).then(() => {
                      hanldeRefresh();
                      seteventDeleteLoader(false);
                    });
                  }
                }
              },
            );
          } else {
            dispatch(deleteGoogleEventMiddleware({ eventId: value })).then(
              (res) => {
                if (res) {
                  {
                    hanldeRefresh();
                    dispatch(
                      eventsApplicantsMiddleware({ can_id: index.can_id }),
                    ).then(() => {
                      hanldeRefresh();
                      seteventDeleteLoader(false);
                    });
                  }
                }
              },
            );
          }
        };

        // join url will open in new tab.
        const joiningUrl = () => {
          window.open(index.join_url, '_blank');
        };

        const { Permission } = useSelector(
          ({ userProfileReducers }: RootState) => {
            return {
              Permission: userProfileReducers.user.id,
            };
          },
        );

        // get the event based on outlook or gmail.
        const getEventHasMeeting = (title: string, inde) => {
          let eventType = new RegExp(/[a-zA-Z]+ [a-zA-Z]+ /).exec(title);
          if (eventType) {
            if (eventType[0].includes('Google Meet')) {
              return true;
            } else if (eventType[0].includes('Microsoft Teams')) {
              return true;
            } else if (inde.slotter === true) {
              if (inde.join_link === true) {
                return true;
              }
            }
          }
          return false;
        };

        // warning modal pop for delete event
        const DeleteWarningPopUp = (
          <Modal open={openEventDeleteModal}>
            <div className={styles.deleteWarningPopUp}>
              {/* <div className={styles.warningMessage}> */}
              <Flex flex={6} center>
                <Text color="black2" size={13} className={styles.insertStyles}>
                  Meeting will be canceled and notified to the attendees.
                </Text>
                <Text color="black2" size={13} className={styles.insertStyles}>
                  Are you sure to proceed further?
                </Text>
              </Flex>
              {/* <SvgInfo /> */}

              {/* </div> */}

              <div
                className={styles.actionButtonWrapper}
                style={{ marginTop: '20px' }}
              >
                <Button
                  className={styles.cancel}
                  style={{ marginRight: 8 }}
                  onClick={() => setOpenEventDeleteModal(false)}
                >
                  No, Thanks
                </Button>
                <Button
                  onClick={() => {
                    setOpenEventDeleteModal(false);
                    handleDelete();
                  }}
                  className={styles.deleteButton}
                >
                  Cancel Meeting
                </Button>
              </div>
            </div>
          </Modal>
        );
        return (
          <Flex
            between
            row
            style={{ borderRight: 'none', display: 'flex' }}
            center
            middle
          >
            <Dropdown className="dropdownButton dropleft">
              <Dropdown.Toggle
                disabled={
                  Number(Permission) !== Number(index.org_id) &&
                  !getEventHasMeeting(index.title, index)
                }
                // onClick={handleOpenPopup}
                style={{
                  borderColor: 'unset',
                  backgroundColor: 'unset',
                  boxShadow: 'none',
                  padding: '0px',
                  marginRight: '5px',
                }}
                id="dropdown-basic"
              >
                <SvgDotMenu width={16} height={16} fill="#581845" />
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ minWidth: '5rem' }}>

                {Number(Permission) === Number(index.org_id) ? (
                  <>
                    {index.slotter === true ? (
                      ''
                    ) : (
                      <>
                        <Dropdown.Item onClick={() => handleClick()}>
                          <Flex row center className={styles.dropDownListStyle}>
                            <Text style={{ cursor: 'pointer' }}>Edit</Text>
                          </Flex>
                        </Dropdown.Item>
                      </>
                    )}
                  </>
                ) : (
                  ''
                )}
                {getEventHasMeeting(index.title, index) && (
                  <Dropdown.Item onClick={joiningUrl}>
                    <Flex row center className={styles.dropDownListStyle}>
                      <Text style={{ cursor: 'pointer' }}>Join</Text>
                    </Flex>
                  </Dropdown.Item>
                )}
                {Number(Permission) === Number(index.org_id) && (
                  <Dropdown.Item onClick={() => setOpenEventDeleteModal(true)}>
                    <Flex row center className={styles.dropDownListStyle}>
                      <Text style={{ cursor: 'pointer' }}>Delete</Text>
                    </Flex>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            {openEventDeleteModal ? DeleteWarningPopUp : null}
          </Flex>
        );
      },
    },
  ];
