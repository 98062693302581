import StarsRating from 'react-star-rate';
import moment from 'moment';
import { useState } from 'react';
import ShowMore from 'react-show-more';
import Svgeditingnotes from '../../icons/editingnotes';
import SvgInterviewer from '../../icons/SvgInterviewer';
import SvgQuestion from '../../icons/SvgQuestion';
import SvgUserRating from '../../icons/SvgUserRating';
import { Card, Flex } from '../../uikit';
import Text from '../../uikit/Text/Text';
import SvgAdd from '../../icons/SvgAdd';
import SvgThumbsup from '../../icons/Svgthumbsup';
import SvgThumbsdown from '../../icons/Svgthumbsdown';
import SvgThumbsneutral from '../../icons/SvgthumbsNeutral';
import SvgAngle from '../../icons/SvgAngle';
import { formatTo12HrClock } from '../calendarModule/util';
import {
  CumulativeData,
  InterviewExtractData,
  NoOfInterview,
  Question,
} from './interviewerQuestionType';
import styles from './interviewscorecardtab.module.css';


type Props = {
  interviews?: InterviewExtractData;
  onEvaluate?: (id: number, value: Question[]) => void;
  cumulative?: any;
  no_of_interview?: any;
  datas?: any;
  UpdateEvaluate: (val: any) => void;
  user?: any;
  transformedDatas?: any;
  setdisabled?: (val: any) => void;
  setopencomment?: (val: any) => void;
  setgettingbasicdata?: (val: any) => void;
  setinterviewbasic?: (val: any) => void;
  setbasicuser?: (val: any) => void;
  user_overview?: any;
}

const InterviewScorecard = ({
  interviews,
  onEvaluate,
  cumulative,
  no_of_interview,
  datas,
  UpdateEvaluate,
  user,
  transformedDatas,
  setdisabled,
  setopencomment,
  setgettingbasicdata,
  setinterviewbasic,
  setbasicuser,
  user_overview
}: Props) => {
  const [isShowFeedback, setFeedbackShow] = useState<any>([]);

  //onclick the icon for  editing the interview question.
  const handleEdit = (interview_id) => {

    const mydata = datas?.find((id) => (id?.Id === interview_id))
    const allQuestions = mydata?.Question?.flatMap((category) =>
      category?.Value?.flatMap((level) => level["Map_question"])
    );
    const filteredQuestions = allQuestions?.filter((question) => question.scorecard !== null);
    UpdateEvaluate(filteredQuestions)
    onEvaluate(interview_id, filteredQuestions);
  };

  //onclick for view the evaluated form of interview question.
  const handleView = (user_id?:any,interview_id?:any) => {
    const mydata = user_overview?.filter((item) => Number(item?.user_id) === Number(user_id));
    const perticulardata = mydata?.filter((item) => Number(item?.interview_id) === Number(interview_id));
    // Assuming each item in mydata has a 'questions' property
    const allQuestions = perticulardata?.flatMap((item) => item?.questions).flatMap((data) => data);

    const filteredQuestions = allQuestions?.filter((question) => question.scorecard !== null); 
    UpdateEvaluate(filteredQuestions); 
    onEvaluate(perticulardata.filter(data=>data.interview_id === interview_id)[0].interview_id, filteredQuestions);
  };


  //handling the up and down arrow for viewing the feedback.
  const handlefeedback = (e) => {
    setFeedbackShow((prevId) =>
      prevId.includes(e)
        ? prevId.filter((prevIds) => prevIds !== e)
        : [...prevId, e]
    );
  }
  const filterAttendees = (attendee) => {
    // Check if any of the ratings (rating1 to rating5) is not zero
    const ratings = {
      rating1: attendee.rating1,
      rating2: attendee.rating2,
      rating3: attendee.rating3,
      rating4: attendee.rating4,
      rating5: attendee.rating5,
    };

    const nonZeroCount = Object.values(ratings).filter(rating => rating !== 0).length;
    return nonZeroCount;
  }

  //Handle thumbs based on the average value.
  const handleRecommendation = (avg_recommend: number) => {
    const value =
      avg_recommend && avg_recommend !== 0 && Math.round(avg_recommend);
    if (value === 1) {
      return <SvgThumbsdown />;
    }
    if (value === 2) {
      return <SvgThumbsneutral />;
    }
    if (value === 3) {
      return <SvgThumbsup />;
    }
    return null;
  };

  const AIcumulative = () => {
    if (cumulative && cumulative?.length !== 0) {
      const transformedData = cumulative.reduce((result, item) => {
        const existingInterview = result.find(interview => interview.interview_id === item.interview_id);

        if (item.total_score !== null) {
          if (!existingInterview) {
            result.push({
              interview_id: item.interview_id,
              overall_score: item.total_score,
              overall_recommend: item.avg_recommend,
              attendees: [{
                first_name: item.first_name,
                last_name: item.last_name,
                question_count: item.question_count,
                total_score: item.total_score,
                scored_question: item.scored_question,
                full_name: item.full_name,
                average_score: item.average_score,
                avg_recommend: item.avg_recommend,
                attendee: item.attendees,
                category: item.category,
                commend: item.commands
              }],
              commands: [{
                full_name: item.full_name,
                commenddd: item.commands,
              }],
            });
          } else {
            existingInterview.overall_score += item.total_score;
            existingInterview.overall_recommend += item.avg_recommend;

            if (item.total_score !== null) {
              existingInterview.attendees.push({
                first_name: item.first_name,
                last_name: item.last_name,
                question_count: item.question_count,
                total_score: item.total_score,
                scored_question: item.scored_question,
                full_name: item.full_name,
                average_score: item.average_score,
                avg_recommend: item.avg_recommend,
                attendee: item.attendees,
                category: item.category,
                commend: item.commands
              });
            }
            existingInterview.commands.push({
              full_name: item.full_name,
              commenddd: item.commands,
            });
          }
        }
        return result;
      }, []);


      {/* AI evaluation form of interview question.*/ }
      return (
        <Flex >
          <Flex flex={1} style={{ overflowY: 'scroll', display: 'flex' }}>
            {transformedData && transformedData?.map((doc, index) => {
              const scoredata = no_of_interview && no_of_interview?.map((id) => (id.id))
              if (scoredata.includes(doc.interview_id) && doc.overall_score !== null && doc.overall_score !== 0) {
                if (doc.total_score !== null && doc.total_score !== 0) {
                  const header = no_of_interview.find((d) => (d.id === doc.interview_id))
                  return (
                    <Card key={index} className={styles.cardStyle}>
                      <Flex row between center>
                        <Text color="theme" size={13}>{`${ header.slotter && header.slotter === true ? header?.slotter_title && header?.slotter_title : header?.event_type && header?.event_type
                          } / ${moment(
                            header?.s_time && header?.s_time,
                          ).format('MMM DD yyyy')} / ${formatTo12HrClock(
                            header?.s_time && header?.s_time,
                          )} - ${formatTo12HrClock(header?.s_time &&header?.e_time)}`}</Text>
                        {doc.attendees?.filter(info => info?.total_score !== null)?.map((e) => (e.attendee)).includes(user?.toString()) ?
                          <Flex onClick={() => handleEdit(doc.interview_id)} style={{ cursor: 'pointer' }} title='Edit scorecard'>
                            <Svgeditingnotes fill={'#581845'} />
                          </Flex> :
                          <Flex >
                            <Svgeditingnotes fill={'rgb(88 24 69/50%)'} />
                          </Flex>
                        }
                      </Flex>
                      <Flex row style={{ padding: '10px 0px' }}>
                        <Flex
                          style={{ display: 'flex' }}
                          marginRight={5}
                          width={115}
                        >
                          <Flex center middle marginRight={10} >
                            <Flex
                              marginLeft={15}
                              marginRight={10}
                              className={styles.OverallScore}
                            >
                              <Flex
                                className={styles.ratingStar}
                                marginLeft={5}
                              >
                                <StarsRating disabled count={1} value={1} />
                              </Flex>
                              <Text style={{ marginTop: '2px' }} size={12} color="theme">
                                {`${Math.round(doc?.overall_score / doc?.attendees?.length) || 0}/5`}
                              </Text>
                            </Flex>
                            <Text color="theme" style={{ marginTop: '3px' }}>
                              Overall Score
                            </Text>
                          </Flex>
                          <Flex
                            marginTop={10}
                            marginRight={10}
                            className={styles.recommended}
                          >
                            <Text style={{ marginRight: '5px' }}>
                              {handleRecommendation(doc?.overall_recommend)}
                            </Text>
                            {doc?.overall_recommend !== null && (
                              <Text color="theme">Recommended</Text>
                            )}
                          </Flex>
                        </Flex>
                        <div
                          style={{
                            left: '100%', // Adjust the left position as needed
                            top: 0,
                            bottom: 0,
                            borderLeft: '1px solid #C3C3C3',
                            content: '""',
                          }}
                        ></div>
                        <Flex width={'100%'} marginLeft={10}>
                          {doc.attendees
                            ?.filter(info => info?.total_score !== null)
                            .map((info, i) => {
                              return (
                                <>
                                  <Flex key={i} row marginTop={10} height={24} between flex={12}>
                                    <Flex row center>
                                      <Text title="Interviewer">
                                        <SvgInterviewer width={16} height={16} />
                                      </Text>
                                      <Text color='theme'
                                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                                        bold
                                        className={styles.changingtexts}
                                        title={info?.full_name}
                                        onClick={() => {
                                          handleView(info.attendee,doc.interview_id);
                                          setdisabled(true)
                                        }}>
                                        {info?.full_name}
                                      </Text>
                                    </Flex>
                                    <Flex row center marginLeft={15}>
                                      <Text title="categories count">
                                        <SvgQuestion width={16} height={16} />
                                      </Text>
                                      <Text style={{ marginLeft: '5px' }}>
                                        {`${info?.category} categories`}
                                      </Text>
                                    </Flex>
                                    <Flex row marginLeft={15} end>
                                      <Text title="Average Rating">
                                        <SvgUserRating width={16} height={16} />
                                      </Text>
                                      <Flex
                                        className={styles.ratingStar}
                                        marginTop={-2}
                                        marginLeft={5}
                                      >
                                        <StarsRating
                                          disabled
                                          count={5}
                                          value={info?.total_score}
                                        />
                                      </Flex>
                                    </Flex>
                                  </Flex>
                                  <Flex className={styles.showmore}>
                                    <ShowMore
                                      lines={2}
                                      className={styles.showmore}
                                      more={
                                        <span>
                                          <Text size={12} bold color='theme'>
                                            View more{' '}
                                          </Text>
                                          <SvgAngle width={12} height={12} fill={'#581845'} up={false} />
                                        </span>
                                      }
                                      less={
                                        <span>
                                          <Text size={12} bold color='theme'>
                                            View less{' '}
                                          </Text>
                                          <SvgAngle width={12} height={12} fill={'#581845'} up={true} />
                                        </span>
                                      }
                                    >
                                      <Text style={{ textAlign: 'justify' }} size={12}>
                                        <td
                                          className={styles.commentTextStyle}
                                          dangerouslySetInnerHTML={{
                                            __html: info?.commend,
                                          }}
                                        />
                                      </Text>
                                    </ShowMore>
                                  </Flex>
                                </>
                              )
                            })}
                        </Flex>
                      </Flex>
                    </Card>
                  );
                }
              }
            })}
          </Flex>
        </Flex>
      );
    }
    else
      return null
  }


  {/* Basic evaluation form  for applicants with out interview question. */ } 
  return (
    <>
      {transformedDatas && transformedDatas?.length !== 0 ?
        <>
          {transformedDatas?.map((docdata) => {
            return docdata.map((doc, index) => {
              return (
                <Card key={doc.interview_id} className={styles.cardStyle}>
                  <Flex row between center>
                    <Text color="theme" size={13}>{`${doc?.event_type
                      } / ${moment(
                        doc?.s_time,
                      ).format('MMM DD yyyy')} / ${formatTo12HrClock(
                        doc?.s_time,
                      )} - ${formatTo12HrClock(doc?.e_time)}`}</Text>
                    {doc.attendees?.filter(info => info?.total_score !== null)?.map((e) => (e.user_id)).includes(user) ?
                      <Flex onClick={() => {
                        setopencomment(true);
                        setgettingbasicdata(doc);
                        setinterviewbasic(doc.id);
                        setbasicuser('');
                      }} style={{ cursor: 'pointer' }} title='Edit scorecard'>
                        <Svgeditingnotes fill={'#581845'} />
                      </Flex> :
                      <Flex >
                        <Svgeditingnotes fill={'rgb(88 24 69/50%)'} />
                      </Flex>
                    }
                  </Flex>
                  <Flex row style={{ padding: '10px 0px' }}>
                    <Flex
                      style={{ display: 'flex' }}
                      marginRight={10}
                      width={117}
                    >
                      <Flex center middle marginRight={10} >
                        <Flex
                          marginLeft={15}
                          marginRight={10}
                          className={styles.OverallScore}
                        >
                          <Flex
                            className={styles.ratingStar}
                            marginLeft={5}
                          >
                            <StarsRating disabled count={1} value={1} />
                          </Flex>

                          <Text style={{ marginTop: '2px' }} size={12} color="theme">
                            {`${Math.round(doc?.overall_score / doc?.attendees?.length) || 0}/5`}
                          </Text>
                        </Flex>
                        <Text color="theme" style={{ marginTop: '3px' }} className={styles.textalign}>
                          Overall Score
                        </Text>
                      </Flex>
                    </Flex>
                    <div
                      style={{
                        left: '100%',
                        top: 0,
                        bottom: 0,
                        borderLeft: '1px solid #C3C3C3',
                        content: '""',
                      }}
                    ></div>
                    <Flex width={'100%'} marginLeft={10}>
                      {doc.attendees
                        .map((info, i) => {
                          return (
                            <>
                              <Flex key={i} row marginTop={10} height={24} between>
                                <Flex row center>
                                  <Text title="Interviewer">
                                    <SvgInterviewer width={16} height={16} />
                                  </Text>
                                  <Text color='theme'
                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    bold
                                    className={styles.changingtexts}
                                    title={info?.full_name}
                                    onClick={() => {
                                      setopencomment(true);
                                      setgettingbasicdata(doc);
                                      setdisabled(true);
                                      setbasicuser(info.user_id)
                                    }}
                                  >
                                    {info?.full_name}
                                  </Text>
                                </Flex>
                                <Flex row center marginLeft={15}>
                                  <Text title="categories count">
                                    <SvgQuestion width={16} height={16} />
                                  </Text>
                                  <Text style={{ marginLeft: '5px' }}>
                                    {`${filterAttendees(info)} categories`}
                                  </Text>
                                </Flex>

                                <Flex row marginLeft={15}>
                                  <Text title="Average Rating">
                                    <SvgUserRating width={16} height={16} />
                                  </Text>
                                  <Flex
                                    className={styles.ratingStar}
                                    marginTop={-2}
                                    marginLeft={5}
                                  >
                                    <StarsRating
                                      disabled
                                      count={5}
                                      value={info?.overall_score}
                                    />
                                  </Flex>
                                </Flex>
                              </Flex>
                              <Flex className={styles.showmore}>
                                <ShowMore
                                  lines={2}
                                  className={styles.showmore}
                                  more={
                                    <span>
                                      <Text size={12} bold color='theme'>View more </Text>
                                      <SvgAngle width={12} height={12} fill={"#581845"} up={false} />
                                    </span>}
                                  less={
                                    <span>
                                      <Text size={12} bold color='theme'>View less </Text>
                                      <SvgAngle width={12} height={12} fill={"#581845"} up={true} />
                                    </span>}
                                >
                                  <Text style={{ textAlign: 'justify' }} size={12}>
                                    <td
                                      className={styles.commentTextStyle}
                                      dangerouslySetInnerHTML={{
                                        __html: info?.commend,
                                      }}
                                    />
                                  </Text>
                                </ShowMore>
                              </Flex>
                            </>
                          )
                        })}
                    </Flex>
                  </Flex>
                </Card>
              );
            })
          })}</> : ''}
      <>
        {AIcumulative()}
      </>

    </>
  )
};

export default InterviewScorecard;
