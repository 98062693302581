import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SvgRoundTick from '../../icons/SvgRoundTick';
import { AppDispatch, RootState } from '../../store';
import Flex from '../../uikit/Flex/Flex';
import { getDateString, toCamelCase } from '../../uikit/helper';
import Text from '../../uikit/Text/Text';
import { InputRadio, Loader, SelectTag, Toast } from '../../uikit';
import styles from './screeningstatustab.module.css';
import InterviewScorecardTab from './InterviewScorecardTab';
import AllMatchTab from './AllMatchTab';
import { PipelineStagesDeleteMiddleWare, PipelineStagesGetMiddleWare, PipelineStagesPostMiddleWare } from './store/middleware/applicantProfileMiddleware';
import NotesTab from './NotesTab';

type Props = {
  title: string;
  issingletab: boolean;
};
const ScreeningStatusandAllMatchJobTab = ({ title, issingletab }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isloader, setloader] = useState(false);
  const [checkingstatus, setcheckingstatus] = useState(null);
  const [ischaractercheck, setcharactercheck] = useState<any>();
  const [isdublicatecheck, setdublicatecheck] = useState<any>();
  const [isdublicateerror, setdublicateerror] = useState<any>(false); 
  
  const { stages, invite } = useSelector(
    ({ applicantStausReducers }: RootState) => {
      return {
        stages: applicantStausReducers?.stages,
        invite: applicantStausReducers?.invite,
      };
    },
  );

  // getting the applicant current stage in this job
  useEffect(() => {
    if (stages.length >= 1) {
      const stage_name = stages[stages.length - 1].stage_id__stage_name;
      setcheckingstatus(stage_name);
    }
    if (stages.length === 0) {
      setcheckingstatus('');
    }
  }, [stages]);

  // default calling the API except the new applicant.
  useEffect(() => {
    if (checkingstatus !== null && checkingstatus !== 'Applied') {
      dispatch(PipelineStagesGetMiddleWare({
        jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
        can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
        pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id)
      }))
    }
  }, [checkingstatus])
  const { pipelinestages, is_move, current_stage, final_stage } = useSelector(
    ({ PipelineStagesReducers }: RootState) => {
      return {
        pipelinestages: PipelineStagesReducers?.stages,
        is_move: PipelineStagesReducers?.is_move,
        current_stage: PipelineStagesReducers?.current_stage,
        final_stage: PipelineStagesReducers?.final_stage
      };
    },
  );

  // condition for updating the stages after calling the post call then call the get call for getting the data from response.
  const handlepipelinestages = (event) => {
    if (event.__isNew__ === true) {
      if (event?.label?.replace(/\s+/g, '')?.trim()?.length > 25) {
        setcharactercheck(true)
      }
      else if (transformedData.filter(data => data.label === isdublicatecheck).length !== 0) {
        setdublicateerror(true)
      }
      else {
        setcharactercheck(false)
        setdublicateerror(false)
        const formData = new FormData();
        formData.append('jd_id', JSON.stringify(stages[stages?.length - 1]?.jd_id))
        formData.append('can_id', JSON.stringify(stages[stages?.length - 1]?.candidate_id))
        formData.append('pipeline_id', JSON.stringify(stages[stages?.length - 1]?.stage_id))
        const eventvalue = toCamelCase(event.value) 
        formData.append('stage_name', eventvalue)
        formData.append('customization', 'customization')
        dispatch(PipelineStagesPostMiddleWare({
          formData
        })).then(() => {
          Toast('Stage status created successfully.', 'SHORT', 'success');
          dispatch(PipelineStagesGetMiddleWare({
            jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
            can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
            pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id)
          }))
        })
      }
    }
    else {
      const data = pipelinestages?.find((e) => Number(e?.stage_id) === Number(event?.value))
      const formData = new FormData();
      formData.append('jd_id', JSON.stringify(stages[stages?.length - 1]?.jd_id))
      formData.append('can_id', JSON.stringify(stages[stages?.length - 1]?.candidate_id))
      formData.append('pipeline_id', JSON.stringify(stages[stages?.length - 1]?.stage_id))
      formData.append('update', JSON.stringify(data?.stage_id))
      if (Number((data?.stage_id) !== 1) && (Number(current_stage) !== 4)) {
        dispatch(PipelineStagesPostMiddleWare({
          formData
        })).then(() => {
          if (Number(data?.stage_id) === 4) {
            Toast('Stage status is set to completed.', 'SHORT', 'success');
          }
          else {
            Toast('Stage status updated successfully.', 'SHORT', 'success');
          }
          dispatch(PipelineStagesGetMiddleWare({
            jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
            can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
            pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id)
          }))
        })
      }
    }
  }

  // passing the pipeline stages to array format to pass as a option in select tag
  const transformedData = pipelinestages?.map(item => ({
    value: item?.stage_id,
    label: item?.stage_name,
    id: item?.stage_userbased,
    stageused: item?.stage_used
  }));

  // ondelete function in select option when don't want the stage status.
  const onDeleteoption = (e) => {
    dispatch(PipelineStagesDeleteMiddleWare({
      id: e
    })).then(() => {
      Toast('Stage status removed permanently.', 'SHORT', 'success');
      dispatch(PipelineStagesGetMiddleWare({
        jd_id: JSON.stringify(stages[stages?.length - 1]?.jd_id),
        can_id: JSON.stringify(stages[stages?.length - 1]?.candidate_id),
        pipeline_id: JSON.stringify(stages[stages?.length - 1]?.stage_id)
      })).then(() => {
        // setloader(false);
      })
    })

  }

  // on inputchange function for creating stage in select tag.
  const onCreatestage = (e) => {
    setdublicatecheck(e)
    setcharactercheck(false)
    setdublicateerror(false)
  }
 
  return (
    <Flex row flex={12}>
      {isloader ?
        <Loader /> :
        <Flex row flex={12}>
          <Flex
            flex={5.4}
            columnFlex
            style={{ overflowY: 'auto' }}
            className={styles.overAll}
            height={window.innerHeight - 120}
          >
            <Text bold color="theme" className={styles.screenText}>
              {title}
            </Text>

            {(stages || [])
              ?.map((doc, index) => {
                return (
                  <Flex key={index} row center className={doc && index !== stages && stages.length - 1 === index ? styles.statusListStyle : styles.statusListStyles1}>
                    <Flex className={styles.svgFlex}>
                      <SvgRoundTick height={30} width={30} fill={'#581845'} />
                      {index !== stages?.length - 1 && (
                        <div
                          className={stages && stages?.length > 0 && index === stages?.length - 2 ? (stages?.length === 2 ? styles.vrLine1 : styles.vrLine2)
                            : stages[index]?.stage_id__stage_name?.toUpperCase() === 'APPLIED' ? styles.vrLine3 : styles.vrLine1
                          }
                          style={{ borderRightColor: '#581845' }}
                        />
                      )}
                    </Flex>
                    <Flex>
                      <Text className={styles.statusStyle}>
                        {doc?.stage_id__stage_name} on{' '}
                        {getDateString(doc && doc?.created_on, 'll')}
                      </Text>
                      {stages && stages.length > 0 && index !== stages.length - 1 && doc?.is_completed !== undefined &&
                        <Text className={styles.statusStyle} bold color='theme'>Completed</Text>}
                      {stages && stages[index]?.stage_id__stage_name?.toUpperCase() !== 'APPLIED' &&
                        stages.length > 0 && index === stages.length - 1 && ( 
                          <>
                            {isdublicateerror || ischaractercheck ?
                              <>
                                <Flex width={215} disabled={Number(current_stage) === 4} className={styles.statusStyle}>
                                  <SelectTag
                                    createstage
                                    Deletecomponent
                                    onDeleteoption={onDeleteoption}
                                    onInputChange={onCreatestage}
                                    value={transformedData?.find((e) => Number(e?.value) === Number(current_stage))}
                                    isCreate
                                    isSearchable={true}
                                    isDisabled={Number(current_stage) === 4}
                                    options={transformedData}
                                    placeholder="Please select the stages"
                                    onChange={(e) => handlepipelinestages(e)}
                                  />
                                </Flex>
                                <Flex className={styles.statusStyle}>{!isdublicateerror && ischaractercheck && <Text color='error' style={{ position: 'absolute' }}>Stage status should not exceed 25 characters</Text>}
                                  {isdublicateerror && <Text color='error' style={{ position: 'absolute' }}>Stage name already exists.</Text>}
                                </Flex>
                              </> :
                              <Flex width={215} disabled={Number(current_stage) === 4} className={styles.statusStyle}>
                                <SelectTag
                                  createstage
                                  Deletecomponent
                                  onDeleteoption={onDeleteoption}
                                  onInputChange={onCreatestage}
                                  value={transformedData?.find((e) => Number(e?.value) === Number(current_stage))}
                                  isCreate
                                  isSearchable={true}
                                  isDisabled={Number(current_stage) === 4}
                                  options={transformedData}
                                  placeholder="Please select the stages"
                                  onChange={(e) => handlepipelinestages(e)}
                                />
                              </Flex>}
                          </>
                        )}
                    </Flex>
                  </Flex>
                );
              })
              .reverse()}
            {
              invite.length !== 0 && stages.length !== 0 &&
              <Flex
                className={styles.vrLin}
                style={{ borderRightColor: '#581845' }}
              />
            }

            {invite && invite.length === 1 && invite[0].is_interested !== null
              &&

              <Flex row center className={styles.statusListStyle}>
                <Flex className={styles.svgFlex}>
                  <SvgRoundTick height={30} width={30} fill={'#581845'} />
                  {stages.length !== 0 && (
                    <div
                      className={styles.vrLines}
                      style={{ borderRightColor: '#581845' }}
                    />
                  )}
                </Flex>
                <Text className={styles.statusStyle}> {invite[0].is_interested === true
                  ? 'Candidate responded as "Interested"'
                  : '' || invite[0].is_interested === false
                    ? 'Candidate responded as "Not Interested"'
                    : ''}{' '}
                  on {getDateString(invite && invite[0]?.responded_date, 'll')}
                </Text>
              </Flex>

            }
            {invite && invite.length === 1
              &&
              <Flex row center className={styles.statusListStyle}>
                <Flex className={styles.svgFlex}>
                  <SvgRoundTick height={30} width={30} fill={'#581845'} />
                  {invite[0].is_interested !== null && (
                    <div
                      className={styles.vrLines}
                      style={{ borderRightColor: '#581845' }}
                    />
                  )}
                </Flex>
                <Text className={styles.statusStyle}>
                  Invited on {getDateString(invite && invite[0].created_at, 'll')}
                </Text>
              </Flex>

            }
          </Flex>
          <Flex
            height={window.innerHeight - 115}
            style={{
              border: '0.3px solid #C3C3C3',
              width: '0.5px',
              margin: '15px 5px 10px 5px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          ></Flex>
          <Flex flex={6.4}>
            <NotesTab />
          </Flex>
        </Flex>}
    </Flex>
  );
};

export default ScreeningStatusandAllMatchJobTab;
