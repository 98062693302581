// const defaultProps = {
//   width: 22,
//   height: 22,
//   fill: 'none',
// };

// const SvgEmail = ({ width, height, fill }: typeof defaultProps) => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width={width}
//       height={height}
//       fill={fill}
//     >
//       <path
//         d="M3.22.519h10.717c.656 0 1.285.292 1.749.813a2.96 2.96 0 0 1 .724 1.964v8.334a2.96 2.96 0 0 1-.724
//       1.964c-.464.52-1.093.813-1.749.813H3.22c-.656 0-1.285-.292-1.749-.813a2.96 2.96 0 0 1-.724-1.964V3.296c0-.
//       736.26-1.443.724-1.964C1.935.812 2.564.52 3.22.52Zm0 .925c-.412 0-.775.158-1.055.436l6.413 4.657 6.414-4.657a1.472 1.472 0
//        0 0-1.055-.436H3.22Zm5.358 6.213-6.9-5.027a2.198 2.198 0 0 0-.107.666v8.334c0 .49.174.962.483 1.31.31.346.729.542 1.166.542h10.717c.437
//       0 .857-.196 1.166-.543.31-.347.483-.818.483-1.31V3.297a2.2 2.2
//       0 0 0-.107-.666l-6.9 5.027Z"
//         fill="#581845"
//       />
//     </svg>
//   );
// };

// SvgEmail.defaultProps = defaultProps;
// export default SvgEmail;

//import * as React from 'react';

//import * as React from 'react';

// const defaultProps = {
//   width: 22,
//   height: 22,
//   fill: 'none',
// };

// const SvgEmail = ({ width, height, fill }: typeof defaultProps) => {
//   return (
//     <svg
//       width={width}
//       height={height}
//       fill={fill}
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M3.22.519h10.717c.656 0 1.285.292 1.749.813a2.96 2.96 0 0 1 .724 1.964v8.334a2.96 2.96 0
//       0 1-.724 1.964c-.464.52-1.093.813-1.749.813H3.22c-.656 0-1.285-.292-1.749-.813a2.96 2.96 0 0
//       1-.724-1.964V3.296c0-.736.26-1.443.724-1.964C1.935.812 2.564.52 3.22.52Zm0 .925c-.412 0-.775.158-1.055.436l6.413
//        4.657 6.414-4.657a1.472 1.472 0 0 0-1.055-.436H3.22Zm5.358 6.213-6.9-5.027a2.198 2.198 0
//        0 0-.107.666v8.334c0 .49.174.962.483 1.31.31.346.729.542 1.166.542h10.717c.437 0 .857-.196 1.166-.543.31-.347.483-.818.483-1.31V3.297a2.2 2.2 0
//         0 0-.107-.666l-6.9 5.027Z"
//         fill="#581845"
//       />
//     </svg>
//   );
// };
// export default SvgEmail;

//import * as React from 'react';

const defaultProps = {
  width: 17,
  height: 17,
  fill: 'none',
};

const SvgEmail = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 13 13.5"
    >
      <path
        fill="#581845"
        d="M1.3 13.5c-.358 0-.663-.165-.918-.495C.127 12.674 0 12.277 0 11.812V1.688C0 1.222.127.826.382.496.637.166.942 0 1.3 0h10.4c.357 0 .664.165.918.496.255.33.382.727.382 1.192v10.124c0 .465-.127.862-.382 1.193-.254.33-.56.495-.918.495H1.3ZM11.7 3.375 6.841 7.32a.597.597 0 0 1-.341.126.597.597 0 0 1-.341-.127L1.3 3.376v8.438h10.4V3.374ZM6.5 5.906l5.2-4.218H1.3l5.2 4.218Zm-5.2-2.32V2.34v.021-.01 1.234Z"
      />
    </svg>
  );
};
export default SvgEmail;
