import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '../../../../store';
import Button from '../../../../uikit/Button/Button';
import Flex from '../../../../uikit/Flex/Flex';
import InputText from '../../../../uikit/InputText/InputText';
import { WebsiteEmailValidation } from '../../../Login/store/middleware/loginMiddleWare';
import { ErrorMessage, Toast } from '../../../../uikit';
import { isEmpty } from '../../../../uikit/helper';
import { THIS_FIELD_REQUIRED } from '../../../constValue';
import { postwebsiteeventmiddleware } from '../store/middleware/eventmiddleware';
import styles from './website.module.css'
import { WebsiteProps, WebsiteTypes } from './WebsiteTypes';

const initial: WebsiteTypes = {
  name: '',
  email: '',
  description: '',
  destination: '',
  contact: '',
  company: '',
};

const WebsiteEvent = ({
  onClose,
  event_id,
  onSubmitEvent,
  setwebsite,
  data,
  time,
  date,
}: WebsiteProps) => {
  const dispatch: AppDispatch = useDispatch();
  const [hasValid, setHasValid] = useState(false);
  const [lengthValid, setLengthValid] = useState(false);
  const [isGetMail, setMail] = useState('');
  const [MailIncorrect, setMailIncorrect] = useState(false);


  //Form Errors
  function handleEventValid(values: WebsiteTypes) {
    const errors: Partial<WebsiteTypes> = {};
    if (isEmpty(values.name.trim())) {
      errors.name = THIS_FIELD_REQUIRED;
    }
    if (isEmpty(values.email.trim())) {
      setLengthValid(false);
      setMailIncorrect(false);
      errors.email = THIS_FIELD_REQUIRED;
    }
    if (isEmpty(values.contact?.trim())) {
      errors.contact = THIS_FIELD_REQUIRED;
    }
    if (isEmpty(values.description.trim())) {
      errors.description = THIS_FIELD_REQUIRED;
    }
    // if (isEmpty(values.destination.trim())) {
    //   errors.destination = THIS_FIELD_REQUIRED;
    // }
    if (isEmpty(values.company.trim())) {
      errors.company = THIS_FIELD_REQUIRED;
    }
    return errors
  }

  //UseEffect For Email Checking
  useEffect(() => {
    dispatch(WebsiteEmailValidation({ email: isGetMail })).then((res) => {
      if (res.payload.success === true) {
        setHasValid(false);
      }
      if (res.payload.success === false) {
        setHasValid(true);
      }
    });
  }, [isGetMail, MailIncorrect]);

  //EMAIL VALID FORMAT CHECKING
  function ValidationMail() {
    if (formik.values.email && !formik.values.email.includes('@')) {
      setMailIncorrect(true)
      return true
    }
    return false
  }
  //Handle DateConvertion
  const HandleDateConvertion = (d) => {
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    const value = `${day}/${month}/${year}`;
    return value;
  };

  //HandleSubmit Form
  function handleSubmitForm(values: WebsiteTypes) {
    const success = ValidationMail()
    if (!success) {
      const formData = new FormData()
      formData.append('id', event_id.toString())
      formData.append('name', formik.values.name)
      formData.append('email', formik.values.email)
      formData.append('contact', formik.values.contact)
      formData.append('company', formik.values.company)
      formData.append('description', formik.values.description)
      // formData.append('destination', formik.values.destination)
      const selecteddate = HandleDateConvertion(date);
      formData.append('date', selecteddate)
      formData.append('time', time)
      const tzone = data?.find(t => t.times_zone !== null && t.times_zone !== undefined).times_zone
      const [timeOffset, locations] = tzone.split(' ');
      const locationWithoutParentheses = locations.slice(1, -1);
      const result = `${locationWithoutParentheses}`;
      formData.append('timezone', result)

      dispatch(postwebsiteeventmiddleware({ formData })).then((q) => {
        onSubmitEvent()
        setwebsite(false)
        Toast(SuccessToast, 'LONG');
      })
    }
  };

  // Email Verification
  const emailupdate = (e) => {
    const enteredEmail = e.target.value;
    if (enteredEmail.length > 50) {
      setLengthValid(true);
      setMail(enteredEmail)
    } else {
      const atIndex = enteredEmail.indexOf('@');
      if (atIndex !== -1 && atIndex < enteredEmail.length - 1) {
      } else {
        setHasValid(false);
      }
    }

    if (enteredEmail.trim()) {
      setMail(e.target.value);
      if (MailIncorrect === true) {
        setMailIncorrect(false)
      }
    }
  }

  // HandleKeyPress
  const handleKeyPress = (event) => {
    const allowedCharactersRegex = /[0-9+]/g;
    const keyPressed = event.key;


    if (!allowedCharactersRegex.test(keyPressed)) {
      event.preventDefault();
    }
  };


  //Formik
  const formik = useFormik({
    initialValues: initial,
    onSubmit: (values) => handleSubmitForm(values),
    validate: handleEventValid,
    enableReinitialize: true,
  });

  const EmailLengthValid = 'Email should be a maximum of 50 characters';
  const EmailValidation = 'Email already exist.';
  const SuccessToast = "Event Scheduled Successfully";
  const IncorrectEmailFormat = "Incorrect email format";

  return (
    <div>
      <Flex className={styles.window}>
        <Flex row className={styles.row}>
          <Flex flex={1} marginRight={25}>
            <InputText
              label="Name"
              size={13}
              required
              value={formik.values.name}
              placeholder="Enter your Name"
              style={{ marginTop: '5px', paddingLeft: '8px' }}
              onChange={(e) => {
                formik.setFieldValue('name', e.target.value);
              }}
            />
            <ErrorMessage
              name={'name'}
              errors={formik.errors}
              touched={formik.touched}
            />
          </Flex>

          <Flex flex={1} marginRight={25}>
            <InputText
              label="Company Name"
              size={13}
              required
              value={formik.values.company}
              placeholder="Enter your Company Name"
              maxLength={100}
              style={{ marginTop: '5px', paddingLeft: '8px' }}
              onChange={(e) => {
                formik.setFieldValue('company', e.target.value);
              }}
            />
            <ErrorMessage
              name={'company'}
              errors={formik.errors}
              touched={formik.touched}
            />
          </Flex>
        </Flex>
          
          {/* <Flex flex={1}>
            <InputText
              label="Destination"
              size={13}
              required
              value={formik.values.destination}
              placeholder="Enter your Destination"
              style={{ marginTop: '5px', paddingLeft: '8px' }}
              onChange={(e) => {
                formik.setFieldValue('destination', e.target.value);
              }}
            />
            <ErrorMessage
              name={'destination'}
              errors={formik.errors}
              touched={formik.touched}
            />
          </Flex> */}
       
        <Flex row className={styles.row}>
          <Flex flex={1} marginRight={25}>
            <InputText
              label="Email"
              size={13}
              required
              value={formik.values.email}
              placeholder="Enter your Email"
              style={{ marginTop: '5px', paddingLeft: '8px' }}
              maxLength={51}
              onChange={(e) => {
                emailupdate(e)
                formik.setFieldValue('email', e.target.value);
              }}
              autoComplete="new-password"
            />
            <Flex row>
              {lengthValid && (
                <div className={styles.error}>
                  {EmailLengthValid}
                </div>
              )}
              {hasValid && (
                <div className={styles.error}>
                  {EmailValidation}
                </div>
              )}
              {MailIncorrect && (
                <div className={styles.error}>
                  {IncorrectEmailFormat}
                </div>
              )}
            </Flex>
            <ErrorMessage
              name={'email'}
              errors={formik.errors}
              touched={formik.touched}
            />
          </Flex>
          <Flex flex={1} marginRight={25}>
            <InputText
              id="contact_number"
              label="Contact Number"
              required
              className={styles.inputStyle}
              placeholder="Enter your contact number"
              name="contact"
              style={{ marginTop: '5px', paddingLeft: '8px' }}
              maxLength={16}
              onKeyPress={handleKeyPress}
              onChange={(e: any) => {
                const regex =/^[0-9]*$/;
                if (regex.test(e.target.value)) {
                  formik.setFieldValue('contact', e.target.value);
                }
              }}
            />
            <ErrorMessage
              name={'contact'}
              errors={formik.errors}
              touched={formik.touched}
            />
          </Flex>
        </Flex>

        <Flex row className={styles.row}>
          <Flex flex={1}>
            <InputText
              label="Commands"
              size={13}
              required
              value={formik.values.description}
              placeholder="Enter your Commands here"
              onChange={(e) => {
                formik.setFieldValue('description', e.target.value);
              }}
              textarea
              style={{
                width: '96%',
                height: '100%',
                marginTop: '5px',
                paddingLeft: '8px',
                marginBottom: '10px',
              }}
            />
            <Flex marginTop={5}>
            <ErrorMessage
              name={'description'}
              errors={formik.errors}
              touched={formik.touched}
            /></Flex>
          </Flex>
        </Flex>

       

        <Flex row end>
          <Button
            onClick={onClose}
            className={styles.cancel}
            types={'primary'}
            textSize={13}
          >
            Cancel
          </Button>
          <Button onClick={formik.handleSubmit} textSize={13} className={styles.share}>
            Submit
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

export default WebsiteEvent;


