const Svgchatbotthumbsdown = (props) => (
<svg width="46" height="19" viewBox="0 0 46 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="45" height="18" rx="2.5" stroke="#581845" />
    <path d="M16.3329 4.66667C15.8296 4.66667 15.3743 4.85933 15.0229 5.16733L14.8976 5.03933C14.2576 4.548 12.4543 4 10.9996 
4C9.74694 4 9.2616 4.19533 8.8316 4.368L8.62094 4.45067C8.06494 4.654 7.56894 5.27 7.46227 5.91733L7.0196 9.89867C6.9036
10.5913 7.3136 11.3167 7.95294 11.5507C8.2156 11.6467 9.6276 11.8207 10.5416 11.924C10.4025 12.72 10.3329 13.5266 10.3336 
14.3347C10.3336 15.254 11.0809 16.0013 12.0003 16.0013C12.9196 16.0013 13.6669 15.254 13.6669 14.3347C13.6669 13.0847 14.1116 
12.51 14.7443 11.8687C14.929 12.1161 15.1688 12.3171 15.4446 12.4558C15.7205 12.5946 16.0248 12.6672 16.3336 12.668C16.8637 
12.6671 17.3718 12.4561 17.7466 12.0812C18.1214 11.7063 18.3322 11.1981 18.3329 10.668V6.668C18.3324 6.13761 18.1216 5.62907 
17.7466 5.2539C17.3717 4.87873 16.8633 4.66755 16.3329 4.66667ZM12.3329 14.3333C12.3329 14.5167 12.1829 14.6667 11.9996 
14.6667C11.8163 14.6667 11.6663 14.5167 11.6663 14.3333C11.6663 13.13 11.8369 12.1727 11.9856 11.4713L12.1836 10.5393L11.3029
 10.6647C10.8996 10.6313 8.68027 10.3667 8.41294 10.2967C8.37427 10.278 8.32494 10.1767 8.34094 10.0827L8.78294 6.09867C8.8076
  5.95 8.97694 5.73933 9.07827 5.70267L9.3296 5.60533C9.69227 5.45933 10.0063 5.33333 11.0003 5.33333C12.2763 5.33333 13.7456 
  5.83533 14.0889 6.09733C14.1929 6.17733 14.3329 6.47333 14.3329 6.66667V9.98467C14.3323 10.002 14.3063 10.4173 13.8616 
  10.862C13.2529 11.4707 12.3329 12.3907 12.3329 14.3333ZM16.9996 10.6667C16.9996 10.8435 16.9294 11.013 16.8043 11.1381C16.6793
  11.2631 16.5097 11.3333 16.3329 11.3333C16.1561 11.3333 15.9866 11.2631 15.8615 11.1381C15.7365 11.013 15.6663 10.8435 15.6663
   10.6667V6.66667C15.6663 6.48986 15.7365 6.32029 15.8615 6.19526C15.9866 6.07024 16.1561 6 16.3329 6C16.5097 6 16.6793 6.07024 
   16.8043 6.19526C16.9294 6.32029 16.9996 6.48986 16.9996 6.66667V10.6667Z" fill="#581845" />
    <path d="M31.0381 5.46875V14H29.2803L25.8525 8.28125V14H24.0947V5.46875H25.8525L29.2861 11.1934V5.46875H31.0381ZM32.1924
 10.8945V10.7715C32.1924 10.3066 32.2588 9.87891 32.3916 9.48828C32.5244 9.09375 32.7178 8.75195 32.9717 8.46289C33.2256
  8.17383 33.5381 7.94922 33.9092 7.78906C34.2803 7.625 34.7061 7.54297 35.1865 7.54297C35.667 7.54297 36.0947 7.625 36.4697
   7.78906C36.8447 7.94922 37.1592 8.17383 37.4131 8.46289C37.6709 8.75195 37.8662 9.09375 37.999 9.48828C38.1318 9.87891
    38.1982 10.3066 38.1982 10.7715V10.8945C38.1982 11.3555 38.1318 11.7832 37.999 12.1777C37.8662 12.5684 37.6709 12.9102
     37.4131 13.2031C37.1592 13.4922 36.8467 13.7168 36.4756 13.877C36.1045 14.0371 35.6787 14.1172 35.1982 14.1172C34.7178
      14.1172 34.29 14.0371 33.915 13.877C33.5439 13.7168 33.2295 13.4922 32.9717 13.2031C32.7178 12.9102 32.5244 12.5684 
      32.3916 12.1777C32.2588 11.7832 32.1924 11.3555 32.1924 10.8945ZM33.8799 10.7715V10.8945C33.8799 11.1602 33.9033 11.4082
       33.9502 11.6387C33.9971 11.8691 34.0713 12.0723 34.1729 12.248C34.2783 12.4199 34.415 12.5547 34.583 12.6523C34.751
        12.75 34.9561 12.7988 35.1982 12.7988C35.4326 12.7988 35.6338 12.75 35.8018 12.6523C35.9697 12.5547 36.1045 12.4199
         36.2061 12.248C36.3076 12.0723 36.3818 11.8691 36.4287 11.6387C36.4795 11.4082 36.5049 11.1602 36.5049 
         10.8945V10.7715C36.5049 10.5137 36.4795 10.2715 36.4287 10.0449C36.3818 9.81445 36.3057 9.61133 36.2002 9.43555C36.0986
          9.25586 35.9639 9.11523 35.7959 9.01367C35.6279 8.91211 35.4248 8.86133 35.1865 8.86133C34.9482 8.86133 34.7451 8.91211
           34.5771 9.01367C34.4131 9.11523 34.2783 9.25586 34.1729 9.43555C34.0713 9.61133 33.9971 9.81445 33.9502 10.0449C33.9033
            10.2715 33.8799 10.5137 33.8799 10.7715Z" fill="#581845" />
</svg>)
export default Svgchatbotthumbsdown;
