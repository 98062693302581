import * as React from 'react';
const SvgFooterHeaderArrow = (props) => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
>
<path
      stroke="#581845"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7 10 5 5 5-5"
    />
</svg>
);
export default SvgFooterHeaderArrow;