import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../store'
import Flex from '../../uikit/Flex/Flex';
import SvgAngle from '../../icons/SvgAngle';
import { isEmpty, lowerCase, notSpecified } from '../../uikit/helper';
import Status from '../../uikit/Status/Status';
import Text from '../../uikit/Text/Text';
import Tab from '../../uikit/Tabs/Tab';
import Toast from '../../uikit/Toast/Toast';
import Loader from '../../uikit/Loader/Loader';
import Questionnaire from '../applicantprofilemodule/Questionnaire';
import { workYear } from '../common/commonHelper';
import CandiDateResumeTab from './CandiDateResumeTab';
import styles from './candidateabouttab.module.css';
const AboutTab = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isloader, setloader] = useState(false);
  const [issuccessfalse, setsuccessfalse] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const {
    can_id,
    jd_id
  } =
    useSelector(({ applicantProfileInitalReducers }: RootState) => {
      return {
        can_id: applicantProfileInitalReducers.can_id,
        jd_id: applicantProfileInitalReducers?.jd_id,
      };
    });


  const {
    candidate_details,
    questionnaire,
    Career_Trajectory,
    Achievements,
    Industry_Engagement,
    education,
    behaviour,
    Networking,
    summary,
    Expertise_and_Skills,
    isLoading } = useSelector(
      ({ applicantProfileInitalReducers, profilesummeryReducers }: RootState) => {
        return {
          candidate_details: applicantProfileInitalReducers.candidate_details,
          questionnaire: applicantProfileInitalReducers.questionnaire,
          Career_Trajectory: profilesummeryReducers.Career_Trajectory,
          Achievements: profilesummeryReducers.Achievements,
          Industry_Engagement: profilesummeryReducers.Industry_Engagement,
          education: profilesummeryReducers.education,
          behaviour: profilesummeryReducers.behaviour,
          Networking: profilesummeryReducers.Networking,
          summary: profilesummeryReducers.summary,
          Expertise_and_Skills: profilesummeryReducers.Expertise_and_Skills,
          isLoading: profilesummeryReducers.isLoading
        };
      },
    );

  // setting the loader based on the api responce for profile summary.
  useEffect(() => {
    if (isLoading === true) {
      setloader(true);
    }
    if (isLoading === false) {
      setloader(false);
      setsuccessfalse(false);
      localStorage.setItem('profile_summery', 'false');
    }
  }, [isLoading])

  // function for show more or less in the profile summery.
  const handleToggleShowMore = () => {
    setShowMore(!showMore);
  };

  const relocate = isEmpty(candidate_details[0].relocate)
    ? 'Not Specified'
    : candidate_details[0].relocate === true
      ? 'YES'
      : 'NO';


  const aboutData = [
    {
      lable: 'Contact Number:',
      value: notSpecified(candidate_details[0].contact),
    },
    {
      lable: 'Email ID:',
      value: notSpecified(candidate_details[0].email),
    },
    {
      lable: 'LinkedIn:',
      value: notSpecified(candidate_details[0].linkedin_url),
    },
    {
      lable: 'Current  Location:',
      value: notSpecified(candidate_details[0].location),
    },
    {
      lable: 'Qualification:',
      value: notSpecified(candidate_details[0].qualification),
    },
    {
      lable: 'Experience:',
      value: notSpecified(workYear(candidate_details[0].work_exp)),
    },
  ];

  const aboutData1 = [
    {
      lable: 'Job Type:',
      value: notSpecified(candidate_details[0].job_type_name),
    },
    {
      lable: 'Willing to Relocate:',
      value: relocate,
    },
    {
      lable: 'Expected Gross Salary:',
      value:
        candidate_details[0].exp_salary === 'Not Specified - Not Specified' ||
          isEmpty(candidate_details[0].exp_salary)
          ? 'Not Specified'
          : candidate_details[0].exp_salary,
    },
  ];
  const skillSplit = isEmpty(candidate_details[0].skills)
    ? []
    : candidate_details[0].skills.replace(',,', ',').split(',');
  return (
    <Flex row flex={12}>
      <Flex
        flex={6}
        columnFlex
        className={styles.overAll}
        height={window.innerHeight - 120}
      >
        <Text bold className={styles.jobPreferenceStyle}>
          Job Preference
        </Text>
        {aboutData1.map((list) => {
          return (
            <Flex key={list.lable} row center className={styles.flexLineHeight}>
              <Text
                style={{ fontSize: '13px' }}
                color="theme"
                className={styles.lableWidth}
              >
                {list.lable}
              </Text>
              <Text style={{ fontSize: '13px' }}>{list.value}</Text>
            </Flex>
          );
        })}
        {skillSplit[0] !== "" && skillSplit.length > 0 && skillSplit[0] !== undefined ? (
          <>
            <Text bold className={styles.jobPreferenceStyles}>
              Professional Skills
            </Text>
            <Flex row center wrap>
              {skillSplit &&
                skillSplit.map((skilsList, index) => {
                  return (
                    skilsList !== ' ' && (
                      <Flex
                        key={skilsList + index}
                        className={styles.skillStyle}
                      >
                        <Status label={lowerCase(skilsList)} />
                      </Flex>
                    )
                  );
                })}
            </Flex>
          </>
        ) : ''}
        <Flex>
          {questionnaire && questionnaire.length !== 0 && (
            <>
              <Text bold className={styles.softSkillStyle}>
                Applicant questionnaire
              </Text>
              <Flex>
                <Questionnaire issingletab={false} />
              </Flex>
            </>
          )}
        </Flex>
        <Flex>
          <Text bold className={styles.softSkillStyle}>
            Professional Profile Analysis
          </Text>
          {isloader ?
            <Flex center middle height={100}>
              <Flex center middle><Loader withOutOverlay size={'medium'} /></Flex>
              <Text color='gray'>Profile is being analysed.</Text>
            </Flex>
            : <>
              {issuccessfalse !== null &&
                <>
                  {issuccessfalse ?
                    <Flex height={100} center middle>
                      <Text color='gray'>No information available for this section</Text>
                    </Flex>
                    :
                    <>
                      {summary !== undefined ?
                        <>
                          <Flex className={styles.position_relative} marginBottom={10}>
                            <Flex row center><Text bold>Summary</Text></Flex>
                            <ul>
                              <li style={{ listStyle: 'none', marginLeft: '5px' }}>
                                <Flex style={{ display: 'block' }}>
                                  <Text size={13}>{(summary &&
                                    summary?.length !== 0 ||
                                    summary !== undefined) ?
                                    summary : 'No information available for this section'
                                  }</Text>
                                </Flex>
                              </li>
                              {!showMore &&
                                <Flex center middle marginTop={5}>
                                  <Text onClick={handleToggleShowMore} color='theme' bold style={{ cursor: 'pointer' }}>
                                    {showMore ? (
                                      <span>
                                        {' View less'}
                                        <Text style={{ marginLeft: '4px' }}><SvgAngle width={12} height={12} fill={'#581845'} up={true} /></Text>
                                      </span>
                                    ) : (
                                      <span>
                                        {' View more'}
                                        <Text style={{ marginLeft: '4px' }}><SvgAngle width={12} height={12} fill={'#581845'} up={false} /></Text>
                                      </span>
                                    )}
                                  </Text>
                                </Flex>}
                            </ul>
                          </Flex>
                          {showMore && (
                            <Flex>
                              <Flex row center><div className={styles.myaccountdropdownline}></div> <Text bold>Career Trajectory</Text></Flex>
                              <ul className={styles.borderline}>
                                <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Roles and Responsibilities </Text>
                                    <ul style={{ marginTop: '7px' }}>
                                      {Object.entries(Career_Trajectory['Roles and Responsibilities'])?.map(([skill, proficiency]) => (
                                        <li style={{ marginLeft: '35px', listStyle: 'circle', marginBottom: '10px', fontSize: '13px' }} key={skill}>
                                          {Object.keys(proficiency)?.map((key, index) => (
                                            <div key={index}>
                                              <strong>{key}:</strong> {proficiency[key]}
                                            </div>
                                          ))}
                                        </li>
                                      ))}
                                    </ul>
                                  </Flex>
                                </li>
                                <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Career Progressions and Promotions: </Text>
                                    <Text>{Career_Trajectory['Career Progressions and Promotions'] &&
                                      Career_Trajectory['Career Progressions and Promotions']?.length === 0 ?
                                      Career_Trajectory['Career Progressions and Promotions'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                              </ul>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold>Achievements</Text></Flex>
                              <ul className={styles.borderline}>
                                <li style={{ listStyle: 'disc', marginLeft: '25px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Significant Contributions: </Text>
                                    <Text>{(Achievements['Significant Contributions'] &&
                                      Achievements['Significant Contributions']?.length !== 0 ||
                                      Achievements['Significant Contributions'] !== undefined) ?
                                      Achievements['Significant Contributions'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                                <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Impact: </Text>
                                    <Text>{(Achievements['Impact'] &&
                                      Achievements['Impact']?.length !== 0 ||
                                      Achievements['Impact'] !== undefined) ?
                                      Achievements['Impact'] :
                                      'No information available for this section'}
                                    </Text>
                                  </Flex>
                                </li>
                              </ul>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold>Expertise and Skills</Text></Flex>
                              <Flex className={styles.borderline}>
                                <ul>
                                  {(Expertise_and_Skills['Projects and Achievements Showcasing Skills'] === undefined ||
                                    Expertise_and_Skills['Technical Skills and Proficiency Levels'] === "No information available for this section") ?
                                    <>
                                      <li style={{ listStyle: 'disc', marginLeft: '25px' }}>
                                        <Flex style={{ display: 'block' }}>
                                          <Text bold size={13} >Technical Skills and Proficiency Levels: </Text>
                                          <Text>No information available for this section</Text>
                                        </Flex>
                                      </li>
                                    </> :
                                    <>
                                      <li style={{ listStyle: 'disc', marginLeft: '25px' }}> <Text bold size={13} >Technical Skills and Proficiency Levels</Text></li>
                                      <ul style={{ marginTop: '7px' }}>
                                        {Object?.entries(Expertise_and_Skills['Technical Skills and Proficiency Levels'])?.map(([skill, proficiency]) => (
                                          <li style={{ marginLeft: '35px', listStyle: 'circle', marginBottom: '10px', fontSize: '13px' }} key={skill}>
                                            <strong>{skill}:</strong> {proficiency}
                                          </li>
                                        ))}
                                      </ul>
                                    </>}
                                  <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                    <Flex style={{ display: 'block' }}>
                                      <Text bold>Projects and Achievements Showcasing Skills: </Text>
                                      <Text>{(Expertise_and_Skills['Projects and Achievements Showcasing Skills'] &&
                                        Expertise_and_Skills['Projects and Achievements Showcasing Skills']?.length !== 0 ||
                                        Expertise_and_Skills['Projects and Achievements Showcasing Skills'] !== undefined) ?
                                        Expertise_and_Skills['Projects and Achievements Showcasing Skills'] : 'No information available for this section'
                                      }</Text>
                                    </Flex>
                                  </li>
                                </ul>
                              </Flex>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold>Industry Engagement</Text></Flex>
                              <ul className={styles.borderline}>
                                <li style={{ listStyle: 'disc', marginLeft: '25px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Contributions to the Industry: </Text>
                                    <Text>{(Industry_Engagement['Contributions to the Industry'] &&
                                      Industry_Engagement['Contributions to the Industry']?.length !== 0 ||
                                      Industry_Engagement['Contributions to the Industry'] !== undefined) ?
                                      Industry_Engagement['Contributions to the Industry'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                                <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Professional Circles: </Text>
                                    <Text>{(Industry_Engagement['Professional Circles'] &&
                                      Industry_Engagement['Professional Circles']?.length !== 0 ||
                                      Industry_Engagement['Professional Circles'] !== undefined) ?
                                      Industry_Engagement['Professional Circles'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                              </ul>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold>Networking and Professional Development </Text></Flex>
                              <Flex className={styles.borderline}>
                                <li style={{ listStyle: 'disc', marginLeft: '15px' }}><Text bold style={{ marginLeft: '-7px' }}>Networking Engagement</Text></li>
                                <ul>
                                  <li style={{ marginLeft: '35px', listStyle: 'circle', marginTop: '7px' }}>
                                    <Flex style={{ display: 'block' }}>
                                      <Text bold>Professional Network: </Text>
                                      <Text>{(Networking['Networking Engagement']['Professional Network'] &&
                                        Networking['Networking Engagement']['Professional Network']?.length !== 0 ||
                                        Networking['Networking Engagement']['Professional Network'] !== undefined) ?
                                        Networking['Networking Engagement']['Professional Network'] : 'No information available for this section'
                                      }</Text>
                                    </Flex>
                                  </li>
                                  <li style={{ marginLeft: '35px', listStyle: 'circle', marginBottom: '10px' }}>
                                    <Flex style={{ display: 'block' }}>
                                      <Text bold>Engagement in Industry-Relevant Events: </Text>
                                      <Text>{(Networking['Networking Engagement']['Engagement in Industry-Relevant Events'] &&
                                        Networking['Networking Engagement']['Engagement in Industry-Relevant Events']?.length !== 0 ||
                                        Networking['Networking Engagement']['Engagement in Industry-Relevant Events'] !== undefined) ?
                                        Networking['Networking Engagement']['Engagement in Industry-Relevant Events'] : 'No information available for this section'
                                      }</Text>
                                    </Flex>
                                  </li>
                                </ul>
                                <li style={{ listStyle: 'disc', marginLeft: '15px' }}><Text bold style={{ marginLeft: '-7px' }}>Continuous Learning and Adaptability</Text></li>
                                <ul>
                                  <li style={{ marginLeft: '35px', listStyle: 'circle', marginTop: '7px' }}>
                                    <Flex style={{ display: 'block' }}>
                                      <Text bold>Ongoing Education and Skill Development: </Text>
                                      <Text>{(Networking['Continuous Learning and Adaptability']['Ongoing Education and Skill Development'] &&
                                        Networking['Continuous Learning and Adaptability']['Ongoing Education and Skill Development']?.length !== 0 ||
                                        Networking['Continuous Learning and Adaptability']['Ongoing Education and Skill Development'] !== undefined) ?
                                        Networking['Continuous Learning and Adaptability']['Ongoing Education and Skill Development'] : 'No information available for this section'
                                      }</Text>
                                    </Flex>
                                  </li>
                                  <li style={{ marginLeft: '35px', listStyle: 'circle', marginBottom: '10px' }}>
                                    <Flex style={{ display: 'block' }}>
                                      <Text bold>Adaptation to Industry Innovations: </Text>
                                      <Text>{(Networking['Continuous Learning and Adaptability']['Adaptation to Industry Innovations'] &&
                                        Networking['Continuous Learning and Adaptability']['Adaptation to Industry Innovations']?.length !== 0 ||
                                        Networking['Continuous Learning and Adaptability']['Adaptation to Industry Innovations'] !== undefined) ?
                                        Networking['Continuous Learning and Adaptability']['Adaptation to Industry Innovations'] : 'No information available for this section'
                                      }</Text>
                                    </Flex>
                                  </li>
                                </ul>
                              </Flex>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold >Education and Alignment with Career Goals </Text></Flex>
                              <Flex className={styles.borderline}><Text style={{ marginLeft: '25px', marginBottom: '10px' }}>
                                {(education && education?.length !== 0 || education !== undefined) ?
                                  education : 'No information available for this section'
                                }</Text></Flex>
                              <Flex row center><div className={styles.myaccountdropdownlinehr}></div> <Text bold>Behavioral and Social Insights </Text></Flex>
                              <ul className={styles.borderline}>
                                <li style={{ listStyle: 'disc', marginLeft: '25px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Social Media and Professional Behavior: </Text>
                                    <Text>{(behaviour['Social Media and Professional Behavior'] &&
                                      behaviour['Social Media and Professional Behavior']?.length !== 0 ||
                                      behaviour['Social Media and Professional Behavior'] !== undefined) ?
                                      behaviour['Social Media and Professional Behavior'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                                <li style={{ listStyle: 'disc', marginLeft: '25px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Mobility and Ambition: </Text>
                                    <Text>{(behaviour['Mobility and Ambition'] &&
                                      behaviour['Mobility and Ambition']?.length !== 0 ||
                                      behaviour['Mobility and Ambition'] !== undefined) ?
                                      behaviour['Mobility and Ambition'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                                <li style={{ listStyle: 'disc', marginLeft: '25px', marginBottom: '10px' }}>
                                  <Flex style={{ display: 'block' }}>
                                    <Text bold>Consistency: </Text>
                                    <Text>{(behaviour['Consistency'] &&
                                      behaviour['Consistency']?.length === 0 ||
                                      behaviour['Consistency'] === undefined) ?
                                      behaviour['Consistency'] : 'No information available for this section'
                                    }</Text>
                                  </Flex>
                                </li>
                                <Flex center middle marginTop={5}>
                                  <Text onClick={handleToggleShowMore} color='theme' bold style={{ cursor: 'pointer' }}>
                                    {showMore ? (
                                      <span>
                                        {' View less'}
                                        <Text style={{ marginLeft: '4px' }}><SvgAngle width={12} height={12} fill={'#581845'} up={true} /></Text>
                                      </span>
                                    ) : (
                                      <span>
                                        {' View more'}
                                        <Text style={{ marginLeft: '4px' }}><SvgAngle width={12} height={12} fill={'#581845'} up={false} /></Text>
                                      </span>
                                    )}
                                  </Text>
                                </Flex>
                              </ul>

                              <div className={styles.myaccountdropdowndotinlast}></div>
                            </Flex>
                          )}
                        </> : <Flex height={100} center middle>
                          <Text color='gray'>No information available for this section</Text>
                        </Flex>}
                    </>
                  }
                </>}
            </>}
        </Flex>
      </Flex>
      <Flex
        height={window.innerHeight - 105}
        style={{
          border: '1px solid #C3C3C3',
          width: '1px',
          margin: '15px 5px 10px 5px',
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
      ></Flex>
      <Flex flex={6.4}>
        <Tab title="Resume">
          <CandiDateResumeTab />
        </Tab>
      </Flex>
    </Flex>
  );
};

export default AboutTab;
