const defaultProps = {
  width: 50,
  height: 50,
  viewBox: '',
};

const SvgOutlookMail = ({ width, height, viewBox }: typeof defaultProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <g clipPath="url(#outlookMail_svg__a)">
        <path
          fill="#0A2767"
          d="M49.265 26.163a1.12 1.12 0 0 0-.525-.96h-.006l-.02-.01-17.071-10.257a2.265 2.265 
        0 0 0-2.56 0l-17.07 10.255-.021.012a1.139 1.139 0 0 0 .027 1.934l17.07 10.255a2.264 2.264 0 0 0 2.56 0l17.07-10.255c.342-.202.55-.574.546-.974Z"
        />
        <path
          fill="#0364B8"
          d="M14.003 19.315h11.203v10.422H14.003V19.315Zm32.97-10.594V3.953c.028-1.192-.902-2.18-2.077-2.209h-29.07c-1.176.028-2.105 
        1.018-2.078 2.21V8.72l17.185 4.651 16.04-4.651Z"
        />
        <path fill="#0078D4" d="M13.748 8.72h11.457v10.466H13.748V8.72Z" />
        <path
          fill="#28A8EA"
          d="M36.662 8.72H25.205v10.466L36.662 29.65h10.31V19.187L36.663 8.72Z"
        />
        <path fill="#0078D4" d="M25.205 19.188h11.457v10.464H25.205V19.187Z" />
        <path fill="#0364B8" d="M25.205 29.65h11.457v10.465H25.205V29.65Z" />
        <path fill="#14447D" d="M14.003 29.738h11.203v9.475H14.003v-9.475Z" />
        <path fill="#0078D4" d="M36.662 29.65h10.31v10.465h-10.31V29.65Z" />
        <path
          fill="url(#outlookMail_svg__b)"
          d="m48.74 27.073-.022.012-17.07 9.744a2.35 2.35 0 0 1-1.155.362l-.932-.553a2.342 
        2.342 0 0 1-.23-.134l-17.3-10.02h-.007l-.566-.322V45.89c.008 1.316 1.067 2.376 2.363 2.367h33.117c.02 
        0 .037-.01.058-.01.274-.018.543-.075.801-.17.112-.048.22-.104.323-.17.077-.044.209-.14.209-.14.586-.44.934-1.137.937-1.878V26.163c-.001.377-.202.725-.526.91Z"
        />
        <path
          fill="#0A2767"
          d="M48.348 26.086v1.209l-17.85 12.474-18.48-13.276c0-.007-.005-.012-.012-.012l-1.695-1.035v-.872l.698-.012 
        1.479.86.034.013.126.08s17.369 10.06 17.415 10.082l.665.395c.058-.023.114-.047.184-.07.034-.023 17.242-9.848 17.242-9.848l.194.012Z"
          opacity={0.5}
        />
        <path
          fill="#1490DF"
          d="m48.74 27.072-.022.013-17.07 9.744a2.36 2.36 0 0 
        1-2.56 0l-17.07-9.744-.02-.013a1.05 1.05 0 0 
        1-.54-.91v19.726c.008 1.315 1.066 2.376 2.362 2.367h33.083c1.297.009 
        2.355-1.052 2.363-2.367V26.162c-.001.377-.202.725-.526.91Z"
        />
        <path
          fill="#333"
          d="m31.896 36.688-.256.145a2.342 2.342 0 0 1-1.13.374l6.495 7.795 11.33 2.77c.311-.238.557-.55.719-.91L31.896 36.687Z"
          opacity={0.1}
        />
        <path
          fill="#333"
          d="m33.053 36.025-1.412.806a2.346 2.346 0 0 1-1.13.374l3.043 8.515 14.785 2.048c.582-.444.926-1.14.926-1.88v-.254l-16.212-9.609Z"
          opacity={0.05}
        />
        <path
          fill="#28A8EA"
          d="M13.852 48.255H46.9a2.347 2.347 0 0 0 1.414-.465L29.559 
        36.64a2.341 2.341 0 0 1-.23-.134l-17.3-10.02h-.007l-.565-.324V45.82c-.001 1.343 1.07 2.434 2.395 2.435Z"
        />
        <path
          fill="#333"
          d="M27.497 13.178V37.98a2.138 2.138 0 0 1-1.317 1.977c-.247.108-.511.163-.78.163H11.457V12.21h2.29v-1.163H25.4c1.159.004 2.095.956 2.098 2.13Z"
          opacity={0.1}
        />
        <path
          fill="#333"
          d="M26.35 14.34v24.804a1.91 1.91 0 0 1-.171.813 2.088 2.088 0 0 1-1.925 1.322H11.457V12.21h12.797c.332-.003.66.081.95.244a2.118 2.118 0 0 1 1.147 1.888Z"
          opacity={0.2}
        />
        <path
          fill="#333"
          d="M26.35 14.34v22.478c-.005 1.173-.94 2.125-2.096 2.135H11.457V12.21h12.797c.332-.003.66.081.95.244a2.118 2.118 0 0 1 1.147 1.888Z"
          opacity={0.2}
        />
        <path
          fill="#333"
          d="M25.205 14.34v22.477c-.001 1.176-.938 2.13-2.097 2.136H11.457V12.21h11.651c1.159.001 2.098.954 2.097 2.13v.002Z"
          opacity={0.2}
        />
        <path
          fill="url(#outlookMail_svg__c)"
          d="M2.1 12.209h21.005c1.16 0 2.1.954 2.1 2.132v21.318c0 1.177-.94 2.132-2.1 2.132H2.1c-1.16 0-2.1-.955-2.1-2.132V14.341c0-1.177.94-2.132 2.1-2.132Z"
        />
        <path
          fill="#fff"
          d="M6.564 21.149a6.17 6.17 0 0 1 2.399-2.69 7.17 7.17 0 0 1 3.811-.97 
        6.672 6.672 0 0 1 3.528.92 6.109 6.109 0 0 1 2.333 2.57 8.387 8.387 0 0 
        1 .818 3.779 8.861 8.861 0 0 1-.842 3.953 6.254 6.254 0 0 1-2.406 2.66 
        6.937 6.937 0 0 1-3.659.944 6.811 6.811 0 0 1-3.605-.931 6.214 6.214 0 0 
        1-2.365-2.574 8.198 8.198 0 0 1-.828-3.734 9.11 9.11 0 0 1 .816-3.927Zm2.557 
        6.314a4.011 4.011 0 0 0 1.35 1.77 3.494 3.494 0 0 0 2.108.644 3.654 3.654 0 0 
        0 2.25-.665 3.847 3.847 0 0 0 1.311-1.77 6.96 6.96 0 0 0 .418-2.468 7.606 7.606 
        0 0 0-.394-2.5 3.955 3.955 0 0 0-1.27-1.844 3.45 3.45 0 0 0-2.23-.697 3.592 3.592 
        num0 0 0-2.157.65 4.047 4.047 0 0 0-1.375 1.785 7.193 7.193 0 0 0-.01 5.099l-.001-.004Z"
        />
        <path fill="#50D9FF" d="M36.662 8.72h10.31v10.466h-10.31V8.72Z" />
      </g>
      <defs>
        <linearGradient
          id="outlookMail_svg__b"
          x1={30.361}
          x2={30.361}
          y1={26.163}
          y2={48.256}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35B8F1" />
          <stop offset={1} stopColor="#28A8EA" />
        </linearGradient>
        <linearGradient
          id="outlookMail_svg__c"
          x1={4.379}
          x2={21.195}
          y1={10.544}
          y2={39.24}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1784D9" />
          <stop offset={0.5} stopColor="#107AD5" />
          <stop offset={1} stopColor="#0A63C9" />
        </linearGradient>
        <clipPath id="outlookMail_svg__a">
          <path fill="#fff" d="M0 0h49.265v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
SvgOutlookMail.defaultProps = defaultProps;

export default SvgOutlookMail;
