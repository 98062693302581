import axios from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SvgCloseSmall from '../../icons/SvgCloseSmall';
import { AppDispatch, RootState } from '../../store';
import Flex from '../../uikit/Flex/Flex';
import { isEmpty, mailformat } from '../../uikit/helper';
import Loader from '../../uikit/Loader/Loader';
import Modal from '../../uikit/Modal/Modal';
import Text from '../../uikit/Text/Text';
import { profileEditMiddleWare } from '../candidateprofile/store/middleware/candidateprofilemiddleware';
import { PLEASE_ENTER_VALID_MAIL, THIS_FIELD_REQUIRED } from '../constValue';
import ForgotPassword, { forgotFormProps } from '../Login/ForgotPassword';
import LoginInto from '../Login/LoginCandidate';
import ResetPasswordSuccess from '../Login/ResetPasswordSuccess';
import {
  loginMiddleWare,
  passwordResetRequestMiddleWare,
} from '../Login/store/middleware/loginMiddleWare';
import styles from './loginpopupmodal.module.css';

// const initial: loginFormProps = {
//   userName: '',
//   email: '',
// };
const forgotInitial: forgotFormProps = {
  forgotEmail: '',
};

type Props = {
  cancel: () => void;
  open: boolean;
  closeLogin: () => void;
};
const LoginPopUpModal = ({ cancel, open, closeLogin }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isForgot, setForgot] = useState(false);
  const [isResetSuccess, setResetSuccess] = useState(false);
  const [isEmailValid, setEmailValid] = useState(false);
  const [isForgotLoader, setForgotLoader] = useState(false);
  const [isError, setError] = useState(false);
  const [isInactive, setInactive] = useState(false);
  const [isForgotvalidation, setForgotvalidation] = useState(false);

  const { isLoading } = useSelector(({ loginReducers }: RootState) => {
    return {
      isLoading: loginReducers.isLoading,
    };
  });

  return (
    <Modal open={open}>
      <Flex columnFlex className={styles.overAll}>
        <div
          className={styles.svgClose}
          onClick={() => {
            closeLogin();
          }}
          tabIndex={-1}
          role="button"
          onKeyDown={() => {}}
        >
          <SvgCloseSmall />
        </div>

        <Flex height={'300px'} width={'350'}>
          <LoginInto candidate={true} />
        </Flex>
      </Flex>
    </Modal>
  );
};

export default LoginPopUpModal;
