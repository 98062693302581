// //import * as React from 'react';
// const defaultProps = {
//   fill: '#34cc65',
//   width: 22,
//   height: 22,
// };

// const SvgUserSearch = ({ width, height, fill }: typeof defaultProps) => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width={width}
//       height={height}
//       fill={fill}
//     >
//       <path
//         fill="#581845"
//         d="M6.376 6.935c-.877 0-1.627-.328-2.251-.985-.625-.657-.937-1.447-.937-2.37 
//         0-.922.312-1.712.937-2.37C4.749.555 5.499.226 6.376.226c.877 
//         0 1.627.329 2.252.986.624.657.936 1.447.936 2.37 0 .922-.312 
//         1.712-.936 2.369-.625.657-1.375.985-2.252.985Zm0-1.677c.438 0 
//         .814-.165 1.126-.493.312-.329.468-.723.468-1.185 0-.461-.156-.856-.468-1.184a1.498 
//         1.498 0 0 0-1.126-.493c-.438 0-.813.164-1.125.493a1.657 1.657 0 0 
//         0-.469 1.184c0 .462.156.856.469 1.185.312.328.687.493 1.125.493Zm8.289 
//         10.736-1.992-2.097a4.845 4.845 0 0 1-.897.42 3.046 3.046 0 0 1-1.016.168c-.997 
//         0-1.844-.367-2.541-1.1-.697-.735-1.046-1.627-1.046-2.675 0-1.048.349-1.94 
//         1.046-2.674.697-.734 1.544-1.1 2.54-1.1.997 0 1.844.366 2.541 1.1.698.734 
//         1.046 1.626 1.046 2.674 0 .377-.053.734-.16 1.07a5.37 5.37 0 0 1-.398.943l1.993 
//         2.097c.146.154.219.35.219.587a.818.818 0 0 1-.22.587.736.736 0 0 1-.557.231.736.736 
//         0 0 1-.558-.23Zm-3.905-3.187c.558 0 1.03-.203 1.414-.608.386-.405.578-.902.578-1.489 
//         0-.587-.192-1.083-.578-1.489a1.876 1.876 0 0 0-1.414-.608c-.558 
//         0-1.03.203-1.415.608-.385.406-.578.902-.578 1.489 0 .587.193 1.083.578 
//         1.489.385.405.857.608 1.415.608Zm-4.364.839H1.594a1.5 1.5 0 0 1-1.126-.493A1.66 
//         1.66 0 0 1 0 11.968v-.65c0-.475.113-.915.339-1.32a2.26 2.26 0 0 1 .936-.924 12.105 
//         12.105 0 0 1 2.292-.922c.85-.252 1.793-.378 2.829-.378-.16.252-.295.52-.408.807a5.708
//          5.708 0 0 0-.27.892 8.884 8.884 0 0 0-2.131.43 9.943 9.943 0 0 0-1.575.681.736.736 
//          0 0 0-.308.304.878.878 0 0 0-.11.43v.65h4.125c.066.308.156.601.269.881.113.28.249.545.408.797Z"
//       />
//     </svg>
//   );
// };
// SvgUserSearch.defaultProps = defaultProps;
// export default SvgUserSearch;
// import * as React from 'react';       
const SvgUserSearch  = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  xmlSpace="preserve"
  width={64}
  height={64}
  fill="#581845"
  stroke="#581845"
  strokeWidth={5.995}
  viewBox="-29.97 -29.97 359.68 359.68"
  {...props}
>
  <path d="m217.635 225.419 72.711 72.709c1.074 1.074 2.482 1.611 3.891 1.611s2.816-.537 
  3.891-1.611a5.5 5.5 0 0 0 0-7.781l-72.709-72.711c22.104-24.014 34.217-54.98 
  34.217-87.814 0-34.676-13.504-67.277-38.023-91.797C197.095 13.505 164.496.002 
  129.824.002c-34.678 0-67.277 13.504-91.799 38.023C13.504 62.544 0 95.143 0 129.821c0 
  34.676 13.504 67.275 38.025 91.793 24.52 24.52 57.119 38.021 91.797 38.021 32.832 
  0 63.799-12.113 87.813-34.216zM11.006 129.821c0-65.514 53.301-118.814 118.816-118.814 65.51 
  0 118.807 53.301 118.807 118.814 0 46.281-26.604 86.463-65.318 106.066v-59.076c0-20.396-16.592-36.99-36.988-36.99h-33.01c-20.395 
  0-36.986 16.594-36.986 36.99v59.074c-38.718-19.603-65.321-59.783-65.321-106.064zm76.326 46.99c0-14.328 11.654-25.984 25.98-25.984h33.01c14.326 
  0 25.982 11.656 25.982 25.984v63.959c-13.199 5.072-27.52 7.859-42.482 7.859-14.967 0-29.289-2.789-42.49-7.861v-63.957z" />
  <path d="M129.686 134.551c20.971 0 38.033-17.061 38.033-38.031 0-20.977-17.063-38.043-38.033-38.043-20.973 
  0-38.033 17.066-38.033 38.043-.001 20.971 17.06 38.031 38.033 38.031zm0-65.068c14.902 
  0 27.027 12.129 27.027 27.037 0 14.902-12.125 27.025-27.027 27.025-14.902 
  0-27.027-12.123-27.027-27.025-.001-14.908 12.124-27.037 27.027-27.037z" />
</svg>
);
export default SvgUserSearch;
