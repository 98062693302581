import * as React from 'react';
const SvgNoDataIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 108.67 122.88"
    {...props}
  >
    <path
      d="M25.14 53.37a2.77 2.77 0 0 0 0 5.54h20.11a2.77 2.77 0 0 0 0-5.54Zm60.48-36.9 6.66 6.69-8 8.14 8 8.14-6.67 6.66-8-8.09-8 8.1L63 39.43l8-8.14-8-8.15 6.67-6.65 8 8.08 8-8.1ZM77.77 0A30.91 30.91 0 0 1 91 58.82v57.69a6.38 6.38 0 0 1-6.37 6.37H6.37A6.38 6.38 0 0 1 0 116.51V22.4A6.38 6.38 0 0 1 6.37 16h44.3a30.89 30.89 0 0 1 27.1-16Zm7.78 60.81a30.92 30.92 0 0 1-37.23-39.29H6.37a.9.9 0 0 0-.63.26.92.92 0 0 0-.26.63v94.09a.89.89 0 0 0 .89.89h78.28a.9.9 0 0 0 .63-.26.92.92 0 0 0 .26-.63V60.81ZM25.14 92.22a2.74 2.74 0 0 0 0 5.48h38.47a2.74 2.74 0 1 0 0-5.48Zm0-19.41a2.74 2.74 0 0 0 0 5.48h38.47a2.74 2.74 0 0 0 0-5.48Z"
      style={{
        fillRule: 'evenodd',
      }}
    />
  </svg>
);
export default SvgNoDataIcon;