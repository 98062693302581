import * as React from 'react';
const SvgParsing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={682.667}
    height={682.667}
    viewBox="0 0 512 512"
    fill='#581845'
    {...props}
  >
    <path d="M34.5 1.4c-8.4 2.1-14.7 5.7-21.1 12.1C6.9 20 2.9 27.1 1.1 35.8c-1.6 7.6-1.6 432.9 0 440.4 3.7 17.2 17.5 31 34.7 34.7 7.4 1.5 198.9 1.6 206.4 0 13-2.7 12.4-2.3 69-58.8 48.3-48.2 53-53.1 55.7-59.1 1.7-3.6 3.5-8.9 4.1-11.9.6-3.1 1-21.6 1-43v-37.6l8.3-1.9c4.5-1 11.1-3 14.5-4.4l6.4-2.5 43.1 43.1c23.8 23.6 44.6 43.7 46.4 44.6 6.9 3.4 15.8.5 19.3-6.4 2.5-4.9 2.5-9 .1-13.8-1.1-2-20.2-21.9-42.5-44.1l-40.5-40.4 7.2-7.8c12.2-13.1 21.1-30.4 25.4-49.1 2.2-9.8 2.2-32.6-.1-42.6-7-31.4-27.3-57.9-55.2-72.2-8.6-4.4-20.2-8.4-27.6-9.5l-4.8-.7V67.3c0-29-.9-35.4-6.6-45-6.3-10.6-17.3-18.7-29.2-21.2-8-1.7-294.8-1.4-301.7.3zm300.1 31.2c1.5 1.1 3.7 3.3 4.8 4.8 2 2.6 2.1 4 2.1 28.8v26l-9 2.3c-12 3.1-16.6 4.8-25.2 9.5-34.1 18.4-55.3 53.9-55.3 92.5s21.3 74.2 55.3 92.5c8 4.3 22.7 9.5 30 10.5l4.7.7V367.9l-43.7.3c-42.2.3-44 .4-48.4 2.4-5.8 2.7-11.6 8.5-14.3 14.3-2 4.4-2.1 6.1-2.4 50.8l-.3 46.3H41.8l-4-2.2c-2.4-1.4-4.8-3.8-5.9-6-1.8-3.6-1.9-9.5-1.9-218 .1-204.4.2-214.5 1.9-217.7 1-1.8 3.5-4.4 5.7-5.7l3.9-2.4 145.2.2c144.9.3 145.2.3 147.9 2.4zm43 91.9c19.8 5.8 37.3 20.4 46.3 38.9 5.6 11.5 7.5 19.9 7.5 33.1 0 13.2-1.9 21.6-7.5 33.1-9.6 19.7-29.2 35.2-50.6 40-11.9 2.6-29.3 1.6-40.6-2.3-11-3.9-18.1-8.1-26.8-16.1-32-29.5-32-79.8 0-109.4 8.6-7.9 17.4-13.1 27.6-16.4 13.7-4.3 31-4.7 44.1-.9zM322 398.8c0 .4-13.3 14-29.5 30.2L263 458.5V398h29.5c16.2 0 29.5.3 29.5.8z" />
    <path d="M71.4 104.1c-12.1 3.5-14 20.3-3.2 26.9 3.2 1.9 5.2 2 85.1 2 90.6 0 86.5.3 90.6-6.4 5-8 2.5-17.2-5.9-21.4l-4.4-2.2-79.6.1c-43.9 0-80.9.5-82.6 1zM68.2 172c-9.6 5.9-9.5 18.3.1 25.4 2.8 2.1 3.4 2.1 73 2.1 66.3 0 70.4-.1 73.2-1.8 4.5-2.8 6.8-6.5 7.3-11.7.5-5.2-1.1-9-5.3-13l-2.7-2.5-71.1-.3C73 170 71.4 170 68.2 172zM69 237.8c-10.7 5.3-10.7 20.5.1 26l4.4 2.2 75.5-.2 75.5-.3 3.3-2.3c4.2-3.1 6.4-7.4 6.4-13 0-5.1-2-8.4-7.2-12l-3.3-2.2h-75.6c-70 0-75.9.1-79.1 1.8zM69 303.9c-5 2.7-7.3 6.2-7.8 12.3-.3 4.4 0 5.9 2 8.8 5 7.5-2.5 7 109.8 7 93.7 0 100.7-.1 104-1.8 10.7-5.3 10.7-21.1 0-26.4-3.3-1.7-10.3-1.8-104-1.8-95.9 0-100.7.1-104 1.9zM71.4 369.1c-12.1 3.5-14 20.3-3.2 26.9 3.2 1.9 5.2 2 64.9 2h61.6l3.3-2.3c9.2-6.2 9-18.1-.4-25.1-2.7-2.1-3.8-2.1-62.9-2.3-34.1-.1-61.5.2-63.3.8zM374.5 168.3c-1.6.8-7.9 5.5-14 10.6-6 5-11.2 9.1-11.5 9.1-.4 0-2.1-1.8-3.9-4-9.2-11.4-26.3-5.2-26.3 9.7 0 3.9 1 5.9 7.3 15.4 4 6.1 8.5 12.1 10.1 13.4 3.6 3.1 8.9 4.1 13.6 2.6 4.7-1.4 41-31.7 43.4-36.3 5-9.1-1.2-20.5-11.5-21.4-2.3-.3-5.5.2-7.2.9z" />
  </svg>
);
export default SvgParsing;