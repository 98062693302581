import * as React from 'react';
const SvgJobSelection = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={18}
    fill="none"
    viewBox='0 0 10 11'
    {...props}
  >
    <path
      fill="#581845"
      d="M5 .738c1.381 0 2.5.995 2.5 2.223C7.5 4.188 6.38 5.182 5 5.182S2.5 4.188 2.5 2.96C2.5 1.733 3.619.738 5 .738Zm2.5 5.856c0 .589-.175 1.96-1.369 3.494l-.506-2.683.587-1.045A12.126 12.126 0 0 0 5 6.295c-.419 0-.825.028-1.213.067l.588 1.044-.506 2.683c-1.194-1.533-1.37-2.905-1.37-3.494C1.007 6.983 0 7.683 0 8.516v2.222h10V8.516c0-.833-1-1.533-2.5-1.922Z"
    />
  </svg>
);
export default SvgJobSelection;