// import { Table } from 'react-bootstrap';

import Text from '../../uikit/Text/Text';

import Flex from '../../uikit/Flex/Flex';
// import Card from '../../uikit/Card/Card';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import { isEmpty } from '../../uikit/helper';
import styles from './myjobpostingscreen.module.css';
import { FinalListEntity } from './myJobPostingTypes';

type Props = {
  list: FinalListEntity;
};

const MyJobPostingScreenStatus = ({ list }: Props) => {
  const shortlisted = isEmpty(list.shortlisted) ? '0' : list.shortlisted;
  const selected = isEmpty(list.hired) ? '0' : list.hired;
  const rejected = isEmpty(list.rejected) ? '0' : list.rejected;

  return (
    <Flex className={styles.screenstatu}>
      {isEmpty(list.shortlisted) ?
        <Flex row center>
          <Flex className={styles.circle1} marginRight={5}></Flex>
          <Flex row> <Text className={styles.styletexts} >
            {shortlisted}
          </Text>
            <Text className={styles.fontScreen}>Shortlisted</Text></Flex>
        </Flex>
        :
        <LinkWrapper
          target={'_parent'}
          to={`/applicant_pipe_line/${list.id}`}
        >
          <Flex row center>
            <Flex className={styles.circle1} marginRight={5}></Flex>
            <Flex row> <Text className={styles.styletext} bold color='theme'>
              {shortlisted}
            </Text>
              <Text className={styles.fontScreens} bold color='theme'>Shortlisted</Text></Flex>
          </Flex>
        </LinkWrapper>
      }
      {isEmpty(list.hired) ?
        <Flex row center>
          <Flex className={styles.circle2} marginRight={5}></Flex>
          <Flex row> <Text className={styles.styletexts} >
            {selected}
          </Text>
            <Text className={styles.fontScreen}>Hired</Text></Flex>
        </Flex>
        :
        <LinkWrapper
          target={'_parent'}
          to={`/applicant_pipe_line/${list.id}`}
        >
          <Flex row center>
            <Flex className={styles.circle2} marginRight={5}></Flex>
            <Flex row> <Text className={styles.styletext} bold color='theme'>
              {selected}
            </Text>
              <Text className={styles.fontScreens} bold color='theme'>Hired</Text></Flex>
          </Flex>
        </LinkWrapper>
        // <li style={{ color: "#00BE4B", fontSize: 14, marginTop: -2, height: 20 }}>
        //   <Text className={styles.styletexts}>
        //     {selected}
        //   </Text>
        //   <Text className={styles.fontScreen} style={{ color: "#581845", width: 8, height: 8 }}>Hired</Text>
        // </li>
        // :
        // <li style={{ color: "#00BE4B", fontSize: 14, marginTop: 0, height: 20 }}   >
        //   <LinkWrapper
        //     target={'_parent'}
        //     to={`/applicant_pipe_line/${list.id}`}
        //   >
        //     <Text className={styles.styletext}>
        //       {selected}
        //     </Text>
        //     <Text className={styles.fontScreens} style={{ color: "#581845", width: 8, height: 8 }}
        //     >Hired</Text>
        //   </LinkWrapper>
        // </li>
      }
      {isEmpty(list.rejected) ?
        <Flex row center>
          <Flex className={styles.circle3} marginRight={5}></Flex>
          <Flex row> <Text className={styles.styletexts} >
            {rejected}
          </Text>
            <Text className={styles.fontScreen}>Rejected</Text></Flex>
        </Flex>
        :
        <LinkWrapper
          target={'_parent'}
          to={`/applicant_pipe_line/${list.id}`}
        >
          <Flex row center>
            <Flex className={styles.circle3} marginRight={5}></Flex>
            <Flex row> <Text className={styles.styletext} bold color='theme'>
              {rejected}
            </Text>
              <Text className={styles.fontScreens} bold color='theme'>Rejected</Text></Flex>
          </Flex>
        </LinkWrapper>
        // <li style={{ color: "#FF0000", fontSize: 14, height: 20 }}>
        //   <Text className={styles.styletexts}>
        //     {rejected}
        //   </Text>
        //   <Text className={styles.fontScreen} bold>Rejected</Text>
        // </li>

        // :

        // <li style={{ color: "#FF0000", fontSize: 14, height: 20 }}>
        //   <LinkWrapper
        //     target={'_parent'}
        //     to={`/applicant_pipe_line/${list.id}`}
        //   >
        //     <Text className={styles.styletext}>
        //       {rejected}
        //     </Text>
        //     <Text className={styles.fontScreens} bold>Rejected</Text>

        //   </LinkWrapper>
        // </li>

      }
      {/* </ul> */}
      {/* <ul className={styles.ulstyle2}>
        {isEmpty(list.hired) ?

          <li style={{ color: "#00BE4B", fontSize: 14, marginTop: -2 }}> 
            <Text className={styles.styletexts}>
              {selected}
            </Text> 
            <Text className={styles.fontScreen} style={{ color: "#581845", width: 8, height: 8 }}>Hired</Text>  
          </li>

          :

          <li style={{ color: "#00BE4B", fontSize: 14, marginTop: 0 }}   >
            <LinkWrapper
              target={'_parent'}
              to={`/applicant_pipe_line/${list.id}`}
            >
              <Text className={styles.styletext}>
                {selected}
              </Text>
              <Text className={styles.fontScreens} style={{ color: "#581845", width: 8, height: 8 }}
              >Hired</Text>
            </LinkWrapper>

          </li>

        }
      </ul>

      <ul className={styles.ulstyle3}>
        {isEmpty(list.rejected) ?

          <li style={{ color: "#FF0000", fontSize: 14 }}> 
            <Text className={styles.styletexts}>
              {rejected}
            </Text> 
            <Text className={styles.fontScreen} bold>Rejected</Text> 

          </li>

          :

          <li style={{ color: "#FF0000", fontSize: 14 }}>
            <LinkWrapper
              target={'_parent'}
              to={`/applicant_pipe_line/${list.id}`}
            >
              <Text className={styles.styletext}>
                {rejected}
              </Text>
              <Text className={styles.fontScreens} bold>Rejected</Text>

            </LinkWrapper>
          </li>

        }
      </ul> */}
    </Flex>



  );
};

export default MyJobPostingScreenStatus;
