import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames/bind';
import { useFormik } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SvgTickOne from '../../../icons/SvgTickOne';
import SvgTick from '../../../icons/SvgTick';
import { AppDispatch, RootState } from '../../../store';
import Button from '../../../uikit/Button/Button';
import Card from '../../../uikit/Card/Card';
import Flex from '../../../uikit/Flex/Flex';
import InputText from '../../../uikit/InputText/InputText';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import Loader from '../../../uikit/Loader/Loader';
import Text from '../../../uikit/Text/Text';
import { SUCCESS } from '../../../uikit/Colors/colors';
import CancelAndDeletePopup from '../../common/CancelAndDeletePopup';
import SingleButton from '../../common/SingleButton';
import { DELETE, onlyNumber } from '../../constValue';
import { stripeMiddleWare } from '../../talentsourcingmodule/store/middleware/talentSoucringMiddleware';
import { Subscription } from './manageSubscriptionTypes';
import styles from './pricecard.module.css';
import { createCheckoutSubscriptionMiddleWare } from './store/managesubscriptionmiddleware';


const cx = classNames.bind(styles);

type Props = {
  headerTitle: string;
  id: any;
  price: string;
  data: {
    value: any;
  }[];
  userPrice?: boolean;
  subheading: any;
  days: string;
  indexparent: any;
  isPlanSwitchmain: boolean

};

const PriceCardWebSite = ({
  headerTitle,
  price,
  data,
  userPrice,
  days,
  subheading,
  isPlanSwitchmain,
  indexparent,
  id

}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isInvite, setInvite] = useState(false);
  const [isChangePlan, setChangePlan] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const ismobile = useMediaQuery({ query: '(max-width: 430px)' });

  const dataArray = Array.isArray(data) ? data : [];
  return (
    <>
      <div style={{ position: 'relative', marginTop: '15px' }}>

        <Card
          //   className={
          //     selectedItemIndices.includes(indexparent) ?
          //        styles.bgcolor
          //       : styles.overAll
          //   }
          // onClick={() => handleTitleClick(indexparent)}
          className={ismobile?(styles.heightsubmobile):(styles.heightsub)}
        >

          <Flex style={{ display: 'contents' }}>
            <Flex>
              <Flex
                style={{
                  margin: '25px 25px 0px 25px',
                  borderBottom: '1px solid #C3C3C3',
                }}
              >
                <Flex row between center>
                  <Text bold size={18} className={styles.heading}>
                    {headerTitle}

                  </Text>

                  {/* <Flex>
                {subscription && subscription.plan_id_id === planId ? (
                  <SvgTick fill={SUCCESS} />
                ) : (
                  ''
                )}
              </Flex> */}
                  <Flex row style={{ display: 'flex', alignItems: 'baseline' }}>
                    {price !== 'FREE' ? (
                      <Text size={16} bold style={{ marginBottom: 4 }} className={styles.subheading}>
                        ${price}
                      </Text>
                    ) :
                      <Text size={14} bold className={styles.subheading} >
                        Free&emsp;
                      </Text>
                    }
                    {userPrice && (
                      <Text size={14} >
                        /
                      </Text>
                    )}
                    <Text
                      align="center"
                      size={14}
                      style={{ marginBottom: 4 }}
                      className={styles.subheading}
                    >
                      {days}
                    </Text>
                  </Flex>
                </Flex>
                {/* <Flex>
                  <Text color='theme' className={styles.subheading1} style={{ paddingBottom: "7px" }}>{subheading}
                  </Text>
                </Flex> */}
                <Flex row between top className={days==='Year'&&id===12 ?(styles.marginborder):(styles.marginborder1)} >
                  <Text color='theme' >{subheading}</Text>
                 { days==='Year'&&id!==13&&(<Text className={styles.ribbon}>Save 10%</Text>)}
                </Flex>
              </Flex>
         
              <Flex columnFlex className={styles.priceListwebsite} >
                {dataArray?.map((list, index) => (
                  <Flex row center key={list.value + index} className={styles.marginbottom}>
                    <div style={{ marginRight: 8 }}>
                      <SvgTickOne />
                    </div>
                    <div key={index}  className={styles.dataarray} dangerouslySetInnerHTML={{ __html: list.value }} />

                  </Flex>
                ))}
              </Flex>
            </Flex>

          </Flex>
          <Flex>
          {id===1 ?(
         
              <Flex center middle marginBottom={20} >
                <LinkWrapper
                  to={`/recruiter/${id}`}
                >
                  <Button style={{ backgroundColor: '#783664' }} className={styles.fontfamilybutton}>14 Days Trial</Button>
                </LinkWrapper>
              </Flex>):(
                id===13 || id===9?(
                  <Flex center middle marginBottom={20} >
                <a
                  rel="noreferrer"
                  href="https://www.zita.ai/contact-us"
                  target={'_blank'}
                >
                  <Button style={{ backgroundColor: '#783664' }} className={styles.fontfamilybutton}>Get In Touch</Button>
                </a>
              </Flex>
                ):(
                  <Flex center middle marginBottom={20} >
                    <LinkWrapper
                      to={`/recruiter/${id}`}
                    >
                      <Button style={{ backgroundColor: '#783664' }} className={styles.fontfamilybutton}>Buy Now</Button>
                    </LinkWrapper>
                  </Flex>
                )
              )
            }
          
          </Flex>
        </Card>
      </div>
    </>
  );
};

export default PriceCardWebSite;
