import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Flex from '../../../uikit/Flex/Flex'
import Text from '../../../uikit/Text/Text';
import { Button, InputCheckBox, Toast } from '../../../uikit';
import Card from '../../../uikit/Card/Card';
import SvgClose from '../../../icons/SvgClose';
import SvgCircleDollar from '../../../icons/SvgCircleDollar';
import SvgRolling from '../../../icons/SvgRolling';
import SvgShareLink from '../../../icons/SvgShareLink';
import { AppDispatch, RootState } from '../../../store';
import SvgTick from '../../../icons/SvgTick';
import SvgRoundEmpty from '../../../icons/SvgRoundEmpty';
import styles from './subscriptioncalculation.module.css'
import { websiteSubscriptionScreenMiddleWare } from './store/managesubscriptionmiddleware';

type Props = {
    isPlanSwitch: boolean;
    setPlanSwitch: any;
};


const SubscriptionCalculation = ({ isPlanSwitch, setPlanSwitch }:Props) => {
    
    const { standard_plan, premium_plan, monthly_plan, yearly_plan} =
    useSelector(({ manageSubscriptionwebsiteReducers}: RootState) => ({
        standard_plan:manageSubscriptionwebsiteReducers.standard_plan,
        premium_plan:manageSubscriptionwebsiteReducers.premium_plan,
        monthly_plan:manageSubscriptionwebsiteReducers.monthly_plan,
        yearly_plan:manageSubscriptionwebsiteReducers.yearly_plan
    }));

const dispatch: AppDispatch = useDispatch();

const [isStandardaddon, setStandardaddon] = useState(true)
const [isPremiumaddon, setPremiumaddon] = useState(false)
const [isStandardcal, setStandardcal] = useState(true)
const [isPremiumcal, setPremiumcal] = useState(false)
const [selectedPlan, setSelectedPlan] = useState('Standard');
const [isStandAddonQuantity, setIsStandAddonQuantity] = useState([]);
const [isPremiumAddonQuantity, setPremiumAddonQuantity] = useState([]);
const [selectedstandAddons, setselectedstandAddons] = useState([]);
const [selectedpremiumAddons, setSelectedpremiumAddons] = useState([]);

const monthlyplans = [monthly_plan];
const yearlyplans = [yearly_plan];


const NoQuantityAddons = [
    "Job Posting Credits", 
    "AI Resume Parsing Credits", 
    "Contact Unlock Credits", 
    "AI Resume Matching with Descriptive Analytics"
]

const handlebillMonthSwitch = () => {
    setPlanSwitch(false);
}

const handlebillYearSwitch = () => {
    setPlanSwitch(true);
}


// Function for Selecting Plan

const handleCardSelect = (planName) => {
    setSelectedPlan(planName);
    if (selectedPlan === 'Standard') {
        setStandardaddon(true)
        setStandardcal(true)
        setPremiumaddon(false)
        setPremiumcal(false)
    } else if (selectedPlan === 'Premium') {
        setPremiumaddon(true)
        setPremiumcal(true)
        setStandardaddon(false)
        setStandardcal(false)
    }
  };
// Function to Generete URL with Selected Plans and Addons


const constructUrlWithAddons = () => {
    const { origin } = window.location;
    const baseUrl = '/plan_and_pricing';
    const billingType = isPlanSwitch ? 'yearly' : 'monthly';
  
    const selectedStandAddonsData = selectedstandAddons
      .map((isChecked, index) => ({
        isChecked,
        addonData: isChecked
          ? {
              standard_addon_name: standard_plan[index].standard_addon_name,
              standard_addon_cost: standard_plan[index].standard_addon_cost,
              standard_addon_id: standard_plan[index].id,
            }
          : null,
        quantity: isChecked ? isStandAddonQuantity[index] : isStandAddonQuantity,
      }))
      .filter((item) => item.isChecked);
  
    const selectedPremiumAddonsData = selectedpremiumAddons
      .map((isChecked, index) => ({
        isChecked,
        quantity: isChecked ? isPremiumAddonQuantity[index] : isPremiumAddonQuantity,
        addonData: isChecked
          ? {
              premium_addon_name: premium_plan[index].premium_addon_name,
              premium_addon_cost: premium_plan[index].premium_addon_cost,
              premium_addon_id: premium_plan[index].id,
            }
          : null,
      }))
      .filter((item) => item.isChecked);
  
      const queryString = `/?billingType=${encodeURIComponent(isPlanSwitch ? 'annually' : 'monthly')}&selectedPlan=${encodeURIComponent(selectedPlan)}&standAddons=${encodeURIComponent(
        JSON.stringify(selectedStandAddonsData)
      )}&premiumAddons=${encodeURIComponent(JSON.stringify(selectedPremiumAddonsData))}&urlstatus=true`;
      
  
    const finalUrl = origin + baseUrl + queryString;
    return finalUrl;
  };
  
  
  
// To Render Addons Initial Value and Selected Values (Standard)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const planselected = urlParams.get('selectedPlan');
    const standAddonsParam = urlParams.get('standAddons');
    const urlstatus = urlParams.get('urlstatus');
    const billingType = urlParams.get('billingType');
  
    if (urlstatus === null && !standAddonsParam) {
        const initialQuantity = standard_plan.map(addon => {
            if (["Job Posting Credits"].includes(addon.standard_addon_name)) {
                return 5;
            } else if (["Contact Unlock Credits"].includes(addon.standard_addon_name)){
                return 20
            } else if (["AI Resume Matching with Descriptive Analytics"].includes(addon.standard_addon_name)){
                return 100
            } else if (["AI Resume Parsing Credits"].includes(addon.standard_addon_name)){
                return 100
            }  else {
                return 1;
            }
        });
        setIsStandAddonQuantity(initialQuantity);
    } else {
      const decodedStandAddons = standAddonsParam ? JSON.parse(decodeURIComponent(standAddonsParam)) : null;
      setselectedstandAddons(decodedStandAddons.map(item => item.isChecked));
      setIsStandAddonQuantity(decodedStandAddons.map(item => item.quantity));
      setSelectedPlan(planselected);
  
      if (billingType === 'annually') {
        setPlanSwitch(true);
      } else {
        setPlanSwitch(false);
      }
    }
  }, [standard_plan]);
  
  
//   To Render Addons Initial Value and Selected Values (Premium)
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const planselected = urlParams.get('selectedPlan');
    const premiumAddonsParam = urlParams.get('premiumAddons');
    const urlstatus = urlParams.get('urlstatus');
    const billingType = urlParams.get('billingType');

    if (urlstatus === null) {
        const initialQuantity = premium_plan.map(addon => {
            if (["Job Posting Credits"].includes(addon.premium_addon_name)) {
                return 5;
            } else if (["Contact Unlock Credits"].includes(addon.premium_addon_name)){
                return 20
            } else if (["AI Resume Matching with Descriptive Analytics"].includes(addon.premium_addon_name)){
                return 100
            } else if (["AI Resume Parsing Credits"].includes(addon.premium_addon_name)){
                return 100
            }  else {
                return 1;
            }
        });
      setPremiumAddonQuantity(initialQuantity);
  } else {
      const decodedPremiumAddons = premiumAddonsParam ? JSON.parse(decodeURIComponent(premiumAddonsParam)) : [];
      setSelectedpremiumAddons(decodedPremiumAddons.map(item => item.isChecked));
      setPremiumAddonQuantity(decodedPremiumAddons.map(item => item.quantity));
      setSelectedPlan(planselected)
      if (billingType === 'annually') {
        setPlanSwitch(true);
      } else {
        setPlanSwitch(false);
      }
  }
  
}, [premium_plan]);
  
// Function to copy the generated URL
  const handleCopyUrl = () => {
    const urlToCopy = constructUrlWithAddons();
    const tempInput = document.createElement('input');
    tempInput.value = urlToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999)
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    Toast('URL copied successfully.');
  };

const selectedStandardAddons = selectedstandAddons?.map((isChecked, index) => {
    const addon_name = standard_plan[index]?.standard_addon_name;
    const addon_cost = standard_plan[index]?.standard_addon_cost;
    const addon_id = standard_plan[index]?.id;
  
    let quantity = isStandAddonQuantity[index];
  
    if (addon_name === "Job Posting Credits") {
      quantity /= 5;
    } else if (addon_name === "AI Resume Parsing Credits"){
      quantity /= 100;
    } else if (addon_name === "Contact Unlock Credits"){
      quantity /= 20;
    } else if (addon_name === "AI Resume Matching with Descriptive Analytics"){
      quantity /= 100;
    }
  
    return isChecked ? {
      addonData: {
        addon_name,
        addon_cost,
        addon_id,
      },
      quantity,
    } : null;
  }).filter(Boolean);

const selectedPremiumAddons = selectedpremiumAddons?.map((isChecked, index) => {
    const addon_name = premium_plan[index]?.premium_addon_name;
    const addon_cost = premium_plan[index]?.premium_addon_cost;
    const addon_id = premium_plan[index]?.id;
  
    let quantity = isPremiumAddonQuantity[index];
  
    if (addon_name === "Job Posting Credits") {
      quantity /= 5;
    } else if (addon_name === "AI Resume Parsing Credits"){
      quantity /= 100;
    } else if (addon_name === "Contact Unlock Credits"){
      quantity /= 20;
    } else if (addon_name === "AI Resume Matching with Descriptive Analytics"){
      quantity /= 100;
    }
  
    return isChecked ? {
      addonData: {
        addon_name,
        addon_cost,
        addon_id,
      },
      quantity,
    } : null;
  }).filter(Boolean);

let ID: string;

if (!isPlanSwitch) {
    ID = selectedPlan === 'Standard' ? '7' : '8';
} else {
    ID = selectedPlan === 'Standard' ? '11' : '12';
}

const getstartedurl = `/recruiter/${ID}?selectedPlan=${encodeURIComponent(selectedPlan)}&standardAddon=${encodeURIComponent(JSON.stringify(selectedStandardAddons)).replace(/%20/g, '-')}&premiumAddons=${encodeURIComponent(JSON.stringify(selectedPremiumAddons)).replace(/%20/g, '-')}`;


const handleGetStarted = () => {
    window.location.href = getstartedurl;
};


  const getInitialQuantityForAddon = (addonName) => {
    switch (addonName) {
      case "Job Posting Credits":
        return 5;
      case "Contact Unlock Credits":
        return 20;
      case "AI Resume Matching with Descriptive Analytics":
        return 100;
      case "AI Resume Parsing Credits":
        return 100;
      default:
        return 1;
    }
  };

const handleStandardCheckboxChange = (index) => {
    const updatedCheckedItems = [...selectedstandAddons];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
  
    const newQuantityArray = [...isStandAddonQuantity];
    if (updatedCheckedItems[index]) {
      newQuantityArray[index] = getInitialQuantityForAddon(standard_plan[index].standard_addon_name);
    } else {
      newQuantityArray[index] = 0;
    }
    setselectedstandAddons(updatedCheckedItems);
    setIsStandAddonQuantity(newQuantityArray);
  };



  const handlePremiumCheckboxChange = (index) => {
    const updatedCheckedItems = [...selectedpremiumAddons];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    
    const newQuantityArray = [...isPremiumAddonQuantity];
    if (updatedCheckedItems[index]) {
        newQuantityArray[index] = getInitialQuantityForAddon(premium_plan[index].premium_addon_name);
    } else {
        newQuantityArray[index] = 0
    }
    setSelectedpremiumAddons(updatedCheckedItems);
    setPremiumAddonQuantity(newQuantityArray)
  };

  const handleStandardRemoveAddon = (index) => {
    const updatedCheckedItems = [...selectedstandAddons];
    updatedCheckedItems[index] = false;
    setselectedstandAddons(updatedCheckedItems);
  };
  const handlePremiumRemoveAddon = (index) => {
    const updatedCheckedItems = [...selectedpremiumAddons];
    updatedCheckedItems[index] = false;
    setSelectedpremiumAddons(updatedCheckedItems);
  };

const handleStandardQuantityChange = (event, index) => {
  const newQuantityArray = [...isStandAddonQuantity];
  newQuantityArray[index] = event.target.value;
  setIsStandAddonQuantity(newQuantityArray);
};
const handlePremiumQuantityChange = (event, index) => {
    const newQuantityArray = [...isPremiumAddonQuantity];
    newQuantityArray[index] = event.target.value;
    setPremiumAddonQuantity(newQuantityArray);
};

const totalStandAddonCost = selectedstandAddons.reduce((total, isChecked, index) => {
    if (isChecked) {
        let quantity = isStandAddonQuantity[index] || 0;
        const addonCost = parseFloat(standard_plan[index]?.standard_addon_cost) || 0;
        const addon_name = (standard_plan[index]?.standard_addon_name) || 0;
        if (addon_name === "Job Posting Credits") {
            quantity /= 5;
          } else if (addon_name === "AI Resume Parsing Credits"){
            quantity /= 100;
          } else if (addon_name === "Contact Unlock Credits"){
            quantity /= 20;
          } else if (addon_name === "AI Resume Matching with Descriptive Analytics"){
            quantity /= 100;
          }
        return total + quantity * addonCost;

    }
    return total;
}, 0);

const totalPremiumAddonCost = selectedpremiumAddons.reduce((total, isChecked, index) => {
    if (isChecked) {
        let quantity = isPremiumAddonQuantity[index] || 0;
        const addonCost = parseFloat(premium_plan[index]?.premium_addon_cost) || 0;
        const addon_name = (premium_plan[index]?.premium_addon_name) || 0;
        if (addon_name === "Job Posting Credits") {
            quantity /= 5;
          } else if (addon_name === "AI Resume Parsing Credits"){
            quantity /= 100;
          } else if (addon_name === "Contact Unlock Credits"){
            quantity /= 20;
          } else if (addon_name === "AI Resume Matching with Descriptive Analytics"){
            quantity /= 100;
          }
        return total + quantity * addonCost;
    }
    return total;
}, 0);

useEffect(()=>{
    dispatch(websiteSubscriptionScreenMiddleWare())
  },[])

  return (
    <div>
        <Flex middle center marginBottom={20}>
            <Flex marginBottom={10} marginTop={15}>
                <Text bold size={16} align='center'>Calculate Your Price</Text>
            </Flex>
            <Flex>
                <Text size={13} align='center'>
                    Calculate your tailor price: Seamlessly combine your Zita paln and Add-Ons for an instant, custom cost estimate
                </Text>
            </Flex>
            </Flex>

        <Flex row between>
            <Flex width={"65%"}>
                <Flex row between marginBottom={15}>
                    <Flex>
                        <Text bold size={14}>Select your subscription plan</Text>
                        <Text size={13}>Elevate Your Business with the Perfect Fit!</Text>
                    </Flex>
                </Flex>

                <Flex row marginBottom={15}>
                {monthly_plan.filter(plan => plan.plan_name !== "Free Trial" && plan.plan_name !== "Enterprise").map((plan, index) => (
                            <Card key={index} 
                            className={plan.plan_name === "Standard" ? `${styles.standardcardplan} ${selectedPlan === plan.plan_name ? styles.standardcardplancheck : ''}` 
                                        : `${styles.premiumcardplan} ${selectedPlan === plan.plan_name ? styles.premiumcardplancheck : ''}`} 
                            onClick={() => handleCardSelect(plan.plan_name)}>
                            <Flex row style={{ padding:"10px" }} width={"100%"}>
                                <Flex width={"8%"} marginTop={5}>
                                    {selectedPlan === plan.plan_name ? (
                                    <Flex><SvgTick/></Flex>
                                    ) : (
                                    <Flex><SvgRoundEmpty width={18} height={18}/></Flex>
                                    )}
                                </Flex>
                                <Flex row between width={"92%"}>
                                    <Flex>
                                        <Text bold size={16}>
                                            {plan.plan_name}
                                        </Text>
                                        <Text color='theme' size={13}>
                                            {plan.sub_heading}
                                        </Text>
                                    </Flex>

                                    <Flex row style={{ alignItems: "end" }}>
                                        <Text bold size={16}>${plan.price}</Text>
                                        <Text size={13}>/Month</Text>
                                        </Flex>
                                </Flex>
                                </Flex>
                            </Card>
                        ))}                       
                        </Flex>

                <Flex marginBottom={20}>
                    <Flex>
                        <Text bold size={14}>Personalize your plan with Add-Ons</Text>
                        <Text size={13}>Tailor your Zita plan with Add-Ons for a personalized business solution.</Text>
                    </Flex>
                </Flex>
                {selectedPlan === 'Standard' && standard_plan.map((standard, index) => (
                    
                    <Card key={index} 
                    className={`${styles.addoncarddes} ${selectedstandAddons[index] ? styles.checkedAddon : ''}`}
                    >
                        <Flex style={{ padding: "15px" }}>
                        <Flex row between marginBottom={3}>
                            <Flex row>
                                <Flex><Text bold color='theme' size={14}>{standard.standard_addon_name}</Text></Flex>
                                </Flex>
                            <InputCheckBox
                            onChange={() => handleStandardCheckboxChange(index)}
                            checked={selectedstandAddons[index]}
                            />
                        </Flex>

                        <Flex row between>
                            <Flex width={'60%'}>
                            <Text size={13} style={{ lineHeight:1.8 }}>
                                {standard.standard_addon_des}
                            </Text>
                            </Flex>

                            <Flex row width={'40%'} between 
                            style={{ alignItems: [NoQuantityAddons].includes(standard.standard_addon_name) ? "flex-end" : "flex-start",padding: "0px 20px 0px 20px" }}>
                            <Flex>
                                <Flex row center marginBottom={5}>
                                <Flex marginRight={5}><SvgCircleDollar width={14} height={14} /></Flex>
                                <Text bold size={13}>{standard.standard_addon_cost}</Text>
                                </Flex>
                                <Flex row center>
                                {standard.type === 'Rolling' &&
                                    <Flex marginRight={5}><SvgRolling width={12} height={12}/></Flex>
                                    }
                                    <Flex center><Text bold size={13}>{standard.type}</Text></Flex>
                                </Flex>

                            </Flex>
                                {standard.quantity && (
                                    <Flex width={'70%'} row>
                                        <Flex>
                                            <Text>Quantity:{' '}</Text>
                                            </Flex>
                                        <Flex marginLeft={5}>
                                            <select
                                                style={{
                                                    width: "60px",
                                                    fontSize: '13px',
                                                    textAlign: 'center',
                                                    boxShadow: "rgb(238, 232, 236) 1px 2px 2px 2px",
                                                    borderRadius: "5px",
                                                    height: "20px"
                                                }}
                                                className={styles.standardQuantityDropdown}
                                                value={isStandAddonQuantity[index]}
                                                onChange={(event) => handleStandardQuantityChange(event, index)}
                                            >
                                                {Array.from({ length: 12 }).map((_, i) => {
                                                    const quantity = standard.quantity * (i + 1);
                                                    return (
                                                        <option key={i} value={quantity}>
                                                            {quantity}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>
                        </Flex>
                    </Card>
                ))}

                {selectedPlan === 'Premium' && premium_plan.map((premiumaddon , index) => (
                            <Card key={index}
                            className={`${styles.addoncarddes} ${selectedpremiumAddons[index] ? styles.checkedAddon : ''}`}>
                            <Flex style={{ padding:"15px" }}>
                                <Flex row between marginBottom={3}>
                                    <Flex row>
                                        <Flex><Text bold color='theme' size={14}>{premiumaddon.premium_addon_name}</Text></Flex>
                                    </Flex>
    
                                    <InputCheckBox
                                        onChange={() => handlePremiumCheckboxChange(index)}
                                        checked={selectedpremiumAddons[index]}
                                            />
                                    </Flex>
        
                                    <Flex row between>
                                        <Flex width={'60%'}>
                                            <Text size={13} style={{ lineHeight: 1.8 }}>
                                                {premiumaddon.premium_addon_des}
                                            </Text>
                                        </Flex>
        
                                        <Flex row width={'40%'} between style={{ padding: "0px 20px 0px 20px" }}>
                                                <Flex width={'30%'}>
                                                <Flex row center marginBottom={5}>
                                                    <Flex marginRight={5}><SvgCircleDollar width={14} height={14}/></Flex>
                                                        <Text bold size={13}>{premiumaddon.premium_addon_cost}</Text>
                                                    </Flex>
        
                                                <Flex row center>
                                                    {premiumaddon.type === 'Rolling' &&
                                                    <Flex marginRight={5}><SvgRolling width={12} height={12}/></Flex>
                                                    }
                                                        <Text bold size={13}>{premiumaddon.type}</Text>
                                                        </Flex>
                                                </Flex>
        
                                                <Flex width={'70%'}>
                                                    {premiumaddon.quantity && (
                                                        <Flex width={'70%'} row>
                                                            <Flex>
                                                                <Text>Quantity:{' '}</Text>
                                                                </Flex>
                                                            <Flex marginLeft={5}>
                                                                <select
                                                                    style={{
                                                                        width: "60px",
                                                                        fontSize: '13px',
                                                                        textAlign: 'center',
                                                                        boxShadow: "rgb(238, 232, 236) 1px 2px 2px 2px",
                                                                        borderRadius: "5px",
                                                                        height: "20px"
                                                                    }}
                                                                    // className={styles.standardQuantityDropdown}
                                                                    value={isPremiumAddonQuantity[index]}
                                                                    onChange={(event) => handlePremiumQuantityChange(event, index)}
                                                                >
                                                                    {Array.from({ length: 12 }).map((_, i) => {
                                                                        const quantity = premiumaddon.quantity * (i + 1);
                                                                        return (
                                                                            <option key={i} value={quantity}>
                                                                                {quantity}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </Flex>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                        </Flex>
                                    </Flex>
                            </Flex>
                    </Card>
                ))}

            </Flex>

            <Flex width={25}/>

            {selectedPlan === 'Standard' && (
                <Flex width={"35%"}>
                <Flex>
                    <Card>
                        <Flex style={{borderRadius: 5 ,padding: "25px", borderTop: '5px solid #581845', backgroundColor:'rgb(250, 250, 250)', position: 'relative'}}>
                        <Flex row center height={35} style={{ 
                            backgroundColor: '#EEE8EC',
                            borderRadius: "25px", 
                            position:'relative' 
                            }}>
                                <Flex className={styles.billMonth} onClick={handlebillMonthSwitch}>
                                    <Text className={!isPlanSwitch ? styles.billmonthtext:''} size={13}>Billed Monthly</Text>
                                </Flex>
                                <Flex className={styles.billYear} onClick={handlebillYearSwitch}>
                                    <Text className={!isPlanSwitch ? '' :styles.billyeartext} size={13}>Billed Annually</Text>
                                </Flex>
                                <Flex
                                    style={{
                                        width: '205px',
                                        height: '43px',
                                        position: 'absolute',
                                        left: !isPlanSwitch ?  '0%' : '50%',
                                        transition: 'left 0.2s ease-in-out',
                                        borderRadius: "25px",
                                        backgroundColor: "#581845",
                                    }}
                            />
                            </Flex>
                            <Flex 
                            // className={isPlanSwitch ? styles.standannalbill:styles.standmonthbill}
                            className={styles.standmonthbill}
                            >
                                {!isPlanSwitch ? (
                                    <>
                                      <Flex marginBottom={5}>
                                        <Text size={14}align='center'>{selectedPlan} Plan at</Text>
                                        </Flex>
                                    <Flex>
                                        <Text size={18}bold align='center'>
                                            ${monthlyplans[0][1]?.price}/ mo
                                            </Text>
                                    </Flex>
                                    <Flex center middle height={30}>
                                        <Text size={13}align='center'>
                                            Billed monthly
                                            </Text>
                                        </Flex>
                                    </>
                                ) : (
                                <>
                                <Flex marginBottom={5}>
                                  <Text size={14}align='center'>{selectedPlan} Plan at</Text>
                                  </Flex>
                                    <Flex height={30} center middle>
                                    <Text size={13}>
                                        Billed anually at{' '}
                                            <Text bold size={14} style={{textDecoration: 'line-through',}}>
                                                ${monthlyplans[0][1]?.price * 12}/ yr
                                                </Text>
                                            <Text bold size={22}>
                                                {' '}${yearlyplans[0][1]?.price}/ yr
                                                </Text>
                                    </Text>
                                </Flex>
                                <Flex row middle center className={styles.discountlabel}>
                                    <Flex center middle>
                                        <Flex className={styles.roundDiv}><Text bold size={16} color='white'>%</Text></Flex>
                                    </Flex>
                                    <Flex marginLeft={3}>
                                        <Text bold size={14} style={{ color: '#E31B1B' }}>
                                            Save 10%
                                        </Text>
                                    </Flex>
                                </Flex>
                              </>
                                )}
                            </Flex>

                            <Flex marginTop={10}>

                            {selectedstandAddons.some((isChecked) => isChecked) && (
                                <Flex marginBottom={12}>
                                <Text bold size={14}>
                                    Selected Add on feature
                                </Text>
                                </Flex>
                            )}

                            <Flex className={styles.addoncalcardflex}>
                                
                                {selectedstandAddons.map((isChecked, index) => (
                                    isChecked && (
                                        <Flex key={`additional_${index}`} className={styles.addoncalcard1}>

                                        <Flex style={{ padding:"10px", height: '116px' }}>

                                            <Flex row between center marginBottom={5} style={{ alignItems: "baseline" }}>
                                                <Flex center>
                                                <Text color='theme'>
                                                    {standard_plan[index]?.standard_addon_name}
                                                </Text>
                                                </Flex>
                                                <Flex 
                                                onClick={() => handleStandardRemoveAddon(index)}
                                                marginBottom={5}>
                                                <SvgClose width={8} height={8}/>
                                            </Flex>
                                            </Flex>

                                            <Flex>
                                            {["Job Posting Credits", "AI Resume Parsing Credits", "Contact Unlock Credits", "AI Resume Matching with Descriptive Analytics"].includes(standard_plan[index]?.standard_addon_name) ? (
                                                <>
                                                {['Job Posting Credits'].includes(standard_plan[index]?.standard_addon_name) && (
                                                        <Flex row center marginBottom={5}>
                                                        <Text >Includes {''} 
                                                            <Text bold>{isStandAddonQuantity[index]}</Text>
                                                            {' '}Job Postings
                                                        </Text>
                                                    </Flex> 
                                                )}
                                                {['AI Resume Parsing Credits'].includes(standard_plan[index]?.standard_addon_name) && (
                                                        <Flex row center marginBottom={5}>
                                                        <Text>Includes {''} 
                                                            <Text bold>{isStandAddonQuantity[index]}</Text>
                                                            {' '}Resumes
                                                        </Text>
                                                    </Flex> 
                                                )}
                                                {['Contact Unlock Credits'].includes(standard_plan[index]?.standard_addon_name) && (
                                                        <Flex row center marginBottom={5}>
                                                        <Text >Includes {''} 
                                                            <Text bold>{isStandAddonQuantity[index]}</Text>
                                                            {' '}Credits
                                                        </Text>
                                                    </Flex> 
                                                )}
                                                {['AI Resume Matching with Descriptive Analytics'].includes(standard_plan[index]?.standard_addon_name) && (
                                                        <Flex row center marginBottom={5}>
                                                        <Text >Includes {''} 
                                                            <Text bold>{isStandAddonQuantity[index]}</Text>
                                                            {' '}Resumes
                                                        </Text>
                                                    </Flex> 
                                                )}
                                                </>          
                                            ) : (null)}
                                            </Flex>

                                            <Flex row center between>
                                            <Flex row center>
                                                <Flex marginRight={5}>

                                                    <SvgCircleDollar width={14} height={14}/>
                                                    </Flex>
                                                        <Text bold>
                                                            {standard_plan[index]?.standard_addon_cost}
                                                        </Text>
                                                    </Flex>
                                                    <Flex row center>
                                                    {standard_plan[index]?.type === 'Rolling' &&
                                                        <Flex marginRight={5}><SvgRolling width={12} height={12}/></Flex>
                                                        }
                                                        <Flex center><Text bold size={13}>{standard_plan[index]?.type}</Text></Flex>
                                                    </Flex>
                                            </Flex>

                                        </Flex>
                                    </Flex>
                                    )
                                ))}
                                    </Flex>
                                {selectedstandAddons.some((isChecked) => isChecked) && (
                                    <>
                                    <Flex marginTop={12} marginBottom={12} className={styles.standorderSum}>
                                    <Flex marginBottom={3}>
                                        <Text size={14} bold>
                                            Order Summary
                                        </Text>
                                    </Flex>
                                    <Flex  between row center>
                                    <Text size={13}>
                                        Cost of add-ons: {' '} 
                                    </Text>
                                    <Text style={{ fontSize: '16px' }} bold>${totalStandAddonCost}</Text>
                                    </Flex>
                                    <Flex row center between>
                                    <Text size={13}>
                                        Cost of Subscription to get started: {' '} 
                                    </Text>
                                    {isPlanSwitch ? (
                                            <Text style={{ fontSize: '16px' }} bold>${totalStandAddonCost + yearlyplans[0][1]?.price}</Text>
                                        ):(
                                            <Text style={{ fontSize: '16px' }} bold>${totalStandAddonCost + monthlyplans[0][1]?.price}</Text>
                                        )}
                                    </Flex>
                                    </Flex>
                                    </>
                                    )}

                                <Flex middle center marginTop={5}>
                                    <Button onClick={handleGetStarted} types='primary' style={{backgroundColor:'#783664'}}>Get Started</Button>
                                </Flex>
                                <Flex row middle className={styles.bottomlinkContainer}>
                                    <Flex row onClick={handleCopyUrl} style={{ cursor: 'pointer' }}>
                                        <Flex marginRight={5}>
                                            <SvgShareLink/>
                                        </Flex>
                                        <Text size={14} bold color='theme'>
                                            Share Link
                                        </Text>
                                    </Flex>
                                </Flex>

                            </Flex>
                            
                            </Flex>
                        </Card>
                </Flex>
            </Flex>
            )}
            {selectedPlan === 'Premium' && (
                <Flex width={"35%"}>
                <Flex>
                    <Card>
                        <Flex style={{borderRadius: 5 ,padding: "25px", borderTop: '5px solid #581845', backgroundColor:'rgb(250, 250, 250)', position: 'relative'}}>

                        <Flex row center height={35} style={{ 
                            backgroundColor: '#EEE8EC',
                            borderRadius: "25px", 
                            position:'relative' 
                            }}>
                                <Flex className={styles.billMonth} onClick={handlebillMonthSwitch}>
                                    <Text className={!isPlanSwitch ? styles.billmonthtext:''} size={13}>Billed Monthly</Text>
                                </Flex>
                                <Flex className={styles.billYear} onClick={handlebillYearSwitch}>
                                    <Text className={!isPlanSwitch ? '' :styles.billyeartext} size={13}>Billed Annually</Text>
                                </Flex>
                                <Flex
                                    style={{
                                        width: '205px',
                                        height: '43px',
                                        position: 'absolute',
                                        left: !isPlanSwitch ?  '0%' : '50%',
                                        transition: 'left 0.2s ease-in-out',
                                        borderRadius: "25px",
                                        backgroundColor: "#581845",
                                    }}
                            />
                            </Flex>
                            <Flex className={styles.premiummonthbill}>
                                {!isPlanSwitch ? (
                                    <>
                                      <Flex marginBottom={5}>
                                        <Text size={14}align='center'>{selectedPlan} Plan at</Text>
                                        </Flex>
                                    <Flex>
                                        <Text size={18}bold align='center'>
                                            ${monthlyplans[0][2]?.price}/ mo
                                            </Text>
                                    </Flex>
                                    <Flex center middle height={30}>
                                        <Text size={13}align='center'>
                                            Billed monthly
                                            </Text>
                                        </Flex>
                                    </>
                                ) : (
                                <>
                                <Flex marginBottom={5}>
                                  <Text size={14}align='center'>{selectedPlan} Plan at</Text>
                                  </Flex>
                                    <Flex height={30} center middle>
                                    <Text size={13}>
                                        Billed anually at{' '}
                                            <Text bold size={14} style={{textDecoration: 'line-through',}}>
                                                ${monthlyplans[0][2]?.price * 12}/ yr
                                                </Text>
                                            <Text bold size={22}>
                                                {' '}${yearlyplans[0][2]?.price}/ yr
                                                </Text>
                                    </Text>
                                </Flex>
                                <Flex row middle center className={styles.discountlabel}>
                                    <Flex center middle>
                                        <Flex className={styles.roundDiv}><Text bold size={16} color='white'>%</Text></Flex>
                                    </Flex>
                                    <Flex marginLeft={3}>
                                        <Text bold size={14} style={{ color: '#E31B1B' }}>
                                            Save 10%
                                        </Text>
                                    </Flex>
                                </Flex>
                              </>
                                )}
                            </Flex>

                            <Flex marginTop={10}>

                            {selectedpremiumAddons.some((isChecked) => isChecked) && (
                                <Flex marginBottom={12}>
                                <Text bold size={14}>
                                    Selected Add on feature
                                </Text>
                                </Flex>
                            )}

                            <Flex className={styles.addoncalcardflex}>
                                {selectedpremiumAddons.map((isPremiumChecked, index) => (
                                    isPremiumChecked && (
                                        <Card key={`additional_${index}`} className={styles.addoncalcard1}>
                                        <Flex style={{ padding:"10px", height:'116px' }}>

                                            <Flex row between center marginBottom={5} style={{ alignItems: 'baseline' }}>
                                                <Flex center>
                                                    <Text color='theme'>
                                                        {premium_plan[index]?.premium_addon_name}
                                                    </Text>
                                                </Flex>

                                                <Flex 
                                                    onClick={() => handlePremiumRemoveAddon(index)}
                                                    marginBottom={5}>
                                                        <SvgClose width={8} height={8}/>
                                                        </Flex>
                                            </Flex>

                                                <>
                                                {['Job Posting Credits'].includes(premium_plan[index]?.premium_addon_name) && (
                                                        <Flex row center marginBottom={5}>
                                                        <Text>Includes {''} 
                                                            <Text bold>{isPremiumAddonQuantity[index]}</Text>
                                                            {' '}Job Postings
                                                        </Text>
                                                    </Flex> 
                                                )}
                                                {['AI Resume Parsing Credits'].includes(premium_plan[index]?.premium_addon_name) && (
                                                        <Flex row center marginBottom={5}>
                                                        <Text>Includes {''} 
                                                            <Text bold>{isPremiumAddonQuantity[index]}</Text>
                                                            {' '}Resumes
                                                        </Text>
                                                    </Flex> 
                                                )}
                                                {['Contact Unlock Credits'].includes(premium_plan[index]?.premium_addon_name) && (
                                                        <Flex row center marginBottom={5}>
                                                        <Text>Includes {''} 
                                                            <Text bold>{isPremiumAddonQuantity[index]}</Text>
                                                            {' '}Credits
                                                        </Text>
                                                    </Flex> 
                                                )}
                                                {['AI Resume Matching with Descriptive Analytics'].includes(premium_plan[index]?.premium_addon_name) && (
                                                        <Flex row center marginBottom={5}>
                                                        <Text>Includes {''} 
                                                            <Text bold>{isPremiumAddonQuantity[index]}</Text>
                                                            {' '}Resumes
                                                        </Text>
                                                    </Flex> 
                                                )}
                                                </>  

                                            <Flex row center between>
                                            <Flex row center>
                                                <Flex marginRight={5}>
                                                    <SvgCircleDollar width={14} height={14}/>
                                                    </Flex>
                                                        <Text bold>
                                                            {premium_plan[index]?.premium_addon_cost}
                                                        </Text>
                                                    </Flex>
                                                    <Flex row center>
                                                    {premium_plan[index]?.type === 'Rolling' &&
                                                        <Flex marginRight={5}><SvgRolling width={12} height={12}/></Flex>
                                                        }
                                                        <Flex center><Text bold size={13}>{premium_plan[index]?.type}</Text></Flex>
                                                    </Flex>
                                            </Flex>
 
                                        </Flex>
                                    </Card>
                                    )
                                ))}
                                    </Flex>

                                    {selectedpremiumAddons.some((isChecked) => isChecked) && (
                                    <>
                                    <Flex marginBottom={12} marginTop={12} className={styles.premorderSum}>
                                        <Flex marginBottom={3}>
                                            <Text size={14} bold>
                                                Order Summary
                                            </Text>
                                        </Flex>
                                    <Flex  between row center>
                                    <Text size={13}>
                                        Cost of add-ons: {' '} 
                                    </Text>
                                    <Text style={{ fontSize: '16px' }} bold>${totalPremiumAddonCost}</Text>
                                    </Flex>
                                    <Flex row center between>
                                    <Text size={13}>
                                        Cost of Subscription to get started: {' '} 
                                    </Text>
                                    {isPlanSwitch ? (
                                            <Text style={{ fontSize: '16px' }} bold>${totalPremiumAddonCost + yearlyplans[0][2]?.price}</Text>
                                        ):(
                                            <Text style={{ fontSize: '16px' }} bold>${totalPremiumAddonCost + monthlyplans[0][2]?.price}</Text>
                                        )}
                                    </Flex>
                                    </Flex>
                                    </>
                                    )}
                                    
                                <Flex middle center marginTop={5}>
                                    <Button onClick={handleGetStarted} types='primary' style={{backgroundColor:'#783664'}}>Get Started</Button>
                                </Flex>
                                <Flex middle between className={styles.bottomlinkContainer}>
                                    <Flex row 
                                    onClick={handleCopyUrl}
                                    style={{ cursor: 'pointer' }}>
                                        <Flex marginRight={5}>
                                            <SvgShareLink/>
                                        </Flex>
                                        <Text size={14} bold color='theme'>
                                            Share Link
                                        </Text>
                                    </Flex>
                                </Flex>

                            </Flex>
                            
                            </Flex>
                        </Card>
                </Flex>
            </Flex>
            )}
        </Flex>
    </div>
  )
}

export default SubscriptionCalculation
