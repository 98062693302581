import * as React from 'react';
const SvgTwitterIcon = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={24} 
    height={24} 
    viewBox='0 0 24 24'
    {...props}>
    
    <path d="m10.053 7.988 5.631 8.024h-1.497L8.566 7.988h1.487zM21 21H3V3h18v18zm-3.462-4-4.186-5.99L16.774 7h-1.311l-2.704 3.16L10.552 7h-3.85l3.941 5.633L6.906 17h1.333l3.001-3.516L13.698 17h3.84z" />
  </svg>
);
export default SvgTwitterIcon;