import { useState, useEffect, useRef } from 'react'
import Select from 'react-select';
import classNames from 'classnames/bind';
import Loader from '../Loader/Loader';
import { isEmpty } from '../helper';
import Text from '../Text/Text';
import Flex from '../Flex/Flex';
import Pangination from '../Pagination/Pangination';
import { options } from '../../modules/myjobposting/mock';
import Rows, { ColumnItem } from './Rows';
import TableTitle from './TableTitle';
import styles from './table.module.css';


const cx = classNames.bind(styles);

type DefaultProps = {
  columns: ColumnItem[];
  dataSource: { [key: string]: any }[];
  border: 'outline' | 'overAll' | 'normal';
};

const defaultProps: DefaultProps = {
  columns: [],
  dataSource: [],
  border: 'normal',
};
type Props = {
  isLoader?: boolean;
  isscroll?: boolean;
  empty?: string;
  scrollHeight?: number;
  emptyHeight?: number;
  fixedScrollHeight?: boolean;
  rowFocusIndex?: number;
  pageCount?: any;
  pageNumber?: any;
  handleSetPagination?: any;
  isCandiTableLoader?: any;
  isPageTab?: any;
  setpagecount?: (arg?: number) => void;
  pagination_count?: number;
  only_pagination?: boolean;
  isBulkTab?: any;
  candidatefocus?: boolean;
} & typeof defaultProps;

const Table = ({
  dataSource,
  columns,
  isLoader,
  empty,
  border,
  isscroll,
  scrollHeight,
  emptyHeight,
  fixedScrollHeight,
  rowFocusIndex,
  pageCount,
  pageNumber,
  handleSetPagination,
  isCandiTableLoader,
  isPageTab,
  setpagecount,
  pagination_count,
  only_pagination,
  isBulkTab,
  candidatefocus
}: Props) => {
  if (columns.length === 0) {
    return null;
  }
  const url = window.location.href;
  const test =
    !url.includes('jobs_workflow') &&
    (url.includes('reports') || url.includes('jobs') || url.includes('job_view'));
  const test1 = !url.includes('jobs_workflow') && url.includes('jobs');
  const [componentHeight, setComponentHeight] = useState(window.innerHeight - 350);
  const [appComponentHeight, setAppComponentHeight] = useState(window.innerHeight - 395)

  useEffect(() => {
    const handleResize = () => {
      if (isBulkTab === '1') {
        setAppComponentHeight(window.innerHeight - 395)
      } else {
        setComponentHeight(window.innerHeight - 350);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // custom styles for page selection text box and dropdown.
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#581845' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
      justifyContent: "center",
      '&:hover': {
        backgroundColor: state.isSelected ? 'none' : '#D7C7D2',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #581845' : '1px solid #d7c7d2',
      fontSize: '12px',
      minHeight: '16px',
      lineHeight: '16px',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '1px solid #581845',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      height: '16px',
      lineHeight: '16px',
      textAlign: 'center',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      margin: '5px 0',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform 0.3s ease',
      height: '16px',
      marginTop: '-20px'
    }),
    menu: (provided) => ({
      ...provided,
      top: 'auto',
      bottom: 22,
    }),
  };

  const rowRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    if (rowFocusIndex !== null && rowRefs.current[rowFocusIndex] && candidatefocus) {
      rowRefs.current[rowFocusIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [rowFocusIndex]);

  return (
    <Flex className={cx({ [`overAll-${border}`]: border })}>
      <TableTitle columns={columns} dataSource={dataSource} />
      <Flex center middle className={cx('overFlowLoader')}>
        {dataSource.length === 0 && !isEmpty(empty) && (
          <Flex center middle height={emptyHeight}>
            <Text
              align="center"
              color="gray"
              className={isscroll === true && dataSource.length === 0 ? (styles.candidateempty) : (cx({
                emptyTextOne: fixedScrollHeight,
                emptyText: !fixedScrollHeight,
              }))}

              style={{ padding: test1 ? '70px 0' : (''), minHeight: test1 ? ('0px') : ('') }}
            >
              {empty}
            </Text>
          </Flex>
        )}
        {isLoader && (
          <div className={styles.loader}>
            <Loader size="medium" withOutOverlay />
          </div>
        )}
      </Flex>
      {fixedScrollHeight ? (
        <div >
          {dataSource.map((item, index) => (
            <Rows
              key={index}
              item={item}
              columns={columns}
              rowIndex={index}
              border={border}
              scrollHeight={scrollHeight}
              rowFocusIndex={rowFocusIndex}
            />
          ))}
        </div>
      ) : (
        <>{dataSource.length !== 0 &&
          <Flex
            style={{ overflow: test ? ('') : 'scroll', display: 'flex', height: test ? ('') : isBulkTab === '1' ? appComponentHeight : componentHeight }}

            // className={cx({ rowScroll: scrollHeight })}
            className={isscroll === true ? (styles.rowScroll) : ("")}
          >{candidatefocus ?
            <>{dataSource.map((item, index) => (
              <div key={index} ref={el => rowRefs.current[index] = el}>
                <Rows
                  key={index}
                  item={item}
                  columns={columns}
                  rowIndex={index}
                  border={border}
                  scrollHeight={scrollHeight}
                  rowFocusIndex={rowFocusIndex}
                /></div>
            ))}</>
            : <>
              {dataSource.map((item, index) => (
                <Rows
                  key={index}
                  item={item}
                  columns={columns}
                  rowIndex={index}
                  border={border}
                  scrollHeight={scrollHeight}
                  rowFocusIndex={rowFocusIndex}
                />
              ))}
            </>}
            {/* {!isCandiTableLoader && isPageTab > 10 && ( */}
            {!isCandiTableLoader && isPageTab > 10 && !only_pagination && (
              <Flex middle className={styles.pagination}>
                <Pangination
                  maxPages={pageCount - 1}
                  currentPage={pageNumber}
                  setCurrentPage={handleSetPagination}
                />
              </Flex>
            )}
            {!isCandiTableLoader && only_pagination && (
              <Flex center row between className={styles.pagination} marginBottom={10} flex={12}>
                <Flex flex={4}>
                </Flex>
                <Flex flex={4} middle>
                  <Pangination
                    maxPages={pageCount - 1}
                    currentPage={pageNumber}
                    setCurrentPage={handleSetPagination}
                  />
                </Flex>
                <Flex row center end flex={4}>
                  <Flex style={{ marginRight: '10px', fontSize: '13px' }}>Candidates per page :</Flex>
                  <Select
                    options={options}
                    value={options.find((e) => e.value === pagination_count)}
                    onChange={(e) => {
                      setpagecount(e.value);
                      handleSetPagination(0, e.value);
                    }}
                    menuPosition="fixed"
                    styles={customStyles}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>}</>
      )}
    </Flex>
  );
};

Table.defaultProps = defaultProps;

export default Table;
