import * as React from 'react';
const SvgBuildCareers = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    viewBox='0 0 25 25'
    {...props}
  >
    <path
      fill="#581845"
      d="M24.167 23.077H22.5V9.617c0-.51-.176-1-.488-1.36-.313-.361-.737-.564-1.179-.564h-6.666V1.924c0-.348-.082-.69-.237-.989a1.8 1.8 0 0 0-.643-.707 1.5 1.5 0 0 0-.868-.226 1.52 1.52 0 0 0-.844.321L3.242 6.732a1.85 1.85 0 0 0-.546.694c-.13.28-.197.592-.196.909v14.742H.833a.78.78 0 0 0-.589.282c-.156.18-.244.424-.244.68 0 .255.088.5.244.68s.368.281.59.281h23.333a.78.78 0 0 0 .589-.282c.156-.18.244-.424.244-.68 0-.255-.088-.5-.244-.68a.78.78 0 0 0-.59-.281m-3.334-13.46v13.46h-6.666V9.617zM4.167 8.334l8.333-6.41v21.152H4.167zm6.666 3.204v1.923c0 .255-.088.5-.244.68a.78.78 0 0 1-.589.282.78.78 0 0 1-.59-.282 1.04 1.04 0 0 1-.243-.68V11.54c0-.255.087-.5.244-.68a.78.78 0 0 1 .589-.281c.221 0 .433.1.59.281.155.18.243.425.243.68m-3.333 0v1.923c0 .255-.088.5-.244.68a.78.78 0 0 1-.59.282.78.78 0 0 1-.589-.282 1.04 1.04 0 0 1-.244-.68V11.54c0-.255.088-.5.244-.68a.78.78 0 0 1 .59-.281c.22 0 .433.1.589.281.156.18.244.425.244.68m0 6.73v1.924c0 .255-.088.5-.244.68a.78.78 0 0 1-.59.281.78.78 0 0 1-.589-.282 1.04 1.04 0 0 1-.244-.68V18.27c0-.255.088-.5.244-.68a.78.78 0 0 1 .59-.282c.22 0 .433.101.589.282s.244.425.244.68m3.333 0v1.924c0 .255-.088.5-.244.68a.78.78 0 0 1-.589.281.78.78 0 0 1-.59-.282 1.04 1.04 0 0 1-.243-.68V18.27c0-.255.087-.5.244-.68a.78.78 0 0 1 .589-.282c.221 0 .433.101.59.282.155.18.243.425.243.68"
    />
  </svg>
);
export default SvgBuildCareers;