import React, { RefObject, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactQuill, { Quill } from 'react-quill';
import { Toast } from '../../../../uikit';
import CustomTooltip from '../../../../uikit/Tooltip/tooltip';
import styles from './emailtemplateeditor.module.css';
import { emailAutomationStyles, templateHubStyles } from './mock';

// Define a custom blot for the custom tag
const Inline = Quill.import('blots/inline');

class CustomTagBlot extends Inline {
  static create(value) {
    let node = super.create();
    node.setAttribute('data-placeholder', value);
    node.setAttribute('contenteditable', false);
    node.style.fontWeight = 'normal !important';
    node.backgroundColor = '#EEE8EC';
    node.color = '#000000 !important';
    node.padding = '3px';
    node.borderRadius = '5px';
    node.marginRight = '5px';
    node.pointerEvents = 'none';
    return node;
  }

  static formats(node: HTMLElement) {
    return node.getAttribute('data-placeholder');
  }
}

CustomTagBlot.blotName = 'customTag';
CustomTagBlot.tagName = 'a';
CustomTagBlot.className = 'custom-tag';
Quill.register(CustomTagBlot);

class NewTagBlot extends Inline {
  static create(value: string) {
    const node = super.create();
    node.textContent = value;
    return node;
  }

  static formats(node: HTMLElement) {
    return node.textContent || '';
  }
}

NewTagBlot.blotName = 'newTag';
NewTagBlot.tagName = 'a';
NewTagBlot.className = 'tag-break';
Quill.register(NewTagBlot);

interface EditorProps {
  handleChange: (
    content: string,
    delta: any,
    source: string,
    editor: any,
  ) => void;
  value: any;
  tagsMenu?: string;
  className?: string;
  height?: any;
  tags?: any[];
  templateHub?: any;
  mailAutomation?: any;
  mailbox?: any;
  disabledTags?: any; // Add disabledTags prop
  isTagIntemation?: any;
  setTagIntemation?: any;
  isDisableTag?: any;
  setDisableTag?: any;
  isTempTagIntemation?: any;
  setTempTagIntemation?: any;
  isTempDisableTag?: any;
  setTempDisableTag?: any;
}

class EmailTemplateEditor extends React.Component<EditorProps> {
  editorRef: RefObject<ReactQuill>;

  constructor(props: EditorProps) {
    super(props);
    this.editorRef = React.createRef<ReactQuill>();
  }

  componentDidMount() {
    // const tagNameOptions = document.querySelectorAll(
    //   '.ql-placeholder .ql-picker-item',
    // );

    // tagNameOptions.forEach((item: HTMLElement) => {
    //   if (item.dataset) {
    //     item.textContent = item.dataset.value || '';
    //   }
    // });
    const { disabledTags = [] } = this.props;
    const tagNameOptions = document.querySelectorAll(
      '.ql-placeholder .ql-picker-item',
    );

    tagNameOptions.forEach((item: HTMLElement) => {
      if (item.dataset) {
        item.textContent = item.dataset.value || '';
      }
    });

    // tagNameOptions.forEach((item: HTMLElement) => {
    //   if (item.dataset) {
    //     item.textContent = item.dataset.value || '';
    //     if (disabledTags?.includes(item.dataset.value)) {
    //       item.classList.add('disabled-tag');
    //       // Render CustomTooltip on the element
    //       ReactDOM.render(
    //         <CustomTooltip title="This tag is disabled">
    //           <span style={{ display: 'block' }}>{item.textContent}</span>
    //         </CustomTooltip>,
    //         item
    //       );
    //     }
    //   }
    // });

    const tagNameLabel = document.querySelector(
      '.ql-placeholder .ql-picker-label',
    );

    if (tagNameLabel) {
      tagNameLabel.innerHTML = '<> Tags';
    }

    // Add styles only if not already added
    if (
      this.props.templateHub &&
      !document.querySelector('#templateHubStyles')
    ) {
      const style = document.createElement('style');
      style.id = 'templateHubStyles';
      style.innerHTML = templateHubStyles;
      document.head.appendChild(style);
    }

    if (
      this.props.mailAutomation &&
      !document.querySelector('#emailAutomationStyles')
    ) {
      const style = document.createElement('style');
      style.id = 'emailAutomationStyles';
      style.innerHTML = emailAutomationStyles;
      document.head.appendChild(style);
    }

    const defaultOption = document.querySelector(
      '.ql-placeholder .ql-picker-item[data-value=""]',
    );
    if (defaultOption) {
      defaultOption.classList.add('ql-placeholder-default');
    }

    const editor = this.editorRef.current?.getEditor();
    if (editor) {
      // editor.root.addEventListener('paste', this.handlePaste, true);
    }
  }

  handlePaste = (event: ClipboardEvent) => {
    const quill = this.editorRef.current?.getEditor();
    if (!quill) return;

    event.preventDefault();

    const { clipboardData } = event;
    if (!clipboardData) return;

    const pastedText = clipboardData.getData('text/plain');
    const cursorPosition = quill.getSelection()?.index;

    if (cursorPosition !== null && cursorPosition !== undefined) {
      quill.insertText(cursorPosition, pastedText);
      quill.setSelection(cursorPosition + pastedText.length, 0);
    }
  };

  handleAutomateIntemationPoppup = (disabledtag?: any) => {
    this.props.setTagIntemation(true);
    this.props.setDisableTag(disabledtag);
  };

  handleTemplateIntemationPoppup = (disabledtag?: any) => {
    this.props.setTempTagIntemation(true);
    this.props.setTempDisableTag(disabledtag);
  };

  handlePlaceholder = (val: string) => {
    const { tags } = this.props;
    if (!tags) return;

    const selectedOption = tags.find(
      (option) => Object.keys(option)[0] === val,
    );
    if (!selectedOption) return;
    if (this.props.disabledTags?.includes(val)) {
      if (this.props.mailAutomation) {
        this.handleAutomateIntemationPoppup(val);
      } 
      if (this.props.templateHub) {
        this.handleTemplateIntemationPoppup(val);
      }
    } 
    if (!this.props.disabledTags?.includes(val)) {
      const templateString = selectedOption[val];
      const quill = this.editorRef.current?.getEditor();
      if (!quill) return;

      const cursorPosition = quill.getSelection()?.index;
      quill.insertText(
        cursorPosition,
        templateString,
        'customTag',
        templateString,
      );
      const NewTag = ' ';
      quill.insertEmbed(
        cursorPosition + templateString.length,
        'newTag',
        NewTag,
      );
      quill.setSelection(cursorPosition + templateString.length + 1, 0);
      // quill.format('customTag', false);
      quill.format('newTag', false);
    }

  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    const quill = this.editorRef.current?.getEditor();
    if (!quill) return;
    if (event.key === 'Enter' && quill) {
      // Prevent default behavior of the Enter key to avoid issues
      // quill.format('customTag', false);
      // quill.format('newTag', false);
      event.preventDefault();
      const cursorPosition = quill.getSelection()?.index;
      if (cursorPosition !== null && cursorPosition !== undefined) {
        quill.insertText(cursorPosition, '\n');
        quill.setSelection(cursorPosition + 1, 0);
      }
    }
    if (event.key === 'Backspace') {
      const selection = quill.getSelection();
      if (!selection || selection.length !== 0) return;

      const [line, offset] = quill.getLine(selection.index);
      const textBeforeCursor = line.domNode.textContent.slice(0, offset);

      // Check for custom tag pattern (e.g., {{customTag}})
      const tagPattern = /\{\{[^{}]+\}\}$/;
      const match = textBeforeCursor.match(tagPattern);

      if (match) {
        event.preventDefault();
        const matchStartIndex = selection.index - match[0].length;
        quill.deleteText(matchStartIndex, match[0].length, 'user');
      }
    }
  };

  render() {
    const {
      handleChange,
      value,
      className,
      tagsMenu,
      height,
      tags,
      templateHub,
      mailAutomation,
      mailbox,
      disabledTags, // Default to an empty array if not provided
    } = this.props;

    const placeholderOptions = tags
      ? tags.filter((option) => !disabledTags.includes(Object.keys(option)[0]))
      : [];
    const modules = {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' },
          ],
          [
            {
              placeholder: [
                '',
                ...tags.map((option) => Object.keys(option)[0]),
              ],
            },
          ],
        ],
        handlers: {
          placeholder: this.handlePlaceholder,
        },
      },
    };

    return (
      <div className={className}>
        <ReactQuill
          ref={this.editorRef}
          onChange={handleChange}
          value={value}
          modules={modules}
          placeholder={tagsMenu}
          className={styles.reactQuillEditChange}
          onKeyDown={this.handleKeyDown}
          formats={[
            'bold',
            'italic',
            'underline',
            'list',
            'align',
            'placeholder',
            'customTag',
            'newTag',
            'div',
          ]}
        />
        <style>
          {`.ql-editor {
            height:${height};
          }
          .ql-editor .custom-tag {
            background-color: #EEE8EC;
            color: #333333 !important;
            padding: 3px;
            border-radius: 5px;
            margin-right: 5px;
            pointer-events: none;
            font-weight: normal !important;
          }
          .ql-placeholder .ql-picker-item.ql-placeholder-default {
            display: none;
          }
          // .ql-placeholder .ql-picker-item.disabled-tag {
          //   color: #999999;
          //   // pointer-events: none;
          }
          // .ql-placeholder .ql-picker-item.disabled-tag:hover {
          //   content: ' (disabled)';
          //   font-style: italic;
          // }
          `}
        </style>
      </div>
    );
  }
}

export default EmailTemplateEditor;
