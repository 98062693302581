import { Key, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { AppDispatch, RootState } from '../../store';
import {
  InputText,
  Button,
  Flex,
  SelectTag,
  Text,
  Toast,
  Loader,
} from '../../uikit';
import { SvgCalendar, SvgCalendar1, SvgEdit } from '../../icons';
import RichText from '../common/RichText';
import ExpandTile from '../../uikit/ExpandTile';
import { isEmpty, toCamelCase } from '../../uikit/helper';
import { CrossButton } from '../../uikit/v2';
import { timezonesdata } from '../meetingscheduler/events/eventType';
import {
  getUpdateEventByIdMiddleWare,
  scheduleEventMiddleware,
  updateEventMiddleware,
} from './store/middleware/calendarmiddleware';
import styles from './styles/MeetingSummary.module.css';
import {
  EditEventDetails,
  IEventNotes,
  meetingFormProps,
  UserType,
  TeamMemberType,
} from './types';
import { formatTo12HrClock } from './util';
import EmailTemplate from './EmailTemplate';
// import { Toast } from 'react-bootstrap';

interface Props {
  meetingForm: meetingFormProps;
  applicants: any[];
  currentUserLabel: string;
  editEventDetails?: EditEventDetails | null;
  username: string;
  EventId?: any;
  eventId?: any;
  recurringEventId?: string | null;
  teamMembers: TeamMemberType[];
  // extraNotes: string;
  currentApplicantId: number;
  setIsTopLineLoading: React.Dispatch<React.SetStateAction<boolean>>;
  nextEvent: () => void;
  handleCloseSchedulingForm: () => void;
  setOpenScheduleForm: any;
  setopenmodel: any;
  formik: any;
  question: any;
  addquestion: any;
  handleCloseSchedulingForm1: any;
}

const MeetingSummary = ({
  handleCloseSchedulingForm,
  handleCloseSchedulingForm1,
  applicants,
  meetingForm,
  currentUserLabel,
  editEventDetails,
  username,
  nextEvent,
  EventId,
  eventId,
  recurringEventId,
  teamMembers,
  // extraNotes,
  currentApplicantId,
  setIsTopLineLoading,
  setOpenScheduleForm,
  setopenmodel,
  formik,
  question,
  addquestion,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const cookies = new Cookies();
  const param = new URLSearchParams(window.location.search);
  const [isloading, setIsloading] = useState(false);
  const [tileState, setTileState] = useState<{
    applicant: boolean;
    interviewer: boolean;
  }>({
    applicant: true,
    interviewer: false,
  });
  const [greetings, setGreetings] = useState<{
    applicant: string;
    interviewer: string;
  }>({
    applicant: `Hello ${toCamelCase(meetingForm?.applicant?.name) || ''}, 
    We'd like to confirm your interview. Please find all the relevant details below.`,
    interviewer: `Hello Team,
    We'd like to confirm your interview. Please find all the relevant details below.`,
  });
  const { userProfile, email_data } = useSelector(({ userProfileReducers, applicantIntegratemailReducers }: RootState) => {
    return {
      userProfile: userProfileReducers?.user,
      email_data:
        applicantIntegratemailReducers.email !== undefined
          ? applicantIntegratemailReducers.email[0]?.email
          : '',
    };
  });

  useEffect(() => {
    if (recurringEventId) {
      dispatch(
        getUpdateEventByIdMiddleWare({ event_id: recurringEventId }),
      ).then((res) => {
        if (res.payload) {
          const obj = res.payload as IEventNotes;
          const applicant = obj.extra_notes || undefined;
          const interviewer = obj.interview_notes || undefined;
          setGreetings((prev) => ({
            applicant: applicant || prev.applicant,
            interviewer: interviewer || prev.interviewer,
          }));
        }
      });
    }
  }, []);

  const getMeetingTitle = () => {
    return `${meetingForm.eventType.value
      } with ${meetingForm.applicant.name} on ${meetingForm.startDateTime.toDateString()} from ${formatTo12HrClock(
        meetingForm.startDateTime,
      )} to ${formatTo12HrClock(meetingForm.endDateTime)}`;
  };

  const getReminder = () => { 
    if (meetingForm.reminder.format) {
      if (meetingForm.reminder.format === 'hours') {
        return meetingForm.reminder.value * 60;
      }
      return meetingForm.reminder.value;
    }
    if(!meetingForm.reminder.format && meetingForm.reminder.value){
      return meetingForm.reminder.value;
    }
    else { 
      const val = Number(meetingForm.reminder)
      return  val
    }
  };
  const handleUpdateEvent = () => {
    if (editEventDetails) {
      let edit_jd = editEventDetails.jobRole.value;
      let app_id = editEventDetails.applicant.id;
      setIsloading(true);
      setIsTopLineLoading(true);
      const updateattendeeIds = meetingForm.attentee
        .filter((item) => item.role !== undefined && item.userId !== null)
        .map((item) => item.userId);
      const filteredInterviewers = meetingForm.interviewer
        ?.filter((member) => updateattendeeIds.includes(member.userId))
        .map((member) => ({
          email: member.email,
          calendarEmail: member.calendarEmail,
        }));
      const timezonelabel = editEventDetails ? timezonesdata?.find(e => e?.timezone === meetingForm?.timeZone?.value)?.timezone :
        timezonesdata.find(t => t.label === meetingForm.timeZone.value).timezone
      dispatch(
        updateEventMiddleware({
          title: getMeetingTitle(),
          reminder: getReminder(),
          app_id: String(app_id),
          extraNotes: greetings.applicant,
          interviewer_notes: greetings.interviewer,
          myJd: meetingForm.job.label,
          eventId: recurringEventId
            ? recurringEventId
            : param.get('eventhandelerid'),
          privateNotes: meetingForm.privateNotes,
          eventType: meetingForm.eventType.value,
          edit_jd,
          curJd: meetingForm.job.value,
          timeZone: timezonelabel,
          interviewer: filteredInterviewers,
          startTime: meetingForm.startDateTime,
          endTime: meetingForm.endDateTime,
          notes: meetingForm.notes,
          location: meetingForm.location.value,
          questions: JSON.stringify(question),
          new_questions: addquestion,
          summary: formik.values.brieftext,
          attendees: meetingForm.attentee.map((e) => ({
            id: e.userId,
            role: e.role,
          })),
        }),
      )
        .then((res) => {
          localStorage.setItem('Applicantsname', '');
          handleCloseSchedulingForm1();
          Toast(`Event updated successfully.`, 'LONG', 'success');
        })
        .catch((err) => {
          Toast('Failed to update event. Please try again.');
        });
    }
  };
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const history = useHistory();
  const handleScheduleEvent = () => {
    const {
      startDateTime,
      endDateTime,
      eventType,
      location,
      job,
      timeZone,
      interviewer,
      notes,
    } = meetingForm;
    setIsloading(true);
    setIsTopLineLoading(true);
    cookies.remove('meetingForm', { path: '/' });
    cookies.remove('formik.values', { path: '/' });
    cookies.remove('sample', { path: '/' });
    localStorage.setItem('return', 'false');
    const timezonelabel = timezonesdata.find(t => t.label === timeZone.value)
    dispatch(
      scheduleEventMiddleware({
        title: getMeetingTitle(),
        applicantId: meetingForm.applicant.id,
        myJd: job.label,
        reminder: getReminder(),
        extraNotes: greetings.applicant,
        interviewer_notes: greetings.interviewer,
        eventType: eventType.value,
        curJd: localStorage.getItem('jdid') !== '' &&
          localStorage.getItem('jdid') !== null
          ? localStorage.getItem('jdid').toString() : job.value,
        timeZone: timezonelabel.timezone,
        interviewer:
          interviewer.length === 0
            ? formik.values.interviewers
              .map((member) => ({
                email: member.email,
                calendarEmail: member.calendarEmail,
              }))
              .filter(
                (member) =>
                  member.email !== undefined &&
                  member.calendarEmail !== undefined,
              )
            : interviewer.map((member) => ({
              email: member.email,
              calendarEmail: member.calendarEmail,
            })),
        startTime: startDateTime,
        endTime: endDateTime,
        location: location.value,
        notes: notes,
        privateNotes: meetingForm.privateNotes,
        questions: JSON.stringify(question),
        new_questions: addquestion,
        summary: formik.values.brieftext,
        attendees: formik.values.interviewers.map((e) => ({
          id: e.userId,
          role: e.role,
        })),
      }),
    )
      .then((res) => {
        // Removing the url params using this function.
        const removeQueryParam = (name) => {
          if (query.has(name)) {
            query.delete(name);
            history.replace({ search: query.toString() });
          }
        };
        removeQueryParam('jobId');
        removeQueryParam('name');
        removeQueryParam('jobTitle');
        removeQueryParam('emailid');
        removeQueryParam('can_id');
        removeQueryParam('id');

        handleCloseSchedulingForm1();
        localStorage.setItem('Applicantsname', '');
        Toast(`Event scheduled successfully.`, 'LONG', 'success');
      })
      .catch((err) => {
        Toast(`Event scheduling failed. Please try again.`, 'LONG', 'success');
      });
  };
  const applicantEmail =
    applicants.filter(
      (applicant) => applicant.value === Number(meetingForm.applicant.id),
    )[0]?.email || null;

  const interviewerEmail =
    meetingForm?.interviewer.map((doc) => doc.email) || null;
  if (meetingForm) {
    if (typeof meetingForm.startDateTime === 'string') {
      meetingForm.startDateTime = new Date(meetingForm.startDateTime);
    }
    if (typeof meetingForm.endDateTime === 'string') {
      meetingForm.endDateTime = new Date(meetingForm.endDateTime);
    }
  }

  const MeetingTitleView = (
    <p>
      {meetingForm.eventType.value} with{' '}
      <b>
        {currentUserLabel !== '' &&
          currentUserLabel !== null
          ? toCamelCase(currentUserLabel) : toCamelCase(localStorage.getItem('Applicantsname'))
        }
      </b> on{' '}
      <b>
        {meetingForm?.startDateTime?.toDateString
          ? meetingForm.startDateTime.toDateString()
          : 'Invalid Date'}
      </b>{' '}
      from{' '}
      <b>
        {meetingForm?.startDateTime
          ? formatTo12HrClock(meetingForm.startDateTime)
          : 'Invalid Time'}
      </b>{' '}
      to{' '}
      <b>
        {meetingForm?.endDateTime
          ? formatTo12HrClock(meetingForm.endDateTime)
          : 'Invalid Time'}
      </b>{' '}({meetingForm?.timeZone?.value})
    </p>
  );

  const updateattendeeIds = meetingForm.attentee
    .filter((item) => item.role !== undefined && item.userId !== null)
    .map((item) => item.userId);

  const filteredInterviewers = meetingForm.interviewer
    ?.filter((member) => updateattendeeIds.includes(member.userId))
    .map((member) => ({
      email: member.email,
      calendarEmail: member.calendarEmail,
    }));

  const formikdatas = formik.values?.interviewers
    ?.filter((e) => e.email !== (null || undefined))
    .map((member) => ({
      email: member.email,
      calendarEmail: member.calendarEmail,
    }));
  // const userProfileEmail = userProfile.email;
  const userProfileData = [{
    email: email_data,
    calendarEmail: null,
  }];
  const combinedData = userProfileData.concat(formikdatas);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <div style={{ padding: '25px' }}>
          <Flex
            row
            center
            style={{
              position: 'relative',
              borderBottom: '0.5px solid #581845',
            }}
          >
            <Text
              size={14}
              bold
              className={styles.formTitle}
              style={{ marginBottom: '5px' }}
            >
              Meeting Notification Summary
            </Text>
          </Flex>
          <Flex style={{ overflowY: 'scroll', maxHeight: '600px' }}>
            <Flex
              className={styles.meetingSummary}
              column
              style={{
                paddingBottom: 10,
                marginRight: '10px',
              }}
            >
              <div className={styles.summary}>
                <p
                  className={styles.header}
                  style={{ marginTop: '5px', fontWeight: 'bold' }}
                >
                  Summary
                </p>
                <div className={styles.content}>{MeetingTitleView}</div>
              </div>
              <ExpandTile
                backgroundColor="#58184530"
                activeColor="#333333"
                title={'Email notification to applicant'}
                show={tileState?.applicant}
                onClick={() =>
                  setTileState({
                    ...tileState,
                    applicant: !tileState.applicant,
                  })
                }
              >
                <EmailTemplate
                  {...meetingForm}
                  currentUserLabel={currentUserLabel}
                  meetingform={meetingForm}
                  greetingText={greetings.applicant}
                  email={
                    applicantEmail
                      ? applicantEmail
                      : localStorage.getItem('emailnote')
                  }
                  interviewerData={meetingForm}
                  onSave={(value) => {
                    setGreetings((prev) => ({ ...prev, applicant: value }));
                  }}
                  editGreeting={true}
                />
              </ExpandTile>
              {meetingForm.interviewer.length !== (editEventDetails ? 1 : 0) && (
                <ExpandTile
                  backgroundColor="#58184530"
                  activeColor="#000000"
                  title={'Email notification to interviewer'}
                  show={tileState?.interviewer}
                  onClick={() =>
                    setTileState({
                      ...tileState,
                      interviewer: !tileState.interviewer,
                    })
                  }
                >

                  <EmailTemplate
                    {...meetingForm}
                    currentUserLabel={currentUserLabel}
                    greetingText={greetings.interviewer}
                    email={filteredInterviewers &&
                      filteredInterviewers?.length === 0
                      ? combinedData
                      : filteredInterviewers
                    }
                    notes={meetingForm.privateNotes}
                    applicantInfo={meetingForm.applicant}
                    onSave={(value) => {
                      /// save this text to some field
                      setGreetings((prev) => ({ ...prev, interviewer: value }));
                    }}
                    editGreeting={true}
                  />
                </ExpandTile>
              )}
            </Flex>
          </Flex>
          <Flex style={{ borderTop: '0.5px solid #c3c3c3' }}>
            <Flex row between marginTop={10}>
              <Button
                onClick={nextEvent}
                types={'secondary'}
                style={{ marginTop: '5px' }}
              >
                Back
              </Button>
              <Flex row>
                {isloading ? (
                  ''
                ) : (
                  <Button
                    types="close"
                    className={styles.continueButton}
                    onClick={handleCloseSchedulingForm}
                    style={{ marginRight: '8px' }}
                  >
                    Cancel
                  </Button>)}
                {editEventDetails ? (
                  isloading ? (
                    <Flex
                      middle
                      center
                      style={{ width: '70px' }}
                      marginTop={10}
                    >
                      <Loader size="small" withOutOverlay />
                    </Flex>
                  ) : (
                    <Button
                      onClick={handleUpdateEvent}
                      className={styles.continueButton}
                    >
                      Update Invite
                    </Button>
                  )
                ) : isloading ? (
                  <Flex middle center style={{ width: '70px' }} marginTop={5}>
                    <Loader size="small" withOutOverlay />
                  </Flex>
                ) : (
                  <Button
                    onClick={handleScheduleEvent}
                    className={styles.continueButton}
                  >
                    Send Invite
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        </div>
      </div>
    </>
  );
};

export default MeetingSummary;
