import * as React from 'react';
const SvgInterviewer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    viewBox='0 0 18 16'
    {...props}
  >
    <path
      fill="#581845"
      d="M3.432.043C2.286.38 1.356 1.8 1.075 3.636a9.233 9.233 0 0 0 0 2.179c.125.796.371 1.554.692 2.102.086.146.14.28.12.3a.762.762 0 0 1-.218.07c-.102.025-.325.159-.496.299-.387.318-.716.834-.911 1.446C.023 10.765 0 11.045 0 13.166c0 1.637.008 1.905.063 2.032.144.332.058.325 3.834.325 2.345 0 3.56-.025 3.658-.07a.454.454 0 0 0 .227-.267c.074-.198.078-.293.062-2.179-.012-1.822-.02-2.007-.098-2.395-.222-1.109-.836-2.039-1.512-2.294a4.853 4.853 0 0 1-.246-.095c-.032-.013.035-.185.172-.446.265-.51.543-1.415.621-2.032.223-1.733-.18-3.6-1.016-4.702C5.49.68 5.003.279 4.67.132 4.393.017 3.693-.04 3.43.042Zm.957 1.306c.692.236 1.306 1.121 1.556 2.23.07.305.09.528.09 1.114.003.892-.079 1.357-.364 2.02-.516 1.191-1.41 1.726-2.286 1.357-.954-.395-1.591-1.74-1.591-3.345 0-.98.2-1.752.63-2.452.515-.841 1.23-1.179 1.965-.924ZM6.3 9.713c.301.243.508.587.653 1.09.102.356.102.37.102 1.885v1.53l-3.139.019-3.142.012.015-1.573c.012-1.466.02-1.6.098-1.88.164-.586.555-1.114.926-1.267.067-.026 1.044-.045 2.174-.039l2.051.013.262.21ZM9.758.053c-.395.153-.805.701-.997 1.332l-.105.35-.012 5.237c-.008 5.186-.008 5.236.074 5.587.153.675.465 1.185.883 1.44.172.101.348.146.759.172.606.044.547-.013.606.618.035.42.183.688.375.688.094 0 .453-.223 1.055-.644l.91-.643h1.349c.743 0 1.47-.032 1.618-.064.66-.16 1.165-.968 1.274-2.045.051-.516.051-9.416 0-9.99-.074-.834-.379-1.509-.852-1.872l-.262-.204L13.17.002c-1.822-.006-3.33.02-3.412.051Zm6.566 1.274c.137.083.309.344.395.6.058.171.066.655.078 4.936.008 3.428 0 4.842-.032 5.084-.062.465-.265.803-.543.899-.129.044-.738.07-1.641.07h-1.442l-.716.503c-.394.28-.727.497-.742.484a1.952 1.952 0 0 1-.051-.344c-.016-.21-.059-.363-.13-.478-.097-.165-.105-.165-.695-.165-.91 0-1.145-.115-1.31-.638-.073-.242-.077-.395-.077-5.223 0-5.358-.008-5.142.203-5.46.23-.35.063-.338 3.459-.338 2.118 0 3.17.02 3.244.07Z"
    />
    <path
      fill="#581845"
      d="M12.849 2.914c-.414.115-.844.637-1.036 1.255-.105.325-.183.968-.148 1.191.074.49.434.656.653.3a.736.736 0 0 0 .113-.402c0-.274.144-.739.277-.898.329-.389.813-.178.99.427.081.28.077.58-.013.905-.093.33-.254.522-.516.598-.246.077-.379.204-.426.42-.023.09-.039.504-.039.925 0 1.025.079 1.267.407 1.267.086 0 .176-.057.246-.153.102-.14.11-.197.121-.688l.016-.535.246-.21c.301-.248.536-.65.676-1.14.086-.306.106-.453.106-.937 0-.522-.012-.611-.13-1-.288-.962-.91-1.497-1.543-1.325ZM12.976 9.991c-.238.153-.328.523-.219.873.078.242.172.331.356.331.195 0 .371-.28.371-.598 0-.415-.281-.746-.508-.606Z"
    />
  </svg>
);
export default SvgInterviewer;