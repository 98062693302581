const SvgInbox = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    viewBox='0 0 12 12'
    {...props}
  >
    <path
      fill={props.fill}
      d="M1.333 12c-.366 0-.68-.13-.942-.392A1.281 1.281 0 0 1 0 10.667V1.333C0 
      .967.13.653.392.391.653.13.967 0 1.333 0h9.334c.366 0 
      .68.13.942.392.261.261.391.575.391.941v9.334c0 .366-.13.68-.392.942a1.282 
      1.282 0 0 1-.941.391H1.333Zm0-1.333h9.334v-2h-2c-.334.422-.731.75-1.192.983-.462.233-.953.35-1.475.35a3.22 3.22 
      0 0 1-1.475-.35 3.555 3.555 0 0 1-1.192-.983h-2v2Zm4.667-2c.356 0 
      .683-.092.983-.276.3-.183.545-.425.734-.724.066-.1.15-.181.25-.242a.62.62 
      0 0 1 .333-.092h2.367v-6H1.333v6H3.7c.122 0 .233.031.333.092.1.062.184.142.25.242.19.3.434.541.734.725.3.184.627.275.983.275Z"
    />
  </svg>
);
export default SvgInbox;
