import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../../uikit/ErrorMessage/ErrorMessage';
import { AppDispatch, RootState } from '../../store';
import { isEmpty } from '../../uikit/helper';
import Flex from '../../uikit/Flex/Flex';
import { Toast } from '../../uikit';
import Text from '../../uikit/Text/Text';
import SelectTag from '../../uikit/SelectTag/SelectTag';
import LabelWrapper from '../../uikit/Label/LabelWrapper';
import MenuLists from '../common/MenuList';
import styles from './nondsskill.module.css';
import { SkillListEntity, SkillsEntity } from './createJdTypes';
import { dsFormProps } from './formikTypes';

type ParamsType = {
  jdId: string;
  editJdId: string;
};
type Props = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  skill_list: SkillListEntity[];
  values: dsFormProps;
  skills: SkillsEntity[];
  jdParseSkill: {
    value: any;
    label: any;
  }[];
  job_description: string;
  onDirty: () => void;
  errors?: any;
  touched?: any;
  setApiCalled: any;
  apiCalled: any;
  nice_to_have: any;
  onPristine: () => void;
  setimportapply?:(arg:boolean)=>void
};

const NonDsSkill = ({
  setFieldValue,
  skill_list,
  values,
  skills,
  jdParseSkill,
  job_description,
  onDirty,
  errors,
  touched,
  setApiCalled,
  apiCalled,
  nice_to_have,
  onPristine,
  setimportapply
}: Props) => {
  const { jdId, editJdId } = useParams<ParamsType>();
  // free fill initial skill
  useEffect(() => {
    if (apiCalled && jdParseSkill.length !== 0 && job_description !== '') {
      const jdDuplicate =
        jdParseSkill &&
        jdParseSkill.filter(
          (value, index: any, self: any[]) =>
            index ===
            self.findIndex(
              (t) =>
                t.value?.toLocaleLowerCase() ===
                value.value?.toLocaleLowerCase(),
            ),
        );
      setFieldValue('nonDsSkill', jdDuplicate);
      setApiCalled(false);
    }
  }, [jdParseSkill, job_description]);

  const skillUpdate =
    skills.length !== 0 &&
    skills.map((skillList) => {
      return { value: skillList.skill, label: skillList.skill };
    });

  const jdParseSkillEmpty =
    skillUpdate && skillUpdate.filter((x) => x.value !== '');

  const splitSkills = nice_to_have !== '' ? nice_to_have?.split(',') : [];
  const uniqueSkills = Array.from(new Set(splitSkills));
  const formattedSkill =
    (editJdId||jdId) &&
    uniqueSkills.map((skill) => ({
      label: skill,
      value: skill,
    }));

  useEffect(() => {
    setFieldValue('nonDsSkill', jdParseSkillEmpty);
    if (nice_to_have !== '') {
      setFieldValue('nicetohave', formattedSkill);
    }
  }, [skills]);

  useEffect(() => {
    if (jdId === undefined && editJdId === undefined) {
      setFieldValue('nonDsSkill', '');
      onPristine();
    }
  }, []);

  // skill change function
  const handleChange = useCallback(
    
    (newValue, data) => {
      setimportapply(false)
      if (data.action === 'select-option' || data.action === 'create-option') {
        if (values.nicetohave.length !== 0) {
          var check = values.nicetohave.some(
            (obj) => obj.value === data.option.value,
          );
          if (check === false) {
            if (data.action === 'select-option') {
              onDirty();
              setFieldValue('nonDsSkill', newValue);
            }
            if (data.action === 'create-option') {
              onDirty();
              const tagsValue = newValue.slice();
              var tagArr = data.option.value.split(',');
              tagArr.map((list: string) => {
                return (
                  !list.includes(',') &&
                  tagsValue.push({ value: list, label: list })
                );
              });
              const nonSkillFilter = tagsValue.filter(
                (x: any) => !x.value.includes(','),
              );
              const nonDsDuplicate =
                nonSkillFilter &&
                nonSkillFilter.filter(
                  (value: { value: string }, index: any, self: any[]) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        t.value?.toLocaleLowerCase() ===
                        value.value?.toLocaleLowerCase(),
                    ),
                );
              const jdParseSkillEmptyCheck = nonDsDuplicate.filter(
                (x: any) => x.value !== undefined,
              );
              setFieldValue('nonDsSkill', jdParseSkillEmptyCheck);
            }
          } else {
            Toast('This field already exists.', 'LONG', 'error');
          }
        } else {
          if (data.action === 'select-option') {
            onDirty();
            setFieldValue('nonDsSkill', newValue);
          }
          if (data.action === 'create-option') {
            onDirty();
            const tagsValue = newValue.slice();
            var tagArr1 = data.option.value.split(',');
            tagArr1.map((list: string) => {
              return (
                !list.includes(',') &&
                tagsValue.push({ value: list, label: list })
              );
            });
            const nonSkillFilter = tagsValue.filter(
              (x: any) => !x.value.includes(','),
            );
            const nonDsDuplicate =
              nonSkillFilter &&
              nonSkillFilter.filter(
                (value: { value: string }, index: any, self: any[]) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      t.value?.toLocaleLowerCase() ===
                      value.value?.toLocaleLowerCase(),
                  ),
              );
            const jdParseSkillEmptyCheck = nonDsDuplicate.filter(
              (x: any) => x.value !== undefined,
            );
            setFieldValue('nonDsSkill', jdParseSkillEmptyCheck);
          }
        }
      }

      if (data.action === 'remove-value') {
        onDirty();
        setFieldValue('nonDsSkill', newValue);
      }
    },
    [onDirty, setFieldValue, values.nicetohave],
  );

  // const {
  //   jdTemplates,
  // } = useSelector(
  //   ({

  //     jdTemplatesReducers,

  //   }: RootState) => {
  //     return {

  //       temTitle: jdTemplatesReducers.job_title,
  //       jdTemplates: jdTemplatesReducers.jd_templates,
  //       jdskills: jdTemplatesReducers.jd_templates.sk
  //     };
  //   },
  // );

  const handleChangefunction = useCallback(
    (newValue, valdata) => {
      if (
        valdata.action === 'select-option' ||
        valdata.action === 'create-option'
      ) {
        if (values.nonDsSkill.length !== 0) {
          var check = values.nonDsSkill.some(
            (obj) => obj.value === valdata.option.value,
          );

          if (check === false) {
            if (valdata.action === 'select-option') {
              onDirty();
              setFieldValue('nicetohave', newValue);
            }

            if (valdata.action === 'create-option') {
              onDirty();
              const tagsValue = newValue.slice();
              var tagArr = valdata.option.value.split(',');
              tagArr.map((list) => {
                return (
                  !list.includes(',') &&
                  tagsValue.push({ value: list, label: list })
                );
              });
              const nonSkillFilter = tagsValue.filter(
                (innerData) => !innerData.value.includes(','),
              );
              const nonDsDuplicate =
                nonSkillFilter &&
                nonSkillFilter.filter(
                  (value, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        t.value?.toLocaleLowerCase() ===
                        value.value?.toLocaleLowerCase(),
                    ),
                );
              const jdParseSkillEmptyCheck = nonDsDuplicate.filter(
                (innerData) => innerData.value !== undefined,
              );
              setFieldValue('nicetohave', jdParseSkillEmptyCheck);
            }
          } else {
            Toast('This field already exists.', 'LONG', 'error');
          }
        } else {
          if (valdata.action === 'select-option') {
            onDirty();
            setFieldValue('nicetohave', newValue);
          }

          if (valdata.action === 'create-option') {
            onDirty();
            const tagsValue = newValue.slice();
            var tagArr1 = valdata.option.value.split(',');
            tagArr1.map((list) => {
              return (
                !list.includes(',') &&
                tagsValue.push({ value: list, label: list })
              );
            });
            const nonSkillFilter = tagsValue.filter(
              (innerData) => !innerData.value.includes(','),
            );
            const nonDsDuplicate =
              nonSkillFilter &&
              nonSkillFilter.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      t.value?.toLocaleLowerCase() ===
                      value.value?.toLocaleLowerCase(),
                  ),
              );
            const jdParseSkillEmptyCheck = nonDsDuplicate.filter(
              (innerData) => innerData.value !== undefined,
            );
            setFieldValue('nicetohave', jdParseSkillEmptyCheck);
          }
        }
      }

      if (valdata.action === 'remove-value') {
        onDirty();
        setFieldValue('nicetohave', newValue);
      }
    },
    [onDirty, setFieldValue, values.nonDsSkill],
  );
  return (
    <Flex row>
      <Flex flex={2} style={{ marginRight: '15px' }}>
        <div className={styles.overAll}>
          <LabelWrapper label="Mandatory Competencies" required>
            <SelectTag
              isClearable
              options={skill_list}
              isMulti
              skillwidth
              isSearchable
              isCreate
              value={values.nonDsSkill}
              onChange={handleChange}
              components={{
                MenuList: (props) => <MenuLists {...props} />,
              }}
              placeholder=" Eg: Add required tools, technologies, professional & soft skills here by typing and selecting from suggestions or type to create new, or paste directly."
            />
            <Flex className={styles.errorMessage}>
              <ErrorMessage
                name="skillerror"
                touched={touched}
                errors={errors}
              />
            </Flex>
          </LabelWrapper>
        </div>
      </Flex>
      <Flex flex={2} style={{ marginRight: '15px' }}>
        <LabelWrapper label="Nice to have Competencies">
          <SelectTag
            isClearable
            options={skill_list}
            isMulti
            skillwidth
            isSearchable
            isCreate
            value={values.nicetohave}
            onChange={handleChangefunction}
            components={{
              MenuList: (props) => <MenuLists {...props} />,
            }}
            placeholder="Eg: Add desirable tools, technologies, professional & soft skills here that enhance candidacy by typing and selecting from suggestions or type to create new, or paste directly."
          />
        </LabelWrapper>
      </Flex>
    </Flex>
  );
};

export default memo(NonDsSkill);
