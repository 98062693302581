import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Flex from '../../../uikit/Flex/Flex';
import Text from '../../../uikit/Text/Text';
import styles from '../templatesmodule/templateshub.module.css';
import Button from '../../../uikit/Button/Button';
import SvgJobPost from '../../../icons/SvgJobPost';
import SvgMessage from '../../../icons/SvgMessage';
import SvgRight from '../../../icons/SvgRight';
import JdtemplateModule from './jdtemplate/jdtemplateModule';
import Emailtemplatescreen from './emailtemplates/emailtemplateScreen';

type Props = {};

const TemplatesHub = () => {
  const history = useHistory();
  const [pipeline, setPipeline] = useState(
    parseInt(sessionStorage.getItem('pipeline')) || 0,
  );
  const [template, setTemplate] = useState(
    parseInt(sessionStorage.getItem('template')) || 0,
  );
  const [workflow, setworkflow] = useState(
    parseInt(sessionStorage.getItem('workflow')) || 0,
  );

  const selectjd = () => {
    setPipeline(2);
    setTemplate(1);
  };

  const selectmail = () => {
    setPipeline(3);
    setTemplate(1);
  };

  const backtemplate = () => {
    setPipeline(0);
    setTemplate(0);
    setworkflow(2);
  };

  const back_navigation = () => {
    sessionStorage.removeItem('template');
    sessionStorage.removeItem('pipeline');
    history.push('/account_setting');
  };

  const temp_hub_navigation = () => {
    setPipeline(0);
    setTemplate(0);
  };

  // setting the helmat title
  document.title = 'Templates Hub';

  return (
    <>
      <Flex row className={styles.ribbon} marginBottom={5} between>
        <Flex marginTop={9} marginLeft={8}>
          <Text size={16} bold color="theme">
            Templates Hub
          </Text>
        </Flex>
        <Flex>
          <div className={styles.triangle}></div>
        </Flex>
      </Flex>

      <Flex row>
        <Flex row marginTop={5} marginLeft={16}>
          <Flex
            style={{
              fontSize: '15px',
              color: '#581845',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={() => back_navigation()}
          >
            Account Settings
          </Flex>
          <Flex marginTop={7} marginLeft={5}>
            <SvgRight fill={'#581845'}></SvgRight>
          </Flex>
          {template === 0 ? (
            <Flex
              marginLeft={5}
              style={{ fontSize: '15px', fontWeight: 'bold' }}
            >
              Templates Hub
            </Flex>
          ) : (
            <>
              <Flex
                onClick={() => temp_hub_navigation()}
                marginLeft={5}
                style={{
                  fontSize: '15px',
                  color: '#581845',
                  fontWeight: 'bold',
                }}
              >
                Templates Hub
              </Flex>
              <Flex marginTop={7} marginLeft={5}>
                <SvgRight fill={'#581845'}></SvgRight>
              </Flex>
              {pipeline === 3 ? (
                <Flex
                  marginLeft={5}
                  style={{ fontSize: '15px', fontWeight: 'bold' }}
                >
                  Email Templates
                </Flex>
              ) : pipeline === 2 ? (
                <Flex
                  marginLeft={5}
                  style={{ fontSize: '15px', fontWeight: 'bold' }}
                >
                  Job Description Templates
                </Flex>
              ) : (
                ''
              )}
            </>
          )}
        </Flex>
      </Flex>

      {template === 0 ? (
        <Flex className={styles.overflowContainer} center middle>
          <Flex className={styles.titleBar}>
            <Flex row marginLeft={'16px'}>
              <Flex flex={3} height={'unset'} minWidth={200} marginRight={20}>
                <TemplateCard
                  icon={<SvgJobPost height={26} width={26} />}
                  paddings={'3'}
                  title={'Job Description Templates'}
                  subTitle={
                    'Effortlessly manage your job descriptions with streamlined features, including easy creation, editing, deletion, and posting capabilities for detailed job descriptions.'
                  }
                  btnName={'Configure Templates'}
                  onClick={() => selectjd()}
                />
              </Flex>
              <Flex flex={3} height={'unset'} minWidth={200} marginRight={20}>
                <TemplateCard
                  icon={<SvgMessage height={30} width={28} fill="#581845" />}
                  title={'Email Templates'}
                  paddings={'4'}
                  subTitle={
                    'Effortlessly generate, scrutinize, and dispatch your emails with seamless efficiency and precision.'
                  }
                  btnName={'Configure Templates'}
                  onClick={() => selectmail()}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        ''
      )}

      {pipeline === 2 ? <JdtemplateModule handleBack={backtemplate} /> : ''}
      {pipeline === 3 ? <Emailtemplatescreen handleBack={backtemplate} /> : ''}
    </>
  );
};

interface TemplateCardProps {
  title: string;
  subTitle: string;
  btnName: string;
  icon: React.ReactNode;
  paddings?: string;
  onClick: () => void;
}

const TemplateCard: React.FC<TemplateCardProps> = (props) => {
  const { title, subTitle, btnName, icon, onClick, paddings } = props;
  return (
    <Card className={styles.cardStructure}>
      <Flex column start className={styles.cardContent}>
        <Flex
          marginTop={40}
          width={348}
          style={{
            position: 'relative',
            border: '0.3px solid #C3C3C3',
            height: '0.5px',
          }}
        ></Flex>
        <Flex center start style={{ position: 'absolute' }} marginLeft={10}>
          <Flex
            middle
            center
            height={50}
            width={50}
            marginLeft={15}
            marginTop={20}
            style={{
              borderRadius: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 0px 6px 3px #eee8ec',
              padding: paddings === '2' ? '2px 0px 0px 4px' : '',
            }}
          >
            {icon}
          </Flex>
          <Flex
            row
            middle
            center
            className={styles.cardHeader}
            marginLeft={20}
            marginTop={10}
          >
            <Text color="theme" bold size={13}>
              {title}
            </Text>
          </Flex>
        </Flex>
        <Flex marginTop={45}>
          <Text
            size={13}
            style={{ marginTop: '10px', padding: '8px 30px 5px 30px' }}
          >
            {subTitle}{' '}
          </Text>
        </Flex>
      </Flex>
      <Flex
        row
        middle
        style={{
          display: 'flex',
          // flexDirection: 'column',
          width: '100%',
          marginTop: 10,
          paddingTop: 10,
          paddingBottom: 20,
          // borderTop: '1px solid #c3c3c3',
        }}
      >
        <Button types="primary" onClick={onClick}>
          <Text bold color="white">
            {btnName}
          </Text>
        </Button>
      </Flex>
    </Card>
  );
};

export default TemplatesHub;
