import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text';
import Button from '../../uikit/Button/Button';
import NavBar from '../navbar/empnavbar/NavBar';
import SvgZitaLogo from '../../icons/SvgZitaLogo';
import NavBarCandidate from '../navbar/candidatenavbar/NavBarCandidate';
import robort from '../../actions/PageNotFound.json'

const height = window.innerHeight - 85;

type Props = {
  update?: () => void;
};

const Pagenotfount = ({ update }: Props) => {
  const history = useHistory();

  const [componentHeight, setComponentHeight] = useState(height);

  const EmployeeLogin = localStorage.getItem('loginUserCheck') === 'true'
  const isPageLoggedIn = localStorage.getItem('token') !== null


  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 85);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: robort,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <Flex center>
        {/* {isPageLoggedIn ? (
        <>
          {EmployeeLogin ? (
            <Flex>
              <NavBar update={update}/>
              </Flex>
              ) : (
              <Flex>
                <NavBarCandidate/>
                </Flex>
            )}
          </> 
    ) : (
      <Flex>
      <Flex row center middle marginTop={5}>
        <SvgZitaLogo width={130} height={100} />
      </Flex>
  </Flex>  
    )} */}
        <Flex row center height={isPageLoggedIn ? componentHeight : 590} marginTop={EmployeeLogin ? 45 : ''}>
          <Flex width={'50%'}>
            <Lottie options={defaultOptions} height={650} width={650} isClickToPauseDisabled={true} />
          </Flex>

          <Flex width={'50%'}>
            <Flex center middle>
              <Text style={{ marginBottom: 10 }} align='center' size={30} color='theme' bold>
                Oops!
              </Text>
              <Text size={20} style={{ marginBottom: 10 }} align='center'>
                {'Looks like the page you\'re searching for took a day off.'}
              </Text>
              {isPageLoggedIn && (
                <Text size={20} style={{ marginBottom: 10 }} align='center'>
                  Navigate back to your dashboard or use the search to find what you need.
                </Text>
              )}
              <Flex center middle>
                {isPageLoggedIn ? (
                  <Button onClick={() => history.push('/')}>
                    Go Home
                  </Button>
                ) : (<Button onClick={() => history.push('/recruiter/1')}>
                  Go Home
                </Button>)}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Pagenotfount;
