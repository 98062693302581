import * as React from 'react';
const defaultProps = {
  fill: '#581845',
  width: 14,
  height: 14,
};
const SvgRelocate = ({ width, height, fill }: typeof defaultProps)=> (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 10 9"
  >
    <path
      fill="#581845"
      d="M8.501 7.002h-1.39a.424.424 0 0 1-.311-.126.423.423 
      0 0 1-.126-.311c0-.124.042-.228.126-.312a.424.424 0 0 1 .312-.126h1.39l-.395-.394a.381.381 
      0 0 1-.093-.142.44.44 0 0 1 .104-.47c.08-.08.183-.121.307-.121.123 0 .226.04.306.12l1.138 1.138a.42.42 
      0 0 1 0 .613L8.73 8.009a.427.427 0 0 1-.3.125.407.407 0 0 1-.313-.125.381.381 0 0 1-.093-.142.48.48 
      0 0 1-.027-.165c0-.058.009-.113.027-.164a.382.382 0 0 1 .093-.142l.383-.394Zm-5-1.313c.409 
      0 .777-.1 1.105-.3.328-.201.587-.466.777-.794a2.805 2.805 
      0 0 0-.864-.487c-.321-.113-.66-.17-1.018-.17-.357 0-.696.057-1.017.17-.321.113-.61.276-.865.487.19.328.449.593.777.793.328.2.697.301 1.105.301Zm0-2.188c.24 
      0 .447-.086.618-.257a.842.842 0 0 0 .257-.618.842.842 0 0 0-.257-.618.843.843 0 0 0-.618-.257.842.842 0 0 0-.618.257.842.842 0 0 0-.257.618c0 .24.086.446.257.618a.842.842 
      0 0 0 .618.257Zm0 4.092c.89-.817 1.55-1.56 1.98-2.227.43-.667.646-1.26.646-1.777 0-.795-.254-1.446-.76-1.954C4.86 1.13 4.236.875 3.5.875c-.737 0-1.358.254-1.865.76-.507.508-.76 1.159-.76 1.954 
0 .517.214 1.11.645 1.777.43.668 1.09 1.41 1.98 2.227Zm0 .996a.525.525 0 0 1-.328-.12c-1.065-.942-1.86-1.815-2.385-2.62C.263 5.041 0 4.288 
0 3.588 0 2.495.352 1.623 1.056.974 1.76.324 2.575 0 3.501 0c.926 0 1.742.325 2.446.974.703.649 1.055 1.52 1.055 2.615 0 .7-.262 1.453-.788 2.26-.525.805-1.32 1.678-2.385 2.62a.525.525 0 0 1-.328.12Z"
    />
  </svg>
);
export default SvgRelocate;