export const unavailble =[
    {id: '1',label:"Busy"},
    {id: '2',label:"Tentative"},
    {id: '3',label:"Away/ Out of Office"},
    {id: '4',label:"Working Elsewhere"},
  ]

export const calendar =[
  {id: '1',label:"calendar"},
]

export const calendarratio =[
  {id: '1',label:""},
  {id: '2',label:"Don't add any new events to the calendar"}
]

