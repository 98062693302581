import * as React from 'react';
const SvgHuman = (props) => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    className="Human_svg__svg-icon"
    style={{
      width: '50px',
      height: '50px',
      verticalAlign: 'middle',
      fill: '#581845',
      overflow: 'hidden',
    }}
    viewBox="0 0 1024 1024"
    {...props}
>
<path d="M512 597.995q108.01 0 225.003 46.997T853.995 768v85.995H169.984V768q0-76.01 116.992-123.008t225.003-46.997zM512 512q-69.995 0-120-50.005t-50.005-120T392 220.992t120-51.008 120 51.008 50.005 121.003-50.005 120T512 512" />
</svg>
);
export default SvgHuman;