/* eslint-disable */
import classNames from 'classnames/bind';
import { FormikProps } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SvgDownload from '../../icons/SvgDownload';
import SvgHeart from '../../icons/SvgHeart';
import { AppDispatch, RootState } from '../../store';
import Flex from '../../uikit/Flex/Flex';
import { isEmpty } from '../../uikit/helper';
import InputCheckBox from '../../uikit/InputCheckbox/InputCheckBox';
import SelectTag from '../../uikit/SelectTag/SelectTag';
import Text from '../../uikit/Text/Text';
import { sortOptions } from './mock';
import Totalcount from '../../globulization/TotalCount';
import styles from './mydatabasebulkaction.module.css';
import { MyDataFormProps } from './MyDataBaseScreen';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { myDataBaseDataMiddleWare } from './store/middleware/mydatabasemiddleware';
import SvgDoublestar from '../../icons/Svgdoublestar';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import AimatchModule from '../zitamatchcandidatemodule/AiMatchModule';
import { jdMatchMiddleWare } from '../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
import { useParams } from 'react-router-dom';
import Toast from '../../uikit/Toast/Toast';
import { ERROR_MESSAGE } from '../constValue';
import { Button, Loader, Modal } from '../../uikit';
import SvgParsing from '../../icons/SvgParsing';
import { zitaMatchProgressMiddleWare } from '../zitamatchcandidatemodule/store/middleware/zitamatchcandidatemiddleware';
import SvgRefresh from '../../icons/SvgRefresh';
import CustomTooltip from '../../uikit/Tooltip/tooltip';

const cx = classNames.bind(styles);

type Props = {
  totalCount: number;
  filterFormik: FormikProps<MyDataFormProps>;
  isFav: boolean;
  handleFav: () => void;
  handleSelectAll: () => void;
  isCheckAll: boolean;
  hanldeDownload: () => void;
  isCheck: string[];
  isSortOptions: {
    value: string;
    label: string;
  };
  setSortOptions: Dispatch<
    SetStateAction<{
      value: string;
      label: string;
    }>
  >;
  tabKey: string;
  addFavFilter: string;
  qaValue: string;
  skillsOptionsList: any;
  isPage: number;
  data?: any;
  cleartick:()=>void;
  iscandidate?:any
  isZitamatchfilter?:any;
  ispagecount?:any;
  isMatchai?:any;
};
const MyDataBaseBulkAction = ({
  totalCount,
  filterFormik,
  isFav,
  handleFav,
  isCheckAll,
  handleSelectAll,
  hanldeDownload,
  isCheck,
  isSortOptions,
  setSortOptions,
  tabKey,
  addFavFilter,
  skillsOptionsList,
  qaValue,
  isPage,
  data,
  cleartick,
  iscandidate,
  isZitamatchfilter,
  ispagecount,
  isMatchai
}: Props) => {
  const [isCheckbox, setCheckBox] = useState(false);
  const [isloading, setloading] = useState(false);
  const [compareAi, setcompareAi] = useState(0);
  const [processcandidate, setprocesscandidate] = useState(null);
  const [totalcount, settotalcount] = useState(0);
  const [aimodule, setaimodule] = useState(false);
  const checkValue = isCheck.length === 0;
  const [isChecks, setIsCheck] = useState<any>([]);
  const dispatch: AppDispatch = useDispatch();
  const [aimatchLoader, setaimatchLoader] = useState(false);
  const [isopensubcription, setopensubcription] = useState(false);
  const [isParse, setParse] = useState(false);
  const [updateloader,setupdateloader]=useState(false)
  const {
    current_plan,
    Permission,
    apply_match,
    blocked_resume,
    ai_credits,
    plan_details,
    current_resume_count,
    status,
  } = useSelector(
    ({
      myDataBaseDataReducers,
      candidatejdmatchReducers,
      weightageReducers,
      permissionReducers,
      SubscriptionReducers,
      zitaMatchprogressReducers,
    }: RootState) => {
      return {
        current_plan: SubscriptionReducers.current_plan,
        Permission: permissionReducers.data,
        apply_match: candidatejdmatchReducers.apply_match,
        blocked_resume: candidatejdmatchReducers.blocked_resume,
        candidates_ai: candidatejdmatchReducers.candidates_ai,
        plan_details: myDataBaseDataReducers.plan_details,
        ai_credits: myDataBaseDataReducers.ai_credits,
        current_resume_count: SubscriptionReducers.current_resume_count,
        status: zitaMatchprogressReducers.status,
      };
    },
  );

  // useEffect(() => {
  //   if(localStorage.getItem('refloader')==='true')
  //   {
    
  //     dispatch(zitaMatchProgressMiddleWare({
  //       jd_id:filterFormik?.values?.jobTitle
  //       })).then((res)=>{
  //         if(res.payload.status===false)
  //         {
           
  //           localStorage.setItem("databaseloader",'false')
  //         }
  //       })
        
  //       localStorage.setItem('refloader','false')
       
  //   }
  // });

  // useEffect(() => {
  //   dispatch(
  //     myDataBaseDataMiddleWare({
  //       applicant_only: isCheckbox ? '1' : '0',
  //       jobTitle: filterFormik.values.jobTitle,
  //       fav: addFavFilter,
  //       experience: filterFormik.values.experience.value,
  //       educationLevel: qaValue,
  //       typeofJob: filterFormik.values.jobType,
  //       location: filterFormik.values.locationSearch,
  //       skill_match: skillsOptionsList,
  //       relocate: filterFormik.values.reLocateValue,
  //       candidate: filterFormik.values.searchValue,
  //       userType: tabKey,
  //       sort: isSortOptions.value,
  //       page: isPage + 1,
  //     }),
  //   );
  // }, [isCheckbox]);

  useEffect(() => {
    // Check Formik's field value and update the checkbox state accordingly
    setCheckBox(filterFormik.values.applicantOnly === '1');
  }, [filterFormik.values.applicantOnly]); 

  const handleCheckboxChange = () => {
    const newValue = !isCheckbox ? '1' : '0'; // Toggle between '1' and '0'
    filterFormik.setFieldValue('applicantOnly', newValue); // Update Formik field value
    setCheckBox(!isCheckbox); // Toggle local checkbox state
  };
  const chklen = isCheck.length > 0;



  // Overall checked data
  const filteredCandidates = data.filter((candidate) =>
    isCheck.includes(candidate.id.toString()),
  );

  // Already  Ai matched data
  const countTrueCandidateAI = filteredCandidates.reduce((count, candidate) => {
    return count + (candidate.candidate_ai ? 1 : 0);
  }, 0);

  // Remaining data without Ai match
  const remainingcount = filteredCandidates.length - countTrueCandidateAI;

  //Not Ai matched data for dispatching
  const falseCandidateAIData = filteredCandidates
    .filter((candidate) => !candidate.candidate_ai)
    .map((candidate) => candidate.id.toString());

  // Module openning for notification while onclick Ai matching
  const module_update = (val) => {
    setaimodule(val);
  };
  const handleCloseParse = () => {
    setaimatchLoader(false);
    setParse(false);
    cleartick();
   setupdateloader(true)
    localStorage.setItem('databaseloader', 'true');
    localStorage.setItem('jdid', filterFormik.values.jobTitle);
    dispatch(
      zitaMatchProgressMiddleWare({
        jd_id:filterFormik?.values?.jobTitle
      }),
    )
  };
  // triggering the API for Ai matching
  const Aiapi_call = () => {
    // setloading(true);
    module_update(false);
    setaimatchLoader(true);
    setParse(true)
    dispatch(
      jdMatchMiddleWare({
        jd_id: filterFormik?.values?.jobTitle,
        candidates: falseCandidateAIData,
        location:'database'
      }),
    ).then((res) => {
      if (res.payload.suceess === true) {
        // module_update(false);
        // setaimatchLoader(false);
        // Toast('Ai match successfully');
        localStorage.setItem('databaseloader', 'false');
        localStorage.removeItem('jdid')
        dispatch(
          myDataBaseDataMiddleWare({
            applicant_only: isCheckbox ? '1' : '0',
            jobTitle: filterFormik.values.jobTitle,
            fav: addFavFilter,
            experience: filterFormik.values.experience.value,
            educationLevel: qaValue,
            typeofJob: filterFormik.values.jobType,
            location: filterFormik.values.locationSearch,
            skill_match: skillsOptionsList,
            relocate: filterFormik.values.reLocateValue,
            candidate: filterFormik.values.searchValue,
            userType: tabKey,
            sort: isSortOptions.value,
            page: isPage + 1,
            pagecount: ispagecount,
            matching:isMatchai,
            zitamatchfilter:isZitamatchfilter ? "1" : "0",
          }),
        ).then((response) => {
          if (response.payload.success === true) {
             setaimatchLoader(false);
             setParse(false);
            setupdateloader(false);
             cleartick();
            dispatch(
              zitaMatchProgressMiddleWare({
                jd_id:filterFormik?.values?.jobTitle
              }),
            )
             localStorage.setItem('databaseloader', 'false');
          } else {
            // setaimatchLoader(false);
            cleartick();
            setupdateloader(false);
            localStorage.setItem('databaseloader', 'false');
            localStorage.removeItem('jdid')
            setaimatchLoader(false);
             setParse(false);
             dispatch(
              zitaMatchProgressMiddleWare({
                jd_id:filterFormik?.values?.jobTitle
              }),
            )
          }
        });
      } else {
        setaimatchLoader(false);
        setloading(false);
        dispatch(
          zitaMatchProgressMiddleWare({
            jd_id:filterFormik?.values?.jobTitle
          }),
        )
        setParse(false);
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      }
    });
  };

  useEffect(() => {
    const jdidFromLocalStorage = localStorage.getItem('jdid');
    // if(localStorage.getItem('jdid')){
    //   const jdIdAsInt = parseInt(jdidFromLocalStorage, 10);
    // dispatch(zitaMatchProgressMiddleWare({
    //   jd_id:jdIdAsInt
    //   })).then((res)=>{
    //     if(res.payload.status===false){
    //     localStorage.setItem('databaseloader', 'false');
    //     localStorage.removeItem('jdid')
    //   }
    //   })}
    //   else
      {
       if(filterFormik.values.jobTitle!==undefined || filterFormik.values.jobTitle!==null){
           dispatch(zitaMatchProgressMiddleWare({
            jd_id:filterFormik?.values?.jobTitle
            })).then((res)=>{
              if(res.payload.status===false){
              localStorage.setItem('databaseloader', 'false');
        
            }
            })}
      }
    
  }, []);

  useEffect(() => {
    localStorage.setItem('databaseloader', JSON.stringify(status));
  }, [status]);

 

  // checking the count while onclick for dispatching the Ai matching
  const Ai_matching = () => {
    if (isCheck.length === 0) {
      setprocesscandidate(isCheck.length);
      module_update(true);
    } else {
      if (filteredCandidates.length !== remainingcount) {
        module_update(true);
        settotalcount(filteredCandidates.length);
        setcompareAi(countTrueCandidateAI);
        setprocesscandidate(remainingcount);
      } else {
        Aiapi_call();
      }
    }
  };

  // Loader upto updating the AI matching response
  if (isloading) {
    return <Loader />;
  }

  // condition for AI matching button
  const update_function = () => {
    if (
      Permission.includes(
        'Advanced AI matching - Resume matching to multiple jobs',
      ) === true
    ) {
      return (
        <Flex row className={styles.bulkButton}>
         
          {localStorage.getItem('databaseloader')==='true' ?(
                  <Text 
                  style={{
                    paddingLeft: '5px',
                    borderLeft: '1px solid #581845',
                    
                  }}
                  color='theme'
                  > In progress...</Text>):(
                    <>
              
       
          <Flex
            row
            center
            style={{
              paddingLeft: '5px',
              borderLeft: '1px solid #581845',
              cursor: 'pointer',
            }}
            onClick={Ai_matching}
            title="Advanced AI Matching"
          >
            <SvgDoublestar />
            <Text color="theme" style={{ marginLeft: '5px' }}>
              Advanced AI Matching
            </Text>
          </Flex></>)}
        </Flex>
      );
    } else {
      if (plan_details.includes(current_plan)) {
        if (ai_credits === true) {
          return (
            <Flex row className={styles.bulkButton}>
              {localStorage.getItem('databaseloader')==='true'?(
                  <Text
                  style={{
                    paddingLeft: '5px',
                    borderLeft: '1px solid #581845',
                  }}
                  color='theme'
                  >In progress...</Text>):(
                    <>
              <Flex
                row
                center
                style={{
                  paddingLeft: '5px',
                  borderLeft: '1px solid #581845',
                  cursor: 'pointer',
                }}
                onClick={Ai_matching}
                title="Advanced AI Matching"
              >
                
                <SvgDoublestar />
                <Text color="theme">Advanced AI Matching</Text>
               
              </Flex> </>)}
            </Flex>
          );
        } else {
          return (
            <Flex row className={styles.bulkButton}>
              <Flex
                row
                center
                style={{
                  paddingLeft: '5px',
                  borderLeft: '1px solid #581845',
                  cursor: 'pointer',
                }}
                onClick={() => setopensubcription(true)}
                title="Advanced AI Matching"
              >
                <SvgDoublestar />
                <Text color="theme" style={{ marginLeft: '2px' }}>
                  Advanced AI Matching
                </Text>
                <Flex style={{ padding: '1px 0px 1px 2px' }} marginBottom={3}>
                  <SvgSubcriptioncrown />
                </Flex>
              </Flex>
            </Flex>
          );
        }
      }
    }
  };

  return (
    <Flex row between={!isEmpty(filterFormik.values.jobTitle)} center className={styles.overAll}>
      {/* Total Count */}
      <Flex
        row
        center
      //  between={iscandidate === 'Applicants' && !isEmpty(filterFormik.values.jobTitle)}
       width={isEmpty(filterFormik.values.jobTitle) ? '33%' : iscandidate === 'Applicants' && !isEmpty(filterFormik.values.jobTitle) && '33%'}
        // className={styles.totalcandidatescheckbox}
      >
        {/* <div
          className={cx('svgDownload', { svgDownloadNone: checkValue })}
        > */}
        {/* <Flex row center between> */}

        <Flex row center>
          <Flex row center middle>
            <InputCheckBox
              checked={isCheckAll}
              onChange={handleSelectAll}
              id="mydatabasebulkaction__input"
            />
          </Flex>

          <Flex
            className={styles.totalcandidatescount}
            marginLeft={8}
            marginRight={8}
          >
            <Totalcount name="Total Candidates" numbers={totalCount} />
          </Flex>
        </Flex>

        <Flex
          row
          center
          // marginLeft={
          //   iscandidate !== 'Applicants' || isEmpty(filterFormik.values.jobTitle) ? 8 : ''
          // }
        >
          <Flex title="AI Resume Parsing Credits">
            <SvgParsing width={15} height={15} />
          </Flex>
          <Flex marginLeft={2}>
            <Text>: {current_resume_count}</Text>
          </Flex>
        </Flex>
        {/* <Flex center>
          {!isEmpty(filterFormik.values.jobTitle) && (
            <Flex row center>
              {iscandidate === 'Applicants' && (
                <InputCheckBox
                  label="Show only applied candidates"
                  onClick={handleCheckboxChange}
                  checked={isCheckbox}
                />
               )} 
            </Flex>
          )}
        </Flex> */}

        {/* </Flex> */}
        {/* </div> */}
      </Flex>
      {/* Download Resumes */}
      <Flex>
        {chklen && (
          <Flex
            row
            center
            className={styles.bulkSelection}
            // style={{
            //   right: !isEmpty(filterFormik.values.jobTitle) ? '-12%' : '32%',
            //   left: (tabKey === "3" && "-15%")
            // }}
          >
            <Flex marginRight={0}>
              <Text color="theme">{`Selected ${isCheck.length} Candidates`}</Text>
            </Flex>

            <Flex row className={styles.bulkButton}>
              <Flex
                row
                center
                style={{
                  paddingLeft: '5px',
                  borderLeft: '1px solid #581845',
                  cursor: 'pointer',
                }}
                onClick={hanldeDownload}
                title="Export Resumes"
              >
                <SvgDownload width={14} height={14} />
                <Text style={{ marginLeft: '10px' }} color="theme">
                  Export Resumes
                </Text>
              </Flex>
            </Flex>
            {!isEmpty(filterFormik.values.jobTitle) && update_function()}
          </Flex>
        )}
      </Flex>
      {/* Sort by & Favourite */}
      <Flex>
        <Flex className={styles.favandsort}>
          {!isEmpty(filterFormik.values.jobTitle) && (
            <Flex className={styles.sortbybar}>
              <Text className={styles.sortText}>Sort By:</Text>
              <div className={styles.selectTagStyle}>
                <SelectTag
                  stylechangess1
                  id="mydatabasebulkaction__sort"
                  value={isSortOptions}
                  options={sortOptions}
                  onChange={(options) => {
                    setSortOptions(options);
                  }}
                />
              </div>
              <Flex width={5}></Flex>
              <CustomTooltip title="Favourite Candidates"> 
              <div
                onClick={handleFav} 
                className={styles.pointer}
                // tabIndex={-1}
                role={'button'}
                onKeyPress={() => {}}
              >
                <div className={styles.svgHeart}>
                  <SvgHeart width={13} height={13} filled={isFav} />
                </div>
                <div className={styles.favourites}>Favourites</div>
              </div>
              </CustomTooltip>
            </Flex>
          )}
        </Flex>
      </Flex>
      {aimodule && (
        <AimatchModule
          module_update={module_update}
          aimodule={aimodule}
          totalcount={totalcount}
          compareAi={compareAi}
          Aiapi_call={Aiapi_call}
          processcandidate={processcandidate}
        />
      )}
      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          differece_key={['AI_matching']}
          addon_name={['Resume Matching For Multiple Jobs(AI)']}
        />
      )}
      <Modal open={isParse}>
      <Flex className={styles.overAllloader}>
        <Flex column>
          <Flex row center>
            {aimatchLoader && <Loader withOutOverlay size={'small'} />}

            <Text
              className={styles.flexConatiner}
              type="titleMedium"
            >
              Advanced AI Matching is running in background.....
            </Text>
          </Flex>
          <Flex className={styles.des}>
          <Text className={styles.des}>
            This might take a few minutes to process. Moving away from this page will not stop
            the process.
          </Text>
          </Flex>
          
        </Flex>

        <Flex row center middle marginTop={10}>
        
          <Button onClick={handleCloseParse} className={styles.btn}>
            OK
          </Button>
        </Flex>
      </Flex>
    </Modal>
    </Flex>
  );
};
export default MyDataBaseBulkAction;
