import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames/bind';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SvgTickOne from '../../../icons/SvgTickOne';
import SvgTick from '../../../icons/SvgTick';
import { AppDispatch, RootState } from '../../../store';
import Button from '../../../uikit/Button/Button';
import Card from '../../../uikit/Card/Card';
import Flex from '../../../uikit/Flex/Flex';
import InputText from '../../../uikit/InputText/InputText';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import Loader from '../../../uikit/Loader/Loader';
import Text from '../../../uikit/Text/Text';
import { SUCCESS } from '../../../uikit/Colors/colors';
import CancelAndDeletePopup from '../../common/CancelAndDeletePopup';
import SingleButton from '../../common/SingleButton';
import { DELETE, onlyNumber } from '../../constValue';
import { stripeMiddleWare } from '../../talentsourcingmodule/store/middleware/talentSoucringMiddleware';
import { Subscription } from './manageSubscriptionTypes';
import styles from './pricecard.module.css';
import { createCheckoutSubscriptionMiddleWare } from './store/managesubscriptionmiddleware';

const cx = classNames.bind(styles);

type Props = {
  headerTitle: string;
  price: string;
  days: string;
  data: {
    value: any;
  }[];
  btnTitle: string;
  userPrice?: boolean;
  disabled?: boolean;
  btnDisabled?: boolean;
  planId: number;
  isPlan: boolean;
  isDefalutPlan: boolean;
  totalUserManger: number;
  downgrade?: number;
  subscription?: Subscription;
  inputNone?: boolean;
  subheading: any;
  strip: any;
  plan_id: any;
  focusRef: any;
  formik1: any;
  setmanagesub:any;
  plandays?:any;
};

const PriceCard = ({
  headerTitle,
  price,
  days,
  data,
  btnTitle,
  userPrice,
  disabled,
  btnDisabled,
  planId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  isDefalutPlan,
  isPlan,
  totalUserManger,
  downgrade,
  subscription,
  inputNone,
  subheading,
  strip,
  plan_id,
  focusRef,
  formik1,
  setmanagesub,
  plandays
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isInvite, setInvite] = useState(false);
  const [isChangePlan, setChangePlan] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [isCheckPlan, setCheckPlan] = useState('');
  const { basic_month, basic_year, pro_month, pro_year } = useSelector(
    ({ manageSubscriptionReducers }: RootState) => ({
      basic_month: manageSubscriptionReducers.basic_month,
      basic_year: manageSubscriptionReducers.basic_year,
      pro_month: manageSubscriptionReducers.pro_month,
      pro_year: manageSubscriptionReducers.pro_year,
    }),
  );

  const strip_id = [strip]
  const plan_name = [planId.toString()]
  const handleSubmit = async (values: any) => {
    setLoader(true);
    dispatch(
      createCheckoutSubscriptionMiddleWare({
        plan: JSON.stringify(strip_id),
        count: JSON.stringify(count),
        plan_name: JSON.stringify(plan_name),
      }),
    ).then((response) => {
      dispatch(stripeMiddleWare()).then(async (stripRes) => {
        const stripe = await loadStripe(stripRes.payload.publicKey);
        if (response.payload.sessionId) {
          stripe
            ?.redirectToCheckout({
              sessionId: response.payload.sessionId,
            })
            .then(() => {
              setLoader(false);
            });
        }
      });
    });
  };
  const formik = useFormik({
    initialValues: { value: '1' },
    onSubmit: handleSubmit,
  });
  const count = [formik.values.value]

  // price card submit function
  const handleClick = () => {
    const mappedArray = [
      {
        key: strip,
        plan: plan_id,
      }
    ]
    formik1.setFieldValue('plan', mappedArray)
    if (focusRef.current) {
      focusRef.current.focus();
    }
    setmanagesub(headerTitle)
  };


  const dataArray = Array.isArray(data) ? data : [];
  return (
    <>
      {isLoader && <Loader />}
      <CancelAndDeletePopup
        title={
          <Flex marginLeft={16}>
            <Text>You currently have {totalUserManger} invited users. </Text>
            <Text>
              To remove license, Please delete the existing user accounts.
            </Text>
          </Flex>
        }
        // btnDelete={() => setTab('3')}
        btnDelete={()=>('')}
        btnCancel={() => setInvite(false)}
        btnRight={DELETE}
        open={isInvite}
      />

      <SingleButton
        btnTitle="OK"
        title={
          <Flex>
            <Text>
              Please maintain the allowed 3 active jobs and 15,000 candidate
              storage for
            </Text>
            <Text>the BASIC Plan. Until that you cannot change your plan.</Text>
          </Flex>
        }
        open={isChangePlan}
        btnOnclick={() => setChangePlan(false)}
      />
      <div style={{ position: 'relative' }}>
        {disabled && <div className={styles.disabled} />}
        <Card
          className={
            subscription && subscription.plan_id_id === planId
              ? styles.bgcolor
              : styles.overAll
          }
        >
          <Flex style={{ display: 'contents' }}>
            <Flex>
              <Flex
                style={{
                  margin: '25px 25px 0px 25px',
                  borderBottom: '0.3px solid #C3C3C3',
                }}
              >
                
                <Flex row between center>
                  <Text bold size={18}>
                    {headerTitle}

                  </Text>
                  
                  <Flex row style={{ display: 'flex', alignItems: 'baseline' }}>
                    {price !== 'FREE' ? (
                      <Text size={16} bold style={{ marginBottom: 4 }}>
                        ${price}
                      </Text>
                       ) :
                      <Text size={14} bold >
                        Free&emsp;
                      </Text>
                    }
                    {userPrice && (
                      <Text size={14} >
                        /
                      </Text>
                    )}
                    <Text
                      align="center"
                      size={14}
                      style={{ marginBottom: 4 }}
                    >
                      {days}
                    </Text>
                  </Flex>
                </Flex>
                <Flex row between center marginBottom={5}>
                  <Text color='theme' >{subheading}</Text>
                 { plandays===365&&(<Text className={styles.ribbon}>Save 10%</Text>)}
                </Flex>
              </Flex>

              <Flex columnFlex className={styles.priceList} style={{ margin: '20px 20px 0 20px' }}>
                {dataArray?.map((list, index) => (
                  <Flex row center key={list.value + index} marginBottom={12}>
                    <div style={{ marginRight: 8 }}>
                      <SvgTickOne />
                    </div>
                    <div key={index} style={{ fontSize: '13px' }} dangerouslySetInnerHTML={{ __html: list.value }} />
                    {/* <Text>{list.value}</Text> */}
                  </Flex>
                ))}
              </Flex>
            </Flex>
            {subscription === null ||
              (subscription && subscription.plan_id_id === 1) ? (
              <Flex center middle marginBottom={20} marginTop={20}>
                <Button disabled={btnDisabled} onClick={formik.handleSubmit}>
                  {btnTitle}
                </Button>
              </Flex>
            ) : (
              <Flex center middle marginBottom={20} marginTop={20}>
                <Button disabled={btnDisabled} onClick={() => { handleClick() }} >{btnTitle}</Button>
              </Flex>

            )}
          </Flex>
        </Card>
      </div>
    </>
  );
};

export default PriceCard;
