import * as React from 'react';
const SvgCircleDollar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      fill="#581845"
      d="M5 0a5 5 0 1 0 0 10A5 5 0 0 0 5 0Zm0 9.152A4.153 4.153 0 1 1 5.001.847 4.153 4.153 0 0 1 5 9.152Zm.532-4.41-.283-.067V3.176c.424.058.686.324.73.65a.088.088 0 0 0 .09.077h.5a.09.09 0 0 0 .09-.098c-.069-.696-.641-1.142-1.406-1.22v-.364a.09.09 0 0 0-.089-.09H4.85a.09.09 0 0 0-.089.09v.368c-.79.077-1.408.514-1.408 1.328 0 .755.555 1.119 1.14 1.258l.275.07v1.593c-.493-.066-.77-.33-.827-.684a.089.089 0 0 0-.088-.074h-.516a.09.09 0 0 0-.09.097c.051.614.516 1.179 1.51 1.252v.35c0 .05.04.09.089.09h.317c.049 0 .09-.04.09-.091l-.003-.354c.874-.077 1.499-.545 1.499-1.384-.001-.774-.493-1.12-1.217-1.299Zm-.765-.182a1.633 1.633 0 0 1-.168-.056c-.377-.136-.552-.356-.552-.639 0-.405.307-.636.72-.689V4.56Zm.482 2.282V5.349c.034.01.066.018.098.025.528.16.705.384.705.726 0 .437-.328.7-.803.742Z"
    />
  </svg>
);
export default SvgCircleDollar;