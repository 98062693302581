import SvgJobPipeline from '../../icons/SvgJobPipeline';
import SvgJobTitleIcon from '../../icons/SvgJobTitleIcon';
import SvgJobTitles from '../../icons/SvgJobTitles';
import SvgLocation from '../../icons/SvgLocation';
import Card from '../../uikit/Card/Card';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import { toCamelCase } from '../../uikit/helper';
import { JobDetailsEntity } from './applicantPipeLineTypes';
import styles from './jobtitlecard.module.css';

type TitleProps = {
  title: string;
  value: string;
};

const TitleLabel = ({ title, value }: TitleProps) => {
  return (
    <Flex row center className={styles.labelContainer}>
      {/* <Text color="theme">{title}</Text> */}
      <Text className={styles.valueText}>{value}</Text>
    </Flex>
  );
};
type Props = {
  job_details: JobDetailsEntity;
};
const JobTitleCard = ({ job_details }: Props) => {
  return (
    // <Card className={styles.cardStyle}>
    <Flex row center className={styles.overAll}>
      <div >
      <SvgJobTitleIcon width={20} height={20} fill={'#581845'} />
      </div>
      <TitleLabel
        title="Job Title:"
        value={`${toCamelCase(job_details.job_title)} - ${job_details.job_id}`}
      />
      <div style={{ marginBottom: '4px' }}>
        <SvgLocation width={16} height={16} fill="#581845" />
      </div>

      {job_details.city === null && job_details.state === null && job_details.country === null && job_details.work_space_type === '3' ? (
        <TitleLabel
        title="Location:"
        value='Remote'
      />
      ):(
      <>
      {job_details.city !== null && job_details.state !== null && job_details.country !== null && job_details.work_space_type === '3' ? (
        <TitleLabel
        title="Location:"
        value={`${job_details.city}, ${job_details.state}, ${job_details.country} (Remote)`} 
      />
      ):(
        <TitleLabel
        title="Location:"
        value={`${job_details.city}, ${job_details.state}, ${job_details.country}`} 
      />
      )}
      </>
      )}
      
    </Flex>
    // </Card>
  );
};

export default JobTitleCard;
