import React, { SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { FormikProps } from 'formik';
import { Button, Card, InputText, Toast } from '../../uikit';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Modal from '../../uikit/Modal/Modal';
import styles from '../applicantpipelinemodule/applicantemailautomation.module.css';
import SvgSearch from '../../icons/SvgSearch';
import { CANCEL } from '../constValue';
import { EmailTemplate } from '../../hooks/useStages/types';

import AutomateEmailTemplateList from './AutomateEmailTemplateList';

const cx = classNames.bind(styles);

type Props = {
  template: any;
  formik: FormikProps<any>;
  setTitle: any;
  setSubject: any;
  settemplate: any;
  hanldeClose: any;
  user?: string;
  istemplate: any;
  open: any;
  setContent: any;
  setEditEmailContent: any;
  settemplatemodel: any;
  setNewStageEmailScreen: any;
  setImportTemplateID: any;
};

const ApplicantEmailImportScreen = ({
  formik,
  setSubject,
  template,
  settemplate,
  hanldeClose,
  user,
  istemplate,
  open,
  setContent,
  setEditEmailContent,
  settemplatemodel,
  setNewStageEmailScreen,
  setTitle,
  setImportTemplateID,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<EmailTemplate[]>([]);
  const [valuelist, setvaluelist] = useState(null);
  const [applybtn, setapplybtn] = useState(null);
  const [indextick, setindextick] = useState(null);
  const [isApplyAction, setApplyAction] = useState(false);

  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setSearchTerm(e.target.value);
    searchfunction(e.target.value);
  };

  const update = (val, index) => {
    setindextick(index);
    setvaluelist(val);
    settemplate(true);
  };

  const applyfun = (val) => {
    setapplybtn(val);
  };

  const handleCopy = (templates: string, subject: any, name: any) => {
    formik.setFieldValue('userMessage', templates);
    formik.setFieldValue('userSubject', subject);
    formik.setFieldValue('userTitle', name);
    hanldeClose();
    settemplatemodel(false);
    setTitle(name);
    setSubject(subject);
    setContent(templates);
    Toast('Email template added successfully.', 'LONG', 'success');
  };

  useEffect(() => {
    setSearchResults(template);
  }, [template]);

  useEffect(() => {
    if (istemplate === false) {
      setvaluelist(null);
      setindextick(null);
      setapplybtn(null);
    }
  }, [istemplate]);

  const searchfunction = (val: any) => {
    const results = template.filter(
      (tempList) =>
        tempList.name.toLowerCase().includes(val?.toLowerCase()) ||
        tempList.templates.toLowerCase().includes(val?.toLowerCase()),
    );
    setSearchResults(results);
  };
  const close = () => {
    if (istemplate === true) {
      hanldeClose();
      if (formik.values.userMessage === '') {
        settemplate(false);
      }
    } else {
      hanldeClose();
      setSearchTerm('');
      setvaluelist(null);
      setindextick(null);
      setSearchResults(template);
    }
  };

  const handleInsertTemplate = () => {
    if (applybtn?.subject === null || valuelist === null) {
      setApplyAction(true);
    } else {
      setApplyAction(false);
      handleCopy(valuelist, applybtn.subject, applybtn.name);
      setEditEmailContent(true);
      setNewStageEmailScreen(false);
      setImportTemplateID(applybtn.id);
      update('', '');
      applyfun('');
      setApplyAction(false);
      setvaluelist(null);
    }
  };

  const nullJdIdCount = searchResults?.filter(item => item.jd_id_id === null).length;

  return (
    <>
      <Modal open={open}>
        <Flex
          columnFlex
          className={styles.overAll}
          width={valuelist ? window.innerWidth / 1.3 : '600px'}
        >
          <Flex
            style={{
              borderBottom: '0.5px solid #581845',
              paddingBottom: '5px',
            }}
            marginBottom={5}
          >
            <Text bold className={styles.insertStyles}>
              Email Templates
            </Text>
          </Flex>
          <Flex row center style={{ padding: '10px 0px 10px 0px' }}>
            <InputText
              id="messsageTemplate_search_id"
              placeholder="Search by template name..."
              className={styles.inputchanges}
              value={searchTerm}
              onChange={handleChange}
              // actionRight={() => (
              //   <label htmlFor={'messsageTemplate_search_id'} style={{ margin: 0,right:0 }}>
              //     <SvgSearch />
              //   </label>
              // )}
            />

            <Flex
              style={{ position: 'absolute' }}
              marginTop={1.5}
              middle
              center
              marginLeft={5}
            >
              <SvgSearch />
            </Flex>
            <Flex
              middle
              center
              row
              marginLeft={15}
              className={styles.totalcountchanges}
              style={{ color: '#581845', fontsize: '13px' }}
            >
              <Flex>
                <Text color="black">Total Search Count :</Text>
              </Flex>
              <Flex marginLeft={5}>
                <Text color="black">{nullJdIdCount}</Text>
              </Flex>
            </Flex>
          </Flex>
          {/* <InputText
          id="messsageTemplate_search_id"
          placeholder="Search by template name"
          value={searchTerm}
          onChange={handleChange}
          actionRight={() => (
            <label htmlFor={'messsageTemplate_search_id'} style={{ margin: 0,left:8 }}>
              <SvgSearch />
            </label>
          )}
        /> */}
          <Flex row>
            {/* <Flex> */}
            <Flex
              style={{
                width: valuelist === null ? '100%' : '50%',
              }}
              columnFlex
              // height={}
              height={
                isApplyAction
                  ? window.innerHeight - 325
                  : window.innerHeight - 300
              }
              marginTop={5}
              className={cx('scrollStyle')}
            >
              <Flex>
                {searchResults && searchResults.length !== 0 ? (
                  searchResults.map((list, index) => {
                    if (list.jd_id_id === null) {
                      return (
                        <AutomateEmailTemplateList
                          key={list.name + index}
                          list={list}
                          formik={formik}
                          hanldeClose={() => {
                            hanldeClose();
                            setSearchTerm('');
                            setindextick(null);
                            setvaluelist(null);
                          }}
                          update={update}
                          applyfun={applyfun}
                          indextick={indextick}
                          index={index}
                          searchTerm={searchTerm}
                          user={user !== '' ? user : ''}
                          valuelist={valuelist}
                          emailTemplate={searchResults}
                          setApplyAction={setApplyAction}
                        />
                      );
                    }
                  })
                ) : (
                  <Flex flex={1} middle center>
                    <Text style={{ padding: 24 }} align="center" color="gray">
                      No result found
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>

            {/* </Flex> */}
            {valuelist !== null && (
              <>
                <Flex
                  height={innerHeight - 325}
                  className={styles.border}
                ></Flex>
                <Flex style={{ width: '50%' }} marginTop={5}>
                  <Flex>
                    <Flex
                      className={styles.descCardstyles}
                      height={innerHeight - 325}
                    >
                      <Flex marginBottom={10} center middle>
                        <Text bold size={13}>
                          {applybtn.name}
                        </Text>
                      </Flex>
                      <Flex
                        style={{
                          overflow: 'scroll',
                          padding: '0px 10px 10px 10px',
                        }}
                      >
                        <Flex row marginBottom={10}>
                          <Text bold>Subject: {applybtn.subject}</Text>
                        </Flex>
                        <Flex>
                          <Flex marginBottom={10}>
                            <Text bold>Content:</Text>
                          </Flex>
                          <div
                            className={styles.automatetemplatealignment}
                            dangerouslySetInnerHTML={{ __html: valuelist }}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
          {isApplyAction ? (
            <Flex marginTop={10}>
              <Text color="error">Please select the template to proceed.</Text>
            </Flex>
          ) : (
            ''
          )}
          <Flex columnFlex row center end className={styles.botomBtncontainer}>
            <Flex row width={130} style={{ justifyContent: 'space-between' }}>
              <Button
                types="close"
                onClick={() => {
                  close();
                }}
              >
                {CANCEL}
              </Button>

              <Button
                types="primary"
                // disabled={searchResults?.length === 0 ? true : false || applybtn?.subject === null || valuelist === null}
                onClick={() => {
                  handleInsertTemplate();
                }}
              >
                Add
              </Button>
            </Flex>
          </Flex>
        </Flex>
        {/* </Card> */}
      </Modal>
    </>
  );
};

export default ApplicantEmailImportScreen;
