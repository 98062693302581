/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
  fill: '#979797',
  width: 20,
  height: 20,
};

const SvgDocument = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.52381 6.33333H6.33333M2.52381 7.85714H7.85714M2.52381 9.38095H4.80952M10.1429
         10.1429V4.80952L6.33333 1H2.52381C2.11967 1 1.73208 1.16054 1.44631 1.44631C1.16054 1.73208
          1 2.11967 1 2.52381V10.1429C1 10.547 1.16054 10.9346 1.44631 11.2204C1.73208 11.5061 2.11967
           11.6667 2.52381 11.6667H8.61905C9.02319 11.6667 9.41077 11.5061 9.69654 11.2204C9.98231
            10.9346 10.1429 10.547 10.1429 10.1429Z"
        stroke="#581845"
      />
      <path
        d="M6.33325 1V3.28571C6.33325 3.68985 6.4938 4.07744 6.77957 4.36321C7.06534
         4.64898 7.45292 4.80952 7.85706 4.80952H10.1428"
        stroke="#581845"
      />
    </svg>
  );
};
SvgDocument.defaultProps = defaultProps;

export default SvgDocument;
