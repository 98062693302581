/* eslint max-len: ["error", { "code": 2000 }] */
const defaultProps = {
  fill: '#979797',
  width: 20,
  height: 20,
};

const SvgPrioritysupport = ({ width, height, fill }: typeof defaultProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 9.33317V11.9998H11.3333V9.33317H12.6667ZM4.66667 9.33317V11.9998H4C3.63333 11.9998
         3.33333 11.6998 3.33333 11.3332V9.33317H4.66667ZM8 0.666504C6.4087 0.666504 4.88258 1.29865 3.75736
          2.42386C2.63214 3.54908 2 5.07521 2 6.6665V11.3332C2 12.4398 2.89333 13.3332 4
           13.3332H6V7.99984H3.33333V6.6665C3.33333 4.0865 5.42 1.99984 8 1.99984C10.58 1.99984 12.6667
            4.0865 12.6667 6.6665V7.99984H10V13.3332H12.6667V13.9998H8V15.3332H12C13.1067 15.3332 14 14.4398
             14 13.3332V6.6665C14 5.07521 13.3679 3.54908 12.2426 2.42386C11.1174 1.29865 9.5913 0.666504 8 0.666504Z"
        fill="#581845"
      />
    </svg>
  );
};
SvgPrioritysupport.defaultProps = defaultProps;

export default SvgPrioritysupport;
