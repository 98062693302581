import React, { useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import classNames, { Value } from 'classnames/bind';
// import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Flex from '../../../uikit/Flex/Flex';
import Text from '../../../uikit/Text';
import SvgJobPipeline from '../../../icons/SvgJobPipeline';
import SvgMessages from '../../../icons/SvgMessages';
import SvgMessage from '../../../icons/SvgMessage';
import {
  Button,
  ErrorMessage,
  InputText,
  LinkWrapper,
  Loader,
  Toast,
} from '../../../uikit';
import SvgAdd from '../../../icons/SvgAdd';
import SvgDotMenu from '../../../icons/SvgDotMenu';
import SvgJobPost from '../../../icons/SvgJobPost';
import SvgBack from '../../../icons/SvgBack';
import SvgRight from '../../../icons/SvgRight';
import SvgArrowLeft from '../../../icons/SvgArrowLeft';
import { isEmpty } from '../../../uikit/helper';
import SvgTickBox from '../../../icons/SvgTickBox';
import SvgCloseBox from '../../../icons/SvgCloseBox';
import { AppDispatch, RootState } from '../../../store';
import { PipelineData } from '../../../hooks/useStages/types';
import { useForm } from '../../../hooks/useForm/useForm';
import Tour from '../../tour/tour';
import Highlighter from '../../tour/highlighter';
import { tourdataget } from '../../tour/store/middleware/middleware';
import styles from './templates.module.css';
import JobPipelinePage from './jobPipelinePage';
import {
  deleteJobPipelineMiddleWare,
  getPipelineDataMiddleWare,
  updatejobPipelineMiddleWare,
} from './store/middleware/pipelinesmiddleware';
import JdtemplateModule from './jdtemplate/jdtemplateModule';
import Emailtemplatescreen from './emailtemplates/emailtemplateScreen';
import DeletePopup from './deletePopup';

const cx = classNames.bind(styles);

const TemplatesPage = ({}) => {
  const [template, setTemplate] = useState(
    parseInt(sessionStorage.getItem('template')) || 1,
  );
  const [pipeline, setPipeline] = useState(
    parseInt(sessionStorage.getItem('pipeline')) || 0,
  );
  const [workflow, setworkflow] = useState(
    parseInt(sessionStorage.getItem('workflow')) || 0,
  );
  const [isSubmitLoader, setSubmitLoader] = useState(false);
  const [deletePopup, setDeletePopup] = useState<{
    visible: boolean;
    data: PipelineData;
  } | null>(null);

  const [workId, setWorkId] = useState<number | undefined>(
    parseInt(sessionStorage.getItem('wk_id')) || undefined,
  );
  const [showbutton, setshowbutton] = useState(
    parseInt(sessionStorage.getItem('button')) || 0,
  );

  const [step8, setstep8] = useState(false);
  const [currentindex, setcurrentindex] = useState(0);
  const [piplinename, setpiplinename] = useState('');
  const history = useHistory();

  const {
    pipelineData,
    isLoading,
    isTemplateLoading,
    message,
    Permission1,
    super_user,
    status,
    explore,
    user,
  } = useSelector(
    ({
      pipelinePageReducers,
      templatePageReducers,
      permissionReducers,
      TourdataReducer,
      userProfileReducers,
    }: RootState) => ({
      message: templatePageReducers.message,
      isLoading: pipelinePageReducers.isLoading,
      isTemplateLoading: templatePageReducers.isLoading,
      pipelineData: pipelinePageReducers.pipeline,
      Permission1: permissionReducers.Permission,
      super_user: permissionReducers.super_user,
      explore: TourdataReducer.is_first_login,
      status: TourdataReducer.is_steps,
      user: userProfileReducers.user,
    }),
  );

  // setting the helmat title
  document.title = 'Hiring Workflow';

  //tour useeffect
  useEffect(() => {
    dispatch(tourdataget({}));
    if (status) {
      setstep8(true);
      sessionStorage.setItem('template', '1');
      sessionStorage.setItem('pipeline', '1');
      sessionStorage.setItem('wk_id', pipelineData[0]?.wk_id.toString());
    } else {
      sessionStorage.setItem('template', '0');
      sessionStorage.setItem('pipeline', '0');
    }
  }, [status]);
  // useEffect(() => {
  //   if (message) Toast('Pipeline created successfully', 'LONG');
  // }, [message]);

  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    // Name('Hiring Workflow');
    dispatch(getPipelineDataMiddleWare());
  }, []);
  const selectTemplate = () => {
    setTemplate(1);
    sessionStorage.setItem('template', '1');
  };
  const selectPipeline = () => {
    setWorkId(undefined);
    setPipeline(1);
    setshowbutton(1);
    // dispatch(getTemplateDataMiddleWare());
  };

  const configPipeline = (id: number, name: any) => {
    setpiplinename(name);
    setWorkId(id);
    setPipeline(1);
    setshowbutton(2);
    sessionStorage.setItem('template', '1');
    sessionStorage.setItem('pipeline', '1');
    sessionStorage.setItem('wk_id', JSON.stringify(id));
  };
  const backFunction = () => {
    setPipeline(0);
    sessionStorage.setItem('pipeline', '0');
    sessionStorage.setItem('button', '0');
  };
  const handleUpdate = (value: PipelineData) => {
    setSubmitLoader(true);
    dispatch(updatejobPipelineMiddleWare(value)).then(() => {
      setSubmitLoader(false);
      Toast('Changes saved successfully.', 'LONG');
    });
  };
  const showDeletePopup = (data: PipelineData) => {
    setDeletePopup({
      visible: true,
      data,
    });
  };
  const handleClose = () => {
    setDeletePopup(null);
  };

  const handleDelete = (data: PipelineData) => {
    setSubmitLoader(true);
    dispatch(deleteJobPipelineMiddleWare(data.wk_id)).then(() => {
      setSubmitLoader(false);
      Toast('Pipeline deleted successfully.', 'LONG');
    });
    handleClose();
  };
  const handleDefault = (data: PipelineData) => {
    dispatch(updatejobPipelineMiddleWare(data));
  };

  const selectjd = () => {
    setPipeline(2);
    setTemplate(1);
    // sessionStorage.setItem('template', '1');
  };

  const selectmail = () => {
    setPipeline(3);
    setTemplate(1);
    // sessionStorage.setItem('template', '1');
  };
  const backtemplate = () => {
    setPipeline(0);
    setTemplate(0);
    setworkflow(2);
  };
  const back_navigation = () => {
    sessionStorage.removeItem('template');
    sessionStorage.removeItem('pipeline');
    history.push('/account_setting');
  };

  if (template === 0) {
    return (
      <>
        {isLoading && <Loader />}

        <Flex className={styles.overflowContainer} center middle>
          <>
            {workflow === 0 ? (
              <Flex row marginLeft={'16px'}>
                <Flex flex={3} height={'unset'} minWidth={200} marginRight={20}>
                  <TemplateCard
                    icon={
                      <SvgJobPipeline
                        height={30}
                        width={30}
                        fill="#581845"
                        viewBox="-4 -4 24 24"
                      />
                    }
                    title={'Workflow'}
                    paddings={'1'}
                    subTitle={
                      'Optimize your job pipeline with tailored stages, advanced automation, and personalized candidate profiles for a more efficient and customized recruitment workflow.'
                    }
                    btnName={'Manage Workflow'}
                    onClick={() => selectTemplate()}
                  />
                </Flex>

                <Flex flex={3} height={'unset'} minWidth={200} marginRight={20}>
                  <TemplateCard
                    icon={<SvgJobPost height={26} width={26} />}
                    paddings={'2'}
                    title={'Templates'}
                    subTitle={
                      'Tailor the job description and email templates according to specific requirements and preferences.'
                    }
                    btnName={'Manage Templates'}
                    onClick={() => setworkflow(2)}
                  />
                </Flex>
                {/* <Flex flex={6}></Flex> */}
              </Flex>
            ) : (
              ''
            )}
          </>
          {workflow === 2 ? (
            <Flex className={styles.titleBar}>
              <Flex row marginLeft={'16px'}>
                <Flex flex={3} height={'unset'} minWidth={200} marginRight={20}>
                  <TemplateCard
                    icon={<SvgJobPost height={26} width={26} />}
                    paddings={'3'}
                    title={'Job Description Templates'}
                    subTitle={
                      'Effortlessly manage your job descriptions with streamlined features, including easy creation, editing, deletion, and posting capabilities for detailed job descriptions.'
                    }
                    btnName={'Configure Templates'}
                    onClick={() => selectjd()}
                  />
                </Flex>
                <Flex flex={3} height={'unset'} minWidth={200} marginRight={20}>
                  <TemplateCard
                    icon={<SvgMessage height={30} width={28} fill="#581845" />}
                    title={'Email Templates'}
                    paddings={'4'}
                    subTitle={
                      'Effortlessly generate, scrutinize, and dispatch your emails with seamless efficiency and precision.'
                    }
                    btnName={'Configure Templates'}
                    onClick={() => selectmail()}
                  />
                </Flex>
                {/* <Flex flex={6}></Flex> */}
              </Flex>
              <Flex
                row
                center
                middle
                marginTop={20}
                marginLeft={10}
                className={styles.title}
                onClick={() => setworkflow(0)}
              >
                <SvgArrowLeft />
                {/* <SvgBack height={10} width={10} /> */}
                <Text
                  color="theme"
                  bold
                  size={13}
                  style={{ marginLeft: '5px' }}
                >
                  Templates
                </Text>
              </Flex>
            </Flex>
          ) : (
            ''
          )}
        </Flex>
      </>
    );
  }
  return (
    <>
      {pipeline === 0 ? (
        <>
          <Flex row className={styles.ribbon} marginBottom={5} between>
            <Flex marginTop={9} marginLeft={8}>
              <Text size={16} bold color="theme">
                Hiring Workflow
              </Text>
            </Flex>
            <Flex>
              <div className={styles.triangle}></div>
            </Flex>
          </Flex>

          <Flex row>
            <Flex row marginTop={5} marginLeft={16}>
              <Flex
                style={{
                  fontSize: '15px',
                  color: '#581845',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => back_navigation()}
              >
                Account Settings
              </Flex>
              <Flex marginTop={7} marginLeft={5}>
                <SvgRight fill={'#581845'}></SvgRight>
              </Flex>
              <Flex
                marginLeft={5}
                style={{ fontSize: '15px', fontWeight: 'bold' }}
              >
                Hiring Workflow
              </Flex>
            </Flex>
          </Flex>
          {deletePopup && (
            <DeletePopup
              onClose={handleClose}
              onDelete={handleDelete}
              data={deletePopup.data}
              visible={deletePopup.visible}
            />
          )}

          {isSubmitLoader && <Loader />}
          <Flex
            column
            className={styles.overflowContainer}
            style={{ padding: '0px 10px' }}
          >
            <Flex row between center className={styles.titleBar} marginTop={10}>
              <Flex row center className={styles.title}>
                {/* <SvgBack height={10} width={10} />
                <Text
                  color="theme"
                  bold
                  size={13}
                  style={{ marginLeft: '5px' }}
                >
                  Job Pipeline
                </Text> */}
              </Flex>

              <Button onClick={() => selectPipeline()}>
                <Flex row center className={styles.pointer}>
                  <SvgAdd height={10} width={10} fill="#FFFFFF" />
                  <Text
                    bold
                    color="white"
                    size={13}
                    style={{ marginLeft: '10px' }}
                  >
                    Add Pipeline
                  </Text>
                </Flex>
              </Button>
            </Flex>
            <Flex row wrap marginTop={'10px'}>
              {pipelineData.map((item, index) => (
                <PipelineCard
                  key={`${item.wk_id}-${index}`}
                  item={item}
                  index={index}
                  list={pipelineData}
                  onConfig={configPipeline}
                  onUpdate={handleUpdate}
                  onDelete={showDeletePopup}
                  onDefault={handleDefault}
                />
              ))}
            </Flex>
          </Flex>
        </>
      ) : (
        ''
      )}

      {pipeline === 1 ? (
        <>
          <Flex row className={styles.ribbon} marginBottom={5} between>
            <Flex marginTop={9} marginLeft={8}>
              <Text size={16} bold color="theme">
                Hiring Workflow
              </Text>
            </Flex>
            <Flex>
              <div className={styles.triangle}></div>
            </Flex>
          </Flex>
          <Flex row>
            <Flex row marginTop={5} marginLeft={16}>
              <Flex
                style={{
                  fontSize: '15px',
                  color: '#581845',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => back_navigation()}
              >
                Account Settings
              </Flex>
              <Flex marginTop={7} marginLeft={5}>
                <SvgRight fill={'#581845'}></SvgRight>
              </Flex>
              <Flex
                style={{
                  fontSize: '15px',
                  color: '#581845',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  marginLeft: '5px',
                }}
                onClick={() => setPipeline(0)}
              >
                Hiring Workflow
              </Flex>
              <Flex marginTop={7} marginLeft={5}>
                <SvgRight fill={'#581845'}></SvgRight>
              </Flex>
              {workId === undefined ? (
                <>
                  <Flex
                    marginLeft={5}
                    style={{ fontSize: '15px', fontWeight: 'bold' }}
                  >
                    Add Pipeline
                  </Flex>
                </>
              ) : (
                <>
                  <Flex
                    marginLeft={5}
                    style={{ fontSize: '15px', fontWeight: 'bold' }}
                  >
                    {piplinename}
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
          <Flex className={styles.overflowContainer}>
            <JobPipelinePage
              handleBack={backFunction}
              buttondata={showbutton}
              wk_id={workId}
            />
          </Flex>
        </>
      ) : (
        ''
      )}
      {pipeline === 2 ? <JdtemplateModule handleBack={backtemplate} /> : ''}
      {pipeline === 3 ? <Emailtemplatescreen handleBack={backtemplate} /> : ''}
    </>
  );
};
interface PipelineCardPros {
  item: PipelineData;
  index: number;
  list: PipelineData[];
  onConfig: (id: number, name?: any) => void;
  onUpdate: (value: PipelineData) => void;
  onDelete: (value: PipelineData) => void;
  onDefault: (value: PipelineData) => void;
}
const PipelineCard: React.FC<PipelineCardPros> = ({
  item,
  index,
  list,
  onConfig,
  onUpdate,
  onDelete,
  onDefault,
}) => {
  const [renamePipeline, setRenamePipeline] = useState(false);
  const [isPipelineLoader, setPipelineLoader] = useState(false);
  const [form, setForm] = useState(item);
  const isPipelineDuplicate = (title: string) => {
    const trimTitle = title.trim().toLowerCase();
    const newList = list
      ? list.filter(
          (doc) =>
            doc.pipeline_name.trim().toLowerCase() !==
            item?.pipeline_name.trim().toLowerCase(),
        )
      : list;
    const tilteMap = [...newList].map((doc) =>
      doc.pipeline_name.trim().toLowerCase(),
    );
    return tilteMap.map((str) => str === trimTitle).includes(true);
  };

  const handleJobPipeline = (values: PipelineData) => {
    const errors: Partial<PipelineData> = {};

    if (isEmpty(values.pipeline_name) || values?.pipeline_name.trim() === '') {
      errors.pipeline_name = 'Enter a valid Pipeline title.';
    }

    if (
      !isEmpty(values.pipeline_name) &&
      values.pipeline_name.trim().length > 25
    ) {
      errors.pipeline_name = 'Pipeline title should not exceed 25 characters.';
    }
    if (isPipelineDuplicate(values.pipeline_name)) {
      errors.pipeline_name = 'Pipeline title already exist.';
    }
    return errors;
  };
  useEffect(() => {
    setForm(item);
  }, [item, index]);

  const formik = useForm<PipelineData>({
    initialValues: form,
    isTrim: false,
    initialValidation: true,
    // enableReinitialize: true,
    validate: handleJobPipeline,
    onSubmit: (value) => {
      // formik.handleChange('pipeline_name')(value.pipeline_name.trim());
      onUpdate({ ...value, pipeline_name: value.pipeline_name.trim() });
      handleRename();
    },
  });

  const handleRename = () => {
    setRenamePipeline(!renamePipeline);
    formik.resetForm();
  };
  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      formik.handleSubmit();
    }
  };
  const handleInputLength = (e, fieldName) => {
    const maxLength = 26; // Maximum length allowed

    if (e.target.value.length >= maxLength) {
      e.preventDefault(); // Prevent further input
      const truncatedValue = e.target.value.slice(0, maxLength);
      formik.setFieldValue(fieldName, truncatedValue); // Update the field value
    }
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };
  const renderTitle = () => {
    if (renamePipeline) {
      return (
        <Flex row noWrap>
          <Flex column start>
            <InputText
              name="pipeline_name"
              value={formik.values.pipeline_name}
              onChange={formik.handleChange('pipeline_name')}
              onKeyPress={(e) => handleInputLength(e, 'password1')}
              lineInput
              size={14}
              className={styles.input}
              //  onKeyPress={handleKeyPress}
              onBlur={formik.handleBlur}
            />
            <ErrorMessage
              touched={formik.touched}
              errors={formik.errors}
              name="pipeline_name"
            />
          </Flex>

          <div className={styles.svgContainer}>
            {isPipelineLoader ? (
              <div className={styles.svgTick}>
                <Loader withOutOverlay size={'small'} />
              </div>
            ) : (
              <div
                className={cx('svgTickMargin', {
                  svgTickDisable: !formik.isValid,
                  tickStyle: !isEmpty(formik.values.pipeline_name.trim()),
                })}
                tabIndex={-1}
                role={'button'}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                <SvgTickBox className={styles.tickStyle} />
              </div>
            )}

            <div
              className={styles.svgClose}
              // onClick={handleCloseLocationInput}
              tabIndex={-1}
              role={'button'}
              onClick={handleRename}
            >
              <SvgCloseBox className={styles.tickStyle} />
            </div>
          </div>
        </Flex>
      );
    }
    return (
      <Text
        color="black2"
        bold
        size={13}
        title={item.pipeline_name}
        className={styles.titleText}
        style={{
          marginLeft: '10px',
          maxWidth: item.set_as_default ? '105px' : '200px',
        }}
      >
        {item.pipeline_name}
      </Text>
    );
  };
  return (
    <Card key={item.wk_id} className={styles.pipelineStructure}>
      <Flex row start between className={styles.rowGroup}>
        <Flex row className={styles.cardHeader} marginBottom={7}>
          {renderTitle()}
          {item.set_as_default === true && (
            <Text color="yellow" className={styles.default}>
              Default
            </Text>
          )}
        </Flex>

        {/* <Text> */}
        <ActionsButton
          disabled={item.is_active}
          defaults={item.set_as_default}
          onDefault={() =>
            item.set_as_default
              ? undefined
              : onDefault({ ...item, set_as_default: true })
          }
          onDelete={() => (item.is_active ? undefined : onDelete(item))}
          onRename={() => (item.is_active ? undefined : handleRename())}
        />
        {/* </Text> */}
      </Flex>
      <Flex
        column
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: 10,
          borderTop: '1px solid #c3c3c3',
        }}
      >
        {/* <hr className={styles.borderLine} /> */}
        <Button
          className={styles.btn2}
          onClick={() => onConfig(item.wk_id, item.pipeline_name)}
        >
          <Text bold color="theme">
            Configure Pipeline
          </Text>
        </Button>
      </Flex>
    </Card>
  );
};

const ActionsButton = ({
  defaults,
  onRename,
  onDefault,
  onDelete,
  disabled,
}) => {
  return (
    <>
      <Dropdown className="dropdownButton dropleft">
        {(!defaults || !disabled) && (
          <Dropdown.Toggle
            style={{
              borderColor: 'unset',
              backgroundColor: 'unset',
              boxShadow: 'none',
            }}
            id="dropdown-basic"
          >
            <SvgDotMenu height={10} width={10} fill="#581845" />
          </Dropdown.Toggle>
        )}

        {(!defaults || !disabled) && (
          <Dropdown.Menu style={{ minWidth: '5rem' }}>
            {!disabled && (
              <Dropdown.Item onClick={onRename}>
                <Flex row center className={styles.dropDownListStyle}>
                  <Text>Rename</Text>
                </Flex>
              </Dropdown.Item>
            )}
            {!defaults && (
              <Dropdown.Item onClick={onDefault}>
                <Flex row center className={styles.dropDownListStyle}>
                  <Text>Set as Default</Text>
                </Flex>
              </Dropdown.Item>
            )}
            {!disabled && !defaults && (
              <Dropdown.Item onClick={onDelete}>
                <Flex row center className={styles.dropDownListStyle}>
                  <Text>Delete</Text>
                </Flex>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};

interface TemplateCardProps {
  title: string;
  subTitle: string;
  btnName: string;
  icon: React.ReactNode;
  paddings?: string;
  onClick: () => void;
}

const TemplateCard: React.FC<TemplateCardProps> = (props) => {
  const { title, subTitle, btnName, icon, onClick, paddings } = props;
  return (
    <Card className={styles.cardStructure}>
      <Flex column start className={styles.cardContent}>
        <Flex
          marginTop={40}
          width={348}
          style={{
            position: 'relative',
            border: '0.3px solid #C3C3C3',
            height: '0.5px',
          }}
        ></Flex>
        <Flex center start style={{ position: 'absolute' }} marginLeft={10}>
          <Flex
            middle
            center
            height={50}
            width={50}
            marginLeft={15}
            marginTop={20}
            style={{
              borderRadius: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 0px 6px 3px #eee8ec',
              padding: paddings === '2' ? '2px 0px 0px 4px' : '',
            }}
          >
            {icon}
          </Flex>
          <Flex
            row
            middle
            center
            className={styles.cardHeader}
            marginLeft={20}
            marginTop={10}
          >
            <Text color="theme" bold size={13}>
              {title}
            </Text>
          </Flex>
        </Flex>
        <Flex marginTop={45}>
          <Text
            size={13}
            style={{ marginTop: '10px', padding: '8px 30px 5px 30px' }}
          >
            {subTitle}{' '}
          </Text>
        </Flex>
      </Flex>
      <Flex
        row
        middle
        style={{
          display: 'flex',
          // flexDirection: 'column',
          width: '100%',
          marginTop: 10,
          paddingTop: 10,
          paddingBottom: 20,
          // borderTop: '1px solid #c3c3c3',
        }}
      >
        <Button types="primary" onClick={onClick}>
          <Text bold color="white">
            {btnName}
          </Text>
        </Button>
      </Flex>
    </Card>
  );
};

export default TemplatesPage;
