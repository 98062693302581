import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { myDataBaseInitalMiddleWare } from '../mydatabasemodule/store/middleware/mydatabasemiddleware';

import SelectTag from '../../uikit/SelectTag/SelectTag';
import { option } from '../myjobposting/mock';
import Flex from '../../uikit/Flex/Flex';
import { toCamelCase } from '../../uikit/helper';
import styles from './pipelinejobsworkflowscreen.module.css';

type Props = {
  update?: (val: any) => void;
  jd_Name?: string;
  jd_id?: any;
};

const Jobsearch = ({ update, jd_Name, jd_id }: Props) => {
  const selectInputRef = useRef<any>();
  const dispatch: AppDispatch = useDispatch();

  const { job_details } = useSelector(
    ({ myDataBaseInitalReducers }: RootState) => {
      return {
        job_details: myDataBaseInitalReducers.job_details,
      };
    },
  );

  useEffect(() => {
    dispatch(myDataBaseInitalMiddleWare());
  }, []);

  const getOptionLabel = (option3: { job_title: string }) =>
    ` ${toCamelCase(option3.job_title)}`;
  const getOptionValue = (option1: { id: any }) => option1.id;

  const customFilter = (option2: { label: string }, inputValue: string) => {
    const result = option2.label
      .toLocaleLowerCase()
      .includes(inputValue.toLocaleLowerCase());
    return result;
  };

  const selectvalue = (e) => {
    update(e);
  };

  const filterOptions = job_details?.filter((jobs) => jobs?.id);
  return (
    <>
      <Flex style={{ width: '300px',borderRadius:'none' }} className={styles.boxstyle}>
        <SelectTag
          value={filterOptions.find((e) => Number(e.id) === Number(jd_id))}
          stylechangess
          stylechanges
          isSearchable={true}
          ref={selectInputRef}
          options={filterOptions}
          placeholder="Select job to find match & invite profiles"
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onChange={(e) => selectvalue(e)}
          noOptionsMessage={({ }) => 'You have no posted jobs to display.'}
          filterOption={customFilter}
        />
      </Flex>
    </>
  );
};

export default Jobsearch;
