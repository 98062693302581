import * as React from 'react';
const SvgUnlockedGreen = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#00BE4B"
      d="M12.973 16c0 1.269-1.155 2.286-2.595 2.286-1.44 0-2.594-1.017-2.594-2.286 0-1.269 1.167-2.286 2.594-2.286 1.427 0 2.595 1.029 2.595 2.286ZM2.595 21.714V10.286h15.567v3.531a9.752 9.752 0 0 1 1.297-.103c.442 0 .87.046 1.298.103v-3.531c0-.606-.274-1.188-.76-1.617A2.783 2.783 0 0 0 18.162 8h-1.297V5.714C16.865 2.56 13.959 0 10.378 0c-3.58 0-6.486 2.56-6.486 5.714h2.594c0-1.897 1.739-3.428 3.892-3.428 2.154 0 3.892 1.531 3.892 3.428V8H2.595c-.689 0-1.348.24-1.835.67C.273 9.097 0 9.68 0 10.285v11.428c0 .607.273 1.188.76 1.617.487.428 1.146.669 1.835.669h10.131a6.578 6.578 0 0 1-.934-2.286H2.595Zm19.9-4.754-4.657 4.103-2.063-1.817-1.505 1.325L17.838 24 24 18.571l-1.505-1.611Z"
    />
  </svg>
);
export default SvgUnlockedGreen;