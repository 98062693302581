import axios from 'axios';
import classNames from 'classnames/bind';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { calendarRoute } from '../../appRoutesPath';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import SvgRefresh from '../../icons/SvgRefresh';
import SvgMeetingicon from '../../icons/SvgMeetingicon';
import { AppDispatch, RootState } from '../../store';
import Button from '../../uikit/Button/Button';
import { BLACK, WHITE } from '../../uikit/Colors/colors';
import Flex from '../../uikit/Flex/Flex';
import Loader from '../../uikit/Loader/Loader';
import { firstNameChar, isEmpty, toCamelCase } from '../../uikit/helper';
import Tabel from '../../uikit/Table/Table';
import Text from '../../uikit/Text/Text';
import Toast from '../../uikit/Toast/Toast';
import { CANCEL, config, mediaPath } from '../constValue';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import Tab from '../../uikit/Tabs/Tab';
import Tabs from '../../uikit/Tabs/Tabs';
import { InputSwitch, LinkWrapper } from '../../uikit';
import { meetingTitle, meetingTitlepast } from './MeetingTable';
import styles from './notestab.module.css';
import {
  applicantNotesMiddleWare,
  checkAuthMiddleware,
  eventsApplicantsMiddleware,
  IntergratemailMiddleWare,
} from './store/middleware/applicantProfileMiddleware';
var querystring = require('querystring');

const cx = classNames.bind(styles);

export type FormProps = {
  notes: string;
};
const initial: FormProps = {
  notes: '',
};



type Props = {
  isMeeting?: boolean;
  eventchang?: boolean;
};
const Notesmeet = ({ isMeeting, eventchang }: Props) => {
  //subcription setstate
  const [isopensubcription, setopensubcription] = useState(false);

  const [isCollapse, setCollapse] = useState(false);
  const [isColor, setColor] = useState<string[]>([]);
  const [buttonName, setButtonName] = useState('Add');
  const [getId, setGetId] = useState(0);
  const dispatch: AppDispatch = useDispatch();
  const [active, setActive] = useState(0);
  const [isGoogle, setIsGoogle] = useState(2);
  const [isLoad, setIsLoad] = useState(true);
  const [myevents, setMyevents] = useState<any[]>([]);
  const [mypastevents, setMypastevents] = useState<any[]>([]);
  const [attendeesevents, setattendeesevents] = useState<any[]>([]);
  const [isLoader, setLoader] = useState<any>(false)
  const [checkingstatus, setcheckingstatus] = useState('');
  const [showPastEvents, setShowPastEvents] = useState<any>(null);
  const [eventsTab, setEventsTab] = useState<any>(null);
  const [tabNumber, setTabNumber] = useState(null);
  const [eventDeleteLoader,seteventDeleteLoader] = useState<any>(false)

  const history = useHistory();
  useEffect(() => {
    dispatch(IntergratemailMiddleWare());
  }, []);

  // they calling the check auth function for getting the responce for secheduled meeting.
  const checkAuth = () => {
    setLoader(true)
    dispatch(checkAuthMiddleware())
      .then((res) => {
        if (res.payload.status === true) {
          if (res.payload.account === 'google') {
            setIsGoogle(1);
          } else {
            setIsGoogle(0);
          }
          setActive(1);
          setIsLoad(false);
          dispatch(eventsApplicantsMiddleware({ can_id }))
            .then((response) => {
              setEventsTab(response.payload.events_tab === true);
              setShowPastEvents(true);
              if (response.payload.status === true && response.payload.data !== undefined) {
                setMyevents(
                  response.payload.data.map((items: any, index) => {
                    const Time = Math.floor(items.duration / 60);

                    return {
                      title: items.slotter_event === true ? items?.slotter_title + ' ' + items.applicant : items.event_type + ' ' + items.applicant,
                      organizer: response.payload.user,
                      date: items.s_time,
                      time: `${moment(items.s_time.slice(11, 16), [
                        'HH.mm',
                      ]).format('hh:mm a')}  - ${moment(
                        items.e_time.slice(11, 16),
                        ['HH.mm'],
                      ).format('hh:mm a')}`,
                      web_url: items.eventId,
                      org_id: items.org_id,
                      can_id: items.cand_id,
                      data: mail,
                      join_url: items.join_url,
                      index: index,
                      Time: Time,
                      attendees: items.attendees,
                      attender: response.payload.attendees,
                      slotter: items.slotter_event, 
                      slotter_title : items?.slotter_title,
                      join_link : items?.join_link
                    };
                  }),
                );
                setattendeesevents(response.payload.attendees);
              }
              if (response.payload.past_events !== undefined) {
                setMypastevents(
                  response.payload.past_events?.map((items: any, index) => {
                    const Time = Math.floor(items.duration / 60);
                    return {
                      title: items.slotter_event === true ? items?.slotter_title + ' ' + items.applicant : items.event_type + ' ' + items.applicant,
                      organizer: response.payload.user,
                      date: items.s_time,
                      time: `${moment(items.s_time.slice(11, 16), [
                        'HH.mm',
                      ]).format('hh:mm a')}  - ${moment(
                        items.e_time.slice(11, 16),
                        ['HH.mm'],
                      ).format('hh:mm a')}`,
                      web_url: items.eventId,
                      org_id: items.org_id,
                      can_id: items.cand_id,
                      data: mail,
                      join_url: items.join_url,
                      index: index,
                      Time: Time,
                      attendees: items.attendees,
                      attender: response.payload.attendees,
                      slotter: items.slotter_event, 
                      slotter_title : items?.slotter_title,
                      join_link : items?.join_link
                    };
                  }),
                );
              }
              if (response.payload.past_events !== undefined) {
                setMypastevents(
                  response.payload.past_events?.map((items: any, index) => {
                    const Time = Math.floor(items.duration / 60);
                    return {
                      title: items.event_type + ' ' + items.applicant,
                      organizer: response.payload.user,
                      date: items.s_time,
                      time: `${moment(items.s_time.slice(11, 16), [
                        'HH.mm',
                      ]).format('hh:mm a')}  - ${moment(
                        items.e_time.slice(11, 16),
                        ['HH.mm'],
                      ).format('hh:mm a')}`,
                      web_url: items.eventId,
                      org_id: items.org_id,
                      can_id: items.cand_id,
                      data: mail,
                      join_url: items.join_url,
                      index: index,
                      Time: Time,
                      attendees: items.attendees,
                      attender: response.payload.attendees,
                      slotter: items.slotter_event, 
                      slotter_title : items?.slotter_title,
                      join_link : items?.join_link
                    };
                  }),
                );
              }
            })
          setLoader(false);
        } else {
          setActive(0);
          setIsLoad(false);
          setLoader(false);
        }
      })
  };

  // color code for showing the background in without profile.
  useEffect(() => {
    checkAuth();
    const colorCode = [
      '#d08014',
      '#d04343',
      '#db1f77',
      '#c0399f',
      '#6367de',
      '#286eb4',
      '#0f828f',
      '#7ca10c',
      '#925ace',
      '#647987',
    ];

    setColor(colorCode);
  }, []);

  const {
    candidate_details,
    notes,
    can_id,
    // calenderEvent,
    email,
    mail,
    jd_id,
    jd,
    google,
    outlook,
    calenderLoader,
    permission,
    stages,
    super_user,
    roles,
  } = useSelector(
    ({
      applicantNotesReducers,
      applicantProfileInitalReducers,
      calenderReducers,
      applicantIntegratemailReducers,
      permissionReducers,
      applicantStausReducers
    }: RootState) => {
      return {
        candidate_details: applicantProfileInitalReducers.candidate_details,
        notes: applicantNotesReducers.notes,
        can_id: applicantProfileInitalReducers.can_id,
        // calenderEvent: calenderReducers.event,
        google: calenderReducers.google,
        outlook: calenderReducers.outlook,
        jd: applicantProfileInitalReducers.jd?.job_title,
        jd_id: applicantProfileInitalReducers.jd_id,
        calenderLoader: calenderReducers.isLoading,
        stages: applicantStausReducers?.stages,
        email:
          applicantIntegratemailReducers.email !== undefined
            ? applicantIntegratemailReducers.email[0]?.email
            : '',
        mail: applicantIntegratemailReducers?.mail,
        permission: permissionReducers.data,
        super_user: permissionReducers.super_user,
        roles: permissionReducers.roles,
      };
    },
  );  
  
  useEffect(() => {
    if (stages.length >= 1) {
      const stage_name = stages[stages.length - 1].stage_id__stage_name;
      setcheckingstatus(stage_name);
    }
    if (stages.length === 0) {
      setcheckingstatus('');
    }
  }, [stages]); 
    
  // refresh calendar function
  const hanldeRefresh = () => {
    setLoader(true);
    dispatch(checkAuthMiddleware())
      .then((res) => {
        if (res.payload.status === true) {
          if (res.payload.account === 'google') {
            setIsGoogle(1);
          } else {
            setIsGoogle(0);
          }
          setActive(1);
          setIsLoad(false);
          dispatch(eventsApplicantsMiddleware({ can_id }))
            .then((response) => {
              setEventsTab(response.payload.events_tab === true);
              setShowPastEvents(true);
              if (response.payload.status === true && response.payload.data !== undefined) {
                setMyevents(
                  response.payload.data.map((items: any, index) => {
                    const Time = Math.floor(items.duration / 60);
                    return {
                      title: items.slotter_event === true ? items?.slotter_title : items.event_type + ' ' + items.applicant,
                      organizer: response.payload.user,
                      date: items.s_time,
                      time: `${moment(items.s_time.slice(11, 16), [
                        'HH.mm',
                      ]).format('hh:mm a')}  - ${moment(
                        items.e_time.slice(11, 16),
                        ['HH.mm'],
                      ).format('hh:mm a')}`,
                      web_url: items.eventId,
                      org_id: items.org_id,
                      can_id: items.cand_id,
                      data: mail,
                      join_url: items.join_url,
                      index: index,
                      Time: Time,
                      attendees: items.attendees,
                      attender: response.payload.attendees,
                      slotter: items.slotter_event, 
                      slotter_title : items?.slotter_title,
                      join_link : items?.join_link
                    };
                  }),
                );
              }
              if (response.payload.past_events !== undefined) {
                setMypastevents(
                  response.payload.past_events.map((items: any, index) => {
                    const Time = Math.floor(items.duration / 60);
                    return {
                      title: items.slotter_event === true ? items?.slotter_title : items.event_type + ' ' + items.applicant,
                      organizer: response.payload.user,
                      date: items.s_time,
                      time: `${moment(items.s_time.slice(11, 16), [
                        'HH.mm',
                      ]).format('hh:mm a')}  - ${moment(
                        items.e_time.slice(11, 16),
                        ['HH.mm'],
                      ).format('hh:mm a')}`,
                      web_url: items.eventId,
                      org_id: items.org_id,
                      can_id: items.cand_id,
                      data: mail,
                      join_url: items.join_url,
                      index: index,
                      Time: Time,
                      attendees: items.attendees,
                      attender: response.payload.attendees,
                      slotter: items.slotter_event, 
                      slotter_title : items?.slotter_title,
                      join_link : items?.join_link
                    };
                  }),
                );
              }
              if (response.payload.past_events !== undefined) {
                setMypastevents(
                  response.payload.past_events.map((items: any, index) => {
                    const Time = Math.floor(items.duration / 60);
                    return {
                      title: items.event_type + ' ' + items.applicant,
                      organizer: response.payload.user,
                      date: items.s_time,
                      time: `${moment(items.s_time.slice(11, 16), [
                        'HH.mm',
                      ]).format('hh:mm a')}  - ${moment(
                        items.e_time.slice(11, 16),
                        ['HH.mm'],
                      ).format('hh:mm a')}`,
                      web_url: items.eventId,
                      org_id: items.org_id,
                      can_id: items.cand_id,
                      data: mail,
                      join_url: items.join_url,
                      index: index,
                      Time: Time,
                      attendees: items.attendees,
                      attender: response.payload.attendees,
                      slotter: items.slotter_event, 
                      slotter_title : items?.slotter_title,
                      join_link : items?.join_link
                    };
                  }),
                );
              }
            })
          setLoader(false);
        } else {
          setActive(0);
          setIsLoad(false);
          setLoader(false);
        }
      }) 
  };

  // set the tab based on the event
  useEffect(() => {
    if (eventsTab !== null) {

      if (eventsTab === true) {
        setTabNumber(0);
      } else {
        setTabNumber(1);
      }
    }
  }, [eventsTab, showPastEvents]);

  // navigation based on the user.
  const handleSetting = () => { 
    sessionStorage.setItem('CommunicationDropdown', '1')
    history.push('/account_setting/integration');
  };
  const meetingMemo = useMemo(() => meetingTitle({ hanldeRefresh,seteventDeleteLoader }), [myevents]);
  const meetingMemopast = useMemo(() => meetingTitlepast({ hanldeRefresh,seteventDeleteLoader }), [mypastevents]);


   
   
  // they opening the scheduled event popup
  const schedulehandleClick = () => {
    const params = new URLSearchParams();
    params.append('action', 'open-scheduler-form');
    params.append('id', stages.filter(e => Number(e.candidate_id) === Number(can_id))[0]?.id);
    params.append('jobId', jd_id);
    params.append('name', `${toCamelCase(candidate_details[0].first_name)} ${candidate_details[0].last_name !== '' &&
      candidate_details[0].last_name !== null
      ? toCamelCase(candidate_details[0].last_name)
      : ''
      }`);
    params.append('jobTitle', jd);
    params.append('emailid', candidate_details[0].email);
    params.append('can_id', can_id);
    const url = `${calendarRoute}?${params}`;
    window.open(url); 
  };

  // they functions for getting the current time.
  const now = new Date();
  const utcOffsetMinutes = now.getTimezoneOffset();
  const hours = Math.floor(Math.abs(utcOffsetMinutes) / 60);
  const minutes = Math.abs(utcOffsetMinutes) % 60;
  const utcOffsetString = `UTC ${utcOffsetMinutes >= 0 ? '-' : '+'
    }${hours}:${minutes.toString().padStart(2, '0')}`;
    
  if (isLoader || eventDeleteLoader) {
    return <Loader />;
  }
  if (active !== 0 && showPastEvents === null) {
    return <Loader />;
  }
  return (
    <Flex
      columnFlex
      className={styles.overAll}
      height={window.innerHeight - 120}
    >
      {isMeeting && (
        <Flex flex={6} columnFlex style={{ padding: "0px 5px 10px 0px" }}>
          <Flex row between >
            {active === 0 ? (
              <Flex row center middle className={styles.syncedWidth}>
              </Flex>
            ) : (
              <Flex
                row
                between
                marginTop={8}
                flex={6}
                columnFlex
                className={styles.borderbellow}
              >
                <Flex>
                  <Flex>
                    <Text color="theme" bold className={styles.meetingFlex}>
                      Details
                    </Text>
                  </Flex>
                  <Flex>
                    {isGoogle === 1 && (
                      <Flex>
                        <Flex row>
                          <Flex className={styles.syncedWidth}>
                            <Text color="theme" style={{ fontSize: '13px' }}>
                              Synced with:
                            </Text>
                          </Flex>
                          <Flex marginLeft={5}>
                            <Text>{email}</Text>
                          </Flex>
                        </Flex>
                        <Flex row>
                          <Flex className={styles.syncedWidth}>
                            <Text
                              color="theme"
                              style={{ fontSize: '13px', marginRight: '15px' }}
                            >
                              Time zone:
                            </Text>
                          </Flex>
                          <Flex>{utcOffsetString}</Flex>
                        </Flex>
                      </Flex>
                    )}
                    {isGoogle === 0 && (
                      <Flex>
                        <Flex row>
                          <Flex className={styles.syncedWidth}>
                            <Text color="theme" style={{ fontSize: '13px' }}>
                              Synced with:
                            </Text>
                          </Flex>
                          <Flex marginLeft={5}>
                            {' '}
                            <Text style={{ fontSize: '13px' }}>
                              {email}
                            </Text>{' '}
                          </Flex>
                        </Flex>
                        <Flex row>
                          <Flex className={styles.syncedWidth}>
                            <Text
                              color="theme"
                              style={{ fontSize: '13px', marginRight: '5px' }}
                            >
                              Time zone:
                            </Text>
                          </Flex>
                          <Flex style={{ fontSize: '13px' }}>
                            {utcOffsetString}
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                </Flex>

                <Flex row end center middle style={{ paddingTop: '4px' }}>
                  <Flex end marginRight={checkingstatus !== 'Applied' && 10} height={30}>
                    <Button
                      types="secondary"
                      className={styles.syncBtn}
                      onClick={hanldeRefresh}
                    >
                      <Flex row center>
                        <Text
                          bold
                          style={{
                            marginRight: 4,
                            marginLeft: 4,
                            cursor: 'pointer',
                            color: '#333333',
                          }}
                        >
                          Sync
                        </Text>
                        <SvgRefresh height={14} width={14} fill={'#333333'} />
                      </Flex>
                    </Button>
                  </Flex>
                  {checkingstatus.length !== 0 && checkingstatus !== 'Applied' &&
                    <>
                      {permission.includes('Interview Scheduler') ? (
                        <Flex height={30}>
                          <Button onClick={schedulehandleClick} types="primary">
                            Schedule event
                          </Button>
                        </Flex>
                      ) : (
                        <Flex height={30}>
                          <Button
                            onClick={() => setopensubcription(true)}
                            types="primary"
                          >
                            <Flex row>
                              <Flex>
                                <Text color="white">Schedule event</Text>
                              </Flex>
                              <Flex
                                marginLeft={5}
                                marginTop={1}
                                style={{ cursor: 'pointer' }}
                              >
                                <SvgSubcriptioncrown
                                  height={14}
                                  width={14}
                                  fill=""
                                />
                              </Flex>
                            </Flex>
                          </Button>
                        </Flex>
                      )}
                    </>
                  }
                </Flex>
              </Flex>
            )}
          </Flex>

          {active === 0 && (
            <Flex center middle columnFlex height={window.innerHeight - 121}>
              {/* <Flex >
                <SvgMeetingicon fill="#979797" />
              </Flex> */}
              <Flex center middle>
                <Text size={13} style={{ marginBottom: 16 }} color="gray">
                  Integrate your calendar with Zita to schedule meetings
                </Text>
                <Flex row center middle className={styles.syncedWidth}>
                  <Button
                    types="primary"
                    className={styles.settingBtn}
                    onClick={handleSetting}
                  >
                    Integrate
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}
          {myevents.length === 0 && mypastevents.length === 0 ? (
            <Flex className={styles.nomeeting} center middle>
              <Flex center middle marginTop={100}>
                <SvgMeetingicon />
              </Flex>
              <Flex center middle marginTop={10}>
                <Text
                  style={{ fontSize: '13px', color: '#979797' }}
                  color="gray"
                >
                  Meetings not yet scheduled
                </Text>
              </Flex>
            </Flex>
          ) : (
            <>
              {tabNumber !== null &&
                <Flex style={{ margin: ' 0 -8px', padding: '0px 8px' }} flex={1}>
                  <Tabs active={tabNumber} >
                    <Tab title={'Upcoming Events'}>
                      {myevents.length !== 0 ? (
                        <Tabel
                          border={'outline'}
                          columns={meetingMemo}
                          dataSource={myevents}
                          // empty="No meetings scheduled yet"
                          isLoader={calenderLoader}
                        />
                      ) : (
                        <Flex className={styles.nomeeting} center middle>
                          <Flex center middle marginTop={100}>
                            <SvgMeetingicon />
                          </Flex>
                          <Flex center middle marginTop={10}>
                            <Text
                              style={{ fontSize: '13px', color: '#979797' }}
                              color="gray"
                            >
                              Meetings not yet scheduled
                            </Text>
                          </Flex>
                        </Flex>
                      )}
                    </Tab>
                    <Tab title={'Past Events'}>
                      {mypastevents.length !== 0 ? (
                        <Tabel
                          border={'outline'}
                          columns={meetingMemopast}
                          dataSource={mypastevents}
                          // empty="No meetings scheduled yet"
                          isLoader={calenderLoader}
                        />
                      ) : (
                        <Flex className={styles.nomeeting} center middle>
                          <Flex center middle marginTop={100}>
                            <SvgMeetingicon />
                          </Flex>
                          <Flex center middle marginTop={10}>
                            <Text
                              style={{ fontSize: '13px', color: '#979797' }}
                              color="gray"
                            >
                              Meetings not yet scheduled
                            </Text>
                          </Flex>
                        </Flex>
                      )}
                    </Tab>
                  </Tabs>

                  {/* <Table
                  border={'outline'}
                  columns={showPastEvents ? meetingMemopast : meetingMemo}
                  dataSource={showPastEvents? mypastevents : myevents}
                  empty="No meetings scheduled yet"
                  isLoader={calenderLoader}
                /> */}
                </Flex>}
            </>
          )}

        </Flex>
      )}
      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
        />
      )}
    </Flex>
  );
};

export default Notesmeet;
