import { createSlice } from '@reduxjs/toolkit';
import { ManageSubscriptionReducerState ,ManageSubscriptionwebsiteReducerState} from '../manageSubscriptionTypes';
import { manageSubscriptionMiddleWare ,websiteSubscriptionScreenMiddleWare} from './managesubscriptionmiddleware';

const buildCareerState: ManageSubscriptionReducerState = {
  isLoading: false,
  error: '',
  sub_id: 0,
  downgrade: 0,
  basic_month: '',
  basic_year: '',
  free_expired: 0,
  expire_in: 0,
  invites: 0,
  total_user: 0,
  user_count: 0,
  pro_year: '',
  pro_month: '',
  price: 0,
  CustomerId: 0,
  available: 0,
  base_price:0,
  total_plan:[],
  yearly_plan:[],
  monthly_plan:[],
  free_plan:[],
  available_addons:[],
  content_text:[],
  addons_content_01:'',
  addons_content_02:'',
  avail_addons:'',
  unlimited:[],
  expire_details:[]
};

const manageSubscriptionReducer = createSlice({
  name: 'subscription',
  initialState: buildCareerState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(manageSubscriptionMiddleWare.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(manageSubscriptionMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.sub_id = action.payload.sub_id;
      state.CustomerId = action.payload.CustomerId;
      state.available = action.payload.available;
      state.basic_month = action.payload.basic_month;
      state.basic_year = action.payload.basic_year;
      state.downgrade = action.payload.downgrade;
      state.error = action.payload.error;
      state.expire_in = action.payload.expire_in;
      state.free_expired = action.payload.free_expired;
      state.invites = action.payload.invites;
      state.price = action.payload.price;
      state.pro_month = action.payload.pro_month;
      state.pro_year = action.payload.pro_year;
      state.subscription = action.payload.subscription;
      state.total_user = action.payload.total_user;
      state.user_count = action.payload.user_count;
      state.base_price = action.payload.base_price;
      state.total_plan=action.payload.total_plan;
      state.yearly_plan=action.payload.yearly_plan;
      state.monthly_plan=action.payload.monthly_plan;
      state.free_plan=action.payload.free_plan;
      state.available_addons=action.payload.available_addons;
      state.content_text=action.payload.content_text;
      state.addons_content_01=action.payload.addons_content_01;
      state.addons_content_02=action.payload.addons_content_02;
      state.avail_addons=action.payload.avail_addons;
      state.unlimited=action.payload.unlimited;
      state.expire_details=action.payload.expire_details;
    });
    builder.addCase(manageSubscriptionMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

//webpage

const buildCareerwebsiteStatewebsite: ManageSubscriptionwebsiteReducerState = {
  isLoading: false,
  error: '',
  yearly_plan:[],
  monthly_plan:[],
  standard_plan:[],
  premium_plan:[],
  web_content_01:[],
  web_content_02:[],
};

const manageSubscriptionwebsiteReducer = createSlice({
  name: 'websitesubscription',
  initialState: buildCareerwebsiteStatewebsite,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(websiteSubscriptionScreenMiddleWare.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(websiteSubscriptionScreenMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
      state.yearly_plan=action.payload.yearly_plan;
      state.monthly_plan=action.payload.monthly_plan;
      state.standard_plan=action.payload.standard_plan;
      state.premium_plan=action.payload.premium_plan;
      state.web_content_01=action.payload.web_content_01;
      state.web_content_02=action.payload.web_content_02;

     
    });
    builder.addCase(websiteSubscriptionScreenMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

export const manageSubscriptionReducers = manageSubscriptionReducer.reducer;
export const manageSubscriptionwebsiteReducers = manageSubscriptionwebsiteReducer.reducer;
