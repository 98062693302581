import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Bothcandidateidjobid } from '../../routes/apiRoutes';
import ProgressBar from '../../uikit/ProgressBar/ProgressBar';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import SvgDone from '../../icons/SvgDone';
import SvgClose from '../../icons/Svgnotmatch';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import { GARY_7, WHITE } from '../../uikit/Colors/colors';
import { AppDispatch, RootState } from '../../store';
import Tab from '../../uikit/Tabs/Tab';
import { Loader } from '../../uikit';
import { Button, LinkWrapper } from '../../uikit';
import Tabs from '../../uikit/Tabs/Tabs';
import { WeightagematchinggetMiddleWare } from '../createjdmodule/store/middleware/createjdmiddleware';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { CandidatejobidMatchMiddleWare } from './store/middleware/applicantProfileMiddleware';
import styles from './matchinganalysistab.module.css';
import AllMatchTab from './AllMatchTab';
const colorCode = [WHITE, GARY_7];
export interface DateEntity {
    not_matched_skills: [];
    not_matched_qualification: [];
}
export type MyJobFormProps = {
    Notmatch: DateEntity;
    data: string;
    // postedOn: DateEntity;
    // jobType: string;
    // location: string;
};
type Props = {
    updatr_overall?: (val: any) => void;
    dispatchhandling?: () => void;
}
const MatchingAnalysisbasicTab = ({ updatr_overall, dispatchhandling }: Props) => {
    //subcription setstate
    const [isopensubcription, setopensubcription] = useState(false);

    useEffect(() => {
        dispatch(SubsriptionMiddleWare());
    }, []);
    const {
        matchql,
        data,
        overall_percentage,
        non_tech_percentage,
        Notmatchql,
        Notmatch,
        qualification_percent,
        skills_percent,
        overallskill,
        isLoading,
        overallQualification,
        jd_id,
        can_id,
        outputtech,
        outputnontech,
        ai_matching_plan,
        Permission
    } = useSelector(
        ({
            applicantProfileInitalReducers,
            candidatejdmatchReducers,
            weightageReducers,
            permissionReducers
        }: RootState) => {
            return {
                can_id: applicantProfileInitalReducers.can_id,
                jd_id: applicantProfileInitalReducers?.jd_id,
                isLoading: candidatejdmatchReducers.isLoading,
                ai_matching: candidatejdmatchReducers?.ai_matching,
                aidata: candidatejdmatchReducers?.data,
                match: candidatejdmatchReducers.match
                    ? candidatejdmatchReducers.match
                    : [],
                ai_matching_plan: candidatejdmatchReducers.ai_matching_plan,
                matchql: candidatejdmatchReducers.matched_data.matched_qualification,
                data: candidatejdmatchReducers.matched_data.matched_skills,
                overall_percentage: candidatejdmatchReducers.overall_percentage,
                non_tech_percentage: candidatejdmatchReducers.non_tech_percentage,
                Notmatch: candidatejdmatchReducers.not_matched_data.not_matched_skills,
                Notmatchql: candidatejdmatchReducers.not_matched_data.not_matched_qualification,
                qualification_percent: candidatejdmatchReducers.qualification_percent,
                skills_percent: candidatejdmatchReducers.skills_percent,
                overallskill: candidatejdmatchReducers.source.jd_skills,
                overallQualification: candidatejdmatchReducers.source.qualification,
                matchedlocation: candidatejdmatchReducers.matched_data.matched_location,
                notmatchedlocation: candidatejdmatchReducers.not_matched_data.not_matched_location,
                location: candidatejdmatchReducers.source.jd_location,
                location_percent: candidatejdmatchReducers.location_percent,
                success: weightageReducers.success,
                non_tech: weightageReducers.non_tech,
                tech: weightageReducers.tech_skills,
                outputnontech: candidatejdmatchReducers.non_technical,
                outputtech: candidatejdmatchReducers.technical,
                Permission: permissionReducers.data,

            };
        },
    );


    const [valtech, setvaltech] = useState(outputtech.length > 0 ? outputtech : [])
    const [valnontech, setvalnontech] = useState(outputnontech.length > 0 ? outputnontech : [])



    const [isViewMoreClicked, setIsViewMoreClicked] = useState(false);

    const handleViewMoreClick = () => {
        setIsViewMoreClicked(true);
    };

    const handleViewLessClick = () => {
        setIsViewMoreClicked(false);
    };
    const [isCollapse, setCollapse] = useState(false);
    const [isloadings, setisloading] = useState(false);

    const isTablet = useMediaQuery({ query: '(max-width: 1000px)' });
    const normal = useMediaQuery({ query: '(min-width: 1000px) and (max-width: 1411px)' });

    let formData = new FormData();
    const [isnextLoader, setnextLoader] = useState(false)


    const [model, setmodel] = useState(false)
    const [isInfoPopupOpen, setInfoPopupOpen] = useState(false);

    const [islodermatch, setloadermatch] = useState(false);

    const [overallpercent, setoverallpercent] = useState<any>();


    useEffect(() => {
        setoverallpercent(overall_percentage)
        setvaltech(outputtech)
        setvalnontech(outputnontech)
    }, [])



    const [expandedIndex2, setExpandedIndex2] = useState([]);
    const [expandedIndex, setExpandedIndex] = useState([]);
    const dispatch: AppDispatch = useDispatch();

    function calculateLineCount(text, lineHeight, maxWidth) {
        // Create a temporary element to measure the text
        const tempElement = document.createElement("div");
        tempElement.style.position = "absolute";
        tempElement.style.whiteSpace = "pre-wrap";
        tempElement.style.wordWrap = "break-word";
        tempElement.style.lineHeight = `${lineHeight}px`;
        tempElement.style.width = `${maxWidth}px`;
        tempElement.innerHTML = text;

        // Append the temporary element to the document
        document.body.appendChild(tempElement);

        // Calculate the number of lines based on the height of the element
        const lineCount = Math.ceil(tempElement.clientHeight / lineHeight);

        // Remove the temporary element
        document.body.removeChild(tempElement);

        return lineCount;
    }


    const handleToggleCollapse = (index) => {
        setExpandedIndex((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((prevIndex) => prevIndex !== index)
                : [...prevIndexes, index]
        );
    };
    const handleToggleCollapse2 = (index) => {
        setExpandedIndex2((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((prevIndex) => prevIndex !== index)
                : [...prevIndexes, index]
        );
    };

    useEffect(() => {
        setExpandedIndex([]);
        setExpandedIndex2([]);
    }, []);

    useEffect(() => {
        if (isLoading === true) {
            setisloading(true);
        } else {
            setisloading(false);
        }
    }, []);


    const checkMatch = overall_percentage === 0 ? true : false;
    const profileMatch = checkMatch ? 0 : overall_percentage;
    const skillconvert = Math.round((skills_percent / 95) * 100);
    const qualificationconvert = (qualification_percent / 5) * 100;
    return (
        <>
            <Flex row between style={{ padding: '16px 16px 0px 16px' }}>
                <Flex>
                    <Flex>
                        <Text bold style={{ fontSize: '14px', marginBottom: '5px' }}>
                            Matching Analysis
                        </Text>
                    </Flex>
                    <Flex>
                        <Text size={13}>
                            Overall matching score for this candidate with the job
                        </Text>
                    </Flex>
                </Flex>
                <Flex>
                    <Button>
                        {Permission.includes('Advanced AI matching - Resume matching to multiple jobs') ?
                            <Flex row style={{ cursor: 'pointer' }}>
                                <Flex><Text color='white' style={{ cursor: 'pointer' }} onClick={() => dispatchhandling()}>AI Matching</Text></Flex>
                            </Flex> :
                            Permission.includes('Addon Resume Matching to Multiple Jobs') ?
                                <Flex row style={{ cursor: 'pointer' }}>
                                    <Flex><Text color='white' style={{ cursor: 'pointer' }} onClick={() => dispatchhandling()}>AI Matching</Text></Flex>
                                </Flex> :
                                <Flex row onClick={() => setopensubcription(true)} style={{ cursor: 'pointer' }}>
                                    <Flex><Text color='white' style={{ cursor: 'pointer' }}>AI Matching</Text></Flex>
                                    <Flex marginLeft={5} marginTop={0} style={{ cursor: 'pointer' }}><SvgSubcriptioncrown /></Flex>
                                </Flex>}
                    </Button>
                </Flex>
            </Flex>
            <Flex center style={{ padding: '0px 16px 0px 16px' }}>
                <Flex
                    row
                    between
                    marginTop={20}
                    center
                    className={styles.progressStyle}
                    style={{
                        paddingBottom: '20px',

                        borderBottom: '1px solid #C3C3C3',
                    }}
                    flex={12}
                >
                    <Flex flex={6} marginLeft={'50px'}>
                        <ProgressBar
                            verticalWidth={'100px'}
                            roundProgressHeight={70}
                            type="round"
                            percentage={profileMatch}
                        />
                    </Flex>
                    <Flex center>
                        <Flex row flex={6}>
                            <Flex marginRight={18} style={{ fontSize: '13px' }}>
                                Skills
                            </Flex>
                            <Flex marginLeft={'43.2px'}>
                                <ProgressBar
                                    verticalWidth={'200px'}
                                    type="hr"
                                    percentage={skillconvert}
                                    changingpercentageinmatching={skills_percent}
                                />
                            </Flex>
                        </Flex>
                        <Flex
                            row
                            marginTop={20}
                            style={{ bottom: '1px solid #C3C3C3' }}
                        >
                            <Flex marginRight={20} style={{ fontSize: '13px' }}>
                                Qualification
                            </Flex>
                            <Flex>
                                <ProgressBar
                                    verticalWidth={'200px'}
                                    type="hr"
                                    percentage={qualificationconvert}
                                    changingpercentageinmatching={qualification_percent}
                                />
                            </Flex>
                        </Flex>

                        {/* manoj <Flex
                    row
                    between
                    marginTop={10}
                    style={{ bottom: '1px solid #C3C3C3' }}
                  >
                    <Flex marginRight={20} style={{ fontSize: '13px' }}>
                    Location 
                    </Flex>
                    <Flex>
                      <ProgressBar
                        verticalWidth={'200px'}
                        type="hr"
                        percentage={location_percent}
                      />
                    </Flex>
                  </Flex> manoj */}
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                height={window.innerHeight - 295}
                style={{ overflow: 'scroll', display: 'flex', padding: '0px 16px 0px 16px' }}
            >
                <Flex className={styles.mapListContainer}>
                    <Flex row center between className={styles.dataListStyle}>
                        <Flex flex={3}>
                            <Text className={styles.titleStyle}>Skills</Text>
                        </Flex>
                        <Flex flex={2}>
                            <Text bold style={{ fontSize: '13px' }}>
                                {data ? data.length : 0}/
                                {overallskill ? overallskill.length : 0}
                            </Text>
                        </Flex>
                        <Flex flex={7}>
                            {data ? data.map((list) => {
                                return (
                                    <>
                                        <Flex className={styles.valueListStyle} row center>
                                            <div className={styles.svgStyle}>
                                                <SvgDone />
                                            </div>
                                            <Text
                                                style={{
                                                    color: '#333333',
                                                    fontSize: '13px',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {list}{' '}
                                            </Text>
                                        </Flex>
                                    </>
                                );
                            }) : ''}
                            {Notmatch ? Notmatch.map((fix) => {
                                return (
                                    <>
                                        <Flex className={styles.valueListStyle} row center>
                                            <Flex row center>
                                                <Flex className={styles.svgStyle}>
                                                    <SvgClose fill="#ED4857" />
                                                </Flex>
                                                <Text
                                                    style={{
                                                        color: '#333333',
                                                        fontSize: '13px',
                                                        textTransform: 'uppercase',
                                                    }}
                                                >
                                                    {' '}
                                                    {fix}
                                                </Text>
                                            </Flex>
                                        </Flex>{' '}
                                    </>
                                );
                            }) : ''}
                        </Flex>
                    </Flex>
                </Flex>

                <Flex className={styles.mapListContainer}>
                    <Flex
                        // key={data}
                        row
                        center
                        between
                        className={styles.dataListStyle}
                    >
                        <Flex flex={3}>
                            <Text className={styles.titleStyle}>Qualification</Text>
                        </Flex>
                        <Flex flex={2}>
                            <Text bold style={{ fontSize: '13px' }}>
                                {matchql ? matchql.length : 0}/
                                {overallQualification ? overallQualification.length : 0}
                            </Text>
                        </Flex>
                        <Flex flex={7}>
                            {matchql ? matchql.map((list) => {
                                return (
                                    <>
                                        <Flex className={styles.valueListStyle} row center>
                                            <div className={styles.svgStyle}>
                                                <SvgDone />
                                            </div>
                                            <Text
                                                style={{
                                                    color: '#333333',
                                                    fontSize: '13px',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {list}{' '}
                                            </Text>
                                        </Flex>
                                    </>
                                );
                            }) : ''}
                            {Notmatchql ? Notmatchql.map((list) => {
                                return (
                                    <>
                                        <Flex className={styles.valueListStyle} row center>
                                            <Flex className={styles.svgStyle}>
                                                <SvgClose fill="#ED4857" />
                                            </Flex>
                                            <Text
                                                style={{
                                                    color: '#333333',
                                                    fontSize: '13px',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {list}{' '}
                                            </Text>
                                        </Flex>{' '}
                                    </>
                                );
                            }) : ''}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            {isopensubcription && (
                <SubscriptionModal
                    openmodel={isopensubcription}
                    differece_key={['AI_matching']}
                    setopensubcription={setopensubcription}
                    addon_name={['Resume Matching For Multiple Jobs(AI)']}
                />
            )}
        </>
    );
};

export default MatchingAnalysisbasicTab;