import { createSlice } from '@reduxjs/toolkit';
import {
  ApplicantData,
  ApplicantDataReducerState,
  ApplicantDownloadReducerState,
  ApplicantEntity,
  ApplicantPipeLineReducerState,
  ApplicantUpdateReducerState,
  EmailAutomateReducerState,
  KanbanStageReducerState,
} from '../../applicantPipeLineTypes';
import {
  applicantPipeLineDataMiddleWare,
  applicantPipeLineMiddleWare,
  applicantUpdateStatusMiddleWare,
  deleteKanbanStagesMiddleware,
  downloadApplicantsMiddleware,
  emailAutomationMiddleware,
  getKanbanStagesMiddleWare,
  kanbanUpdateMiddleWare,
  updateKanbanStagesMiddleware,
} from '../middleware/applicantpipelinemiddleware';
import { checkAuthMiddleware } from '../../../applicantprofilemodule/store/middleware/applicantProfileMiddleware';

const applicantPipeLineState: ApplicantPipeLineReducerState = {
  isLoading: false,
  error: '',
  success: false,
  skill_list: [],
  jd_id: '',
  permission: [''],
  job_details: {
    city: '',
    country: '',
    job_role__label_name: '',
    job_title: '',
    state: '',
    job_id: '',
    work_space_type: '',
  },
  zita_match_count: 0,
};

const applicantPipeLineReducer = createSlice({
  name: 'applicantpipe',
  initialState: applicantPipeLineState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(applicantPipeLineMiddleWare.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(applicantPipeLineMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.jd_id = action.payload.jd_id;
      state.skill_list = action.payload.skill_list;
      state.job_details = action.payload.job_details;
      state.success = action.payload.success;
      state.zita_match_count = action.payload.zita_match_count;
    });
    builder.addCase(applicantPipeLineMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

const applicantPipeLineDataState: ApplicantDataReducerState = {
  isLoading: false,
  error: '',
  jd_id: 0,
  // workflow_id: null,
  applicants_list: [],
  applicants: {},
  locations: [],
  params: '',
  fav_id: false,
  google: [],
  outlook: [],
  total_applicant: 0,
  checkauth: null,
  active_resume: [],
  new_apply_count: [],
  comparative: null,
  plan_details: [],
  current_plan: null,
  ai_matched_count: null,
  candidate_location: [],
  candidate_name_mail: [],
};

const getApplicants = (payload: any) => {
  const obj = Object.keys(payload)
    .filter((key) => Array.isArray(payload[key]))
    .reduce((o, key) => ({ ...o, [key]: payload[key] }), {});
  return obj;
};

const getApplicantsByStageId = (list: ApplicantEntity[]) => {
  return list.reduce((o, v) => {
    const stageId = v?.stage_id_id ?? 0;
    return {
      ...o,
      [stageId]: [...(o[stageId] || []), v],
    };
  }, {} as { [key: number]: ApplicantEntity[] });
};

const disablecard = (data, candidatesToFilter) => {
  const filtercard = [...candidatesToFilter];
  var setcan = data.filter(
    (item) => !candidatesToFilter.includes(item.candidate_id_id),
  );
  localStorage.setItem('Id', setcan);

  if (setcan !== undefined && setcan.length > 0) {
    let first_canditate = setcan[0].candidate_id_id;
    filtercard.push(first_canditate);

    if (filtercard.length !== candidatesToFilter.length) {
      const newdatas = data.filter((d) =>
        filtercard.includes(d.candidate_id_id),
      );

      return newdatas;
    }
  } else {
    return data;
  }
};

const applicantPipeLineDataReducer = createSlice({
  name: 'applicantpipeData',
  initialState: applicantPipeLineDataState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(applicantPipeLineDataMiddleWare.pending, (state) => {
      state.isLoading = state.applicants_list.length === 0 ? true : false;
      state.error = '';
    });
    builder.addCase(
      applicantPipeLineDataMiddleWare.fulfilled,
      (state, action) => {
        // const applicantsObj = getApplicantsByStageId(
        //   action.payload.applicants_list || [],
        // );
        const applicantsObj = getApplicantsByStageId(
          disablecard(
            action.payload.applicants_list,
            action.payload.active_resume,
          ) || [],
        );
        state.isLoading = false;
        state.jd_id = action.payload.jd_id;
        state.locations = (action.payload.applicants_list || [])
          .filter((doc) => doc.location !== null)
          .map((doc) => doc.location); /// TODO: dependent on applicants list so locations will be few if few applicants
        state.applicants_list = action.payload.applicants_list || [];
        state.applicants = applicantsObj;
        state.fav_id = action.payload.fav_id;
        state.google = action.payload.google;
        state.outlook = action.payload.outlook;
        state.total_applicant = action.payload.total_applicant;
        state.active_resume = action.payload.active_resume;
        state.new_apply_count = action.payload.new_apply_count;
        state.comparative = action.payload.comparative;
        state.plan_details = action.payload.plan_details;
        state.current_plan = action.payload.current_plan;
        state.ai_matched_count = action.payload.ai_matched_count;
        state.candidate_location = action.payload.candidate_location;
        state.candidate_name_mail = action.payload.candidate_name_mail;
      },
    );
    builder.addCase(
      applicantPipeLineDataMiddleWare.rejected,
      (state, action) => {
        state.isLoading = false;
        if (typeof action.payload === 'string') {
          state.error = action.payload;
        }
      },
    );

    builder.addCase(checkAuthMiddleware.fulfilled, (state, action) => {
      state.checkauth = action.payload;
    });
  },
});

const applicantPipeLineUpdateState: ApplicantUpdateReducerState = {
  isLoading: false,
  error: '',
};

const applicantPipeLineUpdateReducer = createSlice({
  name: 'applicantpipeUpdate',
  initialState: applicantPipeLineUpdateState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(applicantUpdateStatusMiddleWare.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(applicantUpdateStatusMiddleWare.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      applicantUpdateStatusMiddleWare.rejected,
      (state, action) => {
        state.isLoading = false;
        if (typeof action.payload === 'string') {
          state.error = action.payload;
        }
      },
    );

    builder.addCase(kanbanUpdateMiddleWare.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(kanbanUpdateMiddleWare.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(kanbanUpdateMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

const kanbanStagesState: KanbanStageReducerState = {
  isLoading: false,
  error: '',
  selectPipeline: false,
  stages: [],
  update: {
    isLoading: false,
    error: '',
    message: '',
  },
  delete: {
    isLoading: false,
    error: '',
    message: '',
  },
  default_stage: [],
  new_stages: [],
};

const getBooleanValue = (payload: boolean | null) => {
  if (payload === null) return null; /// Open 1st popup
  if (payload === true) return true; /// Open 2nd popup
  return false; /// no popup
};
const kanbanStagesReducer = createSlice({
  name: 'kanbanStages',
  initialState: kanbanStagesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getKanbanStagesMiddleWare.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getKanbanStagesMiddleWare.fulfilled, (state, action) => {
      if (action.payload) {
        state.isLoading = false;
        state.stages = action.payload.stages || action.payload.data || [];
        state.selectPipeline = getBooleanValue(action.payload.select_pipeline);
        state.default_stage = action.payload.default_stage;
        state.new_stages = action.payload.new_stages;
      }
    });
    builder.addCase(getKanbanStagesMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
    // update add case
    builder.addCase(updateKanbanStagesMiddleware.pending, (state) => {
      state.update.isLoading = true;
      state.update.error = '';
    });
    builder.addCase(updateKanbanStagesMiddleware.fulfilled, (state, action) => {
      state.update.isLoading = false;
      state.update.message = action.payload.message;
    });
    builder.addCase(updateKanbanStagesMiddleware.rejected, (state, action) => {
      state.update.isLoading = false;
      if (typeof action.payload === 'string') {
        state.update.error = action.payload;
      }
    });
    // delete add case
    builder.addCase(deleteKanbanStagesMiddleware.pending, (state) => {
      state.delete.isLoading = true;
      state.delete.error = '';
    });
    builder.addCase(deleteKanbanStagesMiddleware.fulfilled, (state, action) => {
      state.delete.isLoading = false;
      state.delete.message = action.payload.message;
    });
    builder.addCase(deleteKanbanStagesMiddleware.rejected, (state, action) => {
      state.delete.isLoading = false;
      if (typeof action.payload === 'string') {
        state.delete.error = action.payload;
      }
    });
  },
});

/// Download reducer

const applicantPipeLineDownloadState: ApplicantDownloadReducerState = {
  isLoading: false,
  error: '',
  filepath: '',
  message: '',
};

const applicantPipeLineDownloadReducer = createSlice({
  name: 'applicantpipeDownload',
  initialState: applicantPipeLineDownloadState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(downloadApplicantsMiddleware.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(downloadApplicantsMiddleware.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filepath = action.payload.filepath;
      state.message = action.payload.message;
    });
    builder.addCase(downloadApplicantsMiddleware.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

const EmailAutomationState: EmailAutomateReducerState = {
  subject: '',
  template: [],
  new_stages: [],
  default_stage: [],
  title: [],
  tags: [],
};

const EmailAutomationReducer = createSlice({
  name: 'emailAutomation',
  initialState: EmailAutomationState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(emailAutomationMiddleware.pending, (state) => {
      state.subject = '';
      state.template = [];
    });
    builder.addCase(emailAutomationMiddleware.fulfilled, (state, action) => {
      state.subject = action.payload.subject;
      state.template = action.payload.template;
      state.new_stages = action.payload.new_stages;
      state.default_stage = action.payload.default_stage;
      state.title = action.payload.title;
      state.tags = action.payload.tags;

    });
    builder.addCase(emailAutomationMiddleware.rejected, (state, action) => {
      if (typeof action.payload === 'string') {
        state.subject = '';
        state.template = [];
      }
    });
  },
});

export const applicantPipeLineReducers = applicantPipeLineReducer.reducer;
export const applicantPipeLineDataReducers =
  applicantPipeLineDataReducer.reducer;
export const applicantPipeLineUpdateReducers =
  applicantPipeLineUpdateReducer.reducer;
export const kanbanStagesReducers = kanbanStagesReducer.reducer;
export const applicantPipelineDownloadReducers =
  applicantPipeLineDownloadReducer.reducer;
export const EmailAutomationReducers = EmailAutomationReducer.reducer;
