import * as React from 'react';
const SvgSetupIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={11}
    fill="none"
    viewBox='0 0 12 11'
    {...props}
  >
    <path stroke="#581845" strokeWidth={0.7} d="M0 1.65h12" />
    <path
      fill="#fff"
      stroke="#581845"
      d="M5.5 1.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
    />
    <path stroke="#581845" strokeWidth={0.7} d="M0 5.65h12" />
    <circle cx={8.5} cy={5.5} r={1} fill="#fff" stroke="#581845" />
    <path stroke="#581845" strokeWidth={0.7} d="M0 9.65h12" />
    <circle cx={3.5} cy={9.5} r={1} fill="#fff" stroke="#581845" />
  </svg>
);
export default SvgSetupIcon;