import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import SvgTriangle from '../../../icons/SvgTriangle';
import SvgInfo from '../../../icons/SvgInfo';

import SvgDashboardicon from '../../../icons/SvgDashboardicon';
import Loader from '../../../uikit/Loader/Loader';
import { getDateString, isEmpty } from '../../../uikit/helper';
import { AppDispatch, RootState } from '../../../store';
import Flex from '../../../uikit/Flex/Flex';
import Tour from '../../tour/tour';
import Highlighter from '../../tour/highlighter';
import Text from '../../../uikit/Text/Text';
import { WARNING } from '../../../uikit/Colors/colors';
import SubscriptionModal from '../../subscriptionmodule/subscriptionmoduleScreen';
import { companyPageInitalMiddleWare } from '../../accountsettingsmodule/store/middleware/accountsettingmiddleware';
import { tourdataget } from '../../tour/store/middleware/middleware';
import SingleButton from '../../common/SingleButton';
import {
  dashboardCalenderMiddleWare,
  dashboardJobMetricsMiddleWare,
  dashboardMessageMiddleWare,
  dashBoardMiddleWare,
} from './store/dashboardmiddleware';
import CalenderCard from './CalenderCard';
import JobMetricsCard from './JobMetricsCard';
import MessageCard from './MessageCard';
import OverallJobActivities from './OverallJobActivities';
import styles from './dashboardscreen.module.css';
import ProfileCard from './ProfileCard';
import AddonDetails from './AddonDetails';

const DashBoardScreen = () => {
  const [isLoader, setLoader] = useState(true);
  const [isLoaderMsg, setLoaderMsg] = useState(true);
  const [isCalLoader, setCalLoader] = useState(true);
  const [currentindex, setcurrentindex] = useState(0);
  const [isOpenPlanDetails, setOpenPlanDetails] = useState(false);
  const [step1, setstep1] = useState(false);
  document.title = 'Dashboard';
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const {
    is_plan,
    events,
    google,
    outlook,
    plan,
    jd_metrics,
    add_on_plans,
    Permission1,
    super_user,
    status,
    explore,
  } = useSelector(
    ({
      permissionReducers,
      dashboardCalenderStateReducers,
      dashboardEmpReducers,
      SubscriptionReducers,
      TourdataReducer,
    }: RootState) => {
      return {
        is_plan: permissionReducers.is_plan,
        events: dashboardCalenderStateReducers.events,
        google: dashboardEmpReducers.google,
        outlook: dashboardEmpReducers.outlook,
        plan: dashboardEmpReducers.plan,
        jd_metrics: dashboardEmpReducers.jd_metrics,
        add_on_plans: SubscriptionReducers.add_on_plans,
        Permission1: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        explore: TourdataReducer.is_first_login,
        status: TourdataReducer.is_steps,
      };
    },
  );

  useEffect(() => {
    dispatch(tourdataget({})).then((res) => {});
    if (status) {
      setstep1(true);
    }
  }, [status]);

  // plan based page redirection condition
  useEffect(() => {
    if (!is_plan) {
      sessionStorage.setItem('MyAccountDropdown', '1');
      history.push('/account_setting/subscription');
    }
  });
  // loop 5 sec once message api
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(dashboardMessageMiddleWare());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // initial api call
  useEffect(() => {
    dispatch(dashBoardMiddleWare()).then((res) => {
      if (res.payload.jd_metrics.length !== 0) {
        dispatch(
          dashboardJobMetricsMiddleWare({
            jd_id: Number(res.payload.jd_metrics[0].id),
          }),
        );
      }
      setLoader(false);
      dispatch(dashboardMessageMiddleWare()).then(() => {
        setLoaderMsg(false);
      });
      dispatch(
        dashboardCalenderMiddleWare({
          date: getDateString(new Date(), 'YYYY-MM-DD'),
        }),
      ).then(() => {
        setCalLoader(false);
      });
    });
    dispatch(companyPageInitalMiddleWare());
  }, []);

  const checkCalendarGoogle = Array.isArray(google);
  const checkCalendarOutlook = Array.isArray(outlook);

  const checkCalendar =
    checkCalendarGoogle === true || checkCalendarOutlook === true
      ? true
      : false;
  const manageUser = () => {
    return window.location.replace(
      window.location.origin + '/account_setting/subscription?planFocus=focus',
    );
  };

  const upgradeplan = () => {
    setOpenPlanDetails(true)
  }
  const getFreeValue = localStorage.getItem('freeCheck');
  const flexHeight = jd_metrics.length !== 0 ? 70 : 60;
  if (isLoader || isLoaderMsg || isCalLoader) {
    return <Loader />;
  }

  return (
    //     <Flex className={styles.overAll} height={window.innerHeight - flexHeight} >
    //       <Flex row center>

    //         <div style={{ display: 'flex' }}>
    //           {isEmpty(getFreeValue) &&
    //             plan &&
    //             plan.plan_id_id === 1 &&
    //             plan.subscription_remains_days <= 7 && (
    //               <Flex columnFlex>
    //                 <Flex middle row center className={styles.warningFlex}>
    //                   <SvgInfo fill={WARNING} />
    //                   <Text
    //                     size={12}
    //                     bold
    //                     color="warning"
    //                     className={styles.warningText}
    //                   >
    //                     {`Your free trial ends in ${plan.subscription_remains_days
    //                       } ${plan.subscription_remains_days === 1 ? 'day' : 'days'
    //                       }. Please `}
    //                     <Text size={12} bold color="link" onClick={manageUser}>
    //                       upgrade{' '}
    //                     </Text>
    //                     to a paid plan to get uninterrupted access and enjoy more
    //                     zita platform features along with your branded careers page.
    //                   </Text>
    //                 </Flex>
    //               </Flex>
    //             )}
    //         </div>
    //       </Flex>

    //     <Flex >

    //         <Flex row className={styles.ribbon}>
    //           <Flex row between>

    //             <Flex  marginTop={9} marginLeft={8} >
    //                 <Text size={16}  bold color="theme" >
    //                    Dashboard
    //                 </Text>

    //              </Flex>
    //           <Flex marginLeft={1395}>

    //              <div className={styles.triangle}></div>
    //           </Flex>
    //           </Flex>
    //         </Flex>

    //           <Flex row>
    //             <Flex >
    //               <ProfileCard></ProfileCard>
    //             </Flex>
    //             <Flex  flex={6}>
    //               <Flex row>
    //               <Flex marginLeft={5} marginTop={5} flex={1}>
    //                 <OverallJobActivities></OverallJobActivities>
    //               </Flex>

    //               <Flex marginLeft={5} marginRight={5} marginTop={5} flex={5}>
    //                 <MessageCard></MessageCard>
    //               </Flex>
    //               </Flex>

    //               <Flex flex={6} marginLeft={5} marginTop={5}>
    //                 <CalenderCard
    //                   events={events}
    //                   checkCalendar={checkCalendar}
    //                   checkCalendarOutlook={checkCalendarOutlook}
    //                   google={google}
    //                   outlook={outlook}
    //                 />
    //               </Flex>
    //             </Flex>
    //           </Flex>
    //           <Flex marginLeft={5} marginTop={5} marginRight={5} marginBottom={5}>
    //             <JobMetricsCard />
    //           </Flex>

    //       </Flex>

    //     </Flex>
    //   );
    // };
    <> 
      {status && (
        <Highlighter
          right={"121px"}
          top={"16px"}
          icon
        />
      )}
      {step1 &&
        <Tour
          process={() => {
            if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '1/9';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '1/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '1/7';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '1/8';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '1/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post')
            ) {
              return '1/6';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '1/7';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '1/6';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '1/7';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '1/6';
            } else if (
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '1/6';
            } else if (Permission1.includes('manage_account_settings')) {
              return '1/5';
            } else if (Permission1.includes('create_post')) {
              return '1/5';
            } else if (Permission1.includes('bulkImport_candidates')) {
              return '1/6';
            } else if (Permission1.includes('talent_sourcing')) {
              return '1/5';
            } else {
              return '1/4';
            }
            return null;
          }}
          skiponclick={() => {
            setstep1(false);
            setcurrentindex(1);
            sessionStorage.setItem('JobsDropdown', '0');
            sessionStorage.setItem('ViewJobsDropdown', '0');
            sessionStorage.setItem('CandidateDropdown', '0');
            sessionStorage.setItem('CommunicationDropdown', '0');
            sessionStorage.setItem('BrandDropdown', '0');
            sessionStorage.setItem('MyAccountDropdown', '1');
            sessionStorage.setItem('SourcingCandidateDropdown', '0')
            history.push("/account_setting/profiles")
            dispatch(tourdataget({ skip_id: 1, restart_status: false, explore: 0 }))
          }}
          nextonclick={() => {
            sessionStorage.setItem('JobsDropdown', '0');
            sessionStorage.setItem('ViewJobsDropdown', '0');
            sessionStorage.setItem('CandidateDropdown', '0');
            sessionStorage.setItem('CommunicationDropdown', '0');
            sessionStorage.setItem('BrandDropdown', '0');
            sessionStorage.setItem('MyAccountDropdown', '1');
            sessionStorage.setItem('SourcingCandidateDropdown', '0')
            setstep1(false)
            history.push("/account_setting/profiles")
          }}
          borderRight
          types={"arrowTopRight"}
          top={"50px"}
          right={"95px"}
          // text={"<div>Questions are the keys to clarity.</div> 'Help' and 'Knowledge Base' are your go-to resources for support and learning. Think of it as your personal library of articles, tutorials, and access to our customer support team—ready to assist you whenever you need."}
          text={'<div style="font-weight: bold; margin-bottom: 15px;">Questions are the keys to clarity.</div> <a style="font-weight: bold;color:#581845" target="_blank" href="https://share.hsforms.com/1_3IRNtXMTI6Xa3AGdP_pVwqmqrf">\'Help\'</a> and <a style="font-weight: bold;color:#581845" target="_blank" href="https://www.zita.ai/knowledgebase">\'Knowledge Base\'</a> are your go-to resources for support and learning. <div style="margin-top:10px;">Think of it as your personal library of articles, tutorials, and access to our customer support team—ready to assist you whenever you need.</div>'}
        />}
      <Flex row className={styles.ribbon} marginBottom={5} between>
        <Flex marginTop={9} marginLeft={8}>
          <Text size={16} bold color="theme">
            Dashboard
          </Text>
        </Flex>
        <Flex>
          <div className={styles.triangle}></div>
        </Flex>
      </Flex>
      <Flex className={styles.scroll}>
        <Flex className={styles.overAll}>
          <Flex row center>
            <div style={{ display: 'flex', marginBottom: '5px' }}>
              {isEmpty(getFreeValue) &&
                plan &&
                plan.plan_id_id === 1 &&
                plan.subscription_remains_days <= 7 && (
                  <Flex row center className={styles.warningFlex}>
                    {/* <Flex  row center > */}
                    <SvgInfo height={16} width={16} fill={'#2E6ADD'} />
                    <Text
                      style={{ color: '#333333' }}
                      className={styles.warningText}
                    >
                      <Text
                        style={{
                          color: '#2E6ADD',
                          marginRight: '3px',
                          fontSize: '13px',
                        }}
                        bold
                      >
                        Heads Up!{' '}
                      </Text>
                      {`Your free trial ends in ${plan.subscription_remains_days
                        } ${plan.subscription_remains_days === 1 ? 'day' : 'days'
                        }. Please `}
                      <Text size={13} bold color="link" onClick={super_user === true ? manageUser : upgradeplan}>
                        upgrade{' '}
                      </Text>
                      to a paid plan to get uninterrupted access and enjoy more
                      zita platform features along with your branded careers
                      page.
                    </Text>

                    {/* </Flex> */}
                  </Flex>
                )}
            </div>
          </Flex>

          <Flex row flex={12}>
            <Flex flex={5}>
              <ProfileCard></ProfileCard>
            </Flex>
            <Flex flex={12}>
              <Flex row>
                <Flex marginLeft={5} flex={5}>
                  <OverallJobActivities></OverallJobActivities>
                </Flex>

                <Flex marginLeft={5} marginRight={5} flex={15}>
                  <MessageCard></MessageCard>
                </Flex>
              </Flex>

              <Flex row marginTop={3} marginLeft={5}>
                <Flex flex={15}>
                  <CalenderCard
                    events={events}
                    checkCalendar={checkCalendar}
                    checkCalendarOutlook={checkCalendarOutlook}
                    google={google}
                    outlook={outlook}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <SingleButton
            btnTitle="OK"
            title={'Please contact your company admin to upgrade you plan.'}
            open={isOpenPlanDetails}
            btnOnclick={() => setOpenPlanDetails(false)}
          />
          {plan.plan_id_id !== 1 ? (
            <Flex>
              <AddonDetails />
            </Flex>
          ) : (
            ''
          )}
          <Flex marginBottom={5}>
            <JobMetricsCard />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default DashBoardScreen;
