import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import classNames from 'classnames/bind';
import ReactQuill from 'react-quill';
import StarsRating from 'react-star-rate';
import Loader from '../../uikit/Loader/Loader';
import Modal from '../../uikit/Modal/Modal';
import Button from '../../uikit/Button/Button';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import { ErrorMessage } from '../../uikit';
import { isEmpty } from '../../uikit/helper';
import { AppDispatch, RootState } from '../../store';
import { InterviewScorecardApi } from '../../routes/apiRoutes';
import SvgClose from '../../icons/SvgClose';
import SvgMeetingicon from '../../icons/SvgMeetingicon';
import {
  CANCEL,
  THIS_FIELD_REQUIRED,
  config,
  mentionnotes,
  mentionspecialcharacter,
} from '../constValue';
import {
  applicantScoreMiddleWare,
} from './store/middleware/applicantProfileMiddleware';

import styles from './interviewscorecardtab.module.css';
import InterviewScorecard from './interviewScorecard';
import {
  Question,
} from './interviewerQuestionType';
import { interviewQuestionMiddleware } from './store/middleware/interviewquestionMiddleware';


const cx = classNames.bind(styles);
var querystring = require('querystring');

interface Props {
  onEvaluate?: (id: number, value: Question[]) => void;
  UpdateEvaluate?: (val: any) => void;
  opencomments?: boolean;
  setopencomment?: (val: any) => void;
  setinterviewbasic?: (val: any) => void;
  isinterviewbasic?: boolean;
  setdisabled?: (val: any) => void;
  isdisabled?: boolean;
  setinterviewbasicevaluate?: (Val: any) => void;
  isinterviewbasicevaluate?: boolean;
}

const InterviewScorecardTab: React.FC<Props> = (props) => {

  const { onEvaluate,
    UpdateEvaluate,
    opencomments,
    setopencomment,
    setinterviewbasic,
    isinterviewbasic,
    setdisabled,
    isdisabled,
    setinterviewbasicevaluate,
    isinterviewbasicevaluate } = props;

  const dispatch: AppDispatch = useDispatch();
  const [transformedData, setTransformedData] = useState([]);
  const [isgettingbasicdata, setgettingbasicdata] = useState<any>();
  const [isbasicuser, setbasicuser] = useState<any>();
  const [isPostLoader, setPostLoader] = useState(false);
  const [isshowerror, setshowerror] = useState(false);
  const [iserror, seterror] = useState(false);
  const [isOpen, setOpen] = useState(true);
  const [buttonName, setButtonName] = useState('Add');
  const [isColor, setColor] = useState<string[]>([]);
  const [rating1, setrating1] = useState(0);
  const [rating2, setrating2] = useState(0);
  const [rating3, setrating3] = useState(0);
  const [rating4, setrating4] = useState(0);
  const [rating5, setrating5] = useState(0);
  const [roundedValues, setroundedValue] = useState(0);
  const [tooltip, settooltip] = useState('');
  const [reaction, setreaction] = useState('');
  const [reactions, setreactions] = useState('');
  const [isaverage, setaverage] = useState(0);
  const [isuser, setuser] = useState(false);

  const {
    can_id,
    jd_id,
    interview,
    interviews,
    overall,
    user,
    no_of_interview,
    cumulative,
    results,
    dataset,
    Permission,
    cumulativebasic,
    user_overview,
    result
  } = useSelector(
    ({
      applicantProfileInitalReducers,
      applicantScoreReducers,
      interviewerQuestionReducers,
      applicantUserlistReducer,
      userProfileReducers,
      permissionReducers
    }: RootState) => {
      return {
        user: applicantScoreReducers.user,
        can_id: applicantProfileInitalReducers.can_id,
        jd_id: applicantProfileInitalReducers.jd_id,
        interviews: interviewerQuestionReducers.interviews,
        results: interviewerQuestionReducers.result,
        no_of_interview: interviewerQuestionReducers.no_of_interview,
        cumulative: interviewerQuestionReducers.cumulative,
        overall: applicantScoreReducers.overall,
        dataset: interviewerQuestionReducers.data,
        user_overview: interviewerQuestionReducers.user_overview,
        result: applicantScoreReducers.result,
        cumulativebasic: applicantScoreReducers.cumulative,
        interview:
          typeof applicantScoreReducers.interview !== 'undefined' &&
            applicantScoreReducers.interview.length === 0
            ? [
              {
                candidate_id_id: 0,
                jd_id_id: 0,
                rating: 0,
                img_name: '',
                first_name: '',
                comments: '',
                created_at: '',
                last_name: '',
                rating1: 0,
                rating2: 0,
                rating3: 0,
                rating4: 0,
                rating5: 0,
                overall_percentage: 0,
                user_id: 0,
                user: 0,
              },
            ]
            : applicantScoreReducers.interview,
        Permission: permissionReducers.data,
      };
    },
  );

  // Get Average for the scorecard based on both basic and ai
  useEffect(() => {
    const Basicscorecardaverage = results?.total_avg;
    const Aiscorecardaverage = result?.total_avg;
    if (Basicscorecardaverage !== null && Aiscorecardaverage !== null) {
      setaverage((Basicscorecardaverage + Aiscorecardaverage) / 2);
    } else if (Basicscorecardaverage !== null) {
      setaverage(Basicscorecardaverage);
    } else if (Aiscorecardaverage !== null) {
      setaverage(Aiscorecardaverage);
    } else {
      setaverage(null);
    }

  }, [results?.total_avg, result?.total_avg, results, result])


  // setting the basic evaluated form data based on the onclick event.
  useEffect(() => {
    if (isgettingbasicdata !== undefined && isgettingbasicdata !== '') {
      if (isbasicuser !== undefined && isbasicuser !== '') {
        const data = isgettingbasicdata?.attendees?.find(e => e.user_id === isbasicuser)
        setrating1(data?.rating1);
        setrating2(data?.rating2);
        setrating3(data?.rating3);
        setrating4(data?.rating4);
        setrating5(data?.rating5);
        formik.setFieldValue('userMessage', data?.commend);
        setopencomment(true);
        setButtonName('Update');
      }
      if (isbasicuser === '') {
        const data = isgettingbasicdata?.attendees?.find(e => e.user_id === user)
        setrating1(data?.rating1);
        setrating2(data?.rating2);
        setrating3(data?.rating3);
        setrating4(data?.rating4);
        setrating5(data?.rating5);
        formik.setFieldValue('userMessage', data?.commend);
        setopencomment(true);
        setButtonName('Update');
      }
    }

  }, [isgettingbasicdata, isbasicuser])

  useEffect(() => {
    dispatch(applicantScoreMiddleWare({ jd_id, can_id }));
  }, []);

  // clearing the data when clicking the basic evaluation open or close.
  useEffect(() => {
    if (isgettingbasicdata === undefined || isgettingbasicdata === '') {
      formik.resetForm();
      setrating1(0);
      setrating2(0);
      setrating3(0);
      setrating4(0);
      setrating5(0);
      formik.setFieldValue('userMessage', '');
      setreactions('');
      setButtonName('Add');
    }
  }, [opencomments, isgettingbasicdata, isinterviewbasicevaluate])


  // function and useEffect for getting data of basic evaluated data.
  const uniqueInterviewIds = Array.from(new Set(cumulativebasic && cumulativebasic.map(item => item.interview_id)));
  useEffect(() => {
    const newData = uniqueInterviewIds.map(x => {
      return cumulativebasic.reduce((accumulator, currentItem) => {
        if (currentItem.interview_id === x) {
          const existingEntry = accumulator.find(entry => entry.id === currentItem.interview_id);
          if (existingEntry) {
            // If entry already exists, add the attendee data to the existing entry
            existingEntry.attendees.push({
              jd_id_id: currentItem.jd_id_id,
              user_id: currentItem.user_id,
              rating1: currentItem.rating1,
              rating2: currentItem.rating2,
              rating3: currentItem.rating3,
              rating4: currentItem.rating4,
              rating5: currentItem.rating5,
              overall_score: currentItem.overall_percentage,
              commend: currentItem.commands,
              full_name: currentItem.full_name
            });
            existingEntry.overall_score += currentItem.overall_percentage;
          } else {
            // If entry does not exist, create a new entry
            accumulator.push({
              id: currentItem.interview_id,
              type: currentItem.interview_id__event_type,
              event_type: currentItem.interview_id__event_type,
              e_time: currentItem.interview_id__e_time,
              s_time: currentItem.interview_id__s_time,
              overall_score: currentItem.overall_percentage,
              attendees: [
                {
                  jd_id_id: currentItem.jd_id_id,
                  user_id: currentItem.user_id,
                  rating1: currentItem.rating1,
                  rating2: currentItem.rating2,
                  rating3: currentItem.rating3,
                  rating4: currentItem.rating4,
                  rating5: currentItem.rating5,
                  overall_score: currentItem.overall_percentage,
                  commend: currentItem.commands,
                  full_name: currentItem.full_name
                },
              ],
            });
          }
        }
        return accumulator;
      }, []);
    });

    setTransformedData(newData);
  }, [cumulativebasic]);
  const ratingValue =
    interview && interview[0].rating
      ? interview[interview.length - 1].rating
      : 0;
  const [rating, setRating] = useState(ratingValue);

  // color code for profile background-color
  useEffect(() => {
    const colorCode = [
      '#d08014',
      '#d04343',
      '#db1f77',
      '#c0399f',
      '#6367de',
      '#286eb4',
      '#0f828f',
      '#7ca10c',
      '#925ace',
      '#647987',
    ];

    setColor(colorCode);
  }, []); 
  // dispatching the data to API
  const hanldeSubmit = () => {
    if (
      rating1 === 0 &&
      rating2 === 0 &&
      rating3 === 0 &&
      rating4 === 0 &&
      rating5 === 0
    ) {
      seterror(true);
    }
    else {
      setPostLoader(true);
      const data = querystring.stringify({
        jd_id,
        can_id,
        rating,
        rating1,
        rating2,
        rating3,
        rating4,
        rating5,
        roundedValues,
        interview_id: isinterviewbasic,
        comments: formik.values.userMessage,
      });

      axios
        .post(InterviewScorecardApi, data, config)
        .then((res) => {
          setinterviewbasicevaluate(false)
          formik.resetForm();
          setrating1(0);
          setrating2(0);
          setrating3(0);
          setrating4(0);
          setrating5(0);
          formik.setFieldValue('userMessage', '');
          setreactions('');
          setOpen(false);
          setPostLoader(false);
          dispatch(applicantScoreMiddleWare({ jd_id, can_id })).then(() => {
            if (res.data.success) {

              dispatch(
                interviewQuestionMiddleware({
                  can_id: can_id,
                  jd_id: jd_id,
                }),
              );
            }
          })
        })
        .catch(() => {
          setPostLoader(false);
        });
      setopencomment(false);
    };
  }

  // field validation for comment section in basic evaluation form
  type notes = {
    userMessage: string;
  };
  const parser = new DOMParser();
  const handlemessage = (values: notes) => {
    const errors: Partial<notes> = {};
    const doc = parser.parseFromString(formik.values.userMessage, 'text/html');
    const textNodes = doc.querySelectorAll('body')[0].textContent;
    const texttrim = textNodes.trim();
    if (isEmpty(texttrim)) {
      errors.userMessage = '';
    }
    if (texttrim === '') {
      errors.userMessage = THIS_FIELD_REQUIRED;
    } else if (
      texttrim?.replace(/\s+/g, '')?.length > 2000
    ) {
      errors.userMessage = 'Message length should not exceed 2000 characters.';
    }
    // if (
    //   rating1 === 0 &&
    //   rating2 === 0 &&
    //   rating3 === 0 &&
    //   rating4 === 0 &&
    //   rating5 === 0
    // ) {
    //   errors.userstar = 'This Field is required'
    // }
    // else {
    //   errors.userstar = ''
    // }
    return errors;
  };


  const formik = useFormik({
    initialValues: {
      userMessage:
        interview && interview[0].comments ? interview[0].comments : '',
    },
    onSubmit: hanldeSubmit,
    validate: handlemessage,
  });

  //cancel function for basic ealuation form.
  const handleCancel = () => {
    setgettingbasicdata('');
    setinterviewbasicevaluate(false);
    setdisabled(false);
    if (isEmpty(interview && interview[0].comments)) {
      setOpen(true);
      formik.setFieldValue('userMessage', '');
    }
    if (!isEmpty(interview && interview[0].comments)) {
      setOpen(false);
    }
    setopencomment(false);
    formik.resetForm();
  };

  // they function for all five star value in set state.
  const handleStar1 = (e) => {
    setrating1(e);
  };
  const handleStar2 = (e) => {
    setrating2(e);
  };
  const handleStar3 = (e) => {
    setrating3(e);
  };
  const handleStar4 = (e) => {
    setrating4(e);
  };
  const handleStar5 = (e) => {
    setrating5(e);
  };

  useEffect(() => {
    if (isaverage <= 1 && isaverage > 0) {
      setreaction('"Poor"');
    }
    if (isaverage > 1 && isaverage <= 2) {
      setreaction('"Below Average"');
    }
    if (isaverage > 2 && isaverage <= 3) {
      setreaction('"Average"');
    }
    if (isaverage > 3 && isaverage <= 4) {
      setreaction('"Above Average"');
    }
    if (isaverage > 4 && isaverage <= 5) {
      setreaction('"Outstanding"');
    }
  }, [isaverage]);
  useEffect(() => {
    if (roundedValues <= 1 && roundedValues > 0) {
      setreactions('"Poor"');
    }
    if (roundedValues > 1 && roundedValues <= 2) {
      setreactions('"Below Average"');
    }
    if (roundedValues > 2 && roundedValues <= 3) {
      setreactions('"Average"');
    }
    if (roundedValues > 3 && roundedValues <= 4) {
      setreactions('"Above Average"');
    }
    if (roundedValues > 4 && roundedValues <= 5) {
      setreactions('"Outstanding"');
    }
  }, [roundedValues]);


  // Average the count for basic evalution of applicant
  useEffect(() => {
    const ratings = [rating1, rating2, rating3, rating4, rating5];
    const nonZeroRatings = ratings.filter(rat => rat !== 0);
    const sum = nonZeroRatings.reduce((acc, rats) => acc + rats, 0);
    const average = nonZeroRatings.length > 0 ? sum / nonZeroRatings.length : 0;
    setroundedValue(average);
  }, [rating1, rating2, rating3, rating4, rating5]);


  useEffect(() => {
    if (interview !== undefined && user !== undefined) {
      if (interview.map((inter) => inter.user_id === user)) {
        setuser(true);
      } else {
        setuser(false);
      }
    }
  }, [user]);

  // Check if iserror is true and any of the ratings is 0
  useEffect(() => {
    if (
      rating1 === 0 &&
      rating2 === 0 &&
      rating3 === 0 &&
      rating4 === 0 &&
      rating5 === 0
    ) {
      seterror(true);
    }
    else{
      seterror(false);
    }
  }, [iserror, rating1, rating2, rating3, rating4, rating5]); 

  return (
    <>
      <Flex row flex={12}>
        <Flex
          flex={6}
          columnFlex
          className={styles.overAll}
        >
          <Text bold style={{ fontSize: '14px' }}>
            Interview Scorecard
          </Text>
          <Text className={styles.addText}>
            {"Interviewer's overall ratings for each interview"}.
          </Text>

          <Flex center middle className={styles.starstylehead} marginTop={-9}>
            <StarsRating disabled count={5} value={isaverage} />
            <Flex center middle marginTop={20} marginBottom={5}>
              <Text color='theme' style={{ marginTop: "15px" }}>{reaction}</Text>
            </Flex>
          </Flex>
          {(no_of_interview?.length !== 0 || transformedData?.length !== 0) ?
            <Flex
              style={{ overflow: 'scroll', paddingRight: '5px' }}
              maxHeight={window.innerHeight - 225}
            >
              <InterviewScorecard
                onEvaluate={onEvaluate}
                UpdateEvaluate={UpdateEvaluate}
                cumulative={cumulative}
                no_of_interview={no_of_interview}
                transformedDatas={transformedData}
                datas={dataset}
                user={user}
                setdisabled={setdisabled}
                setopencomment={setopencomment}
                setgettingbasicdata={setgettingbasicdata}
                setbasicuser={setbasicuser}
                setinterviewbasic={setinterviewbasic}
                user_overview={user_overview}
              />
            </Flex>
            :
            <Flex
              style={{ overflow: 'scroll', paddingRight: '5px', display: 'flex' }}
              height={window.innerHeight - 225}
              center
              middle
              marginTop={-20}
            >
              <Flex>
                <SvgMeetingicon />
              </Flex>
              <Flex marginTop={10}>
                <Text style={{ color: '#979797' }}
                  size={13}
                  color="gray">No Interviews Conducted - Yet!</Text>
              </Flex>
              <Flex>
                <Text style={{ color: '#979797', width: '420px' }} size={13} color="gray">
                  It seems that no interviews have been conducted for this applicant. Don&apos;t worry, you can initiate the interview process by scheduling interviews.
                </Text>
              </Flex>
            </Flex>}
        </Flex>
      </Flex>
      <Modal open={opencomments}>
        <Flex
          style={{ backgroundColor: '#ffffff' }}
          className={styles.overallmodal}
        >
          {isdisabled &&
            <Flex
              end
              style={{ cursor: 'pointer' }}
              onClick={() => { setopencomment(false); handleCancel(); }}
            >
              <SvgClose
                width={10}
                height={10}
                fill={'#888888'}
                cursor={'pointer'}
              />
            </Flex>}
          <Flex row>
            <Flex flex={6}>
              <Text bold size={14}>
                Overall Rating
              </Text>
              <Text size={13}>Average of all the team members</Text>
              <Flex
                center
                middle
                marginTop={30}
                height={40}
                className={styles.starsizes}
              >
                <StarsRating
                  value={roundedValues}
                  disabled
                  count={5}
                />
                <Flex center middle marginTop={25}>
                  <Text>{reactions}</Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              style={{
                border: '0.3px solid #C3C3C3',
                width: '0.3px',
                margin: '0px 5px 10px',
                paddingBottom: '10px',
                height: '175px',
              }}
            ></Flex>
            <Flex flex={6.4} marginLeft={10}>
              <Text bold size={14}>
                Scoring Categories
              </Text>
              <Text size={13}>
                Rate the candidate based on following aspects
              </Text>
              <Flex marginTop={10}>
                <Flex
                  row
                  center
                  width={350}
                  height={25}
                  disabled={isdisabled}
                >
                  <Flex width={100}>
                    <Text size={13} color="theme">
                      General:
                    </Text>
                  </Flex>
                  <Flex className={styles.starsize}>
                    <StarsRating
                      value={rating1}
                      onChange={handleStar1}
                      count={5}
                      disabled={isdisabled}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex marginRight={10}>
                <Flex
                  row
                  center
                  width={350}
                  height={25}
                  className={styles.starsize}
                  disabled={isdisabled}
                >
                  <Flex width={100}>
                    <Text size={13} color="theme">
                      IQ Based:
                    </Text>
                  </Flex>
                  <StarsRating
                    value={rating2}
                    onChange={handleStar2}
                    disabled={isdisabled}
                    count={5}
                  />
                </Flex>
              </Flex>
              <Flex marginRight={10}>
                <Flex
                  row
                  center
                  width={350}
                  height={25}
                  className={styles.starsize}
                  disabled={isdisabled}
                >
                  <Flex width={100}>
                    <Text size={13} color="theme">
                      Technical:
                    </Text>
                  </Flex>
                  <StarsRating
                    value={rating3}
                    onChange={handleStar3}
                    disabled={isdisabled}
                    count={5}
                  />
                </Flex>
              </Flex>
              <Flex marginRight={10}>
                <Flex
                  row
                  center
                  width={350}
                  height={25}
                  className={styles.starsize}
                  disabled={isdisabled}
                >
                  <Flex width={100}>
                    <Text size={13} color="theme">
                      Coding:
                    </Text>
                  </Flex>
                  <StarsRating
                    value={rating4}
                    disabled={isdisabled}
                    onChange={handleStar4}
                    count={5}
                  />
                </Flex>
              </Flex>
              <Flex>
                <Flex
                  row
                  center
                  width={350}
                  height={25}
                  className={styles.starsize}
                  disabled={isdisabled}
                >
                  <Flex width={100}>
                    <Text size={13} color="theme">
                      Behavioral:
                    </Text>
                  </Flex>
                  <StarsRating
                    value={rating5}
                    disabled={isdisabled}
                    onChange={handleStar5}
                    count={5}
                  />
                </Flex>
              </Flex> 
               {isshowerror && iserror &&<Flex>
                <Text color="error" size={12}>
                  {'This Field is required'}
                </Text>
              </Flex>} 
            </Flex>

          </Flex>

          <Flex marginTop={10}>
            <Text bold size={14}>
              Comments/Feedback*
            </Text>
            {!isdisabled ?
              <Flex className={styles.textArea} disabled={isdisabled}>
                <ReactQuill
                  value={formik?.values?.userMessage}
                  className={styles.reactquillchange}
                  onChange={!isdisabled && formik.handleChange('userMessage')}
                  placeholder="Add your feedback here"
                />
                <ErrorMessage
                  touched={formik.touched}
                  errors={formik.errors}
                  name="userMessage"
                />
              </Flex> : <Flex
                style={{
                  flexWrap: 'wrap',
                  overflow: ' hidden',
                  textOverflow: 'clip',
                  fontSize: 13,
                }}
              >
                <td
                  className={styles.notesTextStyle}
                  dangerouslySetInnerHTML={{
                    __html: formik?.values?.userMessage,
                  }}
                />
              </Flex>}
            {!isdisabled &&
              <Flex row center end columnFlex className={styles.btnConatiner}>
                <Button types="close" onClick={() => { setopencomment(false); handleCancel(); setshowerror(false); }}>
                  {CANCEL}
                </Button>
                <Button
                  className={styles.addBtn}
                  onClick={() => {
                    if (!isdisabled) {
                      formik.handleSubmit();
                    }
                    setshowerror(true);
                  }}
                  disabled={isdisabled}
                >
                  {buttonName}
                </Button>
                {isPostLoader && (
                  <div style={{ marginLeft: 8 }}>
                    <Loader withOutOverlay size="small" />
                  </div>
                )}
              </Flex>}
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default InterviewScorecardTab;
