import * as React from 'react';
const SvgMyAccount = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#581845"
      d="M6.448 10.479v1.612a4.836 4.836 0 0 0-4.836 4.836H0a6.448 6.448 0 0 1 6.448-6.448Zm0-.806a4.835 4.835 0 0 1-4.836-4.837A4.835 4.835 0 0 1 6.448 0a4.835 4.835 0 0 1 4.837 4.836 4.835 4.835 0 0 1-4.837 4.837Zm0-1.613a3.223 3.223 0 1 0 0-6.448 3.223 3.223 0 1 0 0 6.448Zm2.092 6.296a2.825 2.825 0 0 1 0-1.307l-.8-.462.807-1.396.8.462a2.82 2.82 0 0 1 1.132-.655v-.922h1.612v.922c.429.128.815.355 1.132.655l.8-.462.806 1.396-.799.462c.102.43.102.878 0 1.308l.799.461-.806 1.396-.8-.461c-.32.304-.709.528-1.132.654v.923h-1.612v-.923a2.817 2.817 0 0 1-1.133-.654l-.8.461-.806-1.396.8-.461Zm2.745.556a1.209 1.209 0 1 0 0-2.418 1.209 1.209 0 0 0 0 2.418Z"    />
  </svg>
);
export default SvgMyAccount;