const SvgNomessage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={20}
    fill="none"
    viewBox="0 0 29 20"
    {...props}
  >
    <path
      fill="#666"
      d="M9.2 20a.938.938 0 0 1-.679-.297 1.046 1.046 0 0 1-.283-.717v-3.185h-5.02a3.137 3.137 0 0 1-2.274-.997A3.5 3.5 
      0 0 1 0 12.402V3.399c0-.9.34-1.765.944-2.402A3.137 3.137 0 0 1 3.218 0h14.187c.853.001 1.67.36 2.274.996a3.5 3.5 0 
      0 1 .944 2.402v3.077a.706.706 0 0 1-.19.484.632.632 0 0 1-.46.201.632.632 0 0 1-.458-.2.705.705 
      0 0 1-.19-.485V3.398c0-.537-.203-1.053-.563-1.433a1.872 1.872 0 0 
      0-1.357-.595H3.218c-.51.001-.997.215-1.357.595s-.563.896-.563 1.433V12.4c0 .538.203 1.053.563 
      1.434.36.38.848.594 1.357.594h5.357c.255 0 .5.107.68.298.18.19.281.448.281.717v2.683l3.221-3.402a.954.954 
      0 0 1 .312-.22.913.913 0 0 1 .368-.077h1.129c.172 0 .337.072.458.2.122.13.19.304.19.485a.705.705 
      0 0 1-.19.485.632.632 0 0 1-.458.2h-.99L9.88 19.701a.956.956 0 0 1-.311.221A.913.913 0 0 1 9.2 20Z"
    />
    <path
      fill="#666"
      d="M14.466 8.584h-.058a.632.632 0 0 1-.46-.2.706.706 0 0 1-.19-.485c0-.182.07-.356.19-.484a.632.632 
      0 0 1 .46-.201h.059c.172 0 .337.072.458.2.122.13.19.303.19.485a.705.705 0 0 1-.19.485.632.632 0 
      0 1-.459.2Zm-4.158 0h-.059a.632.632 0 0 1-.459-.2.705.705 0 0 1-.19-.485c0-.182.069-.356.19-.484a.632.632 
      0 0 1 .46-.201h.058c.172 0 .337.072.458.2.122.13.19.303.19.485a.705.705 0 0 1-.19.485.632.632 
      0 0 1-.458.2Zm-4.16 0H6.09a.632.632 0 0 1-.459-.2.705.705 0 0 1-.19-.485c0-.182.069-.356.19-.484a.632.632 
      0 0 1 .46-.201h.058c.172 0 .337.072.459.2.121.13.19.303.19.485a.705.705 0 0 1-.19.485.632.632 
      0 0 1-.46.2ZM22.84 20a.803.803 0 0 1-.584-.255l-2.012-2.125h-2.052a2.052 2.052 0 0 1-1.487-.652 2.29 2.29 
      0 0 1-.617-1.57v-5.1c0-.59.222-1.154.617-1.57a2.05 2.05 0 0 1 1.487-.652h8.037c.558 
      0 1.093.235 1.487.651.395.417.617.982.617 1.57v5.1a2.29 2.29 0 0 
      1-.617 1.571c-.394.417-.929.651-1.487.652h-2.563v1.508a.914.914 0 0 1-.242.617.827.827 0 0 
      1-.268.189.788.788 0 0 1-.317.066ZM18.191 9.446a.786.786 0 0 0-.57.25.877.877 0 0 
      0-.236.602v5.1c0 .226.085.442.236.602.151.16.356.25.57.25h2.248c.108 
      0 .216.021.316.065s.191.109.268.19l1.345 1.42v-.805c0-.23.087-.453.242-.616a.805.805 
      0 0 1 .583-.256h3.035c.214 0 .419-.09.57-.25a.877.877 0 0 0 .237-.602v-5.1a.877.877 0 0 
      0-.237-.602.786.786 0 0 0-.57-.25l-8.037.002Z"
    />
  </svg>
);
export default SvgNomessage;