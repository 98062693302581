import * as React from 'react';
const SvgThumbsneutral = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#F29111"
      d="M7.234 1.91c-.147.39-.426 2.509-.59 3.878a20.906 20.906 0 0 0-3.614-.342 2.503 2.503 0 0 0-2.52 2.48 2.503 2.503 0 0 0 2.48 2.52c1.875.015 2.732.688 3.686 1.646a2.99 2.99 0 0 0-1.218 2.374 3.004 3.004 0 0 0 2.975 3.024l6 .048a3.004 3.004 0 0 0 3.024-2.976 2.971 2.971 0 0 0-.736-1.97l.194-.187c.744-.954 1.589-3.654 1.606-5.835.015-1.88-.272-2.61-.527-3.258l-.12-.317c-.298-.836-1.214-1.585-2.186-1.753L9.72.528C8.684.346 7.59.954 7.234 1.91Zm8.223 12.636a1 1 0 0 1-1.008.992l-6-.048a1.001 1.001 0 0 1 .016-2l6 .048c.552.005.997.457.992 1.008Zm-.059-11.328c.224.04.539.297.593.448l.143.377c.214.547.4 1.02.387 2.512-.015 1.914-.786 4.111-1.183 4.622-.121.157-.567.364-.857.361l-4.98-.04c-.047-.003-.655-.056-1.306-.717-.906-.92-2.275-2.311-5.19-2.335a.501.501 0 0 1-.495-.504.501.501 0 0 1 .504-.496c1.407.011 2.729.168 4.289.514l1.393.308-.177-1.323c.055-.602.479-3.929.585-4.33.03-.057.184-.13.324-.108l5.97.711Z"
    />
  </svg>
);
export default SvgThumbsneutral;