import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { AppDispatch, RootState } from '../../store';
import { Flex, Text, Modal, Button, Card, LinkWrapper } from '../../uikit';
import Loader from '../../uikit/Loader/Loader';
import SvgClose from '../../icons/SvgClose';
import SvgTick from '../../icons/SvgTick';
import { SUCCESS } from '../../uikit/Colors/colors';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import styles from '../subscriptionmodule/subscriptionscreen.module.css';
import {
  backendProcessMiddleWare,
  creditsPurchaseMiddleWare,
  orderSummaryMiddleWare,
  orderSummaryPostMiddleWare,
} from '../ordersummarymodule/store/ordersummarymiddleware';
import {
  createCheckoutMiddleWare,
  stripeMiddleWare,
} from '../talentsourcingmodule/store/middleware/talentSoucringMiddleware';
import {
  createCheckoutSubscriptionMiddleWare,
  manageSubscriptionMiddleWare,
} from '../accountsettingsmodule/managesubscription/store/managesubscriptionmiddleware';
import { isEmpty } from '../../uikit/helper';
import { permissionMiddleWare } from '../Login/store/middleware/loginMiddleWare';
type Props = {
  open: boolean;
  setverifymodel: (val: any) => void;
};
const JdrestrictModal = ({ open, setverifymodel }: Props) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <>
      <Modal open={open}>
        <Flex
          middle
          center
          style={{
            width: '500px',
            backgroundColor: ' #ffffff',
            padding: '25px',
            borderRadius: '5px',
          }}
        >
          <Text>You alredy Ai generate count as be finished.</Text>
          <Flex middle center marginTop={20}>
            <Button onClick={() => setverifymodel(false)}>OK</Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default JdrestrictModal;
