import { useMediaQuery } from 'react-responsive';
import SvgBag from '../../../icons/SvgBag';
import SvgCalendar from '../../../icons/SvgCalendar';
import SvgLocation from '../../../icons/SvgLocation';
import Totalcount from '../../../globulization/TotalCount';
import Button from '../../../uikit/Button/Button';
import Card from '../../../uikit/Card/Card';
import { GARY_4 } from '../../../uikit/Colors/colors';
import Flex from '../../../uikit/Flex/Flex';
import { getDateString, toCamelCase } from '../../../uikit/helper';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import Text from '../../../uikit/Text/Text';
import SvgNoData from '../../../icons/SvgNoData';
import styles from './bannercardlist.module.css';
import BannerCardView from './BannerCardView';
import { JdFormEntity, CareerPageSetting } from './buildCareerPageTypes';

type Props = {
  jd_form: JdFormEntity[];
  career_page_setting: CareerPageSetting;
  total: number;
  showRemoteJobs: any;
  jd_active: boolean;
};

const BannerCardList = ({
  jd_form,
  career_page_setting,
  total,
  showRemoteJobs,
  jd_active,
}: Props) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1050px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const fontFamily = career_page_setting.page_font;

  const titleFontSize = career_page_setting.page_font_size + 2;

  const fontSize = career_page_setting.page_font_size;
  const filteredJdForm = showRemoteJobs
    ? jd_form.filter((job) => job.work_space_type === '3')
    : jd_form;

  const totalCount = showRemoteJobs ? filteredJdForm.length : total;
  return (
    <Flex marginTop={10} columnFlex className={styles.overAll}>
      {jd_active === true && (
        <Totalcount name="Total Jobs Found " numbers={totalCount} />
      )}

      {isMobile && (
        <>
          <Flex>
            {jd_form &&
              jd_form.map((list) => {
                const formattedJobTitle = list.job_title.replace(/\s+/g, '-');
                <div
                  style={{
                    marginBottom: 20,
                    position: 'relative',
                    // width: '100%',
                  }}
                  key={Date.now()}
                >
                  <Card className={styles.cardPadding}>
                    <Flex center columnFlex middle>
                      <LinkWrapper
                        onClick={() =>
                          localStorage.setItem(
                            'careerJobTitle',
                            formattedJobTitle,
                          )
                        }
                        to={`/${career_page_setting.career_page_url}/career_job_view/${list.id}/${formattedJobTitle}`}
                      >
                        <Text
                          bold
                          style={{
                            fontFamily,
                            fontSize: titleFontSize,
                          }}
                        >
                          {toCamelCase(list.job_title)}
                        </Text>
                      </LinkWrapper>
                      <Flex row center className={styles.lineHeight}>
                        <SvgBag height={16} width={16} fill={GARY_4} />
                        <Text
                          className={styles.labelStyle}
                          style={{
                            fontFamily,
                            fontSize,
                          }}
                        >
                          {list.job_type__label_name}
                        </Text>
                      </Flex>
                      <Flex row center>
                        <SvgLocation height={16} width={16} fill={GARY_4} />
                        <Text
                          className={styles.labelStyle}
                          style={{
                            fontFamily,
                            fontSize,
                          }}
                        >
                          {list.job_location}
                        </Text>
                      </Flex>
                      <Flex center middle>
                        <LinkWrapper
                          onClick={() =>
                            localStorage.setItem(
                              'careerJobTitle',
                              formattedJobTitle,
                            )
                          }
                          to={`/${career_page_setting.career_page_url}/career_job_view/${list.id}/${formattedJobTitle}`}
                        >
                          <Button
                            types="secondary"
                            style={{
                              borderColor: career_page_setting.button_color,
                              margin: '16px 0',
                            }}
                          >
                            <Text
                              style={{
                                color: career_page_setting.button_color,
                              }}
                            >
                              Apply
                            </Text>
                          </Button>
                        </LinkWrapper>
                      </Flex>
                      <Flex row center>
                        <SvgCalendar height={16} width={16} fill={GARY_4} />
                        <Text
                          className={styles.labelStyle}
                          style={{
                            fontFamily,
                            fontSize,
                          }}
                        >
                          {getDateString(list.job_posted_on, 'll')}
                        </Text>
                      </Flex>
                    </Flex>
                  </Card>
                </div>;
              })}
          </Flex>
        </>
      )}
      {!isMobile && (
        <Flex row wrap marginTop={10}>
          {jd_form && jd_form.length !== 0 ? (
            <>
              {filteredJdForm.map((list, index) => (
                <BannerCardView
                  key={Date.now() + index.toString()}
                  list={list}
                  index={index}
                  isTablet={isTablet}
                  career_page_setting={career_page_setting}
                  fontFamily={fontFamily}
                  titleFontSize={titleFontSize}
                  fontSize={fontSize}
                />
              ))}
            </>
          ) : (
            <>
              <Flex flex={1} center middle height={232}>
                {jd_active === true && (
                  <Flex className={styles.nodataavailicon}>
                    <Flex flex={8} center middle style={{ display: 'flex' }}>
                      <SvgNoData width={16} height={16} fill={'#888888'} />
                      <Text
                        style={{ marginTop: '2px' }}
                        size={13}
                        color="placeholder"
                      >
                        No jobs found
                      </Text>
                    </Flex>
                  </Flex>
                )}
                {jd_active !== true && (
                  <Text align="center" color="gray">
                    No Current Openings
                  </Text>
                )}
              </Flex>
            </>
          )}
          {/* {jd_form.length === 1 && (
            <div
              style={{
                marginLeft: 10,
                marginBottom: 20,
                position: 'relative',
                // width: '49%',
              }}
            >
              <></>
            </div>
          )} */}
        </Flex>
      )}
    </Flex>
  );
};

export default BannerCardList;
