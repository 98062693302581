import * as React from 'react';
const defaultProps = {
  fill: 'ED4857',
  width: 17,
  height: 18,
  viewbox: '0 0 18 18',
};

const SvgThumbsdown = ({
  width,
  height,
  fill,
  viewbox,
}: typeof defaultProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewbox}
    >
      <path d="M1.43 11.324c.391.144 2.512.406 3.883.56A20.906 20.906 0 0 0 5 15.5C5 16.879 6.122 18 7.5 18s2.5-1.121 2.5-2.5c0-1.875.666-2.738 1.616-3.699A2.99 2.99 0 0 0 14 13c1.654 0 3-1.346 3-3V4c0-1.654-1.346-3-3-3-.755 0-1.438.29-1.965.752l-.188-.193C10.887.823 8.18 0 5.999 0 4.12 0 3.391.293 2.746.553L2.43.676C1.596.981.854 1.903.694 2.876L.028 8.85c-.173 1.037.443 2.125 1.402 2.474ZM14 3c.551 0 1 .448 1 1v6a1 1 0 0 1-2 0V4c0-.552.449-1 1-1Zm-11.327.15c.037-.224.292-.541.443-.596l.376-.146C4.037 2.189 4.508 2 6 2c1.914 0 4.118.753 4.632 1.146.158.12.368.564.368.854v4.98c-.003.047-.051.656-.707 1.312C9.38 11.206 8 12.586 8 15.5c0 .275-.225.5-.5.5a.501.501 0 0 1-.5-.5c0-1.407.146-2.73.479-4.293l.297-1.396-1.321.188c-.603-.05-3.933-.447-4.334-.55-.058-.03-.132-.183-.111-.323l.663-5.976Z" />
    </svg>
  );
};
SvgThumbsdown.defaultProps = defaultProps;

export default SvgThumbsdown;
