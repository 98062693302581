import * as React from 'react';
const SvgSubsandBills = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    viewBox='0 0 25 25'
    {...props}
  >
    <g clipPath="url(#SubsandBills_svg__a)">
      <mask id="SubsandBills_svg__b" fill="#fff">
        <path d="M22.695 1.857V0H.684v23.144h.09C.98 23.992 1.6 25 3.001 25h21.316V1.857zm-1.391-.467v4.305H2.071V1.39zM2.071 6.7h19.233v15.057H2.071zm20.86 16.912H3a.82.82 0 0 1-.781-.469h20.475v-19.9h.235z" />
      </mask>
      <path
        fill="#581845"
        d="M22.695 1.857h-1v1h1zm0-1.857h1v-1h-1zM.684 0v-1h-1v1zm0 23.144h-1v1h1zm.09 0 .972-.235-.185-.765H.774zM24.317 25v1h1v-1zm0-23.143h1v-1h-1zm-3.013-.467h1v-1h-1zm0 4.305v1h1v-1zm-19.233 0h-1v1h1zm0-4.305v-1h-1v1zm0 5.311v-1h-1v1zm19.233 0h1v-1h-1zm0 15.057v1h1v-1zm-19.233 0h-1v1h1zm20.86 1.855v1h1v-1zM2.22 23.144v-1H.581l.75 1.458zm20.475 0v1h1v-1zm0-19.9v-1h-1v1zm.235 0h1v-1h-1zm.765-1.387V0h-2v1.857zm-1-2.857H.684v2h22.011zM-.316 0v23.144h2V0zm1 24.144h.09v-2h-.09zm-.881-.764c.132.545.407 1.192.927 1.715C1.272 25.64 2.032 26 3 26v-2c-.432 0-.683-.145-.851-.315-.192-.193-.33-.474-.403-.776zM3 26h21.316v-2H3zm22.316-1V1.857h-2V25zm-1-24.143h-1.622v2h1.622zm-4.013.533v4.305h2V1.39zm1 3.305H2.071v2h19.233zm-18.233 1V1.39h-2v4.305zm-1-3.305h19.233v-2H2.071zm0 5.311h19.233v-2H2.071zm18.233-1v15.057h2V6.701zm1 14.057H2.071v2h19.233zm-18.233 1V6.701h-2v15.057zm19.86.855H3v2H22.93zm-19.93 0c-.018 0-.015-.003.002.002q.027.009.06.03c.044.03.054.056.046.042l-1.778.915c.116.226.302.487.598.69.304.21.67.32 1.072.32zm-.781 1.531h20.475v-2H2.22zm21.475-1v-19.9h-2v19.9zm-1-18.9h.235v-2h-.235zm-.765-1v20.369h2V3.243z"
        mask="url(#SubsandBills_svg__b)"
      />
      <path
        fill="#581845"
        d="M19.525 7.939H12.53v1.388h6.995zM19.525 10.76H12.53v1.392h6.995zM19.525 13.583H12.53v1.394h6.995zM19.525 16.253H12.53v1.396h6.995zM19.525 19.076H12.53v1.392h6.995z"
      />
      <path stroke="#581845" d="M3.863 14.477V8.44h6.151v6.038z" />
      <path stroke="#581845" strokeWidth={0.5} d="M8.021 4.52V2.544h7.775V4.52z" />
      <path
        fill="#581845"
        d="M10.648 16.407H3.286V17.8h7.362zM10.648 19.226H3.286v1.393h7.362z"
      />
      <circle cx={20.5} cy={19.5} r={6.5} fill="#eee8ec" />
      <path
        fill="#581845"
        d="M20 14c-2.75 0-5 2.25-5 5s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5m0 9c-2.205 0-4-1.795-4-4s1.795-4 4-4 4 1.795 4 4-1.795 4-4 4m2.295-6.21L19 20.085l-1.295-1.29L17 19.5l2 2 4-4z"
      />
    </g>
    <defs>
      <clipPath id="SubsandBills_svg__a">
        <path fill="#581845" d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSubsandBills;