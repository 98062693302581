import { createSlice } from '@reduxjs/toolkit';
import {
  NotificationReducerState,
  SubsriptionReducerState,
} from '../navbarTypes';
import {
  SubsriptionMiddleWare,
  notificationMiddleWare,
} from './navbarmiddleware';

const NotificationState: NotificationReducerState = {
  isLoading: false,
  error: '',
  success: false,
  total: 0,
  total_unread: 0,
};

const notificationReducer = createSlice({
  name: 'navbar',
  initialState: NotificationState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(notificationMiddleWare.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(notificationMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.total = action.payload.total;
      state.today = action.payload.today;
      state.yesterday = action.payload.yesterday;
      state.others = action.payload.others;
      state.total_unread = action.payload.total_unread;
    });
    builder.addCase(notificationMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});
const SubscriptionState: SubsriptionReducerState = {
  isLoading: false,
  error: '',
  success: false,
  current_plan: 0,
  current_jd_count: null,
  current_resume_count: null,
  total_plan: [
    {
      plan_id: 0,
      plan_name: '',
      subscription_value_days: 0,
      price: 0,
      currency: '',
      stripe_id: 0,
      is_active: true,
      created_at: '',
      inactived_date: '',
      updated_by: '',
      jd_count: 0,
      resume_count: 0,
    },
  ],
  user_credits: {
    job_credits: 0,
    resume_credits: 0,
    resume_unlock_credits: 0,
    ai_match_count: 0,
  },
  add_on_plans: [
    {
      addon_id__id: 0,
      addon_id__name: '',
      addon_id__currency: '',
      price: 0,
      usage: 0,
      carry_forward: false,
      plan_id: 0,
      addon_id__display_name: '',
      stripe_id: '',
      name: '',
    },
  ],
  differences: [],
  disable_count: 0,
  active_resume: [],
  Last_plan: [],
  standard: [],
  priority: null,
  client_url: '',
  integration: '',
  job_post: null,
  zita_match: null,
  import_candidates: null,
  import_applicants: null,
  database: null,
  talent_sourcing: null,
  careers_page: null,
  expire_details: [],
};

const SubscriptionReducer = createSlice({
  name: 'navbar',
  initialState: SubscriptionState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(SubsriptionMiddleWare.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(SubsriptionMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.current_plan = action.payload.current_plan;
      state.current_jd_count = action.payload.current_jd_count;
      state.current_resume_count = action.payload.current_resume_count;
      state.total_plan = action.payload.total_plan;
      state.add_on_plans = action.payload.add_on_plans;
      state.differences = action.payload.differences;
      state.disable_count = action.payload.disable_count;
      state.active_resume = action.payload.active_resume;
      state.Last_plan = action.payload.Last_plan;
      state.standard = action.payload.standard;
      state.priority = action.payload.priority;
      state.user_credits = action.payload.user_credits;
      state.client_url = action.payload.client_url;
      state.integration = action.payload.integration;
      state.job_post = action.payload.job_post;
      state.zita_match = action.payload.zita_match;
      state.import_candidates = action.payload.import_candidates;
      state.import_applicants = action.payload.import_applicants;
      state.database = action.payload.database;
      state.talent_sourcing = action.payload.talent_sourcing;
      state.careers_page = action.payload.careers_page;
      state.expire_details = action.payload.expire_details;

    });
    builder.addCase(SubsriptionMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

export const notificationReducers = notificationReducer.reducer;
export const SubscriptionReducers = SubscriptionReducer.reducer;
