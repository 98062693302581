// import * as React from 'react';
const SvgIntomark = (props) => (
  <svg
    width={7}
    height={7}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox=''
    {...props}
  >
    <path
      d="M1 5.713 5.715 1M1 1l4.715 4.713"
      stroke="#cccccc"
      strokeWidth={1.25}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgIntomark;
