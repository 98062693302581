import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import ReactQuill from 'react-quill';
import { useEffect, useState } from 'react';
import StarsRating from 'react-star-rate';
import { useForm } from 'react-hook-form';
import {
  Button,
  ErrorMessage,
  InputCheckBox,
  InputRadio,
  InputText,
  Loader,
  Modal,
  Toast,
} from '../../uikit';
import { AppDispatch, RootState } from '../../store';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Blur from '../../uikit/Blur/blur';
import { isEmpty } from '../../uikit/helper';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { userProfileMiddleWare } from '../accountsettingsmodule/userprofilemodule/store/middleware/userprofilemiddleware';
import Interviewquestiondummycard from './interviewquestiondummycard';
import InterviewQustioncard from './InterviewQuestioncard';
import InterviewScorecardTab from './InterviewScorecardTab';
import styles from './screeningstatustab.module.css';
import { hireList } from './mock';
import {
  evaluateQuestionMiddleware,
  interviewQuestionMiddleware,
} from './store/middleware/interviewquestionMiddleware';
import QuestionCard from './questionsCard';

import { EvaluateInterviewInput, Question } from './interviewerQuestionType';
import EvaluateModal from './EvaluateModal';
import Interviewmodalpopup from './InterviewModalpopup';

const cx = classNames.bind(styles);

type Props = {
  title: string;
  issingletab: boolean;
  jd_id: string;
  can_id: string;
  interview_id: string;
};
const ScreeningStatusTab = ({
  title,
  issingletab,
  jd_id,
  can_id,
  interview_id,
}: Props) => {
  // subscription setstate
  const [isopensubcription, setopensubcription] = useState(false);
  const [opencomment, setopencomment] = useState(false);
  const [isinterviewbasic, setinterviewbasic] = useState<any>();
  const [isdisabled, setdisabled] = useState<any>();
  const [isinterviewbasicevaluate, setinterviewbasicevaluate] = useState<any>();
  // const form: QuestionForm = { question: '' };
  const dispatch: AppDispatch = useDispatch();
  const [addQuestion, setaddQuestion] = useState(false);
  const [isPostLoader, setPostLoader] = useState(false);
  const [isaddqustion, setAddquestion] = useState(false);
  const [isgeneratequestion, setgeneratequestion] = useState(false);
  const [isregeneratequestion, setregeneratequestion] = useState(false);
  const [generatedquestion, setgeneratedquestion] = useState<any>([]);
  const [isinterviewid, setinterviewid] = useState<any>();
  const [isloader, setisloader] = useState<any>(false);
  const [iscleardata, setcleardata] = useState<any>(false);
  const [istriggerevaluate, settriggerevaluate] = useState<any>(false);
  const [evaluatePopup, setEvaluatePopup] = useState<{
    open: boolean;
    data: Question[];
    interview_id: number;
  } | null>(null);
  const [isevaluatedata, setevaluatedata] = useState<any>([])
  const [isQuestionLoader, setQuestionLoader] = useState(false);

  useEffect(() => {
    dispatch(userProfileMiddleWare());
  }, []);
  const {
    stages,
    invite,
    user,
    candidate_details,
    questions,
    question_loading,
    question_error,
    interviews,
    no_of_interview,
    interviewData,
    genearate,
    cumulative,
    Permission,
    interview_access
  } = useSelector(
    ({
      applicantStausReducers,
      userProfileReducers,
      applicantProfileInitalReducers,
      interviewerQuestionReducers,
      permissionReducers
    }: RootState) => {
      return {
        stages: applicantStausReducers?.stages,
        invite: applicantStausReducers?.invite,
        user: userProfileReducers.user,
        candidate_details: applicantProfileInitalReducers.candidate_details,
        questions: interviewerQuestionReducers.data,
        cumulative: interviewerQuestionReducers.cumulative,
        interviews: interviewerQuestionReducers.interviews,
        no_of_interview: interviewerQuestionReducers.no_of_interview,
        question_loading: interviewerQuestionReducers.isLoading,
        question_error: interviewerQuestionReducers.error,
        genearate: interviewerQuestionReducers?.generateQuestionsState,
        interviewData: interviewerQuestionReducers.data,
        Permission: permissionReducers.data,
        interview_access: interviewerQuestionReducers.interview_access
      };
    },
  );

  //setting the overall data for every render 
  useEffect(() => {
    setgeneratedquestion(interviewData)
  }, [interviewData])

  //close the evaluate modal
  const handleCancel = () => {
    setEvaluatePopup(null);
    settriggerevaluate(true);
    setdisabled(false);
  };

  //function for the evaluate update event
  function UpdateEvaluate(data: any) {
    setevaluatedata(data)
  }

  //Add question functionality API trigger
  function AddnewQuestion(formvalue) {
    const addnewquestion = []
    const filteringdata = formvalue.levellist.map(e => e.levelvalue);

    filteringdata
      .filter(val => val.question !== '' && val.question !== undefined)
      .map((value, index) => {
        const questiondtasa = {
          "id": "0",
          "question": value.question,
          "type": value.questiontype,
          "level": value.difficulty
        };
        addnewquestion.push(questiondtasa);
      });
    setisloader(true)
    dispatch(evaluateQuestionMiddleware({
      jd_id: jd_id,
      can_id: can_id,
      scorecard: JSON.stringify(addnewquestion),
      interview_id: isinterviewid,
    })).then((res) => {
      if (res.payload.success === true) {

        setAddquestion(false);
        setisloader(false);
        setcleardata(true);
        Toast('Question added successfully.', 'LONG', 'success');
      }
      else {
        setisloader(false);
        Toast(
          'Oops! Something went wrong. Please try again shortly.',
          'LONG',
          'error',
        );
      }
    })
  }

  //Regeneratequestion functionality API trigger
  function Regeneratequestion(formvalue) {
    const result = formvalue.levellist.reduce((accumulator, item) => {
      if (item.levelvalue.easy !== "" &&
        item.levelvalue.easy !== undefined &&
        item.levelvalue.easy !== null &&
        Number(item.levelvalue.easy) !== 0) {
        accumulator.push({
          level: "Easy",
          type: item.levelvalue.name,
          count: item.levelvalue.easy
        });
      }
      if (item.levelvalue.medium !== "" &&
        item.levelvalue.medium !== undefined &&
        item.levelvalue.medium !== null &&
        Number(item.levelvalue.medium) !== 0) {
        accumulator.push({
          level: "Medium",
          type: item.levelvalue.name,
          count: item.levelvalue.medium
        });
      }
      if (item.levelvalue.hard !== "" &&
        item.levelvalue.hard !== undefined &&
        item.levelvalue.hard !== null &&
        Number(item.levelvalue.hard) !== 0) {
        accumulator.push({
          level: "Hard",
          type: item.levelvalue.name,
          count: item.levelvalue.hard
        });
      }
      return accumulator;
    }, []);
    setisloader(true);
    dispatch(interviewQuestionMiddleware({
      jd_id: jd_id,
      can_id: can_id,
      re_generate: result,
      interview_id: isinterviewid,
      exclude: Object.keys(isevaluatedata).length !== 0 ? (isevaluatedata?.filter(item => item?.interview_id === isinterviewid).map(item => item?.id)) : ''

    })).then((res) => {
      if (res?.payload.success === true) {
        setisloader(false);
        setcleardata(true);
        setregeneratequestion(false);
        Toast('Question successfully regenerated.', 'LONG', 'success');
      }
      else {
        setisloader(false);
        if (res?.payload?.standalone === true) {
          Toast(res?.payload?.message, 'LONG', 'error');
        } else {
          Toast(
            'Oops! Something went wrong. Please try again shortly.',
            'LONG',
            'error',
          );
        }
      }
    })
  }

  //generatequestion functionality API trigger
  function generatequestion(formvalue) {
    const result = formvalue.levellist.reduce((accumulator, item) => {
      if (item.levelvalue.easy !== "" &&
        item.levelvalue.easy !== undefined &&
        item.levelvalue.easy !== null &&
        Number(item.levelvalue.easy) !== 0) {
        accumulator.push({
          level: "Easy",
          type: item.levelvalue.name,
          count: item.levelvalue.easy
        });
      }
      if (item.levelvalue.medium !== "" &&
        item.levelvalue.medium !== undefined &&
        item.levelvalue.medium !== null &&
        Number(item.levelvalue.medium) !== 0) {
        accumulator.push({
          level: "Medium",
          type: item.levelvalue.name,
          count: item.levelvalue.medium
        });
      }
      if (item.levelvalue.hard !== "" &&
        item.levelvalue.hard !== undefined &&
        item.levelvalue.hard !== null &&
        Number(item.levelvalue.hard) !== 0) {
        accumulator.push({
          level: "Hard",
          type: item.levelvalue.name,
          count: item.levelvalue.hard
        });
      }
      return accumulator;

    }, []);
    setisloader(true)
    dispatch(interviewQuestionMiddleware({
      jd_id: jd_id,
      can_id: can_id,
      re_generate: result,
      interview_id: isinterviewid,
      role: formvalue.role
    })).then((res) => {
      if (res.payload.success === true) {
        setisloader(false);
        setcleardata(true);
        setgeneratequestion(false);
        Toast('Question generated successfully.', 'LONG', 'success');
      }
      else {
        setisloader(false);
        if (res?.payload?.standalone === true) {
          Toast(res?.payload?.message, 'LONG', 'error');
        } else {
          Toast(
            'Oops! Something went wrong. Please try again shortly.',
            'LONG',
            'error',
          );
        }
      }
    })
  }
  return (
    <Flex row flex={12}>
      <Interviewmodalpopup
        isregeneratequestion={isregeneratequestion}
        isgeneratequestion={isgeneratequestion}
        isaddqustion={isaddqustion}
        setregeneratequestion={setregeneratequestion}
        setgeneratequestion={setgeneratequestion}
        setAddquestion={setAddquestion}
        AddnewQuestion={AddnewQuestion}
        Regeneratequestion={Regeneratequestion}
        generatequestion={generatequestion}
        isloader={isloader}
        setcleardata={setcleardata}
        iscleardata={iscleardata}
      />
      <Flex flex={6} style={{ padding: '10px 0 10px 10px' }}>
        <Flex row>
          <Flex><Text bold className={styles.screenText}>
            Interview Questions
          </Text></Flex>
          {Permission.includes('AI Interview Question') ? '' : !Permission.includes('Addon AI Interview Question') && <Flex marginLeft={5} marginTop={1}><SvgSubcriptioncrown /></Flex>}
        </Flex>

        <Flex>
          {Permission.includes('AI Interview Question') ?
            '' :
            <>
              {!Permission.includes('Addon AI Interview Question') && no_of_interview && no_of_interview?.length === 0 &&
                <Blur
                  width='100%'
                  height={window.innerHeight - 135}
                  content='to access AI Interview Questions Generations feature.'
                  rightalign='0px'
                  marginTop={1}
                  Addon
                  upgrade
                  handlefunction={setopensubcription}
                />
              }
            </>}
          <Text style={{ marginBottom: '10px' }}>
            You can select, deselect, regenerate, and add questions for the
            applicant.
          </Text>

          {no_of_interview && no_of_interview?.length > 0 ?
            <Flex
              columnFlex
              className={styles.overAllPopup}
              height={window.innerHeight - 153}
            >

              <InterviewQustioncard
                Permission={Permission}
                interviewData={generatedquestion}
                no_of_interview={no_of_interview}
                istriggerevaluate={istriggerevaluate}
                isevaluatedata={isevaluatedata}
                setregeneratequestion={setregeneratequestion}
                setgeneratequestion={setgeneratequestion}
                setAddquestion={setAddquestion}
                setevaluatedata={setevaluatedata}
                UpdateEvaluate={UpdateEvaluate}
                setinterviewid={setinterviewid}
                setopencomment={setopencomment}
                setinterviewbasic={setinterviewbasic}
                interview_access={interview_access}
                setinterviewbasicevaluate={setinterviewbasicevaluate}
                onEvaluate={(id, value) => {
                  setEvaluatePopup({
                    open: true,
                    data: value,
                    interview_id: id,
                  });
                }}
              />
            </Flex> :
            <>
              {Permission.includes('AI Interview Question') ?
                <Flex
                  center
                  middle
                  height={window.innerHeight - 180}
                >
                  <Text color='gray'>No interview found</Text>
                </Flex>
                :
                Permission.includes('Addon AI Interview Question') ?
                  <Flex
                    center
                    middle
                    height={window.innerHeight - 180}
                  >
                    <Text color='gray'>No interview found</Text>
                  </Flex> :
                  <Flex
                    center
                    middle
                    height={window.innerHeight - 180}
                  >
                    <Interviewquestiondummycard />
                  </Flex>
              }
            </>}
        </Flex>
      </Flex >
      {evaluatePopup && (
        <EvaluateModal
          {...{ ...evaluatePopup, jd_id, can_id }}
          user={user}
          interview_ids={evaluatePopup.interview_id}
          candidateDetails={candidate_details}
          isevaluatedata={(function () {
            const filteredData = isevaluatedata.filter(is => is.interview_id === evaluatePopup.interview_id);
            return filteredData;
          })()}
          onCancel={handleCancel}
          commands={
            isevaluatedata.map((ele) => {
              const finddata = cumulative.find((obj2) => obj2.interview_id === evaluatePopup.interview_id && obj2.attendees === user.id.toString());

              return finddata !== undefined ? finddata.commands : "";
            })}
          recommend={isevaluatedata.map((ele) => {
            const finddata = cumulative.find((obj2) => obj2.interview_id === evaluatePopup.interview_id && obj2.attendees === user.id.toString());
            return finddata !== undefined ? finddata : '';
          })}
          setdisabled={setdisabled}
          isdisabled={isdisabled}
          cumulative={cumulative}
        />
      )}

      {
        !issingletab && (
          <Flex
            height={window.innerHeight - 115}
            style={{
              border: '0.5px solid #C3C3C3',
              width: '0.5px',
              margin: '15px 5px 10px 5px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          ></Flex>
        )
      }
      {
        !issingletab && (
          <Flex flex={6} style={{ padding: '10px 0 10px 10px' }}>
            <InterviewScorecardTab
              UpdateEvaluate={UpdateEvaluate}
              onEvaluate={(id, value) => {
                setEvaluatePopup({
                  open: true,
                  data: value,
                  interview_id: id,
                });
              }}
              opencomments={opencomment}
              setopencomment={setopencomment}
              isinterviewbasic={isinterviewbasic}
              setinterviewbasic={setinterviewbasic}
              setinterviewbasicevaluate={setinterviewbasicevaluate}
              isinterviewbasicevaluate={isinterviewbasicevaluate}
              setdisabled={setdisabled}
              isdisabled={isdisabled}
            />
          </Flex>
        )
      }
      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          differece_key={['Ai_interviewquestion']}
          addon_name={'AI Interview Questions Generation'}
        />
      )}
    </Flex >
  );
};

export default ScreeningStatusTab;
