import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import Modal from '../../../../uikit/Modal/Modal';
import { Button, Flex, InputText, Loader, Toast } from '../../../../uikit';
import RichText from '../../../common/RichText';
import { Text } from '../../../../uikit';
import Required from '../../../../uikit/Required/required';
import styles from '../jdtemplate/jdopenmodal.module.css';
import { mentionnotes } from '../../../constValue';
import { isEmpty, toCamelCase } from '../../../../uikit/helper';

import { createjdtemplatepostMiddleWare } from '../../store/middleware/accountsettingmiddleware';
import { jdTemplatesApiMiddleWare } from '../../../createjdmodule/store/middleware/createjdmiddleware';

type Props = {
  open: boolean;
  handleJdModal: () => void;
  itemvalue: any;
  itemclose: () => void;
  job_title: any;
  setopenview: (statevalue: any) => void;
  setEditedId?: (id?: any) => void;
  jd_templates?: any;
};

const JDopenModal = ({
  open,
  handleJdModal,
  itemvalue,
  itemclose,
  job_title,
  setopenview,
  setEditedId,
  jd_templates
}: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const [isTitle, setTitle] = useState('');
  const [isDescription, setDescription] = useState('');
  const [errordiscription, seterrordiscription] = useState(false);
  const [errortitle, seterrortitle] = useState(false);
  const [errorrequiredtitle, seterrorrequiredtitle] = useState(false);
  const [errorrequireddescription, seterrorrequireddescription] =
    useState(false);
  const [errorname, seterrorname] = useState(false);
  const [isLoader, setisLoader] = useState(false);
  const [minlengthdec, setminlengthdec] = useState(false);
  const [isshowfieldrequired, setfieldrequired] = useState(false);

  // filtered the without current data.
  const templatevalue = jd_templates && jd_templates?.filter(val=>val?.id !== itemvalue?.id) 

  const handletitle = (e: any) => {
    //setTitle(e.target.value)
    const inputTitle = e.target.value.trim().toLowerCase();
    const jobTitlesLowercase = templatevalue.map((title) =>
      title?.job_title?.trim().toLowerCase(),
    );
    var test1 = e.target.value.trim();

    if (test1.length !== 0) {
      setTitle(e.target.value);
      seterrorrequiredtitle(false);
    } else {
      seterrorrequiredtitle(true);
      setTitle(e.target.value.trim());
    }
    const title = e.target.value;
    if (title.length > 35) {
      seterrortitle(true);
    } else {
      seterrortitle(false);
    }

    if (job_title.length > 1 && job_title !== undefined && job_title !== null) {
      if (jobTitlesLowercase.includes(inputTitle)) {
        seterrorname(true);
      } else {
        seterrorname(false);
      }
    }
  };

  useEffect(() => {
    if (itemvalue !== null && itemvalue !== undefined) {
      setTitle(itemvalue.job_title);
      setDescription(itemvalue.job_description);
    }
  }, []);

  const parser = new DOMParser();
  const handlemessage = (values) => {
    const doc = parser.parseFromString(values, 'text/html');
    const textNodes = doc.querySelectorAll('body')[0].textContent;
    const texttrim = textNodes.trim();
    return texttrim;
  };

  const handleDescription = (e: any) => {
    //formik.setFieldValue('userMessage', e);
    setDescription(e);
    const description = e;
    const result = handlemessage(description);
    if (result.length !== 0) {
      seterrorrequireddescription(false);
    }
    if (result.length === 0) {
      seterrorrequireddescription(true);
    }
    if (result.length > 2000) {
      seterrordiscription(true);
    } else {
      seterrordiscription(false);
    }
    if (result.length > 300) {
      setminlengthdec(false);
    }
  };
  const addfunction = () => {
    setfieldrequired(true);
    if (isTitle.trim() === '') {
      seterrorrequiredtitle(true);
    } else {
      seterrorrequiredtitle(false);
    }
    const result = handlemessage(isDescription);
    if (result.trim() === '') {
      //alert('space')
      seterrorrequireddescription(true);
    } else {
      seterrorrequireddescription(false);
    }
    if(isTitle?.trim()?.length > 35){
      seterrortitle(true);
    }
    if (result.length <= 300) {
      setminlengthdec(true);
    } else {
      setminlengthdec(false);
    }
    if (result.length > 2000) {
      seterrordiscription(true);
    } else {
      seterrordiscription(false);
    } 
    if (
      isTitle.trim() !== '' &&
      isDescription.trim() !== '' &&
      result.length >= 300 &&
      result.length <= 2000 &&
      isTitle?.trim()?.length <= 35
    ) {
      setisLoader(true);
      let formData = new FormData();
      if (itemvalue !== null) {
        formData.append('id', itemvalue.id);
      }

      formData.append('title', toCamelCase(isTitle));
      formData.append('description', isDescription);
      dispatch(createjdtemplatepostMiddleWare({ formData })).then((res) => { 
        if (res.payload.success === true) {
          setEditedId(res.payload.templateid)
          if (itemvalue !== null) {
            Toast('Template updated successfully.', 'LONG', 'success');
            setisLoader(false);
          } else {
            Toast('Template saved successfully.', 'LONG', 'success');
            setisLoader(false);
          }
        } else {
          Toast('Oops! Something went wrong. Please try again shortly.', 'LONG', 'error');
          setisLoader(false);
        }
        dispatch(jdTemplatesApiMiddleWare({ ds_role: '0', user: '2' }));
      });
      handleJdModal();
    }
  };
  return (
    <div>
      <Modal open={open}>
        <Flex className={styles.addtemplatePopup}>
          {itemvalue !== null ? (
            <Text bold size={14} className={styles.titletext}>
              Update Template
            </Text>
          ) : (
            <Text bold size={14} className={styles.titletext}>
              Add Template
            </Text>
          )}
          <Flex marginBottom={5}>
            <Required />
          </Flex>
          <Flex>
            <Flex>
              <InputText
                label="Template Title *"
                className={styles.templatetitleInput}
                maxLength={36}
                onChange={handletitle}
                value={isTitle}
              />
            </Flex>
            {errortitle && (
              <Text color="error">
                Template title should not exceed 35 characters.
              </Text>
            )}
            {errorrequiredtitle && isshowfieldrequired && (
              <Text color="error">This field is required.</Text>
            )}
            {errorname && (
              <Text color="error">Template title already exists.</Text>
            )}
            <Flex marginTop={10}>
              <Text color="theme" size={13}>
                Job Description *
              </Text>
              <RichText
                height={400}
                onChange={handleDescription}
                value={isDescription} 
              />
            </Flex>
            {errordiscription && (
              <Text color="error">Job Description should not exceed 2000 characters.</Text>
            )}
            {errorrequireddescription && isshowfieldrequired && (
              <Text color="error">This field is required.</Text>
            )}
            {minlengthdec && !errorrequireddescription && (
              <Text color="error">
                Job Description must contain minimum of 300 characters.
              </Text>
            )}
          </Flex>

          <Flex className={styles.btnContainer}>
            <Flex row>
              <Button
                types="close"
                onClick={handleJdModal}
                style={{ marginRight: '5px' }}
              >
                Cancel
              </Button>
              {isLoader ? (
                <Flex className={styles.updateBtnLoader}>
                  <Loader size="small" withOutOverlay />
                </Flex>
              ) : (
                <>
                  {itemvalue !== null ? (
                    <Button
                      onClick={addfunction} 
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      onClick={addfunction} 
                    >
                      Add
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </div>
  );
};

export default JDopenModal;
