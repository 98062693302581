const defaultProps = {
    fill: '#581845',
    width: 30,
    height: 18,
    className: '',
  };
  const SvgSwitchOnextra = ({
    fill,
    width,
    height,
    className,
  }: typeof defaultProps) => (
    <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.25284 16.2812H24.7585C26.9473 16.2812 29.0464 15.4236 30.5941 13.8971C32.1418 12.3706 33.0113 10.3001 33.0113 8.14125C33.0113 5.98239 32.1418 3.91195 30.5941 2.3854C29.0464 0.858852 26.9473 0.00124931 24.7585 0.00124931H8.25284C6.06406 0.00124931 3.96492 0.858852 2.41722 2.3854C0.869514 3.91195 2.67029e-05 5.98239 2.67029e-05 8.14125C2.67029e-05 10.3001 0.869514 12.3706 2.41722 13.8971C3.96492 15.4236 6.06406 16.2812 8.25284 16.2812ZM24.7585 3.25725C26.0717 3.25725 27.3312 3.77181 28.2598 4.68774C29.1885 5.60367 29.7102 6.84593 29.7102 8.14125C29.7102 9.43657 29.1885 10.6788 28.2598 11.5948C27.3312 12.5107 26.0717 13.0252 24.7585 13.0252C23.4452 13.0252 22.1857 12.5107 21.2571 11.5948C20.3285 10.6788 19.8068 9.43657 19.8068 8.14125C19.8068 6.84593 20.3285 5.60367 21.2571 4.68774C22.1857 3.77181 23.4452 3.25725 24.7585 3.25725Z" fill="#FF0000"/>
    <path d="M9.66486 4.88281V11.2812H8.81232L5.59113 6.34619V11.2812H4.74298V4.88281H5.59113L8.8255 9.83105V4.88281H9.66486ZM10.8294 8.95654V8.85547C10.8294 8.5127 10.8792 8.19482 10.9788 7.90186C11.0784 7.60596 11.222 7.34961 11.4095 7.13281C11.597 6.91309 11.824 6.74316 12.0906 6.62305C12.3572 6.5 12.6561 6.43848 12.9871 6.43848C13.3211 6.43848 13.6214 6.5 13.888 6.62305C14.1575 6.74316 14.386 6.91309 14.5735 7.13281C14.764 7.34961 14.909 7.60596 15.0086 7.90186C15.1082 8.19482 15.158 8.5127 15.158 8.85547V8.95654C15.158 9.29932 15.1082 9.61719 15.0086 9.91016C14.909 10.2031 14.764 10.4595 14.5735 10.6792C14.386 10.896 14.159 11.0659 13.8924 11.189C13.6287 11.3091 13.3299 11.3691 12.9959 11.3691C12.6619 11.3691 12.3616 11.3091 12.095 11.189C11.8284 11.0659 11.5999 10.896 11.4095 10.6792C11.222 10.4595 11.0784 10.2031 10.9788 9.91016C10.8792 9.61719 10.8294 9.29932 10.8294 8.95654ZM11.6424 8.85547V8.95654C11.6424 9.19385 11.6702 9.41797 11.7259 9.62891C11.7816 9.83691 11.8651 10.0215 11.9764 10.1826C12.0906 10.3438 12.2327 10.4712 12.4026 10.5649C12.5726 10.6558 12.7703 10.7012 12.9959 10.7012C13.2186 10.7012 13.4134 10.6558 13.5804 10.5649C13.7503 10.4712 13.8909 10.3438 14.0023 10.1826C14.1136 10.0215 14.1971 9.83691 14.2527 9.62891C14.3113 9.41797 14.3406 9.19385 14.3406 8.95654V8.85547C14.3406 8.62109 14.3113 8.3999 14.2527 8.19189C14.1971 7.98096 14.1121 7.79492 13.9979 7.63379C13.8865 7.46973 13.7459 7.34082 13.576 7.24707C13.409 7.15332 13.2127 7.10645 12.9871 7.10645C12.7645 7.10645 12.5682 7.15332 12.3983 7.24707C12.2313 7.34082 12.0906 7.46973 11.9764 7.63379C11.8651 7.79492 11.7816 7.98096 11.7259 8.19189C11.6702 8.3999 11.6424 8.62109 11.6424 8.85547Z" fill="white"/>
    </svg>
    

  );
  
  SvgSwitchOnextra.defaultProps = defaultProps;
  export default SvgSwitchOnextra;
  