import SvgFooterHeaderArrow from '../../../icons/SvgFooterHeaderArrow';
import './header.css'



const MegaMenu = () => (
    <ul className="navbar-nav"  >
      <li className="nav-item1 dropdown">
        <li className="nav-item1 dropdown">
          <div style={{display:'flex', flexDirection:'row' ,alignItems:'center'}}>
          <a
            className="nav-link2 dropdown-toggle"
            href="# "
            data-toggle="dropdown"
         
          >
            Recruit & Engage
          </a>
          <div className='makeicon'>
          <SvgFooterHeaderArrow/>
          </div>
          </div>
          <ul className="dropdown-menu1">
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/create-and-postjob" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Create & Post Job
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/branded-career-page" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Branded Career Pages
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/talent-sourcing" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Talent Sourcing
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/kanban-hiring-board" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Customizable Hiring Board
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/human-like-ai-matching"  onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Human-Like AI Matching
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/zita-profile-view" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Zita Profile View
              </a>
            </li>
          </ul>
        </li>
      </li>
  
      <li className="nav-item1 dropdown">
        <li className="nav-item1 dropdown">
        <div style={{display:'flex', flexDirection:'row' ,alignItems:'center'}}>
          <a
            className="nav-link2 dropdown-toggle"
            href="# "
            data-toggle="dropdown"
          
          >
            Track & Collaborate
          </a>
          <div className='makeicon'>
          <SvgFooterHeaderArrow/>
          </div>
          </div>
          <ul className="dropdown-menu1">
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/applicant-tracking-system" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Applicant Tracking System
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/candidate-matching" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Candidate Matching
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/comparative-analysis " onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Comparative Analysis
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/team-collaboration" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Team Collaboration
              </a>
            </li>
  
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/inapp-mailboxintegration" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                In app Mailbox Integration
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/in-appcalendar-interview-scheduler" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Calendar & Interview Scheduler
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/interview-questions" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Interview Question by AI
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/reports" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Reports & Insights
              </a>
            </li>
          </ul>
        </li>
      </li>
  
      <li className="nav-item1 dropdown">
        <a
          className="nav-link2"
          href="https://www.app.zita.ai/plan_and_pricing"
          
          rel="noreferrer"
        >
          Plan & Pricing
        </a>
      </li>
  
      <li className="nav-item1 dropdown">
        <a href="https://www.zita.ai/knowledgebase"  className="nav-link2" rel="noreferrer">
          Knowledge Base
        </a>
      </li>
  
      <li className="nav-item1 dropdown">
        <li className="nav-item1 dropdown">
        <div style={{display:'flex', flexDirection:'row' ,alignItems:'center'}}>
          <a
            className="nav-link2  dropdown-toggle"
            href="# "
            data-toggle="dropdown"
            
          >
            insights
          </a>
          <div className='makeicon'>
          <SvgFooterHeaderArrow/>
          </div>
          </div>
          <ul className="dropdown-menu1">
          <li>
              <a className="dropdown-item1" href="https://www.zita.ai/zita-ats" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Zita ATS
              </a>
            </li>
          
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/roadmap" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Roadmap
              </a>
            </li>
            <li>
              <a className="dropdown-item1" href="https://www.zita.ai/blog" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Blog
              </a>
            </li>
          </ul>
        </li>
      </li>
  {/* 
      <li className="nav-item1 dropdown">
        <a className="nav-a" href="/contact-us" >
          Contact Us
        </a>
      </li> */}
    </ul>
  );
  export default MegaMenu