import React, { Key, useState } from 'react';
import { Tooltip } from '@mui/material';
import {
  SvgTrash,
  SvgCalendar1,
  SvgOrganizer,
  SvgPrivate,
  SvgInterviewCalendar,
  SvgInterviewers,
  SvgEdit,
  SvgCopy,
} from '../../icons';
import { Flex, LinkWrapper, Loader, Text } from '../../uikit';
import { Button } from '../../uikit';
import { toCamelCase } from '../../uikit/helper';
import { CrossButton, Modal } from '../../uikit/v2';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import SvgInfo from '../../icons/SvgInfo';
import Avatar, { getUserInitials } from '../../uikit/Avatar';
import { formatTo12HrClock, getEventHasMeeting } from './util';
import styles from './styles/EventPopUp.module.css';
import { CALENDAR, EventPopUpDetails } from './types';


interface Props {
  showEventPopUpModal: boolean;
  handleRemoveEvent: (
    setOpenEventDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
  handleCloseEventPopUpModal: () => void;
  joinMeeting: (eventId: string, link?: any) => void;
  handleEditEvent: () => void;
  isEventOrganizer?: any;
  eventPopUpDetails: EventPopUpDetails;
  copyMeeting: (eventId: string, link?: any) => void;
  calendarProvider: CALENDAR;
  evaluate: boolean;
  evaluatedata?: any;
  NavigateEvaluate: ({ }) => void;
  currentUser?: any;
  currentUserEvents?: any;
  isEventPrivateName?: any;
  ShowEditButton?: boolean;
  datalist?: any;
  isbuttonloading?: boolean;
}

const EventPopUpModal = ({
  showEventPopUpModal,
  handleCloseEventPopUpModal,
  handleEditEvent,
  handleRemoveEvent,
  isEventOrganizer,
  joinMeeting,
  copyMeeting,
  eventPopUpDetails,
  calendarProvider,
  evaluate,
  evaluatedata,
  NavigateEvaluate,
  currentUser,
  currentUserEvents,
  isEventPrivateName,
  ShowEditButton,
  datalist,
  isbuttonloading
}: Props) => {
  const [openEventDeleteModal, setOpenEventDeleteModal] = useState(false);

  const {
    attendees,
    endDate,
    eventId,
    isZitaEvent,
    link,
    organizer,
    startDate,
    syncedBy,
    title,
    canEdit,
    email,
  } = eventPopUpDetails;
  const DeleteWarningPopUp = (

    <Modal
      open={openEventDeleteModal}
      onClose={() => setOpenEventDeleteModal(false)}
    >
      <div className={styles.deleteWarningPopUp}>
        {/* <div className={styles.warningMessage}> */}
        <Flex flex={6} center>
          <Text color="black2" size={13} className={styles.insertStyles}>
            Meeting will be canceled and notified to the attendees.
          </Text>
          <Text color="black2" size={13} className={styles.insertStyles}>
            Are you sure to proceed further?
          </Text>
        </Flex>
        {/* <SvgInfo /> */}

        {/* </div> */}

        <div
          className={styles.actionButtonWrapper}
          style={{ marginTop: '20px' }}
        >
          <Button
            className={styles.cancel}
            style={{ marginRight: 8 }}
            onClick={() => setOpenEventDeleteModal(false)}
          >
            No, Thanks
          </Button>
          <Button
            onClick={() => {
              handleRemoveEvent(setOpenEventDeleteModal);
            }}
            className={styles.deleteButton}
          >
            Cancel Meeting
          </Button>
        </div>
      </div>
    </Modal>
  );

  const ZitaEvent = (
    <>
      {openEventDeleteModal ? DeleteWarningPopUp : null}
      <div className={styles.content}>
        <div className={styles.profile}>
          {/* <img
            src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="candidate profile pic"
          /> */}
          <div className={styles.title}>
            <CustomTooltip title={title} >
              <p style={{ fontWeight: 'bold' }} className={styles.eventTitle}>
                {title}
              </p>
            </CustomTooltip>
            {/* <p>Gowtham Frontend Developer</p> */}
          </div>
        </div>
        <div className={styles.info}>
          <SvgInterviewCalendar size={16} />
          <div className={styles.infoText}>
            <Text style={{ marginBottom: 3 }}>{startDate.toDateString()}</Text>
            {/* <br /> */}
            <Text>{`${formatTo12HrClock(startDate)} - ${formatTo12HrClock(
              endDate,
            )}`}</Text>
          </div>
        </div>
        <div className={styles.info}>
          <SvgInterviewers size={16} />
          <div className={styles.infoText}>
            <p style={{ marginBottom: 3 }}>Interviewer&#40;s&#41;</p>
            <Flex row className={styles.emailContainer}>
              {isEventOrganizer && (
                <Avatar
                  initials={getUserInitials({
                    fullName: isEventOrganizer,
                  })}
                  style={{ width: 28, height: 28, marginRight: '5px' }}
                  textStyle={{ fontSize: 12 }}
                  title={toCamelCase(isEventOrganizer)}
                />
              )}
              {attendees &&
                attendees?.length > 0 &&
                attendees.map((item: string, index: Key | null | undefined) => {
                  if (
                    isEventOrganizer?.toLowerCase().trim() ===
                    item.toLowerCase().trim()
                  ) {
                    return null;
                  }
                  return (
                    <Avatar
                      key={index}
                      initials={getUserInitials({ fullName: item })}
                      style={{ width: 28, height: 28, marginRight: '5px' }}
                      textStyle={{ fontSize: 12 }}
                      title={item}
                    />
                  );
                })}
            </Flex>
          </div>
        </div>

        <div className={styles.info}>
          <SvgOrganizer size={16} />
          <div className={styles.infoText}>
            <Text style={{ marginBottom: 3 }}>Organizer</Text>
            {/* <br /> */}
            <Text className={styles.email}>{toCamelCase(isEventOrganizer)}</Text>
          </div>
        </div>
      </div>

      {(eventId &&
        (attendees.includes(currentUser.name) ||
          isEventOrganizer === currentUser.name)) && (
          <hr style={{ margin: '10px 0 10px 0', padding: 0 }} />
        )}
      {isbuttonloading ?
        <Flex end center>
          <Loader size="small" withOutOverlay />
        </Flex>
        :
        <>
          {evaluate !== null && (attendees.includes(currentUser.name) ||
            isEventOrganizer === currentUser.name) &&
            <Flex
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'relative',
                height: '32px',
              }}
              row
              center
              between
              marginTop={5}
            >
              <Flex row>
                {evaluate &&
                  evaluatedata &&
                  (attendees.includes(currentUser.name) ||
                    isEventOrganizer === currentUser.name) && (
                    <>
                      <Button
                        onClick={() => {
                          NavigateEvaluate(evaluatedata);
                          handleCloseEventPopUpModal();
                        }}
                        style={{ alignSelf: 'center' }}
                        types="secondary"
                      >
                        Evaluate
                      </Button>
                    </>
                  )}
                {eventId &&
                  getEventHasMeeting(title, datalist) &&
                  (attendees.includes(currentUser.name) ||
                    isEventOrganizer === currentUser.name) && (
                    <Button
                      onClick={() => {
                        joinMeeting(eventId, link);
                        handleCloseEventPopUpModal();
                      }}
                      style={{
                        alignSelf: 'flex-start',
                        marginLeft:
                          evaluate &&
                            evaluatedata &&
                            (attendees.includes(currentUser.name) ||
                              isEventOrganizer === currentUser.name)
                            ? '12px'
                            : '',
                      }}
                    >
                      Join
                    </Button>
                  )}
              </Flex>
              <Flex row center style={{ position: 'absolute', top: 0, right: 0, bottom: 0 }}>
                {eventId !== '' && getEventHasMeeting(title, datalist) && (attendees.includes(currentUser.name) ||
                  isEventOrganizer === currentUser.name) ? (
                  <Flex title="Copy Meeting URL">
                    <Button
                      className={`${styles.icon} ${styles.popover}`}
                      onClick={() => {
                        // navigator.clipboard.writeText(link);
                        copyMeeting(eventId, link);
                      }}
                    >
                      <SvgCopy fill="#581845" width={16} height={16} />
                    </Button>
                  </Flex>
                ) : null}
                {currentUser.name === isEventOrganizer ? (
                  <>{ShowEditButton === true &&
                    <Flex title="Edit">
                      <button
                        className={styles.icon}
                        onClick={() => handleEditEvent()}
                      >
                        <SvgEdit width={16} height={16} />
                      </button>
                    </Flex>}
                  </>
                ) : null}
                {currentUser.name === isEventOrganizer ? (
                  <Flex title="Delete">
                    <button
                      className={styles.icon}
                      onClick={() => setOpenEventDeleteModal(true)}
                    >
                      <SvgTrash width={16} height={16} fill="#581845" />
                    </button>
                  </Flex>
                ) : null}
              </Flex>
            </Flex>}
        </>}
    </>
  );

  const PrivateEvent = (
    <div className={styles.content}>
      {/* <Tooltip title={title} placement="bottom-start">
              <p style={{ fontWeight: 'bold' }} className={styles.eventTitle}>
                {title}
              </p>
            </Tooltip> */}
      {/* <CustomTooltip title={title}  > */}
      {/* <p style={{ fontWeight: 'bold' }} className={styles.title}> */}
      <p style={{ fontWeight: 'bold' }} className={styles.title}>
        <Flex className={styles.titleadjust}>
          <Text title={title}>
            {title}
          </Text>
        </Flex>
        {/* </Flex> */}
      </p>
      {/* </CustomTooltip> */}

      <div className={styles.info}>
        <SvgInterviewCalendar size={14} />
        <div className={styles.infoText}>
          <Text style={{ marginBottom: 3 }}>
            {startDate.toString().slice(0, 15)}
          </Text>
          <Text>{`${formatTo12HrClock(startDate)} - ${formatTo12HrClock(
            endDate,
          )}`}</Text>
        </div>
      </div>
      <div className={styles.info}>
        <SvgCalendar1 size={14} />
        <div className={styles.infoText}>
          <Text style={{ marginBottom: 3 }}>Calendar</Text>
          <Text>Synced by {isEventPrivateName}</Text>
        </div>
      </div>
      <div className={styles.info}>
        <SvgPrivate size={14} />
        <div className={styles.infoText} style={{ alignItems: 'center' }}>
          <Text style={{ marginBottom: 3 }}>Private Event</Text>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Modal open={showEventPopUpModal} onClose={handleCloseEventPopUpModal}>
        <div className={styles.eventPopUp}>
          <CrossButton
            onClick={handleCloseEventPopUpModal}
            size={10}
            style={{
              position: 'absolute',
              top: '25px',
              right: '15px',
              display: 'flex',
              justifyContent: 'center',
            }}
            fill={'#333'}
          />
          {isZitaEvent ? ZitaEvent : PrivateEvent}
        </div>
      </Modal>
    </>
  );
};

export default EventPopUpModal;
