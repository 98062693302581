import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { dashboardApi } from '../../../../../routes/apiRoutes';

export const dashBoardMiddleWare = createAsyncThunk(
  'dashboard',
  async (_a, { rejectWithValue }) => {
    try {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; 
      const { data } = await axios.get(dashboardApi,{
        params: { time_zone: userTimeZone},
      });
      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);
