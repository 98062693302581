import { FormikProps } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import SvgView from '../../icons/SvgView';
//import SvgMail from '../../icons/SvgMail';
import SvgLock from '../../icons/SvgLock';
import SvgEmail from '../../icons/SvgEmail';
import Button from '../../uikit/Button/Button';
import ErrorMessage from '../../uikit/ErrorMessage/ErrorMessage';
import Flex from '../../uikit/Flex/Flex';
import SvgZitaLogo from '../../icons/SvgZitaLogo';
import { enterKeyPress, isEmpty } from '../../uikit/helper';
import InputText from '../../uikit/InputText/InputText';
import Text from '../../uikit/Text/Text';
import SvgPasswordlock from '../../icons/SvgPasswordlock';
import SvgUsername from '../../icons/SvgUsername';
import Help from '../Help/Helpscreen';
import styles from './loginscreen.module.css';
import Loginslider from './Loginslider';

const today = new Date();
export type loginFormProps = {
  userName: string;
  email: string;
};

type Props = {
  formik: FormikProps<loginFormProps>;
  handleForgotOpen: () => void;
  isError: boolean;
  isInactive: boolean;
  iswrongcredential?: boolean;
  //loginTitle:string;
};
const LoginInto = ({
  formik,
  handleForgotOpen,
  isError,
  isInactive,
  iswrongcredential,
}: // loginTitle,
Props) => {
  const version = process.env.REACT_APP_VERSION;
  const [isShowPass, setShowPass] = useState(false);

  const handleShowPass = () => {
    setShowPass(!isShowPass);
  };

  const [inputLengthError, setInputLengthError] = useState(false);
  const [inputLengthErrorpass, setInputLengthErrorpass] = useState(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputLength = event.target.value.length;

    // Check if input length exceeds 20 characters
    if (inputLength > 50) {
      setInputLengthError(true);
    } else {
      setInputLengthError(false);
      formik.handleChange('userName')(event); // Update the formik value
    }
  };
  const handleInputChangepass = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputLength = event.target.value.length;

    // Check if input length exceeds 20 characters
    if (inputLength > 50) {
      setInputLengthErrorpass(true);
    } else {
      setInputLengthErrorpass(false);
      formik.handleChange('email')(event); // Update the formik value
    }
  };
  const handleKeyPress = (event: { key: any }, submit: () => void) => {
    if (event.key === 'Enter') {
      if (inputLengthError === false && inputLengthErrorpass === false)
        submit(); // Call the submit function when Enter is pressed
    }
  };
  const submit = (e) => {
    if (inputLengthError === false && inputLengthErrorpass === false)
      formik.handleSubmit();
  };


  return (
    <>
      <Flex className={styles.row}>
        <Flex className={styles.splitrow_1}>
          <Help show={true}/>
          <Flex className={styles.center_aligh}>
            <Text size={22} className={styles.Log_title} bold>
              Welcome Back!
            </Text>
            <Text className={styles.text}>
              Start with the fast and smart hiring process.
            </Text>

            {/* Fields starts */}
            <Flex>
              <div>
                <InputText
                  className={styles.input}
                  placeholder="Your email or username"
                  required
                  value={formik.values.userName}
                  onChange={handleInputChange}
                  // onKeyPress={handleKeyPress}
                  onKeyPress={(e) => handleKeyPress(e, formik.handleSubmit)}
                  keyboardType="email"
                  autoComplete="new-password"
                  actionLeft={() => (
                    <Button
                      types="link"
                      className={styles.usericon}
                      tabIndex={-1}
                    >
                      <div style={{ marginTop: '-19px', marginLeft: '1px' }}>
                        <SvgUsername height={13} width={13} fill={'none'} />
                      </div>
                    </Button>
                  )}
                />
                <div style={{ marginTop: '4px' }}></div>
                <ErrorMessage
                  name="userName"
                  touched={formik.touched}
                  errors={formik.errors}
                />

                {inputLengthError && (
                  <Text size={12} color="error">
                    Email or username should be a maximum of 50 characters
                  </Text>
                )}
              </div>
              {/* Password Starts */}
              <div>
                <InputText
                  actionLeft={() => (
                    <Button
                      types="link"
                      className={styles.Passicon}
                      tabIndex={-1}
                    >
                      <div style={{ marginTop: '-16px', marginLeft: '1px' }}>
                        <SvgPasswordlock
                          height={15}
                          width={15}
                          fill="#581845"
                        />
                      </div>
                    </Button>
                  )}
                  className={styles.input}
                  placeholder="Your password"
                  required
                  autoComplete="new-password"
                  value={formik.values.email}
                  // onChange={formik.handleChange('email')}
                  onChange={handleInputChangepass}
                  // onKeyPress={handleKeyPress}
                  keyboardType={!isShowPass ? 'password' : 'text'}
                  actionRight={() => (
                    <Button
                      types="link"
                      className={styles.passwordicon}
                      onClick={handleShowPass}
                      tabIndex={-1}
                    >
                      <div style={{ marginTop: '-5px', marginRight: '2px' }}>
                        <SvgView nonView={isShowPass} height={20} width={20} />
                      </div>
                    </Button>
                  )}
                  // maxLength={50}
                  onKeyPress={(e) => handleKeyPress(e, formik.handleSubmit)}
                />
                <div style={{ marginTop: '4px' }}></div>
                <ErrorMessage
                  name="email"
                  touched={formik.touched}
                  errors={formik.errors}
                />

                {inputLengthErrorpass && (
                  <Text size={12} color="error">
                    Password should be a maximum of 50 characters
                  </Text>
                )}
                {isError && (
                  <Text size={12} color="error">
                    Your username or password is incorrect. Try again.
                  </Text>
                )}
                {formik.errors.email !== 'This field is required.' &&
                  iswrongcredential && (
                    <Text size={12} color="error">
                      Your username or password is incorrect. Try again.
                    </Text>
                  )}
                {!isEmpty(formik.values.email) && isInactive && (
                  <Text size={12} color="error">
                    Please verify your email to activate your account.
                  </Text>
                )}
              </div>
              {/* Password end */}
            </Flex>

            <Flex end>
              <Button
                types="link"
                className={styles.forget}
                onClick={handleForgotOpen}
              >
                Forgot password?
              </Button>
            </Flex>
            <Button
              onClick={submit}
              className={styles.login_button}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Login
            </Button>
            <hr />
            <Flex middle>
              <Text className={styles.text_account}>
                Don`t have an account?{' '}
                <u style={{ textDecoration: 'none' }}>
                  <Link to="/recruiter/1" style={{ fontWeight: 'bold' }}>
                    Sign up
                  </Link>
                </u>
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex className={styles.splitrow_2}>
          <Flex middle className={styles.logo}>
            <SvgZitaLogo width={240} height={125} />
          </Flex>
          <Flex className={styles.center_aligh_slider}>
            <Loginslider />
          </Flex>
        </Flex>
        <Flex className={styles.footer}>
          <Text className={styles.footer1}>App Version {version}</Text>
          &#169; Sense7ai {today.getFullYear()} ALL RIGHTS RESERVED
        </Flex>
      </Flex>
    </>
  );
};

export default LoginInto;
