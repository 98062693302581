const SvgEditStages = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="#581845"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill={props.fill}
      d="m10.267 5.016-.934 1V0h3.734v3.828a4.566 4.566 0 0 0-.263-.039 2.22 2.22 0 0 
        0-.67.023V1h-1.867v4.016ZM5.6 10.023l-.933 1.227V0H8.4v7.016l-.933 
        1V1H5.6v9.023ZM0 0h3.733v12H0V0Zm.933 11H2.8V1H.933v10Zm11.609-6c.204 
        0 .393.04.568.117.175.078.331.188.467.328s.238.305.306.492c.068.188.107.391.117.61 
        0 .203-.037.4-.11.594a1.529 1.529 0 0 1-.313.507L8.35 13.266 5.6 14l.685-2.945 5.229-5.61c.136-.146.294-.255.474-.328A1.58 1.58 
        0 0 1 12.542 5Zm.372 1.945a.563.563 0 0 0 .153-.398.533.533 0 0 0-.146-.39.535.535 0 0 
        0-.38-.157.625.625 0 0 0-.196.031.372.372 0 0 0-.168.117L7.131 11.57l-.248 1.055.985-.266 5.046-5.414Z"
    />
  </svg>
);
export default SvgEditStages;
