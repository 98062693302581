import * as React from 'react';
const SvgProfiles = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    viewBox='0 0 25 25'
    {...props}
  >
    <path
      fill="#581845"
      d="M12.5 12.086a7.3 7.3 0 0 0 3.74-1.019c1.106-.664 1.969-1.608 2.478-2.712s.643-2.319.383-3.491-.9-2.249-1.842-3.094C16.318.925 15.12.35 13.813.116A7.45 7.45 0 0 0 9.924.46c-1.23.457-2.281 1.232-3.02 2.226a5.6 5.6 0 0 0-1.135 3.357c0 1.602.71 3.14 1.971 4.273 1.263 1.133 2.975 1.77 4.76 1.77m0-10.36c.95 0 1.88.254 2.67.728a4.45 4.45 0 0 1 1.772 1.937c.363.789.459 1.657.273 2.494a4.2 4.2 0 0 1-1.316 2.21 5 5 0 0 1-2.461 1.181c-.933.167-1.9.081-2.778-.245a4.73 4.73 0 0 1-2.158-1.59 4 4 0 0 1-.81-2.398C7.692 4.898 8.2 3.8 9.1 2.99c.902-.81 2.125-1.264 3.4-1.264M24.49 17.55a16.6 16.6 0 0 0-5.46-3.425 18.1 18.1 0 0 0-6.525-1.207c-2.244 0-4.464.41-6.525 1.207A16.6 16.6 0 0 0 .52 17.55c-.335.32-.52.744-.52 1.183v4.54c0 .458.203.898.563 1.221s.85.506 1.36.506h21.154c.51 0 1-.182 1.36-.506.36-.323.563-.763.563-1.22v-4.541a1.63 1.63 0 0 0-.51-1.183m-1.413 5.723H1.923v-4.549a14.7 14.7 0 0 1 4.822-3.015 16 16 0 0 1 5.755-1.062c1.979 0 3.937.361 5.755 1.062a14.7 14.7 0 0 1 4.822 3.015z"
    />
  </svg>
);
export default SvgProfiles;