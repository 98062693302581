import SvgImportTick from '../../icons/SvgImportTick';
import SvgRoundClose from '../../icons/SvgRoundClose';
import SvgTick from '../../icons/SvgTick';
import { ERROR, SUCCESS } from '../../uikit/Colors/colors';
import Flex from '../../uikit/Flex/Flex';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import { EmpPoolEntity } from './bulkImportTypes';

type Props = {
  value: EmpPoolEntity;
  jdId?: any;
};

const Status = ({ value, jdId }: Props) => {
  const checkStatus =
    value &&
      value.first_name !== null &&
      value &&
      value.first_name &&
      value &&
      value.email !== null &&
      value &&
      value.email !== ''
      ? true
      : false;

  return (
    <Flex middle>
      {checkStatus ? (
        <CustomTooltip title={jdId === undefined ? "Candidate profile sent for matching." : "Applicant profile sent for matching."}>
          <div>
            <SvgImportTick fill={SUCCESS} />
          </div>
        </CustomTooltip>
      ) : (
        <CustomTooltip title={jdId === undefined ? 'Candidate profile is incomplete and not sent for matching.' : 'Applicant profile is incomplete and not sent for matching.'}>
          <div>
            <SvgRoundClose fill={ERROR} height={18} width={18} />
          </div>
        </CustomTooltip>
      )}
    </Flex>
  );
};

export default Status;
