import { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import NavBarCandidate from '../modules/navbar/candidatenavbar/NavBarCandidate';
import PasswordChangeScreen from '../modules/dashboardmodule/candidatedashboard/passwordchange/PasswordChangeScreen';


axios.defaults.headers.common['Authorization'] =
  'Token ' + localStorage.getItem('token');

const ProtectedRouteCandidate = ({
  notIsNav,
  component: Component,
  ...rest
}: any) => {
  const [passwordupdate, setpasswordupdate] = useState(false);
  const [isjd_id,setjd_id] = useState(null);
  const [isnotificationdata,setnotificationdata] = useState(null);
  const updatepassword = () => {
    setpasswordupdate(!passwordupdate);
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem('token') !== null) {
          return (
            <div>
              <div>
                <PasswordChangeScreen
                  value={passwordupdate}
                  update={updatepassword} />
              </div>
              {notIsNav && <NavBarCandidate update={updatepassword} setjd_id={setjd_id}setnotificationdata={setnotificationdata}/>}
              <Component {...rest} {...props} isjd_id={isjd_id} setjd_id={setjd_id} isnotificationdata={isnotificationdata}/>

            </div>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: `/login/?next=${props.location.pathname}`,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRouteCandidate;
