import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import classNames from 'classnames/bind';
import Card from '../../uikit/Card/Card';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import { toCamelCase } from '../../uikit/helper';
import Button from '../../uikit/Button/Button';
import SvgUnlockedGreen from '../../icons/SvgUnlockedGreen';
import SvgLock from '../../icons/SvgLock';
import { WHITE } from '../../uikit/Colors/colors';
import SvgUnlock from '../../icons/SvgUnlock';
import SvgDollar from '../../icons/Svgdollar1';
// import SvgJobtitle from '../../icons/SvgJobtitle';
import SvgQualification from '../../icons/qualification1';
import SvgRelocate from '../../icons/Relocate1';
import SvgSalary from '../../icons/Salary1';
import InputCheckBox from '../../uikit/InputCheckbox/InputCheckBox';
import { colorCode } from '../constValue';
import SvgJobtitle from '../../icons/Jobtitle';
import styles from './talentcardlist.module.css';
//import { DataEntity } from './talentSourcingTypes';

const cx = classNames.bind(styles);

type Props = {
  talentList: any;
  index: any;
  handleUnlockSubmit: (arg: string) => void;
  handleClick: (e: any, value: any) => void;
  isCheck: any;
  handleCandidateView: (hashKey: string) => void;
  candi_list?: string[];
};

const notSpecified = (value: string, reLocate?: string) => {
  let initialValue;
  if (value === 'not_set' || reLocate === '0' || value === null) {
    initialValue = 'Not Specified';
  } else {
    initialValue = value;
  }
  return initialValue;
};

const TalentCardMap = ({
  talentList,
  index,
  handleUnlockSubmit,
  handleClick,
  isCheck,
  handleCandidateView,
  candi_list,
}: Props) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1000px)' });
  const normal = useMediaQuery({
    query: '(min-width: 1000px) and (max-width: 1411px)',
  });
  const isLarge = useMediaQuery({ query: '(min-width: 2560px)' });

  const [isColor, setColor] = useState<string[]>([]);
  const [count, setcount] = useState(0);
  const [list, setlist] = useState([]);
  useEffect(() => {
    setColor(colorCode);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('index', index + 1);
  }, [index]);
  // const handleloader=(val)=>{
  //   setSubmitLoader();
  //   handleCandidateView(val)
  // }

  // const handlepage=()=>{
  //  setcount(index+1)
  // }
  const checkVist = candi_list?.includes(talentList.id) ? true : false;
  const sidebar = sessionStorage.getItem('EmpToggle');
  const windowSize =
    sidebar === '1' ? window.innerWidth / 3.31 : window.innerWidth / 3.76;
  return (
    <>
      <div
        style={{
          width: isTablet ? '100%' : normal ? '46%' : '32%',
          borderRadius: '4px',
        }}
        className={styles.overAll}
      >
        <Card key={index} className={cx('cardConatiner')}>
          <Flex row top>
            <InputCheckBox
              key={talentList.id}
              name={talentList.name}
              id={talentList.id}
              onChange={(e) => handleClick(e, talentList)}
              checked={isCheck.includes(talentList.id)}

              // disabled={checkVist}
            />
            <Flex row flex={1}>
              <Flex between row className={cx('profileOverAll')} flex={1}>
                <Flex flex={1}>
                  <Flex flex={1} row className={cx('profileContainer')}>
                    <div
                      className={cx('profile')}
                      style={{
                        backgroundColor: isColor[index % isColor.length],
                      }}
                    >
                      <Text size={16} bold color="white" transform="uppercase">
                        {talentList?.name.charAt(0)}
                      </Text>
                    </div>
                    <Flex flex={1}>
                      <Flex row center>
                        {!checkVist ? (
                          <>
                            <Text
                              bold
                              transform="capitalize"
                              className={styles.pointer}
                              color="link"
                              onClick={() => handleCandidateView(talentList.id)}
                              size={13}
                            >
                              {talentList.id}
                            </Text>
                          </>
                        ) : (
                          <>
                            <Text
                              bold
                              transform="capitalize"
                              className={styles.pointer}
                              color="link"
                              onClick={() => handleCandidateView(talentList.id)}
                              size={13}
                            >
                              {talentList.name}
                            </Text>
                          </>
                        )}
                      </Flex>

                      {talentList.work_experience === 0 ? (
                        <Flex
                          row
                          center
                          // wrap
                        >
                          <Flex>
                            <Text
                              color="primary"
                              size={11}
                              className={styles.ellipsis}
                              title={`Location: ${talentList.location}`}
                              style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '125px',
                                overflow: 'hidden',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                              }}
                            >
                              {talentList.location}
                            </Text>
                          </Flex>
                          <Flex>
                            <Text
                              color="primary"
                              size={11}
                              style={{ marginLeft: 1, marginRight: 2 }}
                            >
                              {' | '}
                            </Text>
                          </Flex>
                          <Flex>
                            <Text
                              color="primary"
                              size={11}
                              title={`Experience: Not Specified`}
                            >{`Not Specified`}</Text>
                          </Flex>
                        </Flex>
                      ) : (
                        <>
                          <Flex
                            row
                            center

                            // wrap
                          >
                            <Flex>
                              <Text
                                color="primary"
                                size={11}
                                className={styles.ellipsis}
                                title={`Location: ${talentList.location}`}
                                style={{
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '125px',
                                  overflow: 'hidden',
                                  display: 'inline-block',
                                  verticalAlign: 'middle',
                                }}
                              >
                                {talentList.location}
                              </Text>
                            </Flex>
                            <Flex>
                              <Text
                                color="primary"
                                size={11}
                                style={{ marginLeft: 1, marginRight: 2 }}
                              >
                                {' | '}
                              </Text>
                            </Flex>
                            <Flex>
                              <Text
                                color="primary"
                                size={11}
                                title={`Experience:${talentList.work_experience}`}
                                style={{
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '85px',
                                  overflow: 'hidden',
                                  display: 'inline-block',
                                  verticalAlign: 'middle',
                                }}
                              >
                                {talentList.work_experience}
                              </Text>
                            </Flex>
                          </Flex>
                        </>
                      )}
                      {/* <Flex row center style={{ marginBottom: '4px' }}>
                        <Text
                          size={11}
                          color="primary"
                          style={{ marginRight: 2 }}
                        >
                          Last Active:
                        </Text>
                        <Text bold size={11} color="primary">
                          {moment(talentList.updated_on).fromNow()}
                        </Text>
                      </Flex> */}
                    </Flex>
                  </Flex>

                  <Flex
                    style={{
                      marginLeft: '-5px',
                      padding: '0 20px 0 0',
                      marginTop: '10px',
                    }}
                    row
                    between
                  >
                    <Flex
                      row
                      style={{
                        overflow: 'hidden',
                        width: '250px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Flex style={{ marginRight: '1px', marginTop: '6px' }}>
                        <SvgJobtitle fill={'#581845'} width={10} height={10} />
                      </Flex>
                      <Flex>
                        <Text
                          title={`Job Title: ${toCamelCase(talentList.title)}`}
                          color="black_1"
                          style={{
                            marginLeft: '3px',
                            marginTop: '3px',
                            // overflow: 'hidden !important',
                            // width: '181px !important',
                            // textOverflow: 'ellipsis !important',
                          }}
                          size={11}
                        >
                          {toCamelCase(talentList.title)}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex row>
                    <Flex style={{ marginLeft: '-8px', marginTop: '4px' }}>
                      <SvgQualification
                        fill={'#581845'}
                        width={16}
                        height={16}
                      />
                    </Flex>

                    {talentList.Qualification === '' ? (
                      <>
                        <Text
                          color="black_1"
                          style={{ marginLeft: '3px', marginTop: '4px' }}
                          title={`Qualification: ${talentList.Qualification}`}
                          size={11}
                        >
                          {'Not Specified'}
                        </Text>
                      </>
                    ) : (
                      <>
                        <>
                          <Text
                            color="black_1"
                            style={{ marginLeft: '3px', marginTop: '4px' }}
                            title={`Qualification: ${talentList.Qualification}`}
                            size={11}
                          >
                            {talentList.Qualification}
                          </Text>
                        </>
                      </>
                    )}
                  </Flex>
                </Flex>

                <div
                  className={styles.line}
                  style={{ marginRight: checkVist ? '22px' : '18px' }}
                ></div>
                <Flex
                  style={{
                    marginTop: '30px',
                    marginRight: checkVist ? '17px' : '25px',
                    marginLeft: checkVist ? '1px' : '7px',
                  }}
                >
                  <Flex width={'113%'} className={styles.svgcenter}>
                    {!checkVist ? (
                      <>
                        <Flex
                          style={{ cursor: checkVist ? 'default' : 'pointer' }}
                          onClick={() => handleUnlockSubmit(talentList.id)}
                        >
                          <SvgLock fill={'#581845'} width={24} height={24} />
                        </Flex>
                        <Text
                          bold
                          size={11}
                          title={'Unlock Contact'}
                          style={{
                            marginLeft: '-5px',
                            color: checkVist ? 'black' : '#581845',
                            fontSize: '12px',
                            cursor: checkVist ? 'default' : 'pointer',
                          }}
                          onClick={() => handleUnlockSubmit(talentList.id)}
                        >
                          {'Unlock'}
                        </Text>
                      </>
                    ) : (
                      <>
                        <Flex>
                          <SvgUnlockedGreen
                            viewBox="0 0 24 24"
                            width={21}
                            height={21}
                          />
                        </Flex>
                        <Text
                          bold
                          size={11}
                          title={'Unlocked Contact'}
                          style={{
                            marginLeft: '-5px',
                            color: checkVist ? 'black' : '#581845',
                            fontSize: '12px',
                            cursor: checkVist ? 'default' : 'pointer',
                          }}
                        >
                          {'Unlocked'}
                        </Text>
                      </>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </div>
    </>
  );
};

export default TalentCardMap;
