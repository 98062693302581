import { createSlice } from '@reduxjs/toolkit';
import {
  ChatbotSearchdata,
  ChatbotFeedbackdata,
  chatget,
  timer,
} from '../../ChatbotTypes';
import {
  chatbotcheckmiddleware,
  chatbotfeedbackmiddleware,
  chatbotdataget,
  chatbottimermiddleware
} from '../middleware/chatbotmiddleware';

const ChatbotSearchdataState: ChatbotSearchdata = {
  search_data: '',
  isLoading: false,
  error: '',
  result: [],
};

const ChatbotFeedbackdataState: ChatbotFeedbackdata = {
  responce: [],
  isLoading: false,
  error: '',
};

const ChatHistory: chatget = {
  chat: [],
  isLoading: false,
  error: '',
};

const ChatTime: timer = {
  count: 0,
  isLoading: false,
  error: '',
  success: false,
};

const ChatboatsearchReducer = createSlice({
  name: 'chatbot_search',
  initialState: ChatbotSearchdataState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(chatbotcheckmiddleware.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(chatbotcheckmiddleware.fulfilled, (state, action) => {
      state.isLoading = false;
      state.result = action.payload.data;
    });
    builder.addCase(chatbotcheckmiddleware.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

const ChatbotfeedbackReducer = createSlice({
  name: 'chatbot_feedback',
  initialState: ChatbotFeedbackdataState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(chatbotfeedbackmiddleware.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(chatbotfeedbackmiddleware.fulfilled, (state, action) => {
      state.isLoading = false;
      state.responce = action.payload.data;
    });
    builder.addCase(chatbotfeedbackmiddleware.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

const Chatbotgetdata = createSlice({
  name: 'chatbot_get',
  initialState: ChatHistory,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(chatbotdataget.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(chatbotdataget.fulfilled, (state, action) => {
      state.isLoading = false;
      state.chat = action.payload.data;
    });
    builder.addCase(chatbotdataget.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

const Chatbottimer = createSlice({
  name: 'chatbot_timer',
  initialState: ChatTime,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(chatbottimermiddleware.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(chatbottimermiddleware.fulfilled, (state, action) => {
      state.isLoading = false;
      state.count = action.payload.data.count;
      state.success = action.payload.data.success;
    });
    builder.addCase(chatbottimermiddleware.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

export const Chatbotchating = ChatboatsearchReducer.reducer;
export const ChatbotFeedbackReducers = ChatbotfeedbackReducer.reducer;
export const ChatbotGetdata = Chatbotgetdata.reducer;
export const ChatbotTimer = Chatbottimer.reducer;
